/**
 *
 * @module purchaseType
 *
 */

/**
 *
 * @enum
 * @typedef {Object} SDK.Purchase.PurchaseType
 * @desc Defines the different types of purchases that are supported.
 *
 * @property {String} AppleIAP - Apple in app purchase.
 * @property {String} GoogleIAP - Google in app purchase.
 * @property {String} DirectToConsumer - Direct to consumer purchase.
 *
 * @example
 * PurchaseType.AppleIAP // 'AppleIAP'
 *
 */
enum PurchaseType {
    AppleIAP = 'AppleIAP',
    GoogleIAP = 'GoogleIAP',
    DirectToConsumer = 'DirectToConsumer'
}

export default PurchaseType;
