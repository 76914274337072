/**
 *
 * @module sdkSession
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk#feature-overviews
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/sdkSession.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/SdkSession.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Error-Handling.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/SDK-Authorization-Workflow.md
 * @see https://github.bamtech.co/pages/fed-packages/dss-type-checking/
 *
 */

/* eslint camelcase: "off" */

import { Check, Types, typecheck } from '@dss/type-checking';
import { v4 as uuidv4 } from 'uuid';

import { EventEmitter } from 'events';

import type { IPluginTypes } from './IPlugin';
import type CommerceApi from './commerce/commerceApi';
import type ComcastApi from './commerce/comcast/comcastApi';
import type IDealApi from './commerce/iDeal/iDealApi';
import type PaymentCardApi from './commerce/paymentCard/paymentCardApi';
import type PayPalApi from './commerce/payPal/payPalApi';
import type KlarnaApi from './commerce/klarna/klarnaApi';
import type MercadoApi from './commerce/mercado/mercadoApi';
import type SocketApi from './socket/socketApi';
import type MediaApi from './media/mediaApi';
import type PurchaseApi from './purchase/purchaseApi';
import type PaywallApi from './paywall/paywallApi';
import type ContentApi from './content/contentApi';
import type InvoiceApi from './invoice/invoiceApi';
import type AccountApi from './account/accountApi';
import type UserActivityApi from './userActivity/userActivityApi';
import type UserProfileApi from './userProfile/userProfileApi';
import type EntitlementApi from './entitlement/entitlementApi';
import type FlexApi from './flex/flexApi';
import type RipcutApi from './ripcut/ripcutApi';

import type BrowserInfo from './services/commerce/browserInfo';
import type TokenRefreshFailure from './token/tokenRefreshFailure';
import type AccessChangedEvent from './accessChangedEvent';
import type SessionInfoChangedEvent from './sessionInfoChangedEvent';
import type FeatureFlagsChangedEvent from './featureFlagsChangedEvent';

import ConfigurationManager from './configuration/configurationManager';

import DeviceAttributeProvider from './device/deviceAttributeProvider';
import DeviceManager from './device/deviceManager';

import LoggingApi from './logging/loggingApi';
import Logger from './logging/logger';

import OrchestrationManager from './orchestration/orchestrationManager';

import TelemetryManager from './internal/telemetry/telemetryManager';

import SubscriptionManager from './subscription/subscriptionManager';

import SessionManager from './session/sessionManager';

import AccessContextState from './token/accessContextState';
import AccessTokenProvider from './token/accessTokenProvider';
import AccessStorage from './token/accessStorage';
import AccountDelegationRefreshTokenStorage from './token/accountDelegationRefreshTokenStorage';
import DeviceGrantStorage from './token/deviceGrantStorage';
import TokenManager from './token/tokenManager';

import FeatureFlagsStorage from './session/featureFlagsStorage';

import OrchestrationApi from './orchestration/orchestrationApi';

import SessionInfoStorage from './session/sessionInfoStorage';

import SubscriptionApi from './subscription/subscriptionApi';

import BootstrapConfiguration from './services/configuration/bootstrapConfiguration';
import SdkSessionConfiguration from './services/configuration/sdkSessionConfiguration';
import ServiceEnvironmentName from './services/configuration/serviceEnvironmentName';

import DelegationToken from './services/token/delegationToken';
import DustLogUtility from './services/internal/dust/dustLogUtility';

import TokenClient from './services/token/tokenClient';
import TelemetryClient from './services/internal/telemetry/telemetryClient';
import SubscriptionClient from './services/subscription/subscriptionClient';
import OrchestrationClient from './services/orchestration/orchestrationClient';
import ServiceException from './services/exception/serviceException';
import ExceptionReference from './services/exception/exceptionReference';
import ErrorReason from './services/exception/errorReason';
import getSafe from './services/util/getSafe';
import LogTransaction from './logging/logTransaction';
import DustUrnReference from './services/internal/dust/dustUrnReference';
import DustDecorators from './services/internal/dust/dustDecorators';
import SocketEvents from './socket/socketEvents';
import Events from './events';
import InternalEvents from './internalEvents';
import InitializationState from './initializationState';
import BrowserDeviceAttributeProvider from './device/browserDeviceAttributeProvider';
import AdvertisingIdProvider from './advertising/advertisingIdProvider';
import PlatformMetricsProvider from './platform/platformMetricsProvider';
import MediaCapabilitiesProvider from './media/mediaCapabilitiesProvider';
import ReauthorizationFailure from './reauthorizationFailure';
import EdgeSink from './internal/dust/edgeSink';
import DustSink from './internal/dust/dustSink';
import AccountGrant from './services/account/accountGrant';
import TokenUpdater from './services/tokenUpdater';
import ServicePlatformProviders from './services/providers/platformProviders';
import PlatformProviders from './providers/platformProviders';
import VersionInfo from './versionInfo';
import sdkPlugins from './sdkPlugins';
import DiagnosticsApi from './diagnosticsApi';
import type { IGeoProvider } from './providers/IGeoProvider';
import type CustomerServiceManager from './customerService/customerServiceManager';
import type SocketManager from './socket/socketManager';
import type { SessionExperimentAssignment } from './services/session/typedefs';
import type SessionInfo from './services/session/sessionInfo';
import AccessToken from './token/accessToken';
import type DeviceGrant from './services/token/deviceGrant';
import type Access from './token/access';

const DustUrn = DustUrnReference.sdkSession;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

type BrowserInfoProviderType = {
    getBrowserInfo: () => BrowserInfo;
};

type SdkSessionManagers = {
    deviceManager: DeviceManager;
    tokenManager: TokenManager;
    telemetryManager: TelemetryManager;
    subscriptionManager: SubscriptionManager;
    sessionManager: SessionManager;
    orchestrationManager: OrchestrationManager;
    customerServiceManager?: CustomerServiceManager;
    socketManager?: SocketManager;
};

/**
 *
 * @access public
 * @see https://nodejs.org/api/events.html
 * @desc Represents an SDK session for the application to interact with.
 *
 */
export default class SdkSession extends EventEmitter {
    public static plugins: Array<IPluginTypes>;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.SdkSessionConfiguration}
     *
     */
    private config: SdkSessionConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessStorage}
     *
     */
    private accessStorage: AccessStorage;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Token.DeviceGrantStorage}
     *
     */
    private deviceGrantStorage: DeviceGrantStorage;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Token.AccountDelegationRefreshTokenStorage}
     *
     */
    private accountDelegationRefreshTokenStorage: AccountDelegationRefreshTokenStorage;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Session.SessionInfoStorage}
     *
     */
    private sessionInfoStorage: SessionInfoStorage;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Session.FeatureFlagsStorage}
     *
     */
    private featureFlagsStorage: FeatureFlagsStorage;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     * @desc Single Logger instance to be reused throughout the SDK.
     * @note this is 'public' at the type level to satisfy the decorator constraint ApiMethodDecoratorableClass
     *
     */
    public logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.GeoProvider}
     *
     */
    private geoProvider: IGeoProvider;

    /**
     *
     * @access public
     * @type {SDK.Media.MediaCapabilitiesProvider}
     *
     */
    public mediaCapabilitiesProvider: MediaCapabilitiesProvider;

    /**
     *
     * @access public
     * @since 7.0.0
     * @type {SDK.Platform.PlatformMetricsProvider}
     *
     */
    public platformMetricsProvider: PlatformMetricsProvider;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Advertising.AdvertisingIdProvider}
     *
     */
    public advertisingIdProvider: AdvertisingIdProvider;

    /**
     *
     * @access private
     * @type {SdkSessionManagers}
     * @desc Structured map with all expected managers.
     *
     */
    private managers: SdkSessionManagers;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessTokenProvider}
     * @since 3.0.0
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @access private
     * @type {String}
     * @desc The public application key for the SDK.
     *
     */
    private clientApiKey: string;

    /**
     *
     * @access public
     * @type {String}
     * @since 1.1.2
     * @desc The client ID to use the SDK against.
     * @note exposed publicly for use in util modules, etc...
     *
     */
    public clientId: string;

    /**
     *
     * @access public
     * @type {String}
     * @since 1.1.2
     * @desc The environment that the SDK services should run in.
     * @note exposed publicly for use in util modules, etc...
     *
     */
    public environment: string;

    /**
     *
     * @access public
     * @type {String}
     * @since 2.0.0
     * @desc The SDK version. The format of the version is `SemVer`.
     * @example '21.1.0'
     *
     */
    public version: string;

    /**
     *
     * @access public
     * @type {String}
     * @since 2.0.0
     * @desc The shortened version String.
     * @example '21.1'
     *
     */
    public versionShort: string;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc Whether the service account was created with debug logging enabled.
     *
     */
    public debugEnabled: boolean;

    // NOTE: We still need to document the public *Api properties
    // but they are attached via the plugin - so define them
    // below - assigning null before they get attached via `createApi`

    /**
     *
     * @access public
     * @type {SDK.Commerce.CommerceApi}
     *
     */
    public commerceApi: Nullable<CommerceApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 4.11.0
     * @type {Object}
     * @desc Commerce sub api namespace
     *
     * @property {ComcastApi} comcastApi
     * @property {IDealApi} iDealApi
     * @property {MercadoApi} mercadoApi
     * @property {PayPalApi} payPalApi
     * @property {KlarnaApi} klarnaApi
     * @property {PaymentCardApi} paymentCardApi
     *
     */
    public commerce?: {
        comcastApi: ComcastApi;
        iDealApi: IDealApi;
        mercadoApi: MercadoApi;
        payPalApi: PayPalApi;
        klarnaApi: KlarnaApi;
        paymentCardApi: PaymentCardApi;
    }; // assigned via plugin

    /**
     *
     * @access public
     * @type {SDK.Media.MediaApi}
     * @desc Gets the media resource used to support media playback.
     *
     */
    public mediaApi: Nullable<MediaApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Socket.SocketApi}
     * @desc Provides an interface for sending and receiving messages over the SDK-managed socket connection.
     *
     */
    public socketApi: Nullable<SocketApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @type {SDK.Purchase.PurchaseApi}
     * @desc Gets the purchases resource used to support and retrieve in app purchases.
     *
     */
    public purchaseApi: Nullable<PurchaseApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {SDK.Paywall.PaywallApi}
     * @desc Provides an object used to access and maintain paywall.
     *
     */
    public paywallApi: Nullable<PaywallApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @type {SDK.Content.ContentApi}
     * @desc Gets the content resource used to support content discovery.
     *
     */
    public contentApi: Nullable<ContentApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {SDK.Invoice.InvoiceApi}
     *
     */
    public invoiceApi: Nullable<InvoiceApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @type {SDK.Account.AccountApi}
     * @desc Gets the account resource to access account properties.
     *
     */
    public accountApi: Nullable<AccountApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @type {SDK.UserActivity.UserActivityApi}
     * @desc Gets an object used to allow application developers to send custom events, related to
     * user activity while using an app.
     *
     */
    public userActivityApi: Nullable<UserActivityApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 3.5.0
     * @type {SDK.UserProfile.UserProfileApi}
     * @desc Provides an object used to access and maintain user profiles.
     *
     */
    public userProfileApi: Nullable<UserProfileApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Entitlement.EntitlementApi}
     *
     */
    public entitlementApi: Nullable<EntitlementApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {SDK.Flex.FlexApi}
     *
     */
    public flexApi: Nullable<FlexApi> = null; // assigned via plugin

    /**
     *
     * @access public
     * @type {SDK.Logging.LoggingApi}
     * @desc Gets the Logging instance for access to logging related features.
     *
     */
    public loggingApi: LoggingApi;

    /**
     *
     * @access public
     * @type {SDK.Subscription.SubscriptionApi}
     * @desc Gets the subscription resource used to support and retrieve subscriptions.
     *
     */
    public subscriptionApi: SubscriptionApi;

    /**
     *
     * @access public
     * @since 4.11.0
     * @type {SDK.DiagnosticsApi}
     *
     */
    public diagnosticsApi: DiagnosticsApi;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {SDK.Orchestration.OrchestrationApi}
     *
     */
    public orchestrationApi: OrchestrationApi;

    /**
     *
     * @access public
     * @since 21.0.0
     * @type {SDK.Media.RipcutApi}
     * @desc Gets the object used for retrieving images from Ripcut.
     *
     */
    public ripcutApi: Nullable<RipcutApi> = null; // assigned via plugin

    /**
     *
     * @access private
     * @since 4.11.0
     * @type {SDK.Device.DeviceAttributeProvider}
     *
     */
    private deviceAttributeProvider: DeviceAttributeProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc Determines if the current session instance is in a initialized state.
     *
     */
    private isInitialized: boolean;

    /**
     *
     * @access private
     * @since 4.8.0
     * @type {Boolean}
     * @desc flag used for enabling dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {String}
     * @desc `uuidv4` unique id that represents a single `sdkSession` from a single bootstrap. Used internally to group events within an instantiated version of an SDK.
     *
     */
    public sdkInstanceId: string;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {Boolean}
     * @desc Indicates whether the session instance is disposed.
     *
     */
    private isDisposed: boolean;

    /**
     *
     * @since 5.0.0
     * @access protected
     * @param {Object} plugin
     *
     */
    public static attachPlugin(plugin: IPluginTypes) {
        sdkPlugins.addPlugin(plugin);
    }

    /**
     *
     * @since 5.0.0
     * @access private
     * @param {Object} options
     *
     */
    public static createManager(options: unknown) {
        sdkPlugins.plugins.forEach((plugin) => {
            plugin.createManager?.(options);
        });
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Configuration.SdkSessionConfiguration} options.sdkSessionConfiguration
     * @param {SDK.Token.DeviceGrantStorage} options.deviceGrantStorage
     * @param {SDK.Token.AccountDelegationRefreshTokenStorage} options.accountDelegationRefreshTokenStorage
     * @param {SDK.Token.AccessStorage} options.accessStorage
     * @param {SDK.Session.SessionInfoStorage} options.sessionInfoStorage
     * @param {SDK.Session.FeatureFlagsStorage} options.featureFlagsStorage
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.GeoProvider} [options.geoProvider=null]
     * @param {SDK.Media.MediaCapabilitiesProvider} [options.mediaCapabilitiesProvider=null]
     * @param {SDK.Platform.PlatformMetricsProvider} options.platformMetricsProvider
     * @param {Object} options.managers
     * @param {Object} options.metadata
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     * @param {String} options.sdkInstanceId
     * @emits {SDK.Events.ReauthorizationFailure} The event raised when automatic token refresh fails.
     * @emits {SDK.Events.OffDeviceTokenRefresh} Provides an event that can share an `Error` in the case an `offDeviceTokenRefresh` socket message fails to process.
     * Additionally, to support Welch Connected Devices flows, this event also contains a `actionGrant` and redemption flow.
     * @emits {SDK.Events.AccessChanged} The event raised each time a access token is updated.
     * @emits {SDK.Events.SessionInfoChanged} Emitted by the `SdkSession` when the session info associated with the underlying access
     * token has changed, providing access to both the old and new `SessionInfo` object(s).
     * @emits {SDK.Events.AgeVerificationChanged} Emitted by the `SdkSession` when an age verification request or
     * redemption is completed successfully or fails with an error.
     *
     */
    public constructor(options: {
        sdkSessionConfiguration: SdkSessionConfiguration;
        deviceGrantStorage: DeviceGrantStorage;
        accountDelegationRefreshTokenStorage: AccountDelegationRefreshTokenStorage;
        accessStorage: AccessStorage;
        sessionInfoStorage: SessionInfoStorage;
        featureFlagsStorage: FeatureFlagsStorage;
        logger: Logger;
        geoProvider?: IGeoProvider;
        mediaCapabilitiesProvider?: MediaCapabilitiesProvider;
        platformMetricsProvider: PlatformMetricsProvider;
        advertisingIdProvider: AdvertisingIdProvider;
        managers: SdkSessionManagers;
        metadata: {
            clientId: string;
            clientApiKey: string;
            environment: keyof typeof ServiceEnvironmentName;
            debugEnabled: boolean;
        };
        accessTokenProvider: AccessTokenProvider;
        sdkInstanceId: string;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sdkSessionConfiguration: Types.instanceStrict(
                        SdkSessionConfiguration
                    ),
                    deviceGrantStorage:
                        Types.instanceStrict(DeviceGrantStorage),
                    accountDelegationRefreshTokenStorage: Types.instanceStrict(
                        AccountDelegationRefreshTokenStorage
                    ),
                    accessStorage: Types.instanceStrict(AccessStorage),
                    sessionInfoStorage:
                        Types.instanceStrict(SessionInfoStorage),
                    featureFlagsStorage:
                        Types.instanceStrict(FeatureFlagsStorage),
                    logger: Types.instanceStrict(Logger),
                    geoProvider: Types.instanceStrict(
                        PlatformProviders.GeoProvider
                    ).optional,
                    mediaCapabilitiesProvider: Types.instanceStrict(
                        MediaCapabilitiesProvider
                    ).optional,
                    platformMetricsProvider: Types.instanceStrict(
                        PlatformMetricsProvider
                    ),
                    advertisingIdProvider: Types.instanceStrict(
                        AdvertisingIdProvider
                    ),
                    managers: Types.object({
                        deviceManager: Types.instanceStrict(DeviceManager),
                        tokenManager: Types.instanceStrict(TokenManager),
                        telemetryManager:
                            Types.instanceStrict(TelemetryManager),
                        subscriptionManager:
                            Types.instanceStrict(SubscriptionManager),
                        sessionManager: Types.instanceStrict(SessionManager)
                    }),
                    metadata: Types.object({
                        clientId: Types.nonEmptyString,
                        clientApiKey: Types.nonEmptyString,
                        environment: Types.keyIn(ServiceEnvironmentName),
                        debugEnabled: Types.boolean
                    }),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider),
                    sdkInstanceId: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            sdkSessionConfiguration,
            accessStorage,
            deviceGrantStorage,
            accountDelegationRefreshTokenStorage,
            sessionInfoStorage,
            featureFlagsStorage,
            mediaCapabilitiesProvider,
            platformMetricsProvider,
            advertisingIdProvider,
            accessTokenProvider,
            geoProvider,
            logger,
            managers,
            metadata,
            sdkInstanceId
        } = options;

        this.config = sdkSessionConfiguration;
        this.accessStorage = accessStorage;
        this.deviceGrantStorage = deviceGrantStorage;
        this.accountDelegationRefreshTokenStorage =
            accountDelegationRefreshTokenStorage;
        this.sessionInfoStorage = sessionInfoStorage;
        this.featureFlagsStorage = featureFlagsStorage;
        this.logger = logger;
        this.geoProvider =
            geoProvider || new PlatformProviders.GeoProvider(this.logger);
        this.mediaCapabilitiesProvider =
            mediaCapabilitiesProvider ||
            new MediaCapabilitiesProvider(this.logger);
        this.platformMetricsProvider = platformMetricsProvider;
        this.advertisingIdProvider = advertisingIdProvider;
        this.managers = managers;
        this.accessTokenProvider = accessTokenProvider;
        this.clientApiKey = metadata.clientApiKey || '';
        this.clientId = metadata.clientId || '';
        this.environment = metadata.environment || '';
        this.version = VersionInfo.version;
        this.versionShort = VersionInfo.versionShort;
        this.debugEnabled = metadata.debugEnabled || false;

        sdkPlugins.plugins.forEach((plugin) => {
            if (plugin.createApi) {
                plugin.createApi({
                    sdkSession: this,
                    logger
                });
            }
        });

        /**
         *
         * @access public
         * @type {SDK.Logging.LoggingApi}
         * @desc Gets the Logging instance for access to logging related features.
         *
         */
        this.loggingApi = new LoggingApi({
            logger: this.logger
        });

        /**
         *
         * @access public
         * @type {SDK.Subscription.SubscriptionApi}
         * @desc Gets the subscription resource used to support and retrieve subscriptions.
         *
         */
        this.subscriptionApi = new SubscriptionApi({
            subscriptionManager: this.managers.subscriptionManager,
            logger: this.logger
        });

        /**
         *
         * @access public
         * @since 4.11.0
         * @type {SDK.DiagnosticsApi}
         *
         */
        this.diagnosticsApi = new DiagnosticsApi({
            telemetryManager: this.managers.telemetryManager,
            logger: this.logger
        });

        /**
         *
         * @access public
         * @since 4.17.0
         * @type {SDK.Orchestration.OrchestrationApi}
         *
         */
        this.orchestrationApi = new OrchestrationApi({
            orchestrationManager: this.managers.orchestrationManager,
            logger: this.logger
        });

        /**
         *
         * @access private
         * @since 4.11.0
         * @type {SDK.Device.DeviceAttributeProvider}
         *
         */
        this.deviceAttributeProvider =
            this.managers.deviceManager.deviceAttributeProvider;

        /**
         *
         * @access private
         * @type {Boolean}
         * @desc Determines if the current session instance is in a initialized state.
         *
         */
        this.isInitialized = false;

        /**
         *
         * @access private
         * @since 4.8.0
         * @type {Boolean}
         * @desc flag used for enabling dust logging
         *
         */
        this.dustEnabled = true;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {String}
         * @desc Root level GUID that groups all events within an instantiated version of an SDK.
         *
         */
        this.sdkInstanceId = sdkInstanceId;

        /**
         *
         * @access private
         * @since 16.0.0
         * @type {Boolean}
         * @desc Indicates whether the session instance is disposed.
         *
         */
        this.isDisposed = false;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Services.Configuration.BootstrapConfiguration} options.bootstrapConfiguration - The bootstrap
     * information used to initialize the SDK.
     * @param {SDK.Token.GeoProvider} [options.geoProvider=null]
     * @param {SDK.Media.MediaCapabilitiesProvider} [options.mediaCapabilitiesProvider=null]
     * @param {SDK.Device.DeviceAttributeProvider} [options.deviceAttributeProvider=SDK.Device.BrowserDeviceAttributeProvider] - Interface for application-provided device
     * attributes. The SDK will query the provider for these values when they will be used for a service request, such as
     * registering a new device.
     * @param {SDK.Commerce.BrowserInfoProvider} [options.browserInfoProvider=null] - Provider used to extract browser information from the shopper.
     * Please note that because the SDK may not be able to detect all required fields, generally it will
     * be required for the app developer to pass in a custom instance.
     * @param {SDK.Platform.PlatformMetricsProvider} [options.platformMetricsProvider] - Provider used to get platform metrics.
     * @param {SDK.Advertising.AdvertisingIdProvider} [options.advertisingIdProvider] - Provider used to get platform metrics.
     * @desc Creates a new instance of `SdkSession` initialized with the supplied
     * bootstrapConfiguration information. This is the entry point into all further interactions with the SDK.
     * @note SessionInfoStorage attempts to fetch SessionInfo from the SDK.Session.SessionInfoStorage implementation.
     * @throws {InvalidDustConfigurationException}
     * @throws {ConfigurationNotFoundException} Unable to locate configuration data based on the supplied
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SdkSession>}
     *
     * @example <caption>Standard way of setting up a new session instance.</caption>
     * SDK.SdkSession.createSdkSession({ bootstrapConfiguration }).then((session) => {
     *     console.log(session.version); // '21.1.0'
     * });
     *
     * @example <caption>Using events after creating a new session instance.</caption>
     * SDK.SdkSession.createSdkSession({ bootstrapConfiguration }).then((session) => {
     *     session.on(SDK.Events.SessionInfoChanged, (sessionInfoChangedEvent) => {
     *         // Evaluate the new session properties (sessionInfoChangedEvent.newSessionInfo) and update the user
     *         // interface as required
     *     });
     * });
     *
     * @example <caption>Setting up a new session instance with all params defined.</caption>
     * SDK.SdkSession.createSdkSession({
     *     bootstrapConfiguration,
     *     geoProvider,
     *     mediaCapabilitiesProvider,
     *     platformMetricsProvider,
     *     deviceAttributeProvider,
     *     advertisingIdProvider,
     *     browserInfoProvider
     * }).then((session) => {
     *     // start using the session
     * });
     *
     */
    public static async createSdkSession(options: {
        bootstrapConfiguration: BootstrapConfiguration;
        geoProvider?: IGeoProvider;
        mediaCapabilitiesProvider?: MediaCapabilitiesProvider;
        platformMetricsProvider?: PlatformMetricsProvider;
        deviceAttributeProvider?: DeviceAttributeProvider;
        advertisingIdProvider?: AdvertisingIdProvider;
        // maybe we provide a "default provider" - in sdk core (not commerce in commerce)
        browserInfoProvider?: BrowserInfoProviderType;
    }) {
        const logger = Logger.instance;
        const sdkInstanceId = uuidv4();
        const _arguments = arguments;

        // Attach the instance id to the logger so that it can easily be
        // referenced throughout the codebase as needed.
        logger.sdkInstanceId = sdkInstanceId;

        return LogTransaction.wrapLogTransaction({
            file: SdkSession.toString(),
            urn: DustUrn.createSdkSession,
            logger,
            /**
             *
             * @param {SDK.Logging.LogTransaction} logTransaction
             *
             */
            action: async (logTransaction: LogTransaction) => {
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const params = {
                        options: Types.object({
                            bootstrapConfiguration: Types.instanceStrict(
                                BootstrapConfiguration
                            ),
                            geoProvider: Types.instanceStrict(
                                PlatformProviders.GeoProvider
                            ).optional,
                            mediaCapabilitiesProvider: Types.instanceStrict(
                                MediaCapabilitiesProvider
                            ).optional,
                            platformMetricsProvider: Types.instanceStrict(
                                PlatformMetricsProvider
                            ).optional,
                            deviceAttributeProvider: Types.instanceStrict(
                                DeviceAttributeProvider
                            ).optional,
                            advertisingIdProvider: Types.instanceStrict(
                                AdvertisingIdProvider
                            ).optional,

                            // maybe we provide a "default provider" - in sdk core (not commerce in commerce)
                            browserInfoProvider: Types.object({
                                getBrowserInfo: Types.function
                            }).optional
                        })
                    };

                    typecheck(this, 'createSdkSession', params, _arguments);
                }

                const { bootstrapConfiguration } = options;

                const {
                    geoProvider,
                    mediaCapabilitiesProvider,
                    deviceAttributeProvider = new BrowserDeviceAttributeProvider(),
                    platformMetricsProvider = new PlatformMetricsProvider(
                        logger
                    ),
                    advertisingIdProvider = new AdvertisingIdProvider(logger),
                    browserInfoProvider
                } = options;

                const {
                    clientId,
                    clientApiKey,
                    environment,
                    debugEnabled,
                    application,
                    useStorageCompression: useStorageCompressionLegacy,
                    extras
                } = bootstrapConfiguration;

                let dustSink = logger.sinks.find((sink) =>
                    Check.instanceStrict(sink, DustSink)
                ) as DustSink;

                if (Check.not.assigned(dustSink)) {
                    dustSink = new DustSink(logger);

                    logger.addSink(dustSink);
                }

                let edgeSink = logger.sinks.find((sink) =>
                    Check.instanceStrict(sink, EdgeSink)
                );

                if (Check.not.assigned(edgeSink)) {
                    edgeSink = new EdgeSink(logger);

                    logger.addSink(edgeSink);
                }

                logger.console(debugEnabled);

                logger.info(
                    SdkSession.toString(),
                    `Create SDK session instance, ${clientId}/${environment}.`
                );
                logger.info(
                    SdkSession.toString(),
                    `Version, ${VersionInfo.version}.`
                );
                logger.info(SdkSession.toString(), 'Debug mode enabled.');

                // do not check for dustEnabled here because we need to log dust events until we get the config
                const dustLogUtility = new DustLogUtility({
                    logger,
                    source: SdkSession.toString(),
                    urn: DustUrn.createSdkSession,
                    logTransaction
                });

                const managers = Object.create(null);

                const tokenUpdater = new TokenUpdater({
                    updateAccessToken: (...args: Array<unknown>) => {
                        return managers.tokenManager.updateAccessToken.call(
                            managers.tokenManager,
                            ...args
                        );
                    },
                    refreshAccessToken: (...args: Array<unknown>) => {
                        return managers.tokenManager.refreshAccessToken.call(
                            managers.tokenManager,
                            ...args
                        );
                    },
                    getAccessToken: () => {
                        return managers.tokenManager.getAccessToken.call(
                            managers.tokenManager
                        );
                    },
                    once: (...args: Array<unknown>) => {
                        return managers.tokenManager.once.call(
                            managers.tokenManager,
                            ...args
                        );
                    },
                    emit: (...args: Array<unknown>) => {
                        return managers.tokenManager.emit.call(
                            managers.tokenManager,
                            ...args
                        );
                    }
                });

                // TODO - Remove this logic once useStorageCompression is fully deprecated on BootstrapConfiguration.
                // @ts-ignore TODO work work through this deprecation
                let useStorageCompression = extras.useStorageCompression;

                if (Check.not.assigned(useStorageCompression)) {
                    useStorageCompression = useStorageCompressionLegacy;
                }

                // @ts-ignore TODO work work through this deprecation
                const { useMemoryFirst } = extras;

                const storage = new ServicePlatformProviders.Storage({
                    logger,
                    useMemoryFirst,
                    useStorageCompression
                });
                const httpClient = new ServicePlatformProviders.HttpClient(
                    logger,
                    tokenUpdater
                );

                const accessStorage = new AccessStorage({
                    clientId,
                    environment,
                    logger,
                    storage
                });
                const deviceGrantStorage = new DeviceGrantStorage({
                    clientId,
                    environment,
                    logger,
                    storage
                });
                const accountDelegationRefreshTokenStorage =
                    new AccountDelegationRefreshTokenStorage({
                        clientId,
                        environment,
                        logger,
                        storage,
                        accountDelegationRefreshToken:
                            bootstrapConfiguration.accountDelegationRefreshToken
                    });

                try {
                    await accessStorage.loadAccessFromStorage();
                } catch (ex) {
                    /* istanbul ignore next */
                    logger.error(SdkSession.toString(), ex);
                }

                try {
                    await deviceGrantStorage.loadDeviceGrantFromStorage();
                } catch (ex) {
                    /* istanbul ignore next */
                    logger.error(SdkSession.toString(), ex);
                }

                try {
                    await accountDelegationRefreshTokenStorage.loadAccountDelegationRefreshTokenFromStorage();
                } catch (ex) {
                    /* istanbul ignore next */
                    logger.error(SdkSession.toString(), ex);
                }

                const featureFlagsStorage = new FeatureFlagsStorage({
                    clientId,
                    environment,
                    logger,
                    storage
                });

                const sessionInfoStorage = new SessionInfoStorage({
                    clientId,
                    environment,
                    logger,
                    storage
                });

                const configurationManager = new ConfigurationManager({
                    bootstrapConfiguration,
                    logger,
                    httpClient,
                    storage
                });

                const accessTokenProvider = new AccessTokenProvider(
                    accessStorage,
                    logger
                );

                try {
                    const sdkSessionConfiguration =
                        await configurationManager.getConfiguration(
                            logTransaction
                        );

                    const { services } = sdkSessionConfiguration;

                    const orchestrationClient = new OrchestrationClient({
                        orchestrationClientConfiguration:
                            services.orchestration.client,
                        logger,
                        tokenUpdater,
                        httpClient,
                        deviceGrantStorage,
                        sessionInfoStorage,
                        accountDelegationRefreshTokenStorage,
                        featureFlagsStorage,
                        accessStorage,
                        apiKey: clientApiKey
                    });

                    managers.orchestrationManager = new OrchestrationManager({
                        config: services.orchestration,
                        client: orchestrationClient,
                        accessTokenProvider,
                        logger
                    });

                    managers.deviceManager = new DeviceManager({
                        tokenUpdater,
                        logger,
                        deviceGrantStorage,
                        environmentConfiguration:
                            configurationManager.environmentConfiguration,
                        advertisingIdProvider,
                        deviceAttributeProvider,
                        orchestrationManager: managers.orchestrationManager,
                        devicePlatformId: services.commonValues.platformId
                    });

                    const tokenClient = new TokenClient({
                        tokenClientConfiguration: services.token.client,
                        platformId: services.commonValues.platformId,
                        logger,
                        httpClient
                    });

                    const tokenManager = new TokenManager({
                        apiKey: clientApiKey,
                        tokenManagerConfiguration: services.token,
                        tokenClient,
                        geoProvider,
                        logger,
                        accountDelegationRefreshTokenStorage,
                        storage: accessStorage,
                        deviceManager: managers.deviceManager,
                        deviceGrantStorage,
                        orchestrationManager: managers.orchestrationManager,
                        refreshSessionInfo: async (
                            innerLogTransaction: LogTransaction
                        ) => {
                            if (managers.sessionManager) {
                                await managers.sessionManager.getInfoFromServices(
                                    innerLogTransaction
                                );
                            }
                        }
                    });

                    tokenUpdater.tokenManager = tokenManager;

                    managers.tokenManager = tokenManager;

                    managers.telemetryManager = new TelemetryManager(
                        services.telemetry,
                        tokenManager,
                        new TelemetryClient(
                            services.telemetry.client,
                            logger,
                            httpClient
                        ),
                        logger
                    );

                    managers.subscriptionManager = new SubscriptionManager({
                        subscriptionManagerConfiguration: services.subscription,
                        subscriptionClient: new SubscriptionClient(
                            services.subscription.client,
                            logger,
                            httpClient
                        ),
                        logger,
                        accessTokenProvider
                    });

                    managers.sessionManager = new SessionManager({
                        logger,
                        tokenManager,
                        sessionInfoStorage,
                        featureFlagsStorage,
                        orchestrationClient
                    });

                    this.createManager({
                        managers,
                        logger,
                        httpClient,
                        storage,
                        services,
                        accessTokenProvider,
                        browserInfoProvider,
                        mediaCapabilitiesProvider,
                        platformMetricsProvider,
                        advertisingIdProvider,
                        geoProvider,
                        metadata: {
                            clientId,
                            environment
                        },
                        environmentConfiguration:
                            configurationManager.environmentConfiguration,
                        orchestrationClient,
                        sessionInfoStorage,
                        featureFlagsStorage,
                        bootstrapConfiguration,
                        deviceAttributeProvider
                    });

                    const metadata = {
                        clientId,
                        clientApiKey,
                        environment,
                        debugEnabled
                    };

                    const sdkSession = new SdkSession({
                        sdkSessionConfiguration,
                        accessStorage,
                        deviceGrantStorage,
                        accountDelegationRefreshTokenStorage,
                        sessionInfoStorage,
                        featureFlagsStorage,
                        logger,
                        geoProvider,
                        mediaCapabilitiesProvider,
                        platformMetricsProvider,
                        advertisingIdProvider,
                        managers,
                        metadata,
                        accessTokenProvider,
                        sdkInstanceId
                    });

                    const { deviceManager, telemetryManager, socketManager } =
                        managers;

                    tokenManager.on(
                        InternalEvents.TokenRefreshFailed,
                        (tokenRefreshFailure: TokenRefreshFailure) => {
                            logger.info(
                                SdkSession.toString(),
                                'Reauthorization Failure event.'
                            );
                            logger.error(
                                SdkSession.toString(),
                                tokenRefreshFailure.error
                            );
                            sdkSession.emit(
                                Events.ReauthorizationFailure,
                                new ReauthorizationFailure(
                                    tokenRefreshFailure.error
                                )
                            );
                        }
                    );

                    if (socketManager) {
                        tokenManager.once(
                            InternalEvents.AccessChanged,
                            (accessChangedEvent: AccessChangedEvent) => {
                                socketManager.init(
                                    accessChangedEvent.accessToken
                                );
                            }
                        );

                        socketManager.on(
                            SocketEvents.ageVerificationChanged,
                            (ageVerificationChangedEvent: unknown) => {
                                logger.info(
                                    SdkSession.toString(),
                                    'AgeVerification Changed event.'
                                );
                                sdkSession.emit(
                                    Events.AgeVerificationChanged,
                                    ageVerificationChangedEvent
                                );
                            }
                        );
                    }

                    tokenManager.on(
                        InternalEvents.AccessChanged,
                        (accessChangedEvent: AccessChangedEvent) => {
                            logger.info(
                                SdkSession.toString(),
                                'Access Changed event.'
                            );
                            sdkSession.emit(
                                Events.AccessChanged,
                                accessChangedEvent
                            );
                        }
                    );

                    sessionInfoStorage.on(
                        InternalEvents.SessionInfoChanged,
                        (sessionInfoChangedEvent: SessionInfoChangedEvent) => {
                            logger.info(
                                SdkSession.toString(),
                                'SessionInfo Changed event.'
                            );
                            sdkSession.emit(
                                Events.SessionInfoChanged,
                                sessionInfoChangedEvent
                            );
                        }
                    );

                    featureFlagsStorage.on(
                        Events.FeatureFlagsChanged,
                        (
                            featureFlagsChangedEvent: FeatureFlagsChangedEvent
                        ) => {
                            logger.info(
                                SdkSession.toString(),
                                'Feature Flags Changed event.'
                            );
                            sdkSession.emit(
                                Events.FeatureFlagsChanged,
                                featureFlagsChangedEvent
                            );
                        }
                    );

                    accountDelegationRefreshTokenStorage.on(
                        Events.AccountDelegationRefreshTokenChanged,
                        (token: string) => {
                            logger.info(
                                SdkSession.toString(),
                                'Account Delegation Refresh Token Changed event.'
                            );
                            sdkSession.emit(
                                Events.AccountDelegationRefreshTokenChanged,
                                token
                            );
                        }
                    );

                    telemetryManager.on(
                        InternalEvents.ValidationResultsReceived,
                        (validation: unknown) => {
                            logger.info(
                                SdkSession.toString(),
                                'Validation Results Received event.'
                            );
                            sdkSession.emit(
                                Events.ValidationResultsReceived,
                                validation
                            );
                        }
                    );

                    /**
                     *
                     * @desc handle notifying plugins on a created sdkSession
                     *
                     */
                    sdkPlugins.plugins.forEach((plugin) => {
                        if (plugin.onSdkSessionCreated) {
                            plugin.onSdkSessionCreated({
                                SdkSession,
                                sdkSession,
                                logger
                            });
                        }
                    });

                    sdkSession.dustEnabled =
                        services.telemetry.disabled === false;

                    if (sdkSession.dustEnabled) {
                        const { environmentConfiguration } = deviceManager;
                        const {
                            deviceRuntimeProfile,
                            deviceProfile,
                            platform
                        } = environmentConfiguration;
                        const deviceAttributes =
                            deviceAttributeProvider.getDeviceAttributes();
                        const {
                            manufacturer,
                            model,
                            operatingSystem,
                            modelFamily
                        } = deviceAttributes;

                        if (services.telemetry.extras.permitAppDustEvents) {
                            sdkSession.loggingApi.enableCustomDustEvents();
                        }

                        dustSink.initializeDustSink({
                            telemetryManager,
                            environment: {
                                device: {
                                    os:
                                        operatingSystem &&
                                        operatingSystem !== 'n/a'
                                            ? operatingSystem
                                            : deviceRuntimeProfile,
                                    model: model || deviceProfile,
                                    brand: manufacturer || undefined, // fall back to undefined instead of null
                                    modelFamily,
                                    platformId: services.commonValues.platformId
                                },
                                sdk: {
                                    version: VersionInfo.version,
                                    platform
                                }
                            },
                            application
                        });
                    } else {
                        dustSink.disableDustSink();

                        Object.values(managers).forEach((manager) => {
                            // @ts-expect-error - we're hacking into a private field
                            if (Check.assigned(manager.client)) {
                                // @ts-expect-error - we're hacking into a private field
                                manager.client.dustEnabled = false;
                            }
                        });
                    }

                    sdkSession.isEventsAtEdgeEnabled =
                        services.eventsAtEdgeDust.disabled === false;

                    return sdkSession;
                } catch (ex) {
                    // even if there is an error we want to try to capture this
                    dustLogUtility.captureError(ex);

                    throw ex;
                } finally {
                    // even if there is an error we want to try to capture this
                    dustLogUtility.log();
                }
            }
        });
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Initializes the SDK and restores previous state if it exists.
     * @note If the operation fails, it can be retried or call reset to
     * reset the state and start in an anonymous state.
     * @note this.initialized MUST come before we try to initialize the DustSink
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} An awaitable action that returns when it succeeds.
     *
     * @example <caption>Initializing the SDK and restoring the previous state if it exists.</caption>
     * sdkSession.initialize().then() => {
     *     console.log(sdkSession.isInitialized); // true
     * });
     *
     */
    public async initialize(): Promise<void>;

    @apiMethodDecorator()
    public async initialize(apiOptions?: unknown) {
        await this.internalInitialize(apiOptions as ApiOptions);
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Object} options
     * @param {String} options.userToken User token that can be used to initialize the SDK with an existing token access.
     * @desc Initializes the SDK with the provided userToken.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the operation has succeeded.
     * @note Retrieves previous AccessContext from local storage if it exists. Checks expiration and refreshes token if necessary. Otherwise, retrieves device grant (or creates it if first time) and exchanges for new AccessContext.
     *
     */
    public async initializeWithUserToken(options: {
        userToken: string;
    }): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object({
                userToken: Types.nonEmptyString
            })
        }
    })
    public async initializeWithUserToken(apiOptions: unknown) {
        await this.internalInitialize(apiOptions as ApiOptions);
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {IGNORE-PARAMS}
     * @desc Creates a class that contains the necessary data to initialize a SDK.SdkSession
     * @returns {Promise<SDK.InitializationState>}
     *
     */
    public async getInitializationState(): Promise<InitializationState>;

    @apiMethodDecorator({
        skipDustLogUtility: true
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async getInitializationState(apiOptions?: unknown) {
        this.throwErrorIfDisposed();

        const deviceGrant =
            this.deviceGrantStorage.getDeviceGrant() as DeviceGrant;
        const access = this.accessStorage.getAccess() as Access;
        const sessionInfo =
            (await this.sessionInfoStorage.getSessionInfo()) as SessionInfo;
        const accountDelegationRefreshToken =
            this.accountDelegationRefreshTokenStorage.getAccountDelegationRefreshToken();

        return new InitializationState({
            deviceGrant,
            access,
            sessionInfo,
            accountDelegationRefreshToken
        });
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {SDK.InitializationState} initializationState
     * @desc Given an `InitializationState` this will `initialize` the sdk with the provided state. Bypassing extra service calls.
     * @returns {Promise<Void>}
     *
     */
    public async initializeWithState(initializationState: InitializationState) {
        this.throwErrorIfDisposed();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                initializationState: Types.instanceStrict(InitializationState)
            };

            typecheck(this, 'initializeWithState', params, arguments);
        }

        if (this.isInitialized) {
            throw this.createAlreadyInitializedException();
        }

        const {
            deviceGrant,
            access,
            sessionInfo,
            accountDelegationRefreshToken
        } = initializationState;

        const storageWork = [
            this.deviceGrantStorage.saveDeviceGrant(deviceGrant),
            this.accessStorage.saveAccess(access),
            this.sessionInfoStorage.saveSessionInfo(sessionInfo)
        ];

        if (accountDelegationRefreshToken) {
            storageWork.push(
                this.accountDelegationRefreshTokenStorage.saveAccountDelegationRefreshToken(
                    accountDelegationRefreshToken
                )
            );
        } else {
            await this.accountDelegationRefreshTokenStorage.clear();
        }

        await Promise.all(storageWork);

        this.isInitialized = true;

        this.enableDustSink();
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Resets the SdkSession to an anonymous state.
     * Clears out all internal Storage providers, removes the current user
     * and resets to an anonymous state.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     * @example <caption>Resetting the SDK to an anonymous state.</caption>
     * sdkSession.reset().then() => {
     *     console.log('Reset SDK to original state');
     * });
     *
     */
    public async reset(): Promise<void>;

    @apiMethodDecorator()
    public async reset(apiOptions?: unknown) {
        this.throwErrorIfDisposed();

        return this.internalReset(apiOptions as ApiOptions);
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Reauthorizes the current access of the SDK.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @throws {AccountLoginFailedException} Unable to get the account grant for the credentials.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @returns {Promise<Void>}
     *
     * @example <caption>Reauthorizing the current access of the SDK.</caption>
     * sdkSession.reauthorize().then() => {
     *     console.log('success');
     * });
     *
     */
    public async reauthorize(): Promise<void>;

    @apiMethodDecorator({
        skipDustLogUtility: true,
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object({
                reason: Types.nonEmptyString.optional
            }).optional
        }
    })
    public async reauthorize(apiOptions?: unknown) {
        this.throwErrorIfDisposed();

        return this.internalReauthorize(apiOptions as ApiOptions);
    }

    /**
     *
     * @access public
     * @desc Returns a token appropriate for sharing with external services.
     * @note Returns the current access token. If the access token needs to be refreshed,
     * perform refresh before fulfilling the promise. Applications should not count on
     * this being a raw access token. The contract does not guarantee an access token.
     * Warning: Applications should not store this token, since the token
     * lifecycle is managed by the SDK. Call this method immediately before use.
     * Applications should not assume any use cases with this token,
     * or try to use it to hit BAM services directly.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String|undefined>}
     *
     * @example <caption>Getting the current access token.</caption>
     * sdkSession.getSessionToken().then((sessionToken) => {
     *     if (sessionToken) {
     *         console.log(sessionToken);
     *     } else {
     *         console.log('sessionToken is undefined');
     *     }
     * });
     *
     */
    @apiMethodDecorator()
    public async getSessionToken() {
        this.throwErrorIfDisposed();

        let sessionToken: string | undefined;

        this.logger.info(
            this.toString(),
            'Getting current session/access token.'
        );

        const accessToken = this.accessTokenProvider.getAccessToken();

        if (accessToken) {
            sessionToken = accessToken.token;
        } else {
            this.logger.info(
                this.toString(),
                'Current session/access token is not defined.'
            );
        }

        return sessionToken;
    }

    /**
     *
     * @access public
     * @desc Gets the access state of the user in a format that
     * can be used to rebuild the state in an external system.
     * @note Contains version information as well as access state data;
     * including the token, refreshToken, and access context state.
     * @returns {Promise<String>}
     *
     * @example <caption>Retrieving the access state.</caption>
     * sdkSession.getAccessState().then((accessState) => {
     *     console.log(accessState); // serialized AccessState
     * });
     *
     */
    @apiMethodDecorator()
    public async getAccessState() {
        this.throwErrorIfDisposed();

        const { logger } = this;

        logger.info(this.toString(), 'Get access state.');

        const accessState = JSON.stringify(
            this.managers.tokenManager.getAccessState()
        );

        return accessState;
    }

    /**
     *
     * @access public
     * @param {String} accessState - The serialized state retrieved from another
     * device via SdkSession#getAccessState
     * @desc Initializes the SDK and restores the state retrieved from another device.
     * @note If the operation fails, it can be retried or call SdkSession#reset to
     * reset the state and start in an anonymous state.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     * @example <caption>Restoring access state.</caption>
     * sdkSession.restoreAccessState(accessState).then() => {
     *     console.log(sdkSession.isInitialized); // true
     * });
     *
     */
    public async restoreAccessState(accessState: string): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            accessState: Types.nonEmptyString
        }
    })
    public async restoreAccessState(apiOptions: unknown) {
        this.throwErrorIfDisposed();

        const actualApiOptions = apiOptions as ApiOptions;

        const {
            logTransaction,
            args: [accessState]
        } = actualApiOptions;

        const { logger } = this;

        logger.info(
            this.toString(),
            'Restore access state from another device or SDK instance.'
        );

        if (this.isInitialized) {
            logger.warn(this.toString(), 'Already initialized.');

            const exception = this.createAlreadyInitializedException();

            throw exception;
        }

        await this.internalReset(actualApiOptions);
        await this.managers.tokenManager.restoreAccessState(
            accessState,
            logTransaction
        );

        this.isInitialized = true;

        this.enableDustSink();
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {String} grant
     * @param {String} provider - An unique key representing the authorization type
     * @desc Authorizes the session with an external account that was authenticated and created by an external provider.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     */
    public async authorize(grant: string, provider: string): Promise<void>;

    @apiMethodDecorator({
        skipDustLogUtility: true,
        paramTypes: __SDK_TYPECHECK__ && {
            grant: Types.nonEmptyString,
            provider: Types.nonEmptyString
        }
    })
    public async authorize(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        this.throwErrorIfDisposed();

        const {
            logTransaction,
            args: [grant, provider]
        } = apiOptions as ApiOptions;

        const accessContextState = new AccessContextState([provider]);
        const accountGrant = new AccountGrant({ assertion: grant });

        await this.managers.tokenManager.exchangeAccountGrant(
            accountGrant,
            accessContextState,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {SDK.Services.Token.DelegationToken} delegationToken - The `DelegationToken` object created by the application,
     * based on the type of token received.
     * @param {String} provider - A unique key representing the authorization type (i.e. 'espn').
     * @desc Authorizes the session with the identity contained within a delegation token provided by a separate sending device.
     * @note Delegation grants must be passed into the SDK from the application receiving the grant from the sender.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     * @example <caption>Working with delegation grants.</caption>
     * const grant = new SDK.Services.Token.RefreshTokenDelegation(refresh_token);
     * await sdkSession.assumeIdentity(grant, 'refresh-delegation');
     *
     */
    public async assumeIdentity(
        delegationToken: DelegationToken,
        provider: string
    ): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            delegationToken: Types.instanceStrict(DelegationToken),
            provider: Types.nonEmptyString
        }
    })
    public async assumeIdentity(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        this.throwErrorIfDisposed();

        const actualApiOptions = apiOptions as ApiOptions;

        const {
            logTransaction,
            args: [delegationToken, provider]
        } = actualApiOptions;

        this.logger.info(this.toString(), `Assume identity for "${provider}"`);

        await this.internalReset(actualApiOptions);

        const { tokenManager } = this.managers;

        const access = tokenManager.storage.getAccess();
        const accessContextState = new AccessContextState([provider]);

        // backfill the current access context device refresh token
        delegationToken.actor = access?.context.refreshToken;

        return await tokenManager.exchangeRequest(
            delegationToken,
            accessContextState,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {String} provider - An unique key representing the authorization type (i.e. 'espn').
     * @desc Tests whether the SDK is currently in an "authorized" state for the given authorization type.
     * @returns {Promise<Boolean>} A boolean indicating whether or not the SDK is
     * currently authorized for the given type of authorization.
     *
     * @example <caption>Checking the authorized status for a given provider.</caption>
     * sdkSession.isAuthorized('espn').then((isAuthorized) => {
     *     console.log(isAuthorized); // true or false
     * });
     *
     */
    public async isAuthorized(provider: string): Promise<boolean>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            provider: Types.nonEmptyString
        }
    })
    public async isAuthorized(apiOptions: unknown) {
        this.throwErrorIfDisposed();

        const {
            logTransaction,
            args: [provider]
        } = apiOptions as ApiOptions;

        const { logger } = this;

        logger.info(
            this.toString(),
            `Check authorization state for "${provider}"`
        );

        const { sessionManager, tokenManager } = this.managers;

        const hasAccessMode = tokenManager.hasAccessMode(provider);

        if (hasAccessMode) {
            return hasAccessMode;
        }

        // Checks session info for an account in the case that a user gets logged
        // in by other means like off device refresh through a socket.
        const sessionInfo = await sessionManager.getInfo(logTransaction);

        if (sessionInfo && sessionInfo.account) {
            return true;
        }

        return false;
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 3.1.0
     * @desc Gets information about the current session.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Session.SessionInfo>}
     *
     * @example <caption>Retrieving the session information.</caption>
     * sdkSession.getSessionInfo().then((sessionInfo) => {
     *     console.log(sessionInfo.device);
     *     console.log(sessionInfo.location);
     *     console.log(sessionInfo.entitlements);
     * });
     *
     */
    public async getSessionInfo(): Promise<SessionInfo>;

    @apiMethodDecorator()
    public async getSessionInfo(apiOptions?: unknown) {
        this.throwErrorIfDisposed();

        const { logTransaction } = apiOptions as ApiOptions;

        this.logger.info(this.toString(), 'Get session information.');

        return await this.managers.sessionManager.getInfo(logTransaction);
    }

    /**
     *
     * @access public
     * @since 4.5.0
     * @param {String} featureId - The experiment's feature ID.
     * @desc Obtains the user's experiment assignment for a given feature ID.
     * The application is responsible for knowing the feature IDs of interest and how to
     * interpret the assignment in the application context.
     * @note The assignment should be obtained from the SessionInfo object's experiments property.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Session.SessionExperimentAssignment>|null>}
     *
     * @example <caption>Experiment assignment usage example.</caption>
     * sdkSession.getExperimentAssignment('button-placement-feature-id').then((sessionExperimentAssignment) => {
     *     if (sessionExperimentAssignment.variantId === 'left-button') {
     *         // render button on the left
     *     } else {
     *         // render button in the usual position
     *     }
     * });
     *
     */
    public async getExperimentAssignment(
        featureId: string
    ): Promise<SessionExperimentAssignment | null>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            featureId: Types.nonEmptyString
        }
    })
    public async getExperimentAssignment(apiOptions: unknown) {
        this.throwErrorIfDisposed();

        const {
            logTransaction,
            args: [featureId]
        } = apiOptions as ApiOptions;

        const { logger } = this;

        logger.info(
            this.toString(),
            `Get experiment assignment for feature ID: ${featureId}.`
        );

        const sessionInfo = await this.managers.sessionManager.getInfo(
            logTransaction
        );
        const experiments = sessionInfo?.experiments;
        const feature = experiments?.[featureId];

        if (Check.not.assigned(feature)) {
            logger.warn(
                this.toString(),
                `Cannot find experiment assignment for feature ID: ${featureId}.`
            );

            return null;
        }

        return feature;
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Creates a code to show the end user when an error is displayed.
     * @note This code can be used to find a customer's current context (such as
     * device ID).
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} A Customer Service Support Code.
     *
     */
    public async createCustomerSupportCode(): Promise<string>;

    @apiMethodDecorator()
    public async createCustomerSupportCode(apiOptions?: unknown) {
        this.throwErrorIfDisposed();

        const { logTransaction } = apiOptions as ApiOptions;

        const accessToken =
            this.accessTokenProvider.getAccessToken() as AccessToken;

        // test if the plugin injected this manager
        if (this.managers.customerServiceManager) {
            return await this.managers.customerServiceManager.createSupportCode(
                accessToken,
                logTransaction
            );
        }

        const reasons = [
            new ErrorReason(
                '',
                `${this.toString()} targeted build does not have CustomerService plugin.`
            )
        ];
        const exceptionData = ExceptionReference.common.invalidState;
        const exception = new ServiceException({
            reasons,
            exceptionData
        });

        throw exception;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc A boolean indicating whether or not the SDK is currently sending logs for Events At Edge.
     *
     */
    public get isEventsAtEdgeEnabled() {
        this.throwErrorIfDisposed();

        const edgeSink = this.logger.sinks.find((sink) =>
            Check.instanceStrict(sink, EdgeSink)
        ) as EdgeSink;

        return getSafe(() => edgeSink.isEnabled, false);
    }

    /**
     *l
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc Allows turning on/off the SDK's logs for Events At Edge sink.
     *
     */
    public set isEventsAtEdgeEnabled(value: boolean) {
        this.throwErrorIfDisposed();

        if (value) {
            this.enableEdgeSink();
        } else {
            this.disableEdgeSink();
        }
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @desc Returns the feature flags object for the current session.
     * @returns {Promise<Object|undefined>}
     *
     */
    public async getFeatureFlags() {
        this.throwErrorIfDisposed();

        const featureFlags = await this.featureFlagsStorage.getFeatureFlags();

        return featureFlags;
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @desc Gets the account delegation refresh token
     * @returns {String|null}
     *
     */
    public getAccountDelegationRefreshToken() {
        this.throwErrorIfDisposed();

        const token =
            this.accountDelegationRefreshTokenStorage.getAccountDelegationRefreshToken();

        return token;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {IGNORE-PARAMS}
     * @throws {SDK.Services.Exception.ServiceException<InvalidStateException>}
     * @returns {Promise<Void>} A promise that completes when the operation has succeeded.
     *
     */
    private async internalInitialize(apiOptions: unknown) {
        this.throwErrorIfDisposed();

        const { logger } = this;

        logger.info(this.toString(), 'Initialize.');

        if (this.isInitialized) {
            logger.error(this.toString(), 'Already initialized.');

            const exception = this.createAlreadyInitializedException();

            throw exception;
        }

        try {
            await this.internalReauthorize(apiOptions);

            logger.info(this.toString(), 'Successfully initialized.');

            this.isInitialized = true;

            this.enableDustSink();
        } catch (ex) {
            logger.error(`${this.toString()}.initialize()`, ex);

            logger.info(
                `${this.toString()}.initialize()`,
                'Dispatch ReauthorizationFailure event.'
            );

            this.emit(
                Events.ReauthorizationFailure,
                new ReauthorizationFailure(ex as ServiceException)
            );

            throw ex;
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} apiOptions
     * @desc The actual implementation of `reset` but without the api decorator so internal methods that call this can pass along their own logTransaction instance
     *
     */
    private async internalReset(options: ApiOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'internalReset', params, arguments);
        }

        const { logTransaction } = options;

        this.logger.info(this.toString(), 'Reset SDK to an anonymous state.');

        const { sessionManager, socketManager, tokenManager } = this.managers;

        socketManager?.stopPing();

        // clear SessionInfoStorage
        await sessionManager?.storage.clear();

        // clears token manager storage, establish an anonymous state, then exchanges anonymous device grant
        await tokenManager?.logout(logTransaction);

        socketManager?.startPing();

        this.isInitialized = true;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} apiOptions
     * @param {Array} apiOptions.args
     * @param {String} apiOptions.args[0].reason
     * @param {String} apiOptions.args[0].userToken
     * @returns {Promise<Void>}
     *
     */
    private async internalReauthorize(apiOptions: unknown) {
        const { logTransaction, args: [options] = [] } =
            apiOptions as ApiOptions;

        const { reason, userToken } = options || {};

        const { dustEnabled, logger } = this;
        const data = reason ? { reason } : undefined;

        logger.info(this.toString(), 'Reauthorize the current access.');

        const endpointKey = 'reauthorize';

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DustUrn[endpointKey],
            data,
            endpointKey,
            logTransaction
        });

        try {
            const { deviceManager, orchestrationManager, tokenManager } =
                this.managers;

            const hadDeviceGrantInMemory =
                !!this.deviceGrantStorage.getDeviceGrant();

            // deviceManager.getDeviceGrant() will create one if it doesn't exist in memory
            const deviceGrant = await deviceManager.getDeviceGrant(
                { userToken },
                logTransaction
            );
            const accessToken = tokenManager.getAccessToken();
            const accessTokenExists = Check.assigned(accessToken);

            // if it didn't have the device grant in memory, then it created one
            const deviceGrantWasCreated =
                hadDeviceGrantInMemory === false && Check.assigned(deviceGrant);

            // if it created a new device grant and access token exists then
            // getting the device grant has properly setup the sdk's device access token and session info
            if (deviceGrantWasCreated && accessTokenExists) {
                return;
            }

            // Can only refresh if not using an external token like huluUserToken
            const canRefreshToken = accessTokenExists && !userToken;

            // access token exists, then refresh it
            if (canRefreshToken) {
                await tokenManager.refreshAccessToken({
                    forceRefresh: true,
                    reason,
                    logTransaction
                });
            } else {
                // if not, then exchange device grant for accessToken

                await tokenManager.exchangeDeviceGrant(
                    { deviceGrant, userToken },
                    logTransaction
                );
            }

            const attributes =
                this.deviceAttributeProvider.getDeviceAttributes();
            const advertisingId = this.advertisingIdProvider.getId();

            DeviceAttributeProvider.validateAttributes(
                attributes,
                advertisingId
            );

            await orchestrationManager.updateDeviceOperatingSystem(
                attributes,
                logTransaction
            );
        } catch (ex) {
            dustLogUtility.captureError(ex);

            throw ex;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @returns {ServiceException}
     *
     */
    private createAlreadyInitializedException() {
        const reasons = [
            new ErrorReason('', `${this.toString()} already initialized.`)
        ];
        const exceptionData = ExceptionReference.common.invalidState;
        const exception = new ServiceException({ reasons, exceptionData });

        return exception;
    }

    /**
     *
     * @access private
     * @desc sets up Dust logging if it is enabled and empties the sink
     *
     */
    private enableDustSink() {
        const dustSink = this.logger.sinks.find((sink) => {
            return Check.instanceStrict(sink, DustSink) ? sink : null;
        }) as DustSink;

        if (dustSink?.initialized) {
            dustSink.enableDustSink();
            dustSink.emptySink();
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @desc sets up Dust logging over Edge if it is enabled and empties the sink
     *
     */
    private enableEdgeSink() {
        const edgeSink = this.logger.sinks.find((sink) =>
            Check.instanceStrict(sink, EdgeSink)
        ) as EdgeSink;

        if (edgeSink && this.socketApi) {
            if (!edgeSink.initialized) {
                edgeSink.initializeEdgeSink({
                    socketApi: this.socketApi
                });
            }

            edgeSink.enableEdgeSink();
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @desc disables Dust logging over Edge
     *
     */
    private disableEdgeSink() {
        const edgeSink = this.logger.sinks.find((sink) =>
            Check.instanceStrict(sink, EdgeSink)
        ) as EdgeSink;

        if (edgeSink) {
            edgeSink.disableEdgeSink();
        }
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @desc Throws an error if the session has been disposed.
     * @throws {SDK.Services.Exception.ServiceException<InvalidStateException>}
     *
     */
    private throwErrorIfDisposed() {
        if (this.isDisposed) {
            const reasons = [
                new ErrorReason('', `${this.toString()} has been disposed.`)
            ];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @desc Free resources and disposes the session.
     *
     */
    public dispose() {
        this.isDisposed = true;

        const { socketManager, telemetryManager, tokenManager } = this.managers;

        if (telemetryManager) {
            telemetryManager.dispose();
        }

        if (tokenManager) {
            tokenManager.clearRetryRefresh();
        }

        if (socketManager) {
            socketManager.closeExistingSocket();
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return SdkSession.toString();
    }

    /**
     *
     * @access private
     *
     */
    public static override toString() {
        return 'SDK.SdkSession';
    }
}

SdkSession.plugins = [];
