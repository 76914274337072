/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.SubscriptionType
 * @desc The type of subscription used for this piece of media.
 *
 * @property {String} adsRequired - Since `18.0.0` - The service returns "ADS_REQUIRED".
 * @property {String} noAdsRequired - Since `18.0.0` - The service returns "NO_ADS_REQUIRED".
 *
 * @example
 * SubscriptionType.adsRequired // 'ADS_REQUIRED'
 *
 */
export enum SubscriptionType {
    adsRequired = 'ADS_REQUIRED',
    noAdsRequired = 'NO_ADS_REQUIRED'
}

/**
 *
 * @enum
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.AssetInsertionStrategyQos
 * @desc The asset insertion strategy used for this piece of media.
 *
 * @property {String} ssai - Since `18.0.0` - The service returns "SSAI".
 * @property {String} sgai - Since `18.0.0` - The service returns "SGAI".
 *
 * @example
 * AssetInsertionStrategyQos.ssai // 'SSAI'
 *
 */
export enum AssetInsertionStrategyQos {
    ssai = 'SSAI',
    sgai = 'SGAI'
}
