/**
 *
 * @module mediaPlayheadStatus
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.Media.MediaPlayheadStatus
 * @desc Provides the status of the playhead retrieval.
 *
 * @property {String} Success - A previous playhead position was found for the associated media and user.
 * @property {String} NoPlayhead - No playhead position was found for the associated media and user.
 * User has never played video before or cleared bookmarks.
 * @property {String} Unavailable - Unable to retrieve the playhead position for the associated media and user.
 * There was an error retrieving the playhead position.
 *
 */
export default keyMirror({
    Success: null,
    NoPlayhead: null,
    Unavailable: null
});
