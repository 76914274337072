/**
 *
 * @module entry
 * @desc {SDK.Account} entry point.
 *
 */

import AccountApi from './accountApi';
import AccountManager from './accountManager';
import Gender from './gender';

/**
 *
 * @typedef {Object} SDK.Account
 *
 * @property {AccountApi} AccountApi
 * @property {AccountManager} AccountManager
 * @property {SDK.Account.Gender} Gender
 *
 */
export default {
    AccountApi,
    AccountManager,
    Gender
};
