/**
 *
 * @module glimpseEvents
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/user-activity.md#known-events
 *
 */

import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import GlimpseEvent from './glimpseEvent';

const GlimpseUrnReference = DustUrnReference.userActivity.glimpse;

/**
 *
 * @access public
 * @type {Object<String, SDK.UserActivity.Glimpse.GlimpseEvent>}
 * @desc contains a list of predefined Glimpse events
 * @note the IIFE is required as the application developer will not be expecting to invoke this method
 *
 */
export default {
    appLaunched: (() => new GlimpseEvent(GlimpseUrnReference.appLaunched))(),
    backgroundPlaybackStarted: (() =>
        new GlimpseEvent(GlimpseUrnReference.backgroundPlaybackStarted))(),
    backgroundPlaybackExited: (() =>
        new GlimpseEvent(GlimpseUrnReference.backgroundPlaybackExited))(),
    contentSelected: (() =>
        new GlimpseEvent(GlimpseUrnReference.contentSelected))(),
    searchViewLoaded: (() =>
        new GlimpseEvent(GlimpseUrnReference.searchViewLoaded))(),
    playbackSelected: (() =>
        new GlimpseEvent(GlimpseUrnReference.playbackSelected))(),
    playbackExited: (() =>
        new GlimpseEvent(GlimpseUrnReference.playbackExited))(),
    purchaseCompleted: (() =>
        new GlimpseEvent(GlimpseUrnReference.purchaseCompleted))(),
    viewLoaded: (() => new GlimpseEvent(GlimpseUrnReference.viewLoaded))()
};
