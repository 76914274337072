/**
 *
 * @module providerException
 *
 */

import ErrorReason from './errorReason';
import ServiceException from './serviceException';

/**
 *
 * @desc An unexpected error occurred with a platform provider implementation.
 *
 */
export default class ProviderException extends ServiceException {
    /**
     *
     * @param {String} message - Human-readable description of the error.
     *
     */
    public constructor(message: string) {
        const reasons = [new ErrorReason('', message)];
        const exceptionData = {
            name: 'ProviderException',
            message
        };

        const options = { reasons, exceptionData };

        super(options);
    }

    /**
     *
     * @access public
     * @returns {String}
     *
     */
    public static get typeName() {
        return 'ProviderException';
    }

    /**
     *
     * @access public
     * @desc A brief message describing the exception
     * @returns {String}
     *
     */
    public static get message() {
        return 'An unexpected error occurred with a platform provider implementation.';
    }
}
