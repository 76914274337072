/**
 *
 * @module mediaPlayhead
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import MediaPlayheadStatus from './mediaPlayheadStatus';

/**
 *
 * @since 4.0.0
 * @desc Represents the position of the associated media when it was last viewed by the current user.
 *
 */
export default class MediaPlayhead {
    /**
     *
     * @param {Object} options
     * @param {Number} [options.position]
     * @param {String} [options.lastUpdated]
     * @param {SDK.Services.Media.MediaPlayheadStatus} options.status
     * @param {String} [options.contentId]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    position: Types.number.optional,
                    lastUpdated: Types.dateString.optional,
                    status: Types.in(MediaPlayheadStatus),
                    contentId: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { position, lastUpdated, status, contentId } = options;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Number|undefined}
         * @desc Gets the position of the playhead in the last session for the associated media.
         *
         */
        this.position = position;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Gets the timestamp when the playhead was last recorded.
         *
         */
        this.lastUpdated = lastUpdated;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {SDK.Services.Media.MediaPlayheadStatus}
         * @desc Gets or sets the status of retrieving the last playhead.
         *
         */
        this.status = status;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         *
         */
        this.contentId = contentId;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaPlayhead';
    }

    // #endregion
}
