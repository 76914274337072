/**
 *
 * @module httpMethod
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.HttpMethod
 * @desc Defines the different types of http methods.
 *
 * @property {String} get - Since `4.0.0`.
 * @property {String} post - Since `4.0.0`.
 * @property {String} put - Since `4.0.0`.
 * @property {String} delete - Since `4.0.0`.
 * @property {String} patch - Since `4.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.HttpMethod.get // 'get'
 *
 */
export default keyMirror({
    get: null,
    post: null,
    put: null,
    delete: null,
    patch: null
});
