/**
 *
 * @module createAccountResult
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 *
 */
export default class CreateAccountResult {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public accountId: string;

    /**
     *
     * @param {String} accountId
     *
     */
    public constructor(accountId: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accountId: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.accountId = accountId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Account.CreateAccountResult';
    }
}
