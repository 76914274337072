/**
 *
 * @module configurationClientEndpoint
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Configuration.ConfigurationClientEndpoint
 *
 * @property {String} getConfiguration
 *
 */
export default keyMirror({
    getConfiguration: null
});
