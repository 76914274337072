/**
 *
 * @module tokenRequest
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Information required by TokenClient to exchange a token.
 *
 */
export default class TokenRequest {
    /**
     *
     * @param {Object} options
     * @param {String} options.grantType - The grant type of the token that will be exchanged.
     * @param {Object} options.tokenData - The parameters that will be added to the token.
     * @param {Number} options.latitude - The latitude of the device.
     * @param {Number} options.longitude - The longitude of the device.
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    grantType: Types.nonEmptyString,
                    tokenData: Types.nonEmptyObject,
                    latitude: Types.number,
                    longitude: Types.number
                })
            };

            typecheck(this, params, arguments);
        }

        const { grantType, tokenData, latitude, longitude } = options;

        /**
         *
         * @access public
         * @type {String}
         *
         */
        this.grantType = grantType;

        /**
         *
         * @access public
         * @type {Object}
         *
         */
        this.tokenData = tokenData;

        /**
         *
         * @access public
         * @type {Number}
         *
         */
        this.latitude = latitude;

        /**
         *
         * @access public
         * @type {Number}
         *
         */
        this.longitude = longitude;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.TokenRequest';
    }

    // #endregion
}
