/**
 *
 * @module errorReason
 *
 */

/**
 *
 * @desc information regarding exception
 *
 */
export default class ErrorReason {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public code: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public description: string;

    /**
     *
     * @param {String} code - Reason code. Can be used by the application developer to look up localized error messages.
     * @param {String} [description=''] - Additional error information (optional).
     *
     */
    public constructor(code: string, description = '') {
        this.code = code;

        this.description = description;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Exception.ErrorReason';
    }
}
