/**
 *
 * @module insertionMode
 * @see https://github.com/STRML/keyMirror
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.InsertionMode
 *
 * @property {String} SGAI - Since `18.0.0`.
 * @property {String} SSAI - Since `18.0.0`.
 *
 * @example
 * InsertionMode.SGAI // 'SGAI'
 *
 */
export default keymirror({
    SGAI: null,
    SSAI: null
});
