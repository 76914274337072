/**
 *
 * @module invoiceManager
 *
 */
import { Types, typecheck } from '@dss/type-checking';

import InvoiceClient from '../services/invoice/invoiceClient';
import InvoiceManagerConfiguration from '../services/configuration/invoiceManagerConfiguration';
import AccessTokenProvider from '../token/accessTokenProvider';
import Logger from '../logging/logger';
import LogTransaction from '../logging/logTransaction';
import type AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @since 4.12.0
 *
 */
export default class InvoiceManager {
    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Services.Configuration.InvoiceManagerConfiguration}
     *
     */
    private config: InvoiceManagerConfiguration;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {SDK.Services.Invoice.InvoiceClient}
     *
     */
    public client: InvoiceClient;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.InvoiceManagerConfiguration} options.config
     * @param {SDK.Services.Invoice.InvoiceClient} options.client
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     *
     */
    public constructor(options: {
        config: InvoiceManagerConfiguration;
        client: InvoiceClient;
        logger: Logger;
        accessTokenProvider: AccessTokenProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(InvoiceManagerConfiguration),
                    client: Types.instanceStrict(InvoiceClient),
                    logger: Types.instanceStrict(Logger),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, logger, accessTokenProvider } = options;

        this.config = config;

        this.client = client;

        this.logger = logger;

        this.accessTokenProvider = accessTokenProvider;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {String} link
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Invoice.Invoice>>}
     *
     */
    public async getInvoice(link: string, logTransaction: LogTransaction) {
        const { accessToken } = this;

        return await this.client.getInvoice({
            link,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {String} pageToken
     * @param {Number} pageSize
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Invoice.PaginatedInvoicesResponse>>}
     *
     */
    public async getPaginatedInvoices(
        pageToken: string,
        pageSize: number,
        logTransaction: LogTransaction
    ) {
        const { accessToken } = this;

        return await this.client.getPaginatedInvoices({
            pageToken,
            pageSize,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken(): AccessToken {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Invoice.InvoiceManager';
    }
}
