/**
 *
 * @module thumbnailSet
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Presentation from './presentation';

import { ThumbnailResolution } from './../../media/enums';

/**
 *
 *
 */
export default class ThumbnailSet {
    /**
     *
     * @param {Object} options
     * @param {String<SDK.Media.ThumbnailResolution>} options.resolution
     * @param {Number} options.thumbnailWidth
     * @param {Number} options.thumbnailHeight
     * @param {Number} options.intervalMilliseconds
     * @param {Number} options.totalBytes
     * @param {Array<SDK.Services.Media.Presentation>} options.presentations
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    resolution: Types.in(ThumbnailResolution),
                    thumbnailWidth: Types.number,
                    thumbnailHeight: Types.number,
                    intervalMilliseconds: Types.number,
                    totalBytes: Types.number,
                    presentations: Types.array.of.instanceStrict(Presentation)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            resolution,
            thumbnailWidth,
            thumbnailHeight,
            intervalMilliseconds,
            totalBytes,
            presentations
        } = options;

        /**
         *
         * @access public
         * @since 4.4.0
         * @type {String<SDK.Media.ThumbnailResolution>}
         * @desc The friendly name of the resolution.
         *
         */
        this.resolution = resolution;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The x resolution for each thumbnail
         *
         */
        this.thumbnailWidth = thumbnailWidth;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The y resolution for each thumbnail
         *
         */
        this.thumbnailHeight = thumbnailHeight;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The amount of media time between each thumbnail
         *
         */
        this.intervalMilliseconds = intervalMilliseconds;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The total download size of all thumbnails at this resolution. This is the sum of the sizes of all of
         * the spritesheets or all of the bifs supplied for a given resolution
         *
         */
        this.totalBytes = totalBytes;

        /**
         *
         * @access public
         * @type {Array<SDK.Services.Media.Presentation>}
         * @desc A list of presentations for this resolution
         *
         */
        this.presentations = presentations;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.ThumbnailSet';
    }

    // #endregion
}
