/**
 *
 * @module dustPayload
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import DustServerPayload from './dustServerPayload';
import DustClientPayload from './dustClientPayload';
import TelemetryPayload from '../telemetry/telemetryPayload';

/**
 *
 * @access protected
 * @implements {TelemetryPayload}
 * @since 3.4.0
 * @desc contains either client and/or server information for this event
 *
 */
export default class DustPayload extends TelemetryPayload {
    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Internal.Dust.DustServerPayload} [options.server={}]
     * @param {SDK.Services.Internal.Dust.DustClientPayload} options.client
     *
     */
    public constructor(options: {
        server?: DustServerPayload;
        client: DustClientPayload;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            // eslint-disable-next-line no-inner-declarations
            function checkServer(server: DustServerPayload | unknown) {
                if (Check.nonEmptyObject(server)) {
                    return Check.instanceStrict(server, DustServerPayload);
                }

                return Check.object(server);
            }

            const params = {
                options: Types.object({
                    server: Types.custom(checkServer).optional,
                    //server: Types.instanceStrict(DustServerPayload).or.object().optional,
                    client: Types.instanceStrict(DustClientPayload)
                })
            };

            typecheck(this, params, arguments);
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Dust.DustPayload';
    }
}
