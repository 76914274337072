/**
 *
 * @module mediaFetchError
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.MediaFetchError
 * @desc Defines the different types errors for media fetch.
 *
 * @property {String} ageNotVerified - Since `9.0.0` - The age for the users profile has not been verified and needs to be before proceeding.
 * @property {String} ageNotVerifiedKr - Since `12.0.0` - The age for the users profile has not been verified and needs to be before proceeding.
 * The user is located in South Korea.
 * @property {String} authenticationExpired - Since `4.0.0` - Authentication has expired and needs to be updated before proceeding.
 * @property {String} authorizationExpired - Since `4.0.0` - DSS user token expired.
 * @property {String} blackout - Since `4.0.0` - Failure due to user being blacked out from watching content.
 * @property {String} concurrency - Since `4.0.0` - Concurrency violation.
 * @property {String} contentNotAvailable - Since `4.0.0` - Failure due to content no longer being available.
 * @property {String} locationAcquisitionError - Since `9.0.0` - Unable to get user location due to location service being disabled.
 * NOTE: Only applicable on certain platforms.
 * @property {String} locationNotAllowed - Since `9.0.0` - User is not allowed to use the app in their current location.
 * Some products prohibit the use of the app in certain locations. NOTE: Only applicable for certain products.
 * @property {String} loginRequired - Since `9.0.0` - The requested media can only be viewed after authentication.
 * @property {String} mediaNotAllowed - Since `9.0.0` - The current user is not allowed to access the media for the requested license.
 * @property {String} networkFailure - Since `9.0.0` - Any failure due to a network related problem.
 * @property {String} notEntitled - Since `4.0.0` - Failure due to user not being entitled to watch content.
 * @property {String} parentalControlsRestricted - Since `9.0.0` - The current user is not allowed to access the media
 * due to parental controls restrictions on the profile.
 * @property {String} pcon - Since `4.0.0` - Failure due to user being locked out of content due to parental controls.
 * @property {String} pinExpired - Since `9.0.0` - The profile pin has expired and needs to be updated before proceeding.
 * @property {String} profileMissing - Since `9.0.0` - There was no active profile in the token or the service was unable to use it.
 * @property {String} profilePinMissing - Since `9.0.0` - The request requires a pin to be set on the profile but no pin has been set.
 * @property {String} requestBlocked - Since `9.0.0` - Device IP address is blocked.
 * @property {String} requestTemporarilyBlocked - Since `9.0.0` - Device IP address is temporarily blocked.
 * @property {String} temporarilyThrottled - Since `9.0.0` - Device IP address is temporarily throttled.
 * @property {String} temporarilyUnavailable - Since `9.0.0` - Server is too busy and rejected request.
 * @property {String} unexpectedError - Since `9.0.0` - An unexpected error has occurred.
 * @property {String} upgradeRequired - Since `9.0.0` - The service received a `X-BAMTech-R21-Unsupported` header and
 * determined this profile did not meet the requirements to play the requested media.
 * @property {String} unknown - Since `4.0.0` - All other errors.
 *
 * @example
 * SDK.Services.QualityOfService.MediaFetchError.networkFailure // 'networkFailure'
 *
 */
export default keyMirror({
    ageNotVerified: null,
    ageNotVerifiedKr: null,
    authenticationExpired: null,
    authorizationExpired: null,
    blackout: null,
    concurrency: null,
    contentNotAvailable: null,
    locationAcquisitionError: null,
    locationNotAllowed: null,
    loginRequired: null,
    mediaNotAllowed: null,
    networkFailure: null,
    notEntitled: null,
    parentalControlsRestricted: null,
    pcon: null,
    pinExpired: null,
    profileMissing: null,
    profilePinMissing: null,
    requestBlocked: null,
    requestTemporarilyBlocked: null,
    temporarilyThrottled: null,
    temporarilyUnavailable: null,
    unexpectedError: null,
    upgradeRequired: null,
    unknown: null
});
