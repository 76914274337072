/**
 *
 * @module fairPlayCertificateStorage
 *
 */

import CertificateStorage from './certificateStorage';
import DrmType from '../services/media/drmType';
import type CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import type Logger from '../logging/logger';

/**
 *
 * @since 3.2.0
 * @desc Provides a storage mechanism for storing a Fairplay Certificate.
 *
 */
export default class FairPlayCertificateStorage extends CertificateStorage {
    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        storage: CoreStorageProvider;
        logger: Logger;
    }) {
        super({ ...options, drmType: DrmType.FAIRPLAY });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.FairPlayCertificateStorage';
    }
}
