/**
 *
 * @module temporaryGrant
 *
 */

import Grant from './grant';

/**
 *
 * @desc A temporary grant, indicating the operation failed due to an
 * internal service error. This grant can be used to access content temporarily.
 *
 */
export default class TemporaryGrant extends Grant {
    /**
     *
     * @param {String} grantType
     * @param {String} assertion
     *
     */
    constructor(grantType, assertion) {
        super(grantType, assertion);

        /**
         *
         * @access public
         * @type {String}
         * @desc Key used to lookup the subject_token_type value needed during token exchange.
         *
         */
        this.subjectTokenTypeKey = 'temporary';

        /**
         *
         * @access public
         * @type {Array<SDK.Services.Exception>}
         * @desc Gets a collection of errors responsible for why the temporary grant was provided.
         *
         */
        this.errors = [];
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.TemporaryGrant';
    }

    // #endregion
}
