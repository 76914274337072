/**
 *
 * @module streamingType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.StreamingType
 *
 * @property {String} VOD - Since `18.0.0`.
 * @property {String} LIVE - Since `18.0.0`.
 *
 * @example
 * StreamingType.VOD // 'VOD'
 *
 */
export default keymirror({
    VOD: null,
    LIVE: null
});
