/**
 *
 * @module accessToken
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Provides the interface used to exchange tokens to provide access to service requests.
 *
 */
export default class AccessToken {
    /**
     *
     * @param {String} token
     * @param {String} [region=null]
     *
     */
    constructor(token, region = null) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                token: Types.nonEmptyString,
                region: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @param {String} token
         * @desc The access token issued by the authorization server.
         *
         */
        this.token = token;

        /**
         *
         * @access public
         * @since 4.6.0
         * @param {String|null} region
         * @desc The region where the accessToken was generated.
         *
         */
        this.region = region;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.AccessToken';
    }

    // #endregion
}
