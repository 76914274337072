/**
 *
 * @module entry
 * @desc {SDK.Services.Account} entry point.
 *
 */

import Account from './account';
import AccountClient from './accountClient';
import AccountGrant from './accountGrant';
import GetAccountResult from './getAccountResult';
import Identity from './identity';
import UserProfile from './userProfile';
import CreateAccountResult from './createAccountResult';
import OnboardAccountToStarRequest from './onboardAccountToStarRequest';
import OnboardProfileToStarRequest from './onboardProfileToStarRequest';
import UpdateProtectProfileCreationWithActionGrantRequest from './updateProtectProfileCreationWithActionGrantRequest';

/**
 *
 * @typedef {Object} SDK.Services.Account
 *
 * @property {Account} Account
 * @property {AccountClient} AccountClient
 * @property {AccountGrant} AccountGrant
 * @property {GetAccountResult} GetAccountResult
 * @property {Identity} Identity
 * @property {UserProfile} UserProfile
 * @property {CreateAccountResult} CreateAccountResult
 * @property {OnboardAccountToStarRequest} OnboardAccountToStarRequest
 * @property {OnboardProfileToStarRequest} OnboardProfileToStarRequest
 * @property {UpdateProtectProfileCreationWithActionGrantRequest} UpdateProtectProfileCreationWithActionGrantRequest
 *
 */
export default {
    Account,
    AccountClient,
    AccountGrant,
    GetAccountResult,
    Identity,
    UserProfile,
    CreateAccountResult,
    OnboardAccountToStarRequest,
    OnboardProfileToStarRequest,
    UpdateProtectProfileCreationWithActionGrantRequest
};
