/**
 *
 * @module baseApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import LogTransaction from './logging/logTransaction';
import Logger from './logging/logger';

/**
 *
 * @access protected
 * @since 13.0.0
 *
 */
export default class BaseApi {
    /**
     *
     * @access protected
     * @since 13.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: { logger: Logger }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { logger } = options;

        this.logger = logger;
    }

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {Object} options
     * @param {String} options.urn - Api dust urn associated with the `options.action`.
     * @param {String} options.file - The namespace (`Class.toString()`) of the caller.
     * @param {Function} options.action - The function to call as a unit of work and wrap with a `LogTransaction`.
     * @returns {Promise<T>} The result of executing the `action`.
     *
     */
    public async wrapLogTransaction<T>(options: {
        urn: string;
        file: string;
        action: () => Promise<T>;
    }): Promise<T> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    urn: Types.nonEmptyString,
                    file: Types.nonEmptyString,
                    action: Types.function
                })
            };

            typecheck(this, 'wrapLogTransaction', params, arguments);
        }

        const { urn, file, action } = options;

        const { logger } = this;

        return LogTransaction.wrapLogTransaction({
            urn,
            file,
            logger,
            action
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.BaseApi';
    }
}
