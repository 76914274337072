/**
 *
 * @module entry
 * @desc {SDK.Internal.Telemetry} entry point.
 *
 */

import TelemetryManager from './telemetryManager';
import TelemetryEvent from './telemetryEvent';
import EventBuffer from './eventBuffer';
import StreamSampleTelemetryEvent from './streamSampleTelemetryEvent';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Internal.Telemetry
 *
 * @property {TelemetryManager} TelemetryManager
 * @property {TelemetryEvent} TelemetryEvent
 * @property {EventBuffer} EventBuffer
 * @property {StreamSampleTelemetryEvent} StreamSampleTelemetryEvent
 *
 */
export default {
    TelemetryManager,
    TelemetryEvent,
    EventBuffer,
    StreamSampleTelemetryEvent
};
