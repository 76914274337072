/**
 *
 * @module PlayerAdapterUtils
 *
 */
/* eslint-disable import/prefer-default-export */

/**
 *
 * @access protected
 * @since 19.0.0
 * @param {Object} variant
 * @desc Takes a variant and combines the `width` and `height` to create a reporting string `{width}x{height}`
 * @returns {String|null}
 *
 */
export function getResolutionString(variant?: {
    height: number;
    width: number;
}) {
    if (variant) {
        const { height, width } = variant;

        return `${width}x${height}`;
    }

    return null;
}
