/**
 *
 * @module coreHttpClientProvider
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from './../../../logging/logger';
import TokenUpdater from './../../tokenUpdater';
import InternalEvents from './../../../internalEvents';
import getSafe from '../../util/getSafe';

import ErrorReason from '../../exception/errorReason';
import ExceptionReference from '../../exception/exceptionReference';
import ServiceException from '../../exception/serviceException';
import ProviderException from '../../exception/providerException';

const TEST_RETRY_COUNT = 4;

/**
 *
 * @access protected
 * @desc Core `HttpClient` provider implementation definition.
 * Acts as a base definition that is extended by platform specific implementations.
 *
 */
export default class CoreHttpClientProvider {
    /**
     *
     * @param {SDK.Logging.Logger} logger
     * @param {TokenUpdater} tokenUpdater
     *
     */
    constructor(logger, tokenUpdater) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger),
                tokenUpdater: Types.instanceStrict(TokenUpdater)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {Object}
         * @desc required options structure
         *
         */
        this.optionsBaseline = {
            url: ''
        };

        /**
         *
         * @access private
         * @type {String}
         * @desc message exposed if provided options don't meet baseline requirements
         *
         */
        this.optionsMismatchError = 'Options do not match expected input';

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @since 7.0.0
         * @type {TokenUpdater}
         *
         */
        this.tokenUpdater = tokenUpdater;

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.regionHeader = 'x-bamtech-region';

        if (process.env.NODE_ENV === 'test') {
            this.testRetryCount = 0;
        }

        this.logger.log(this.toString(), 'Created Core Provider.');
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {GetPayloadResult} payload
     * @returns {Promise<Object>}
     *
     */
    async request(payload) {
        const method = payload.method.toLowerCase();

        return await this[method](payload);
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @throws {ProviderException} Not implemented
     * @returns {Promise<ServerResponse>}
     *
     * @example
     * httpClient.post(options).then((response) => { console.log(response.data); });
     *
     */
    // eslint-disable-next-line no-unused-vars
    post(options) {
        return Promise.reject(
            new ProviderException(
                `${this.toString()}.post(options) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @throws {ProviderException} Not implemented
     * @returns {Promise<ServerResponse>}
     *
     * @example
     * httpClient.get(options).then((response) => { console.log(response.data); });
     *
     */
    // eslint-disable-next-line no-unused-vars
    get(options) {
        return Promise.reject(
            new ProviderException(
                `${this.toString()}.get(options) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @throws {ProviderException} Not implemented
     * @returns {Promise<Void>}
     *
     * @example
     * httpClient.del(options).then((response) => { console.log(response.data); });
     *
     */
    // eslint-disable-next-line no-unused-vars
    del(options) {
        return Promise.reject(
            new ProviderException(
                `${this.toString()}.del(options) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @param {Object} options
     * @throws {NetworkException}
     * @returns {Promise<Object>}
     *
     * @example
     * httpClient.delete(options).then((response) => { console.log(response.data); });
     *
     */
    delete(options) {
        return this.del(options);
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @throws {ProviderException} Not implemented
     * @returns {Promise<Void>}
     *
     * @example
     * httpClient.patch(options).then((response) => { console.log(response.data); });
     *
     */
    // eslint-disable-next-line no-unused-vars
    patch(options) {
        return Promise.reject(
            new ProviderException(
                `${this.toString()}.patch(options) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @throws {ProviderException} Not implemented
     * @returns {Promise<Object>}
     *
     * @example
     * httpClient.put(options).then((response) => { console.log(response.data); });
     *
     */
    // eslint-disable-next-line no-unused-vars
    put(options) {
        return Promise.reject(
            new ProviderException(
                `${this.toString()}.put(options) - not-implemented`
            )
        );
    }

    // #region private

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} response
     * @desc Reads the text off a HTTP response and tries to parse it as JSON otherwise fall back to text
     * @returns {Promise<Object>}
     *
     */
    async getResponseData(response) {
        let data;
        let dataType = 'text';

        try {
            data = await response.text();
            dataType = 'text';

            data = JSON.parse(data);
            dataType = 'json';
        } catch (error) {
            // no-op
        }

        return {
            data,
            dataType
        };
    }

    /**
     *
     * @access private
     * @param {Number} status
     * @desc Allow status codes to pass through to the client
     * implementations where they are expected to be handled
     * @returns {Boolean}
     *
     */
    verifyStatus(status) {
        if (status >= 200) {
            return true;
        }

        return false;
    }

    /**
     *
     * @access private
     * @param {Object} error
     * @param {Object} httpOptions - the original http `options` object used to generate a request
     * @returns {Promise<NetworkException>}
     * @desc Fetch API "will only reject on network failure or
     * if anything prevented the request from completing."
     * Therefore, reject a `NetworkException`. 4xx and 5xx responses
     * "will resolve normally (with ok status set to false)"
     * Handle them further down the Promise chain.
     *
     */
    onError(error, httpOptions) {
        if (process.env.NODE_ENV === 'test') {
            console.log('DEBUG: HTTP fetch options', httpOptions); // eslint-disable-line no-console
            console.error('DEBUG: Error', error); // eslint-disable-line no-console
        }

        const exceptionData = ExceptionReference.common.network;

        let reasons = [];

        if (Check.assigned(error)) {
            reasons = [new ErrorReason(error.name, error.message)];
        }

        return Promise.reject(new ServiceException({ reasons, exceptionData }));
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {Object} options
     * @param {String} options.url
     * @param {SDK.Services.Configuration.HttpMethod} options.method a `HttpMethod` value (GET, POST, etc...)
     * @param {Object} options.headers
     * @param {Object} options.body
     * @desc to be overridden and provide the raw HTTP platform network call
     * @returns {Promise<Object>}
     *
     */
    // eslint-disable-next-line no-unused-vars
    async rawFetch(options) {
        return Promise.reject(
            new ProviderException(
                `${this.toString()}.rawFetch(options) - not-implemented`
            )
        );
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} options - that will get passed to the `HttpClient` specific implementation of `rawFetch`
     * @param {String} [options.bodyType] - The expected response data type, executed after initial JSON attempt.
     * @param {Boolean} [retry=true] - when true will attempt to retry with a refreshed auth token if we receive a specific invalid token edge error
     * @desc Runs a standard HTTP fetch request but fails with a very specific error code from the edge service.
     * It will attempt to refresh the auth token and re-execute the fetch one more time.
     * @returns {Promise<Object>}
     *
     */
    async fetchRequest(options, retry = true) {
        // //
        // // SAVE - for debugging purposes - can uncomment to generate curl's for failing test http requests for debugging.
        // //
        //
        // try {
        //     const body = options.body ? JSON.stringify(options.body).replace(/\\"/g, '"') : undefined;
        //     const headers2 = options.headers && Object.keys(options.headers).map((key) => `-H '${key}: ${options.headers[key]}'`).join(' \\\n');
        //     const httpRequest = `curl -v '${options.url}' \\\n${headers2} ${body ? `\\\n--data-binary $'${body.slice(1).slice(0, -1)}'` : ''}`;
        //     console.log(httpRequest); // eslint-disable-line no-console, padding-line-between-statements
        // } catch (error) {
        //     console.log(error); // eslint-disable-line no-console
        // }

        try {
            const response = await this.rawFetch(options);

            const dataWrapper = await this.getResponseData(
                response,
                options.bodyType
            );

            const { status, headers } = response;
            const { data } = dataWrapper;

            const result = {
                url: options.url,
                status,
                headers,
                data
            };

            if (retry) {
                const shouldRefreshToken =
                    await this.isTokenInvalidatedBasedOnErrorResponse({
                        status,
                        dataWrapper
                    });

                if (shouldRefreshToken) {
                    await this.applyNewTokenToHeaders(options.headers);

                    return this.fetchRequest(options, false);
                }

                // our integration tests can often fail due to an upstream timeout
                // let's re-play the request on a timeout (but only in test)...
                if (process.env.NODE_ENV === 'test') {
                    this.testRetryCount++;

                    if (
                        this.requestTimedOut({
                            status: response.status,
                            dataWrapper
                        })
                    ) {
                        /* eslint-disable no-console */
                        console.log('**************************************');
                        console.log('* TIMEOUT DETECTED RETRYING REQUEST...');
                        console.log('**************************************');
                        /* eslint-enable no-console */

                        return this.fetchRequest(
                            options,
                            this.testRetryCount < TEST_RETRY_COUNT
                        );
                    }
                }
            }

            return result;
        } catch (error) {
            return this.onError(error, options);
        }
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} response
     * @desc Attempts to parse the response and inspect it for a very specific type of 401
     * error code associated with the Edge service notifying about an invalid token
     * @returns {Boolean}
     *
     */
    async isTokenInvalidatedBasedOnErrorResponse({ status, dataWrapper }) {
        const unauthorizedStatus = status === 401;
        const hasJSON = dataWrapper.dataType === 'json';

        // Edge service is not returning application/json content type
        // so we'll just try to pull JSON out of it regardless - if it works - cool
        if (unauthorizedStatus && hasJSON) {
            try {
                const firstError = getSafe(() => dataWrapper.data.errors[0]);
                const isErrorTokenInvalid =
                    firstError && firstError.code === 'access-token.invalid';
                const reasonsToCheck = ['auth.invalidated', 'auth.expired'];

                // Ideally we should only have to check the `description`
                // but original spec had `reason` so we go ahead and test both just in case.
                if (
                    isErrorTokenInvalid &&
                    (reasonsToCheck.includes(firstError.description) ||
                        reasonsToCheck.includes(firstError.reason))
                ) {
                    this.logger.warn(
                        `${firstError.code} found - going to re-try acquire token`
                    );

                    return true;
                }
            } catch (error) {
                this.logger.error(
                    'Error reading JSON response',
                    error,
                    dataWrapper.data
                );
            }
        }

        return false;
    }

    /**
     *
     * @access private
     * @since 21.0.0
     * @param {Object} response
     * @desc Attempts to parse the response and inspect it for a very specific type http timeout code(s)
     * @returns {Boolean}
     *
     */
    requestTimedOut({ status, dataWrapper }) {
        const hasJSON = dataWrapper && dataWrapper.dataType === 'json';

        // http GATEWAY TIMEOUT
        if (status === 504) {
            return true;
        }

        // Edge service is not returning application/json content type
        // so we'll just try to pull JSON out of it regardless - if it works - cool
        if (status === 200 && hasJSON) {
            try {
                const firstError = dataWrapper.data.errors[0];

                const errorCodeIsTimeout =
                    firstError.code === 'graph.upstream.timeout';
                const errorDescriptionLikeTimeout =
                    firstError.description &&
                    firstError.description.includes(
                        "upstream error with status '503'"
                    );

                if (errorCodeIsTimeout || errorDescriptionLikeTimeout) {
                    console.log('DEBUG: HTTP fetch options', status); // eslint-disable-line no-console
                    console.error('DEBUG: Error', firstError); // eslint-disable-line no-console

                    this.logger.warn(
                        `${firstError.code} found - going to re-try the request`
                    );

                    return true;
                }
            } catch (error) {
                this.logger.error(
                    'Error reading JSON response',
                    error,
                    dataWrapper.data
                );
            }
        }

        return false;
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} headers
     * @desc Refreshes the current accessToken and replaces the Authentication header with the updated token.
     * @returns {Promise<Void>}
     *
     */
    applyNewTokenToHeaders(headers) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const forceRefresh = true;

                // The tokenUpdater.refreshAccessToken could potentially resolve in a failure
                // state so we catch that error and reject this promise before it actually
                // resolves to properly fail out of this effort.

                if (this.tokenUpdater && this.tokenUpdater.once) {
                    this.tokenUpdater.once(
                        InternalEvents.TokenRefreshFailed,
                        (error) => {
                            reject(error);
                        }
                    );
                }

                // force refresh of access token
                await this.tokenUpdater.refreshAccessToken({ forceRefresh });

                const authHeader = headers.get('Authorization');
                const accessToken = this.tokenUpdater.getAccessToken();

                const token = getSafe(() => accessToken.token);

                if (token && authHeader) {
                    // This regex updates the Authorization token by replacing the following possible headers values
                    // 1. "Bearer TOKEN_HERE"
                    // 2. "bearer TOKEN_HERE"
                    // 3. "TOKEN_HERE"
                    headers.set(
                        'Authorization',
                        authHeader.replace(/([Bb]earer ?)?(.*)/, `$1${token}`)
                    );
                }

                resolve();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Providers.Shared.CoreHttpClientProvider';
    }

    // #endregion
}
