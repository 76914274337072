/**
 *
 * @module pvrTexture
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.PVRTexture
 * @desc Controls image texture for variant images generated in PVR format. This parameter is applicable only when format parameter is set to PVR.
 *
 * @property {String} etc1 - Since `17.0.0`
 * @property {String} etc2 - Since `17.0.0`
 *
 * @example
 * SDK.Ripcut.PVRTexture.etc1 // 'etc1'
 *
 */
export default keymirror({
    etc1: null,
    etc2: null
});
