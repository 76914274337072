/**
 *
 * @module eventsAtEdgeDustConfiguration
 *
 */

import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @since 13.0.0
 * @desc Provides configuration information necessary for DUST over Events@Edge.
 *
 */
export default class EventsAtEdgeDustConfiguration extends ServiceConfiguration {
    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.EventsAtEdgeDustConfiguration';
    }

    // #endregion
}
