/**
 *
 * @module defaultGeoProvider
 * @desc Uses zeros to stub out the GeoProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import GeoLocation from '../token/geoLocation';

import type { IGeoProvider } from './IGeoProvider';

/**
 *
 * @desc Defines the contract for a class that can provide the current GeoLocation of the device.
 *
 */
export default class DefaultGeoProvider implements IGeoProvider {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     *
     * @param {SDK.Logging.Logger} [logger=Logging]
     *
     */
    public constructor(logger?: Logger) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger).optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger || Logger.instance;

        this.logger.log(this.toString(), 'Created GeoProvider.');
    }

    /**
     *
     * @access public
     * @param {Number} [timeout]
     * @returns {SDK.Token.GeoLocation}
     *
     */
    public async getGeoLocation() {
        return new GeoLocation(0, 0);
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Providers.DefaultGeoProvider';
    }

    // #endregion
}
