/**
 *
 * @module contentClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/content.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from './../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import DustLogUtility from './../internal/dust/dustLogUtility';
import DustUrnReference from './../internal/dust/dustUrnReference';
import CoreHttpClientProvider from './../providers/shared/coreHttpClientProvider';

import ContentClientConfiguration from './contentClientConfiguration';
import ContentClientEndpoint from './contentClientEndpoint';
import HttpMethod from '../configuration/httpMethod';
import QueryBuilder from './queryBuilder';

import AccessToken from '../token/accessToken';
import SearchOverrides from '../../content/searchOverrides';
import LogTransaction from '../../logging/logTransaction';

const ContentClientDustUrnReference =
    DustUrnReference.services.content.contentClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access content services.
 *
 */
export default class ContentClient {
    /**
     *
     * @access private
     * @type {SDK.Services.Content.ContentClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: ContentClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {CoreHttpClientProvider}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @note Needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled.
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @type {String}
     * @desc Optional parameter that defines the country of origin for the content request.
     *
     */
    private readonly xGeoOverride: string;

    /**
     *
     * @type {String}
     * @desc An optional parameter that can be used to limit search results to a specific time period.
     * @note This is not supported in production.
     *
     */
    private readonly xDelorean: string;

    /**
     *
     * @type {String}
     * @desc An optional parameter that is supplied to content requests to identify related content requests.
     *
     */
    private readonly xContentTransactionId: string;

    /**
     *
     * @param {SDK.Services.Content.ContentClientConfiguration} contentClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        contentClientConfiguration: ContentClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                contentClientConfiguration: Types.instanceStrict(
                    ContentClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = contentClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;
        this.xGeoOverride = 'X-GEO-OVERRIDE';
        this.xDelorean = 'X-DELOREAN';
        this.xContentTransactionId = 'X-Content-Transaction-ID';

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.context - The GraphQL endpoint context.
     * @param {SDK.Services.Content.QueryBuilder} options.queryBuilder - A QueryBuilder object that creates
     * a valid GraphQL query to be used to get the content.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The current access token.
     * @param {SDK.Content.SearchOverrides} [options.overrides] - Values that can be used to override location and time
     * information that is otherwise automatically derived for search requests.
     * @param {String} [options.contentTransactionId] - An application developer provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Returns the results for the query.
     * @returns {Promise<Object>} The results for the query.
     *
     */
    public query(options: {
        context: string;
        queryBuilder: QueryBuilder;
        accessToken: AccessToken;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
        logTransaction: LogTransaction;
    }): Promise<Record<string, unknown>> {
        const {
            context,
            queryBuilder,
            accessToken,
            overrides,
            contentTransactionId,
            logTransaction
        } = options;

        const { dustEnabled, logger } = this;

        const endpointKey = context as keyof typeof ContentClientEndpoint;

        if (Check.not.assigned(ContentClientEndpoint[endpointKey])) {
            logger.warn(
                this.toString(),
                `ContentClientEndpoint[${context}] is not defined.`
            );
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            queryBuilder,
            overrides,
            contentTransactionId
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ContentClientDustUrnReference.query,
            payload,
            method: payload.method,
            endpointKey,
            logTransaction
        });

        return this.httpClient[payload.method](payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility, 'query');
            })
            .then((response) => {
                const { data } = response;

                return Promise.resolve(data);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @param {String} dictionary - The name of the data dictionary used for the suggestions.
     * You must know what valid values are ahead of time.
     * @param {String} query - The case-insensitive query term entered by a user searching for something.
     * @param {Number} [limit=''] - Limits the maximum number of suggestions in the response (must be an Integer).
     * Required if you are also using the filter parameter.
     * @param {String} [filter=''] - Only return suggestions which also contain this string (case insensitive).
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Queries for search suggestions.
     * @returns {Promise<Object>} An Object containing a list of search suggestions.
     *
     */
    public getSearchSuggestions(
        dictionary: string,
        query: string,
        limit = '',
        filter = '',
        logTransaction: LogTransaction
    ): Promise<{
        suggestions: Array<string>;
    }> {
        const { dustEnabled, logger } = this;
        const { endpoints } = this.config;
        const { headers } = endpoints;

        const endpointKey = ContentClientEndpoint.searchSuggestions;
        const endpoint = endpoints[endpointKey];

        const href = `${endpoint.href}?limit={limit}&filter={filter}`;

        const url = href
            .replace('{dictionary}', dictionary)
            .replace('{query}', query)
            .replace('{limit}', limit)
            .replace('{filter}', filter);

        const payload = {
            url,
            headers
        };

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ContentClientDustUrnReference.getSearchSuggestions,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;

                return Promise.resolve(data);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Content.ContentClientEndpoint} options.endpointKey
     * @param {SDK.Services.Content.QueryBuilder} options.queryBuilder
     * @param {SDK.Content.SearchOverrides} [options.overrides]
     * @param {String} [options.contentTransactionId]
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof ContentClientEndpoint;
        queryBuilder: QueryBuilder;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
    }): Omit<GetPayloadResult, 'method'> & {
        method: Lowercase<HttpCoreMethod>;
    } {
        const {
            accessToken,
            endpointKey,
            queryBuilder,
            overrides,
            contentTransactionId
        } = options;

        const { endpoints } = this.config;
        const { urlSizeLimit } = this.config.extras;
        const { xDelorean, xGeoOverride, xContentTransactionId } = this;

        const endpoint = endpoints[endpointKey];

        const { href, method, headers } = endpoint;

        const overrideHeaders: Record<string, string> = {};

        if (Check.assigned(overrides)) {
            const { activeDate, countryCode } = overrides as SearchOverrides;

            if (activeDate) {
                overrideHeaders[xDelorean] = activeDate;
            }

            if (countryCode) {
                overrideHeaders[xGeoOverride] = countryCode;
            }
        }

        if (Check.assigned(contentTransactionId)) {
            overrideHeaders[xContentTransactionId] =
                contentTransactionId as string;
        }

        const requestHeaders = { ...headers, ...overrideHeaders };

        let requestMethod = method
            ? HttpMethod[method.toUpperCase() as HttpCoreMethod]
            : HttpMethod.POST;
        let url = queryBuilder.createQueryUrl(href, requestMethod);
        let requestBody = '';

        if (requestMethod === HttpMethod.GET && url.length > urlSizeLimit) {
            requestMethod = HttpMethod.POST;
            url = queryBuilder.createQueryUrl(href, requestMethod);
        }

        if (requestMethod === HttpMethod.GET) {
            delete requestHeaders['Content-Type'];
        } else if (requestMethod === HttpMethod.POST) {
            requestBody = queryBuilder.createPostContent();
        }

        if (requestHeaders.Authorization) {
            requestHeaders.Authorization = requestHeaders.Authorization.replace(
                /\{accessToken\}/gi,
                accessToken.token
            );
        }

        return {
            url,
            body: requestBody,
            method: requestMethod.toLowerCase() as Lowercase<HttpCoreMethod>,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Content.ContentClient';
    }
}
