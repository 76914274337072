/**
 *
 * @module entry
 * @desc {SDK.Services.Purchase} entry point.
 *
 */

import PurchaseClient from './purchaseClient';
import PurchaseActivation from './purchaseActivation';
import PurchaseActivationResult from './purchaseActivationResult';
import PurchaseActivationStatus from './purchaseActivationStatus';
import ReceiptCredentials from './receiptCredentials';

/**
 *
 * @typedef {Object} SDK.Services.Purchase
 *
 * @property {PurchaseClient} PurchaseClient
 * @property {PurchaseActivation} PurchaseActivation
 * @property {PurchaseActivationResult} PurchaseActivationResult
 * @property {SDK.Services.Purchase.PurchaseActivationStatus} PurchaseActivationStatus
 * @property {ReceiptCredentials} ReceiptCredentials
 *
 */
export default {
    PurchaseClient,
    PurchaseActivation,
    PurchaseActivationResult,
    PurchaseActivationStatus,
    ReceiptCredentials
};
