/**
 *
 * @module commerceManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/commerce.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/commerce.md#browser-info
 * @see https://github.bamtech.co/mercury/universal-payment-bridge/blob/master/docs/3ds/waiver/BR_MC_Debit_3DS_Waiver.md#browser-information
 * @see https://docs.adyen.com/checkout/3d-secure/api-reference#browserinfo
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccessTokenProvider from '../token/accessTokenProvider';
import BrowserInfoProvider from './browserInfoProvider';
import BaseEnvironmentConfiguration from '../services/providers/baseEnvironmentConfiguration';
import CommerceClient from '../services/commerce/commerceClient';
import CommerceManagerConfiguration from '../services/configuration/commerceManagerConfiguration';
import Logger from '../logging/logger';

import delay from '../services/util/delay';
import {
    AccountResumeRequest,
    AssociateAuthValuesWithPaymentMethodRequest,
    PaymentMethod,
    PlanSwitchRequest,
    PriceOrderRequest,
    RedeemRequest,
    RestartSubscriptionRequest,
    SubmitOrderWithPaymentMethodRequest,
    UpdateOrderRequest,
    UpdatePaymentMethodBase
} from './typedefs';
import LogTransaction from '../logging/logTransaction';
import {
    ClientTokenResponse,
    CreatePaymentMethodResponse,
    PaymentRedirectResponse,
    PriceOrderResponse,
    QueryOrderResponse,
    RedeemResponse,
    SetCheckoutDetailsResponse,
    ZipLocation
} from '../services/commerce/typedefs';
import { CreateCardPaymentMethodRequest } from './paymentCard/typedefs';
import { PaymentType } from '../services/commerce/enums';
import { KlarnaPaymentRequest } from './klarna/typedefs';
import { MercadoPaymentRequest } from './mercado/typedefs';
import {
    ComcastConsentRequest,
    ComcastConsentResponse,
    ComcastPaymentRequest,
    CreateComcastPaymentMethodRequest
} from './comcast/typedefs';
import { IDealPaymentRequest } from './iDeal/typedefs';
import {
    CreateBraintreePaymentMethodRequest,
    GetCheckoutDetailsRequest,
    SetCheckoutDetailsRequest,
    UpdateBraintreePaymentMethodRequest
} from './payPal/typedefs';
import AccessToken from '../token/accessToken';

interface CommerceManagerOptions {
    config: CommerceManagerConfiguration;
    client: CommerceClient;
    accessTokenProvider: AccessTokenProvider;
    environmentConfiguration: BaseEnvironmentConfiguration;
    browserInfoProvider: BrowserInfoProvider;
    logger: Logger;
}

/**
 *
 * @access protected
 * @desc Provides ability to access commerce data and link subscriptions to the commerce.
 *
 */
export default class CommerceManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.CommerceManagerConfiguration}
     *
     */
    private config: CommerceManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Commerce.CommerceClient}
     *
     */
    public client: CommerceClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {Number}
     * @desc Number of retries for the getOrderStatus request.
     *
     */
    private counter: number;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {BaseEnvironmentConfiguration}
     *
     */
    private environmentConfiguration: BaseEnvironmentConfiguration;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Commerce.BrowserInfoProvider}
     * @desc Provider used to extract browser information from the shopper.
     *
     */
    private browserInfoProvider: BrowserInfoProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.CommerceManagerConfiguration} options.config
     * @param {SDK.Services.Commerce.CommerceClient} options.client
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     * @param {BaseEnvironmentConfiguration} options.environmentConfiguration
     * @param {SDK.Commerce.BrowserInfoProvider} [options.browserInfoProvider]
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: CommerceManagerOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(CommerceManagerConfiguration),
                    client: Types.instanceStrict(CommerceClient),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider),
                    environmentConfiguration: Types.instanceStrict(
                        BaseEnvironmentConfiguration
                    ),
                    browserInfoProvider:
                        Types.instanceStrict(BrowserInfoProvider).optional,
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            config,
            client,
            accessTokenProvider,
            environmentConfiguration,
            browserInfoProvider,
            logger
        } = options;

        this.config = config;
        this.client = client;
        this.logger = logger;
        this.counter = 0;
        this.accessTokenProvider = accessTokenProvider;

        /**
         *
         * @access private
         * @since 8.0.0
         * @type {SDK.Services.Configuration.EnvironmentConfiguration}
         *
         */
        this.environmentConfiguration = environmentConfiguration;

        /**
         *
         * @access private
         * @since 4.12.0
         * @type {SDK.Commerce.BrowserInfoProvider}
         * @desc Provider used to extract browser information from the shopper.
         *
         */
        this.browserInfoProvider =
            browserInfoProvider || new BrowserInfoProvider({ logger });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {Object<SDK.Commerce.PriceOrderRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PriceOrderResponse>>}
     *
     */
    public async priceOrder(
        request: PriceOrderRequest,
        logTransaction: LogTransaction
    ): Promise<PriceOrderResponse> {
        return await this.client.priceOrder(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @param {String} zipCode
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Array<Object<SDK.Services.Commerce.ZipLocation>>>}
     *
     */
    public async lookupByZipCode(
        zipCode: string,
        logTransaction: LogTransaction
    ): Promise<Array<ZipLocation>> {
        return await this.client.lookupByZipCode(zipCode, logTransaction);
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @param {Object<SDK.Commerce.RedeemRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.RedeemResponse>>}
     *
     */
    public async redeem(
        request: RedeemRequest,
        logTransaction: LogTransaction
    ): Promise<RedeemResponse> {
        return await this.client.redeem(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {Object<SDK.Commerce.SubmitOrderWithPaymentMethodRequest>} request
     * @param {String|undefined} deviceProfile
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async submitOrderWithPaymentMethod(
        request: SubmitOrderWithPaymentMethodRequest,
        deviceProfile: string | undefined,
        logTransaction: LogTransaction
    ): Promise<string> {
        const { accessToken } = this;
        const { applicationRuntime } = this.environmentConfiguration;

        const browserInfo = this.browserInfoProvider.getBrowserInfo();

        const paymentMethodResponse =
            await this.client.submitOrderWithPaymentMethod({
                request,
                browserInfo,
                applicationRuntime,
                deviceProfile,
                accessToken,
                logTransaction
            });

        return paymentMethodResponse.guid;
    }

    /**
     *
     * @access public
     * @since 4.7.0
     * @param {Object<SDK.Commerce.UpdateOrderRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async updateOrder(
        request: UpdateOrderRequest,
        logTransaction: LogTransaction
    ): Promise<void> {
        const browserInfo = this.browserInfoProvider.getBrowserInfo();

        return await this.client.updateOrder(
            request,
            browserInfo,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @param {String} orderId
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.QueryOrderResponse>>}
     * @desc 400 - Thrown when a user token is expired or invalid
     * @desc 422 - Thrown in the event of a billing failure
     * @desc 429 - Thrown after too many requests to the service have been made or the process times out
     * @note return value is SDK.Services.Commerce.OrderStatusResponse enum
     *
     */
    public async validateOrder(
        orderId: string,
        logTransaction: LogTransaction
    ): Promise<QueryOrderResponse> {
        const delayMilliseconds =
            this.config.extras.checkOrderStatusDelay * 1000;

        await delay(delayMilliseconds);

        try {
            const response = await this.client.getOrderStatus(
                orderId,
                this.counter,
                this.accessToken,
                logTransaction
            );

            return response;
        } catch (exception: TodoAny) {
            const { status } = exception;
            const {
                validateOrderNonRetryCodes,
                retryPolicy: { retryMaxAttempts }
            } = this.config.extras;

            if (
                this.counter >= retryMaxAttempts ||
                validateOrderNonRetryCodes.includes(status)
            ) {
                // reached max attempts or error status code is a non-retry code
                this.counter = 0;
                throw exception;
            }

            // try again for any error status code that is not a non-retry code
            this.counter++;

            return await this.validateOrder(orderId, logTransaction);
        }
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {Object<SDK.Commerce.CreateCardPaymentMethodRequest>} request
     * @param {String|undefined} region
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async createCardPaymentMethod(
        request: CreateCardPaymentMethodRequest,
        region: string | undefined,
        logTransaction: LogTransaction
    ): Promise<string> {
        const { client, accessToken } = this;

        const createPaymentMethodResponse =
            await client.createCardPaymentMethod({
                request,
                region,
                accessToken,
                logTransaction
            });

        const { paymentMethodId } = createPaymentMethodResponse;

        return paymentMethodId;
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public async getDefaultPaymentMethod(
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        return await this.client.getDefaultPaymentMethod(
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {Object<SDK.Commerce.UpdatePaymentMethodBase>} request
     * @param {String<SDK.Services.Commerce.PaymentType>} paymentType
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public async updatePaymentMethod(
        request: UpdatePaymentMethodBase,
        paymentType: keyof typeof PaymentType,
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        return await this.client.updatePaymentMethod(
            request,
            paymentType,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Array<Object<SDK.Commerce.PaymentMethod>>>}
     *
     */
    public async listAllPaymentMethods(
        logTransaction: LogTransaction
    ): Promise<Array<PaymentMethod>> {
        return await this.client.listAllPaymentMethods(
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.Klarna.KlarnaPaymentRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PaymentRedirectResponse>>}
     *
     */
    public async submitKlarnaPayment(
        request: KlarnaPaymentRequest,
        logTransaction: LogTransaction
    ): Promise<PaymentRedirectResponse> {
        return await this.client.submitKlarnaPayment(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.7.0
     * @param {Object<SDK.Commerce.AccountResumeRequest>} request
     * @param {String|undefined} deviceProfile
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async resumeAccount(
        request: AccountResumeRequest,
        deviceProfile: string | undefined,
        logTransaction: LogTransaction
    ): Promise<string> {
        const { client, browserInfoProvider, accessToken } = this;
        const browserInfo = browserInfoProvider.getBrowserInfo();
        const accountResumeResponse = await client.resumeAccount({
            request,
            browserInfo,
            deviceProfile,
            accessToken,
            logTransaction
        });

        return accountResumeResponse.guid;
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async getDdcJwtToken(
        logTransaction: LogTransaction
    ): Promise<string> {
        return await this.client.getDdcJwtToken(
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.19.0
     * @param {Object<SDK.Commerce.Mercado.MercadoPaymentRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PaymentRedirectResponse>>}
     *
     */
    public async submitMercadoPayment(
        request: MercadoPaymentRequest,
        logTransaction: LogTransaction
    ): Promise<PaymentRedirectResponse> {
        return await this.client.submitMercadoPayment(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object<SDK.Commerce.Comcast.ComcastPaymentRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public async submitComcastPayment(
        request: ComcastPaymentRequest,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        return await this.client.submitComcastPayment(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.IDeal.IDealPaymentRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PaymentRedirectResponse>>}
     *
     */
    public async submitIDealPayment(
        request: IDealPaymentRequest,
        logTransaction: LogTransaction
    ): Promise<PaymentRedirectResponse> {
        return await this.client.submitIDealPayment(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object<SDK.Commerce.PlanSwitchRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async planSwitch(
        request: PlanSwitchRequest,
        logTransaction: LogTransaction
    ): Promise<string> {
        const { client, accessToken } = this;

        const planSwitchResponse = await client.planSwitch({
            request,
            accessToken,
            logTransaction
        });

        return planSwitchResponse.guid;
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.PayPal.GetCheckoutDetailsRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public async getCheckoutDetails(
        request: GetCheckoutDetailsRequest,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        return await this.client.getCheckoutDetails(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.PayPal.SetCheckoutDetailsRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.SetCheckoutDetailsResponse>>}
     *
     */
    public async setCheckoutDetails(
        request: SetCheckoutDetailsRequest,
        logTransaction: LogTransaction
    ): Promise<SetCheckoutDetailsResponse> {
        return await this.client.setCheckoutDetails(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public async shareDefaultPaymentMethod(
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        return await this.client.shareDefaultPaymentMethod(
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.RestartSubscriptionRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async restartSubscription(
        request: RestartSubscriptionRequest,
        logTransaction: LogTransaction
    ): Promise<string> {
        const restartSubscriptionResponse =
            await this.client.restartSubscription(
                request,
                this.accessToken,
                logTransaction
            );

        return restartSubscriptionResponse.guid;
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {String} paymentMethodId
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public async getPaymentMethod(
        paymentMethodId: string,
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        return await this.client.getPaymentMethod(
            paymentMethodId,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.1.0
     * @param {Object<SDK.Commerce.Comcast.CreateComcastPaymentMethodRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public async createComcastPaymentMethod(
        request: CreateComcastPaymentMethodRequest,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        return await this.client.createComcastPaymentMethod(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.1.0
     * @param {Object<SDK.Commerce.Comcast.ComcastConsentRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.Comcast.ComcastConsentResponse>>}
     *
     */
    public async getComcastConsent(
        request: ComcastConsentRequest,
        logTransaction: LogTransaction
    ): Promise<ComcastConsentResponse> {
        return await this.client.getComcastConsent(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 12.0.0
     * @param {Object<SDK.Commerce.AssociateAuthValuesWithPaymentMethodRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async associateAuthValuesWithPaymentMethod(
        request: AssociateAuthValuesWithPaymentMethodRequest,
        logTransaction: LogTransaction
    ): Promise<void> {
        await this.client.associateAuthValuesWithPaymentMethod(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Commerce.ClientTokenResponse>}
     *
     */
    public async createBraintreeToken(
        logTransaction: LogTransaction
    ): Promise<ClientTokenResponse> {
        return await this.client.createBraintreeToken(
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} billingAgreementId
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>}
     *
     */
    public async getBraintreePaymentMethodId(
        billingAgreementId: string,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        return await this.client.getBraintreePaymentMethodId(
            billingAgreementId,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.CreateBraintreePaymentMethodRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>}
     *
     */
    public async createBraintreePaymentMethod(
        request: CreateBraintreePaymentMethodRequest,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        return await this.client.createBraintreePaymentMethod(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.UpdateBraintreePaymentMethodRequest>} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async updateBraintreePaymentMethod(
        request: UpdateBraintreePaymentMethodRequest,
        logTransaction: LogTransaction
    ): Promise<void> {
        await this.client.updateBraintreePaymentMethod(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} token
     * @returns {Void}
     *
     */
    public setUbaToken(token: string): void {
        this.client.setUbaToken(token);
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Commerce.CommerceManager';
    }
}
