/**
 *
 * @module refreshGrant
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TokenRequestBuilder from './tokenRequestBuilder';

/**
 *
 * @desc Represents the response from exchanging a token and provides
 * access context for subsequent service requests.
 *
 */
export default class RefreshGrant extends TokenRequestBuilder {
    /**
     *
     * @param {String} [refreshToken]
     *
     */
    constructor(refreshToken) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                refreshToken: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.grantType = 'refresh_token';

        // assign refresh_token
        this.tokenData.refresh_token = refreshToken || '';
    }

    /**
     *
     * @access public
     * @type {String}
     * @desc The refresh token, which can be used to obtain a new access token.
     * @note A getter is used to avoid serialization
     *
     */
    get refreshToken() {
        return this.tokenData.refresh_token;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.RefreshGrant';
    }

    // #endregion
}
