/**
 *
 * @module entry
 * @desc {SDK.Content.Bookmarks} entry point.
 *
 */

import BookmarksQuery from './bookmarksQuery';

/**
 *
 * @typedef {Object} SDK.Content.Bookmarks
 *
 * @property {SDK.Content.Bookmarks.BookmarksQuery} BookmarksQuery
 *
 */
export default {
    BookmarksQuery
};
