/**
 *
 * @module deviceGrantStorage
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import PlatformProviders from '../services/providers/platformProviders';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import DeviceGrant from '../services/token/deviceGrant';

/**
 *
 * @desc Provides a storage mechanism for storing DeviceGrant.
 *
 */
export default class DeviceGrantStorage {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    private clientId: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private environment: string;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Services.Token.DeviceGrant|null}
     *
     */
    private deviceGrant: Nullable<DeviceGrant>;

    /**
     *
     * @access private
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     *
     */
    private cacheKey: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.Token.DeviceGrant} [options.deviceGrant=null]
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        logger: Logger;
        deviceGrant?: DeviceGrant;
        storage: CoreStorageProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    deviceGrant: Types.instanceStrict(DeviceGrant).optional,
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientId, environment, logger, deviceGrant, storage } = options;

        this.clientId = clientId;

        this.environment = environment;

        this.logger = logger;

        this.deviceGrant = deviceGrant || null;

        this.storage = storage;

        this.cacheKey = `
            __bam_sdk_device_grant--${this.clientId}_${this.environment}
        `.replace(/(?:\n\s+)/g, '');

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {SDK.Services.Token.DeviceGrant} deviceGrant
     * @desc Stores given deviceGrant in memory and in Storage
     * @returns {Promise<Void>}
     *
     */
    public async saveDeviceGrant(deviceGrant: DeviceGrant) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                deviceGrant: Types.instanceStrict(DeviceGrant)
            };

            typecheck(this, 'saveDeviceGrant', params, arguments);
        }

        this.logger.info(
            this.toString(),
            `Saving deviceGrant to Storage for key: "${this.cacheKey}".`
        );

        this.deviceGrant = deviceGrant;

        await this.storage.set(this.cacheKey, this.deviceGrant);
    }

    /**
     *
     * @access protected
     * @desc Gets deviceGrant from memory
     * @returns {SDK.Services.Token.DeviceGrant|null}
     *
     */
    public getDeviceGrant() {
        return this.deviceGrant;
    }

    /**
     *
     * @access protected
     * @desc Deletes the current deviceGrant from memory and from Storage
     * @returns {Promise<Void>}
     *
     */
    protected async clear() {
        this.logger.warn(
            this.toString(),
            `Deleting deviceGrant from Storage for key: "${this.cacheKey}".`
        );

        this.deviceGrant = null;

        await this.storage.remove(this.cacheKey);
    }

    /**
     *
     * @access protected
     * @desc Gets device grant from Storage.
     * @note Executed a single time on DeviceGrantStorage instantiation.
     * @note Checks Storage to determine if a device grant was previously stored.
     * @note Should fail silently if no device grant found but still returns it from memory if there is one.
     * @returns {Promise<SDK.Services.Token.DeviceGrant|null>}
     *
     */
    public async loadDeviceGrantFromStorage() {
        this.logger.info(
            this.toString(),
            `Loading deviceGrant from Storage for key: "${this.cacheKey}".`
        );

        try {
            const deviceGrant = await this.storage.get(this.cacheKey);

            if (deviceGrant) {
                const { grantType, assertion } = deviceGrant;

                this.deviceGrant = new DeviceGrant(grantType, assertion);

                return this.deviceGrant;
            }
        } catch (ex) {} // eslint-disable-line no-empty

        if (Check.assigned(this.deviceGrant)) {
            this.logger.info(
                this.toString(),
                'Could not load deviceGrant from Storage but it is in memory.'
            );
        } else {
            this.logger.info(
                this.toString(),
                'Could not load deviceGrant from Storage and memory is null.'
            );
        }

        return this.deviceGrant;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.DeviceGrantStorage';
    }
}
