/**
 *
 * @module drmClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';
import DrmClientExtrasMap from './drmClientExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the DrmClient.
 *
 */
export default class DrmClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    DrmClientExtrasMap
> {
    /**
     *
     * @param {String} baseUrl
     * @param {Object} endpoints
     * @param {DrmClientExtrasMap} extras
     *
     */
    public constructor(
        baseUrl: string,
        endpoints: IEndpoints<Record<string, never>>,
        extras: DrmClientExtrasMap
    ) {
        super(baseUrl, endpoints);
        this.extras = extras;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Drm.DrmClientConfiguration';
    }
}
