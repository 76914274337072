/**
 *
 * @module entry
 * @desc {SDK.Services.Media.AdEngine} entry point.
 *
 */

import AdEngineClient from './adEngineClient';

/**
 *
 * @typedef {Object} SDK.Services.Media.AdEngine
 *
 * @property {AdEngineClient} AdEngineClient
 *
 */
export default {
    AdEngineClient
};
