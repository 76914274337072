/**
 *
 * @module adEngineClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playback-session.md#adengineclient
 * @see https://wiki.bamtechmedia.com/pages/viewpage.action?pageId=17892211
 * @see https://wiki.disneystreaming.com/display/AdEngine/adEngine+RFC003+Settoken+SDK+Integration
 * @see https://www.npmjs.com/package/query-string
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import checkResponseCode from '../../util/checkResponseCode';
import Logger from './../../../logging/logger';
import DustLogUtility from './../../internal/dust/dustLogUtility';
import DustUrnReference from './../../internal/dust/dustUrnReference';

import AdEngineClientConfiguration from './adEngineClientConfiguration';
import AdEngineClientEndpoint from './adEngineClientEndpoint';
import CoreHttpClientProvider from '../../providers/shared/coreHttpClientProvider';
import LogTransaction from '../../../logging/logTransaction';
import AccessToken from '../../token/accessToken';
import HttpMethod from '../../configuration/httpMethod';

const AdEngineClientDustUrnReference =
    DustUrnReference.services.media.adEngine.adEngineClient;

/**
 *
 * @access protected
 * @since 3.6.0
 * @desc The `AdEngineClient` is used to retrieve the AdEngine cookie.
 * The network request requires several parameters to be passed with the request body
 * along with an access token.
 *
 */
export default class AdEngineClient {
    /**
     *
     * @param {SDK.Services.Media.AdEngine.AdEngineClientConfiguration} adEngineClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    constructor(adEngineClientConfiguration, logger, httpClient) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                adEngineClientConfiguration: Types.instanceStrict(
                    AdEngineClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Services.Media.AdEngine.AdEngineClientConfiguration}
         * @desc The configuration information to use.
         *
         */
        this.config = adEngineClientConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {HttpClient}
         * @desc The object responsible for making HTTP requests.
         *
         */
        this.httpClient = httpClient;

        /**
         *
         * @access private
         * @type {Boolean}
         * @note needs to default to true to collect dust events before the configuration is fetched and we can
         * determine if this should be enabled
         *
         */
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {Object} adEngineObject - The AdEngine payload.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Used when the SDK and a native player share a networking stack. The stack must
     * have a local cookie store. If no cookie store is available, {getCookies} should be used.
     * @note adEngineObject is defined as SDK.Media.AdEngine.AdEnginePayload in the SDK spec,
     * for the JS SDK it's a passthrough Object called adEngineObject (which originates in the PlaybackSession).
     * @note The underlying network stack will call the ad-engine endpoint. The cookie will be saved in the cookie store.
     * @note The adEnginePayload Map should be posted as form data to the payload url.
     * @returns {Promise<Void>}
     *
     */
    updateCookies(accessToken, adEngineObject, logTransaction) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                adEngineObject: Types.nonEmptyObject,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'updateCookies', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = AdEngineClientEndpoint.setTokenPost;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: adEngineObject
        });

        payload.credentials = 'include';

        logger.info(
            this.toString(),
            `Attempting to update AdEngine cookies with: "${payload.body}".`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: AdEngineClientDustUrnReference.updateCookies,
            payload,
            method: HttpMethod.POST,
            data: {
                query: payload.body
            },
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {Object} adEngineObject - The AdEngine payload.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Used when the SDK and a native player share do not share a networking stack and
     * cookies can be set on the native player. May not be required on all platforms.
     * @note adEngineObject is defined as SDK.Media.AdEngine.AdEnginePayload in the SDK spec,
     * for the JS SDK it's a passthrough Object called adEngineObject.
     * @note The networking stack will call the ad-engine endpoint and retrieve the cookie from
     * the response. The cookie is then added to the native player.
     * @note The adEnginePayload Map should be posted as form data to the payload url.
     * @returns {Promise<Cookie[]>}
     *
     */
    getCookies(accessToken, adEngineObject, logTransaction) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                adEngineObject: Types.nonEmptyObject,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getCookies', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = AdEngineClientEndpoint.setTokenPost;

        const payload = this.getPayload({
            accessToken,
            endpointKey, // uses the same endpoint as updateCookies
            body: adEngineObject
        });

        logger.info(
            this.toString(),
            `Attempting to update AdEngine cookies with: "${payload.body}".`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: AdEngineClientDustUrnReference.getCookies,
            payload,
            method: HttpMethod.POST,
            data: {
                query: payload.body
            },
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Media.AdEngine.AdEngineClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {Object} The payload for the client request.
     *
     */
    getPayload(options) {
        const { accessToken, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, headers } = endpoint;
        const requestBody = body ? queryString.stringify(body) : '';
        const url = href;

        const requestHeaders = {
            ...headers,
            Authorization: headers.Authorization.replace(
                /\{accessToken\}/gi,
                accessToken.token
            )
        };

        return {
            url,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.AdEngine.AdEngineClient';
    }

    // #endregion
}
