/**
 *
 * @module playbackActivity
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackActivity
 * @desc Describes the current playback activity of the player when sending a `urn:dss:event:playback:event:v1`.
 *
 * @property {String} bitrateChanged - `Since 13.0.0` - Indicates that the video bitrate has changed for the piece of content.
 * @property {String} ended - `Since 13.0.0` - Indicates that the video playback experience ends for any reason.
 * @property {String} paused - `Since 13.0.0` - Indicates that playback has paused.
 * @property {String} rebufferingEnded - `Since 13.0.0` - Indicates that the player has stopped rebuffering.
 * @property {String} rebufferingStarted - `Since 13.0.0` - Indicates that the player has started rebuffering.
 * @property {String} resumed - `Since 13.0.0` - Indicates that playback has resumed after pausing but not after buffering.
 * @property {String} seekEnded - `Since 13.0.0` - Indicates that the seek operation has completed.
 * @property {String} seekStarted - `Since 13.0.0` - Indicates that a seek has started (no other user-confirmation is needed).
 * @property {String} started - `Since 13.0.0` - Indicates that playback has started.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackActivity.started // 'started'
 *
 */
export default keyMirror({
    bitrateChanged: null,
    ended: null,
    paused: null,
    rebufferingEnded: null,
    rebufferingStarted: null,
    resumed: null,
    seekEnded: null,
    seekStarted: null,
    started: null
});
