/**
 *
 * @module entry
 * @desc {SDK.Services.Orchestration} entry point.
 *
 */

import OffDeviceRedemptionFlow from './offDeviceRedemptionFlow';
import OrchestrationClient from './orchestrationClient';
import OrchestrationClientConfiguration from './orchestrationClientConfiguration';
import OrchestrationClientEndpoint from './orchestrationClientEndpoint';
import OrchestrationExtrasMap from './orchestrationExtrasMap';
import PurchaseContext from './purchaseContext';
import Internal from './internal/entry';

/**
 *
 * @typedef {Object} SDK.Services.Orchestration
 *
 * @property {SDK.Services.Orchestration.OffDeviceRedemptionFlow} OffDeviceRedemptionFlow
 * @property {OrchestrationClient} OrchestrationClient
 * @property {OrchestrationClientConfiguration} OrchestrationClientConfiguration
 * @property {OrchestrationClientEndpoint} OrchestrationClientEndpoint
 * @property {OrchestrationExtrasMap} OrchestrationExtrasMap
 * @property {SDK.Services.Orchestration.PurchaseContext} PurchaseContext
 * @property {SDK.Services.Orchestration.Internal} Internal
 *
 */
export default {
    OffDeviceRedemptionFlow,
    OrchestrationClient,
    OrchestrationClientConfiguration,
    OrchestrationClientEndpoint,
    OrchestrationExtrasMap,
    PurchaseContext,
    Internal
};
