/**
 *
 * @module serviceConfiguration
 *
 */

/**
 *
 * @access protected
 * @desc Provides base ServiceConfiguration
 *
 */
export default class ServiceConfiguration<
    TClient = Record<string, never>,
    TExtras = Record<string, never>
> {
    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ServiceClientConfiguration}
     *
     */
    public client: TClient;

    /**
     *
     * @access public
     * @type {TExtras}
     *
     */
    public extras: TExtras;

    /**
     *
     * @access public
     * @type {Boolean}
     *
     */
    public disabled: boolean;

    /**
     *
     * @param {SDK.Services.Configuration.ServiceClientConfiguration} [client]
     * @param {Object} [extras={}]
     * @param {Boolean} [disabled=false]
     *
     */
    public constructor(
        client: TClient,
        extras: TExtras = {} as TExtras,
        disabled = false
    ) {
        this.client = client;
        this.extras = extras;
        this.disabled = disabled;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ServiceConfiguration';
    }
}
