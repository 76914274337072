/**
 *
 * @module entry
 * @desc {SDK.Services.Internal.Telemetry} entry point.
 *
 */

import TelemetryClientConfiguration from './telemetryClientConfiguration';
import TelemetryClient from './telemetryClient';
import TelemetryPayload from './telemetryPayload';
import TelemetryResponse from './telemetryResponse';
import HoraValidatedTelemetryResponse from './horaValidatedTelemetryResponse';
import HoraValidationResultType from './horaValidationResultType';
import HoraValidationSource from './horaValidationSource';
import StreamSamplePayload from './streamSamplePayload';
import StreamSampleClientPayload from './streamSampleClientPayload';
import ValidatedTelemetryResponse from './validatedTelemetryResponse';
import ValidationResult from './validationResult';
import ValidationResultJsonDustEventDecodeError from './validationResultJsonDustEventDecodeError';
import ValidationResultSuccess from './validationResultSuccess';
import ValidationResultType from './validationResultType';
import ValidationResultUnknownTypeError from './validationResultUnknownTypeError';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Services.Internal.Telemetry
 *
 * @property {TelemetryClientConfiguration} TelemetryClientConfiguration
 * @property {TelemetryClient} TelemetryClient
 * @property {TelemetryPayload} TelemetryPayload
 * @property {HoraValidatedTelemetryResponse} HoraValidatedTelemetryResponse
 * @property {SDK.Services.Internal.Telemetry.HoraValidationResultType} HoraValidationResultType
 * @property {SDK.Services.Internal.Telemetry.HoraValidationSource} HoraValidationSource
 * @property {StreamSamplePayload} StreamSamplePayload
 * @property {StreamSampleClientPayload} StreamSampleClientPayload
 * @property {ValidatedTelemetryResponse} ValidatedTelemetryResponse
 * @property {ValidationResult} ValidationResult
 * @property {ValidationResultJsonDustEventDecodeError} ValidationResultJsonDustEventDecodeError
 * @property {ValidationResultSuccess} ValidationResultSuccess
 * @property {SDK.Services.Internal.Telemetry.ValidationResultType} ValidationResultType
 * @property {ValidationResultUnknownTypeError} ValidationResultUnknownTypeError
 *
 */
export default {
    TelemetryClientConfiguration,
    TelemetryClient,
    TelemetryPayload,
    TelemetryResponse,
    HoraValidatedTelemetryResponse,
    HoraValidationResultType,
    HoraValidationSource,
    StreamSamplePayload,
    StreamSampleClientPayload,
    ValidatedTelemetryResponse,
    ValidationResult,
    ValidationResultJsonDustEventDecodeError,
    ValidationResultSuccess,
    ValidationResultType,
    ValidationResultUnknownTypeError
};
