/**
 *
 * @module startupActivity
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.StartupActivity
 * @desc Describes the current startup activity of the player when sending a `urn:dss:event:client:playback:startup:v1`.
 *
 * @property {String} requested - `Since 13.0.0` - Indicates that the user has initiated playback.
 * @property {String} ready - `Since 13.0.0` - Indicates that enough media has been buffered for playback to begin.
 * @property {String} initialized - `Since 13.0.0` - Indicates that the player is initialized with a playlist.
 * @property {String} fetched - `Since 13.0.0` - Indicates that a call to the Playlist Service was requested to get information about a stream.
 * @property {String} masterFetched - `Since 13.0.0` - Indicates that a fetch of the master m3u8 has been requested.
 * @property {String} variantFetched - `Since 13.0.0` - Indicates that a fetch of a variant m3u8 has been requested.
 * @property {String} drmCertificateFetched - `Since 13.0.0` - Indicates that a fetch of a DRM certificate has been requested.
 * @property {String} drmKeyFetched - `Since 13.0.0` - Indicates that a fetch of a DRM key has been requested.
 * @property {String} reattempt - `Since 13.0.0` - Indicates a subsequent systematic reattempt playback due to non fatal playback cases, including CDN fallback.
 *
 * @example
 * SDK.Services.QualityOfService.StartupActivity.requested // 'requested'
 *
 */
export default keyMirror({
    requested: null,
    ready: null,
    initialized: null,
    fetched: null,
    masterFetched: null,
    variantFetched: null,
    drmCertificateFetched: null,
    drmKeyFetched: null,
    reattempt: null
});
