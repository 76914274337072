/**
 *
 * @module updateProtectProfileCreationWithActionGrantRequest
 *
 */

import OrchestrationRequest from '../orchestration/internal/orchestrationRequest';

/**
 *
 * @since 4.17.0
 *
 */
export default class UpdateProtectProfileCreationWithActionGrantRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: object) {
        const query = UpdateProtectProfileCreationWithActionGrantRequest.query;

        const variables = {
            input
        };

        super({
            query,
            variables,
            operationName: 'updateProtectProfileCreationWithActionGrant'
        });
    }

    /**
     *
     * @access private
     * @since 4.17.0
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/orchestration_graphql/query/updateProtectProfileCreationWithActionGrant.md
     * @returns {String}
     *
     */
    private static get query() {
        return `mutation updateProtectProfileCreationWithActionGrant($input: UpdateProtectProfileCreationWithActionGrantInput!){
            updateProtectProfileCreationWithActionGrant(updateProtectProfileCreationWithActionGrant: $input){
              accepted
            }
          }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Account.UpdateProtectProfileCreationWithActionGrantRequest';
    }
}
