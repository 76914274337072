/**
 *
 * @module playbackHeartbeatEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/playback/v1/playback-heartbeat.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import HeartbeatSampleType from './heartbeatSampleType';
import NetworkType from './networkType';
import PlaybackState from './playbackState';
import PlaylistBitrateType from './playlistBitrateType';
import ProductType from './productType';

import { AdInsertionType, PresentationType } from './enums';

import {
    AdPodDataTypedef,
    AdPodPlacementTypedef,
    AdSlotDataTypedef
} from './typedefs';

/**
 *
 * @since 13.0.0
 * @description This event represents a playback heartbeat.
 * @note urn:dss:event:client:playback:heartbeat:v1
 * @note This event should be sent every 30 seconds or when one of the following properties changes: `cpSessionId`,
 * `playbackSessionId`, `streamUrl`, `contentId`, `presentationType`, `cdnName`, `videoBitrate`.
 * @note Fire immediately before playback pause and playback ended. Throttle to not send when the playback duration is
 * 0 or if playback hasn't happened in the past 30 seconds.
 *
 */
export default class PlaybackHeartbeatEventData {
    /**
     *
     * @param {Object} [options={}]
     * @param {Number} [options.playbackDuration]
     * @param {String<SDK.Services.QualityOfService.PlaybackState>} [options.playbackState]
     * @param {String<SDK.Services.QualityOfService.ProductType>} [options.productType]
     * @param {Number} [options.totalVst=0]
     * @param {Number} [options.videoStartTimestamp=0]
     * @param {Number} [options.bufferSegmentDuration=0]
     * @param {Number} [options.mediaBytesDownloaded]
     * @param {Number} [options.mediaDownloadTotalTime]
     * @param {Number} [options.mediaDownloadTotalCount]
     * @param {String} [options.playbackSessionId]
     * @param {Number} [options.playheadPosition=-1]
     * @param {Number} [options.videoBitrate=0]
     * @param {Number} [options.videoAverageBitrate=0]
     * @param {Number} [options.audioBitrate=0]
     * @param {Number} [options.maxAllowedVideoBitrate]
     * @param {Number} [options.segmentPosition]
     * @param {String} [options.cdnName='null']
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType=SDK.Services.QualityOfService.NetworkType.unknown]
     * @param {Number} [options.liveLatencyAmount]
     * @param {String<SDK.Services.QualityOfService.PlaylistBitrateType>} [options.playlistBitrateType]
     * @param {Number} [options.currentThroughput]
     * @param {Number} [options.cpuPercentage]
     * @param {Number} [options.freeMemory]
     * @param {Number} [options.seekableRangeEndProgramDateTime]
     * @param {Boolean} [options.isLiveEdge]
     * @param {Boolean} [options.artificialDelay]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Object<String, String>} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {String<SDK.Services.QualityOfService.HeartbeatSampleType>} [options.sampleType]
     * @param {Number} [options.playlistAudioChannels]
     * @param {String} [options.playlistAudioCodec]
     * @param {String} [options.playlistAudioLanguage]
     * @param {String} [options.playlistAudioName]
     * @param {String} [options.playlistSubtitleLanguage]
     * @param {String} [options.playlistSubtitleName]
     * @param {Boolean} [options.subtitleVisibility=false]
     * @param {String<SDK.Services.QualityOfService.PresentationType>} [options.presentationType=PresentationType.unknown]
     * @param {String<SDK.Services.QualityOfService.AdInsertionType>} [options.adInsertionType=AdInsertionType.none]
     * @param {String<SDK.Services.Media.SubscriptionType>|undefined} [options.subscriptionType]
     * @param {String|undefined} [options.adSessionId]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData} [options.adSlotData]
     * @param {Number} [options.adPlayheadPosition]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackDuration: Types.number.optional,
                    playbackState: Types.in(PlaybackState).optional,
                    productType: Types.in(ProductType).optional,
                    totalVst: Types.number.optional,
                    videoStartTimestamp: Types.number.optional,
                    bufferSegmentDuration: Types.number.optional,
                    mediaBytesDownloaded: Types.number.optional,
                    mediaDownloadTotalTime: Types.number.optional,
                    mediaDownloadTotalCount: Types.number.optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    playheadPosition: Types.number.optional,
                    videoBitrate: Types.number.optional,
                    videoAverageBitrate: Types.number.optional,
                    audioBitrate: Types.number.optional,
                    maxAllowedVideoBitrate: Types.number.optional,
                    segmentPosition: Types.number.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    liveLatencyAmount: Types.number.optional,
                    playlistBitrateType: Types.in(PlaylistBitrateType).optional,
                    currentThroughput: Types.number.optional,
                    cpuPercentage: Types.number.optional,
                    freeMemory: Types.number.optional,
                    seekableRangeEndProgramDateTime: Types.number.optional,
                    isLiveEdge: Types.boolean.optional,
                    artificialDelay: Types.boolean.optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    contentKeys: Types.object().optional,
                    data: Types.object().optional,
                    sampleType: Types.in(HeartbeatSampleType).optional,
                    playlistAudioChannels: Types.number.optional,
                    playlistAudioCodec: Types.nonEmptyString.optional,
                    playlistAudioLanguage: Types.nonEmptyString.optional,
                    playlistAudioName: Types.nonEmptyString.optional,
                    playlistSubtitleLanguage: Types.nonEmptyString.optional,
                    playlistSubtitleName: Types.nonEmptyString.optional,
                    subtitleVisibility: Types.boolean.optional,
                    presentationType: Types.in(PresentationType).optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    subscriptionType: Types.string.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional,
                    adPlayheadPosition: Types.number.optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            playbackDuration,
            playbackState,
            productType,
            totalVst,
            videoStartTimestamp,
            bufferSegmentDuration,
            mediaBytesDownloaded,
            mediaDownloadTotalTime,
            mediaDownloadTotalCount,
            playbackSessionId,
            playheadPosition,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            cdnName,
            networkType,
            liveLatencyAmount,
            playlistBitrateType,
            currentThroughput,
            cpuPercentage,
            freeMemory,
            seekableRangeEndProgramDateTime,
            isLiveEdge,
            artificialDelay,
            clientGroupIds,
            serverGroupIds,
            contentKeys,
            sampleType,
            data = {},
            playlistAudioChannels,
            playlistAudioCodec,
            playlistAudioLanguage,
            playlistAudioName,
            playlistSubtitleLanguage,
            playlistSubtitleName,
            subtitleVisibility,
            presentationType,
            adInsertionType,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            adPlayheadPosition
        } = options || {};

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Duration of the playback since last player_heartbeat event in milliseconds, regardless of presentationType.
         *
         */
        this.playbackDuration = Check.greaterOrEqual(playbackDuration, 0)
            ? playbackDuration
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.PlaybackState>|undefined}
         * @desc Current state of playback.
         *
         */
        this.playbackState = playbackState;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
         * @desc The Product type, Live or VOD.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.productType = productType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The total time, in milliseconds, from the playlist configuration service request to either
         * the first time unit render or the player being fully buffered and ready in the case of preloading.
         * @note Pass 0 if the value is unavailable at the time of reporting.
         * @note Source this by calculating the time elapsed from the `videoStartTimestamp` stored during the
         * `requested` event and the current time of the `ready` event.
         * @note This may vary by platform implementation, especially when bumpers and prerolls are involved.
         * See the QoE Feature Overview for additional details.
         *
         */
        this.totalVst = Check.assigned(totalVst) ? totalVst : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The client epoch timestamp of requesting playlist configuration service.
         * @note When preloading (prefetch playlist), this time is the epoch timestamp, in milliseconds,
         * that the platform loads the prefetched playlist from cache.
         * @note Pass 0 if the value is unavailable at the time of reporting.
         * @note Source this by getting the current time during the `requested` event.
         *
         */
        this.videoStartTimestamp = Check.assigned(videoStartTimestamp)
            ? videoStartTimestamp
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc Amount of video currently fully buffered in milliseconds. Best version is accessed via a getter provided by logic player.
         * @note Pass 0 if the value is unavailable at the time of reporting.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.bufferSegmentDuration = Check.assigned(bufferSegmentDuration)
            ? bufferSegmentDuration
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Sum of all bytes downloaded since the last player heartbeat for all the following resource types:
         * manifests (M3U8 or MPD), video segments, audio segments, muxed video/audio segments, timed text segments or sidecar files,
         * thumbnail images or I-frame segments.
         * @note The value should preferably represent bytes downloaded (not played), but if that data is not available it’s acceptable
         * to represent bytes played instead.
         * @note If data about all listed resource types is not available, clients should use best effort to include as many listed
         * resource types as possible. Value is the number of bytes.
         * @note The SDK should reference the previous `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider`
         * to calculate this value.
         *
         */
        this.mediaBytesDownloaded = mediaBytesDownloaded;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Sum of time spent for all media bytes downloaded since the last player heartbeat event in milliseconds.
         * @note "All media" here means all media resources, mainly including video segments and audio segments.
         * @note The SDK should reference the previous `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider`
         * to calculate this value.
         *
         */
        this.mediaDownloadTotalTime = mediaDownloadTotalTime;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Number of all downloaded (including successfully and failed) video and audio segments.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.mediaDownloadTotalCount = mediaDownloadTotalCount;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Client generated ID of the stream/playback session.
         * @note Added after this event is created via the `logQoeEvent` method on `SDK.Media.PlaybackTelemetryDispatcher`
         *
         */
        this.playbackSessionId = playbackSessionId;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
         * @note Source from the associated `SDK.Media.PlaybackEndedEvent`.
         *
         */
        this.playheadPosition = Check.assigned(playheadPosition)
            ? playheadPosition
            : -1;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The peak video bitrate of the current presentationType, in bps.
         * @dnote The SDK should keep track of the most recent videoBitrate value from the `PlaybackEventListener.onBitrateChanged`
         * and `PlaybackEventListener.onPresentationTypeChanged` event.
         * @note If value is 0 or not available return 0.
         *
         */
        this.videoBitrate = Check.assigned(videoBitrate) ? videoBitrate : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The average video bitrate of the current presentationType, in bps.
         * @note The SDK should keep track of the most recent videoAverageBitrate value from the `PlaybackEventListener.onBitrateChanged`
         * and `PlaybackEventListener.onPresentationTypeChanged` event.
         * @note If value is 0 or not available return 0.
         *
         */
        this.videoAverageBitrate = Check.assigned(videoAverageBitrate)
            ? videoAverageBitrate
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant
         * last played before the event, as reported in the HLS or DASH manifest.
         * @note If the value is 0 or not available, return 0. If value is muxed, send the bitrate of the combined content.
         * @note The SDK should keep track of the most recent audioBitrate value from the `PlaybackEventListener.onAudioBitrateChanged`
         * and `PlaybackEventListener.onPresentationTypeChanged` event.
         *
         */
        this.audioBitrate = Check.assigned(audioBitrate) ? audioBitrate : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc The highest video bitrate (in bps) available for the currently selected presentationType in the current
         * DASH/HLS manifest that is allowed to play. The user may not reach this bitrate in their session.
         * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
         * resolution caps based on video window resolution/orientation.
         * @note The value should default to 0 if unavailable or unknown.
         * @note If the `PlaybackActivity` is `started`, SDKs should source this from the associated `SDK.Media.PlaybackStartedEvent`
         * event and update the cached `maxAllowedVideoBitrate` value.
         * @note If the `PlaybackActivity` is not `started`, SDKs should source this from the cached `maxAllowedVideoBitrate` value.
         *
         */
        this.maxAllowedVideoBitrate = Check.assigned(maxAllowedVideoBitrate)
            ? maxAllowedVideoBitrate
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc This should be the epoch time in milliseconds of the video. Required if `productType` is `Live`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.segmentPosition = Check.assigned(segmentPosition)
            ? Math.floor(segmentPosition)
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String}
         * @desc The CDN running the server.
         * @note A string value of "null" needs to be provided if not run by a CDN.
         *
         */
        this.cdnName = cdnName || 'null';

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.NetworkType>}
         * @desc The type of network connection currently in use by the client.
         *
         */
        this.networkType = networkType || NetworkType.unknown;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Latency of the current playback position, in milliseconds, with the live head
         * (live head - current position, >= 0). Required if `productType` is `Live`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.liveLatencyAmount = Check.assigned(liveLatencyAmount)
            ? Math.floor(liveLatencyAmount)
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.PlaylistBitrateType>|undefined}
         * @desc Average or peak (average is default). If known, we should indicate whether this is the average
         * (e.g. `AVERAGE-BANDWIDTH`) or peak (e.g. `BANDWIDTH`).
         *
         */
        this.playlistBitrateType = playlistBitrateType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc The current user throughput reported, if sent by the application (measure in bits per second), regardless of the presentationType.
         * @note Heimdall 1.0 field.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.currentThroughput = currentThroughput;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Current available CPU as a percentage.
         * @note Heimdall 1.0 field.
         * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
         *
         */
        this.cpuPercentage = cpuPercentage;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Current available memory in MB.
         * @note Heimdall 1.0 field.
         * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
         *
         */
        this.freeMemory = freeMemory;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Value of the PROGRAM-DATE-TIME of the last (most recent) seekable segment + segment length in epoch millis
         * i.e. end of last segment. Used to support live latency calculation.
         * @note Heimdall 1.0 field.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.seekableRangeEndProgramDateTime = seekableRangeEndProgramDateTime;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean|undefined}
         * @desc Indication that the content being played is at the live edge (3 segment durations behind live edge)
         * i.e. playing 4th segment from front of playlist.
         * @note Heimdall 1.0 field.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.isLiveEdge = isLiveEdge;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean|undefined}
         * @desc Indicates if some form of artificial delay occurs on content start up.
         * @note Source from the `artificialDelay` flag passed in from `SDK.Media.MediaApi.fetch()`.
         *
         */
        this.artificialDelay = artificialDelay;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when testing PQM.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.clientGroupIds = clientGroupIds;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.serverGroupIds = serverGroupIds;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Object<String, String>}
         * @desc Associated content keys for the media item.
         * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.contentKeys = contentKeys || {};

        /**
         *
         * @access public
         * @since 16.0.0
         * @type {String<SDK.Services.QualityOfService.HeartbeatSampleType>|undefined}
         * @desc Differentiates the different kinds of playback heartbeat events.
         * @note This is technically optional, but the intent is to provide this when available.
         *
         */
        this.sampleType = sampleType;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {Number|undefined}
         * @desc Audio channels as defined by the selected playlist variant.
         * @note The SDK should keep track of the most recent playlistAudioChannels value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onAudioChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
         * sampleType, once a value has been established.
         *
         */
        this.playlistAudioChannels = playlistAudioChannels;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {String|undefined}
         * @desc Audio codec as defined by the selected playlist variant.
         * @note The SDK should keep track of the most recent playlistAudioCodec value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onAudioChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
         * sampleType, once a value has been established.
         *
         */
        this.playlistAudioCodec = playlistAudioCodec;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {String|undefined}
         * @description Audio language as defined by the selected audio rendition.
         * @note The SDK should keep track of the most recent playlistAudioLanguage value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onAudioChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
         * sampleType, once a value has been established.
         *
         */
        this.playlistAudioLanguage = playlistAudioLanguage;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {String|undefined}
         * @desc Audio name as defined by the selected audio rendition.
         * @note The SDK should keep track of the most recent playlistAudioName value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onAudioChanged` and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
         * sampleType, once a value has been established.
         *
         */
        this.playlistAudioName = playlistAudioName;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {String|undefined}
         * @desc Subtitle language as defined by a variant playlist.
         * @note The SDK should keep track of the most recent playlistSubtitleLanguage value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onSubtitleChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note Required if subtitleVisibility is `true`.
         * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
         * sampleType, once a value has been established if subtitleVisibility is `true`.
         *
         */
        this.playlistSubtitleLanguage = playlistSubtitleLanguage;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {String|undefined}
         * @desc Name of the subtitle.
         * @note The SDK should keep track of the most recent playlistSubtitleName value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onSubtitleChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note Required if subtitleVisibility is `true`.
         * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
         * sampleType, once a value has been established if subtitleVisibility is `true`.
         *
         */
        this.playlistSubtitleName = playlistSubtitleName;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {Boolean}
         * @desc Name of the subtitle.
         * @desc Subtitle visibility.
         * @note The SDK should keep track of the most recent subtitleVisibility value from `PlaybackEventListener.onInitialized`,
         * `PlaybackEventListener.onSubtitleChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
         * @note Default to false if unavailable.
         *
         */
        this.subtitleVisibility = Check.assigned(subtitleVisibility)
            ? subtitleVisibility
            : false;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.PresentationType>}
         * @desc The type of presentation currently being played.
         * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
         * event or the `SDK.Media.PlaybackStartedEvent` object.
         *
         */
        this.presentationType = presentationType || PresentationType.unknown;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.AdInsertionType>}
         * @desc The way ads are inserted into the stream.
         * @note Source by converting the `SDK.Media.MediaDescriptor.AssetInsertionStrategy`.
         *
         */
        this.adInsertionType = adInsertionType;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.SubscriptionType>}
         * @desc An identifier that provides insight into the tier of service associated with the subscription that is entitled for playback.
         * @note Required if `adInsertionType` != `none`.
         * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
         *
         */
        this.subscriptionType = subscriptionType || '';

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String|undefined}
         * @desc Random UUID assigned by the ad server.
         * @note Required if `adInsertionType` != `none`.
         * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
         *
         */
        this.adSessionId = adSessionId;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>|undefined}
         * @desc Placement information relevant to ad pods.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the associated `PlaybackEventListener` event arguments.
         *
         */
        this.adPodPlacement = adPodPlacement;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdPodData>|undefined}
         * @desc Metadata relevant to ad pods.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.adPodData = adPodData;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdSlotData>|undefined}
         * @desc Metadata relevant to ad slots.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.adSlotData = adSlotData;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Number|undefined}
         * @desc The location of the current ad playhead, measured as a millisecond offset from the start time of the current ad pod.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.adPlayheadPosition = adPlayheadPosition;

        /**
         *
         * @deprecated Value will be reported as 'deprecated'. Replaced by `presentationType`.
         * @access public
         * @since 20.0.1
         * @type {String}
         *
         */
        this.periodType = 'deprecated';

        this.setData(data);
    }

    // #region private

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    setData(data) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.PlaybackHeartbeatEventData';
    }

    // #endregion
}
