/**
 *
 * @module commerceClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

interface CommerceClientExtrasMapOptions {
    clientId?: string;
    namespaceId?: number;
    createPaymentMethodRegionalEndpoints?: { [key: string]: string };
}

/**
 *
 * @access protected
 *
 */
export default class CommerceClientExtrasMap {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public clientId?: string;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Number}
     *
     */
    public namespaceId?: number;

    /**
     *
     * @access public
     * @type {Object<String>}
     *
     */
    public createPaymentMethodRegionalEndpoints?: { [key: string]: string };

    /**
     *
     * @param {Object} options
     * @param {String} [options.namespace]
     * @param {String} [options.clientId]
     * @param {Number} [options.namespaceId]
     * @param {Object} [options.createPaymentMethodRegionalEndpoints]
     *
     */
    public constructor(options: CommerceClientExtrasMapOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString.optional,
                    namespaceId: Types.number.optional,
                    createPaymentMethodRegionalEndpoints:
                        Types.object().optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const { clientId, namespaceId, createPaymentMethodRegionalEndpoints } =
            options || {};

        this.clientId = clientId;
        this.namespaceId = namespaceId;
        this.createPaymentMethodRegionalEndpoints =
            createPaymentMethodRegionalEndpoints;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Commerce.CommerceClientExtrasMap';
    }

    // #endregion
}
