/**
 *
 * @module entry
 * @desc {SDK.Services.QualityOfService} entry point.
 *
 */

import ApplicationContext from './applicationContext';
import AudioChangedEventData from './audioChangedEventData';
import BufferType from './bufferType';
import ErrorEventData from './errorEventData';
import ErrorLevel from './errorLevel';
import ErrorSource from './errorSource';
import ExperimentPlatform from './experimentPlatform';
import ExperimentsDetails from './experimentsDetails';
import FetchStatus from './fetchStatus';
import HeartbeatSampleType from './heartbeatSampleType';
import HttpMethod from './httpMethod';
import MediaFetchError from './mediaFetchError';
import MediaSegmentFetchedEventData from './mediaSegmentFetchedEventData';
import MediaSegmentType from './mediaSegmentType';
import NetworkError from './networkError';
import NetworkType from './networkType';
import PlaybackActivity from './playbackActivity';
import PlaybackAdEventData from './playbackAdEventData';
import PlaybackEventData from './playbackEventData';
import PlaybackExitedCause from './playbackExitedCause';
import PlaybackHeartbeatEventData from './playbackHeartbeatEventData';
import PlaybackIntent from './playbackIntent';
import PlaybackPausedCause from './playbackPausedCause';
import PlaybackResumedCause from './playbackResumedCause';
import PlaybackSeekCause from './playbackSeekCause';
import PlaybackSessionTransferredEventData from './playbackSessionTransferredEventData';
import PlaybackSnapshotEventData from './playbackSnapshotEventData';
import PlaybackStartupEventData from './playbackStartupEventData';
import PlaybackState from './playbackState';
import PlayerSeekDirection from './playerSeekDirection';
import PlaylistBitrateType from './playlistBitrateType';
import ProductType from './productType';
import QoeError from './qoeError';
import QoePlaybackError from './qoePlaybackError';
import SeekDirection from './seekDirection';
import ServerRequest from './serverRequest';
import StartupActivity from './startupActivity';
import SubtitleChangedEventData from './subtitleChangedEventData';
import mapHiveToQoeErrorCodes from './mapHiveToQoeErrorCodes';

import {
    AdActivity,
    AdInsertionType,
    PodPosition,
    PresentationType,
    SnapshotEventType,
    SnapshotNextAction,
    SnapshotDownloadDataType,
    SnapshotDownloadFailure
} from './enums';

/**
 *
 * @typedef {Object} SDK.Services.QualityOfService
 *
 * @property {SDK.Services.QualityOfService.AdActivity} AdActivity
 * @property {SDK.Services.QualityOfService.AdInsertionType} AdInsertionType
 * @property {SDK.Services.QualityOfService.ApplicationContext} ApplicationContext
 * @property {AudioChangedEventData} AudioChangedEventData
 * @property {SDK.Services.QualityOfService.BufferType} BufferType
 * @property {ErrorEventData} ErrorEventData
 * @property {SDK.Services.QualityOfService.ErrorLevel} ErrorLevel
 * @property {SDK.Services.QualityOfService.ErrorSource} ErrorSource
 * @property {SDK.Services.QualityOfService.ExperimentPlatform} ExperimentPlatform
 * @property {ExperimentsDetails} ExperimentsDetails
 * @property {SDK.Services.QualityOfService.FetchStatus} FetchStatus
 * @property {HeartbeatSampleType} HeartbeatSampleType
 * @property {SDK.Services.QualityOfService.HttpMethod} HttpMethod
 * @property {Function} mapHiveToQoeErrorCodes
 * @property {SDK.Services.QualityOfService.MediaFetchError} MediaFetchError
 * @property {MediaSegmentFetchedEventData} MediaSegmentFetchedEventData
 * @property {SDK.Services.QualityOfService.MediaSegmentType} MediaSegmentType
 * @property {SDK.Services.QualityOfService.NetworkError} NetworkError
 * @property {SDK.Services.QualityOfService.NetworkType} NetworkType
 * @property {SDK.Services.QualityOfService.PlaybackActivity} PlaybackActivity
 * @property {PlaybackAdEventData} PlaybackAdEventData
 * @property {PlaybackEventData} PlaybackEventData
 * @property {SDK.Services.QualityOfService.PlaybackExitedCause} PlaybackExitedCause
 * @property {PlaybackHeartbeatEventData} PlaybackHeartbeatEventData
 * @property {SDK.Services.QualityOfService.PlaybackIntent} PlaybackIntent
 * @property {SDK.Services.QualityOfService.PlaybackPausedCause} PlaybackPausedCause
 * @property {SDK.Services.QualityOfService.PlaybackResumedCause} PlaybackResumedCause
 * @property {SDK.Services.QualityOfService.PlaybackSeekCause} PlaybackSeekCause
 * @property {PlaybackSessionTransferredEventData} PlaybackSessionTransferredEventData
 * @property {PlaybackSnapshotEventData} PlaybackSnapshotEventData
 * @property {PlaybackStartupEventData} PlaybackStartupEventData
 * @property {SDK.Services.QualityOfService.PlaybackState} PlaybackState
 * @property {SDK.Services.QualityOfService.PlayerSeekDirection} PlayerSeekDirection
 * @property {SDK.Services.QualityOfService.PlaylistBitrateType} PlaylistBitrateType
 * @property {SDK.Services.QualityOfService.PodPosition} PodPosition
 * @property {SDK.Services.QualityOfService.PresentationType} PresentationType
 * @property {SDK.Services.QualityOfService.ProductType} ProductType
 * @property {QoeError} QoeError
 * @property {SDK.Services.QualityOfService.QoePlaybackError} QoePlaybackError
 * @property {SDK.Services.QualityOfService.SeekDirection} SeekDirection
 * @property {SDK.Services.QualityOfService.ServerRequest} ServerRequest
 * @property {SDK.Services.QualityOfService.SnapshotDownloadDataType} SnapshotDownloadDataType
 * @property {SDK.Services.QualityOfService.SnapshotDownloadFailure} SnapshotDownloadFailure
 * @property {SDK.Services.QualityOfService.SnapshotEventType} SnapshotEventType
 * @property {SDK.Services.QualityOfService.SnapshotNextAction} SnapshotNextAction
 * @property {SDK.Services.QualityOfService.StartupActivity} StartupActivity
 * @property {SubtitleChangedEventData} SubtitleChangedEventData
 *
 */
export default {
    ApplicationContext,
    AudioChangedEventData,
    BufferType,
    ErrorEventData,
    ErrorLevel,
    ErrorSource,
    ExperimentPlatform,
    ExperimentsDetails,
    FetchStatus,
    HeartbeatSampleType,
    HttpMethod,
    mapHiveToQoeErrorCodes,
    MediaFetchError,
    MediaSegmentFetchedEventData,
    MediaSegmentType,
    NetworkError,
    NetworkType,
    PlaybackActivity,
    PlaybackAdEventData,
    PlaybackEventData,
    PlaybackExitedCause,
    PlaybackHeartbeatEventData,
    PlaybackIntent,
    PlaybackPausedCause,
    PlaybackResumedCause,
    PlaybackSeekCause,
    PlaybackSessionTransferredEventData,
    PlaybackSnapshotEventData,
    PlaybackStartupEventData,
    PlaybackState,
    PlayerSeekDirection,
    PlaylistBitrateType,
    ProductType,
    QoeError,
    QoePlaybackError,
    SeekDirection,
    ServerRequest,
    StartupActivity,
    SubtitleChangedEventData,

    AdActivity,
    AdInsertionType,
    PodPosition,
    PresentationType,
    SnapshotEventType,
    SnapshotNextAction,
    SnapshotDownloadDataType,
    SnapshotDownloadFailure
};
