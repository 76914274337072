/**
 *
 * @module mediaSegmentType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.MediaSegmentType
 * @desc Defines the different types of playlists.
 *
 * @property {String} video - Since `4.0.0` - Video
 * @property {String} audio - Since `4.0.0` - Audio
 * @property {String} subtitle - Since `4.0.0` - Subtitle
 * @property {String} iframe - Since `4.0.0` - Iframe
 *
 * @example
 * SDK.Services.QualityOfService.MediaSegmentType.video // 'video'
 *
 */
export default keyMirror({
    video: null,
    audio: null,
    subtitle: null,
    iframe: null
});
