/**
 *
 * @module receiptCredentials
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import getSafe from '../util/getSafe';

export type ReceiptData = {
    $schema: string;
    title: string;
    description: string;
    type: string;
    properties: {
        email: {
            type: string;
            description: string;
        };
        identifierForVendor: {
            type: string;
            description: string;
        };
        receiptBundle: {
            type: string;
            description: string;
        };
    };
    required: Array<string>;
};

/**
 *
 * @public
 * @desc Represents the data used to authenticate a purchase activation.
 *
 */
export default class ReceiptCredentials {
    /**
     *
     * @access public
     * @type {String}
     * @desc The store associated with this receipt.
     *
     */
    public store: string;

    /**
     *
     * @access public
     * @type {ReceiptData}
     * @desc The receipt used to validate the purchase.
     *
     */
    public receipt: ReceiptData;

    /**
     *
     * @param {String} store
     * @param {ReceiptData} receipt
     *
     */
    public constructor(store: string, receipt: ReceiptData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                store: Types.nonEmptyString,
                receipt: Types.object()
            };

            typecheck(this, params, arguments);
        }

        this.store = store;
        this.receipt = receipt;
    }

    /**
     *
     * @access public
     * @returns {Object} The payload being sent to services to validate the store purchase.
     *
     */
    public payload(): Record<string, unknown> {
        const receipt = this.receipt;
        let payload = {};

        switch (this.store) {
            case 'apple':
                payload = {
                    receiptBundle: getSafe(
                        () => receipt.properties.receiptBundle
                    ),
                    email: getSafe(() => receipt.properties.email),
                    identifierForVendor: getSafe(
                        () => receipt.properties.identifierForVendor
                    )
                };
                break;
        }

        return payload;
    }

    /**
     *
     * @access public
     * @returns {String} The name of the store.
     *
     */
    public getStore(): string {
        return this.store;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Purchase.ReceiptCredentials';
    }
}
