/**
 *
 * @module exchangeGrant
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import Grant from './grant';
import TokenRequestBuilder from './tokenRequestBuilder';

/**
 *
 * @desc Provides an implementation for ITokenRequestBuilder used to exchange a grant for an AccessContext.
 *
 */
export default class ExchangeGrant extends TokenRequestBuilder {
    /**
     *
     * @param {Grant} grant - The grant to build a TokenRequest for.
     * @param {String} subjectType - The subject_token_type of the grant.
     *
     */
    constructor(grant, subjectType) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                grant: Types.instanceStrict(Grant),
                subjectType: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Grant}
         *
         */
        this.grant = grant;

        /**
         *
         * @access public
         * @type {String}
         *
         */
        this.subjectType = subjectType;

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.grantType = 'urn:ietf:params:oauth:grant-type:token-exchange';

        // assign data to token data
        this.tokenData.subject_token_type = this.subjectType;
        this.tokenData.subject_token = this.grant.assertion;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.ExchangeGrant';
    }

    // #endregion
}
