/**
 *
 * @module ripcutClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/ripcut.md
 *
 */

/* eslint-disable camelcase */

import { Types, typecheck } from '@dss/type-checking';
import { stringify } from 'query-string';

import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import checkResponseCode from '../util/checkResponseCode';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import HttpMethod from '../configuration/httpMethod';

import appendQuerystring from '../util/appendQuerystring';
import replaceUrl from '../util/replaceUrl';

import { RipcutQueryParams } from '../../ripcut/typedefs';
import RipcutImageRequestMode from '../../ripcut/ripcutImageRequestMode';

import RipcutClientEndpoint from './ripcutClientEndpoint';
import RipcutClientConfiguration from './ripcutClientConfiguration';

const RipcutClientDustUrnReference =
    DustUrnReference.services.ripcut.ripcutClient;

interface RipcutClientOptions {
    ripcutClientConfiguration: RipcutClientConfiguration;
    logger: Logger;
    httpClient: CoreHttpClientProvider;
}

/**
 *
 * @access protected
 * @since 17.0.0
 * @desc Most content images are fetched from a backend service called Ripcut.
 *
 */
export default class RipcutClient {
    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Services.Ripcut.RipcutClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: RipcutClientConfiguration;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {HttpClient}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Ripcut.RipcutClientConfiguration} options.ripcutClientConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: RipcutClientOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    ripcutClientConfiguration: Types.instanceStrict(
                        RipcutClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { ripcutClientConfiguration, logger, httpClient } = options;

        this.config = ripcutClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} options.imageId
     * @param {String<RipcutImageRequestMode>} options.imageRequestMode
     * @param {String} options.partnerId
     * @param {Object<RipcutQueryParams>} [options.queryParams]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Ripcut.RipcutResult>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImage(
        options: {
            imageId: string;
            imageRequestMode: string;
            partnerId: string;
            queryParams?: SDK.Ripcut.RipcutQueryParams;
        },
        logTransaction?: LogTransaction
    ): Promise<SDK.Services.Ripcut.RipcutResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    imageId: Types.nonEmptyString,
                    imageRequestMode: Types.in(RipcutImageRequestMode),
                    partnerId: Types.nonEmptyString,
                    queryParams: Types.object(RipcutQueryParams).optional
                }),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getImage', params, arguments);
        }

        const { imageId, imageRequestMode, partnerId, queryParams } = options;

        const { dustEnabled, logger, httpClient } = this;

        type objectKey = keyof typeof RipcutClientEndpoint;
        const endpointKey = RipcutClientEndpoint[imageRequestMode as objectKey];

        const payload = this.getPayload({
            endpointKey,
            data: {
                imageId,
                partnerId,
                queryParams
            },
            bodyType: 'arrayBuffer'
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: RipcutClientDustUrnReference.getImage,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .request(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data: image, headers } = response;

                const headerError = headers.get('X-BAMTECH-ERROR');

                let error;

                try {
                    error = JSON.parse(headerError);
                } catch (ex) {
                    logger.error(
                        this.toString(),
                        'Could not parse error from "X-BAMTECH-ERROR" header.'
                    );

                    error = null;
                }

                return {
                    image,
                    error
                };
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} options.imageId
     * @param {String<SDK.Ripcut.RipcutImageRequestMode>} options.imageRequestMode
     * @param {String} options.partnerId
     * @param {Object<SDK.Ripcut.RipcutQueryParams>} [options.queryParams]
     * @returns {Promise<String>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImageUrl(options: {
        imageId: string;
        imageRequestMode: SDK.Ripcut.RipcutImageRequestMode;
        partnerId: string;
        queryParams?: SDK.Ripcut.RipcutQueryParams;
    }): Promise<string> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    imageId: Types.nonEmptyString,
                    imageRequestMode: Types.in(RipcutImageRequestMode),
                    partnerId: Types.nonEmptyString,
                    queryParams: Types.object(RipcutQueryParams).optional
                })
            };

            typecheck(this, 'getImageUrl', params, arguments);
        }

        const { imageId, imageRequestMode, partnerId, queryParams } = options;

        type objectKey = keyof typeof RipcutClientEndpoint;
        const endpointKey = RipcutClientEndpoint[imageRequestMode as objectKey];

        const { url } = this.getPayload({
            endpointKey,
            data: {
                imageId,
                partnerId,
                queryParams
            }
        });

        return url;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Ripcut.RipcutClientEndpoint} options.endpointKey
     * @param {Object} options.data
     * @param {Object} [options.bodyType]
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        endpointKey: keyof typeof RipcutClientEndpoint;
        data: {
            imageId: string;
            partnerId: string;
            queryParams?: SDK.Ripcut.RipcutQueryParams;
        };
        bodyType?: string;
    }): GetPayloadResult {
        const { endpointKey, data, bodyType } = options;
        const { imageId, partnerId, queryParams } = data;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { headers, method } = endpoint;
        const requestHeaders = { ...headers };

        const href = replaceUrl(endpoint.href, { imageId, partnerId });

        const url = appendQuerystring(href, stringify(queryParams));

        return {
            url,
            headers: requestHeaders,
            method,
            bodyType
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Ripcut.RipcutClient';
    }
}
