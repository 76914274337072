/**
 *
 * @module accountClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/account.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/account.md
 *
 */

/* eslint-disable camelcase */

import { Types, typecheck } from '@dss/type-checking';

import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';

import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';

import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import OrchestrationClient from '../orchestration/orchestrationClient';

import Account from './account';
import AccountClientConfiguration from './accountClientConfiguration';
import AccountClientEndpoint from './accountClientEndpoint';
import AccountGrant from './accountGrant';
import CreateAccountResult from './createAccountResult';
import GetAccountResult from './getAccountResult';
import UserProfile from './userProfile';
import OnboardAccountToStarRequest from './onboardAccountToStarRequest';
import OnboardProfileToStarRequest from './onboardProfileToStarRequest';
import UpdateProtectProfileCreationWithActionGrantRequest from './updateProtectProfileCreationWithActionGrantRequest';
import ClientBase from '../clientBase';

const AccountClientDustUrnReference =
    DustUrnReference.services.account.accountClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access account services.
 *
 */
export default class AccountClient extends ClientBase<
    typeof AccountClientEndpoint
> {
    /**
     *
     * @access private
     * @type {SDK.Services.Account.AccountClientConfiguration}
     *
     */
    private config: AccountClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {CoreHttpClientProvider}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @since 5.0.0
     * @type {SDK.Services.Orchestration.OrchestrationClient}
     *
     */
    public override orchestrationClient: OrchestrationClient;

    /**
     *
     * @access private
     * @type {String}
     * @desc determines if you should refresh the access token
     *
     */
    private refreshAccessTokenHeader: string;

    /**
     *
     * @access public
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Account.AccountClientConfiguration} options.accountClientConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.Orchestration.OrchestrationClient} options.orchestrationClient
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        accountClientConfiguration: AccountClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        orchestrationClient: OrchestrationClient;
    }) {
        const {
            accountClientConfiguration,
            logger,
            orchestrationClient,
            httpClient
        } = options || {};

        const endpoints =
            accountClientConfiguration && accountClientConfiguration.endpoints;

        super({
            endpoints,
            clientEndpoint: AccountClientEndpoint,
            clientDustUrnReference: AccountClientDustUrnReference,
            orchestrationClient
        });

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountClientConfiguration: Types.instanceStrict(
                        AccountClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    orchestrationClient:
                        Types.instanceStrict(OrchestrationClient)
                })
            };

            typecheck(this, params, arguments);
        }

        this.config = accountClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.orchestrationClient = orchestrationClient;
        this.refreshAccessTokenHeader = 'X-BAMTech-Refresh-Access-Token';
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.identityToken - Identity to create the account from.
     * @param {Object} options.attributes - Attributes to create with the account.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context
     * @param {Object} [options.metadata] - Object which may hold additional information about the account
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Create an account by providing an id token and some registration information.
     * @returns {Promise<SDK.Services.Account.CreateAccountResult>} The account ID if successfully created.
     *
     * @todo Remove as part of https://jira.disneystreaming.com/browse/SDKMRJS-4038
     * see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1522 for reference
     *
     */
    public createAccount(options: {
        identityToken: string;
        attributes: object;
        accessToken: AccessToken;
        metadata?: object;
        logTransaction: LogTransaction;
    }): Promise<CreateAccountResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    identityToken: Types.nonEmptyString,
                    attributes: Types.object(),
                    accessToken: Types.instanceStrict(AccessToken),
                    metadata: Types.object().optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'createAccount', params, arguments);
        }

        const {
            identityToken,
            attributes,
            accessToken,
            metadata,
            logTransaction
        } = options;

        const { dustEnabled, logger, httpClient } = this;

        const body = {
            ...{ id_token: identityToken },
            ...attributes,
            ...metadata
        };

        const endpointKey = AccountClientEndpoint.createAccount;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body
        });

        logger.info(this.toString(), 'Attempting to create account.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.createAccount,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const { accountId } = data;

                return Promise.resolve(new CreateAccountResult(accountId));
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Get an account grant by providing some proof of identity e.g. id token.
     * @returns {Promise<SDK.Services.Account.GetAccountResult>} Describes the result of fetching an Account
     *
     */
    public getCurrentAccount(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<GetAccountResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getCurrentAccount', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = AccountClientEndpoint.getCurrentAccount;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Get current account based on provided token.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.getCurrentAccount,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data, headers } = response;
                const { accountId, activeProfile, attributes, identities } =
                    data;

                const activeUserProfile = new UserProfile(activeProfile);
                const refreshAccessToken = !!headers.get(
                    this.refreshAccessTokenHeader
                );

                const options = {
                    accountId,
                    activeUserProfile,
                    attributes,
                    identities
                };

                const account = new Account(options);
                const getAccountResult = new GetAccountResult(
                    refreshAccessToken,
                    account
                );

                return Promise.resolve(getAccountResult);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @param {String} identityToken - Identity to get the account grant for.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Get an account grant by providing some proof of identity e.g. id token.
     * @returns {Promise<SDK.Services.Account.AccountGrant>} An account grant that can be exchanged for an access token
     * if successful.
     *
     * @todo Remove as part of https://jira.disneystreaming.com/browse/SDKMRJS-4038
     * see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1522 for reference
     *
     */
    public createAccountGrant(
        identityToken: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<AccountGrant> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                identityToken: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createAccountGrant', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = AccountClientEndpoint.createAccountGrant;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                id_token: identityToken
            }
        });

        logger.log(this.toString(), 'Attempting to create account grant.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.createAccountGrant,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const { assertion, grant_type } = data;

                return Promise.resolve(
                    new AccountGrant({
                        assertion,
                        grant_type
                    })
                );
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @param {Object} attributes - The account attributes to update.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Update the account attributes of the account identified in the access token,
     * if field(s) are missing from the request body, the original value will be left intact.
     * @returns {Promise<Void>} An awaitable task indicating settings were updated successfully.
     *
     */
    public async updateAttributes(
        attributes: object,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<TodoAny> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                attributes: Types.object(),
                accessToken: Types.instanceStrict(AccessToken)
            };

            typecheck(this, 'updateAttributes', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = AccountClientEndpoint.updateAccountAttributes;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: attributes
        });

        logger.info(this.toString(), 'Update account attributes.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.updateAttributes,
            payload,
            method: HttpMethod.PATCH,
            endpointKey,
            logTransaction
        });

        if (Object.keys(attributes).length) {
            logger.info(
                this.toString(),
                `Account attributes to update - "${JSON.stringify(
                    attributes
                )}".`
            );
        }

        try {
            const response: TodoAny = await httpClient.patch(payload);

            return await checkResponseCode(response, dustLogUtility);
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {SDK.Services.Account.OnboardProfileToStarRequest} request
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Onboards the active profile to Star.
     * @returns {Promise<Object>} A promise that returns an Object when the operation has succeeded.
     * has succeeded.
     *
     */
    public async onboardProfileToStar(
        request: OnboardProfileToStarRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<object> {
        const nameAndKey = 'onboardProfileToStar';

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.instanceStrict(OnboardProfileToStarRequest),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, nameAndKey, params, arguments);
        }

        return await this.queryGraph({
            nameAndKey,
            request,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {SDK.Services.Account.OnboardAccountToStarRequest} request
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Onboards an account to Star.
     * @returns {Promise<Object>} A promise that returns an Object when the operation has succeeded.
     * has succeeded.
     *
     */
    public async onboardAccountToStar(
        request: OnboardAccountToStarRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<object> {
        const nameAndKey = 'onboardAccountToStar';

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.instanceStrict(OnboardAccountToStarRequest),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, nameAndKey, params, arguments);
        }

        return await this.queryGraph({
            nameAndKey,
            request,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {SDK.Services.Account.UpdateProtectProfileCreationWithActionGrantRequest} request
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Updates the isProfileCreationProtected value for an account.
     * @returns {Promise<Object>} A promise that completes when the operation has succeeded.
     *
     */
    public async updateProtectProfileCreationWithActionGrant(
        request: UpdateProtectProfileCreationWithActionGrantRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<object> {
        const nameAndKey = 'updateProtectProfileCreationWithActionGrant';

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.instanceStrict(
                    UpdateProtectProfileCreationWithActionGrantRequest
                ),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, nameAndKey, params, arguments);
        }

        return await this.queryGraph({
            nameAndKey,
            request,
            accessToken,
            logTransaction
        });
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Account.AccountClientEndpoint} options.endpointKey - endpoint key to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {GetPayloadResult} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof AccountClientEndpoint;
        data?: {
            profileId?: 'string';
        };
        body?: object;
    }): GetPayloadResult {
        const { accessToken, endpointKey, data = {}, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];

        const { headers } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        let href = endpoint.href;

        if (endpoint.templated && data && data.profileId) {
            href = href.replace(/\{profileId\}/gi, data.profileId);
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            body: requestBody,
            headers: requestHeaders as Record<string, string> // TODO: fix once we convert services/util/replaceHeaders to typescript
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Account.AccountClient';
    }

    // #endregion
}
