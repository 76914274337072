/**
 *
 * @module mediaPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import MediaPayloadStream from './mediaPayloadStream';
import MediaPlayhead from './mediaPlayhead';
import MediaThumbnailLinks from './mediaThumbnailLinks';

/**
 *
 * @desc Represents the media payload response from the playlist service.
 *
 */
export default class MediaPayload {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Media.MediaPayloadStream} options.stream
     * @param {Object} options.tracking
     * @param {SDK.Services.Media.MediaPlayhead} options.playhead
     * @param {SDK.Services.Media.MediaThumbnailLinks} [options.thumbnails]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    stream: Types.instanceStrict(MediaPayloadStream),
                    tracking: Types.object(),
                    playhead: Types.instanceStrict(MediaPlayhead),
                    thumbnails:
                        Types.instanceStrict(MediaThumbnailLinks).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { stream, tracking, playhead, thumbnails } = options;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {SDK.Services.Media.MediaPayloadStream}
         * @desc Gets the "complete" media stream URL
         *
         */
        this.stream = stream;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Object}
         * @desc Gets additional information used to track the media.
         *
         */
        this.tracking = tracking;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {SDK.Services.Media.MediaPlayhead}
         * @desc Gets playhead information from the previous playback session for the current user.
         *
         */
        this.playhead = playhead;

        /**
         *
         * @access public
         * @since 3.8.0
         * @type {SDK.Services.Media.MediaThumbnailLinks|undefined}
         * @desc Gets thumbnail information for the media
         *
         */
        this.thumbnails = thumbnails;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaPayload';
    }

    // #endregion
}
