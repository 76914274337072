/**
 *
 * @module playlist
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import MediaSource from './mediaSource';

import AudioRendition from '../services/media/audioRendition';
import SubtitleRendition from '../services/media/subtitleRendition';
import PlaybackVariant from '../services/media/playbackVariant';
import PlaybackAttributes from '../services/media/playbackAttributes';
import PlaylistType from './../services/media/playlistType';

import { MediaAnalyticsKey } from './enums';

/**
 *
 * @since 2.0.0
 * @desc The stream URI and metadata about which playlist stream was picked for
 * playback (in the case of requesting one playlistType and getting another that is available).
 *
 */
export default class Playlist {
    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Array<SDK.Media.MediaSource>}
     * @desc Prioritized Urls ordered by priority.
     *
     */
    public mediaSources: Array<MediaSource>;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Number}
     * @desc Keep track of the Media Source index.
     *
     */
    public mediaSourceIndex: number;

    /**
     *
     * @access public
     * @since 7.0.0
     * @type {Object}
     *
     */
    public trackingInfo: object;

    /**
     *
     * @access public
     * @type {String<SDK.Services.Media.PlaylistType>}
     *
     */
    public playlistType: ValueOf<typeof PlaylistType>;

    /**
     *
     * @access public
     * @type {Array<String<SDK.Services.Media.PlaylistType>>}
     *
     */
    public availablePlaylistTypes: Array<ValueOf<typeof PlaylistType>>;

    /**
     *
     * @access public
     * @since 3.2.0
     * @type {SDK.Services.Media.PlaybackAttributes|undefined}
     * @desc Gets the attributes of the stream.
     *
     */
    public attributes?: PlaybackAttributes;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<SDK.Services.Media.PlaybackVariant>|undefined}
     *
     */
    public variants?: Array<PlaybackVariant>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<SDK.Services.Media.AudioRendition>|undefined}
     *
     */
    public audioRenditions?: Array<AudioRendition>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<SDK.Services.Media.SubtitleRendition>|undefined}
     *
     */
    public subtitleRenditions?: Array<SubtitleRendition>;

    /**
     *
     * @param {Object} options
     * @param {Array<SDK.Media.MediaSource>} options.mediaSources
     * @param {Object} options.trackingInfo
     * @param {String<SDK.Services.Media.PlaylistType>} options.playlistType
     * @param {Array<String<SDK.Services.Media.PlaylistType>>} options.availablePlaylistTypes
     * @param {SDK.Services.Media.PlaybackAttributes} [options.attributes]
     * @param {Array<SDK.Services.Media.PlaybackVariant>} [options.variants]
     * @param {Array<SDK.Services.Media.AudioRendition>} [options.audioRenditions]
     * @param {Array<SDK.Services.Media.SubtitleRendition>} [options.subtitleRenditions]
     * @note availablePlaylistTypes requires two type checks. The first to verify it is an array. The second to test the
     * elements in the array are members of the correct enum. check-types cannot perform array.of.includes().
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    public constructor(options: {
        mediaSources: Array<MediaSource>;
        trackingInfo: object;
        playlistType: ValueOf<typeof PlaylistType>;
        availablePlaylistTypes: Array<ValueOf<typeof PlaylistType>>;
        attributes?: PlaybackAttributes;
        variants?: Array<PlaybackVariant>;
        audioRenditions?: Array<AudioRendition>;
        subtitleRenditions?: Array<SubtitleRendition>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaSources: Types.array.of.instanceStrict(MediaSource),
                    trackingInfo: Types.object(),
                    playlistType: Types.in(PlaylistType),
                    availablePlaylistTypes: Types.array.of.in(PlaylistType),
                    attributes:
                        Types.instanceStrict(PlaybackAttributes).optional,
                    variants:
                        Types.array.of.instanceStrict(PlaybackVariant).optional,
                    audioRenditions:
                        Types.array.of.instanceStrict(AudioRendition).optional,
                    subtitleRenditions:
                        Types.array.of.instanceStrict(SubtitleRendition)
                            .optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            mediaSources,
            playlistType,
            availablePlaylistTypes,
            attributes
        } = options;
        const { trackingInfo, variants, audioRenditions, subtitleRenditions } =
            options;

        this.mediaSources = mediaSources.sort(
            (a, b) => a.priority - b.priority
        );
        this.mediaSourceIndex = 0;
        this.trackingInfo = trackingInfo;
        this.playlistType = playlistType;
        this.availablePlaylistTypes = availablePlaylistTypes;
        this.attributes = attributes;
        this.variants = variants;
        this.audioRenditions = audioRenditions;
        this.subtitleRenditions = subtitleRenditions;
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @desc Gets the current prioritized Url.
     * @returns {String}
     *
     */
    public get streamUri() {
        return this.mediaSources[this.mediaSourceIndex].url;
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @returns {Boolean}
     *
     */
    public advanceNextSource() {
        if (this.mediaSourceIndex + 1 < this.mediaSources.length) {
            this.mediaSourceIndex++;

            return true;
        }

        return false;
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @param {String<SDK.Media.MediaAnalyticsKey>} [key=MediaAnalyticsKey.conviva]
     * @param {Number} [priority]
     * @desc Retrieves the dictionary for the requested analytics provider.
     * @returns {Object}
     *
     */
    public getTrackingData(
        key: MediaAnalyticsKey,
        priority?: number
    ): Record<string, unknown> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                key: Types.in(MediaAnalyticsKey).optional,
                priority: Types.number.optional
            };

            typecheck(this, 'getTrackingData', params, arguments);
        }

        key = key || MediaAnalyticsKey.conviva;

        const trackingData = this.getTrackingInfo(key);

        let mediaSource = this.mediaSources.find(
            (url) => url.priority === priority
        );

        mediaSource = mediaSource || this.mediaSources[this.mediaSourceIndex];

        const { tracking } = mediaSource.primaryContent || {};

        const prioritizedTracking = tracking || {};
        const prioritizedTrackingData =
            prioritizedTracking[key as keyof typeof prioritizedTracking] || {};

        return Object.assign({}, trackingData, prioritizedTrackingData);
    }

    /**
     *
     * @access protected
     * @since 7.0.0
     * @param {String<SDK.Media.MediaAnalyticsKey>} key
     * @desc Retrieves the dictionary for the requested analytics provider.
     * @returns {Object}
     * @note this private method is used by PlaybackTelemetryDispatcher
     *
     */
    public getTrackingInfo(key: MediaAnalyticsKey) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                key: Types.in(MediaAnalyticsKey)
            };

            typecheck(this, 'getTrackingInfo', params, arguments);
        }

        return this.trackingInfo[key as keyof typeof this.trackingInfo] || {};
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.Playlist';
    }
}
