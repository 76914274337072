/**
 *
 * @module entitlementClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/entitlement.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';

import EntitlementClientConfiguration from './entitlementClientConfiguration';
import EntitlementClientEndpoint from './entitlementClientEndpoint';
import AccessToken from '../token/accessToken';
import LogTransaction from '../../logging/logTransaction';
import HttpMethod from '../configuration/httpMethod';

const EntitlementClientDustUrnReference =
    DustUrnReference.services.entitlement.entitlementClient;

interface EntitlementClientOptions {
    entitlementClientConfiguration: EntitlementClientConfiguration;
    logger: Logger;
    httpClient: CoreHttpClientProvider;
}

/**
 *
 * @access protected
 * @since 4.9.0
 * @desc Provides a data client that can be used to access entitlement services.
 *
 */
export default class EntitlementClient {
    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Services.Entitlement.EntitlementClientConfiguration}
     *
     */
    public config: EntitlementClientConfiguration;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {CoreHttpClientProvider}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled = true;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Entitlement.EntitlementClientConfiguration} options.entitlementClientConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: EntitlementClientOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    entitlementClientConfiguration: Types.instanceStrict(
                        EntitlementClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { entitlementClientConfiguration, logger, httpClient } = options;

        this.config = entitlementClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {String} mediaId - The mediaId to verify account has sufficient entitlements to watch.
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns nothing upon successful verification
     * @returns {Promise<Void>}
     *
     */
    public async verifyMediaRights(
        mediaId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<TodoAny> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                mediaId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'verifyMediaRights', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = EntitlementClientEndpoint.verifyMediaRights;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                mediaId
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: EntitlementClientDustUrnReference.verifyMediaRights,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.get(payload);

            return await checkResponseCode(response, dustLogUtility);
        } finally {
            dustLogUtility.log();
        }
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Entitlement.EntitlementClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @returns {GetPayloadResult} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof EntitlementClientEndpoint;
        data?: {
            mediaId?: string;
        };
    }): GetPayloadResult {
        const { accessToken, endpointKey, data = {} } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];

        const { headers } = endpoint;

        let href = endpoint.href;

        if (endpoint.templated && data && data.mediaId) {
            href = href.replace(/\{mediaId\}/gi, data.mediaId);
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            headers: requestHeaders as Record<string, string> // TODO: fix once we convert services/util/replaceHeaders to typescript
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Entitlement.EntitlementClient';
    }

    // #endregion
}
