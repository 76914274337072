/**
 *
 * @module entry
 * @desc {SDK.Socket} entry point.
 *
 */

import FlowControlPolicy from './flowControlPolicy';
import FlowControlPolicyType from './flowControlPolicyType';
import Ping from './ping';
import PingPongMessageProcessor from './pingPongMessageProcessor';
import SocketApi from './socketApi';
import SocketConnectionState from './socketConnectionState';
import SocketEvent from './socketEvent';
import SocketEvents from './socketEvents';
import SocketManager from './socketManager';
import SocketSchemaUrls from '../socket/socketSchemaUrls';

/**
 *
 * @typedef {Object} SDK.Socket
 *
 * @property {FlowControlPolicy} FlowControlPolicy
 * @property {SDK.Socket.FlowControlPolicyType} FlowControlPolicyType
 * @property {Ping} Ping
 * @property {PingPongMessageProcessor} PingPongMessageProcessor
 * @property {SocketApi} SocketApi
 * @property {SDK.Socket.SocketConnectionState} SocketConnectionState
 * @property {SocketEvent} SocketEvent
 * @property {SDK.Socket.SocketEvents} SocketEvents
 * @property {SocketManager} SocketManager
 * @property {SocketSchemaUrls} SocketSchemaUrls
 *
 */
export default {
    FlowControlPolicy,
    FlowControlPolicyType,
    Ping,
    PingPongMessageProcessor,
    SocketApi,
    SocketConnectionState,
    SocketEvent,
    SocketEvents,
    SocketManager,
    SocketSchemaUrls
};
