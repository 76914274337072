/**
 *
 * @module mediaQuality
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.MediaQuality
 * @desc An enumeration of possible user-preferred media qualities.
 * Android, Apple, and Browser supported.
 *
 * @property {String} limited - Since `4.18.0` - Indicates using the limited base device mapping. eg `cbcs-limited`,
 * `ctr-limited`. Playlist responses are limited to 480p resolution, eg for metered networks.
 * @property {String} regular - Since `4.18.0` - Indicates using the regular base device mapping. eg `cbcs-regular`,
 * `ctr-regular`. Playlist responses are limited to 10,000k bitrate.
 * @property {String} high - Since `4.18.0` - Indicates using the high base device mapping. eg `cbcs-high`, `ctr-high`.
 * Playlist responses are limited to 100,000k bitrate.
 *
 * @example
 * MediaQuality.limited // 'limited'
 *
 */
export default keyMirror({
    limited: null,
    regular: null,
    high: null
});
