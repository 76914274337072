/**
 *
 * @module sdkPlugins
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/SdkPluginModel.md
 *
 */

import { IPluginTypes } from './IPlugin';

const plugins: Array<IPluginTypes> = [];

export default class SdkPlugins {
    /**
     *
     * @access public
     * @returns {Array<Object>}
     *
     */
    public static get plugins(): Array<IPluginTypes> {
        return plugins;
    }

    /**
     *
     * @param {Object} plugin
     * @desc stores the referenced `plugin` in an internal collection of plugins.
     * @returns {Void}
     *
     */
    public static addPlugin(plugin: IPluginTypes) {
        plugins.push(plugin);
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.SdkPlugins';
    }

    // #endregion
}
