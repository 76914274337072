/**
 *
 * @module dustServerPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 3.4.0
 * @desc describes any relevant server information
 * @note if the dustEvent did not include a service call this will be unused
 *
 */
export default class DustServerPayload {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    public host: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public path: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public method: string;

    /**
     *
     * @access private
     * @type {Number|undefined}
     *
     */
    public statusCode?: number;

    /**
     *
     * @access private
     * @type {String|undefined}
     *
     */
    public requestId?: string;

    /**
     *
     * @access private
     * @type {Number|undefined}
     *
     */
    public roundTripTime?: number;

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.host
     * @param {String} options.path
     * @param {String} options.method
     * @param {Number} [options.statusCode]
     * @param {String} [options.requestId]
     * @param {Number} [options.roundTripTime]
     * @note unfortunately some services return an empty string for requestId
     *
     */
    public constructor(options: {
        host: string;
        path: string;
        method: string;
        statusCode?: number;
        requestId?: string;
        roundTripTime?: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    host: Types.nonEmptyString,
                    path: Types.nonEmptyString,
                    method: Types.nonEmptyString,
                    statusCode: Types.number.optional,
                    requestId: Types.nonEmptyString.optional,
                    roundTripTime: Types.number.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { host, path, method, statusCode, requestId, roundTripTime } =
            options;

        this.host = host;
        this.path = path;
        this.method = method;
        this.statusCode = statusCode;
        this.requestId = requestId;
        this.roundTripTime = roundTripTime;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustServerPayload';
    }
}
