/**
 *
 * @module replaceHeaders
 *
 */

import { Check } from '@dss/type-checking';

/**
 *
 * @access private
 * @since 18.0.0
 * @param {*} value - Any value
 * @Desc Converts value to String.
 * @returns {String}
 *
 */
function toString(value: unknown) {
    if (Check.object(value)) {
        return JSON.stringify(value);
    }

    if (Check.date(value)) {
        return value.toISOString();
    }

    return `${value}`;
}

/**
 *
 * @access protected
 * @since 16.0.0
 * @param {Object} replacerConfig - A replacer config object.
 * @desc Replaces a property value in `headers` using a replacement object.
 * @param {Object} headers - A headers object.
 * @param {Object} [optionalHeaders] - An optional headers object.
 * @returns {Object} A new headers object.
 *
 * @example Replace parts of a header value
 * replaceHeaders({ Authorization: { replacer: '{accessToken}', value: accessToken.token } }, headers)
 *
 * @note Values will be stringified as header values are always string.
 * @note If a header's new value is not defined, then the config value is not going to be replaced.
 */
export default function (
    replacerConfig: object,
    headers: Record<string, string>,
    optionalHeaders?: Record<string, string>
) {
    const newHeaders = { ...headers };

    // for each header in replacerConfig, invoke the replacer function to get the new value and replace the original value with it
    Object.entries(replacerConfig).forEach(([key, replacementFunction]) => {
        const originalValue = headers[key] ?? optionalHeaders?.[key];

        // if header exists then replace it
        if (Check.assigned(originalValue)) {
            const {
                replacer,
                value,
                removeIfEmpty = false
            } = replacementFunction(originalValue);

            if (Check.assigned(value)) {
                if (replacer) {
                    newHeaders[key] = originalValue.replace(
                        new RegExp(replacer, 'gi'),
                        toString(value)
                    );
                } else {
                    newHeaders[key] = toString(value);
                }
            } else if (removeIfEmpty) {
                delete newHeaders[key];
            }
        }
    });

    return newHeaders;
}
