/**
 *
 * @module purchaseClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/purchase.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/store.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import PurchaseActivation from './purchaseActivation';
import PurchaseActivationResult from './purchaseActivationResult';
import PurchaseActivationStatus from './purchaseActivationStatus';
import PurchaseClientEndpoint from './purchaseClientEndpoint';
import PurchaseClientConfiguration from './purchaseClientConfiguration';
import ReceiptCredentials from './receiptCredentials';
import LogTransaction from '../../logging/logTransaction';
import getSafe from '../util/getSafe';

import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';

import ErrorReason from '../exception/errorReason';

const PurchaseClientDustUrnReference =
    DustUrnReference.services.purchase.purchaseClient;

type PurchaseData = {
    products: Array<string>;
    sku: string;
    status: string;
    reason: ErrorData;
};

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access purchase services.
 *
 */
export default class PurchaseClient {
    /**
     *
     * @access private
     * @type {SDK.Services.Purchase.PurchaseClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: PurchaseClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {CoreHttpClientProvider}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {String}
     * @desc determines if you should refresh the access token
     * @note Todo - Maybe centralize this string if it's going to be used in many places
     *
     */
    private refreshAccessTokenHeader: string;

    /**
     *
     * @access public
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.Purchase.PurchaseClientConfiguration} purchaseClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        purchaseClientConfiguration: PurchaseClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                purchaseClientConfiguration: Types.instanceStrict(
                    PurchaseClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = purchaseClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.refreshAccessTokenHeader = 'X-BAMTech-Refresh-Access-Token';
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Purchase.ReceiptCredentials} receiptCredentials - Information about the purchase made in the
     * Apple store.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Redeem just made purchases to watch on the current device.
     * @returns {Promise<SDK.Services.Purchase.PurchaseActivationResult>} Result indicating activation success and any
     * active entitlements.
     *
     */
    public redeemPurchases(
        receiptCredentials: ReceiptCredentials,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PurchaseActivationResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                receiptCredentials: Types.instanceStrict(ReceiptCredentials),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'redeemPurchases', params, arguments);
        }

        const { dustEnabled, logger } = this;

        const endpointKey = PurchaseClientEndpoint.redeemPurchases;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            receiptCredentials
        });

        logger.info(
            this.toString(),
            `redeemPurchases - receiptCredentials(${JSON.stringify(
                receiptCredentials,
                null,
                2
            )})`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: PurchaseClientDustUrnReference.redeemPurchases,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data = {}, headers } = response;
                const {
                    temporaryAccessGranted = false,
                    invalid = [],
                    purchases = []
                } = data;
                const refreshAccessToken = !!headers.get(
                    this.refreshAccessTokenHeader
                );

                const errors = this.getErrorReasons(invalid);
                const purchaseActivations =
                    this.getPurchaseActivations(purchases);

                return Promise.resolve(
                    new PurchaseActivationResult({
                        refreshAccessToken,
                        temporaryAccessGranted,
                        errors,
                        purchases: purchaseActivations
                    })
                );
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Purchase.ReceiptCredentials} receiptCredentials - Information about the purchase made in the
     * Apple store.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Redeem previously made purchases to watch on the current device.
     * @returns {Promise<SDK.Services.Purchase.PurchaseActivationResult>} Result indicating activation success and any
     * active entitlements.
     *
     */
    public restorePurchases(
        receiptCredentials: ReceiptCredentials,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PurchaseActivationResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                receiptCredentials: Types.instanceStrict(ReceiptCredentials),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'restorePurchases', params, arguments);
        }

        const { dustEnabled, logger } = this;

        const endpointKey = PurchaseClientEndpoint.restorePurchases;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            receiptCredentials
        });

        logger.info(
            this.toString(),
            `restorePurchases - receiptCredentials(${JSON.stringify(
                receiptCredentials,
                null,
                2
            )})`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: PurchaseClientDustUrnReference.restorePurchases,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data = {}, headers } = response;
                const {
                    temporaryAccessGranted = false,
                    invalid = [],
                    purchases = []
                } = data;
                const refreshAccessToken = !!headers.get(
                    this.refreshAccessTokenHeader
                );
                const errors = this.getErrorReasons(invalid);
                const purchaseActivations =
                    this.getPurchaseActivations(purchases);

                return Promise.resolve(
                    new PurchaseActivationResult({
                        refreshAccessToken,
                        temporaryAccessGranted,
                        errors,
                        purchases: purchaseActivations
                    })
                );
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Purchase.PurchaseClientEndpoint} options.endpointKey
     * @param {SDK.Services.Purchase.ReceiptCredentials} options.receiptCredentials
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof PurchaseClientEndpoint;
        receiptCredentials: ReceiptCredentials;
    }): GetPayloadResult {
        const { accessToken, endpointKey, receiptCredentials } = options;
        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, headers } = endpoint;

        const body = JSON.stringify(receiptCredentials.payload());
        const url = href.replace('{store}', receiptCredentials.getStore());

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url,
            body,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     * @param {ErrorData} error
     * @returns {SDK.Services.Exception.ErrorReason}
     *
     */
    private getErrorReason(error: ErrorData): ErrorReason | undefined {
        let errorReason;

        if (getSafe(() => error.code)) {
            errorReason = new ErrorReason(
                error.code,
                getSafe(() => error.description)
            );
        }

        return errorReason;
    }

    /**
     *
     * @access private
     * @param {Array<ErrorData>} errors
     * @returns {Array<SDK.Services.Exception.ErrorReason>}
     *
     */
    private getErrorReasons(errors: Array<ErrorData>): Array<ErrorReason> {
        const errorReasons: Array<ErrorReason> = [];

        if (Check.array(errors)) {
            errors.forEach((error) => {
                const errorReason = this.getErrorReason(error);

                if (errorReason) {
                    errorReasons.push(errorReason);
                }
            });
        }

        return errorReasons;
    }

    /**
     *
     * @access private
     * @param {Array<Purchase>} purchases
     * @returns {Array<SDK.Services.Purchase.PurchaseActivation>}
     *
     */
    private getPurchaseActivations(
        purchases: Array<PurchaseData>
    ): Array<PurchaseActivation> {
        const purchaseActivations: Array<PurchaseActivation> = [];

        purchases.forEach((purchase) => {
            const { products, sku } = purchase;
            const reason = this.getErrorReason(purchase.reason);
            const status =
                PurchaseActivationStatus[
                    purchase.status as keyof typeof PurchaseActivationStatus
                ];

            purchaseActivations.push(
                new PurchaseActivation({
                    products,
                    sku,
                    status,
                    reason
                })
            );
        });

        return purchaseActivations;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Purchase.PurchaseClient';
    }
}
