/**
 *
 * @module entry
 * @desc {SDK.Commerce.Klarna} entry point.
 *
 */

import KlarnaApi from './klarnaApi';

/**
 *
 * @typedef {Object} SDK.Commerce.Klarna
 *
 * @property {KlarnaApi} KlarnaApi
 *
 */
export default {
    KlarnaApi
};
