/**
 *
 * @module errorCode
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/sdkErrorCases.json
 * @see https://github.bamtech.co/userservices/orchestration-api/blob/master/OrchestrationApi.yaml#L99
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Error-Handling.mm
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Error-Handling.md#error-cases
 * @see https://docs.google.com/spreadsheets/d/1kHtlh7D08dArPp97iYTMHA2aIoBtK2bqBIbIUK1PJiM
 *
 */

import { Exception } from './exceptionDefinitions';
import ExceptionReference from './exceptionReference';

type ServiceErrorFormat = {
    code: string;
    description?: Array<string>;
    status?: Array<number>;
    exceptionData: Exception;
};

/**
 *
 * @access private
 * @since 3.7.0
 * @typedef {Object} SDK.Services.Exception.ErrorCode
 * @desc Internal error code reference object.
 *
 */
export default {
    accountArchived: {
        code: 'account.archived',
        exceptionData: ExceptionReference.orchestration.accountArchived
    },
    accountBlocked: {
        code: 'account.blocked',
        status: [200, 403],
        exceptionData: ExceptionReference.account.accountBlocked
    },
    accountGetFailed: {
        code: 'account.get.failed',
        status: [200, 401, 404],
        exceptionData: ExceptionReference.account.accountNotFound
    },
    accountIdMissing: {
        code: 'account-id.missing',
        status: [400],
        exceptionData: ExceptionReference.media.accountIdMissing
    },
    accountLoginFailed: {
        code: 'account.login.failed',
        exceptionData: ExceptionReference.account.accountLoginFailed
    },
    accountNotEligibleAdTier: {
        code: 'account.ad-tier.not-eligible',
        exceptionData: ExceptionReference.orchestration.accountNotEligibleAdTier
    },
    accountNotFound: {
        code: 'account.not.found',
        status: [200, 401, 404],
        exceptionData: ExceptionReference.account.accountNotFound
    },
    accountProfilePinDeleteFailed: {
        code: 'account.profile.pin.delete.failed',
        exceptionData: ExceptionReference.orchestration.profilePinDeleteFailed
    },
    accountProfilePinGetFailed: {
        code: 'account.profile.pin.get.failed',
        exceptionData:
            ExceptionReference.orchestration.profilePinRetrievalFailed
    },
    deviceIdInvalid: {
        code: 'device.error.device.id.invalid',
        exceptionData: ExceptionReference.orchestration.deviceIdInvalid
    },
    userProfileDeleteFailed: {
        code: 'account.profile.delete.failed',
        status: [200],
        exceptionData: ExceptionReference.orchestration.userProfileDeleteFailed
    },
    profileLookupIdFailed: {
        code: 'profile.lookup.id.failed',
        status: [200],
        exceptionData: ExceptionReference.orchestration.userProfileNotFound
    },
    userProfileNotFound: {
        code: 'account.profile.not.found',
        status: [200],
        exceptionData: ExceptionReference.orchestration.userProfileNotFound
    },
    profileRetrieval: {
        code: 'account.profile.read.failed',
        status: [200],
        exceptionData: ExceptionReference.orchestration.profileRetrieval
    },
    userProfileUpdateFailed: {
        code: 'profile.update.failed',
        status: [200],
        exceptionData: ExceptionReference.orchestration.userProfileUpdateFailed
    },
    maximumProfilesReached: {
        code: 'account.profiles.max.exceeded',
        status: [200],
        exceptionData: ExceptionReference.orchestration.maximumProfilesReached
    },
    accountResolution: {
        code: 'account.resolution.failure',
        exceptionData: ExceptionReference.eligibility.accountResolution
    },
    accountSecurityFlagged: {
        code: 'account.security-flagged',
        exceptionData: ExceptionReference.orchestration.accountSecurityFlagged
    },
    accountVerificationRequired: {
        code: 'account.verification.required',
        exceptionData:
            ExceptionReference.orchestration.accountVerificationRequired
    },
    accountIdentityVerificationRequired: {
        code: 'idp.error.identity.verification.required',
        exceptionData:
            ExceptionReference.orchestration.accountVerificationRequired
    },
    identityPasswordResetRequired: {
        code: 'idp.error.identity.password-reset-required',
        exceptionData:
            ExceptionReference.orchestration.identityPasswordResetRequired
    },
    actionGrantRejected: {
        code: 'account.error.action-grant.invalid',
        exceptionData: ExceptionReference.account.actionGrantRejected
    },
    ageNotVerified: {
        code: 'age-not-verified',
        status: [403],
        exceptionData: ExceptionReference.userProfile.ageNotVerified
    },
    ageNotVerifiedKr: {
        code: 'age-not-verified-kr',
        status: [403],
        exceptionData: ExceptionReference.userProfile.ageNotVerifiedKr
    },
    authorizationExpired: {
        code: 'access-token.expired',
        status: [401],
        exceptionData: ExceptionReference.media.authorizationExpired
    },
    blackout: {
        code: 'blackout',
        status: [403],
        exceptionData: ExceptionReference.media.blackout
    },
    channelNoAiring: {
        code: 'channel.no-airing',
        status: [404],
        exceptionData: ExceptionReference.media.mediaUnavailable
    },
    channelNotFound: {
        code: 'channel.not-found',
        status: [404],
        exceptionData: ExceptionReference.media.mediaUnavailable
    },
    clientIdNotFound: {
        code: 'payload.client-id.not.found',
        exceptionData: ExceptionReference.orchestration.clientIdNotFound
    },
    clientRevoked: {
        code: 'client-revoked',
        status: [403],
        exceptionData: ExceptionReference.media.mediaNotAllowed
    },
    clientUnsupportedFeature: {
        code: 'client.unsupported-feature',
        exceptionData: ExceptionReference.common.clientUnsupportedFeature
    },
    clockSync: {
        code: 'clock-sync',
        status: [403],
        exceptionData: ExceptionReference.media.mediaNotAllowed
    },
    comcastConsentRefused: {
        code: 'comcast.commerce.403',
        exceptionData: ExceptionReference.commerce.comcastConsentRefused
    },
    comcastServerError: {
        code: 'comcast.commerce.500',
        exceptionData: ExceptionReference.commerce.comcastServerError
    },
    comcastServiceUnavailable: {
        code: 'comcast.commerce.503',
        exceptionData: ExceptionReference.commerce.comcastServiceUnavailable
    },
    contentKeyNotFound: {
        code: 'content-key.not-found',
        status: [404],
        exceptionData: ExceptionReference.media.keyNotFound
    },
    copyFailure: {
        code: 'copy.failure',
        exceptionData: ExceptionReference.account.linkSubscriptionPartial
    },
    copyLimitReached: {
        code: 'copy.limit.reached',
        exceptionData: ExceptionReference.account.linkSubscriptionPartial
    },
    documentsRetrievalFailed: {
        code: 'documents.retrieval.failure',
        exceptionData: ExceptionReference.orchestration.documentsRetrievalFailed
    },
    downgrade: {
        code: 'downgrade',
        status: [403],
        exceptionData: ExceptionReference.drm.downgrade
    },
    downloadLimitReached: {
        code: 'not-permitted-renewal',
        description: ['download-limit-reached'],
        exceptionData: ExceptionReference.media.downloadLimitReached
    },
    expectedSubscriptionsNotFound: {
        code: 'expected.subscriptions.not.found',
        status: [403],
        exceptionData: ExceptionReference.purchase.bundleForbidden
    },
    flowControlPolicyConflict: {
        code: 'policy.conflict',
        exceptionData: ExceptionReference.socket.flowControlPolicyConflict
    },
    flowControlPolicyInvalid: {
        code: 'policy.invalid',
        exceptionData: ExceptionReference.socket.flowControlPolicyInvalid
    },
    flowControlEventInvalid: {
        code: 'event.invalid',
        exceptionData: ExceptionReference.socket.flowControlEventInvalid
    },
    forbidden: {
        code: 'forbidden',
        status: [403],
        exceptionData: ExceptionReference.common.requestTemporarilyBlocked
    },
    fraudAccessRevoked: {
        code: 'not-permitted-offline',
        description: ['fraud-access-revoked'],
        exceptionData: ExceptionReference.media.fraudDetectionViolation
    },
    huluService: {
        code: 'hulu.service.failure',
        exceptionData: ExceptionReference.eligibility.huluService
    },
    identityAlreadyExists: {
        code: 'idp.error.identity.already-exists',
        exceptionData: ExceptionReference.orchestration.identityAlreadyExists
    },
    identityAlreadyUsed: {
        code: 'identity.already.used',
        exceptionData: ExceptionReference.account.identityAlreadyUsed
    },
    idpErrorIdentityBadCredentials: {
        code: 'idp.error.identity.bad-credentials',
        status: [200, 401],
        exceptionData: ExceptionReference.orchestration.invalidCredentials
    },
    idpErrorPasswordInvalidValue: {
        code: 'idp.error.password.enhanced.invalid-value',
        exceptionData: ExceptionReference.orchestration.invalidPassword
    },
    idpErrorResetTokenAlreadyUsed: {
        code: 'idp.error.reset-token.already-used',
        exceptionData: ExceptionReference.orchestration.tokenAlreadyUsed
    },
    idpErrorResetTokenInvalid: {
        code: 'idp.error.reset-token.invalid',
        exceptionData: ExceptionReference.orchestration.tokenInvalid
    },
    idpErrorAuthenticationBlocked: {
        code: 'idp.error.identity.blocked',
        status: [200, 403],
        exceptionData: ExceptionReference.orchestration.authenticationBlocked
    },
    idpErrorIdentityNotFound: {
        code: 'idp.error.identity.not-found',
        exceptionData: ExceptionReference.orchestration.identityNotFound
    },
    idpFailureDataStoreThrottling: {
        code: 'idp.failure.datastore.throttling-exception',
        status: [200],
        exceptionData: ExceptionReference.common.temporarilyThrottled
    },
    invalidScaReturnUrl: {
        code: 'invalid.sca.return.url',
        exceptionData: ExceptionReference.orchestration.invalidAuthenticationUrl
    },
    invalidCampaign: {
        code: 'invalid.campaign',
        exceptionData: ExceptionReference.orchestration.invalidCampaign
    },
    invalidCardSecurityCode: {
        code: 'invalid.card.security.code',
        exceptionData: ExceptionReference.orchestration.invalidSecurityCode
    },
    invalidEmail: {
        code: 'idp.error.params.invalid-email',
        exceptionData: ExceptionReference.orchestration.invalidEmail
    },
    invalidEmailUpdate: {
        code: 'idp.error.identity.invalid-email-update',
        exceptionData: ExceptionReference.orchestration.invalidEmail
    },
    invalidGrant: {
        code: 'invalid_grant',
        exceptionData: ExceptionReference.common.authenticationExpired
    },
    invalidLineItemsCount: {
        code: 'invalid.line.items.count',
        exceptionData: ExceptionReference.orchestration.invalidOrder
    },
    invalidOrderCampaignsCount: {
        code: 'invalid.order.campaigns.count',
        exceptionData: ExceptionReference.orchestration.invalidCampaign
    },
    invalidOrderId: {
        code: 'invalid.order.id',
        exceptionData: ExceptionReference.commerce.invalidOrderId
    },
    invalidPasscode: {
        code: 'idp.error.otp.invalid-passcode',
        exceptionData: ExceptionReference.orchestration.invalidPasscode
    },
    invalidPaymentMethodId: {
        code: 'invalid.payment.method.id',
        exceptionData: ExceptionReference.orchestration.invalidPaymentMethod
    },
    invalidPreAuthToken: {
        code: 'idp.error.pre-auth.invalid-token',
        exceptionData: ExceptionReference.orchestration.invalidPreAuthToken
    },
    invalidRedemptionCode: {
        code: 'redemption.redeem.invalid.redemptionCode',
        status: [422],
        exceptionData: ExceptionReference.commerce.invalidRedemptionCode
    },
    invalidVoucher: {
        code: 'invalid.voucher',
        exceptionData: ExceptionReference.orchestration.invalidVoucher
    },
    keyNotFound: {
        code: 'key.not-found',
        status: [404],
        exceptionData: ExceptionReference.media.keyNotFound
    },
    kidsModeEnabled: {
        code: 'kids-mode-enabled',
        status: [403],
        exceptionData: ExceptionReference.media.kidsModeEnabled
    },
    licensePlateNotFound: {
        code: 'device.error.license.not.found',
        status: [200],
        exceptionData: ExceptionReference.orchestration.licensePlateNotFound
    },
    linkedElsewhere: {
        code: 'linked.elsewhere',
        exceptionData: ExceptionReference.purchase.activationForbidden
    },
    loginRequired: {
        code: 'login.required',
        status: [403],
        exceptionData: ExceptionReference.media.loginRequired
    },
    marketingPreferenceUpdateFailed: {
        code: 'idp.error.marketing.update.failed',
        exceptionData:
            ExceptionReference.orchestration.marketingPreferenceUpdateFailed
    },
    mediaExperienceContextBodyInvalid: {
        code: 'experience.context.body-invalid',
        status: [400],
        exceptionData:
            ExceptionReference.media.mediaExperienceContextBodyInvalid
    },
    mediaMissing: {
        code: 'media.missing',
        status: [404],
        exceptionData: ExceptionReference.media.mediaUnavailable
    },
    mediaNotPlayable: {
        code: 'media.not-playable',
        status: [404],
        exceptionData: ExceptionReference.media.mediaUnavailable
    },
    notEntitled: {
        code: 'not-entitled',
        status: [403],
        exceptionData: ExceptionReference.media.notEntitled
    },
    notPermittedOffline: {
        code: 'not-permitted-offline',
        status: [403],
        exceptionData: ExceptionReference.media.offlineNotPermitted
    },
    offDeviceGrantInvalid: {
        code: 'device.error.offdevicegrant.invalid',
        status: [200],
        exceptionData: ExceptionReference.orchestration.offDeviceGrantInvalid
    },
    offDeviceActionGrantInvalid: {
        code: 'device.error.invalid.action.grant',
        status: [200],
        exceptionData:
            ExceptionReference.orchestration.offDeviceActionGrantInvalid
    },
    offDeviceMissingActionGrant: {
        code: 'device.error.missing.action.grant',
        status: [200],
        exceptionData:
            ExceptionReference.orchestration.offDeviceMissingActionGrant
    },
    offDeviceMissingRedemptionFlow: {
        code: 'device.error.missing.redemption.flow',
        status: [200],
        exceptionData:
            ExceptionReference.orchestration.offDeviceMissingRedemptionFlow
    },
    orderProductBlocked: {
        code: 'order.product.blocked',
        status: [422],
        exceptionData: ExceptionReference.commerce.orderProductBlocked
    },
    orderCountryInvalid: {
        code: 'order.bin.country.invalid',
        status: [200, 422],
        exceptionData: ExceptionReference.commerce.orderCountryInvalid
    },
    orderFraud: {
        code: 'order.ft.fraud',
        status: [422],
        exceptionData: ExceptionReference.commerce.orderFraud
    },
    orderPaymentDeclined: {
        code: 'order.failed',
        status: [422],
        exceptionData: ExceptionReference.commerce.orderPaymentDeclined
    },
    orderStandaloneExceedsBundle: {
        code: 'order.standalone.exceeds.bundle',
        status: [200, 422],
        exceptionData: ExceptionReference.commerce.orderStandaloneExceedsBundle
    },
    parentalControlsRestricted: {
        code: 'parental-controls-restricted',
        status: [403],
        exceptionData: ExceptionReference.media.parentalControlsRestricted
    },
    partnerNotSupported: {
        code: 'graph.local.partner.not-supported',
        exceptionData: ExceptionReference.orchestration.partnerNotSupported
    },
    payloadFieldsIncorrect: {
        code: 'payload.fields.incorrect',
        status: [200, 404],
        exceptionData: ExceptionReference.orchestration.attributeValidation
    },
    idpPayloadFieldsIncorrectGraphQl: {
        code: 'idp.error.payload.fields.incorrect',
        exceptionData: ExceptionReference.orchestration.attributeValidation
    },
    payloadIdTokenInvalid: {
        code: 'payload.id-token.validation.failed',
        exceptionData: ExceptionReference.account.invalidToken
    },
    pinExpired: {
        code: 'pin-expired',
        status: [403],
        exceptionData: ExceptionReference.userProfile.profilePinExpired
    },
    pinMissing: {
        code: 'pin-missing',
        status: [403],
        exceptionData: ExceptionReference.userProfile.profilePinMissing
    },
    preAuthThrottled: {
        code: 'idp.error.auth.pre-auth.throttled',
        exceptionData: ExceptionReference.orchestration.rateLimited
    },
    profileCreationProtected: {
        code: 'account.profile.creation.protected',
        status: [200],
        exceptionData: ExceptionReference.orchestration.profileCreationProtected
    },
    profileMissing: {
        code: 'profile-missing',
        status: [403],
        exceptionData: ExceptionReference.userProfile.profileMissing
    },
    profilePersonalInfoMissing: {
        code: 'profile.personal-info-missing',
        status: [403],
        exceptionData: ExceptionReference.media.profilePersonalInfoMissing
    },
    profilePinInvalid: {
        code: 'account.profile.pin.invalid',
        status: [200],
        exceptionData: ExceptionReference.orchestration.profilePinInvalid
    },
    profilePinNotEligible: {
        code: 'account.profile.pin.not-eligible',
        status: [200],
        exceptionData: ExceptionReference.userProfile.profilePinNotEligible
    },
    profilePinMissing: {
        code: 'account.profile.pin.missing',
        status: [400, 401, 404],
        exceptionData: ExceptionReference.userProfile.profilePinMissing
    },
    profilePinUpdateFailed: {
        code: 'account.profile.pin.update.failed',
        status: [200],
        exceptionData: ExceptionReference.userProfile.profilePinUpdateFailed
    },
    productBlocked: {
        code: 'redemption.redeem.product.blocked',
        status: [422],
        exceptionData: ExceptionReference.commerce.productBlocked
    },
    providerNotEnabled: {
        code: 'provider.not.enabled',
        status: [400],
        exceptionData: ExceptionReference.purchase.providerNotEnabled
    },
    rateLimited: {
        code: 'idp.error.auth.otp.throttled',
        status: [200, 400],
        exceptionData: ExceptionReference.orchestration.rateLimited
    },
    redirectUriInvalid: {
        code: 'payload.redirect-uri.invalid',
        exceptionData: ExceptionReference.orchestration.redirectUriInvalid
    },
    redirectUriNotAllowed: {
        code: 'payload.redirect-uri.not.allowed',
        exceptionData: ExceptionReference.orchestration.redirectUriNotAllowed
    },
    rejected: {
        code: 'rejected',
        exceptionData: ExceptionReference.drm.rejected
    },
    requestRejected: {
        code: 'request.rejected',
        status: [400],
        exceptionData: ExceptionReference.commerce.cardUpdateRejected
    },
    resourceTimedOut: {
        code: 'resource.db.timeout.error',
        exceptionData: ExceptionReference.orchestration.resourceTimedOut
    },
    securityLevel: {
        code: 'security-level',
        status: [403],
        exceptionData: ExceptionReference.media.mediaNotAllowed
    },
    securityLevelInsufficient: {
        code: 'security-level.insufficient',
        status: [403],
        exceptionData: ExceptionReference.drm.downgrade
    },
    selfAccountHasActiveD2cSubscription: {
        code: 'self.account.has_active_d2c_subscription',
        exceptionData:
            ExceptionReference.orchestration.selfAccountHasActiveD2cSubscription
    },
    selfAccountNotActive: {
        code: 'self.account.not_active',
        exceptionData: ExceptionReference.orchestration.selfAccountNotActive
    },
    selfAccountNotFound: {
        code: 'self.account.not_found',
        exceptionData: ExceptionReference.orchestration.selfAccountNotFound
    },
    selfRequestDuplicate: {
        code: 'self.request.duplicate',
        exceptionData: ExceptionReference.orchestration.selfRequestDuplicate
    },
    streamConcurrency: {
        code: 'stream-concurrency',
        status: [403],
        exceptionData: ExceptionReference.media.streamConcurrency
    },
    streamConcurrencyViolation: {
        code: 'stream-concurrency-violation',
        status: [403],
        exceptionData: ExceptionReference.media.streamConcurrency
    },
    subscriptionNotFound: {
        code: 'subscription.not.found',
        status: [404],
        exceptionData: ExceptionReference.subscription.subscriptionNotFound
    },
    temporarilyUnavailable: {
        code: 'graph.upstream.timeout',
        status: [200],
        exceptionData: ExceptionReference.common.temporarilyUnavailable
    },
    tokenExpired: {
        code: 'token.expired',
        exceptionData: ExceptionReference.purchase.activationTokenExpired
    },
    tokenInvalid: {
        code: 'token.invalid',
        exceptionData: ExceptionReference.purchase.activationBadRequest
    },
    tokenMissing: {
        code: 'token.missing',
        exceptionData: ExceptionReference.purchase.activationBadRequest
    },
    tokenForbidden: {
        code: 'token.forbidden',
        exceptionData: ExceptionReference.purchase.activationForbidden
    },
    tokenServiceInvalidGrant: {
        code: 'token.service.invalid.grant',
        exceptionData: ExceptionReference.common.authenticationExpired
    },
    throttled: {
        code: 'throttled',
        exceptionData: ExceptionReference.common.temporarilyThrottled
    },
    thumbnailsUnavailable: {
        code: 'thumbnails-unavailable',
        status: [404],
        exceptionData: ExceptionReference.media.thumbnailsNotAvailable
    },
    tokenServiceUnauthorizedClient: {
        code: 'token.service.unauthorized.client',
        exceptionData: ExceptionReference.common.locationNotAllowed
    },
    unauthorizedClient: {
        code: 'unauthorized_client',
        description: [
            'unknown-location',
            'unreliable-location',
            'forbidden-location'
        ],
        exceptionData: ExceptionReference.common.locationNotAllowed
    },
    unexpectedError: {
        code: 'graph.local.unexpected',
        status: [200],
        exceptionData: ExceptionReference.common.unexpectedError
    },
    unpriceableOrderError101: {
        code: 'price.order.101',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError102: {
        code: 'price.order.102',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError103: {
        code: 'price.order.103',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError104: {
        code: 'price.order.104',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError110: {
        code: 'price.order.110',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError111: {
        code: 'price.order.111',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError112: {
        code: 'price.order.112',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError113: {
        code: 'price.order.113',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError116: {
        code: 'price.order.116',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError117: {
        code: 'price.order.117',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unpriceableOrderError118: {
        code: 'price.order.118',
        exceptionData: ExceptionReference.commerce.unpriceableOrder
    },
    unspecifiedDrmError: {
        code: 'unspecified-drm-error',
        status: [403],
        exceptionData: ExceptionReference.media.drm
    },
    upgradeRequired: {
        code: 'platform.client.upgrade.required',
        exceptionData: ExceptionReference.common.upgradeRequired
    }
} as Record<string, ServiceErrorFormat>;
