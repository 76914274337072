/**
 *
 * @module mediaFetchErrorMapping
 *
 */

import MediaFetchError from './../qualityOfService/mediaFetchError';

/**
 *
 * @typedef {Object} SDK.Services.Media.MediaFetchErrorMapping
 * @access private
 * @since 9.0.0
 * @desc Maps SDK Error Cases to MediaFetchError enum for QoS.
 *
 */
export default {
    AgeNotVerifiedException: MediaFetchError.ageNotVerified,
    AgeNotVerifiedKrException: MediaFetchError.ageNotVerifiedKr,
    AuthenticationExpiredException: MediaFetchError.authenticationExpired,
    AuthorizationExpiredException: MediaFetchError.authorizationExpired,
    BlackoutException: MediaFetchError.blackout,
    StreamConcurrencyException: MediaFetchError.concurrency,
    MediaUnavailableException: MediaFetchError.contentNotAvailable,
    LocationAcquisitionException: MediaFetchError.locationAcquisitionError,
    LocationNotAllowedException: MediaFetchError.locationNotAllowed,
    LoginRequiredException: MediaFetchError.loginRequired,
    MediaNotAllowedException: MediaFetchError.mediaNotAllowed,
    NetworkException: MediaFetchError.networkFailure,
    NotEntitledException: MediaFetchError.notEntitled,
    ParentalControlsRestrictedException:
        MediaFetchError.parentalControlsRestricted,
    ProfilePinExpiredException: MediaFetchError.pinExpired,
    ProfileMissingException: MediaFetchError.profileMissing,
    ProfilePinMissingException: MediaFetchError.profilePinMissing,
    RequestBlockedException: MediaFetchError.requestBlocked,
    RequestTemporarilyBlockedException:
        MediaFetchError.requestTemporarilyBlocked,
    TemporarilyThrottledException: MediaFetchError.temporarilyThrottled,
    TemporarilyUnavailableException: MediaFetchError.temporarilyUnavailable,
    UnexpectedErrorException: MediaFetchError.unexpectedError,
    UpgradeRequiredException: MediaFetchError.upgradeRequired,
    default: MediaFetchError.unknown
};
