/**
 *
 * @module purchasePlugin
 * @desc {SDK.Purchase.PurchasePlugin} entry point.
 *
 */

import Purchase from './entry';
import PurchaseApi from './purchaseApi';
import PurchaseManager from './purchaseManager';

import PurchaseService from '../services/purchase/entry';
import PurchaseClient from '../services/purchase/purchaseClient';

import getSafe from '../services/util/getSafe';

import PurchaseClientConfiguration from './../services/purchase/purchaseClientConfiguration';
import PurchaseManagerConfiguration from './../services/configuration/purchaseManagerConfiguration';
import PurchaseManagerExtrasMap from './../services/configuration/purchaseManagerExtrasMap';
import RetryPolicy from './../services/configuration/retryPolicy';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Purchase, 'Purchase', typeof PurchaseService> = {
    pluginName: 'Purchase',
    entry: Purchase,
    service: PurchaseService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;

        const {
            purchaseManager,
            subscriptionManager,
            eligibilityManager,
            externalActivationManager
        } = sdkSession.managers;

        /**
         *
         * @access public
         * @type {SDK.Purchase.PurchaseApi}
         * @desc Gets the purchases resource used to support and retrieve in app purchases.
         *
         */
        sdkSession.purchaseApi = new PurchaseApi({
            purchaseManager,
            subscriptionManager,
            eligibilityManager,
            externalActivationManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services } = options;

        const { tokenManager } = managers;

        managers.purchaseManager = new PurchaseManager(
            services.purchase,
            new PurchaseClient(services.purchase.client, logger, httpClient),
            logger,
            tokenManager
        );
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const purchase = services.purchase;
        const { extras: purchaseExtras } = purchase;
        const { retryPolicy: purchaseAutoRefreshRetryPolicy } = purchaseExtras;

        const purchaseRetryPolicy = new RetryPolicy(
            purchaseAutoRefreshRetryPolicy.retryBasePeriod,
            purchaseAutoRefreshRetryPolicy.retryMultiplier,
            purchaseAutoRefreshRetryPolicy.retryMaxAttempts,
            purchaseAutoRefreshRetryPolicy.retryMaxPeriod
        );

        const clientConfiguration = new PurchaseClientConfiguration(
            getSafe(() => services.purchase.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.purchase.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.purchase =
            new PurchaseManagerConfiguration(
                clientConfiguration,
                new PurchaseManagerExtrasMap(purchaseRetryPolicy),
                services.purchase.disabled
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
