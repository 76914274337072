/**
 *
 * @module DssHlsPlayerAdapter
 * @desc PlayerAdapter for dss-hls devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/dss-hls
 * @see https://github.bamtech.co/dss-hls/dss-hls.js
 * @see https://github.bamtech.co/pages/dss-hls/documentation/
 * @see https://github.bamtech.co/pages/dss-hls/documentation/source/api/events.html
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import getSafe from '../../services/util/getSafe';
import adEngineRegex from '../../services/util/adEngineRegex';
import parseUrl from '../../services/util/parseUrl';

import PublicEvents from './../../events';
import InternalEvents from './../../internalEvents';
import PlaybackEventListener from './../playbackEventListener';
import DrmType from '../../services/media/drmType';
import DrmProvider from '../../drm/drmProvider';
import PlaybackMetrics from './../playbackMetrics';
import Playlist from './../playlist';
import PlayerAdapter from './../playerAdapter';
import BamHlsErrorMapping from './../bamHlsErrorMapping';

import AudioRendition from './../../services/media/audioRendition';
import PlaybackVariant from './../../services/media/playbackVariant';
import SubtitleRendition from './../../services/media/subtitleRendition';
import PlaylistType from '../../services/media/playlistType';

import BufferType from '../../services/qualityOfService/bufferType';
import ErrorLevel from '../../services/qualityOfService/errorLevel';
import FetchStatus from '../../services/qualityOfService/fetchStatus';
import HttpMethod from '../../services/qualityOfService/httpMethod';
import MediaSegmentType from '../../services/qualityOfService/mediaSegmentType';
import mapHiveToQoeErrorCodes from '../../services/qualityOfService/mapHiveToQoeErrorCodes';
import PlaybackExitedCause from '../../services/qualityOfService/playbackExitedCause';
import PlaybackPausedCause from '../../services/qualityOfService/playbackPausedCause';
import PlaybackResumedCause from '../../services/qualityOfService/playbackResumedCause';
import PlaybackSeekCause from '../../services/qualityOfService/playbackSeekCause';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import PlaybackState from '../../services/qualityOfService/playbackState';
import PlayerSeekDirection from '../../services/qualityOfService/playerSeekDirection';
import SeekDirection from '../../services/qualityOfService/seekDirection';
import ServerRequest from '../../services/qualityOfService/serverRequest';
import StartupActivity from '../../services/qualityOfService/startupActivity';
import QoePlaybackError from '../../services/qualityOfService/qoePlaybackError';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

import { getResolutionString } from './playerAdapterUtils';

/**
 *
 * @since 4.4.0
 * @desc Interface used to communicate with the media player.
 *
 */
export default class DssHlsPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer An instance of DssHls.js<HlsStream>
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The DssHlsPlayerAdapter requires nativePlayer.on && nativePlayer.off
     *
     */
    constructor(options) {
        super(options);

        /**
         *
         * @access private
         * @since 10.0.0
         * @type {Number|undefined}
         * @desc Value of the `PROGRAM-DATE-TIME` of the last (most recent) seek-able segment + segment length in
         * epoch millis i.e. end of last segment. Used to support live latency calculation.
         * @note This is stored here as an intermediary due to the data being returned in one event but being needed in others.
         *
         */
        this.seekableRangeEndProgramDateTime = undefined;

        /**
         *
         * @access private
         * @since 8.0.0
         * @type {SDK.Services.QualityOfService.PlaybackStartupEventData}
         * @desc Keeps track of CDN fallback for QoE support.
         *
         */
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        /**
         *
         * @access private
         * @since 11.0.0
         * @type {Object}
         * @desc Keeps track of CDN fallback QoE tracking information.
         *
         */
        this.qos = {};

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {Date}
         * @desc Time when rebufferingStartedEvent is triggered. Used to calculate duration for rebufferingEndedEvent.
         *
         */
        this.rebufferingDuration = null;

        /**
         *
         * @access public
         * @since 14.0.0
         * @type {Array<SDK.Drm.DrmProvider>}
         * @desc Set of DRM providers
         *
         */
        this.drmProviders = [];

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {HlsStream}
         *
         */
        this.hlsStatic = this.nativePlayer.getClass();

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {HlsStream.Events}
         * @note Events enum
         *
         */
        this.Events = this.hlsStatic.Events;

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {HlsStream.States}
         * @note States Enum
         *
         */
        this.States = this.hlsStatic.States;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {HlsStream.MediaElementStates}
         * @note MediaElementStates Enum
         *
         */
        this.MediaElementStates = this.hlsStatic.MediaElementStates;

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {HlsStream.PlaybackStates}
         * @note States Enum
         *
         */
        this.PlaybackStates = this.hlsStatic.PlaybackStates;

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {HlsStream.DataTypes}
         * @note DataTypes Enum
         *
         */
        this.DataTypes = this.hlsStatic.DataTypes;

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {Boolean}
         * @desc Indicates when player is buffering.
         *
         */
        this.isBuffering = false;

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {Boolean}
         * @desc Indicates when application is backgrounded or foregrounded.
         *
         */
        this.isApplicationBackgrounded = false;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {Number}
         * @desc current playing segment from the `SEGMENT_PLAYING` event.
         * @note eventData.segment.programDateTimeStart.
         *
         */
        this.segmentPosition = null;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {Object}
         * @desc set by the application via this.setSeekData();
         *
         */
        this.seekData = {};

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {Number}
         * @desc the starting bitrate for the media content.
         *
         */
        this.mediaStartBitrate = null;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {String<SDK.Services.QualityOfService.MediaSegmentType>}
         * @desc the starting bitrate for the media content.
         *
         */
        this.mediaSegmentType = null;

        /**
         *
         * @access private
         * @since 15.1.0
         * @type {Number}
         * @desc the total number of media bytes downloaded.
         *
         */
        this.mediaBytesDownloaded = 0;

        /**
         *
         * @access private
         * @since 15.2.0
         * @type {SDK.Services.QualityOfService.BufferType}
         * @desc Used to store buffer type for rebuffering ended.
         *
         */
        this.bufferType = undefined;

        /**
         *
         * @access private
         * @since 15.2.3
         * @type {Boolean}
         * @desc A flag to keep track of when the player is ready.
         *
         */
        this.isReady = false;

        /**
         *
         * @access private
         * @since 16.1.0
         * @type {Object}
         * @desc Cached values use for the heartbeat event that are updated
         * when specific events fire.
         *
         */
        this.heartbeatData = {};

        /**
         *
         * @access private
         * @since 16.1.0
         * @type {Number}
         * @desc Used to track total time of media downloaded (gets reset with each heartbeat).
         *
         */
        this.mediaDownloadTotalTime = 0;

        /**
         *
         * @access private
         * @since 16.1.0
         * @type {Number}
         * @desc Used to track how many times media gets downloaded (gets reset with each heartbeat).
         *
         */
        this.mediaDownloadTotalCount = 0;

        /**
         *
         * @access private
         * @since 20.0.1
         * @type {Number}
         * @desc used to indicate the current bitrateAvg for the chosen variant
         *
         */
        this.bitrateAvg = 0;
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    async setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        const { cdnFallback } = this;

        // only attempt to assign this if the user hasn't already done so when initializing bam-hls
        if (Check.not.assigned(this.nativePlayer.setupXhrCallback)) {
            this.setupXhrCallback();
        }

        // `playlistUri` can be either a string or array of objects for backwards compatibility purposes.
        this.playlistUri = playlist.streamUri;
        this.currentStreamUrl = playlist.streamUri;

        if (
            this.nativePlayer.startFailureSaveSupported &&
            cdnFallback.isEnabled
        ) {
            this.playlistUri = playlist.mediaSources;
            this.nativePlayer.config = {
                bufferingTimeoutStart: cdnFallback.defaultTimeout
                    ? cdnFallback.defaultTimeout * 1000
                    : 90000,
                requestStartContentNetworkFallbackLimit:
                    cdnFallback.fallbackLimit || 5
            };
        }
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        this.playlistUri = '';
        this.drmProvider = null;
        this.drmProviders = [];
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.qos = {};
        this.mediaBytesDownloaded = 0;
        this.isBuffering = false;
        this.isReady = false;
        this.heartbeatData = {};
        this.mediaDownloadTotalCount = 0;
        this.mediaDownloadTotalTime = 0;
        this.bitrateAvg = 0;
        this.seekData = null;

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.clean();

        this.nativePlayer = null;
        this.accessToken = null;
        this.playbackStartupEventData = null;
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {Object} data
     * @param {Number} data.seekSize - Indication that the user is seeking by a fixed time (size) e.g. +30, -30
     * @param {String<SDK.Services.QualityOfService.SeekDirection>} [data.seekDirection] - Used for Live events
     * @param {String<SDK.Services.QualityOfService.PlaybackSeekCause>} data.seekCause
     * @desc sets the seekData property provided by the application.
     *
     */
    setSeekData(data) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                data: Types.object({
                    seekSize: Types.number,
                    seekDirection: Types.in(SeekDirection).optional,
                    seekCause: Types.in(PlaybackSeekCause)
                })
            };

            typecheck.warn(this, 'setSeekData', params, arguments);
        }

        this.seekData = data;
    }

    /**
     *
     * @access public
     * @since 14.0.0
     * @desc Sets the application state flag to backgrounded.
     *
     */
    setApplicationBackgrounded() {
        this.isApplicationBackgrounded = true;
    }

    /**
     *
     * @access public
     * @since 14.0.0
     * @desc Sets the application state flag to foregrounded.
     *
     */
    setApplicationForegrounded() {
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object} [options]
     * @param {Object} [options.eventData]
     * @param {Boolean} [options.isEnded]
     * @note Handles the PLAYBACK_ENDED events
     * @note eventData is internal use only when called via playbackErrorEvent, should be undefined otherwise.
     *
     */
    playbackEndedEvent(options) {
        const { eventData, isEnded } = options || {};
        const { playbackStartupEventData } = this;
        const { errorName: playbackError, errorDetail: playbackErrorDetail } =
            eventData || {};

        const playbackData = this.getPlaybackData();

        let cause;

        if (Check.assigned(eventData)) {
            cause = PlaybackExitedCause.error;
        } else if (isEnded) {
            cause = PlaybackExitedCause.playedToEnd;
        } else {
            cause = PlaybackExitedCause.user;
        }

        const playbackEndedEvent = {
            ...playbackData,
            cause,
            playbackError,
            playbackErrorDetail
        };

        playbackEndedEvent.cdnRequestedTrail =
            playbackStartupEventData.cdnRequestedTrail;
        playbackEndedEvent.cdnFailedTrail =
            playbackStartupEventData.cdnFailedTrail;
        playbackEndedEvent.cdnFallbackCount =
            playbackStartupEventData.cdnFallbackCount;
        playbackEndedEvent.isCdnFallback =
            playbackStartupEventData.isCdnFallback;

        // case: Playback has ended and the rebufferingEndedEvent did not fire for an unknown reason.
        if (this.isBuffering) {
            this.rebufferingEndedEvent();
        }

        this.onPlaybackEnded(playbackEndedEvent);
    }

    // #region protected

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {SDK.Services.Exception.ServiceException} Unable to add PlaybackEventListener
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Void}
     * @note messageDetailed is the error message pre-formatted with error, message and cause
     * from bam-hls ERROR event
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, boundHandlers, Events } = this;

        const {
            STREAM_PLAYBACKSTATE_CHANGED,
            PRESENTATION_STATE_LOADED,
            MEDIA_PLAYING,
            DRM_LICENSE_RECEIVED,
            CANPLAY,
            SEEKING_STARTED,
            NETWORK_METRICS_CHANGED,
            SEEKABLE_RANGE_CHANGED,
            BUFFERING_STARTED,
            BUFFERING_FINISHED,
            SUBTITLES_RENDITION_UPDATED,
            MASTER_PLAYLIST_REQUEST,
            MASTER_PLAYLIST_FALLBACK,
            MASTER_PLAYLIST_LOADED,
            SEEKING_FINISHED,
            VARIANT_UPDATED,
            AUDIO_RENDITION_UPDATED,
            ERROR,
            SEGMENT_PLAYING,
            SOURCE_BUFFER_APPEND_STARTED,
            CONTENT_DOWNLOAD_FINISHED
        } = Events;

        if (Check.function(nativePlayer.on)) {
            this.listener = listener;

            boundHandlers.playbackStartedEvent =
                this.playbackStartedEvent.bind(this);
            boundHandlers.playbackStateChangedEvent =
                this.playbackStateChangedEvent.bind(this);
            boundHandlers.playbackInitializedEvent =
                this.playbackInitializedEvent.bind(this);
            boundHandlers.playbackReadyEvent =
                this.playbackReadyEvent.bind(this);
            boundHandlers.playbackSeekStartedEvent =
                this.playbackSeekStartedEvent.bind(this);
            boundHandlers.playbackSeekEndedEvent =
                this.playbackSeekEndedEvent.bind(this);
            boundHandlers.bitrateChangedEvent =
                this.bitrateChangedEvent.bind(this);
            boundHandlers.rebufferingStartedEvent =
                this.rebufferingStartedEvent.bind(this);
            boundHandlers.rebufferingEndedEvent =
                this.rebufferingEndedEvent.bind(this);
            boundHandlers.subtitleChangedEvent =
                this.subtitleChangedEvent.bind(this);
            boundHandlers.audioChangedEvent = this.audioChangedEvent.bind(this);
            boundHandlers.multivariantPlaylistFetchedEvent =
                this.multivariantPlaylistFetchedEvent.bind(this);
            boundHandlers.variantPlaylistFetchedEvent =
                this.variantPlaylistFetchedEvent.bind(this);
            boundHandlers.successfulPlaylistLoad =
                this.successfulPlaylistLoad.bind(this);
            boundHandlers.multivariantPlaylistRequest =
                this.multivariantPlaylistRequest.bind(this);
            boundHandlers.multivariantPlaylistFallback =
                this.multivariantPlaylistFallback.bind(this);
            boundHandlers.drmKeyFetchedEvent =
                this.drmKeyFetchedEvent.bind(this);
            boundHandlers.seekableRangeChangedEvent =
                this.seekableRangeChangedEvent.bind(this);
            boundHandlers.playbackErrorEvent =
                this.playbackErrorEvent.bind(this);
            boundHandlers.segmentPlayingEvent =
                this.segmentPlayingEvent.bind(this);
            boundHandlers.sourceBufferAppendStartedEvent =
                this.sourceBufferAppendStartedEvent.bind(this);
            boundHandlers.contentDownloadFinishedEvent =
                this.contentDownloadFinishedEvent.bind(this);

            nativePlayer.on(
                PRESENTATION_STATE_LOADED,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.on(
                STREAM_PLAYBACKSTATE_CHANGED,
                boundHandlers.playbackStateChangedEvent
            );
            nativePlayer.on(
                NETWORK_METRICS_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.on(
                BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.on(
                BUFFERING_FINISHED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.on(
                SUBTITLES_RENDITION_UPDATED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.on(
                AUDIO_RENDITION_UPDATED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.on(
                MASTER_PLAYLIST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.on(
                MASTER_PLAYLIST_LOADED,
                boundHandlers.successfulPlaylistLoad
            );
            nativePlayer.on(
                MASTER_PLAYLIST_REQUEST,
                boundHandlers.multivariantPlaylistRequest
            );
            nativePlayer.on(
                MASTER_PLAYLIST_FALLBACK,
                boundHandlers.multivariantPlaylistFallback
            );
            nativePlayer.on(
                VARIANT_UPDATED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.on(
                SEEKING_STARTED,
                boundHandlers.playbackSeekStartedEvent
            );
            nativePlayer.on(
                SEEKING_FINISHED,
                boundHandlers.playbackSeekEndedEvent
            );
            nativePlayer.on(ERROR, boundHandlers.playbackErrorEvent);
            nativePlayer.on(SEGMENT_PLAYING, boundHandlers.segmentPlayingEvent);
            nativePlayer.on(
                SOURCE_BUFFER_APPEND_STARTED,
                boundHandlers.sourceBufferAppendStartedEvent
            );
            nativePlayer.on(
                CONTENT_DOWNLOAD_FINISHED,
                boundHandlers.contentDownloadFinishedEvent
            );

            if (SEEKABLE_RANGE_CHANGED) {
                nativePlayer.on(
                    SEEKABLE_RANGE_CHANGED,
                    boundHandlers.seekableRangeChangedEvent
                );
            }

            if (DRM_LICENSE_RECEIVED) {
                nativePlayer.on(
                    DRM_LICENSE_RECEIVED,
                    boundHandlers.drmKeyFetchedEvent
                );
            }

            if (Check.function(nativePlayer.once)) {
                nativePlayer.once(CANPLAY, boundHandlers.playbackReadyEvent);
                nativePlayer.once(
                    MEDIA_PLAYING,
                    boundHandlers.playbackStartedEvent
                );
            }
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {SDK.Media.PlaybackEventListener} listener
     * @throws {SDK.Services.Exception.ServiceException} Unable to remove PlaybackEventListener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer, boundHandlers, Events } = this;

        const {
            STREAM_PLAYBACKSTATE_CHANGED,
            PRESENTATION_STATE_LOADED,
            MEDIA_PLAYING,
            DRM_LICENSE_RECEIVED,
            CANPLAY,
            SEEKING_STARTED,
            NETWORK_METRICS_CHANGED,
            SEEKABLE_RANGE_CHANGED,
            BUFFERING_STARTED,
            BUFFERING_FINISHED,
            SUBTITLES_RENDITION_UPDATED,
            MASTER_PLAYLIST_REQUEST,
            MASTER_PLAYLIST_FALLBACK,
            MASTER_PLAYLIST_LOADED,
            SEEKING_FINISHED,
            VARIANT_UPDATED,
            AUDIO_RENDITION_UPDATED,
            ERROR,
            SEGMENT_PLAYING,
            SOURCE_BUFFER_APPEND_STARTED,
            CONTENT_DOWNLOAD_FINISHED
        } = Events;

        const isCorrectListener =
            Check.assigned(this.listener) && this.listener === listener;

        if (Check.function(nativePlayer.off) && isCorrectListener) {
            this.listener = null;

            nativePlayer.off(
                PRESENTATION_STATE_LOADED,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.off(MEDIA_PLAYING, boundHandlers.playbackStartedEvent);
            nativePlayer.off(
                STREAM_PLAYBACKSTATE_CHANGED,
                boundHandlers.playbackStateChangedEvent
            );
            nativePlayer.off(CANPLAY, boundHandlers.playbackReadyEvent);
            nativePlayer.off(
                NETWORK_METRICS_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.off(
                BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.off(
                BUFFERING_FINISHED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.off(
                SUBTITLES_RENDITION_UPDATED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.off(
                AUDIO_RENDITION_UPDATED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.off(
                MASTER_PLAYLIST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.off(
                MASTER_PLAYLIST_LOADED,
                boundHandlers.successfulPlaylistLoad
            );
            nativePlayer.off(
                MASTER_PLAYLIST_REQUEST,
                boundHandlers.multivariantPlaylistRequest
            );
            nativePlayer.off(
                MASTER_PLAYLIST_FALLBACK,
                boundHandlers.multivariantPlaylistFallback
            );
            nativePlayer.off(
                VARIANT_UPDATED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.off(
                SEEKING_STARTED,
                boundHandlers.playbackSeekStartedEvent
            );
            nativePlayer.off(
                SEEKING_FINISHED,
                boundHandlers.playbackSeekEndedEvent
            );
            nativePlayer.off(ERROR, boundHandlers.playbackErrorEvent);
            nativePlayer.off(
                SEGMENT_PLAYING,
                boundHandlers.segmentPlayingEvent
            );
            nativePlayer.off(
                SOURCE_BUFFER_APPEND_STARTED,
                boundHandlers.sourceBufferAppendStartedEvent
            );
            nativePlayer.off(
                CONTENT_DOWNLOAD_FINISHED,
                boundHandlers.contentDownloadFinishedEvent
            );

            if (SEEKABLE_RANGE_CHANGED) {
                nativePlayer.off(
                    SEEKABLE_RANGE_CHANGED,
                    boundHandlers.seekableRangeChangedEvent
                );
            }

            if (DRM_LICENSE_RECEIVED) {
                nativePlayer.off(
                    DRM_LICENSE_RECEIVED,
                    boundHandlers.drmKeyFetchedEvent
                );
            }
        }
    }

    /**
     *
     * @access protected
     * @since 4.4.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    async setDrmProviders(drmProviders) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer } = this;

        this.drmProviders = drmProviders;

        const drmCapabilities = await nativePlayer.getDrmCapabilities();

        const { keySystems = {} } = drmCapabilities;

        let drmProvidersIndex = drmProviders.length;

        while (drmProvidersIndex--) {
            const type = drmProviders[drmProvidersIndex].type;
            const provider =
                type === DrmType.PRMNAGRA ? keySystems.NAGRA : keySystems[type];

            if (getSafe(() => !provider.supported, true)) {
                drmProviders.splice(drmProvidersIndex, 1);
            }
        }

        nativePlayer.drmConfiguration = this.setDrmConfiguration();
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @note DssHls expects the keySystems[x].keySystem value to be `NAGRA` instead of `PRMNAGRA`
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    setDrmConfiguration() {
        const { drmProviders } = this;

        const providerImplementations = {
            [DrmType.FAIRPLAY]: {
                keySystem: DrmType.FAIRPLAY,
                getCertificate: (drmProvider) =>
                    drmProvider.getFairPlayCertificate()
            },
            [DrmType.WIDEVINE]: {
                keySystem: DrmType.WIDEVINE,
                getCertificate: (drmProvider) =>
                    drmProvider.getWidevineCertificate()
            },
            [DrmType.PRMNAGRA]: {
                keySystem: 'NAGRA',
                getCertificate: (drmProvider) =>
                    drmProvider.getNagraCertificate()
            }
        };

        const keySystems = drmProviders.map((drmProvider) => {
            const keySystemObj = {
                individualizationUri: drmProvider.individualizationUri,
                keySystem: drmProvider.type,
                licenseRequestUri: drmProvider.licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                serverCertificate: Promise.resolve(undefined)
            };

            const providerImplementation =
                providerImplementations[drmProvider.type];

            if (providerImplementation) {
                const { keySystem, getCertificate } = providerImplementation;

                keySystemObj.keySystem = keySystem;
                keySystemObj.serverCertificate = (async () => {
                    return await getCertificate(drmProvider);
                })();
            }

            return keySystemObj;
        });

        return {
            keySystems
        };
    }

    /**
     *
     * @access protected
     * @since 10.0.0
     * @desc Gets a snapshot of information about media playback.
     * @returns {SDK.Media.PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note (chosenBitRate / 1000) - need to convert to Kbps
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        const { currentPDT, isLive, isBehindLive } = this.nativePlayer;

        let bufferSegmentDuration = 0;
        let liveLatencyAmount = 0;

        const isLiveEdge = isLive ? !isBehindLive : false;
        const {
            currentTime,
            bufferedRange = {},
            seekableRange
        } = this.nativePlayer.getPlaybackMetrics();
        const { chosenBitrate, chosenMaxBitrate, throughput } =
            this.nativePlayer.getNetworkMetrics();

        const currentPlayhead = currentTime * 1000;
        const chosenBitrateKbps = chosenBitrate / 1000; // divide by 1000 to convert to Kbps
        const currentBitrateAvg = chosenBitrate;
        const currentBitratePeak = chosenMaxBitrate;

        if (bufferedRange.end - currentTime > 0) {
            bufferSegmentDuration = bufferedRange.end - currentTime;
        }

        if (isBehindLive && seekableRange.pdtEnd - currentPDT > 0) {
            liveLatencyAmount = seekableRange.pdtEnd - currentPDT;
        }

        return new PlaybackMetrics({
            currentBitrate: chosenBitrateKbps,
            currentPlayhead,
            currentBitrateAvg,
            currentBitratePeak,
            currentThroughput: throughput,
            playheadProgramDateTime: currentPDT,
            seekableRangeEndProgramDateTime: seekableRange.pdtEnd,
            isLiveEdge,
            bufferSegmentDuration,
            mediaBytesDownloaded: this.mediaBytesDownloaded,
            playbackState: this.getPlaybackState(),
            segmentPosition: currentPDT,
            liveLatencyAmount,
            maxAllowedVideoBitrate: this.getMaxAllowedVideoBitrate()
        });
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {String}
     *
     */
    getCdnName() {
        const cdnName = getSafe(() => this.qos.cdnName);

        return Check.string(cdnName) ? cdnName : 'null';
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {Number}
     *
     */
    getAudioBitrate() {
        const { audioRenditions = [], currentAudioRenditionIndex } =
            this.nativePlayer;
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex] || {};

        return audioRendition.averageBitrate;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {Number}
     *
     */
    getMaxAllowedVideoBitrate() {
        const variants = getSafe(() => this.nativePlayer.variants, []);
        const maxVariant = variants[variants.length - 1] || {};

        return maxVariant.peakBitrate;
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     * @since 5.0.0
     * @note Handles the MEDIA_PLAYING event. This uses the nativePlayer.once to make sure that this QoE
     * event is only posted once. The MEDIA_PLAYING event will fire every time media is started, even after pausing, so
     * we only want this QoE event to post after the first time MEDIA_PLAYING fires and then disregard it thereafter
     * for this playback session.
     *
     */
    playbackStartedEvent() {
        const playbackData = this.getPlaybackData();

        this.onPlaybackStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_LOADED event
     *
     */
    multivariantPlaylistFetchedEvent(eventData) {
        const { isLive, isSlidingWindow } = this.nativePlayer;

        if (Check.object(eventData)) {
            const url = eventData.playlist
                ? eventData.playlist.url
                : eventData.url;
            const priority = eventData.playlist
                ? eventData.playlist.priority
                : 1;

            const playbackStartupData = this.getPlaybackStartupData();

            const { host, path } = parseUrl(url);

            let playlistLiveType;

            if (isLive && isSlidingWindow) {
                playlistLiveType = PlaylistType.SLIDE;
            } else {
                playlistLiveType = PlaylistType.COMPLETE;
            }

            const serverRequest = new ServerRequest({
                host,
                path,
                status: FetchStatus.completed,
                method: HttpMethod.get
            });

            this.emit(InternalEvents.UpdateAdEngine, priority);

            this.onMultivariantPlaylistFetched({
                ...playbackStartupData,
                mediaStartBitrate: this.mediaStartBitrate,
                playlistLiveType,
                serverRequest
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @note Handles the VARIANT_UPDATED event
     * @note The VariantPlaylistFetchedEventData.serverRequest.status is only set if there were no errors loading
     * the variant, e.g. nativePlayer.variants[eventData.level].loadError = 0. The status is not set if loadError > 0
     * because the nativePlayer does not expose the reason for the errors.
     *
     */
    variantPlaylistFetchedEvent(eventData = {}) {
        const { details = {} } = eventData || {};
        const { url, audioRendition = {} } = details;
        const { host, path } = parseUrl(url);

        const playbackMetrics = this.getPlaybackMetrics();
        const currentVariant = this.getCurrentVariant();

        const { averageBitrate, bitrate, resolution } = currentVariant;

        const serverRequest = new ServerRequest({
            host,
            path,
            status: FetchStatus.completed,
            method: HttpMethod.get
        });

        this.onVariantPlaylistFetched({
            ...eventData,
            playlistAverageBandwidth: averageBitrate,
            playlistBandwidth: bitrate,
            playlistChannels: audioRendition.channels,
            playlistName: audioRendition.name,
            playlistLanguage: audioRendition.language,
            playlistResolution: resolution,
            serverRequest,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.7.0
     * @desc Handles the CANPLAY event
     *
     */
    playbackReadyEvent() {
        const playbackStartupData = this.getPlaybackStartupData();

        this.onPlaybackReady({
            ...playbackStartupData,
            mediaStartBitrate: this.mediaStartBitrate
        });

        this.isReady = true;
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} eventData
     * @desc Handles the STREAM_PLAYBACKSTATE_CHANGED event
     *
     */
    playbackStateChangedEvent(eventData) {
        const { PlaybackStates } = this;
        const { playbackState, lastPlaybackState } = eventData || {};

        const playbackData = this.getPlaybackData();

        let cause;

        if (
            lastPlaybackState === PlaybackStates.PAUSED &&
            playbackState === PlaybackStates.PLAYING
        ) {
            cause = PlaybackResumedCause.user; // resuming playback is always caused by the user

            this.onPlaybackResumed({
                ...playbackData,
                cause
            });
        }

        if (
            lastPlaybackState === PlaybackStates.PLAYING &&
            playbackState === PlaybackStates.PAUSED
        ) {
            if (this.isApplicationBackgrounded) {
                cause = PlaybackPausedCause.applicationBackgrounded;
            } else if (this.isBuffering) {
                cause = PlaybackPausedCause.stall;
            } else {
                cause = PlaybackPausedCause.user;
            }

            this.onPlaybackPaused({
                ...playbackData,
                cause
            });
        }
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @desc Triggered when playback is initialized.
     * @note Handles the PRESENTATION_STATE_LOADED event
     *
     */
    playbackInitializedEvent() {
        const playbackStartupData = this.getPlaybackStartupData();

        const { variant, audio, subtitle } = playbackStartupData;

        // Cache values to be used for heartbeat event.
        this.heartbeatData = {
            playlistAudioChannels: variant.audioChannels,
            playlistAudioCodec: variant.audioCodec,
            playlistAudioLanguage: audio.language,
            playlistAudioName: audio.name,
            playlistSubtitleLanguage: subtitle.language,
            playlistSubtitleName: subtitle.name,
            subtitleVisibility: subtitle.forced
        };

        this.onPlaybackInitialized(playbackStartupData);
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} eventData
     *
     */
    drmKeyFetchedEvent(eventData) {
        const playbackStartupData = this.getPlaybackStartupData();

        const { url = '', keyID: drmKeyId } = eventData || {};
        const { host, path } = parseUrl(url);

        const serverRequest = new ServerRequest({
            host,
            path,
            status: FetchStatus.completed,
            method: HttpMethod.get
        });

        this.onDrmKeyFetched({
            ...playbackStartupData,
            drmKeyId,
            serverRequest
        });
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_LOADED event to update tracking when successful playlist loads.
     *
     */
    successfulPlaylistLoad(eventData) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.currentStreamUrl = playlist.url;

            if (getSafe(() => playlist.primaryContent.tracking)) {
                this.onSuccessfulPlaylistLoad({
                    priority: playlist.priority,
                    url: playlist.url,
                    tracking: playlist.primaryContent.tracking
                });
            }
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_REQUEST event to update tracking when successful playlist loads.
     *
     */
    multivariantPlaylistRequest(eventData) {
        const { playbackStartupEventData } = this;
        const { playlist } = eventData || {};

        if (playlist && playlist.primaryContent) {
            const qosData = getSafe(() => playlist.primaryContent.tracking.qos);
            const cdnVendor = qosData ? qosData.cdnVendor : null;

            this.qos = qosData;

            playbackStartupEventData.fallbackAttempt(cdnVendor);

            if (playbackStartupEventData.isCdnFallback) {
                playbackStartupEventData.setQosData(qosData);
                this.onPlaybackReattempt(playbackStartupEventData);
            }
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_FALLBACK event to update tracking when successful playlist loads.
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    multivariantPlaylistFallback(eventData) {
        const { playbackStartupEventData } = this;
        const { playlist } = eventData || {};

        if (playlist) {
            playbackStartupEventData.playbackError = QoePlaybackError.unknown;
            playbackStartupEventData.fallbackFailed();
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_STARTED event
     *
     */
    playbackSeekStartedEvent(eventData) {
        const { isLive } = this.nativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause, seekSize } = this.seekData || {};
        const playbackMetrics = this.getPlaybackMetrics();
        const seekDistance = Math.abs(Math.floor(targetPosition - currentTime));

        let playerSeekDirection;
        let seekDirection;

        if (seekSize < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (seekSize === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekStarted({
            ...eventData,
            playerSeekDirection,
            seekSize,
            seekDistance,
            playheadPosition: playbackMetrics.currentPlayhead,
            cause,
            seekDirection
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_FINISHED event
     *
     */
    playbackSeekEndedEvent(eventData) {
        const { isLive } = this.nativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause, seekSize } = this.seekData || {};
        const playbackMetrics = this.getPlaybackMetrics();
        const seekDistance = Math.abs(Math.floor(targetPosition - currentTime));

        let playerSeekDirection;
        let seekDirection;

        if (seekSize < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (seekSize === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekEnded({
            ...eventData,
            playerSeekDirection,
            seekDistance,
            playheadPosition: playbackMetrics.currentPlayhead,
            cause,
            seekDirection
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the ERROR event
     *
     */
    playbackErrorEvent(eventData) {
        const { fatal = false, message: errorDetail } = eventData || {};

        const { currentTime } = this.nativePlayer.getPlaybackMetrics();
        const { currentPDT: segmentPosition } = this.nativePlayer;
        const playheadPosition = this.normalizePlayhead(
            Math.floor(currentTime * 1000)
        );
        const { liveLatencyAmount } = this.getPlaybackMetrics();

        const errorName = mapHiveToQoeErrorCodes(eventData);

        if (fatal) {
            if (eventData.error === 'DRM_FAILED') {
                const errorCase =
                    this.constructErrorCaseFromDssHlsError(eventData);

                this.emit(PublicEvents.MediaFailure, errorCase);
            }

            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: fatal,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.error,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount
                });

                this.playbackEndedEvent({
                    eventData: { errorName, errorDetail }
                });

                this.removeListener(this.listener);
            }
        } else {
            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: false,
                    errorName,
                    errorDetail,
                    errorLevel: ErrorLevel.warn,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount
                });
            }
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @param {Object} eventData
     * @note Handles the SEEKABLE_RANGE_CHANGED event
     *
     */
    seekableRangeChangedEvent(eventData) {
        const { pdtEnd } = eventData || {};

        this.seekableRangeEndProgramDateTime = pdtEnd;
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} [eventData={}]
     * @note Handles the NETWORK_METRICS_CHANGED event
     *
     */
    bitrateChangedEvent(eventData = {}) {
        if (this.isReady) {
            const {
                currentBitrateAvg: bitrateAvg,
                currentBitratePeak: bitratePeak,
                currentPlayhead: playheadPosition
            } = this.getPlaybackMetrics();

            if (this.bitrateAvg !== bitrateAvg) {
                this.bitrateAvg = bitrateAvg;

                this.onBitrateChanged({
                    ...eventData,
                    bitrateAvg,
                    bitratePeak,
                    playheadPosition
                });
            }
        }
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} eventData
     * @note Handles the SUBTITLES_RENDITION_UPDATED event
     *
     */
    subtitleChangedEvent(eventData) {
        const {
            language: subtitleLanguage,
            name: subtitleName,
            forced: subtitleVisibility
        } = eventData || {};

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistSubtitleLanguage = subtitleLanguage;
        this.heartbeatData.playlistSubtitleName = subtitleName;
        this.heartbeatData.subtitleVisibility = subtitleVisibility;

        this.onSubtitleChanged({
            subtitleLanguage,
            subtitleName,
            subtitleVisibility
        });
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} eventData
     * @note Handles the AUDIO_RENDITION_UPDATED event
     *
     */
    audioChangedEvent(eventData) {
        if (this.isReady) {
            const {
                channels: audioChannels,
                codec: audioCodec,
                language: audioLanguage,
                name: audioName
            } = eventData || {};

            // Cache values to be used for heartbeat event.
            this.heartbeatData.playlistAudioChannels = audioChannels;
            this.heartbeatData.playlistAudioCodec = audioCodec;
            this.heartbeatData.playlistAudioLanguage = audioLanguage;
            this.heartbeatData.playlistAudioName = audioName;

            this.onAudioChanged({
                audioChannels,
                audioCodec,
                audioLanguage,
                audioName
            });
        }
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @note Handles the BUFFERING_STARTED event
     *
     */
    rebufferingStartedEvent() {
        // Used to calculate duration for rebufferingEndedEvent
        this.rebufferingDuration = new Date();

        this.isBuffering = true;

        const playbackData = this.getPlaybackData();

        this.bufferType = playbackData.bufferType;

        this.onRebufferingStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @note Handles the `BUFFERING_FINISHED` event
     *
     */
    rebufferingEndedEvent() {
        this.isBuffering = false;

        const playbackData = this.getPlaybackData();
        const duration = Date.now() - this.rebufferingDuration;

        this.onRebufferingEnded({
            ...playbackData,
            duration,
            bufferType: playbackData.bufferType
                ? playbackData.bufferType
                : this.bufferType
        });

        this.bufferType = undefined;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Handles the `SEGMENT_PLAYING` event
     *
     */
    segmentPlayingEvent(eventData = {}) {
        const programDateTimeStart = getSafe(
            () => eventData.segment.programDateTimeStart
        );

        this.segmentPosition = Check.assigned(programDateTimeStart)
            ? Math.floor(programDateTimeStart)
            : null;
        this.mediaSegmentType = MediaSegmentType[eventData.type];
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Handles the `SOURCE_BUFFER_APPEND_STARTED` event.
     * @note This value gets cleared when `PlaybackTelemetryDispatcher.recordStreamSample()` is called.
     *
     */
    sourceBufferAppendStartedEvent(eventData) {
        if (Check.number(getSafe(() => eventData.segment.byteSize))) {
            this.mediaBytesDownloaded += eventData.segment.byteSize;
        }
    }

    /**
     *
     * @access private
     * @since 16.1.0
     * @desc Handles the `CONTENT_DOWNLOAD_STARTED` event.
     * @note These values get reset to zero when `PlaybackTelemetryDispatcher.createPlaybackHeartbeatEventData(provider)` is called.
     *
     */
    contentDownloadFinishedEvent(eventData) {
        const { startTime, endTime } = eventData;

        this.mediaDownloadTotalCount++;
        this.mediaDownloadTotalTime += endTime - startTime;
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    setupXhrCallback() {
        const {
            nativePlayer,
            accessToken,
            DataTypes,
            adEngineData = {}
        } = this;
        const { ssess } = adEngineData;

        nativePlayer.xhrSetupCallback = (xhr, url, type) => {
            if (DataTypes) {
                const isKeyCall = type === DataTypes.KEY;
                const isAdEngineCall =
                    adEngineRegex(url) && type === DataTypes.CHUNK;

                xhr.withCredentials = false; // Ensure no false positive from any cookies

                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                }

                if (isKeyCall) {
                    xhr.setRequestHeader('Authorization', accessToken);
                } else if (isAdEngineCall && Check.assigned(ssess)) {
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('ssess', ssess);
                }
            }
        };
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} exception - the exception object that bam-hls throws
     * @note exception.cause will be one value from the DRM section of this list:
     * https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/src/hlsjs/errors.js
     * @desc Converts a bam-hls error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given bam-hls error
     *
     */
    constructErrorCaseFromDssHlsError(exception) {
        const errorCode =
            BamHlsErrorMapping[exception.cause] || BamHlsErrorMapping.default;
        const reasons = [new ErrorReason(errorCode.code, null)];

        return new ServiceException({
            reasons,
            exceptionData: errorCode.exceptionData
        });
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @returns {Object}
     *
     */
    getCurrentVariant() {
        const variants = this.nativePlayer.variants;
        const currentVariantIndex =
            this.nativePlayer.getNetworkMetrics().currentVariantIndex;
        const currentVariant = variants[currentVariantIndex] || {};

        const maxVariant = variants[variants.length - 1] || {};

        const {
            encodedFrameRate: playlistFrameRate,
            peakBitrate: maxAllowedVideoBitrate
        } = maxVariant;

        const {
            peakBitrate: bitrate,
            encodedFrameRate: frameRate,
            audioCodecs,
            averageBitrate,
            videoCodec,
            videoRangeSubtype
        } = currentVariant;

        if (this.mediaStartBitrate === null) {
            this.mediaStartBitrate = averageBitrate;
        }

        return {
            resolution: getResolutionString(currentVariant),
            bitrate,
            averageBitrate,
            frameRate,
            audioCodec: Check.array(audioCodecs) ? audioCodecs[0] : undefined,
            videoCodec,
            videoRange: videoRangeSubtype,
            maxAllowedVideoBitrate,
            playlistResolution: getResolutionString(maxVariant),
            playlistFrameRate
        };
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @returns {Object}
     *
     */
    getAudioRendition() {
        const { audioRenditions = [], currentAudioRenditionIndex } =
            this.nativePlayer;
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex] || {};

        const { averageBitrate, channels, codec, name, language } =
            audioRendition;

        return {
            audioRendition: new AudioRendition({ name, language }),
            averageBitrate,
            audioChannels: channels,
            audioCodec: codec
        };
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @returns {SDK.Services.Media.SubtitleRendition}
     *
     */
    getSubtitleRendition() {
        const { subtitleRenditions = [], currentSubtitleRenditionIndex } =
            this.nativePlayer;
        const subtitleRendition =
            subtitleRenditions[currentSubtitleRenditionIndex] || {};

        return new SubtitleRendition(subtitleRendition);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @returns {String<SDK.Services.QualityOfService.BufferType>}
     *
     */
    getBufferType() {
        let bufferType;

        const { mediaElementState } = this.nativePlayer;

        const {
            //DETACHING,
            //DETACHED,
            ATTACHING,
            //ATTACHED,
            //ENDED,
            //PLAYING,
            PAUSED,
            BUFFERING,
            SEEKING
        } = this.MediaElementStates || {};

        if (mediaElementState) {
            switch (mediaElementState) {
                case ATTACHING:
                    bufferType = BufferType.initializing;
                    break;
                case BUFFERING:
                    bufferType = BufferType.buffering;
                    break;
                case SEEKING:
                    bufferType = BufferType.seeking;
                    break;
                case PAUSED:
                    bufferType = BufferType.resuming;
                    break;
            }
        }

        return bufferType;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc returns playback state enum
     * @note nativePlayer.playbackState will always be populated.
     * @returns {String}
     *
     */
    getPlaybackState() {
        let state;

        const { PlaybackStates } = this;

        switch (this.nativePlayer.playbackState) {
            case PlaybackStates.BUFFERING:
            case PlaybackStates.NOTREADY:
                state = PlaybackState.buffering;
                break;

            case PlaybackStates.ENDED:
                state = PlaybackState.finished;
                break;

            case PlaybackStates.PAUSED:
                state = PlaybackState.paused;
                break;

            case PlaybackStates.PLAYING:
                state = PlaybackState.playing;
                break;

            case PlaybackStates.SEEKING:
                state = PlaybackState.seeking;
                break;
        }

        return state;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc return data relevant to create `PlaybackEventData`
     * @returns {Object}
     *
     */
    getPlaybackData() {
        const { currentStreamUrl: streamUrl, nativePlayer } = this;
        const { currentPDT: segmentPosition } = nativePlayer;

        const { liveLatencyAmount, currentPlayhead: playheadPosition } =
            this.getPlaybackMetrics();
        const { audioBitrate } = this.getAudioRendition();

        const {
            bitrate: videoBitrate,
            averageBitrate: videoAverageBitrate,
            maxAllowedVideoBitrate
        } = this.getCurrentVariant();

        const bufferType = this.getBufferType();

        return {
            playheadPosition,
            streamUrl,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            liveLatencyAmount,
            bufferType
        };
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc return data relevant to create `PlaybackStartupEventData`
     * @returns {Object}
     *
     */
    getPlaybackStartupData() {
        const { currentStreamUrl: streamUrl } = this;

        const { bufferSegmentDuration, currentPlayhead } =
            this.getPlaybackMetrics();
        const { audioRendition, audioChannels } = this.getAudioRendition();
        const subtitle = this.getSubtitleRendition();

        const {
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            audioCodec,
            videoCodec,
            videoRange
        } = this.getCurrentVariant();

        let audioChannelsNumber;

        if (Check.string(audioChannels)) {
            const audioChannelsMatches = /\d+/.exec(audioChannels);

            if (audioChannelsMatches) {
                audioChannelsNumber = Number(audioChannelsMatches[0]);
            }
        } else if (Check.number(audioChannels)) {
            audioChannelsNumber = audioChannels;
        }

        // TODO: Look into commented values.
        const variant = new PlaybackVariant({
            bandwidth: bitrate,
            resolution,
            //videoBytes,
            //maxAudioRenditionBytes,
            //maxSubtitleRenditionBytes,
            audioChannels: audioChannelsNumber,
            videoRange,
            videoCodec,
            //audioType,
            audioCodec,
            averageBandwidth: averageBitrate,
            frameRate
        });

        return {
            variant,
            audio: audioRendition,
            subtitle,
            streamUrl,
            playheadPosition: currentPlayhead,
            bufferSegmentDuration
        };
    }

    /**
     *
     * @access private
     * @since 16.1.0
     * @desc Returns heartbeatData object on the ctor for heartbeat event.
     * @returns {Object}
     *
     */
    getHeartbeatData() {
        return this.heartbeatData;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.DssHlsPlayerAdapter';
    }

    // #endregion
}
