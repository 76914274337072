/**
 *
 * @module audioChangedEventData
 *
 */

import { Types, typecheck, Check } from '@dss/type-checking';

/**
 *
 * @since 4.0.0
 * @desc This event represents an audio stream change.
 *
 */
export default class AudioChangedEventData {
    /**
     *
     * @param {Object} [options={}]
     * @param {String} [options.playbackSessionId]
     * @param {Number} [options.audioChannels]
     * @param {String} [options.audioCodec]
     * @param {String} [options.audioLanguage]
     * @param {String} [options.audioName]
     *
     */
    constructor(options = {}) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackSessionId: Types.nonEmptyString.optional,
                    audioChannels: Types.number.optional,
                    audioCodec: Types.nonEmptyString.optional,
                    audioLanguage: Types.nonEmptyString.optional,
                    audioName: Types.nonEmptyString.optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            playbackSessionId,
            audioChannels,
            audioCodec,
            audioLanguage,
            audioName
        } = options || {};

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc client generated ID of the stream/playback session
         * @note Is added in the `logQoeEvent` method in `SDK.Media.PlaybackTelemetryDispatcher`.
         *
         */
        this.playbackSessionId = playbackSessionId;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Number|undefined}
         * @desc Audio channels as defined by the selected playlist variant
         *
         */
        this.audioChannels = Check.assigned(audioChannels)
            ? audioChannels
            : undefined;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Audio codec as defined by the selected playlist variant
         *
         */
        this.audioCodec = audioCodec;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Audio language as defined by the selected audio rendition
         *
         */
        this.audioLanguage = audioLanguage;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Audio name as defined by the selected audio rendition
         *
         */
        this.audioName = audioName;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.AudioChangedEventData';
    }

    // #endregion
}
