/**
 *
 * @module entry
 * @desc {SDK.Media.AdEngine} entry point.
 *
 */

import AdEngineManager from './adEngineManager';

/**
 *
 * @typedef {Object} SDK.Media.AdEngine
 *
 * @property {AdEngineManager} AdEngineManager
 *
 */
export default {
    AdEngineManager
};
