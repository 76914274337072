/**
 *
 * @module heartbeatSampleType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 16.0.0
 * @typedef {Object} SDK.Services.QualityOfService.HeartbeatSampleType
 * @desc Defines the different types of playback heartbeat events.
 *
 * @property {String} periodic - Since `16.0.0` - An event that is produced on a ~30-second timer (along with an initial heartbeat on start / resume) should have type "periodic".
 * @property {String} user - Since `16.0.0` - A heartbeat that has been created in response to a user action that changes a property should have type "user".
 * @property {String} responsive - Since `16.0.0` - A heartbeat that has been created in response to changes to any other change in the property (e.g. the variant fetch changed the quality of the stream in any way) should have a "responsive" sample type.
 * @property {String} state - Since `16.0.0` - Anything that happens along with any other state change in playback (e.g. prior to pause or ended) should have type "state".
 *
 * @example
 * SDK.Services.QualityOfService.HeartbeatSampleType.periodic // 'periodic'
 *
 */
export default keyMirror({
    periodic: null,
    user: null,
    responsive: null,
    state: null
});
