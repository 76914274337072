/**
 *
 * @module subscriptionSource
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/subscription.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import SubscriptionProvider from './subscriptionProvider';

import {
    SubscriptionSourceSubType,
    SubscriptionSourceType
} from '../services/subscription/enums';

/**
 *
 * @desc Represents a source a subscription can be from.
 *
 */
export default class SubscriptionSource {
    /**
     *
     * @access public
     * @type {SDK.Subscription.SubscriptionProvider|String}
     * @desc Gets the provider of the subscription.
     *
     */
    public provider: OpenEnumOf<typeof SubscriptionProvider>;

    /**
     *
     * @access public
     * @type {String}
     * @desc Source reference identifier - value depends on the source type and source subType.
     * In case of D2C it is an order id.
     *
     */
    public ref: string;

    /**
     *
     * @access public
     * @type {SDK.Services.Subscription.SubscriptionSourceSubType|undefined}
     * @desc The subType is a free field to provide more context about how the subscription has been acquired.
     * The subType is not validated by the subscription service and it depends on the source of the subscription.
     * Enumeration is used but describes currently available values which are provided by the actual sources.
     * @note Possible values are not validated, hence it is optional.
     *
     */
    public subType?: ValueOf<typeof SubscriptionSourceSubType>;

    /**
     *
     * @access public
     * @type {SDK.Services.Subscription.SubscriptionSourceType|String}
     * @desc Gets the type of the source.
     *
     */
    public type: OpenEnumOf<typeof SubscriptionSourceType>;

    /**
     *
     * @param {Object} options
     * @param {String} options.provider
     * @param {String} options.ref
     * @param {String} options.type
     * @param {String} [options.subType]
     *
     */
    public constructor(options: {
        provider: string;
        ref: string;
        type: string;
        subType?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    provider: Types.nonEmptyString,
                    ref: Types.nonEmptyString,
                    type: Types.nonEmptyString,
                    subType: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { provider, ref, subType, type } = options;

        this.provider =
            SubscriptionProvider[
                provider as keyof typeof SubscriptionProvider
            ] || provider;

        this.ref = ref;

        this.subType =
            SubscriptionSourceSubType[
                subType as keyof typeof SubscriptionSourceSubType
            ] || SubscriptionSourceSubType.OTHER;

        this.type =
            SubscriptionSourceType[
                type as keyof typeof SubscriptionSourceType
            ] || type;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Subscription.SubscriptionSource';
    }
}
