/**
 *
 * @module sessionInfoRequest
 *
 */

import OrchestrationRequest from '../services/orchestration/internal/orchestrationRequest';

/**
 *
 * @since 14.0.0
 * @access protected
 *
 */
export default class SessionInfoRequest extends OrchestrationRequest {
    public constructor() {
        const query = SessionInfoRequest.query;

        super({ query, operationName: 'activeSession' });
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @returns {String}
     *
     */
    private static get query() {
        return `query activeSession {
            activeSession {
              device {
                id
                platform
              }
              entitlements
              inSupportedLocation
              isSubscriber
              location {
                type
                countryCode
                dma
                asn
                regionName
                connectionType
                zipCode
              }
              sessionId
              experiments {
                featureId
                variantId
                version
              }
              account {
                id
              }
              profile {
                id
              }
              preferredMaturityRating {
                impliedMaturityRating
                ratingSystem
              }
              homeLocation {
                countryCode
              }
              portabilityLocation {
                countryCode
              }
              partnerName
            }
          }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Session.SessionInfoRequest';
    }
}
