/**
 *
 * @module interfaces
 * @see https://github.com/STRML/keyMirror
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import QoePlaybackError from './qoePlaybackError';
import BufferType from './bufferType';

import {
    PodPosition,
    SnapshotDownloadDataType,
    SnapshotDownloadFailure,
    SnapshotEventType,
    SnapshotNextAction,
    SnapshotFragmentType
} from './enums';

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdPodPlacement
 * @since 19.0.0
 * @desc Placement information relevant to ad pods.
 * @property {String<SDK.QualityOfService.PodPosition>} podPosition - Since `19.0.0` - The position of the requested or played ad pod.
 * @property {Number} [midrollIndex] - Since `19.0.0` - The 1-based index of the current midroll pod's position relative to other midroll pods.
 * Increments every time there is a new midroll pod requested.
 * @note `midrollIndex` is Required when `podPosition` = `midroll`.
 *
 */
export interface AdPodPlacement {
    podPosition: keyof typeof PodPosition;
    midrollIndex?: number;
}

/**
 *
 * @access private
 *
 */
export const AdPodPlacementTypedef = {
    podPosition: Types.in(PodPosition),
    midrollIndex: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdPodData
 * @since 19.0.0
 * @desc Metadata relevant to ad pods.
 * @property {Number} plannedLength - Since `19.0.0` - The total length of the planned ad object in milliseconds.
 * @property {Number} plannedSlotCount - Since `19.0.0` - The number of planned ad slots for the ad pod.
 *
 */
export interface AdPodData {
    plannedLength: number;
    plannedSlotCount: number;
}

/**
 *
 * @access private
 *
 */
export const AdPodDataTypedef = {
    plannedLength: Types.number,
    plannedSlotCount: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdSlotData
 * @since 19.0.0
 * @desc Metadata relevant to individual ads within an ad pod.
 * @property {String} adMediaId - Since `19.0.0` - Unique identifier for a media asset in a given advertisement.
 * @property {Number} slotNumber - Since `19.0.0` - The 1-based index of the current ad slot's position relative to other slots in the current ad pod.
 * @property {Number} plannedLength - Since `19.0.0` - The total length of the planned ad object in milliseconds.
 * @note `adMediaId` Corresponds to the `media-id` of a given asset from the Disney Ad Server.
 *
 */
export interface AdSlotData {
    adMediaId: string;
    slotNumber: number;
    plannedLength: number;
}

/**
 *
 * @access private
 *
 */
export const AdSlotDataTypedef = {
    adMediaId: Types.nonEmptyString,
    slotNumber: Types.number,
    plannedLength: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdVideoData
 * @since 19.0.0
 * @desc Metadata relevant to the video in ads.
 * @property {String} playlistVideoCodec - Since `19.0.0` - Video codec as defined by a variant playlist.
 * @property {String} playlistVideoRange - Since `19.0.0` - Video range as defined by a variant playlist.
 * @property {Number} [videoBitrate] - Since `19.0.0` - The peak video bitrate in bps. If value is 0 or not available return 0.
 * @property {Number} [videoAverageBitrate] - Since `19.0.0` - The average video bitrate in bps. If value is 0 or not available return 0.
 * @property {String} playlistResolution - Since `19.0.0` - Resolution as defined by a variant playlist.
 * @property {Number} playlistFrameRate - Since `19.0.0` - Frame rate as defined by a variant playlist.
 *
 */
export interface AdVideoData {
    playlistVideoCodec: string;
    playlistVideoRange: string;
    videoBitrate?: number;
    videoAverageBitrate?: number;
    playlistResolution: string;
    playlistFrameRate: number;
}

/**
 *
 * @access private
 *
 */
export const AdVideoDataTypedef = {
    playlistVideoCodec: Types.nonEmptyString,
    playlistVideoRange: Types.nonEmptyString,
    videoBitrate: Types.number.optional,
    videoAverageBitrate: Types.number.optional,
    playlistResolution: Types.nonEmptyString,
    playlistFrameRate: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdAudioData
 * @since 19.0.0
 * @desc Metadata relevant to the audio in ads.
 * @property {Number} playlistAudioChannels - Since `19.0.0` - Audio channels as defined by a variant playlist.
 * @property {String} playlistAudioCodec - Since `19.0.0` - Audio codec as defined by a variant playlist.
 * @property {String} playlistAudioLanguage - Since `19.0.0` - Audio language as defined by a variant playlist.
 * @property {String} playlistAudioName - Since `19.0.0` - Audio name as defined by a variant playlist.
 *
 */
export interface AdAudioData {
    playlistAudioChannels: number;
    playlistAudioCodec: string;
    playlistAudioLanguage: string;
    playlistAudioName: string;
}

/**
 *
 * @access private
 *
 */
export const AdAudioDataTypedef = {
    playlistAudioChannels: Types.number,
    playlistAudioCodec: Types.nonEmptyString,
    playlistAudioLanguage: Types.nonEmptyString,
    playlistAudioName: Types.nonEmptyString
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdSubtitleData
 * @since 19.0.0
 * @desc Metadata relevant to the subtitles in ads.
 * @property {Boolean} subtitleVisibility - Since `19.0.0` - Subtitle visibility
 * @property {String} [playlistSubtitleLanguage] - Since `19.0.0` - Subtitle language as defined by a variant playlist. Required if `subtitleVisibility` = `true`.
 * @property {String} [playlistSubtitleName] - Since `19.0.0` - Name of the subtitle. Required if `subtitleVisibility` = `true`.
 *
 */
export interface AdSubtitleData {
    subtitleVisibility: boolean;
    playlistSubtitleLanguage?: string;
    playlistSubtitleName?: string;
}

/**
 *
 * @access private
 *
 */
export const AdSubtitleDataTypedef = {
    subtitleVisibility: Types.boolean,
    playlistSubtitleLanguage: Types.nonEmptyString.optional,
    playlistSubtitleName: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdStartupData
 * @since 19.0.0
 * @desc Metadata relevant to ad startup time for an `adActivity`.
 *
 * @property {Number} startTimestamp - Since `19.0.0` - Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
 * @note This should be a monotonic timestamp. Source from the `MonotonicTimestampManager.getTimestamp()` function.
 * @note When adActivity = `adPodFetched`: `startTimestamp` equals the `monotonicTimestamp` of the preceding `adPodRequested` event.
 * @note When adActivity = `adMultivariantFetched`: `startTimestamp` equals `MonotonicTimestampManager.getTimestamp()` minus the associated `ServerRequest.roundTripTime`.
 * @note When adActivity = `adVariantFetched`: `startTimestamp` equals `MonotonicTimestampManager.getTimestamp()` minus the associated `ServerRequest.roundTripTime`.
 *
 * @property {Number} requestDuration - Since `19.0.0` - Time duration, in milliseconds, of the `adActivity` request.
 * @note This field is the difference between this `AdStartupData.startTimestamp` and the current `PlaybackAd` event's `monotonicTimestamp`.
 * @note When adActivity = `adPodFetched`: `requestDuration` equals `MonotonicTimestampManager.getTimestamp()` minus the `monotonicTimestamp` of the preceding `adPodRequested` event.
 * @note When adActivity = `adMultivariantFetched`: `requestDuration` equals the associated `ServerRequest.roundTripTime`.
 * @note When adActivity = `adVariantFetched`: `requestDuration` equals the associated `ServerRequest.roundTripTime`.
 *
 */
export interface AdStartupData {
    startTimestamp: number;
    requestDuration: number;
}

/**
 *
 * @access private
 *
 */
export const AdStartupDataTypedef = {
    startTimestamp: Types.number,
    requestDuration: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AdErrorData
 * @since 19.0.0
 * @desc Metadata relevant to ad-related errors.
 * @property {String<SDK.Services.QualityOfService.QoePlaybackError>} errorName - Since `19.0.0` - An error resulting in degraded user experience.
 * @property {String} [errorMessage] - Since `19.0.0` - Supporting error text for QoePlaybackError property where this can be provided for additional context.
 * If `errorName` == `adBeaconError`, this field should be populated with the `beacon_url` that failed.
 *
 */
export interface AdErrorData {
    errorName: keyof typeof QoePlaybackError;
    errorMessage?: string;
}

/**
 *
 * @access private
 *
 */
export const AdErrorDataTypedef = {
    errorName: Types.in(QoePlaybackError),
    errorMessage: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.PqmDecisionData
 * @since 19.0.0
 * @desc Defines PQM specific fields that are required on a `SnapshotEvent` when PQM is enabled.
 * @property {String<SDK.Services.QualityOfService.SnapshotNextAction>} errorName - Since `19.0.0` - Describes how the player should proceed when handling upcoming downloads.
 * @property {String} nextCdnUrl - Since `19.0.0` - New selected CDN URL used for the next download. Default value is an empty String.
 * @property {Number} nextVideoBitrate - Since `19.0.0` - The peak video bitrate in bps. If value is 0 or not available return 0.
 * @property {Number} estimatedBandwidth - Since `19.0.0` - The estimated network bandwidth, in bps. If there is no estimated bandwidth at a given point, 0 should be sent.
 * @property {Number} perceivedBandwidth - Since `19.0.0` - The instant network bandwidth observed according to the last media segment download, in bps. If there is no
 * observed bandwidth, 0 should be sent.
 *
 */
export interface PqmDecisionData {
    nextAction: keyof typeof SnapshotNextAction;
    nextCdnUrl: string;
    nextVideoBitrate: number;
    estimatedBandwidth: number;
    perceivedBandwidth: number;
}

/**
 *
 * @access private
 *
 */
export const PqmDecisionDataTypedef = {
    nextAction: Types.in(SnapshotNextAction),
    nextCdnUrl: Types.nonEmptyString,
    nextVideoBitrate: Types.number,
    estimatedBandwidth: Types.number,
    perceivedBandwidth: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotEvent
 * @since 19.0.0
 * @desc An object that contains information about media resource downloading and player status during QoE playback sessions.
 * @property {String<SDK.Services.QualityOfService.SnapshotEventType>} eventName - Since `19.0.0` - The type of snapshot associated with the player dispatched event.
 * @property {Number} millisecondTimestamp - Since `19.0.0` - Timestamp when the snapshot was captured, in milliseconds.
 * @property {Number} videoBufferLength - Since `19.0.0` - Amount of video currently fully buffered, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} audioBufferLength - Since `19.0.0` - Buffered audio media segment duration, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} playheadPosition - Since `19.0.0` - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Object<SDK.Services.QualityOfService.PqmDecisionData>} pqmDecisionData - Since `19.0.0` - Describes how the player should proceed when handling upcoming downloads. Required if PQM is enabled.
 *
 */
export interface SnapshotEvent {
    eventName: keyof typeof SnapshotEventType;
    millisecondTimestamp: number;
    videoBufferLength: number;
    audioBufferLength: number;
    playheadPosition: number;
    pqmDecisionData: PqmDecisionData;
}

/**
 *
 * @access private
 *
 */
export const SnapshotEventTypedef = {
    eventName: Types.in(SnapshotEventType),
    millisecondTimestamp: Types.number,
    videoBufferLength: Types.number,
    audioBufferLength: Types.number,
    playheadPosition: Types.number,
    pqmDecisionData: Types.object(PqmDecisionDataTypedef)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.DownloadMetadata
 * @since 19.0.0
 * @desc An object which contains information about a media resource downloading for a SnapshotEvent object. Required when the eventName is 'qosFragment' or 'qosPlaylist'.
 * @property {String} cdnUrl - Since `19.0.0` - CDN URL used for each of the downloads.
 * @property {Number} downloadStartTime - Since `19.0.0` - The timestamp of the download starting, in milliseconds.
 * @property {Number} downloadTotalTime - Since `19.0.0` - Total elapsed time of the current download, in milliseconds.
 * @property {Number} rtt - Since `19.0.0` - The round trip time of the current download, in milliseconds.
 * @property {Number} downloadByteSize - Since `19.0.0` - Total size of the download, in bytes.
 * @property {Boolean} downloadSuccessful - Since `19.0.0` - True if the download was successful, otherwise false.
 * @property {String<SDK.Services.QualityOfService.SnapshotDownloadDataType>} dataType - Since `19.0.0` - The type of resource that was being downloaded.
 * @property {Number} httpStatus - Since `19.0.0` - The status code of the HTTP request performed (e.g. 400).
 * @property {String<SDK.Services.QualityOfService.SnapshotDownloadFailure>} [failureType] - Since `19.0.0` - The type of download failure that occurred.
 *
 */
export interface DownloadMetadata {
    cdnUrl: string;
    downloadStartTime: number;
    downloadTotalTime: number;
    rtt: number;
    downloadByteSize: number;
    downloadSuccessful: boolean;
    dataType: keyof typeof SnapshotDownloadDataType;
    httpStatus: number;
    failureType?: keyof typeof SnapshotDownloadFailure;
}

/**
 *
 * @access private
 *
 */
export const DownloadMetadataTypedef = {
    eventName: Types.nonEmptyString,
    downloadStartTime: Types.number,
    downloadTotalTime: Types.number,
    rtt: Types.number,
    downloadByteSize: Types.number,
    downloadSuccessful: Types.boolean,
    dataType: Types.in(SnapshotDownloadDataType),
    httpStatus: Types.number,
    failureType: Types.in(SnapshotDownloadFailure).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.VideoFragmentMetadata
 * @since 19.0.0
 * @desc An object which contains information about a downloaded video qosFragment.
 * @property {String} codec - Since `19.0.0` - The codec of the current downloaded video segment.
 * @property {String} frameRate - Since `19.0.0` - The frame rate of the current downloaded video segment.
 * @property {String} videoResolution - Since `19.0.0` - Video resolution of the new selected variantIndex, being formatted as "\(width) x \(height)" (e.g. 1280x720).
 * @property {String} videoBitrate - Since `19.0.0` - The peak video bitrate in bps. If value is 0 or not available return 0.
 *
 */
export interface VideoFragmentMetadata {
    codec: string;
    frameRate: number;
    videoResolution: string;
    videoBitrate: number;
}

/**
 *
 * @access private
 *
 */
export const VideoFragmentMetadataTypedef = {
    codec: Types.nonEmptyString,
    frameRate: Types.number,
    videoResolution: Types.nonEmptyString,
    videoBitrate: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AudioFragmentMetadata
 * @since 19.0.0
 * @desc An object which contains information about a downloaded audio qosFragment.
 * @property {String} codec - Since `19.0.0` - The codec of the current downloaded audio segment.
 *
 */
export interface AudioFragmentMetadata {
    codec: string;
}

/**
 *
 * @access private
 *
 */
export const AudioFragmentMetadataTypedef = {
    codec: Types.nonEmptyString
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.FragmentSnapshotEvent
 * @since 20.0.0
 * @desc Defines a `SnapshotEvent` where `eventName` is `qosFragment`.
 * @property {String<SDK.Services.QualityOfService.SnapshotEventType>} eventName - Since `20.0.0` - The type of snapshot associated with the player dispatched event.
 * @property {Number} millisecondTimestamp - Since `20.0.0` - Timestamp when the snapshot was captured, in milliseconds.
 * @property {Number} videoBufferLength - Since `20.0.0` - Amount of video currently fully buffered, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} audioBufferLength - Since `20.0.0` - Buffered audio media segment duration, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} playheadPosition - Since `20.0.0` - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Object<SDK.Services.QualityOfService.PqmDecisionData>} pqmDecisionData - Since `20.0.0` - Describes how the player should proceed when handling upcoming downloads. Required if PQM is enabled.
 * @property {Object<SDK.Services.QualityOfService.DownloadMetadata>} downloadMetadata - Since `20.0.0` - The codec of the current downloaded audio segment.
 * @property {String<SDK.Services.QualityOfService.SnapshotFragmentType>} fragmentType - Since `20.0.0` - The type of fragment that was downloaded.
 *
 */
export interface FragmentSnapshotEvent extends SnapshotEvent {
    downloadMetadata: DownloadMetadata;
    fragmentType: keyof typeof SnapshotFragmentType;
}

/**
 *
 * @access private
 *
 */
export const FragmentSnapshotEventTypedef = {
    ...SnapshotEventTypedef,
    downloadMetadata: Types.object(DownloadMetadataTypedef),
    fragmentType: Types.in(SnapshotFragmentType)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.VideoFragmentSnapshotEvent
 * @since 20.0.0
 * @desc Defines a `FragmentSnapshotEvent` where `downloadMetadata.dataType` is `video`.
 * @property {String<SDK.Services.QualityOfService.SnapshotEventType>} eventName - Since `20.0.0` - The type of snapshot associated with the player dispatched event.
 * @property {Number} millisecondTimestamp - Since `20.0.0` - Timestamp when the snapshot was captured, in milliseconds.
 * @property {Number} videoBufferLength - Since `20.0.0` - Amount of video currently fully buffered, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} audioBufferLength - Since `20.0.0` - Buffered audio media segment duration, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} playheadPosition - Since `20.0.0` - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Object<SDK.Services.QualityOfService.PqmDecisionData>} pqmDecisionData - Since `20.0.0` - Describes how the player should proceed when handling upcoming downloads. Required if PQM is enabled.
 * @property {Object<SDK.Services.QualityOfService.DownloadMetadata>} downloadMetadata - Since `20.0.0` - The codec of the current downloaded audio segment.
 * @property {String<SDK.Services.QualityOfService.SnapshotFragmentType>} fragmentType - Since `20.0.0` - The type of fragment that was downloaded.
 * @property {Object<SDK.Services.QualityOfService.VideoFragmentMetadata>} videoFragmentMetadata - Since `20.0.0` - An object which contains information about a downloaded video qosFragment.
 *
 */
export interface VideoFragmentSnapshotEvent extends FragmentSnapshotEvent {
    videoFragmentMetadata: VideoFragmentMetadata;
}

/**
 *
 * @access private
 *
 */
export const VideoFragmentSnapshotEventTypedef = {
    ...FragmentSnapshotEventTypedef,
    videoFragmentMetadata: Types.object(VideoFragmentMetadataTypedef)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.AudioFragmentSnapshotEvent
 * @since 20.0.0
 * @desc Defines a `FragmentSnapshotEvent` where `downloadMetadata.dataType` is `audio`.
 * @property {String<SDK.Services.QualityOfService.SnapshotEventType>} eventName - Since `20.0.0` - The type of snapshot associated with the player dispatched event.
 * @property {Number} millisecondTimestamp - Since `20.0.0` - Timestamp when the snapshot was captured, in milliseconds.
 * @property {Number} videoBufferLength - Since `20.0.0` - Amount of video currently fully buffered, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} audioBufferLength - Since `20.0.0` - Buffered audio media segment duration, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} playheadPosition - Since `20.0.0` - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Object<SDK.Services.QualityOfService.PqmDecisionData>} pqmDecisionData - Since `20.0.0` - Describes how the player should proceed when handling upcoming downloads. Required if PQM is enabled.
 * @property {Object<SDK.Services.QualityOfService.DownloadMetadata>} downloadMetadata - Since `20.0.0` - The codec of the current downloaded audio segment.
 * @property {String<SDK.Services.QualityOfService.SnapshotFragmentType>} fragmentType - Since `20.0.0` - The type of fragment that was downloaded.
 * @property {Object<SDK.Services.QualityOfService.AudioFragmentMetadata>} audioFragmentMetadata - Since `20.0.0` - An object which contains information about a downloaded audio qosFragment.
 *
 */
export interface AudioFragmentSnapshotEvent extends FragmentSnapshotEvent {
    audioFragmentMetadata: AudioFragmentMetadata;
}

/**
 *
 * @access private
 *
 */
export const AudioFragmentSnapshotEventTypedef = {
    ...FragmentSnapshotEventTypedef,
    audioFragmentMetadata: Types.object(AudioFragmentMetadataTypedef)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.QualityOfService.WaitingSnapshotEvent
 * @since 20.0.0
 * @desc Defines a `SnapshotEvent` where `eventName` is `waiting`.
 * @property {String<SDK.Services.QualityOfService.SnapshotEventType>} eventName - Since `20.0.0` - The type of snapshot associated with the player dispatched event.
 * @property {Number} millisecondTimestamp - Since `20.0.0` - Timestamp when the snapshot was captured, in milliseconds.
 * @property {Number} videoBufferLength - Since `20.0.0` - Amount of video currently fully buffered, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} audioBufferLength - Since `20.0.0` - Buffered audio media segment duration, in milliseconds. Pass 0 if the value is unavailable at the time of reporting.
 * @property {Number} playheadPosition - Since `20.0.0` - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Object<SDK.Services.QualityOfService.PqmDecisionData>} pqmDecisionData - Since `20.0.0` - Describes how the player should proceed when handling upcoming downloads. Required if PQM is enabled.
 * @property {String<SDK.Services.QualityOfService.BufferType>} bufferType - Since `20.0.0` - The type of buffering that is happening. Report `unknown` if the type of buffering cannot be determined.
 *
 */
export interface WaitingSnapshotEvent extends SnapshotEvent {
    bufferType: keyof typeof BufferType;
}

/**
 *
 * @access private
 *
 */
export const WaitingSnapshotEventTypedef = {
    ...SnapshotEventTypedef,
    bufferType: Types.in(BufferType)
};
