/**
 *
 * @module experimentPlatform
 *
 */

/**
 *
 * @enum
 * @since 6.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ExperimentPlatform
 * @desc Name of the testing platform used to run A/B tests, primarily on Marketing Landing Pages (MLPs).
 *
 * @property {String} optimizely - Since `6.0.0`.
 * @property {String} weaponX - Since `6.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.ExperimentPlatform.weaponX // 'weapon_x'
 *
 */
export default {
    optimizely: 'optimizely',
    weaponX: 'weapon_x'
};
