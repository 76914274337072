/**
 *
 * @module entry
 * @desc {SDK.Services.Entitlement} entry point.
 *
 */

import EntitlementClient from './entitlementClient';

/**
 *
 * @typedef {Object} SDK.Services.Entitlement
 *
 * @property {SDK.Services.Entitlement.EntitlementClient} EntitlementClient
 *
 */
export default {
    EntitlementClient
};
