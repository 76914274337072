/**
 *
 * @module refreshTokenDelegation
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md#delegation-grants
 *
 */

import DelegationToken from './delegationToken';

/**
 *
 * @since 4.9.0
 *
 */
export default class RefreshTokenDelegation extends DelegationToken {
    /**
     *
     * @param {String} refreshToken
     *
     */
    constructor(refreshToken) {
        const subject = refreshToken;
        const subjectType = 'urn:ietf:params:oauth:token-type:refresh_token';

        super(subject, subjectType);

        /**
         *
         * @access public
         * @type {String}
         * @since 4.9.0
         *
         */
        this.refreshToken = refreshToken;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.RefreshTokenDelegation';
    }

    // #endregion
}
