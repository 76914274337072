/**
 *
 * @module purchaseClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the PurchaseClient.
 *
 */
export default class PurchaseClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Purchase.PurchaseClientConfiguration';
    }
}
