/**
 *
 * @module entry
 * @desc {SDK.Commerce.IDeal} entry point.
 *
 */

import IDealApi from './iDealApi';

/**
 *
 * @typedef {Object} SDK.Commerce.IDeal
 *
 * @property {IDealApi} IDealApi
 *
 */
export default {
    IDealApi
};
