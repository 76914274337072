/**
 *
 * @module featureFlagsChangedEvent
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 15.0.0
 * @desc Emitted by `SdkSession` when the feature flags associated with the session has changed.
 *
 */
export default class FeatureFlagsChangedEvent {
    /**
     *
     * @access public
     * @since 15.0.0
     * @type {Object|undefined}
     *
     */
    public oldFeatureFlags?: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {Object}
     *
     */
    public newFeatureFlags?: Record<string, unknown>;

    /**
     *
     * @param {Object} [oldFeatureFlags]
     * @param {Object} newFeatureFlags
     *
     */
    public constructor(
        oldFeatureFlags?: Record<string, unknown>,
        newFeatureFlags?: Record<string, unknown>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                oldFeatureFlags: Types.object().optional,
                newFeatureFlags: Types.object()
            };

            typecheck(this, params, arguments);
        }

        this.oldFeatureFlags = oldFeatureFlags;
        this.newFeatureFlags = newFeatureFlags;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.FeatureFlagsChangedEvent';
    }
}
