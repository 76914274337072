/**
 *
 * @module userProfileApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/features_overviews/user-profile.md
 * @desc An account may have multiple user profiles associated with it. The UserProfileApi will be used by
 * applications to control the creation, modification, deletion of user profiles, and setting a user profile as active.
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccountManager from '../account/accountManager';
import BaseApi from '../baseApi';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';

import TokenManager from '../token/tokenManager';
import Logger from '../logging/logger';

const DustUrn = DustUrnReference.userProfile.userProfileApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 3.5.0
 * @desc Provides the ability to manipulate user profiles.
 *
 */
export default class UserProfileApi extends BaseApi {
    /**
     *
     * @access private
     * @type {SDK.Account.AccountManager}
     *
     */
    private accountManager: AccountManager;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Account.AccountManager} options.accountManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        accountManager: AccountManager;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountManager: Types.instanceStrict(AccountManager),
                    tokenManager: Types.instanceStrict(TokenManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { accountManager, tokenManager } = options;

        this.accountManager = accountManager;

        this.tokenManager = tokenManager;

        this.dustEnabled = getSafe(
            () => this.accountManager.client.dustEnabled,
            false
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @deprecated Deprecated as of `16.0.0` and will be removed in a future version. See release notes for replacement.
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 4.17.0
     * @desc  Onboards the active profile to Star.
     * @throws {AccountBlockedException} Account is blocked because it has been either identified as a minor or banned globally by OneID.
     * @throws {AccountNotFoundException} The account was not found.
     * @throws {AccountVerificationRequiredException} Invalid email address syntax.
     * @throws {AccountSecurityFlaggedException} The account has been security flagged.
     * @throws {UserProfileNotFoundException} The userProfile was not found.
     * @throws {UserProfileUpdateFailedException} The userProfile update failed.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that returns an Object when the operation has succeeded.
     *
     */
    public async onboardProfileToStar(): Promise<TodoAny>;

    @apiMethodDecorator()
    public async onboardProfileToStar(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.accountManager.onboardProfileToStar(logTransaction);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.UserProfile.UserProfileApi';
    }
}
