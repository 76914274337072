/**
 *
 * @module entry
 * @desc {SDK.Entitlement} entry point.
 *
 */

import EntitlementApi from './entitlementApi';
import EntitlementManager from './entitlementManager';

/**
 *
 * @typedef {Object} SDK.Entitlement
 *
 * @property {SDK.Entitlement.EntitlementApi} EntitlementApi
 * @property {SDK.Entitlement.EntitlementManager} EntitlementManager
 *
 */
export default {
    EntitlementApi,
    EntitlementManager
};
