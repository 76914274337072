/**
 *
 * @module entry
 * @desc {SDK.UserActivity.Glimpse} entry point.
 *
 */

import GlimpseEvent from './glimpseEvent';
import GlimpseEvents from './glimpseEvents';

/**
 *
 * @typedef {SDK.UserActivity.Glimpse}
 *
 * @property {GlimpseEvent} GlimpseEvent
 * @property {GlimpseEvents} GlimpseEvents
 *
 */
export default {
    GlimpseEvent,
    GlimpseEvents
};
