/**
 *
 * @module entry
 * @desc {SDK.ExternalActivation} entry point.
 *
 */

import ExternalActivationManager from './externalActivationManager';

/**
 *
 * @typedef {Object} SDK.ExternalActivation
 *
 * @property {ExternalActivationManager} ExternalActivationManager
 *
 */
export default {
    ExternalActivationManager
};
