/**
 *
 * @module mediaSource
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    InsertionUrlInfoTypedef,
    UrlInfoTypedef
} from './../services/media/typedefs';

/**
 *
 * @since 18.0.0
 *
 */
export default class MediaSource {
    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Number}
     * @desc CDN fallback priority
     *
     */
    public priority: number;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Services.Media.UrlInfo}
     * @desc Primary content playlist URL.
     *
     */
    public primaryContent: SDK.Services.Media.UrlInfo;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Services.Media.InsertionUrlInfo|undefined}
     * @desc Media insertion URL
     *
     */
    public insertion?: SDK.Services.Media.InsertionUrlInfo;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {String|undefined}
     * @desc The full url based on the `primaryContent`.
     *
     */
    public url?: string;

    /**
     *
     * @param {Object} options
     * @param {Number} options.priority - CDN fallback priority
     * @param {SDK.Services.Media.UrlInfo} options.primaryContent - Primary content playlist Url
     * @param {SDK.Services.Media.InsertionUrlInfo} [options.insertion] - Media insertion URL
     *
     */
    public constructor(options: {
        priority: number;
        primaryContent: SDK.Services.Media.UrlInfo;
        insertion?: SDK.Services.Media.InsertionUrlInfo;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    priority: Types.integer,
                    primaryContent: Types.object(UrlInfoTypedef),
                    insertion: Types.object(InsertionUrlInfoTypedef).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { priority, primaryContent, insertion } = options;

        this.priority = priority;
        this.primaryContent = primaryContent;
        this.insertion = insertion;
        this.url = primaryContent.url;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaSource';
    }
}
