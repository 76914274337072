/**
 *
 * @module subscriptionManager
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';
import Subscription from './subscription';

import AccessTokenProvider from '../token/accessTokenProvider';
import SubscriptionManagerConfiguration from '../services/configuration/subscriptionManagerConfiguration';
import SubscriptionClient from '../services/subscription/subscriptionClient';
import LogTransaction from '../logging/logTransaction';

import type AccessToken from '../token/accessToken';

import { SubscriptionState } from '../services/subscriber/enums';

import {
    Subscription as SubscriptionV2,
    SubscriberInfo
} from './../services/subscriber/typedefs';

/**
 *
 * @access protected
 *
 */
export default class SubscriptionManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.SubscriptionManagerConfiguration}
     *
     */
    private config: SubscriptionManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Subscription.SubscriptionClient}
     *
     */
    public client: SubscriptionClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.SubscriptionManagerConfiguration} options.subscriptionManagerConfiguration
     * @param {SDK.Services.Subscription.SubscriptionClient} options.subscriptionClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     *
     */
    public constructor(options: {
        subscriptionManagerConfiguration: SubscriptionManagerConfiguration;
        subscriptionClient: SubscriptionClient;
        logger: Logger;
        accessTokenProvider: AccessTokenProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    subscriptionManagerConfiguration: Types.instanceStrict(
                        SubscriptionManagerConfiguration
                    ),
                    subscriptionClient:
                        Types.instanceStrict(SubscriptionClient),
                    logger: Types.instanceStrict(Logger),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            subscriptionManagerConfiguration,
            subscriptionClient,
            logger,
            accessTokenProvider
        } = options;

        this.config = subscriptionManagerConfiguration;
        this.client = subscriptionClient;
        this.logger = logger;
        this.accessTokenProvider = accessTokenProvider;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns a list of subscriptions for the current user.
     * @returns {Promise<Array<SDK.Subscription.Subscription>>}
     *
     */
    public async getSubscriptions(
        logTransaction: LogTransaction
    ): Promise<Array<Subscription>> {
        const subscriptionData = await this.client.getSubscriptions(
            this.accessToken,
            logTransaction
        );

        const subscriptions: Array<Subscription> = [];

        if (Check.nonEmptyArray(subscriptionData)) {
            subscriptionData.forEach((subscription) => {
                subscriptions.push(new Subscription(subscription));
            });
        }

        return subscriptions;
    }

    /**
     *
     * @access private
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Links (copies) all subscriptions from the user's device to the Account.
     * @returns {Promise<Void>}
     *
     */
    public async linkSubscriptionsFromDevice(
        logTransaction: LogTransaction
    ): Promise<void> {
        return await this.client.linkSubscriptionsFromDevice(
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @since 4.13.0
     * @param {Object} options
     * @param {SDK.Services.Subscriber.SubscriptionState} [options.state] - When supplied, only returns subscriptions
     * with this status. If not supplied, returns all subscriptions except churned.
     * @param {Boolean} [options.includeChurned] - When supplied, indicates whether churned subscriptions should be returned. Default is false.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Returns subscriber's subscription information.
     * @returns {Promise<Object<SDK.Services.Subscriber.SubscriberInfo>>} A promise that completes when the operation has
     * succeeded and returns the requested subscriber info.
     *
     */
    public async getSubscriberInfo(options: {
        state?: ValueOf<typeof SubscriptionState>;
        includeChurned?: boolean;
        logTransaction: LogTransaction;
    }): Promise<SubscriberInfo> {
        return await this.client.getSubscriberInfo({
            ...options,
            accessToken: this.accessToken
        });
    }

    /**
     *
     * @access private
     * @since 4.13.0
     * @param {String} subscriptionId - The subscription identifier.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns a subscription by id.
     * @returns {Promise<Object<SDK.Services.Subscriber.Subscription>>} A promise that completes when the operation has
     * succeeded and returns the requested subscription.
     *
     */
    public async getAccountSubscription(
        subscriptionId: string,
        logTransaction: LogTransaction
    ): Promise<SubscriptionV2> {
        return await this.client.getAccountSubscription(
            subscriptionId,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh `AccessToken` from the `AccessTokenProvider` instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken(): AccessToken {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Subscription.SubscriptionManager';
    }
}
