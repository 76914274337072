/**
 *
 * @module playbackSecurity
 *
 */
import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 8.0.0
 *
 */
export default class PlaybackSecurity {
    /**
     *
     * @param {Object} [options={}]
     * @param {String} options.drmEndpointKey
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    drmEndpointKey: Types.nonEmptyString
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const { drmEndpointKey } = options || {};

        /**
         *
         * @access public
         * @since 8.0.0
         * @type {String}
         *
         */
        this.drmEndpointKey = drmEndpointKey;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackSecurity';
    }

    // #endregion
}
