/**
 *
 * @module socketConnectionState
 * @see https://github.com/STRML/keyMirror
 *
 */

/**
 *
 * @enum
 * @typedef {Object} SDK.Socket.SocketConnectionState
 * @desc Defines the different sates of the underlying socket connection.
 *
 * @example
 * SocketConnectionState.closed // 'closed'
 *
 * @property {String} disabled - Since `4.9.0` - Socket connections are disabled by the SDK configuration.
 * @property {String} closed - Since `4.9.0` - The connection is currently closed and the SDK will not attempt to open a new one.
 * The application developer must invoke the `start()` method.
 * @property {String} connecting - Since `4.9.0` - The SDK is attempting to open a connection.
 * @property {String} connected - Since `4.9.0` - The socket connection is established and ready for sending and receiving.
 * @property {String} disconnected - Since `4.9.0` - The socket connection has been temporarily disconnected, and the SDK will attempt to open a new one.
 *
 */
enum SocketConnectionState {
    disabled = 'disabled',
    /**
     *
     * @note This is the default state for socket connections.
     *
     */
    closed = 'closed',
    connecting = 'connecting',
    connected = 'connected',
    disconnected = 'disconnected'
}
export default SocketConnectionState;
