/**
 *
 * @module configurationClientConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ConfigurationClientExtrasMap from './configurationClientExtrasMap';
import ServiceClientConfiguration from './serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc A model containing the ConfigurationClient's configuration settings.
 *
 */
export default class ConfigurationClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @param {ConfigurationClientExtrasMap} extras
     * @param {String} baseUrl
     * @param {Object} [endpoints={}]
     *
     */
    constructor(extras, baseUrl, endpoints = {}) {
        super(baseUrl, endpoints);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                extras: Types.instanceStrict(ConfigurationClientExtrasMap),
                baseUrl: Types.nonEmptyString,
                endpoints: Types.skip
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {ConfigurationClientExtrasMap}
         * @desc The object containing the list of configuration host names and corresponding baseUrls
         *
         */
        this.extras = extras;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.ConfigurationClientConfiguration';
    }

    // #endregion
}
