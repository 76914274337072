/**
 *
 * @module mediaClientEndpoint
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Media.MediaClientEndpoint
 *
 * @property {String} mediaPayload
 * @property {String} mediaPayloadV5
 * @property {String} key
 * @property {String} playbackCookie
 * @property {String} spriteSheetThumbnails
 * @property {String} spriteSheetThumbnail
 * @property {String} bifThumbnails
 * @property {String} bifThumbnail
 *
 */
export default keyMirror({
    mediaPayload: null,
    mediaPayloadV5: null,
    key: null,
    playbackCookie: null,
    spriteSheetThumbnails: null,
    spriteSheetThumbnail: null,
    bifThumbnails: null,
    bifThumbnail: null
});
