/**
 *
 * @module playbackState
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackState
 * @desc Defines the current state of playback.
 *
 * @property {String} buffering - `Since 13.0.0`
 * @property {String} finished - `Since 13.0.0`
 * @property {String} paused - `Since 13.0.0`
 * @property {String} playing - `Since 13.0.0`
 * @property {String} seeking - `Since 15.2.3`
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackState.buffering // 'buffering'
 *
 */
export default keyMirror({
    buffering: null,
    finished: null,
    paused: null,
    playing: null,
    seeking: null
});
