/**
 *
 * @module mediaPlugin
 * @desc {SDK.Media.MediaPlugin} entry point.
 *
 */

import Media from './entry';

import MediaApi from './mediaApi';
import MediaManager from './mediaManager';
import AdEngineManager from './adEngine/adEngineManager';

import MediaService from '../services/media/entry';
import MediaClient from '../services/media/mediaClient';

import AdEngineClient from '../services/media/adEngine/adEngineClient';
import AdEngineManagerConfiguration from './../services/configuration/adEngineManagerConfiguration';
import AdEngineClientConfiguration from './../services/media/adEngine/adEngineClientConfiguration';
import AdEngineManagerExtrasMap from './../services/configuration/adEngineManagerExtrasMap';

import getSafe from '../services/util/getSafe';

import MediaClientConfiguration from './../services/media/mediaClientConfiguration';
import MediaManagerConfiguration from './../services/configuration/mediaManagerConfiguration';
import MediaManagerExtrasMap from './../services/configuration/mediaManagerExtrasMap';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Media, 'Media', typeof MediaService> = {
    pluginName: 'Media',
    entry: Media,
    service: MediaService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { platformMetricsProvider } = sdkSession;

        const { mediaManager, sessionManager } = sdkSession.managers;

        /**
         *
         * @access public
         * @type {SDK.Media.MediaApi}
         * @desc Gets the media resource used to support media playback.
         *
         */
        sdkSession.mediaApi = new MediaApi({
            mediaManager,
            sessionManager,
            platformMetricsProvider,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const {
            managers,
            logger,
            httpClient,
            services,
            mediaCapabilitiesProvider,
            platformMetricsProvider,
            geoProvider,
            advertisingIdProvider
        } = options;

        const { tokenManager, telemetryManager, deviceManager, drmManager } =
            managers;

        const adEngineManager = new AdEngineManager({
            config: services.adEngine,
            client: new AdEngineClient(
                services.adEngine.client,
                logger,
                httpClient
            ),
            tokenManager,
            sdkver: deviceManager.environmentConfiguration.sdkVersion,
            logger,
            geoProvider,
            advertisingIdProvider
        });

        managers.adEngineManager = adEngineManager;

        managers.mediaManager = new MediaManager({
            mediaManagerConfiguration: services.media,
            tokenManager,
            drmManager,
            telemetryManager,
            mediaCapabilitiesProvider,
            platformMetricsProvider,
            mediaClient: new MediaClient(
                services.media.client,
                logger,
                httpClient
            ),
            adEngineManager,
            logger,
            advertisingIdProvider
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { extras: adEngineExtras } = services.adEngine;
        const { adTargeting } = adEngineExtras;

        const adEngineClientConfiguration = new AdEngineClientConfiguration(
            getSafe(() => services.adEngine.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.adEngine.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.adEngine =
            new AdEngineManagerConfiguration(
                adEngineClientConfiguration,
                new AdEngineManagerExtrasMap(adTargeting),
                services.adEngine.disabled
            );

        const { extras: mediaExtras } = services.media;

        const mediaClientConfiguration = new MediaClientConfiguration(
            getSafe(() => services.media.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.media.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.media = new MediaManagerConfiguration(
            mediaClientConfiguration,
            new MediaManagerExtrasMap(mediaExtras),
            services.media.disabled
        );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
