/**
 *
 * @module validationResultType
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 4.11.0
 * @typedef {Object} SDK.Services.Internal.Telemetry.ValidationResultType
 * @desc Specifies the success or failure of the validation and the type of error
 *
 * @property {String} other - Since `4.11.0`.
 * @property {String} success - Since `4.11.0`.
 * @property {String} unknownTypeError - Since `4.11.0`.
 * @property {String} jsonDustEventDecodeError - Since `4.11.0`.
 *
 * @example
 * SDK.Services.Internal.Telemetry.ValidationResultType.success // 'com.disneystreaming.sdp.http.Success'
 *
 */
export default {
    other: 'other',
    success: 'com.disneystreaming.sdp.http.Success',
    unknownTypeError: 'com.disneystreaming.sdp.http.UnknownTypeError',
    jsonDustEventDecodeError:
        'com.disneystreaming.sdp.http.JsonEventDecodeError'
};
