/**
 *
 * @module commerceManagerConfiguration
 *
 */

import CommerceClientConfiguration from '../commerce/commerceClientConfiguration';
import CommerceManagerExtrasMap from './commerceManagerExtrasMap';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing commerce.
 *
 */
export default class CommerceManagerConfiguration extends ServiceConfiguration<
    CommerceClientConfiguration,
    CommerceManagerExtrasMap
> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.CommerceManagerConfiguration';
    }

    // #endregion
}
