/**
 *
 * @module streamSampleConfiguration
 *
 */

/**
 *
 *
 */
export default class StreamSampleConfiguration {
    /**
     *
     * @param {Number} [streamSampleInterval=30]
     *
     */
    constructor(streamSampleInterval = 30) {
        /**
         *
         * @access public
         * @type {Number}
         *
         */
        this.streamSampleInterval = streamSampleInterval;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.StreamSampleConfiguration';
    }

    // #endregion
}
