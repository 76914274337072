/**
 *
 * @module dustUrnReference
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#api-events
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#service-events
 *
 */

/**
 *
 * @access protected
 * @since 3.4.0
 * @typedef {Object} SDK.Services.Internal.Dust.DustUrnReference
 * @desc Internal DUST API event hash reference.
 * Mapping of all SDK methods and events in a single location.
 *
 * @property {Object} account
 * @property {String} bamSdk
 * @property {Object} commerce
 * @property {Object} configuration
 * @property {Object} content
 * @property {Object} customerService
 * @property {Object} drm
 * @property {Object} entitlement
 * @property {Object} flex
 * @property {Object} invoice
 * @property {Object} media
 * @property {Object} messaging
 * @property {Object} orchestration
 * @property {Object} paywall
 * @property {Object} purchase
 * @property {Object} qualityOfService
 * @property {Object} sdkSession
 * @property {Object} services
 * @property {Object} services.account
 * @property {Object} services.commerce
 * @property {Object} services.configuration
 * @property {Object} services.content
 * @property {Object} services.customerService
 * @property {Object} services.drm
 * @property {Object} services.eligibility
 * @property {Object} services.entitlement
 * @property {Object} services.externalActivation
 * @property {Object} services.flex
 * @property {Object} services.internal
 * @property {Object} services.invoice
 * @property {Object} services.media
 * @property {Object} services.orchestration
 * @property {Object} services.paywall
 * @property {Object} services.purchase
 * @property {Object} services.subscription
 * @property {Object} services.token
 * @property {Object} socket
 * @property {String} streamSample
 * @property {Object} subscription
 * @property {Object} userActivity
 * @property {Object} userProfile
 *
 * @example
 * SDK.Services.Internal.Dust.DustUrnReference.account.accountApi.getAccount
 * SDK.Services.Internal.Dust.DustUrnReference.services.account.accountClient.getCurrentAccount
 *
 */
export default {
    account: {
        accountApi: {
            authorize: 'urn:bamtech:dust:bamsdk:api:account:authorize',
            createAccount: 'urn:bamtech:dust:bamsdk:api:account:createAccount',
            getAccount: 'urn:bamtech:dust:bamsdk:api:account:getAccount',
            linkSubscriptionsFromDevice:
                'urn:bamtech:dust:bamsdk:api:account:linkSubscriptionsFromDevice',
            onboardAccountToStar:
                'urn:bamtech:dust:bamsdk:api:account:onboardAccountToStar',
            updateAccount: 'urn:bamtech:dust:bamsdk:api:account:updateAccount',
            updateProtectProfileCreationWithActionGrant:
                'urn:bamtech:dust:bamsdk:api:account:updateProtectProfileCreationWithActionGrant'
        }
    },
    bamSdk: 'urn:bamtech:dust:bamsdk',
    commerce: {
        commerceApi: {
            associateAuthValuesWithPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:associateAuthValuesWithPaymentMethod',
            createCardPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:createPaymentMethod',
            getDdcJwtToken:
                'urn:bamtech:dust:bamsdk:api:commerce:getDdcJwtToken',
            getDefaultPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:getDefaultPaymentMethod',
            getLocationCandidates:
                'urn:bamtech:dust:bamsdk:api:commerce:getLocationCandidate',
            getPaymentCard:
                'urn:bamtech:dust:bamsdk:api:commerce:getPaymentCard',
            getPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:getPaymentMethod',
            listAllPaymentMethods:
                'urn:bamtech:dust:bamsdk:api:commerce:listAllPaymentMethods',
            planSwitch: 'urn:bamtech:dust:bamsdk:api:commerce:planSwitch',
            priceOrder: 'urn:bamtech:dust:bamsdk:api:commerce:priceOrder',
            redeem: 'urn:bamtech:dust:bamsdk:api:commerce:redeem',
            restartSubscription:
                'urn:bamtech:dust:bamsdk:api:commerce:restartSubscription',
            resumeAccount: 'urn:bamtech:dust:bamsdk:api:commerce:resumeAccount',
            shareDefaultPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:shareDefaultPaymentMethod',
            submitOrderWithCardPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:submitOrderWithPaymentMethod',
            submitOrderWithPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:submitOrderWithPaymentMethod',
            updateOrder: 'urn:bamtech:dust:bamsdk:api:commerce:updateOrder',
            updatePaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:updatePaymentMethod',
            validateOrder:
                'urn:bamtech:dust:bamsdk:api:commerce:checkOrderStatus'
        },
        comcastApi: {
            createComcastPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:comcast:createComcastPaymentMethod',
            getComcastConsent:
                'urn:bamtech:dust:bamsdk:api:commerce:comcast:getComcastConsent',
            submitPayment:
                'urn:bamtech:dust:bamsdk:api:commerce:comcast:submitPayment'
        },
        iDealApi: {
            createIDealPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:iDeal:createIDealPaymentMethod',
            getIDealPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:iDeal:getIDealPaymentMethod',
            submitPayment:
                'urn:bamtech:dust:bamsdk:api:commerce:iDeal:submitPayment'
        },
        klarnaApi: {
            submitPayment:
                'urn:bamtech:dust:bamsdk:api:commerce:klarna:submitPayment'
        },
        mercadoApi: {
            submitPayment:
                'urn:bamtech:dust:bamsdk:api:commerce:mercado:submitPayment'
        },
        paymentCardApi: {
            createCardPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:paymentCard:createPaymentMethod',
            submitOrderWithCardPaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:paymentCard:submitOrderWithPaymentMethod'
        },
        payPalApi: {
            createBraintreePaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:paypal:createBraintreePaymentMethod',
            createBraintreeToken:
                'urn:bamtech:dust:bamsdk:api:commerce:paypal:createBraintreeToken',
            getBraintreePaymentMethodId:
                'urn:bamtech:dust:bamsdk:api:commerce:paypal:getBraintreePaymentMethodId',
            getCheckoutDetails:
                'urn:bamtech:dust:bamsdk:api:commerce:paypal:getCheckoutDetails',
            setCheckoutDetails:
                'urn:bamtech:dust:bamsdk:api:commerce:paypal:setCheckoutDetails',
            updateBraintreePaymentMethod:
                'urn:bamtech:dust:bamsdk:api:commerce:paypal:updateBraintreePaymentMethod'
        }
    },
    configuration: {
        configurationManager: {
            getConfiguration:
                'urn:bamtech:dust:bamsdk:service:configuration:loadRemote'
        }
    },
    content: {
        contentApi: {
            addToWatchlist:
                'urn:bamtech:dust:bamsdk:api:content:addToWatchlist',
            clearWatchlist:
                'urn:bamtech:dust:bamsdk:api:content:clearWatchlist',
            getBookmarks: 'urn:bamtech:dust:bamsdk:api:content:getBookmarks',
            getSearchSuggestions:
                'urn:bamtech:dust:bamsdk:api:content:getSearchSuggestions',
            getWatchlist: 'urn:bamtech:dust:bamsdk:api:content:getWatchlist',
            query: 'urn:bamtech:dust:bamsdk:api:content:query',
            removeFromWatchlist:
                'urn:bamtech:dust:bamsdk:api:content:removeFromWatchlist',
            search: 'urn:bamtech:dust:bamsdk:api:content:search'
        }
    },
    customerService: {
        customerServiceApi: {
            createSupportCode:
                'urn:bamtech:dust:bamsdk:api:customerService:createSupportCode'
        }
    },
    drm: {
        fairPlayDrmProvider: {
            getFairPlayCertificate:
                'urn:bamtech:dust:bamsdk:api:fairPlayDrmProvider:getFairPlayCertificate',
            getFairPlayLicense:
                'urn:bamtech:dust:bamsdk:api:fairPlayDrmProvider:getFairPlayLicense'
        },
        nagraDrmProvider: {
            getNagraCertificate:
                'urn:bamtech:dust:bamsdk:api:nagraDrmProvider:getNagraCertificate'
        },
        playReadyDrmProvider: {
            getPlayReadyLicense:
                'urn:bamtech:dust:bamsdk:api:playReadyDrmProvider:getPlayReadyLicense'
        },
        silkDrmProvider: {
            getSilkKey: 'urn:bamtech:dust:bamsdk:api:silkDrmProvider:getSilkKey'
        },
        widevineDrmProvider: {
            getWidevineCertificate:
                'urn:bamtech:dust:bamsdk:api:widevineDrmProvider:getWidevineCertificate',
            getWidevineLicense:
                'urn:bamtech:dust:bamsdk:api:widevineDrmProvider:getWidevineLicense'
        }
    },
    entitlement: {
        entitlementApi: {
            verifyMediaRights:
                'urn:bamtech:dust:bamsdk:api:entitlement:verifyMediaRights'
        }
    },
    flex: {
        flexApi: {
            getScreen: 'urn:bamtech:dust:bamsdk:api:flex:getScreen',
            execute: 'urn:bamtech:dust:bamsdk:api:flex:execute'
        }
    },
    invoice: {
        invoiceApi: {
            getInvoice: 'urn:bamtech:dust:bamsdk:api:invoice:getInvoice',
            getPaginatedInvoices:
                'urn:bamtech:dust:bamsdk:api:invoice:getPaginatedInvoices'
        }
    },
    media: {
        encryptionKeyProvider: {
            getEncryptionKey:
                'urn:bamtech:dust:bamsdk:api:media:encryptionKeyProvider:getEncryptionKey'
        },
        mediaApi: {
            createPlaybackSession:
                'urn:bamtech:dust:bamsdk:api:media:createPlaybackSession',
            fetch: 'urn:bamtech:dust:bamsdk:api:media:fetch',
            getSpriteSheetThumbnailSets:
                'urn:bamtech:dust:bamsdk:api:media:getSpriteSheetThumbnailSets',
            getSpriteSheetThumbnailSet:
                'urn:bamtech:dust:bamsdk:api:media:getSpriteSheetThumbnailSet',
            getSpriteSheetThumbnail:
                'urn:bamtech:dust:bamsdk:api:media:getSpriteSheetThumbnail',
            getBifThumbnailSets:
                'urn:bamtech:dust:bamsdk:api:media:getBifThumbnailSets',
            getBifThumbnailSet:
                'urn:bamtech:dust:bamsdk:api:media:getBifThumbnailSet'
        },
        playbackSession: {
            onAccessChanged:
                'urn:bamtech:dust:bamsdk:api:media:playbackSession:onAccessChanged',
            prepare:
                'urn:bamtech:dust:bamsdk:api:media:playbackSession:prepare',
            release: 'urn:bamtech:dust:bamsdk:api:media:playbackSession:release'
        }
    },
    messaging: {
        messagingApi: {
            registerChannel:
                'urn:bamtech:dust:bamsdk:api:messaging:registerChannel'
        }
    },
    orchestration: {
        orchestrationApi: {
            getOperationUrn: (name: string) =>
                `urn:bamtech:dust:bamsdk:api:orchestration:${name}`,
            query: 'urn:bamtech:dust:bamsdk:api:orchestration:query'
        }
    },
    paywall: {
        paywallApi: {
            getPaywall: 'urn:bamtech:dust:bamsdk:api:paywall:getPaywall'
        }
    },
    purchase: {
        purchaseApi: {
            createExternalRedemptionToken:
                'urn:bamtech:dust:bamsdk:api:purchase:createExternalRedemptionToken',
            generateActivationToken:
                'urn:bamtech:dust:bamsdk:api:purchase:generateActivationToken',
            getEligibilityStatus:
                'urn:bamtech:dust:bamsdk:api:purchase:getEligibilityStatus',
            redeem: 'urn:bamtech:dust:bamsdk:api:purchase:redeem',
            redeemBundle: 'urn:bamtech:dust:bamsdk:api:purchase:redeemBundle',
            redeemToken: 'urn:bamtech:dust:bamsdk:api:purchase:redeemToken',
            restore: 'urn:bamtech:dust:bamsdk:api:purchase:restore'
        }
    },
    ripcut: {
        ripcutApi: {
            getImage: 'urn:bamtech:dust:bamsdk:api:ripcut:getImage',
            getImageUrl: 'urn:bamtech:dust:bamsdk:api:ripcut:getImageUrl'
        }
    },
    qualityOfService: {
        audioChanged: 'urn:dss:event:fed:media:playback:audio:changed',
        bitrateChanged: 'urn:dss:event:fed:media:playback:bitrate:changed',
        drmCertificateFetched:
            'urn:dss:event:fed:media:drm:certificate:fetched',
        drmKeyFetched: 'urn:dss:event:fed:media:drm:key:fetched',
        error: 'urn:dss:event:qoe:client:error:v1',
        mediaPayloadFetched: 'urn:dss:event:fed:media:payload:fetched',
        mediaSegmentFetched: 'urn:dss:event:fed:media:segment:fetched',
        multivariantPlaylistFetched:
            'urn:dss:event:fed:media:playlist:master:fetched',
        playback: 'urn:dss:event:client:playback:event:v1',
        playbackAd: 'urn:dss:event:client:playback:ad:v1',
        playbackHeartbeat: 'urn:dss:event:client:playback:heartbeat:v1',
        playbackSessionTransferred:
            'urn:dss:event:fed:media:session:playback:transferred',
        playbackStartup: 'urn:dss:event:client:playback:startup:v1',
        subtitleChanged: 'urn:dss:event:fed:media:playback:subtitle:changed'
    },
    sdkSession: {
        assumeIdentity: 'urn:bamtech:dust:bamsdk:api:session:assumeIdentity',
        authorize: 'urn:bamtech:dust:bamsdk:api:session:authorize',
        createCustomerSupportCode:
            'urn:bamtech:dust:bamsdk:api:session:createCustomerSupportCode',
        createSdkSession: 'urn:bamtech:dust:bamsdk:api:session:create',
        getAccessState: 'urn:bamtech:dust:bamsdk:api:session:getAccessState',
        getExperimentAssignment:
            'urn:bamtech:dust:bamsdk:api:session:getExperimentAssignment',
        getInitializationState:
            'urn:bamtech:dust:bamsdk:api:session:getInitializationState',
        getSessionInfo: 'urn:bamtech:dust:bamsdk:api:session:getSessionInfo',
        getSessionToken: 'urn:bamtech:dust:bamsdk:api:session:getSessionToken',
        initialize: 'urn:bamtech:dust:bamsdk:api:session:initialize',
        initializeWithUserToken:
            'urn:bamtech:dust:bamsdk:api:session:initializeWithUserToken',
        isAuthorized: 'urn:bamtech:dust:bamsdk:api:session:isAuthorized',
        reauthorize: 'urn:bamtech:dust:bamsdk:api:session:reauthorize',
        reset: 'urn:bamtech:dust:bamsdk:api:session:reset',
        restoreAccessState:
            'urn:bamtech:dust:bamsdk:api:session:restoreAccessState'
    },
    services: {
        account: {
            accountClient: {
                createAccount:
                    'urn:bamtech:dust:bamsdk:service:account:createAccount',
                createAccountGrant:
                    'urn:bamtech:dust:bamsdk:service:account:createAccountGrant',
                getCurrentAccount:
                    'urn:bamtech:dust:bamsdk:service:account:getCurrentAccount',
                onboardAccountToStar:
                    'urn:bamtech:dust:bamsdk:service:account:onboardAccountToStar',
                onboardProfileToStar:
                    'urn:bamtech:dust:bamsdk:service:account:onboardProfileToStar',
                updateAttributes:
                    'urn:bamtech:dust:bamsdk:service:account:updateAttributes',
                updateProtectProfileCreationWithActionGrant:
                    'urn:bamtech:dust:bamsdk:service:account:updateProtectProfileCreationWithActionGrant'
            }
        },
        commerce: {
            commerceClient: {
                associateAuthValuesWithPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:associateAuthValuesWithPaymentMethod',
                createBraintreePaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:createBraintreePaymentMethod',
                createBraintreeToken:
                    'urn:bamtech:dust:bamsdk:service:commerce:createBraintreeToken',
                createCardPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:createPaymentMethod',
                createComcastPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:createComcastPaymentMethod',
                getBraintreePaymentMethodId:
                    'urn:bamtech:dust:bamsdk:service:commerce:getBraintreePaymentMethodId',
                getCheckoutDetails:
                    'urn:bamtech:dust:bamsdk:service:commerce:getCheckoutDetails',
                getComcastConsent:
                    'urn:bamtech:dust:bamsdk:service:commerce:getComcastConsent',
                getDdcJwtToken:
                    'urn:bamtech:dust:bamsdk:service:commerce:getDdcJwtToken',
                getDefaultPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:getDefaultPaymentMethod',
                getOrderStatus:
                    'urn:bamtech:dust:bamsdk:service:commerce:getOrderStatus',
                getPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:getPaymentMethod',
                listAllPaymentMethods:
                    'urn:bamtech:dust:bamsdk:service:commerce:listAllPaymentMethods',
                lookupByZipCode:
                    'urn:bamtech:dust:bamsdk:service:commerce:lookupByZipCode',
                planSwitch:
                    'urn:bamtech:dust:bamsdk:service:commerce:planSwitch',
                priceOrder:
                    'urn:bamtech:dust:bamsdk:service:commerce:priceOrder',
                redeem: 'urn:bamtech:dust:bamsdk:service:commerce:redeem',
                restartSubscription:
                    'urn:bamtech:dust:bamsdk:service:commerce:restartSubscription',
                resumeAccount:
                    'urn:bamtech:dust:bamsdk:service:commerce:resumeAccount',
                setCheckoutDetails:
                    'urn:bamtech:dust:bamsdk:service:commerce:setCheckoutDetails',
                shareDefaultPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:shareDefaultPaymentMethod',
                submitComcastPayment:
                    'urn:bamtech:dust:bamsdk:service:commerce:submitComcastPayment',
                submitIDealPayment:
                    'urn:bamtech:dust:bamsdk:service:commerce:submitIDealPayment',
                submitKlarnaPayment:
                    'urn:bamtech:dust:bamsdk:service:commerce:submitKlarnaPayment',
                submitMercadoPayment:
                    'urn:bamtech:dust:bamsdk:service:commerce:submitMercadoPayment',
                submitOrderWithPaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:submitOrderWithPaymentMethod',
                updateBraintreePaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:updateBraintreePaymentMethod',
                updateOrder:
                    'urn:bamtech:dust:bamsdk:service:commerce:updateOrder',
                updatePaymentMethod:
                    'urn:bamtech:dust:bamsdk:service:commerce:updatePaymentMethod'
            }
        },
        configuration: {
            configurationClient: {
                getConfiguration:
                    'urn:bamtech:dust:bamsdk:service:configuration:getConfiguration'
            }
        },
        content: {
            contentClient: {
                getSearchSuggestions:
                    'urn:bamtech:dust:bamsdk:service:content:getSearchSuggestions',
                query: 'urn:bamtech:dust:bamsdk:service:content:query'
            }
        },
        customerService: {
            customerServiceClient: {
                createSupportCode:
                    'urn:bamtech:dust:bamsdk:service:customerService:createSupportCode'
            }
        },
        drm: {
            drmClient: {
                getFairPlayCertificate:
                    'urn:bamtech:dust:bamsdk:service:drm:fairPlayCertificate',
                getFairPlayLicense:
                    'urn:bamtech:dust:bamsdk:service:drm:fairPlayLicense',
                getNagraCertificate:
                    'urn:bamtech:dust:bamsdk:service:drm:nagraCertificate',
                getNagraLicense:
                    'urn:bamtech:dust:bamsdk:service:drm:nagraLicense',
                getPlayReadyLicense:
                    'urn:bamtech:dust:bamsdk:service:drm:playReadyLicense',
                getSilkKey: 'urn:bamtech:dust:bamsdk:service:drm:silkKey',
                getWidevineCertificate:
                    'urn:bamtech:dust:bamsdk:service:drm:widevineCertificate',
                getWidevineLicense:
                    'urn:bamtech:dust:bamsdk:service:drm:widevineLicense'
            }
        },
        eligibility: {
            eligibilityClient: {
                getEligibilityStatus:
                    'urn:bamtech:dust:bamsdk:service:eligibility:getEligibilityStatus'
            }
        },
        entitlement: {
            entitlementClient: {
                verifyMediaRights:
                    'urn:bamtech:dust:bamsdk:service:entitlement:verifyMediaRights'
            }
        },
        externalActivation: {
            externalActivationClient: {
                createExternalRedemptionToken:
                    'urn:bamtech:dust:bamsdk:service:externalActivation:createExternalRedemptionToken',
                generateActivationToken:
                    'urn:bamtech:dust:bamsdk:service:externalActivation:generateActivationToken',
                redeemBundle:
                    'urn:bamtech:dust:bamsdk:service:externalActivation:redeemBundle',
                redeemToken:
                    'urn:bamtech:dust:bamsdk:service:externalActivation:redeemToken'
            }
        },
        flex: {
            flexClient: {
                getScreen: 'urn:bamtech:dust:bamsdk:service:flex:getScreen',
                execute: 'urn:bamtech:dust:bamsdk:service:flex:execute'
            }
        },
        internal: {
            telemetry: {
                telemetryClient: {
                    postEvents:
                        'urn:bamtech:dust:bamsdk:service:telemetry:postEvent'
                },
                eventBuffer: {
                    processBatch:
                        'urn:bamtech:dust:bamsdk:service:telemetry:processBatch'
                }
            }
        },
        invoice: {
            invoiceClient: {
                getInvoice:
                    'urn:bamtech:dust:bamsdk:service:invoice:getInvoice',
                getPaginatedInvoices:
                    'urn:bamtech:dust:bamsdk:service:invoice:getPaginatedInvoices'
            }
        },
        media: {
            adEngine: {
                adEngineClient: {
                    getCookies:
                        'urn:bamtech:dust:bamsdk:service:adEngine:setTokenRedirect',
                    updateCookies:
                        'urn:bamtech:dust:bamsdk:service:adEngine:setToken'
                }
            },
            mediaClient: {
                createAuthCookie:
                    'urn:bamtech:dust:bamsdk:service:media:createAuthCookie',
                downloadBifThumbnail:
                    'urn:bamtech:dust:bamsdk:service:media:bifThumbnail',
                getBifThumbnails:
                    'urn:bamtech:dust:bamsdk:service:media:bifThumbnails',
                getSpriteSheetThumbnail:
                    'urn:bamtech:dust:bamsdk:service:media:spriteSheetThumbnail',
                getSpriteSheetThumbnails:
                    'urn:bamtech:dust:bamsdk:service:media:spriteSheetThumbnails',
                mediaPayload:
                    'urn:bamtech:dust:bamsdk:service:media:postForMediaPayload'
            }
        },
        orchestration: {
            orchestrationClient: {
                getOperationUrn: (name: string) =>
                    `urn:bamtech:dust:bamsdk:service:orchestration:${name}`,
                exchangeDeviceGrantForAccessToken:
                    'urn:bamtech:dust:bamsdk:service:orchestration:exchangeDeviceGrantForAccessToken',
                query: 'urn:bamtech:dust:bamsdk:service:orchestration:query',
                refreshToken:
                    'urn:bamtech:dust:bamsdk:service:orchestration:refreshToken',
                registerDevice:
                    'urn:bamtech:dust:bamsdk:service:orchestration:registerDevice'
            }
        },
        paywall: {
            paywallClient: {
                getPaywall: 'urn:bamtech:dust:bamsdk:service:paywall:paywall'
            }
        },
        purchase: {
            purchaseClient: {
                getTokens: 'urn:bamtech:dust:bamsdk:service:purchase:getTokens',
                redeemPurchases:
                    'urn:bamtech:dust:bamsdk:service:purchase:redeemPurchases',
                restorePurchases:
                    'urn:bamtech:dust:bamsdk:service:purchase:restorePurchases'
            }
        },
        ripcut: {
            ripcutClient: {
                getImage: 'urn:bamtech:dust:bamsdk:service:ripcut:getImage',
                getImageUrl:
                    'urn:bamtech:dust:bamsdk:service:ripcut:getImageUrl'
            }
        },
        subscription: {
            subscriptionClient: {
                getAccountSubscription:
                    'urn:bamtech:dust:bamsdk:service:subscription:getAccountSubscription',
                getSubscriberInfo:
                    'urn:bamtech:dust:bamsdk:service:subscription:getSubscriberInfo',
                getSubscriptions:
                    'urn:bamtech:dust:bamsdk:service:subscription:getSubscriptions',
                linkSubscriptionsFromDevice:
                    'urn:bamtech:dust:bamsdk:service:subscription:linkSubscriptionsFromDevice'
            }
        },
        token: {
            tokenClient: {
                exchange: 'urn:bamtech:dust:bamsdk:service:token:exchange'
            }
        }
    },
    socket: {
        socketApi: {
            sendMessage: 'urn:bamtech:dust:bamsdk:api:socket:sendMessage',
            start: 'urn:bamtech:dust:bamsdk:api:socket:start',
            stop: 'urn:bamtech:dust:bamsdk:api:socket:stop'
        },
        socketManager: {
            ageVerificationRedeemCompleted:
                'urn:dss:event:age-verification:redeem:completed',
            ageVerificationRedeemErrored:
                'urn:dss:event:age-verification:redeem:errored',
            ageVerificationRequestCompleted:
                'urn:dss:event:age-verification:request:completed',
            ageVerificationRequestErrored:
                'urn:dss:event:age-verification:request:errored',
            authenticated: 'urn:dss:transport:edge:event:authenticated',
            authentication: 'urn:dss:event:edge:sdk:authentication',
            edgeEventReceived: 'urn:dss:transport:edge:event:received',
            event: 'urn:dss:event:sdk:api:event',
            exposure: 'urn:dss:event:weaponx:exposure',
            flowControl: 'urn:dss:event:edge:sdk:flowControlRequest',
            flowControlError: 'urn:dss:transport:edge:event:flowControlError',
            offDeviceTokenRefresh: 'urn:dss:event:offDeviceLogin:refresh',
            ping: 'urn:dss:transport:edge:event:ping',
            pong: 'urn:dss:transport:edge:event:pong',
            received: 'urn:dss:transport:edge:event:received',
            reconnect: 'urn:dss:event:edge:sdk:reconnect',
            refresh: 'urn:dss:event:edge:sdk:token:refresh',
            sessionAuthentication:
                'urn:dss:event:edge:sdk:sessionAuthentication',
            sourcePrefix: 'urn:dss:source:sdk:javascript',
            unauthenticated: 'urn:dss:transport:edge:event:unauthenticated'
        }
    },
    streamSample: 'urn:dss:telemetry-service:event:stream-sample',
    subscription: {
        subscriptionApi: {
            getAccountSubscription:
                'urn:bamtech:dust:bamsdk:api:subscription:getAccountSubscription',
            getSubscriberInfo:
                'urn:bamtech:dust:bamsdk:api:subscription:getSubscriberInfo',
            getSubscriptions:
                'urn:bamtech:dust:bamsdk:api:subscription:getSubscriptions'
        }
    },
    userActivity: {
        personalization: {
            contentRecommendationAward:
                'urn:bamtech:dust:bamsdk:app:content-recommendation-reward:v1'
        },
        glimpse: {
            appLaunched: 'urn:dss:glimpse:event:app-lifecycle:app-launched',
            backgroundPlaybackExited:
                'urn:dss:glimpse:track-action:background-playback-exited',
            backgroundPlaybackStarted:
                'urn:dss:glimpse:track-action:background-playback-started',
            contentSelected:
                'urn:dss:glimpse:event:track-action:content-selected',
            playbackExited:
                'urn:dss:glimpse:event:track-action:playback-exited',
            playbackSelected:
                'urn:dss:glimpse:event:track-action:playback-selected',
            purchaseCompleted:
                'urn:dss:glimpse:event:track-action:purchase-completed',
            purchaseCompletedv2: 'urn:dss:event:fed:purchase:completed-v2',
            searchViewLoaded:
                'urn:dss:glimpse:event:track-action:search-view-loaded',
            viewLoaded: 'urn:dss:glimpse:event:track-state:view-loaded'
        }
    },
    userProfile: {
        userProfileApi: {
            onboardProfileToStar:
                'urn:bamtech:dust:bamsdk:api:userProfile:onboardProfileToStar'
        }
    }
};
