/**
 *
 * @module delegationToken
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md#delegation-grants
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TokenRequestBuilder from './tokenRequestBuilder';

/**
 *
 * @since 4.9.0
 * @desc Delegation grants allow a device to temporarily act on behalf of an
 * account and identity transferred to it from another device. Delegation exchanges
 * are performed by a receiving device (the "actor") through an access token provided
 * from a sending device (the "subject"). This permits a receiver to assume the identity
 * and scope delegated to it by the sender and interact with services on that
 * identity's behalf.
 * @note The classic example of this is Chromecast, where an identity authorized
 * on a sending device (e.g. Google Home or a Disney+ sender application) is used
 * for playback on the receiving device (the Chromecast).
 *
 */
export default class DelegationToken extends TokenRequestBuilder {
    /**
     *
     * @param {String} subject
     * @param {String} subjectType
     *
     */
    constructor(subject, subjectType) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                subject: Types.nonEmptyString,
                subjectType: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {String}
         * @since 4.9.0
         * @note Is the token received from the sender. Disney+ sender applications
         * should utilize the current access context's refresh token.
         *
         */
        this.subject = subject;

        /**
         *
         * @access public
         * @type {String}
         * @since 4.9.0
         * @note `urn:ietf:params:oauth:token-type:access_token` or
         * `urn:ietf:params:oauth:token-type:refresh_token` depending on the
         * type of token in `subject_token`.
         *
         */
        this.subjectType = subjectType;

        /**
         *
         * @access private
         * @type {String}
         * @since 4.9.0
         * @note always expected to be token-exchange for delegation grants.
         *
         */
        this.grantType = 'urn:ietf:params:oauth:grant-type:token-exchange';

        /**
         *
         * @access private
         * @type {String}
         * @since 4.9.0
         * @note always expected to be refresh_token for delegation grants.
         *
         */
        this.actorType = 'urn:ietf:params:oauth:token-type:refresh_token';

        /**
         *
         * @desc Data used in a TokenRequest by the TokenRequestBuilder.
         * @note When a delegation grant is exchanged with the token service,
         * the request body must include the following fields:
         *   - grant_type
         *   - subject_token_type
         *   - subject_token
         *   - actor_token_type
         *   - actor_token
         *
         */
        this.tokenData.subject_token_type = this.subjectType;
        this.tokenData.subject_token = this.subject;
        this.tokenData.actor_token_type = this.actorType;
        this.tokenData.actor_token = '';
    }

    // #region private

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {String} actorToken
     * @note The actor token to be set upstream (via SDK.SdkSession#assumeIdentity)
     *
     */
    set actor(actorToken) {
        this.tokenData.actor_token = actorToken;
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @desc The current access context device refresh token
     * @returns {String}
     *
     */
    get actor() {
        return this.tokenData.actor_token;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.DelegationToken';
    }

    // #endregion
}
