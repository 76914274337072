/**
 *
 * @module purchaseManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import RetryPolicy from './retryPolicy';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class PurchaseManagerExtrasMap {
    /**
     *
     * @param {RetryPolicy} retryPolicy
     *
     */
    constructor(retryPolicy) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                retryPolicy: Types.instanceStrict(RetryPolicy)
            };

            typecheck.warn(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.RetryPolicy}
         * @desc Gets or sets the retry policy to help drive the retry behavior for service failures.
         *
         */
        this.retryPolicy = retryPolicy;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.PurchaseManagerExtrasMap';
    }

    // #endregion
}
