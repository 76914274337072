/**
 *
 * @module mediaThumbnailLinks
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import MediaThumbnailLink from './mediaThumbnailLink';

/**
 *
 *
 */
export default class MediaThumbnailLinks {
    /**
     *
     * @param {Object} thumbnails
     * @note If the playlist service response contains a `thumbnails` object we will try to construct one or both of
     * the properties in this file. These properties will be used when attempting to fetch thumbnails.
     * @note both `spritesheet` and `bif` are optional
     *
     */
    constructor(thumbnails) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                thumbnails: Types.object()
            };

            typecheck(this, params, arguments);
        }

        const { bif, spritesheet } = thumbnails;

        /**
         *
         * @access public
         * @type {SDK.Services.Media.MediaThumbnailLink|null}
         * @desc response from the playlist service used to fetch a thumbnail presentation
         *
         */
        this.bif = Check.assigned(bif) ? new MediaThumbnailLink(bif) : null;

        /**
         *
         * @access public
         * @type {SDK.Services.Media.MediaThumbnailLink|null}
         * @desc response from the playlist service used to fetch a thumbnail presentation
         *
         */
        this.spritesheet = Check.assigned(spritesheet)
            ? new MediaThumbnailLink(spritesheet)
            : null;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaThumbnailLinks';
    }

    // #endregion
}
