/**
 *
 * @module playbackResumedCause
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 5.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackResumedCause
 * @desc Reason playback resumed.
 *
 * @property {String} user - Since `5.0.0` - If the user pressed play from a paused state.
 * @property {String} stall - Since `5.0.0` - If the player buffered enough to resume playback.
 * @property {String} applicationForegrounded - Since `5.0.0` - Where the application is brought to foreground
 * after being previously background.
 * @property {String} liveEndofWindow - Since `9.0.0` - If a user has paused live content and exceeds available window,
 * they will be unpaused and start at end of the window.
 *
 */
export default keyMirror({
    user: null,
    stall: null,
    applicationForegrounded: null,
    liveEndofWindow: null
});
