/**
 *
 * @module graphQlAccountDelegationRefreshToken
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 16.0.0
 *
 */
export default class GraphQlAccountDelegationRefreshToken {
    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {String}
     *
     */
    public tokenType: string;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {String}
     *
     */
    public token: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.tokenType
     * @param {String} options.token
     *
     */
    public constructor(options: { tokenType: string; token: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    tokenType: Types.nonEmptyString,
                    token: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { tokenType, token } = options;

        this.tokenType = tokenType;
        this.token = token;
    }

    // #region private

    /**
     *
     * @access protected
     *
     */
    public toString() {
        return 'SDK.Services.GraphQl.GraphQlAccountDelegationRefreshToken';
    }

    // #endregion
}
