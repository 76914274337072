/**
 *
 * @module contentManagerConfiguration
 *
 */

import ContentClientConfiguration from '../content/contentClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing content data.
 *
 */
export default class ContentManagerConfiguration extends ServiceConfiguration<ContentClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.ContentManagerConfiguration';
    }

    // #endregion
}
