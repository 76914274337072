/**
 *
 * @module protocol
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.Protocol
 * @desc Protocol strings used for filtering during playback selection
 *
 * @property {String} HTTP - Since `4.19.0`.
 * @property {String} HTTPS - Since `4.18.0`.
 *
 * @example
 * Protocol.HTTPS // 'HTTPS'
 *
 */
export default keymirror({
    HTTP: null,
    HTTPS: null
});
