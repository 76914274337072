/**
 *
 * @module dustEvent
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import TelemetryEvent from './../telemetry/telemetryEvent';

import DustPayload from '../../services/internal/dust/dustPayload';
import DustClientPayload from '../../services/internal/dust/dustClientPayload';
import DustServerPayload from '../../services/internal/dust/dustServerPayload';

/**
 *
 * @access protected
 * @implements {TelemetryEvent}
 *
 */
export default class DustEvent extends TelemetryEvent {
    /**
     *
     * @access public
     * @type {SDK.Services.Internal.Dust.DustClientPayload}
     * @desc Dictionary containing client provided tracking information.
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#client-data
     *
     */
    public client: DustClientPayload;

    /**
     *
     * @access public
     * @type {SDK.Services.Internal.Dust.DustServerPayload|Object}
     * @desc Optional Dictionary containing server provided tracking information.
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#server-data
     *
     */
    public server?: DustServerPayload;
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Internal.Dust.DustClientPayload} options.client
     * @param {SDK.Services.Internal.Dust.DustServerPayload} [options.server={}]
     *
     */
    public constructor(options: {
        client: DustClientPayload;
        server?: DustServerPayload;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            // eslint-disable-next-line no-inner-declarations
            function checkServer(server: DustServerPayload) {
                if (Check.nonEmptyObject(server)) {
                    return Check.instanceStrict(server, DustServerPayload);
                }

                return Check.object(server);
            }

            const params = {
                options: Types.object({
                    client: Types.instanceStrict(DustClientPayload),
                    server: Types.custom(checkServer).optional
                    //server: Types.instanceStrict(DustServerPayload).or.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { server = {}, client } = options;

        this.client = client;
        this.server = server as DustServerPayload; // TODO refine types to work with default empty object
    }

    /**
     *
     * @access private
     * @desc assembles a dust payload object
     * @returns {SDK.Services.Internal.Dust.DustPayload} jsonSerializable object
     * @note should take a an empty `Object` for `server` by default
     *
     */
    public override getPayload() {
        const { server, client } = this;

        return new DustPayload({
            server,
            client
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Dust.DustEvent';
    }
}
