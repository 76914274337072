/**
 *
 * @module entry
 * @desc {SDK.Services.Invoice} entry point.
 *
 */

import InvoiceClient from './invoiceClient';

/**
 *
 * @typedef {Object} SDK.Services.Invoice
 *
 * @property {InvoiceClient} InvoiceClient
 *
 */
export default {
    InvoiceClient
};
