/**
 *
 * @module entitlementManagerConfiguration
 *
 */

import EntitlementClientConfiguration from '../entitlement/entitlementClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for entitlement.
 *
 */
export default class EntitlementManagerConfiguration extends ServiceConfiguration<EntitlementClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.EntitlementManagerConfiguration';
    }

    // #endregion
}
