/**
 *
 * @module deviceManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/device.md
 * @note it is necessary to import `DeviceGrantStorage` directly to avoid a circular dependency.
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import TokenUpdater from './../services/tokenUpdater';

import Logger from './../logging/logger';
import DeviceGrantStorage from './../token/deviceGrantStorage';
import OrchestrationManager from '../orchestration/orchestrationManager';

import AdvertisingIdProvider from './../advertising/advertisingIdProvider';
import DeviceAttributeProvider from './deviceAttributeProvider';
import DeviceMetadata from './deviceMetadata';

import DeviceGrant from '../services/token/deviceGrant';
import BaseEnvironmentConfiguration from '../services/providers/baseEnvironmentConfiguration';
import PlatformProviders from '../services/providers/platformProviders';
import InternalEvents from '../internalEvents';
import LogTransaction from '../logging/logTransaction';

/**
 *
 * @access protected
 * @desc Provides a manager to be used to work with device grants.
 *
 */
export default class DeviceManager {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     * @memberof {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {DeviceGrantStorage}
     *
     */
    private deviceGrantStorage: DeviceGrantStorage;

    /**
     *
     * @access private
     * @type {BaseEnvironmentConfiguration}
     *
     */
    private environmentConfiguration: BaseEnvironmentConfiguration;

    /**
     *
     * @access private
     * @since 20.0.0
     * @type {SDK.Advertising.AdvertisingIdProvider}
     *
     */
    private advertisingIdProvider: AdvertisingIdProvider;

    /**
     *
     * @access private
     * @type {DeviceAttributeProvider}
     *
     */
    public deviceAttributeProvider: DeviceAttributeProvider;

    /**
     *
     * @access private
     * @since 9.0.0
     * @type {OrchestrationManager}
     * @desc The orchestration manager object.
     *
     */
    private orchestrationManager: OrchestrationManager;

    /**
     *
     * @access private
     * @since 9.0.0
     * @type {TokenUpdater}
     * @desc The object responsible for allowing updates to tokens.
     *
     */
    private tokenUpdater: TokenUpdater;

    /**
     *
     * @access private
     * @since 15.2.0
     * @type {String}
     * @desc Used for device registration to populate the `device.platform` value
     * on the access token. Maps to `SdkSessionConfiguration.commonValues.platformId`.
     *
     */
    private devicePlatformId: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {DeviceGrantStorage} options.deviceGrantStorage
     * @param {BaseEnvironmentConfiguration} options.environmentConfiguration
     * @param {SDK.Advertising.AdvertisingIdProvider} options.advertisingIdProvider
     * @param {DeviceAttributeProvider} options.deviceAttributeProvider
     * @param {OrchestrationManager} options.orchestrationManager
     * @param {TokenUpdater} options.tokenUpdater
     * @param {String} options.devicePlatformId
     *
     */
    public constructor(options: {
        logger: Logger;
        deviceGrantStorage: DeviceGrantStorage;
        environmentConfiguration: BaseEnvironmentConfiguration;
        advertisingIdProvider: AdvertisingIdProvider;
        deviceAttributeProvider: DeviceAttributeProvider;
        orchestrationManager: OrchestrationManager;
        tokenUpdater: TokenUpdater;
        devicePlatformId: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    deviceGrantStorage:
                        Types.instanceStrict(DeviceGrantStorage),
                    environmentConfiguration: Types.instanceStrict(
                        PlatformProviders.EnvironmentConfiguration
                    ),
                    advertisingIdProvider: Types.instanceStrict(
                        AdvertisingIdProvider
                    ),
                    deviceAttributeProvider: Types.instanceStrict(
                        DeviceAttributeProvider
                    ),
                    orchestrationManager:
                        Types.instanceStrict(OrchestrationManager),
                    tokenUpdater: Types.instanceStrict(TokenUpdater),
                    devicePlatformId: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            logger,
            deviceGrantStorage,
            environmentConfiguration,
            advertisingIdProvider,
            deviceAttributeProvider,
            orchestrationManager,
            tokenUpdater,
            devicePlatformId
        } = options;

        this.logger = logger;
        this.deviceGrantStorage = deviceGrantStorage;
        this.environmentConfiguration = environmentConfiguration;
        this.advertisingIdProvider = advertisingIdProvider;
        this.deviceAttributeProvider = deviceAttributeProvider;
        this.orchestrationManager = orchestrationManager;
        this.tokenUpdater = tokenUpdater;
        this.devicePlatformId = devicePlatformId;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {Object} [options]
     * @param {String} [options.userToken]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Token.DeviceGrant>}
     *
     */
    public async getDeviceGrant(
        options: { userToken?: string } | undefined,
        logTransaction: LogTransaction
    ): Promise<DeviceGrant> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    userToken: Types.nonEmptyString.optional
                }).optional
            };

            typecheck(this, 'getDeviceGrant', params, arguments);
        }

        const deviceGrant: Nullable<DeviceGrant> =
            this.deviceGrantStorage.getDeviceGrant();

        if (Check.assigned(deviceGrant)) {
            return deviceGrant as DeviceGrant;
        }

        return await this.createDeviceGrant(options, logTransaction);
    }

    /**
     *
     * @access private
     * @param {Object} [options]
     * @param {String} [options.userToken]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Create a new device grant. It should only be called if there is no stored device grant.
     * @returns {Promise<SDK.Services.Token.DeviceGrant>}
     *
     */
    public async createDeviceGrant(
        options: { userToken?: string } | undefined,
        logTransaction: LogTransaction
    ): Promise<DeviceGrant> {
        const {
            advertisingIdProvider,
            deviceGrantStorage,
            deviceAttributeProvider,
            logger,
            environmentConfiguration,
            devicePlatformId
        } = this;

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    userToken: Types.nonEmptyString.optional
                }).optional
            };

            typecheck(this, 'createDeviceGrant', params, arguments);
        }

        const { userToken } = options || {};

        const { deviceFamily, applicationRuntime, deviceProfile } =
            environmentConfiguration;

        const attributes = deviceAttributeProvider.getDeviceAttributes();
        const advertisingId = advertisingIdProvider.getId();

        DeviceAttributeProvider.validateAttributes(attributes, advertisingId);

        const deviceMetadata = new DeviceMetadata({
            deviceFamily,
            applicationRuntime,
            deviceProfile,
            attributes,
            devicePlatformId
        });

        try {
            const registerDeviceOptions = {
                deviceMetadata,
                userToken
            };

            const responsePromise = this.orchestrationManager.registerDevice(
                registerDeviceOptions,
                logTransaction
            );

            this.tokenUpdater.currentTokenRefreshPromise = responsePromise;

            const response: TodoAny = await responsePromise;

            const deviceGrant = response.extensions.deviceGrant;

            logger.info(this.toString(), 'DeviceGrant Created');

            await deviceGrantStorage.saveDeviceGrant(deviceGrant);

            this.tokenUpdater.emit(
                InternalEvents.DeviceRegistered,
                deviceGrant
            );

            return deviceGrant;
        } finally {
            this.tokenUpdater.currentTokenRefreshPromise = null;
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Device.DeviceManager';
    }
}
