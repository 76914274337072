/**
 *
 * @module adEngineClientConfiguration
 *
 */

import ServiceClientConfiguration from '../../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the AdEngineClient.
 *
 */
export default class AdEngineClientConfiguration extends ServiceClientConfiguration {
    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.AdEngine.AdEngineClientConfiguration';
    }

    // #endregion
}
