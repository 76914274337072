/**
 *
 * @module entry
 * @desc {SDK.Content} entry point.
 *
 */

import ContentApi from './contentApi';
import ContentManager from './contentManager';
import GraphQlQuery from './graphQlQuery';
import GraphQlPersistedQuery from './graphQlPersistedQuery';
import ContentQuery from './contentQuery';
import SearchOverrides from './searchOverrides';

import Search from './search/entry';
import Watchlist from './watchlist/entry';
import Bookmarks from './bookmarks/entry';

/**
 *
 * @typedef {Object} SDK.Content
 *
 * @property {SDK.Content.ContentApi} ContentApi
 * @property {SDK.Content.ContentManager} ContentManager
 * @property {SDK.Content.GraphQlQuery} GraphQlQuery
 * @property {SDK.Content.GraphQlPersistedQuery} GraphQlPersistedQuery
 * @property {SDK.Content.BookmarksQuery} BookmarksQuery
 * @property {SDK.Content.ContentQuery} ContentQuery
 * @property {SDK.Content.SearchQuery} SearchQuery
 * @property {SDK.Content.SearchOverrides} SearchOverrides
 *
 * @property {SDK.Content.Bookmarks} Bookmarks
 * @property {SDK.Content.Search} Search
 * @property {SDK.Content.Watchlist} Watchlist
 *
 */
export default {
    ContentApi,
    ContentManager,
    GraphQlQuery,
    GraphQlPersistedQuery,
    ContentQuery,
    SearchOverrides,

    Bookmarks,
    Search,
    Watchlist
};
