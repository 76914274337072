/**
 *
 * @module entry
 * @desc {SDK.Services.ExternalActivation} entry point.
 *
 */

import ActivationToken from './activationToken';
import ExternalActivationClient from './externalActivationClient';
import ExternalActivationResult from './externalActivationResult';
import TemporaryDefaultAccessActivationStatus from './temporaryDefaultAccessActivationStatus';

/**
 *
 * @typedef {Object} SDK.Services.ExternalActivation
 *
 * @property {ActivationToken} ActivationToken
 * @property {ExternalActivationClient} ExternalActivationClient
 * @property {ExternalActivationResult} ExternalActivationResult
 * @property {SDK.Services.ExternalActivation.TemporaryDefaultAccessActivationStatus} TemporaryDefaultAccessActivationStatus
 *
 */
export default {
    ActivationToken,
    ExternalActivationClient,
    ExternalActivationResult,
    TemporaryDefaultAccessActivationStatus
};
