/**
 *
 * @module deviceGrant
 *
 */

import Grant from './grant';

/**
 *
 * @desc A special type of grant used for exchanging device information for a token.
 *
 */
export default class DeviceGrant extends Grant {
    /**
     *
     * @param {String} grantType
     * @param {String} assertion
     *
     */
    constructor(grantType, assertion) {
        super(grantType, assertion);

        /**
         *
         * @access public
         * @type {String}
         * @desc Key used to lookup the subject_token_type value needed during token exchange.
         *
         */
        this.subjectTokenTypeKey = 'device';
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.DeviceGrant';
    }

    // #endregion
}
