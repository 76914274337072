/**
 *
 * @module MelHivePlayerAdapter
 * @desc PlayerAdapter for mel-hive devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/master/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/master/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/events.html
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import getSafe from '../../services/util/getSafe';
import adEngineRegex from '../../services/util/adEngineRegex';
import parseUrl from '../../services/util/parseUrl';

import PublicEvents from './../../events';
import InternalEvents from './../../internalEvents';
import PlaybackEventListener from './../playbackEventListener';
import DrmType from '../../services/media/drmType';
import DrmProvider from '../../drm/drmProvider';
import PlaybackMetrics from './../playbackMetrics';
import Playlist from './../playlist';
import PlayerAdapter from './../playerAdapter';
import BamHlsErrorMapping from './../bamHlsErrorMapping';

import AudioRendition from './../../services/media/audioRendition';
import InsertionPointPlacement from './../../services/media/insertionPointPlacement';
import PlaybackVariant from './../../services/media/playbackVariant';
import SubtitleRendition from './../../services/media/subtitleRendition';
import PlaylistType from '../../services/media/playlistType';

import BufferType from '../../services/qualityOfService/bufferType';
import ErrorLevel from '../../services/qualityOfService/errorLevel';
import FetchStatus from '../../services/qualityOfService/fetchStatus';
import HttpMethod from '../../services/qualityOfService/httpMethod';
import MediaSegmentType from '../../services/qualityOfService/mediaSegmentType';
import NetworkType from '../../services/qualityOfService/networkType';
import mapHiveToQoeErrorCodes from '../../services/qualityOfService/mapHiveToQoeErrorCodes';
import PlaybackExitedCause from '../../services/qualityOfService/playbackExitedCause';
import PlaybackPausedCause from '../../services/qualityOfService/playbackPausedCause';
import PlaybackResumedCause from '../../services/qualityOfService/playbackResumedCause';
import PlaybackSeekCause from '../../services/qualityOfService/playbackSeekCause';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import PlaybackState from '../../services/qualityOfService/playbackState';
import PlayerSeekDirection from '../../services/qualityOfService/playerSeekDirection';
import SeekDirection from '../../services/qualityOfService/seekDirection';
import ServerRequest from '../../services/qualityOfService/serverRequest';
import StartupActivity from '../../services/qualityOfService/startupActivity';
import QoePlaybackError from '../../services/qualityOfService/qoePlaybackError';

import {
    PodPosition,
    PresentationType
} from '../../services/qualityOfService/enums';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';
import ApplicationContext from '../../services/qualityOfService/applicationContext';

import { getResolutionString } from './playerAdapterUtils';

const defaultAdMetadata = {
    adSlotData: {
        adMediaId: '00000000-0000-0000-0000-000000000000',
        slotNumber: 0,
        plannedLength: 0
    },
    adPodPlacement: {
        podPosition: PodPosition.preroll
    },
    adPodData: {
        plannedSlotCount: 0,
        plannedLength: 0
    },
    mediaId: '00000000-0000-0000-0000-000000000000'
};

/**
 *
 * @since 18.0.0
 * @desc Interface used to communicate with the media player.
 *
 */
export default class MelHivePlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer - An instance of MelHivePlayer.
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The MelHivePlayerAdapter requires nativePlayer.on && nativePlayer.off
     *
     */
    constructor(options) {
        super(options);

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Number|undefined}
         * @desc Value of the `PROGRAM-DATE-TIME` of the last (most recent) seek-able segment + segment length in
         * epoch millis i.e. end of last segment. Used to support live latency calculation.
         * @note This is stored here as an intermediary due to the data being returned in one event but being needed in others.
         *
         */
        this.seekableRangeEndProgramDateTime = undefined;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {SDK.Services.QualityOfService.PlaybackStartupEventData}
         * @desc Keeps track of CDN fallback for QoE support.
         *
         */
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Object}
         * @desc Keeps track of CDN fallback QoE tracking information.
         *
         */
        this.qos = {};

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Date}
         * @desc Time when rebufferingStartedEvent is triggered. Used to calculate duration for rebufferingEndedEvent.
         *
         */
        this.rebufferingDuration = null;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {Array<SDK.Drm.DrmProvider>}
         * @desc Set of DRM providers
         *
         */
        this.drmProviders = [];

        /**
         * @access public
         * @since 18.0.0
         * @type {Object}
         * @desc formatted drmConfigurations object to be used on the MelHive player.
         */
        this.drmConfigurations = {};

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {HlsStream}
         *
         */
        this.melStatic = this.nativePlayer.getClass();

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {HlsStream.Events}
         * @note Events enum
         *
         */
        this.Events = this.melStatic.Events;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {HlsStream.MediaElementStates}
         * @note MediaElementStates Enum
         *
         */
        this.MediaElementStates = this.melStatic.MediaElementStates;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {HlsStream.DataTypes}
         * @note DataTypes Enum
         *
         */
        this.DataTypes = this.melStatic.DataTypes;

        /**
         *
         * @access private
         * @since 21.0.0
         * @type {HlsStream.AssetTypes}
         * @note AssetTypes Enum
         *
         */
        this.AssetTypes = this.melStatic.AssetTypes;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Boolean}
         * @desc Indicates when player is buffering.
         *
         */
        this.isBuffering = false;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Boolean}
         * @desc Indicates when application is backgrounded or foregrounded.
         *
         */
        this.isApplicationBackgrounded = false;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Number}
         * @desc current playing segment from the `SEGMENT_PLAYING` event.
         * @note eventData.segment.programDateTimeStart.
         *
         */
        this.segmentPosition = null;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Object}
         * @desc set by the application via this.setSeekData();
         *
         */
        this.seekData = {};

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Number}
         * @desc the starting bitrate for the media content.
         *
         */
        this.mediaStartBitrate = null;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {String<SDK.Services.QualityOfService.MediaSegmentType>}
         * @desc the starting bitrate for the media content.
         *
         */
        this.mediaSegmentType = null;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Number}
         * @desc the total number of media bytes downloaded.
         *
         */
        this.mediaBytesDownloaded = 0;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {SDK.Services.QualityOfService.BufferType}
         * @desc Used to store buffer type for rebuffering ended.
         *
         */
        this.bufferType = undefined;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Boolean}
         * @desc A flag to keep track of when the player is ready.
         *
         */
        this.isReady = false;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Object}
         * @desc Cached values use for the heartbeat event that are updated
         * when specific events fire.
         *
         */
        this.heartbeatData = {};

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Number}
         * @desc Used to track total time of media downloaded (gets reset with each heartbeat).
         *
         */
        this.mediaDownloadTotalTime = 0;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Number}
         * @desc Used to track how many times media gets downloaded (gets reset with each heartbeat).
         *
         */
        this.mediaDownloadTotalCount = 0;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Object}
         * @desc Used to store the last known playback metrics raised
         *
         */
        this._latestPlaybackMetricsChanged = null;

        /**
         *
         * @access private
         * @since 18.0.0
         * @type {Object}
         * @desc Used to store the last known network metrics raised
         *
         */
        this._latestNetworkMetricsChanged = null;

        /**
         *
         * @access private
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.PresentationType>}
         * @desc Used to store the last known presentation type.
         *
         */
        this.presentationType = PresentationType.main;

        /**
         *
         * @access private
         * @since 19.0.0
         * @type {Object}
         * @desc Used to store various data related to ads.
         *
         */
        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        /**
         *
         * @access private
         * @since 20.0.1
         * @type {Number}
         * @desc used to indicate the current bitrateAvg for the chosen variant
         *
         */
        this.bitrateAvg = 0;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    async setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        const { cdnFallback } = this;

        // only attempt to assign this if the user hasn't already done so when initializing bam-hls
        if (Check.not.assigned(this.nativePlayer.setupXhrCallback)) {
            this.setupXhrCallback();
        }

        // `playlistUri` can be either a string or array of objects for backwards compatibility purposes.
        this.playlistUri = playlist.streamUri;
        this.currentStreamUrl = playlist.streamUri;

        // @todo not sure what to do about this yet, configs should come from PCS so we shouldn't be updating anything here

        if (cdnFallback.isEnabled) {
            this.playlistUri = playlist.mediaSources;
            //     this.nativePlayer.config = {
            //         bufferingTimeoutStart: cdnFallback.defaultTimeout ? cdnFallback.defaultTimeout * 1000 : 90000,
            //         requestStartContentNetworkFallbackLimit: cdnFallback.fallbackLimit || 5
            //     };
        }
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        this.playlistUri = '';
        this.drmProvider = null;
        this.drmProviders = [];
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.qos = {};
        this.mediaBytesDownloaded = 0;
        this.isBuffering = false;
        this.isReady = false;
        this.heartbeatData = {};
        this.mediaDownloadTotalCount = 0;
        this.mediaDownloadTotalTime = 0;
        this.bitrateAvg = 0;
        this.seekData = null;
        this.presentationType = PresentationType.main;
        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.clean();

        this.nativePlayer = null;
        this.accessToken = null;
        this.playbackStartupEventData = null;
        this._latestPlaybackMetricsChanged = null;
        this._latestNetworkMetricsChanged = null;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Object} data
     * @param {Number} data.seekSize - Indication that the user is seeking by a fixed time (size) e.g. +30, -30
     * @param {String<SDK.Services.QualityOfService.SeekDirection>} [data.seekDirection] - Used for Live events
     * @param {String<SDK.Services.QualityOfService.PlaybackSeekCause>} data.seekCause
     * @desc sets the seekData property provided by the application.
     *
     */
    setSeekData(data) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                data: Types.object({
                    seekSize: Types.number,
                    seekDirection: Types.in(SeekDirection).optional,
                    seekCause: Types.in(PlaybackSeekCause)
                })
            };

            typecheck.warn(this, 'setSeekData', params, arguments);
        }

        this.seekData = data;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Sets the application state flag to backgrounded.
     *
     */
    setApplicationBackgrounded() {
        this.isApplicationBackgrounded = true;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Sets the application state flag to foregrounded.
     *
     */
    setApplicationForegrounded() {
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Object} [options]
     * @param {Object} [options.eventData]
     * @param {Object} [options.eventData.errorName] - include if playback ended with an error
     * @param {Object} [options.eventData.errorDetail] - include if playback ended with an error
     * @param {Boolean} [options.isEnded]
     * @param {String<PlaybackExitedCause>} [options.cause] - Since `20.1.0` - signal the type of `PlaybackExitedCause`
     * @note eventData is internal use only when called via playbackErrorEvent, should be undefined otherwise.
     *
     */
    playbackEndedEvent(options) {
        const { eventData, isEnded } = options || {};
        const { playbackStartupEventData } = this;
        const { errorName: playbackError, errorDetail: playbackErrorDetail } =
            eventData || {};

        let { cause } = options || {};

        const playbackData = this.getPlaybackData();

        if (!PlaybackExitedCause[cause]) {
            if (Check.assigned(eventData)) {
                cause = PlaybackExitedCause.error;
            } else if (isEnded) {
                cause = PlaybackExitedCause.playedToEnd;
            } else {
                cause = PlaybackExitedCause.user;
            }
        }

        const playbackEndedEvent = {
            ...playbackData,
            cause,
            playbackError,
            playbackErrorDetail
        };

        playbackEndedEvent.cdnRequestedTrail =
            playbackStartupEventData.cdnRequestedTrail;
        playbackEndedEvent.cdnFailedTrail =
            playbackStartupEventData.cdnFailedTrail;
        playbackEndedEvent.cdnFallbackCount =
            playbackStartupEventData.cdnFallbackCount;
        playbackEndedEvent.isCdnFallback =
            playbackStartupEventData.isCdnFallback;

        // case: Playback has ended and the rebufferingEndedEvent did not fire for an unknown reason.
        if (this.isBuffering) {
            this.rebufferingEndedEvent();
        }

        if (this.presentationType === PresentationType.ad) {
            this.adPlaybackEnded({ cause, playbackError, playbackErrorDetail });
        }

        this.onPlaybackEnded(playbackEndedEvent);
    }

    // #region protected

    /**
     *
     * @access protected
     * @since 18.0.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {SDK.Services.Exception.ServiceException} Unable to add PlaybackEventListener
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Void}
     * @note messageDetailed is the error message pre-formatted with error, message and cause
     * from bam-hls ERROR event
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, boundHandlers, Events } = this;

        const {
            MEDIAELEMENT_STATE_CHANGED,
            PRESENTATION_STATE_LOADED,
            MEDIA_PLAYING,
            DRM_LICENSE_RECEIVED,
            CANPLAY,
            SEEKING_STARTED,
            SEEKABLE_RANGE_CHANGED,
            BUFFERING_STARTED,
            BUFFERING_FINISHED,
            SUBTITLE_RENDITION_CHANGED,
            MULTIVARIANT_PLAYLIST_REQUEST,
            MULTIVARIANT_PLAYLIST_FALLBACK,
            MULTIVARIANT_PLAYLIST_LOADED,
            SEEKING_FINISHED,
            VIDEO_RENDITION_UPDATED,
            AUDIO_RENDITION_CHANGED,
            ERROR,
            SEGMENT_PLAYING,
            SOURCE_BUFFER_APPEND_STARTED,
            CONTENT_DOWNLOAD_FINISHED,
            PLAYBACK_METRICS_CHANGED,
            NETWORK_METRICS_CHANGED,
            INTERSTITIAL_SESSION_REQUESTED,
            INTERSTITIAL_SESSION_FETCHED,
            INTERSTITIAL_SESSION_STARTED,
            INTERSTITIAL_SESSION_FINISHED,
            INTERSTITIAL_MULTIVARIANT_FETCHED,
            INTERSTITIAL_VARIANT_FETCHED,
            INTERSTITIAL_ASSET_STARTED,
            INTERSTITIAL_ASSET_FINISHED,
            BEACON_ERROR,
            INTERSTITIAL_SESSION_REQUESTED_ERROR
        } = Events;

        if (Check.function(nativePlayer.on)) {
            this.listener = listener;

            boundHandlers.playbackStartedEvent =
                this.playbackStartedEvent.bind(this);
            boundHandlers.mediaElementStateChangedEvent =
                this.mediaElementStateChangedEvent.bind(this);
            boundHandlers.playbackInitializedEvent =
                this.playbackInitializedEvent.bind(this);
            boundHandlers.playbackReadyEvent =
                this.playbackReadyEvent.bind(this);
            boundHandlers.playbackSeekStartedEvent =
                this.playbackSeekStartedEvent.bind(this);
            boundHandlers.playbackSeekEndedEvent =
                this.playbackSeekEndedEvent.bind(this);
            boundHandlers.bitrateChangedEvent =
                this.bitrateChangedEvent.bind(this);
            boundHandlers.rebufferingStartedEvent =
                this.rebufferingStartedEvent.bind(this);
            boundHandlers.rebufferingEndedEvent =
                this.rebufferingEndedEvent.bind(this);
            boundHandlers.subtitleChangedEvent =
                this.subtitleChangedEvent.bind(this);
            boundHandlers.audioChangedEvent = this.audioChangedEvent.bind(this);
            boundHandlers.multivariantPlaylistFetchedEvent =
                this.multivariantPlaylistFetchedEvent.bind(this);
            boundHandlers.variantPlaylistFetchedEvent =
                this.variantPlaylistFetchedEvent.bind(this);
            boundHandlers.successfulPlaylistLoad =
                this.successfulPlaylistLoad.bind(this);
            boundHandlers.multivariantPlaylistRequest =
                this.multivariantPlaylistRequest.bind(this);
            boundHandlers.multivariantPlaylistFallback =
                this.multivariantPlaylistFallback.bind(this);
            boundHandlers.drmKeyFetchedEvent =
                this.drmKeyFetchedEvent.bind(this);
            boundHandlers.seekableRangeChangedEvent =
                this.seekableRangeChangedEvent.bind(this);
            boundHandlers.playbackErrorEvent =
                this.playbackErrorEvent.bind(this);
            boundHandlers.segmentPlayingEvent =
                this.segmentPlayingEvent.bind(this);
            boundHandlers.sourceBufferAppendStartedEvent =
                this.sourceBufferAppendStartedEvent.bind(this);
            boundHandlers.contentDownloadFinishedEvent =
                this.contentDownloadFinishedEvent.bind(this);
            boundHandlers.playbackMetricsChanged =
                this.playbackMetricsChanged.bind(this);
            boundHandlers.networkMetricsChanged =
                this.networkMetricsChanged.bind(this);
            boundHandlers.adPodRequested = this.adPodRequested.bind(this);
            boundHandlers.adPodFetched = this.adPodFetched.bind(this);
            boundHandlers.adMultivariantFetched =
                this.adMultivariantFetched.bind(this);
            boundHandlers.adVariantFetched = this.adVariantFetched.bind(this);
            boundHandlers.adPlaybackStarted = this.adPlaybackStarted.bind(this);
            boundHandlers.adPlaybackEnded = this.adPlaybackEnded.bind(this);
            boundHandlers.adPodStarted = this.adPodStarted.bind(this);
            boundHandlers.adPodEnded = this.adPodEnded.bind(this);
            boundHandlers.adBeaconError = this.adBeaconError.bind(this);
            boundHandlers.adRequestedError = this.adRequestedError.bind(this);

            nativePlayer.on(
                PRESENTATION_STATE_LOADED,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.on(
                MEDIAELEMENT_STATE_CHANGED,
                boundHandlers.mediaElementStateChangedEvent
            );
            nativePlayer.on(
                NETWORK_METRICS_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.on(
                BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.on(
                BUFFERING_FINISHED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.on(
                SUBTITLE_RENDITION_CHANGED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.on(
                AUDIO_RENDITION_CHANGED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.successfulPlaylistLoad
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_REQUEST,
                boundHandlers.multivariantPlaylistRequest
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_FALLBACK,
                boundHandlers.multivariantPlaylistFallback
            );
            nativePlayer.on(
                VIDEO_RENDITION_UPDATED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.on(
                SEEKING_STARTED,
                boundHandlers.playbackSeekStartedEvent
            );
            nativePlayer.on(
                SEEKING_FINISHED,
                boundHandlers.playbackSeekEndedEvent
            );
            nativePlayer.on(ERROR, boundHandlers.playbackErrorEvent);
            nativePlayer.on(SEGMENT_PLAYING, boundHandlers.segmentPlayingEvent);
            nativePlayer.on(
                SOURCE_BUFFER_APPEND_STARTED,
                boundHandlers.sourceBufferAppendStartedEvent
            );
            nativePlayer.on(
                CONTENT_DOWNLOAD_FINISHED,
                boundHandlers.contentDownloadFinishedEvent
            );
            nativePlayer.on(
                PLAYBACK_METRICS_CHANGED,
                boundHandlers.playbackMetricsChanged
            );
            nativePlayer.on(
                NETWORK_METRICS_CHANGED,
                boundHandlers.networkMetricsChanged
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_REQUESTED,
                boundHandlers.adPodRequested
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_FETCHED,
                boundHandlers.adPodFetched
            );
            nativePlayer.on(
                INTERSTITIAL_MULTIVARIANT_FETCHED,
                boundHandlers.adMultivariantFetched
            );
            nativePlayer.on(
                INTERSTITIAL_VARIANT_FETCHED,
                boundHandlers.adVariantFetched
            );
            nativePlayer.on(
                INTERSTITIAL_ASSET_STARTED,
                boundHandlers.adPlaybackStarted
            );
            nativePlayer.on(
                INTERSTITIAL_ASSET_FINISHED,
                boundHandlers.adPlaybackEnded
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_STARTED,
                boundHandlers.adPodStarted
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_FINISHED,
                boundHandlers.adPodEnded
            );
            nativePlayer.on(BEACON_ERROR, boundHandlers.adBeaconError);
            nativePlayer.on(
                INTERSTITIAL_SESSION_REQUESTED_ERROR,
                boundHandlers.adRequestedError
            );

            if (SEEKABLE_RANGE_CHANGED) {
                nativePlayer.on(
                    SEEKABLE_RANGE_CHANGED,
                    boundHandlers.seekableRangeChangedEvent
                );
            }

            if (DRM_LICENSE_RECEIVED) {
                nativePlayer.on(
                    DRM_LICENSE_RECEIVED,
                    boundHandlers.drmKeyFetchedEvent
                );
            }

            if (Check.function(nativePlayer.once)) {
                nativePlayer.once(CANPLAY, boundHandlers.playbackReadyEvent);
                nativePlayer.once(
                    MEDIA_PLAYING,
                    boundHandlers.playbackStartedEvent
                );
            }
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @param {SDK.Media.PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer, boundHandlers, Events } = this;

        const {
            MEDIAELEMENT_STATE_CHANGED,
            PRESENTATION_STATE_LOADED,
            MEDIA_PLAYING,
            DRM_LICENSE_RECEIVED,
            CANPLAY,
            SEEKING_STARTED,
            SEEKABLE_RANGE_CHANGED,
            BUFFERING_STARTED,
            BUFFERING_FINISHED,
            SUBTITLE_RENDITION_CHANGED,
            MULTIVARIANT_PLAYLIST_REQUEST,
            MULTIVARIANT_PLAYLIST_FALLBACK,
            MULTIVARIANT_PLAYLIST_LOADED,
            SEEKING_FINISHED,
            VIDEO_RENDITION_UPDATED,
            AUDIO_RENDITION_CHANGED,
            ERROR,
            SEGMENT_PLAYING,
            SOURCE_BUFFER_APPEND_STARTED,
            CONTENT_DOWNLOAD_FINISHED,
            PLAYBACK_METRICS_CHANGED,
            NETWORK_METRICS_CHANGED,
            INTERSTITIAL_SESSION_REQUESTED,
            INTERSTITIAL_SESSION_FETCHED,
            INTERSTITIAL_SESSION_STARTED,
            INTERSTITIAL_SESSION_FINISHED,
            INTERSTITIAL_MULTIVARIANT_FETCHED,
            INTERSTITIAL_VARIANT_FETCHED,
            INTERSTITIAL_ASSET_STARTED,
            INTERSTITIAL_ASSET_FINISHED,
            BEACON_ERROR,
            INTERSTITIAL_SESSION_REQUESTED_ERROR
        } = Events;

        const isCorrectListener =
            Check.assigned(this.listener) && this.listener === listener;

        if (Check.function(nativePlayer.off) && isCorrectListener) {
            this.listener = null;

            nativePlayer.off(
                PRESENTATION_STATE_LOADED,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.off(MEDIA_PLAYING, boundHandlers.playbackStartedEvent);
            nativePlayer.off(
                MEDIAELEMENT_STATE_CHANGED,
                boundHandlers.mediaElementStateChangedEvent
            );
            nativePlayer.off(CANPLAY, boundHandlers.playbackReadyEvent);
            nativePlayer.off(
                NETWORK_METRICS_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.off(
                BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.off(
                BUFFERING_FINISHED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.off(
                SUBTITLE_RENDITION_CHANGED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.off(
                AUDIO_RENDITION_CHANGED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.successfulPlaylistLoad
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_REQUEST,
                boundHandlers.multivariantPlaylistRequest
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_FALLBACK,
                boundHandlers.multivariantPlaylistFallback
            );
            nativePlayer.off(
                VIDEO_RENDITION_UPDATED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.off(
                SEEKING_STARTED,
                boundHandlers.playbackSeekStartedEvent
            );
            nativePlayer.off(
                SEEKING_FINISHED,
                boundHandlers.playbackSeekEndedEvent
            );
            nativePlayer.off(ERROR, boundHandlers.playbackErrorEvent);
            nativePlayer.off(
                SEGMENT_PLAYING,
                boundHandlers.segmentPlayingEvent
            );
            nativePlayer.off(
                SOURCE_BUFFER_APPEND_STARTED,
                boundHandlers.sourceBufferAppendStartedEvent
            );
            nativePlayer.off(
                CONTENT_DOWNLOAD_FINISHED,
                boundHandlers.contentDownloadFinishedEvent
            );
            nativePlayer.off(
                PLAYBACK_METRICS_CHANGED,
                boundHandlers.playbackMetricsChanged
            );
            nativePlayer.off(
                NETWORK_METRICS_CHANGED,
                boundHandlers.networkMetricsChanged
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_REQUESTED,
                boundHandlers.adPodRequested
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_FETCHED,
                boundHandlers.adPodFetched
            );
            nativePlayer.off(
                INTERSTITIAL_MULTIVARIANT_FETCHED,
                boundHandlers.adMultivariantFetched
            );
            nativePlayer.off(
                INTERSTITIAL_VARIANT_FETCHED,
                boundHandlers.adVariantFetched
            );
            nativePlayer.off(
                INTERSTITIAL_ASSET_STARTED,
                boundHandlers.adPlaybackStarted
            );
            nativePlayer.off(
                INTERSTITIAL_ASSET_FINISHED,
                boundHandlers.adPlaybackEnded
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_STARTED,
                boundHandlers.adPodStarted
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_FINISHED,
                boundHandlers.adPodEnded
            );
            nativePlayer.off(BEACON_ERROR, boundHandlers.adBeaconError);
            nativePlayer.off(
                INTERSTITIAL_SESSION_REQUESTED_ERROR,
                boundHandlers.adRequestedError
            );

            if (SEEKABLE_RANGE_CHANGED) {
                nativePlayer.off(
                    SEEKABLE_RANGE_CHANGED,
                    boundHandlers.seekableRangeChangedEvent
                );
            }

            if (DRM_LICENSE_RECEIVED) {
                nativePlayer.off(
                    DRM_LICENSE_RECEIVED,
                    boundHandlers.drmKeyFetchedEvent
                );
            }
        }
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    async setDrmProviders(drmProviders) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer } = this;

        this.drmProviders = drmProviders;

        const drmCapabilities = await nativePlayer.getDrmCapabilities();

        const { keySystems = {} } = drmCapabilities;

        let drmProvidersIndex = drmProviders.length;

        while (drmProvidersIndex--) {
            const type = drmProviders[drmProvidersIndex].type;
            const provider =
                type === DrmType.PRMNAGRA ? keySystems.NAGRA : keySystems[type];

            if (getSafe(() => !provider.supported, true)) {
                drmProviders.splice(drmProvidersIndex, 1);
            }
        }

        this.drmConfiguration = this.setDrmConfiguration();

        // @todo could we provide a callback function to the applications so they know then the drmConfigurations are available?
        // Then it seems like the could do playerAdapter.drmConfigurations and pluck out the value to pass to MelHive?
        // nativePlayer.drmConfiguration = this.setDrmConfiguration();
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @note DssHls expects the keySystems[x].keySystem value to be `NAGRA` instead of `PRMNAGRA`
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    setDrmConfiguration() {
        const { drmProviders } = this;

        const providerImplementations = {
            [DrmType.FAIRPLAY]: {
                keySystem: DrmType.FAIRPLAY,
                getCertificate: (drmProvider) =>
                    drmProvider.getFairPlayCertificate()
            },
            [DrmType.WIDEVINE]: {
                keySystem: DrmType.WIDEVINE,
                getCertificate: (drmProvider) =>
                    drmProvider.getWidevineCertificate()
            },
            [DrmType.PRMNAGRA]: {
                keySystem: 'NAGRA',
                getCertificate: (drmProvider) =>
                    drmProvider.getNagraCertificate()
            }
        };

        const keySystems = drmProviders.map((drmProvider) => {
            const keySystemObj = {
                individualizationUri: drmProvider.individualizationUri,
                keySystem: drmProvider.type,
                licenseRequestUri: drmProvider.licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                serverCertificate: Promise.resolve(undefined)
            };

            const providerImplementation =
                providerImplementations[drmProvider.type];

            if (providerImplementation) {
                const { keySystem, getCertificate } = providerImplementation;

                keySystemObj.keySystem = keySystem;
                keySystemObj.serverCertificate = (async () => {
                    return await getCertificate(drmProvider);
                })();
            }

            return keySystemObj;
        });

        return {
            keySystems
        };
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @desc Gets a snapshot of information about media playback.
     * @returns {SDK.Media.PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note (chosenBitRate / 1000) - need to convert to Kbps
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        const {
            positionPdt,
            isLive,
            isBehindLive,
            variants,
            currentVariantIndex
        } = this.nativePlayer;

        let liveLatencyAmount = 0;
        let bufferSegmentDuration = 0;

        const isLiveEdge = isLive ? !isBehindLive : false;
        const playbackMetrics =
            this.internalGetPlaybackMetrics() ||
            this.nativePlayer.getPlaybackMetrics();
        const networkMetrics =
            this.internalGetNetworkMetrics() ||
            this.nativePlayer.getNetworkMetrics();
        const currentInterstitial = this.nativePlayer.currentInterstitial;

        if (currentInterstitial) {
            this.adData.adMetadata.adPlayheadPosition =
                currentInterstitial.position
                    ? Math.floor(currentInterstitial.position)
                    : 0;

            if (currentInterstitial.currentAsset) {
                const { index: slotNumber, mediaId: adMediaId } =
                    currentInterstitial.currentAsset;

                const adDataData = this.getAdDataData(adMediaId, slotNumber);

                if (adDataData) {
                    const { adSlotData = defaultAdMetadata.adSlotData } =
                        adDataData;

                    this.adData.adMetadata.adSlotData = adSlotData;
                }
            }
        }

        const {
            mainContentTime: currentPlayhead,
            bufferedRange = {},
            seekableRange,
            currentTime
        } = playbackMetrics;
        const { throughput } = networkMetrics;
        const { averageBitrate = 0, peakBitrate = 0 } =
            variants[currentVariantIndex] || {};

        const chosenBitrateKbps = averageBitrate / 1000; // divide by 1000 to convert to Kbps

        if (isBehindLive && seekableRange.pdtEnd - positionPdt > 0) {
            liveLatencyAmount = seekableRange.pdtEnd - positionPdt;
        }

        if (bufferedRange.end - currentTime > 0) {
            bufferSegmentDuration = bufferedRange.end - currentTime;
        }

        return new PlaybackMetrics({
            currentBitrate: chosenBitrateKbps,
            currentPlayhead,
            currentBitrateAvg: averageBitrate,
            currentBitratePeak: peakBitrate,
            currentThroughput: throughput,
            playheadProgramDateTime: positionPdt,
            seekableRangeEndProgramDateTime: seekableRange.pdtEnd,
            isLiveEdge,
            bufferSegmentDuration,
            mediaBytesDownloaded: this.mediaBytesDownloaded,
            playbackState: this.getPlaybackState(),
            segmentPosition: positionPdt,
            liveLatencyAmount,
            maxAllowedVideoBitrate: this.getMaxAllowedVideoBitrate(),
            adMetadata: this.adData ? this.adData.adMetadata : undefined
        });
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @returns {String}
     *
     */
    getCdnName() {
        return getSafe(() => this.qos.cdnName, 'null');
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @returns {Number}
     *
     */
    getAudioBitrate() {
        const { audioRenditions = [], currentAudioRenditionIndex } =
            this.nativePlayer;
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex] || {};

        return audioRendition.averageBitrate;
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @returns {Number}
     *
     */
    getMaxAllowedVideoBitrate() {
        const variants = getSafe(() => this.nativePlayer.variants, []);
        const maxVariant = variants[variants.length - 1] || {};

        return maxVariant.peakBitrate;
    }

    // #region private

    /**
     *
     * @access private
     * @since 18.0.0
     * @note Handles the MEDIA_PLAYING event. This uses the nativePlayer.once to make sure that this QoE
     * event is only posted once. The MEDIA_PLAYING event will fire every time media is started, even after pausing, so
     * we only want this QoE event to post after the first time MEDIA_PLAYING fires and then disregard it thereafter
     * for this playback session.
     *
     */
    playbackStartedEvent() {
        const playbackData = this.getPlaybackData();

        this.onPlaybackStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_LOADED event
     *
     */
    multivariantPlaylistFetchedEvent(eventData) {
        const { isLive, isSlidingWindow } = this.nativePlayer;

        if (Check.object(eventData)) {
            const { id, playlist } = eventData;
            const url = playlist ? playlist.url : eventData.url;
            const priority = playlist ? playlist.priority : 1;

            if (
                Check.assigned(id) &&
                id !== this.melStatic.AssetIds.MAIN_CONTENT
            ) {
                return;
            }

            const playbackStartupData = this.getPlaybackStartupData();

            const { host, path } = parseUrl(url);

            let playlistLiveType;

            if (isLive && isSlidingWindow) {
                playlistLiveType = PlaylistType.SLIDE;
            } else {
                playlistLiveType = PlaylistType.COMPLETE;
            }

            const serverRequest = new ServerRequest({
                host,
                path,
                status: FetchStatus.completed,
                method: HttpMethod.get
            });

            this.emit(InternalEvents.UpdateAdEngine, priority);

            this.onMultivariantPlaylistFetched({
                ...playbackStartupData,
                mediaStartBitrate: this.mediaStartBitrate,
                playlistLiveType,
                serverRequest
            });
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} [eventData={}]
     * @note Handles the VIDEO_RENDITION_UPDATED event
     * @note The VariantPlaylistFetchedEventData.serverRequest.status is only set if there were no errors loading
     * the variant, e.g. nativePlayer.variants[eventData.level].loadError = 0. The status is not set if loadError > 0
     * because the nativePlayer does not expose the reason for the errors.
     *
     */
    variantPlaylistFetchedEvent(eventData) {
        const { rendition = {}, serverRequest: request = {} } = eventData || {};
        const { url } = rendition;
        const { host, path } = parseUrl(url);

        const playbackMetrics = this.getPlaybackMetrics();
        const currentVariant = this.getCurrentVariant();

        const { averageBitrate, bitrate, resolution } = currentVariant;

        const serverRequest = new ServerRequest({
            ...request,
            host,
            path,
            method: HttpMethod[(request.method || 'get').toLowerCase()],
            status:
                FetchStatus[(request.fetchStatus || '').toLowerCase()] ||
                FetchStatus.completed,
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const details = { ...rendition };

        this.onVariantPlaylistFetched({
            ...eventData,
            playlistAverageBandwidth: averageBitrate,
            playlistBandwidth: bitrate,
            playlistResolution: resolution,
            serverRequest,
            playheadPosition: playbackMetrics.currentPlayhead,
            details
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the CANPLAY event
     *
     */
    playbackReadyEvent() {
        const playbackStartupData = this.getPlaybackStartupData();

        this.onPlaybackReady({
            ...playbackStartupData,
            mediaStartBitrate: this.mediaStartBitrate
        });

        this.isReady = true;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @desc Handles the MEDIAELEMENT_STATE_CHANGED event
     *
     */
    mediaElementStateChangedEvent(eventData) {
        const { MediaElementStates } = this;
        const { mediaElementState, lastMediaElementState } = eventData || {};

        const playbackData = this.getPlaybackData();

        let cause;

        if (
            lastMediaElementState === MediaElementStates.PAUSED &&
            mediaElementState === MediaElementStates.PLAYING
        ) {
            cause = PlaybackResumedCause.user; // resuming playback is always caused by the user

            this.onPlaybackResumed({
                ...playbackData,
                cause
            });
        }

        if (
            lastMediaElementState === MediaElementStates.PLAYING &&
            mediaElementState === MediaElementStates.PAUSED
        ) {
            if (this.isApplicationBackgrounded) {
                cause = PlaybackPausedCause.applicationBackgrounded;
            } else if (this.isBuffering) {
                cause = PlaybackPausedCause.stall;
            } else {
                cause = PlaybackPausedCause.user;
            }

            this.onPlaybackPaused({
                ...playbackData,
                cause
            });
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Triggered when playback is initialized.
     * @note Handles the PRESENTATION_STATE_LOADED event
     *
     */
    playbackInitializedEvent() {
        const playbackStartupData = this.getPlaybackStartupData();

        const { variant, audio, subtitle } = playbackStartupData;

        // Cache values to be used for heartbeat event.
        this.heartbeatData = {
            playlistAudioChannels: variant.audioChannels,
            playlistAudioCodec: variant.audioCodec,
            playlistAudioLanguage: audio.language,
            playlistAudioName: audio.name,
            playlistSubtitleLanguage: subtitle.language,
            playlistSubtitleName: subtitle.name,
            subtitleVisibility: subtitle.forced
        };

        this.onPlaybackInitialized(playbackStartupData);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     *
     */
    drmKeyFetchedEvent(eventData) {
        const playbackStartupData = this.getPlaybackStartupData();

        const { url = '', keyID: drmKeyId } = eventData || {};
        const { host, path } = parseUrl(url);

        const serverRequest = new ServerRequest({
            host,
            path,
            status: FetchStatus.completed,
            method: HttpMethod.get
        });

        this.onDrmKeyFetched({
            ...playbackStartupData,
            drmKeyId,
            serverRequest
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_LOADED event to update tracking when successful playlist loads.
     *
     */
    successfulPlaylistLoad(eventData) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.currentStreamUrl = playlist.url;

            if (playlist.tracking) {
                this.onSuccessfulPlaylistLoad(playlist);
            }
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_REQUEST event to update tracking when successful playlist loads.
     *
     */
    multivariantPlaylistRequest(eventData) {
        const { playbackStartupEventData } = this;
        const { playlist } = eventData || {};

        if (playlist && playlist.tracking) {
            // This check is necessary, due to some asset types (Bumpers) won't have tracking data.

            const qosData = getSafe(() => playlist.tracking.qos);
            const cdnVendor = qosData ? qosData.cdnVendor : null;

            this.qos = qosData;

            playbackStartupEventData.fallbackAttempt(cdnVendor);

            if (playbackStartupEventData.isCdnFallback) {
                playbackStartupEventData.setQosData(qosData);
                this.onPlaybackReattempt(playbackStartupEventData);
            }
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_FALLBACK event to update tracking when successful playlist loads.
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    multivariantPlaylistFallback(eventData) {
        const { playbackStartupEventData } = this;
        const { playlist } = eventData || {};

        if (playlist && playlist.tracking) {
            playbackStartupEventData.playbackError = QoePlaybackError.unknown;
            playbackStartupEventData.fallbackFailed();
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_STARTED event
     *
     */
    playbackSeekStartedEvent(eventData) {
        const { isLive } = this.nativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause = PlaybackSeekCause.app, seekSize } =
            this.seekData || {};
        const playbackMetrics = this.getPlaybackMetrics();
        const seekDistance = Math.abs(Math.floor(targetPosition - currentTime));

        let playerSeekDirection;
        let seekDirection;

        if (seekSize < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (seekSize === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekStarted({
            ...eventData,
            playerSeekDirection,
            seekSize,
            seekDistance,
            playheadPosition: playbackMetrics.currentPlayhead,
            cause,
            seekDirection
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_FINISHED event
     *
     */
    playbackSeekEndedEvent(eventData) {
        const { isLive } = this.nativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause = PlaybackSeekCause.app, seekSize } =
            this.seekData || {};
        const playbackMetrics = this.getPlaybackMetrics();
        const seekDistance = Math.abs(Math.floor(targetPosition - currentTime));

        let playerSeekDirection;
        let seekDirection;

        if (seekSize < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (seekSize === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekEnded({
            ...eventData,
            playerSeekDirection,
            seekDistance,
            playheadPosition: playbackMetrics.currentPlayhead,
            cause,
            seekDirection
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the ERROR event
     *
     */
    playbackErrorEvent(eventData) {
        const { fatal = false, message: errorDetail } = eventData || {};
        const { currentPDT: segmentPosition } = this.nativePlayer;
        const { liveLatencyAmount, currentPlayhead: playheadPosition } =
            this.getPlaybackMetrics();
        const { adPodData, adSlotData, adPodPlacement } =
            this.adData.adMetadata;

        const errorName = mapHiveToQoeErrorCodes(eventData);

        if (fatal) {
            // @todo probably don't need this anymore since Bam-hls is gone?
            if (eventData.error === 'DRM_FAILED') {
                const errorCase =
                    this.constructErrorCaseFromDssHlsError(eventData);

                this.emit(PublicEvents.MediaFailure, errorCase);
            }

            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: fatal,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.error,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adSlotData,
                    adPodData,
                    adPodPlacement
                });

                this.playbackEndedEvent({
                    eventData: { errorName, errorDetail },
                    cause: PlaybackExitedCause.error
                });

                this.removeListener(this.listener);
            }
        } else {
            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: false,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.warn,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adSlotData,
                    adPodData,
                    adPodPlacement
                });
            }
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SEEKABLE_RANGE_CHANGED event
     *
     */
    seekableRangeChangedEvent(eventData) {
        const { pdtEnd } = eventData || {};

        this.seekableRangeEndProgramDateTime = pdtEnd;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} [eventData={}]
     * @note Handles the NETWORK_METRICS_CHANGED event
     *
     */
    bitrateChangedEvent(eventData = {}) {
        if (this.isReady) {
            const { variants = [], currentVariantIndex } = this.nativePlayer;
            const { currentBitratePeak, currentPlayhead } =
                this.getPlaybackMetrics();
            const { averageBitrate } = variants[currentVariantIndex] || {};

            if (this.bitrateAvg !== averageBitrate) {
                if (this.bitrateAvg) {
                    // don't trigger event on initial load
                    this.onBitrateChanged({
                        ...eventData,
                        bitrateAvg: averageBitrate,
                        bitratePeak: currentBitratePeak,
                        playheadPosition: currentPlayhead
                    });
                }

                this.bitrateAvg = averageBitrate;
            }
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SUBTITLE_RENDITION_CHANGED event
     *
     */
    subtitleChangedEvent(eventData) {
        const { currentSubtitleRendition } = eventData || {};

        const { subtitleRenditions = [] } = this.nativePlayer;
        const subtitleRendition =
            subtitleRenditions[currentSubtitleRendition] || {};

        const {
            language: subtitleLanguage,
            name: subtitleName,
            forced: subtitleVisibility
        } = subtitleRendition || {};

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistSubtitleLanguage = subtitleLanguage;
        this.heartbeatData.playlistSubtitleName = subtitleName;
        this.heartbeatData.subtitleVisibility = subtitleVisibility;

        this.onSubtitleChanged({
            subtitleLanguage,
            subtitleName,
            subtitleVisibility
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the AUDIO_RENDITION_CHANGED event
     *
     */
    audioChangedEvent(eventData) {
        // TODO Typecheck validation warn?

        if (this.isReady) {
            const { currentAudioRendition } = eventData || {};

            const { audioRenditions = [] } = this.nativePlayer;
            const audioRendition = audioRenditions[currentAudioRendition] || {};

            const {
                channels,
                codec: audioCodec,
                language: audioLanguage,
                name: audioName
            } = audioRendition || {};

            const audioChannels = parseInt(channels, 10);

            // Cache values to be used for heartbeat event.
            this.heartbeatData.playlistAudioChannels = audioChannels;
            this.heartbeatData.playlistAudioCodec = audioCodec;
            this.heartbeatData.playlistAudioLanguage = audioLanguage;
            this.heartbeatData.playlistAudioName = audioName;

            this.onAudioChanged({
                audioChannels,
                audioCodec,
                audioLanguage,
                audioName
            });
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @note Handles the BUFFERING_STARTED event
     *
     */
    rebufferingStartedEvent() {
        // Used to calculate duration for rebufferingEndedEvent
        this.rebufferingDuration = new Date();

        this.isBuffering = true;

        const playbackData = this.getPlaybackData();

        this.bufferType = playbackData.bufferType;

        this.onRebufferingStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @note Handles the `BUFFERING_FINISHED` event
     *
     */
    rebufferingEndedEvent() {
        this.isBuffering = false;

        const playbackData = this.getPlaybackData();
        const duration = Date.now() - this.rebufferingDuration;

        this.onRebufferingEnded({
            ...playbackData,
            duration,
            bufferType: playbackData.bufferType
                ? playbackData.bufferType
                : this.bufferType
        });

        this.bufferType = undefined;
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `` event
     *
     */
    snapshotCreated() {
        this.onSnapshotCreated({});
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `BEACON_ERROR` event
     *
     */
    adBeaconError(eventData = {}) {
        const { serverRequest } = eventData;

        const { host, path } = serverRequest || {};

        this.onAdBeaconError({
            error: QoePlaybackError.adBeaconError,
            errorLevel: ErrorLevel.info,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED_ERROR` event
     *
     */
    adRequestedError(eventData) {
        const { serverRequest } = eventData || {};

        const { host, path } = serverRequest || {};

        this.onAdRequestedError({
            error: QoePlaybackError.adServerError,
            errorLevel: ErrorLevel.warn,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED` event
     *
     */
    adPodRequested(eventData) {
        const { interstitialMetadata } = eventData;
        const { placement, midRollIndex: midrollIndex } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];

        if (!podPosition) {
            return;
        }

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.onAdPodRequested({
            adPodPlacement
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_FETCHED` event
     *
     */
    adPodFetched(eventData) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            midRollIndex: midrollIndex,
            totalDuration: plannedLength,
            slotCount: plannedSlotCount
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];
        const fetchStatus = request.fetchStatus || '';

        if (!podPosition) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method.toLowerCase()],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status:
                FetchStatus[fetchStatus.toLowerCase()] || FetchStatus.completed,
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const adPodData = {
            plannedSlotCount,
            plannedLength: Check.assigned(plannedLength)
                ? Math.floor(plannedLength)
                : undefined
        };

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.onAdPodFetched({
            adPodData,
            adPodPlacement,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_MULTIVARIANT_FETCHED` event
     *
     */
    adMultivariantFetched(eventData) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const {
            slotCount: plannedSlotCount,
            placement = '',
            midRollIndex: midrollIndex,
            totalDuration: adPodPlannedLength,
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];
        const fetchStatus = request.fetchStatus || '';

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method.toLowerCase()],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status:
                FetchStatus[fetchStatus.toLowerCase()] || FetchStatus.completed,
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const adPodData = {
            plannedSlotCount,
            plannedLength: Check.assigned(adPodPlannedLength)
                ? Math.floor(adPodPlannedLength)
                : undefined
        };

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        const { adSlotData } = this.getAdDataByMediaId({
            adMediaId,
            plannedLength,
            slotNumber
        });

        this.onAdMultivariantFetched({
            adPodData,
            adPodPlacement,
            adSlotData,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} options
     * @param {String} options.adMediaId
     * @param {Number} [options.plannedLength]
     * @param {Number} [options.slotNumber]
     * @returns {Object}
     *
     */
    getAdDataByMediaId(options) {
        const { adMediaId, plannedLength, slotNumber } = options;

        let adDataData = this.getAdDataData(adMediaId, slotNumber);

        if (!adDataData) {
            adDataData = {
                adSubtitleData: {
                    subtitleVisibility: false
                },
                adSlotData: {
                    adMediaId,
                    plannedLength: Check.assigned(plannedLength)
                        ? Math.floor(plannedLength)
                        : undefined,
                    slotNumber: slotNumber || 0
                }
            };
        }

        const metadataKey = this.getMetadataKey(adMediaId, slotNumber);

        this.adData.data[metadataKey] = adDataData;

        return adDataData;
    }

    /**
     *
     * @access private
     * @since 21.0.3
     * @param {String} adMediaId
     * @param {Number} slotNumber
     * @desc Combines `adMediaId` and `slotNumber` to form a unique key.
     * @returns {String}
     *
     */
    getMetadataKey(adMediaId, slotNumber) {
        return `${adMediaId}-${slotNumber}`;
    }

    /**
     *
     * @access private
     * @since 21.0.3
     * @param {String} adMediaId
     * @param {Number} slotNumber
     * @desc Retrieves stored adDataData.
     * @returns {Object|undefined}
     *
     */
    getAdDataData(adMediaId, slotNumber) {
        const metadataKey = this.getMetadataKey(adMediaId, slotNumber);

        return this.adData.data[metadataKey];
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_VARIANT_FETCHED` event
     *
     */
    adVariantFetched(eventData) {
        const subtitleVisibility = this.nativePlayer.areSubtitlesEnabled;

        const {
            serverRequest: request,
            startTimestamp,
            assetData,
            interstitialMetadata
        } = eventData;

        const {
            slotCount: plannedSlotCount,
            placement = '',
            midRollIndex: midrollIndex,
            totalDuration: adPodPlannedLength,
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];
        const fetchStatus = request.fetchStatus || '';

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method.toLowerCase()],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status:
                FetchStatus[fetchStatus.toLowerCase()] || FetchStatus.completed,
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const {
            mediaSegmentType,
            codec,
            range,
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            channels,
            language,
            name
        } = assetData;

        const adPodData = {
            plannedSlotCount,
            plannedLength: Check.assigned(adPodPlannedLength)
                ? Math.floor(adPodPlannedLength)
                : undefined
        };

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        const adDataData = this.getAdDataByMediaId({
            adMediaId,
            plannedLength,
            slotNumber
        });

        const { adSlotData } = adDataData;
        let { adVideoData, adAudioData, adSubtitleData } = adDataData;

        let segmentType;

        switch (mediaSegmentType) {
            case 'video':
                segmentType = MediaSegmentType.video;
                adVideoData = {
                    playlistVideoCodec: codec,
                    playlistVideoRange: range,
                    videoBitrate: bitrate,
                    videoAverageBitrate: averageBitrate,
                    playlistResolution: resolution,
                    playlistFrameRate: frameRate
                };

                adDataData.adVideoData = adVideoData;
                break;

            case 'audio':
                segmentType = MediaSegmentType.audio;
                adAudioData = {
                    playlistAudioChannels: parseInt(channels, 10),
                    playlistAudioCodec: codec,
                    playlistAudioLanguage: language,
                    playlistAudioName: name
                };

                adDataData.adAudioData = adAudioData;
                break;

            case 'subtitles':
                segmentType = MediaSegmentType.subtitle;
                adSubtitleData = {
                    subtitleVisibility,
                    playlistSubtitleLanguage: language,
                    playlistSubtitleName: name
                };
                adDataData.adSubtitleData = adSubtitleData;
                break;

            default:
                this.logger.error(
                    `Unknown mediaSegmentType: ${mediaSegmentType}`
                );
                break;
        }

        this.onAdVariantFetched({
            adPodPlacement,
            adPodData,
            adSlotData,
            serverRequest,
            mediaSegmentType: segmentType,
            adVideoData,
            adAudioData,
            adSubtitleData,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_ASSET_STARTED` event
     *
     */
    adPlaybackStarted(eventData) {
        const { asset } = eventData;
        const {
            mediaId: adMediaId,
            index: slotNumber,
            duration: plannedLength,
            type
        } = asset;
        const { placement = '' } = this.nativePlayer.currentInterstitial;

        const podPosition = PodPosition[placement.toLowerCase()];

        let presentation = PresentationType.ad;

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            presentation = PresentationType.bumper;
        }

        if (this.presentationType !== presentation) {
            this.changePresentationType(placement);
        }

        if (!podPosition || type === this.AssetTypes.SLUG) {
            return;
        }

        const data = this.getAdDataData(adMediaId, slotNumber);

        this.adData.adMetadata.mediaId = adMediaId;

        this.adData.adMetadata.adSlotData = {
            adMediaId,
            plannedLength: Check.assigned(plannedLength)
                ? Math.floor(plannedLength)
                : undefined,
            slotNumber: slotNumber || 0
        };

        this.onAdPlaybackStarted({
            adPodPlacement: this.adData.adMetadata.adPodPlacement,
            adPodData: this.adData.adMetadata.adPodData,
            adSlotData: this.adData.adMetadata.adSlotData,
            adVideoData: data.adVideoData,
            adAudioData: data.adAudioData,
            adSubtitleData: data.adSubtitleData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_ASSET_FINISHED` event
     *
     */
    adPlaybackEnded(eventData) {
        const {
            asset,
            playbackError: errorName,
            playbackErrorDetail: errorMessage,
            cause: endCause
        } = eventData || {};

        const { currentAsset } = this.nativePlayer.currentInterstitial || {};

        const { mediaId: adMediaId, index: slotNumber } =
            asset || currentAsset || {};

        const { adPodPlacement, adPodData, adSlotData } =
            this.adData.adMetadata;

        const { placement = '' } = this.nativePlayer.currentInterstitial;

        let cause = endCause;
        let adErrorData;
        let adVideoData;
        let adAudioData;
        let adSubtitleData;

        const podPosition = PodPosition[placement.toLowerCase()];

        if (!podPosition || !adMediaId) {
            return;
        }

        if (!cause) {
            cause = PlaybackExitedCause.playedToEnd;
        }

        if (errorName || errorMessage) {
            adErrorData = {
                errorName,
                errorMessage
            };
        }

        const adDataData = this.getAdDataData(adMediaId, slotNumber);

        if (adDataData) {
            const data = Object.assign({}, adDataData);

            adVideoData = data.adVideoData;
            adAudioData = data.adAudioData;
            adSubtitleData = data.adSubtitleData;
        }

        this.onAdPlaybackEnded({
            adPodPlacement,
            adPodData,
            adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData,
            cause,
            adErrorData
        });

        const metadataKey = this.getMetadataKey(adMediaId, slotNumber);

        delete this.adData.data[metadataKey];
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_STARTED` event
     *
     */
    adPodStarted() {
        const {
            totalAssets: plannedSlotCount,
            duration: plannedLength,
            midRollIndex: midrollIndex,
            placement = ''
        } = this.nativePlayer.currentInterstitial;

        let podPosition =
            PodPosition[placement.toLowerCase()] || PodPosition.preroll;

        this.presentationType = PresentationType.ad;

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            this.presentationType = PresentationType.bumper;
            podPosition = PodPosition.preroll;
        }

        this.adData.adMetadata = defaultAdMetadata;

        this.adData.adMetadata.adPodData = {
            plannedSlotCount,
            plannedLength: Check.assigned(plannedLength)
                ? Math.floor(plannedLength)
                : undefined
        };

        this.adData.adMetadata.adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.changePresentationType(placement);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_FINISHED` event
     *
     */
    adPodEnded() {
        this.changePresentationType();
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `SEGMENT_PLAYING` event
     *
     */
    segmentPlayingEvent(eventData = {}) {
        const programDateTimeStart = getSafe(
            () => eventData.segment.programDateTimeStart
        );

        this.segmentPosition = Check.assigned(programDateTimeStart)
            ? Math.floor(programDateTimeStart)
            : null;
        this.mediaSegmentType = MediaSegmentType[eventData.type];
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `SOURCE_BUFFER_APPEND_STARTED` event.
     * @note This value gets cleared when `PlaybackTelemetryDispatcher.recordStreamSample()` is called.
     *
     */
    sourceBufferAppendStartedEvent(eventData) {
        if (Check.number(getSafe(() => eventData.segment.byteSize))) {
            this.mediaBytesDownloaded += eventData.segment.byteSize;
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `CONTENT_DOWNLOAD_STARTED` event.
     * @note These values get reset to zero when `PlaybackTelemetryDispatcher.createPlaybackHeartbeatEventData(provider)` is called.
     *
     */
    contentDownloadFinishedEvent(eventData) {
        const { startTime, endTime } = eventData;

        this.mediaDownloadTotalCount++;
        this.mediaDownloadTotalTime += endTime - startTime;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `PLAYBACK_METRICS_CHANGED` event.
     *
     */
    playbackMetricsChanged(eventData) {
        this._latestPlaybackMetricsChanged = eventData;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     *
     */
    internalGetPlaybackMetrics() {
        return this._latestPlaybackMetricsChanged;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     *
     */
    networkMetricsChanged(eventData) {
        this._latestNetworkMetricsChanged = eventData;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     *
     */
    internalGetNetworkMetrics() {
        return this._latestNetworkMetricsChanged;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    setupXhrCallback() {
        const {
            nativePlayer,
            accessToken,
            DataTypes,
            adEngineData = {}
        } = this;
        const { ssess } = adEngineData;

        nativePlayer.xhrSetupCallback = (xhr, url, type) => {
            // @todo DataTypes doesn't seem to exist how this is expecting
            /**
             *  DataTypes: Object.freeze({
                    MULTIVARIANT_PLAYLIST: 'MULTIVARIANT_PLAYLIST',
                    MEDIA_PLAYLIST       : 'MEDIA_PLAYLIST',
                    MEDIA_SEGMENT        : 'MEDIA_SEGMENT',
                    INIT_SECTION         : 'INIT_SECTION',
                    DRM_LICENSE          : 'DRM_LICENSE',
                    AD_POD               : 'AD_POD',
                }),
             */
            if (DataTypes) {
                const isKeyCall = type === DataTypes.KEY;
                const isAdEngineCall =
                    adEngineRegex(url) && type === DataTypes.CHUNK;

                xhr.withCredentials = false; // Ensure no false positive from any cookies

                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                }

                if (isKeyCall) {
                    xhr.setRequestHeader('Authorization', accessToken);
                } else if (isAdEngineCall && Check.assigned(ssess)) {
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('ssess', ssess);
                }
            }
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} exception - the exception object that bam-hls throws
     * @note exception.cause will be one value from the DRM section of this list:
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/src/hlsjs/errors.js
     * @desc Converts a bam-hls error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given bam-hls error
     *
     */
    constructErrorCaseFromDssHlsError(exception) {
        const errorCode =
            BamHlsErrorMapping[exception.cause] || BamHlsErrorMapping.default;
        const reasons = [new ErrorReason(errorCode.code, null)];

        return new ServiceException({
            reasons,
            exceptionData: errorCode.exceptionData
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {Object}
     *
     */
    getCurrentVariant() {
        const { variants = [], currentVariantIndex } = this.nativePlayer;
        const currentVariant = variants[currentVariantIndex] || {};

        const maxVariant = variants[variants.length - 1] || {};

        const {
            encodedFrameRate: playlistFrameRate,
            peakBitrate: maxAllowedVideoBitrate
        } = maxVariant;

        const {
            peakBitrate: bitrate,
            encodedFrameRate: frameRate,
            audioCodecs,
            averageBitrate,
            videoCodec,
            videoRangeSubtype
        } = currentVariant;

        if (this.mediaStartBitrate === null) {
            this.mediaStartBitrate = averageBitrate;
        }

        return {
            resolution: getResolutionString(currentVariant),
            bitrate,
            averageBitrate,
            frameRate,
            audioCodec: Check.array(audioCodecs) ? audioCodecs[0] : undefined,
            videoCodec,
            videoRange: videoRangeSubtype,
            maxAllowedVideoBitrate,
            playlistResolution: getResolutionString(maxVariant),
            playlistFrameRate
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {Object}
     *
     */
    getAudioRendition() {
        const { audioRenditions = [], currentAudioRenditionIndex } =
            this.nativePlayer;
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex] || {};

        const { averageBitrate, channels, codec, name, language } =
            audioRendition;

        return {
            audioRendition: new AudioRendition({ name, language }),
            averageBitrate,
            audioChannels: channels,
            audioCodec: codec
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {SDK.Services.Media.SubtitleRendition}
     *
     */
    getSubtitleRendition() {
        const { subtitleRenditions = [], currentSubtitleRenditionIndex } =
            this.nativePlayer;
        const subtitleRendition =
            subtitleRenditions[currentSubtitleRenditionIndex] || {};

        return new SubtitleRendition(subtitleRendition);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {String<SDK.Services.QualityOfService.BufferType>}
     *
     */
    getBufferType() {
        let bufferType;

        const { mediaElementState } = this.nativePlayer;

        const {
            //DETACHING,
            //DETACHED,
            ATTACHING,
            //ATTACHED,
            //ENDED,
            //PLAYING,
            PAUSED,
            BUFFERING,
            SEEKING
        } = this.MediaElementStates || {};

        if (mediaElementState) {
            switch (mediaElementState) {
                case ATTACHING:
                    bufferType = BufferType.initializing;
                    break;
                case BUFFERING:
                    bufferType = BufferType.buffering;
                    break;
                case SEEKING:
                    bufferType = BufferType.seeking;
                    break;
                case PAUSED:
                    bufferType = BufferType.resuming;
                    break;
            }
        }

        return bufferType;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc returns playback state enum
     * @note nativePlayer.mediaElementState will always be populated and is used to determine the playback state.
     * @returns {String}
     *
     */
    getPlaybackState() {
        let state;

        const { MediaElementStates } = this;

        switch (this.nativePlayer.mediaElementState) {
            case MediaElementStates.BUFFERING:
            case MediaElementStates.DETACHED: // player starts in a detached state
            case MediaElementStates.DETACHING:
            case MediaElementStates.ATTACHING:
            case MediaElementStates.ATTACHED:
                state = PlaybackState.buffering;
                break;

            case MediaElementStates.ENDED:
                state = PlaybackState.finished;
                break;

            case MediaElementStates.PAUSED:
                state = PlaybackState.paused;
                break;

            case MediaElementStates.PLAYING:
                state = PlaybackState.playing;
                break;

            case MediaElementStates.SEEKING:
                state = PlaybackState.seeking;
                break;
        }

        return state;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc return data relevant to create `PlaybackEventData`
     * @returns {Object}
     *
     */
    getPlaybackData() {
        const { currentStreamUrl: streamUrl, nativePlayer } = this;
        const { positionPdt: segmentPosition } = nativePlayer;

        const { liveLatencyAmount, currentPlayhead: playheadPosition } =
            this.getPlaybackMetrics();
        const { audioBitrate } = this.getAudioRendition();

        const {
            bitrate: videoBitrate,
            averageBitrate: videoAverageBitrate,
            maxAllowedVideoBitrate
        } = this.getCurrentVariant();

        const bufferType = this.getBufferType();

        return {
            playheadPosition,
            streamUrl,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            liveLatencyAmount,
            bufferType
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc return data relevant to create `PlaybackStartupEventData`
     * @returns {Object}
     *
     */
    getPlaybackStartupData() {
        const { currentStreamUrl: streamUrl } = this;

        const { bufferSegmentDuration, currentPlayhead } =
            this.getPlaybackMetrics();
        const { audioRendition, audioChannels } = this.getAudioRendition();
        const subtitle = this.getSubtitleRendition();

        const {
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            audioCodec,
            videoCodec,
            videoRange
        } = this.getCurrentVariant();

        let audioChannelsNumber;

        if (Check.string(audioChannels)) {
            const audioChannelsMatches = /\d+/.exec(audioChannels);

            if (audioChannelsMatches) {
                audioChannelsNumber = Number(audioChannelsMatches[0]);
            }
        } else if (Check.number(audioChannels)) {
            audioChannelsNumber = audioChannels;
        }

        // @todo: Look into commented values.
        const variant = new PlaybackVariant({
            bandwidth: bitrate,
            resolution,
            //videoBytes,
            //maxAudioRenditionBytes,
            //maxSubtitleRenditionBytes,
            audioChannels: audioChannelsNumber,
            videoRange,
            videoCodec,
            //audioType,
            audioCodec,
            averageBandwidth: averageBitrate,
            frameRate
        });

        return {
            variant,
            audio: audioRendition,
            subtitle,
            streamUrl,
            playheadPosition: currentPlayhead,
            bufferSegmentDuration
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Returns heartbeatData object on the ctor for heartbeat event.
     * @returns {Object}
     *
     */
    getHeartbeatData() {
        return this.heartbeatData;
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {String} [placement]
     * @desc Updates PresentationType
     *
     */
    changePresentationType(placement) {
        const areSubtitlesEnabled = this.nativePlayer.areSubtitlesEnabled;

        const {
            audioRendition: audio,
            averageBitrate: audioBitrate,
            audioChannels
        } = this.getAudioRendition();

        const { bitrate: videoBitrate, averageBitrate: videoAverageBitrate } =
            this.getCurrentVariant();

        const subtitle = this.getSubtitleRendition();

        this.presentationType = PresentationType.ad;

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            this.presentationType = PresentationType.bumper;
        } else if (!placement) {
            this.presentationType = PresentationType.main;
        }

        this.onPresentationTypeChanged({
            presentationType: this.presentationType,
            areSubtitlesEnabled,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            audioChannels: parseInt(audioChannels, 10),
            audio,
            subtitle
        });
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.MelHivePlayerAdapter';
    }

    // #endregion
}
