/**
 *
 * @module tokenClientEndpoint
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Token.TokenClientEndpoint
 *
 * @property {String} exchange
 *
 */
export default keyMirror({
    exchange: null
});
