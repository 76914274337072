/**
 *
 * @module accountApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/account.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/AccountApi.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/SDK-Authorization-Workflow.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccountManager from './accountManager';
import Account from '../services/account/account';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import BaseApi from '../baseApi';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import TokenManager from '../token/tokenManager';
import SubscriptionManager from '../subscription/subscriptionManager';
import Logger from '../logging/logger';
import CreateAccountResult from '../services/account/createAccountResult';
import AccessContextState from '../token/accessContextState';

const DustUrn = DustUrnReference.account.accountApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface AccountApiOptions {
    accountManager: AccountManager;
    tokenManager: TokenManager;
    subscriptionManager: SubscriptionManager;
    logger: Logger;
}

/**
 *
 * @access public
 * @desc Provides ability to access account data and link subscriptions to the account.
 *
 */
export default class AccountApi extends BaseApi {
    /**
     *
     * @access private
     * @type {AccountManager}
     *
     */
    private accountManager: AccountManager;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {SDK.Subscription.SubscriptionManager}
     *
     */
    private subscriptionManager: SubscriptionManager;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled?: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {AccountManager} options.accountManager
     * @param {TokenManager} options.tokenManager
     * @param {SDK.Subscription.SubscriptionManager} options.subscriptionManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: AccountApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountManager: Types.instanceStrict(AccountManager),
                    tokenManager: Types.instanceStrict(TokenManager),
                    subscriptionManager:
                        Types.instanceStrict(SubscriptionManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { accountManager, tokenManager, subscriptionManager } = options;

        this.accountManager = accountManager;
        this.tokenManager = tokenManager;
        this.subscriptionManager = subscriptionManager;

        /**
         *
         * @access private
         * @type {Boolean}
         * @desc used to enable dust logging
         *
         */
        this.dustEnabled = getSafe(
            () => this.accountManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {String} token
     * @param {String} provider
     * @desc Authorizes a session with an identity that has been authenticated by an external provider.
     * @throws {AccountBlockedException} Account is blocked because it has been either identified as a minor or banned globally by OneID.
     * @throws {InvalidTokenException} External identity token was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     * @todo Remove as part of https://jira.disneystreaming.com/browse/SDKMRJS-4038
     * see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1522 for reference
     *
     */
    public async authorize(token: string, provider: string): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            token: Types.nonEmptyString,
            provider: Types.nonEmptyString
        }
    })
    public async authorize(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<void> {
        const {
            logTransaction,
            args: [token, provider]
        } = apiOptions as ApiOptions;

        const accountGrant = await this.accountManager.createAccountGrant(
            token,
            logTransaction
        );
        const accessContextState = new AccessContextState([provider]);

        await this.tokenManager.exchangeAccountGrant(
            accountGrant,
            accessContextState,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {String} token
     * @param {Object} [attributes={}] - A set of attributes to associate with the new account. Note: certain
     * attributes may be required while others are optional.
     * @param {Object} [metadata] - Object which may hold additional information about the account.
     * @desc Creates an account from an identity that has been authenticated by an external provider.
     * @throws {InvalidTokenException} External identity token was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Account.CreateAccountResult>}
     *
     * @todo Remove as part of https://jira.disneystreaming.com/browse/SDKMRJS-4038
     * see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1522 for reference
     *
     */
    public async createAccount(
        token: string,
        attributes?: object,
        metadata?: object
    ): Promise<CreateAccountResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            token: Types.nonEmptyString,
            attributes: Types.object().optional,
            metadata: Types.object().optional
        }
    })
    public async createAccount(
        apiOptions: unknown,
        ignoredParam?: unknown, // eslint-disable-line @typescript-eslint/no-unused-vars
        ignoredParam2?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<CreateAccountResult> {
        const {
            logTransaction,
            args: [token, attributes = {}, metadata]
        } = apiOptions as ApiOptions;

        return this.accountManager.createAccount({
            identityToken: token,
            attributes,
            metadata,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 3.1.0
     * @desc Gets an Account instance that can be used to get information about the account.
     * @throws {AccountNotFoundException} The account was not found.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Account.Account>}
     *
     * @example sdkSession.accountApi.getAccount().then(account) => {
     *     console.log(account.accountId);
     *     console.log(account.attributes);
     * });
     *
     */
    public async getAccount(): Promise<Account>;

    @apiMethodDecorator()
    public async getAccount(apiOptions?: unknown): Promise<Account> {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.accountManager.getAccount(logTransaction);
    }

    /**
     *
     * @access public
     * @since 3.2.0
     * @param {SDK.Services.Account.Account} account - account to be saved.
     * @desc Saves all current changes made to the account attributes.
     * @throws {AttributeValidationException} One or more attribute was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the operation has succeeded.
     *
     * @example const accountApi = sdkSession.accountApi;
     * @example accountApi.getAccount().then(account) => {
     *     account.attributes.optInUpdates = true; // update attribute
     *     account.attributes.birthDate = null; // delete attribute
     *
     *     return accountApi.updateAccount(account);
     * });
     *
     */
    public async updateAccount(account: Account): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            account: Types.instanceStrict(Account)
        }
    })
    public async updateAccount(apiOptions: unknown): Promise<void> {
        const {
            logTransaction,
            args: [account]
        } = apiOptions as ApiOptions;

        return await this.accountManager.updateAttributes(
            account.attributes,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Links (copies) all subscriptions from the user's device to the Account.
     * @throws {LinkSubscriptionPartialException} Copy partial error.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the operation has succeeded.
     *
     */
    public async linkSubscriptionsFromDevice(): Promise<void>;

    @apiMethodDecorator()
    public async linkSubscriptionsFromDevice(
        apiOptions?: unknown
    ): Promise<void> {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.subscriptionManager.linkSubscriptionsFromDevice(
            logTransaction
        );
    }

    /**
     *
     * @deprecated Deprecated as of `16.0.0` and will be removed in a future version. See release notes for replacement.
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 4.17.0
     * @desc Onboards an account to Star.
     * @throws {AccountNotFoundException} The account was not found.
     * @throws {AccountSecurityFlaggedException} The account has been security flagged.
     * @throws {AttributeValidationException} One or more attributes supplied are invalid.
     * @throws {AuthenticationBlockedException} The user's account should be blocked for being a minor or due to inappropriate behavior.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that returns an Object when the operation has succeeded.
     *
     */
    public async onboardAccountToStar(): Promise<object>;

    @apiMethodDecorator()
    public async onboardAccountToStar(apiOptions?: unknown): Promise<object> {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.accountManager.onboardAccountToStar(logTransaction);
    }

    /**
     *
     * @deprecated Deprecated as of `16.0.0` and will be removed in a future version. See release notes for replacement.
     * @access public
     * @since 4.17.0
     * @param {Object} input
     * @param {String} input.actionGrant
     * @param {Boolean} input.isProfileCreationProtected
     * @desc Updates the isProfileCreationProtected value for an account.
     * @throws {ActionGrantRejectedException} ActionGrant was not accepted by the service either due to it being expired or otherwise invalid.
     * @throws {AccountBlockedException} Account is blocked because it has been either identified as a minor or banned globally by OneID.
     * @throws {AccountSecurityFlaggedException} The account has been security flagged.
     * @throws {AccountVerificationRequiredException} The account is not verified.
     * @throws {AccountNotFoundException} The account was not found.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that completes when the operation has succeeded.
     *
     */
    public async updateProtectProfileCreationWithActionGrant(input: {
        actionGrant: string;
        isProfileCreationProtected: boolean;
    }): Promise<object>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            input: Types.object({
                actionGrant: Types.nonEmptyString,
                isProfileCreationProtected: Types.boolean
            })
        }
    })
    public async updateProtectProfileCreationWithActionGrant(
        apiOptions:
            | ApiOptions
            | {
                  actionGrant: string;
                  isProfileCreationProtected: boolean;
              }
    ): Promise<object> {
        const {
            logTransaction,
            args: [input]
        } = apiOptions as ApiOptions;

        return await this.accountManager.updateProtectProfileCreationWithActionGrant(
            input,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Account.AccountApi';
    }

    // #endregion
}
