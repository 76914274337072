/**
 *
 * @module userActivity}Plugin
 * @desc {SDK.UserActivity.UserActivityPlugin} entry point.
 *
 */

import UserActivity from './entry';
import UserActivityApi from './userActivityApi';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof UserActivity, 'UserActivity'> = {
    pluginName: 'UserActivity',
    entry: UserActivity,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { sessionManager, socketManager } = sdkSession.managers;

        sdkSession.userActivityApi = new UserActivityApi({
            logger,
            sessionManager,
            socketManager
        });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
