/**
 *
 * @module serviceDefinitionsConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../../logging/logger';
import getSafe from './../util/getSafe';
import sdkPlugins from './../../sdkPlugins';

import EventsAtEdgeDustConfiguration from './eventsAtEdgeDustConfiguration';
import OrchestrationManagerConfiguration from './orchestrationManagerConfiguration';
import OrchestrationClientConfiguration from './../orchestration/orchestrationClientConfiguration';
import OrchestrationExtrasMap from './../orchestration/orchestrationExtrasMap';
import SubscriptionClientConfiguration from './../subscription/subscriptionClientConfiguration';
import SubscriptionManagerConfiguration from './subscriptionManagerConfiguration';
import TelemetryClientConfiguration from './../internal/telemetry/telemetryClientConfiguration';
import TelemetryManagerConfiguration from './telemetryManagerConfiguration';
import TelemetryManagerExtrasMap from './telemetryManagerExtrasMap';
import TokenClientConfiguration from './../token/tokenClientConfiguration';
import TokenClientExtrasMap from './../token/tokenClientExtrasMap';
import TokenManagerConfiguration from './tokenManagerConfiguration';
import TokenManagerExtrasMap from './tokenManagerExtrasMap';
import TelemetryBufferConfiguration from './telemetryBufferConfiguration';

import RetryPolicy from './retryPolicy';
import ServiceEndpoint from './serviceEndpoint';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary to communicate with services.
 *
 */
export default class ServiceDefinitionsConfiguration {
    /**
     *
     * @param {Object} options
     * @param {Object} options.services - JSON services config object returned from configuration service.
     * @param {Object} [options.commonHeaders={}]
     * @param {Object} [options.commonValues={}]
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    services: Types.nonEmptyObject,
                    commonHeaders: Types.object().optional,
                    commonValues: Types.object().optional,
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            services,
            commonHeaders = {},
            commonValues = {},
            logger
        } = options;

        /**
         *
         * @access private
         * @type {Object}
         *
         */
        this.commonHeaders = commonHeaders || {};

        /**
         *
         * @access private
         * @since 14.0.0
         * @type {TodoAny}
         *
         */
        this.commonValues = commonValues || {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.AccountManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing accounts.
         *
         */
        this.account = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.AdEngineConfiguration}
         * @desc Gets or sets the configuration information necessary for managing adEngine.
         *
         */
        this.adEngine = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.CustomerServiceManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing customer service.
         *
         */
        this.customerService = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.DrmManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing Drm capabilities.
         *
         */
        this.drm = {};

        /**
         *
         * @access public
         * @since 4.2.0
         * @type {SDK.Services.Configuration.EligibilityManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing Eligibility.
         *
         */
        this.eligibility = {};

        /**
         *
         * @access public
         * @since 4.2.0
         * @type {SDK.Services.Configuration.EntitlementManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing Entitlement.
         *
         */
        this.entitlement = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.PurchaseManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing receipt activation.
         *
         */
        this.purchase = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.SubscriptionManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing subscriptions.
         *
         */
        this.subscription = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.TokenManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing token exchange.
         *
         */
        this.token = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.MediaManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing media.
         *
         */
        this.media = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.TelemetryManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing telemetry.
         *
         */
        this.telemetry = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.ContentManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing content data.
         *
         */
        this.content = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.CommerceManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing commerce.
         *
         */
        this.commerce = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.OrchestrationManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing orchestration.
         *
         */
        this.orchestration = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.PaywallManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing paywall.
         *
         */
        this.paywall = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.ExternalActivationManagerConfiguration}
         *
         */
        this.externalActivation = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.SocketManagerConfiguration}
         *
         */
        this.socket = {};

        /**
         *
         * @access public
         * @type {SDK.Services.Configuration.InvoiceManagerConfiguration}
         *
         */
        this.invoice = {};

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {SDK.Services.Configuration.EventsAtEdgeDustConfiguration}
         *
         */
        this.eventsAtEdgeDust = {};

        /**
         *
         * @access public
         * @since 17.0.0
         * @type {SDK.Services.Configuration.RipcutManagerConfiguration}
         * @desc Gets or sets the configuration information necessary for managing Ripcut.
         *
         */
        this.ripcut = {};

        /**
         *
         * @desc process services Object and create configuration instances
         *
         */
        this.processServices(services, logger);
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} services - JSON services config object returned from configuration service.
     * @param {SDK.Logging.Logger} logger
     * @desc Initialize SdkSessionConfiguration services.
     *
     */
    processServices(services, logger) {
        const {
            subscription,
            token,
            telemetry,
            orchestration,
            eventsAtEdgeDust
        } = services;

        const { client: tokenClient, extras: tokenExtras } = token;
        const { endpoints: tokenClientEndpoints, extras: tokenClientExtras } =
            tokenClient;
        const {
            refreshThreshold,
            subjectTokenTypes,
            disableTokenRefresh,
            autoRefreshRetryPolicy: tokenAutoRefreshRetryPolicy
        } = tokenExtras;

        const { extras: telemetryExtras } = telemetry;

        const autoRefreshRetryPolicy = new RetryPolicy(
            tokenAutoRefreshRetryPolicy.retryBasePeriod,
            tokenAutoRefreshRetryPolicy.retryMultiplier,
            tokenAutoRefreshRetryPolicy.retryMaxAttempts,
            tokenAutoRefreshRetryPolicy.retryMaxPeriod
        );

        const serviceDefinitionsConfiguration = this;

        /**
         *
         * @type {SDK.Services.Subscription.SubscriptionClientConfiguration}
         *
         */
        const subscriptionClientConfiguration =
            new SubscriptionClientConfiguration(
                getSafe(() => subscription.client.baseUrl),
                serviceDefinitionsConfiguration.processEndpoints(
                    getSafe(() => subscription.client.endpoints)
                )
            );

        /**
         *
         * @type {SDK.Services.Internal.Telemetry.TelemetryClientConfiguration}
         *
         */
        const telemetryClientConfiguration = new TelemetryClientConfiguration(
            getSafe(() => telemetry.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => telemetry.client.endpoints)
            )
        );

        /**
         *
         * @type {SDK.Services.Token.TokenClientConfiguration}
         *
         */
        const tokenClientConfiguration = new TokenClientConfiguration(
            tokenClient.baseUrl,
            serviceDefinitionsConfiguration.processEndpoints(
                tokenClientEndpoints
            ),
            new TokenClientExtrasMap(tokenClientExtras)
        );

        /**
         *
         * @type {SDK.Services.Orchestration.OrchestrationClientConfiguration}
         *
         */
        const orchestrationClientConfiguration =
            new OrchestrationClientConfiguration(
                getSafe(() => orchestration.client.baseUrl),
                serviceDefinitionsConfiguration.processEndpoints(
                    orchestration.client.endpoints
                )
            );

        /**
         *
         * @type {SDK.Services.Configuration.EventsAtEdgeDustConfiguration}
         * @since 13.0.0
         *
         */
        this.eventsAtEdgeDust = new EventsAtEdgeDustConfiguration(
            null,
            null,
            eventsAtEdgeDust.disabled
        );

        sdkPlugins.plugins.forEach((plugin) => {
            if (plugin.applyConfig) {
                plugin.applyConfig(serviceDefinitionsConfiguration, services);
            }
        });

        /**
         *
         * @type {SDK.Services.Configuration.SubscriptionManagerConfiguration}
         *
         */
        serviceDefinitionsConfiguration.subscription =
            new SubscriptionManagerConfiguration(
                subscriptionClientConfiguration,
                null,
                subscription.disabled
            );

        const bufferConfigurationDefault = new TelemetryBufferConfiguration(
            telemetryExtras.bufferConfigurationDefault
        );
        const eventBufferConfiguration = new TelemetryBufferConfiguration(
            telemetryExtras.eventBufferConfiguration
        );
        const streamSampleBufferConfiguration =
            new TelemetryBufferConfiguration(
                telemetryExtras.streamSampleBufferConfiguration
            );
        const glimpseBufferConfiguration = new TelemetryBufferConfiguration(
            telemetryExtras.glimpseBufferConfiguration
        );
        const qoeBufferConfiguration = new TelemetryBufferConfiguration(
            telemetryExtras.qoeBufferConfiguration
        );

        /**
         *
         * @type {SDK.Services.Configuration.TelemetryManagerConfiguration}
         *
         */
        serviceDefinitionsConfiguration.telemetry =
            new TelemetryManagerConfiguration(
                telemetryClientConfiguration,
                new TelemetryManagerExtrasMap({
                    bufferConfigurationDefault,
                    eventBufferConfiguration,
                    streamSampleBufferConfiguration,
                    glimpseBufferConfiguration,
                    qoeBufferConfiguration,
                    fastTrack: telemetryExtras.fastTrack,
                    prohibited: telemetryExtras.prohibited,
                    permitAppDustEvents: telemetryExtras.permitAppDustEvents,
                    logger
                }),
                telemetry.disabled
            );

        /**
         *
         * @type {SDK.Services.Configuration.TokenManagerConfiguration}
         *
         */
        serviceDefinitionsConfiguration.token = new TokenManagerConfiguration(
            tokenClientConfiguration,
            new TokenManagerExtrasMap({
                refreshThreshold,
                autoRefreshRetryPolicy,
                subjectTokenTypes,
                disableTokenRefresh
            }),
            token.disabled
        );

        /**
         *
         * @type {SDK.Services.Configuration.OrchestrationManagerConfiguration}
         *
         */
        serviceDefinitionsConfiguration.orchestration =
            new OrchestrationManagerConfiguration(
                orchestrationClientConfiguration,
                new OrchestrationExtrasMap(orchestration.extras),
                orchestration.disabled
            );
    }

    /**
     *
     * @access protected
     * @param {Object} [endpoints={}]
     * @desc creates service endpoint instance for each item
     * @returns {Object}
     *
     */
    processEndpoints(endpoints = {}) {
        const { commonHeaders } = this;

        const serviceEndpoints = {};

        Object.keys(endpoints).forEach((key) => {
            const endpoint = endpoints[key];

            endpoint.headers = endpoint.headers
                ? Object.assign(endpoint.headers, commonHeaders)
                : commonHeaders;
            endpoint.rel = key;

            serviceEndpoints[endpoint.rel] = new ServiceEndpoint(endpoint);
        });

        return serviceEndpoints;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.ServiceDefinitionsConfiguration';
    }

    // #endregion
}
