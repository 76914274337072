/**
 *
 * @module logSink
 *
 */

/* eslint @typescript-eslint/no-unused-vars: "off" */

import LogEvent from './logEvent';
import LogLevel from './logLevel';

/**
 *
 * @abstract LogSink
 * @desc Defines the contract for a logging "endpoint" that can be registered with the Logger.
 *
 * @note a LogSink implementation will construct with a LogLevel, filtering out events that fall below that
 * level. Further each LogSink will log these LogEvents in a unique way, examples being:
 *
 * ConsoleSink: writes the logEvent as a string to the console
 * MemorySink: simply stores the logEvent in an array.
 * Potential NetworkSinks will POST the logEvent to a URL.
 *
 */
export default abstract class LogSink {
    /**
     *
     * @access public
     * @type {LogLevel}
     *
     */
    public logLevel: LogLevel;

    /**
     *
     * @access protected
     * @param {LogLevel} [logLevel=LogLevel.none]
     *
     */
    public constructor(logLevel = LogLevel.none) {
        this.logLevel = logLevel;
    }

    /**
     *
     * @abstract
     * @access public
     * @param {LogEvent} logEvent - The event to be logged.
     * @returns {Promise<Void>}
     *
     */
    public abstract log(logEvent: LogEvent): void;

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.LogSink';
    }

    // #endregion
}
