/**
 *
 * @module silkDrmProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 *
 */

import { Types } from '@dss/type-checking';

import Events from './../events';
import DrmProvider from './drmProvider';
import DrmType from './../services/media/drmType';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import type DrmManager from './drmManager';
import type TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';
import type MediaItem from '../media/mediaItem';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';

const DustUrn = DustUrnReference.drm.silkDrmProvider;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @since 3.2.0
 * @desc SILK is the existing encryption key mechanism.
 * @note A new key service will be introduced as part of the DRM service, but
 * will be fully backwards-compatible with the existing key service.
 * @note In previous versions of the SDK, a EncryptionKeyProvider existed with the same functionality.
 *
 */
export default class SilkDrmProvider extends DrmProvider {
    /**
     *
     * @param {Object} options
     * @param {SDK.Drm.DrmManager} options.drmManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {String} [options.endpointKey]
     *
     */
    public constructor(options: {
        drmManager: DrmManager;
        tokenManager: TokenManager;
        logger: Logger;
        mediaItem: MediaItem;
        endpointKey?: keyof typeof DrmClientEndpoint;
    }) {
        super({
            ...options,
            type: DrmType.SILK
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {String} keyLocation - The encryption key endpoint.
     * @param {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @desc Gets the current encryption key via the media service.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<Uint8Array>}
     *
     */

    public async getSilkKey(
        keyLocation: string,
        playheadPosition?: number
    ): Promise<Uint8Array>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            keyLocation: Types.nonEmptyString,
            playheadPosition: Types.number.optional
        }
    })
    public async getSilkKey(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<Uint8Array> {
        const {
            logTransaction,
            args: [keyLocation, playheadPosition]
        } = apiOptions as ApiOptions;

        const { mediaItem } = this;

        try {
            return await this.drmManager.getSilkKey({
                keyLocation,
                mediaItem,
                playheadPosition,
                logTransaction
            });
        } catch (exception) {
            this.logger.error(this.toString(), 'SILK key request failure.');
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.SilkDrmProvider';
    }

    // #endregion
}
