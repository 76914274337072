/**
 *
 * @module typedefs
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#types
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

/**
 *
 * @typedef {Object} SDK.Services.Flex.Screen
 * @since 16.0.0
 * @desc The template for the page.
 * @property {Object} content - The page template.
 * @property {String} screenType - The screen identifier, i.e. "billing-history".
 * @property {String} templateId - The template identifier, i.e. "default, default:ios, comcast, billing-history".
 * @property {String} initialFocus - Initial element in focus, i.e. "string".
 * @property {Object} metricsData - Additional metadata provided with the template.
 *
 */
export const Screen = {
    content: Types.object(),
    screenType: Types.nonEmptyString,
    templateId: Types.nonEmptyString,
    initialFocus: Types.nonEmptyString,
    metricsData: Types.object()
};

/**
 *
 * @typedef {Object} SDK.Services.Flex.ExecutionResponse
 * @since 17.0.0
 * @desc The response data from an execute call.
 * @property {Boolean} success
 * @property {Object} [successData]
 * @property {Object} [errorData]
 * @property {Object} metricsData - Additional metadata provided with the template.
 *
 */
export const ExecutionResponse = {
    success: Types.boolean,
    successData: Types.object().optional,
    errorData: Types.object().optional,
    metricsData: Types.object()
};
