/**
 *
 * @module adEngineManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class AdEngineManagerExtrasMap {
    /**
     *
     * @param {Object} adTargeting
     *
     */
    constructor(adTargeting) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                adTargeting: Types.object()
            };

            typecheck.warn(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Object}
         *
         */
        this.adTargeting = adTargeting;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.AdEngineManagerExtrasMap';
    }

    // #endregion
}
