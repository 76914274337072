/**
 *
 * @module entry
 * @desc {SDK.Media} entry point.
 *
 */

import AdEngine from './adEngine/entry';
import MediaApi from './mediaApi';
import MediaManager from './mediaManager';

import AuthCookieProvider from './authCookieProvider';
import BamHlsErrorMapping from './bamHlsErrorMapping';
import MediaCapabilitiesProvider from './mediaCapabilitiesProvider';
import MediaDescriptor from './mediaDescriptor';
import MediaItem from './mediaItem';
import MediaPreferences from './mediaPreferences';
import MediaSource from './mediaSource';
import PlaybackEventListener from './playbackEventListener';
import PlaybackMetrics from './playbackMetrics';
import PlaybackMetricsProvider from './playbackMetricsProvider';
import PlaybackSession from './playbackSession';
import PlaybackTelemetryConfiguration from './playbackTelemetryConfiguration';
import PlaybackTelemetryDispatcher from './playbackTelemetryDispatcher';
import PlayerAdapter from './playerAdapter';
import Playlist from './playlist';

import AampUvePlayerAdapter from './playerAdapter/aampUvePlayerAdapter';
import BamWebPlayerAdapter from './playerAdapter/bamWebPlayerAdapter';
import HlsJsPlayerAdapter from './playerAdapter/hlsJsPlayerAdapter';
import CafPlayerAdapter from './playerAdapter/cafPlayerAdapter';
import SamsungTizenPlayerAdapter from './playerAdapter/samsungTizenPlayerAdapter';
import BamHlsPlayerAdapter from './playerAdapter/bamHlsPlayerAdapter';
import DssHlsPlayerAdapter from './playerAdapter/dssHlsPlayerAdapter';
import DssWebPlayerAdapter from './playerAdapter/dssWebPlayerAdapter';
import MelHivePlayerAdapter from './playerAdapter/melHivePlayerAdapter';

import {
    AssetInsertionStrategy,
    HdcpSecurityLevel,
    MediaAnalyticsKey,
    PlaybackInitiationContext,
    SlugDuration,
    ThumbnailResolution,
    WidevineSecurityLevel
} from './enums';

/**
 *
 * @typedef {Object} SDK.Media
 *
 * @property {SDK.Media.AdEngine} AdEngine
 * @property {MediaApi} MediaApi
 * @property {MediaManager} MediaManager
 *
 * @property {AssetInsertionStrategy} AssetInsertionStrategy
 * @property {AuthCookieProvider} AuthCookieProvider
 * @property {SDK.Media.BamHlsErrorMapping} BamHlsErrorMapping
 * @property {SDK.Media.HdcpSecurityLevel} HdcpSecurityLevel
 * @property {SDK.Media.MediaAnalyticsKey} MediaAnalyticsKey
 * @property {MediaCapabilitiesProvider} MediaCapabilitiesProvider
 * @property {MediaDescriptor} MediaDescriptor
 * @property {MediaItem} MediaItem
 * @property {MediaPreferences} MediaPreferences
 * @property {MediaSource} MediaSource
 * @property {PlaybackEventListener} PlaybackEventListener
 * @property {PlaybackInitiationContext} PlaybackInitiationContext
 * @property {PlaybackMetrics} PlaybackMetrics
 * @property {PlaybackMetricsProvider} PlaybackMetricsProvider
 * @property {PlaybackSession} PlaybackSession
 * @property {PlaybackTelemetryConfiguration} PlaybackTelemetryConfiguration
 * @property {PlaybackTelemetryDispatcher} PlaybackTelemetryDispatcher
 * @property {PlayerAdapter} PlayerAdapter
 * @property {Playlist} Playlist
 * @property {SDK.Media.SlugDuration} SlugDuration
 * @property {SDK.Media.ThumbnailResolution} ThumbnailResolution
 * @property {SDK.Media.WidevineSecurityLevel} WidevineSecurityLevel
 *
 */
export default {
    AdEngine,
    MediaApi,
    MediaManager,
    /**
     *
     * @typedef {Object} SDK.Media.PlayerAdapter
     *
     * @property {AampUvePlayerAdapter} AampUvePlayerAdapter
     * @property {BamWebPlayerAdapter} BamWebPlayerAdapter
     * @property {HlsJsPlayerAdapter} HlsJsPlayerAdapter
     * @property {CafPlayerAdapter} CafPlayerAdapter
     * @property {SamsungTizenPlayerAdapter} SamsungTizenPlayerAdapter
     * @property {BamHlsPlayerAdapter} BamHlsPlayerAdapter
     * @property {DssHlsPlayerAdapter} DssHlsPlayerAdapter
     * @property {DssWebPlayerAdapter} DssWebPlayerAdapter
     * @property {MelHivePlayerAdapter} MelHivePlayerAdapter
     *
     */
    PlayerAdapter: Object.assign(PlayerAdapter, {
        AampUvePlayerAdapter,
        BamWebPlayerAdapter,
        HlsJsPlayerAdapter,
        CafPlayerAdapter,
        SamsungTizenPlayerAdapter,
        BamHlsPlayerAdapter,
        DssHlsPlayerAdapter,
        DssWebPlayerAdapter,
        MelHivePlayerAdapter
    }),
    AssetInsertionStrategy,
    AuthCookieProvider,
    BamHlsErrorMapping,
    HdcpSecurityLevel,
    MediaAnalyticsKey,
    MediaCapabilitiesProvider,
    MediaDescriptor,
    MediaItem,
    MediaPreferences,
    MediaSource,
    PlaybackEventListener,
    PlaybackInitiationContext,
    PlaybackMetrics,
    PlaybackMetricsProvider,
    PlaybackSession,
    PlaybackTelemetryConfiguration,
    PlaybackTelemetryDispatcher,
    Playlist,
    SlugDuration,
    ThumbnailResolution,
    WidevineSecurityLevel
};
