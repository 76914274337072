/**
 *
 * @module insertionPointContentSubType
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/playback.oas3.yaml
 *
 */

/**
 *
 * @access public
 * @since 19.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointContentSubType
 * @desc Sub-type of content to be inserted (bumper or slug).
 *
 * @property {String} BUMPER - Since `19.0.0`.
 * @property {String} SLUG - Since `19.0.0`.
 *
 * @example
 * SDK.Services.Media.InsertionPointContentSubType.SLUG // 'SLUG'
 *
 */
enum InsertionPointContentSubType {
    BUMPER = 'BUMPER',
    SLUG = 'SLUG'
}

export default InsertionPointContentSubType;
