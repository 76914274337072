/**
 *
 * @module entry
 * @desc {SDK.Commerce.PaymentCard} entry point.
 *
 */

import PaymentCardApi from './paymentCardApi';

/**
 *
 * @typedef {Object} SDK.Commerce.PaymentCard
 *
 * @property {PaymentCardApi} PaymentCardApi
 *
 */
export default {
    PaymentCardApi
};
