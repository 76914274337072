/**
 *
 * @module contentClientConfiguration
 *
 */

import ServiceClientConfiguration from './../configuration/serviceClientConfiguration';

import ContentClientExtrasMap from './contentClientExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the `ContentClient`.
 *
 */
export default class ContentClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    ContentClientExtrasMap
> {
    /**
     *
     * @access public
     * @type {ContentClientExtrasMap}
     * @desc Gets or sets the extra data needed by the client.
     *
     */
    public override extras: ContentClientExtrasMap;

    /**
     *
     * @param {String} baseUrl
     * @param {Object} endpoints
     * @param {ContentClientExtrasMap} extras
     *
     */
    public constructor(
        baseUrl: string,
        endpoints: IEndpoints<Record<string, never>>,
        extras: ContentClientExtrasMap
    ) {
        super(baseUrl, endpoints);

        this.extras = extras;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Content.ContentClientConfiguration';
    }
}
