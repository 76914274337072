/**
 *
 * @module mediaPlaybackSelectionPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import PlaybackSelectionProperties from './playbackSelectionProperties';

/**
 *
 * @since 4.18.0
 *
 */
export default class MediaPlaybackSelectionPayload {
    /**
     *
     * @param {SDK.Services.Media.PlaybackSelectionProperties} playback
     *
     */
    constructor(playback) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playback: Types.instanceStrict(PlaybackSelectionProperties)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {SDK.Services.Media.PlaybackSelectionProperties}
         *
         */
        this.playback = playback;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaPlaybackSelectionPayload';
    }

    // #endregion
}
