/**
 *
 * @module playlistType
 *
 */

/**
 *
 * @enum
 * @access public
 * @since 2.0.0
 * @typedef {Object} SDK.Services.Media.PlaylistType
 * @desc Enumerates the available playlist types for media.
 *
 * @property {String} COMPLETE - The "complete" playlist type value.
 * @property {String} SLIDE - The "sliding" playlist type value.
 *
 * @example
 * PlaylistType.COMPLETE // 'complete'
 * PlaylistType.SLIDE // 'sliding'
 *
 */
enum PlaylistType {
    COMPLETE = 'complete',
    SLIDE = 'sliding'
}

export default PlaylistType;
