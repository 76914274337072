/**
 *
 * @module telemetryEvent
 *
 */

/**
 *
 * @access protected
 * @interface TelemetryEvent
 *
 */
export default class TelemetryEvent {
    /**
     *
     * @access private
     * @returns {SDK.Services.Internal.Telemetry.TelemetryPayload} JSONSerializable payload
     *
     */
    getPayload() {
        return new Promise((resolve) => {
            return resolve(
                new Error('TelemetryEvent#getPayload - not-implemented')
            );
        });
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Internal.Telemetry.TelemetryEvent';
    }
}
