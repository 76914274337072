/**
 *
 * @module customerServicePlugin
 * @desc {SDK.CustomerService.CustomerServicePlugin} entry point.
 *
 */

import CustomerService from './entry';
import CustomerServiceService from '../services/customerService/entry';
import CustomerServiceManager from './customerServiceManager';

import CustomerServiceClient from '../services/customerService/customerServiceClient';

import getSafe from '../services/util/getSafe';

import CustomerServiceClientConfiguration from './../services/customerService/customerServiceClientConfiguration';
import CustomerServiceManagerConfiguration from '../services/configuration/customerServiceManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<
    typeof CustomerService,
    'CustomerService',
    typeof CustomerServiceService
> = {
    pluginName: 'CustomerService',
    entry: CustomerService,
    service: CustomerServiceService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services } = options;

        managers.customerServiceManager = new CustomerServiceManager(
            services.customerService,
            new CustomerServiceClient(
                services.customerService.client,
                logger,
                httpClient
            ),
            logger
        );
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new CustomerServiceClientConfiguration(
            getSafe(() => services.customerService.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.customerService.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.customerService =
            new CustomerServiceManagerConfiguration(
                clientConfiguration,
                undefined,
                services.customerService.disabled
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
