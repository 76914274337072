/**
 *
 * @module ripcutClientConfiguration
 *
 */

import ServiceClientConfiguration from './../configuration/serviceClientConfiguration';
import RipcutClientEndpoint from './ripcutClientEndpoint';

/**
 *
 * @access protected
 * @since 17.0.0
 * @desc Provides configuration information used by the RipcutClient.
 *
 */
export default class RipcutClientConfiguration extends ServiceClientConfiguration<
    typeof RipcutClientEndpoint
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Ripcut.RipcutClientConfiguration';
    }
}
