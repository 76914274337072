/**
 *
 * @module embeddedConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ConfigurationClientConfiguration from '../services/configuration/configurationClientConfiguration';
import ConfigurationClientExtrasMap from '../services/configuration/configurationClientExtrasMap';
import ConfigurationHostName from '../services/configuration/configurationHostName';
import ServiceEndpoint from '../services/configuration/serviceEndpoint';

/**
 *
 *
 */
export default class EmbeddedConfiguration {
    /**
     *
     * @access public
     * @type {String<SDK.Services.Configuration.ConfigurationHostName>}
     * @desc The default configuration host name to use ("dev" or "prod").
     *
     */
    public defaultConfigHostName: keyof typeof ConfigurationHostName;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ConfigurationClientConfiguration}
     *
     */
    public client: ConfigurationClientConfiguration;

    /**
     *
     * @param {Object} options
     * @param {Boolean} [options.isFallback=false] - flag to determine if the EmbeddedConfiguration
     * instance is being used as a fallback (for example, if config fetch fails)
     *
     */
    public constructor(options: { isFallback: boolean }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    isFallback: Types.boolean.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { isFallback = false } = options;

        const defaultConfigHostName = ConfigurationHostName.prod;

        const configHostParams = {
            dev: {
                baseUrl: 'https://dev-bam-sdk-configs.bamgrid.com/bam-sdk'
            },
            prod: {
                baseUrl: 'https://bam-sdk-configs.bamgrid.com/bam-sdk'
            },
            staging: {
                baseUrl: 'https://staging-bam-sdk-configs.bamgrid.com/bam-sdk'
            }
        };

        let href = `
            {baseUrl}/
            v{configVersion}/
            {clientId}/{deviceFamily}/v{sdkVersion}/
            {deviceProfile}/{applicationRuntime}/{environment}.json
        `.replace(/(?:\n\s+)/g, '');

        if (isFallback) {
            href = href
                .replace(/\{deviceProfile\}/gi, 'default')
                .replace(/\{applicationRuntime\}/gi, 'browser');
        }

        const headers = {
            Accept: 'application/json'
        };

        const endpoints = {
            bootstrap: new ServiceEndpoint({
                rel: 'bootstrap',
                href,
                ttl: 0,
                timeout: 30,
                templated: true,
                headers
            })
        };

        this.defaultConfigHostName = defaultConfigHostName;
        this.client = new ConfigurationClientConfiguration(
            new ConfigurationClientExtrasMap(configHostParams),
            defaultConfigHostName,
            endpoints
        );
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Configuration.EmbeddedConfiguration';
    }
}
