/**
 *
 * @module typedefs
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#types
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import ImageFormat from './imageFormat';
import PNGCompression from './pngCompression';
import PVRTexture from './pvrTexture';
import PVRTextureQuality from './pvrTextureQuality';
import RipcutImageRequestMode from './ripcutImageRequestMode';
import RipcutLabelPosition from './ripcutLabelPosition';
import ScalingAlgorithm from './scalingAlgorithm';

/**
 *
 * @typedef {Object} SDK.Ripcut.RipcutQueryParams
 * @since 17.0.0
 * @desc Parameters specifying image details such as scaling/formatting/label placement, attached as query params in the request.
 * @property {Number} [width] - Width of desired variant image in pixels. If not present on request the width will be calculated in proportion to the master image.
 * @property {Number} [height] - Height of desired variant image in pixels. If not present on request the height will be calculated in proportion to the master image.
 * @property {String<ScalingAlgorithm>} [scalingAlgorithm] - Which algorithm to use for scaling.
 * @property {String<ImageFormat>} [format] - File format of the desired variant image. The PVR and jpeg2000 formats supported only when library parameter is present on request.
 * @property {String<PVRTexture>} [texture] - Controls image texture for variant images generated in PVR format. This parameter is applicable only when format parameter is set to PVR.
 * @property {String<PVRTextureQuality>} [textureQuality] - Controls quality of image texture for variant images generated in PVR format. This parameter is applicable only when format parameter is set to PVR.
 * @property {Number} [quality] - JPEG quality to use for creating a JPEG image response. If a non-JPEG file format is requested, this will be ignored.
 * @property {Number} [roundCornerRadius] - Radius of round corners for desired variant image.
 * @property {Number<PNGCompression>} [compression] - Compression strength for PNG images. The parameter should be one of the following. 0 - No Compression, 1 - Default Compression. If there's no compression parameter on a request to get a PNG variant then Default Compression is applied.
 * @property {String} [blurFilter] - Blur filter type to apply, currently we only support gaussianfilter
 * @property {Number} [blurRadius] - The radius to blur the image by.
 * @property {String} [fillColor] - The color to fill the generated image background. The color value should be in hexadecimal format representing RGB value. If the fillColor parameter is not present on request the default color will be used. The default color value is 0E0B14.
 * @property {String} [label] - Name of the label image that will be placed on top of the scaled master image. If no label is provided or if the label is unknown, the scaled master image will be returned with X-BAMTECH-ERROR header indicating this error condition.
 * @property {Number} [aspectRatio] - Aspect ratio of the composited image. If not present on request the composited image will have aspect ratio of the master image. Should be decimal format.
 * @property {String} [topImage] - MasterId of the image that will be composited on top of the master image referenced by imageId provided in url path.
 * @property {String} [language] - Indicates language of the label's image text. If no language parameter provided the endpoint defaults label to en (English). If language value is unknown scaled master image will be returned with X-BAMTECH-ERROR header indicating this error condition.
 * @property {String<RipcutLabelPosition>} [placement] - Indicates position of the label's image on the scaled master image. TL - Top Left, TC - Top Center, TR - Top Right, L - Left, C - Center, R - Right, LL - Low Left, LC - Low Center, LR - Low Right. If placement value is unknown scaled master image will be returned with X-BAMTECH-ERROR header indicating this error condition.
 * @property {String} [origLabel] - Name of the originals label image that will be placed on top of the scaled master image. If no label provided or if label is unknown scaled master image will be returned with X-BAMTECH-ERROR header indicating this error condition.
 * @property {String<RipcutLabelPosition>} [origPlacement] - Indicates position of the originals label's image on the scaled master image. TL - Top Left, TC - Top Center, TR - Top Right, L - Left, C - Center, R - Right, LL - Low Left, LC - Low Center, LR - Low Right. If placement value is unknown scaled master image will be returned with X-BAMTECH-ERROR header indicating this error condition.
 * @property {String} [origLanguage] - Indicates language of the originals label's image text. If no language parameter provided the endpoint defaults label to en (English). If language value is unknown scaled master image will be returned with X-BAMTECH-ERROR header indicating this error condition.
 *
 */
export const RipcutQueryParams = {
    width: Types.number.optional,
    height: Types.number.optional,
    scalingAlgorithm: Types.in(ScalingAlgorithm).optional,
    format: Types.in(ImageFormat).optional,
    texture: Types.in(PVRTexture).optional,
    textureQuality: Types.in(PVRTextureQuality).optional,
    quality: Types.number.optional,
    roundCornerRadius: Types.number.optional,
    compression: Types.in(PNGCompression).optional,
    blurFilter: Types.nonEmptyString.optional,
    blurRadius: Types.number.optional,
    fillColor: Types.nonEmptyString.optional,
    label: Types.nonEmptyString.optional,
    aspectRatio: Types.number.optional,
    topImage: Types.nonEmptyString.optional,
    language: Types.nonEmptyString.optional,
    placement: Types.in(RipcutLabelPosition).optional,
    origLabel: Types.nonEmptyString.optional,
    origPlacement: Types.in(RipcutLabelPosition).optional,
    origLanguage: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Ripcut.RipcutOptions
 * @since 19.0.0
 * @property {String} imageId - The Id of the requested image.
 * @property {String<RipcutImageRequestMode>} imageRequestMode - Specifies image retrieval option such as compositing or scaling logic to be applied by Ripcut to the returned image.
 * @property {Object<RipcutQueryParams>} [queryParams] - Parameters specifying image details such as scaling/formatting/label placement, attached as query params in the request.
 *
 */
export const RipcutOptions = {
    imageId: Types.nonEmptyString,
    imageRequestMode: Types.in(RipcutImageRequestMode),
    queryParams: Types.object(RipcutQueryParams).optional
};
