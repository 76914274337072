/**
 *
 * @module invoiceApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import BaseApi from '../baseApi';

import InvoiceManager from './invoiceManager';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import Logger from '../logging/logger';

const DustUrn = DustUrnReference.invoice.invoiceApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.12.0
 * @desc Provides ability to access invoice data
 *
 */
export default class InvoiceApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Invoice.InvoiceManager}
     *
     */
    private invoiceManager: InvoiceManager;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Invoice.InvoiceManager} options.invoiceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        invoiceManager: InvoiceManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    invoiceManager: Types.instanceStrict(InvoiceManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { invoiceManager } = options;

        this.invoiceManager = invoiceManager;

        this.dustEnabled = getSafe(
            () => this.invoiceManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {String} link - path used to get a specific invoice.
     * @desc Returns a specific invoice belonging to the given account
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Invoice.Invoice>>}
     *
     */
    public async getInvoice(link: string): Promise<TodoAny>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            link: Types.nonEmptyString
        }
    })
    public async getInvoice(apiOptions: unknown): Promise<TodoAny> {
        const {
            logTransaction,
            args: [link]
        } = apiOptions as ApiOptions;

        return await this.invoiceManager.getInvoice(link, logTransaction);
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {String} [pageToken] - token containing the information necessary to retrieve the next page of results.
     * @param {Number} [pageSize] - The maximum number of results to return in a single response.
     * @desc Returns a list of invoices for the given account, in date descending order
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Invoice.PaginatedInvoicesResponse>>}
     *
     */
    public async getPaginatedInvoices(
        pageToken?: string,
        pageSize?: number
    ): Promise<TodoAny>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            pageToken: Types.nonEmptyString.optional,
            pageSize: Types.number.optional
        }
    })
    public async getPaginatedInvoices(
        apiOptions?: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<TodoAny> {
        const {
            logTransaction,
            args: [pageToken, pageSize]
        } = apiOptions as ApiOptions;

        return await this.invoiceManager.getPaginatedInvoices(
            pageToken,
            pageSize,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Invoice.InvoiceApi';
    }
}
