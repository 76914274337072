/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

/**
 *
 * @access private
 * @typedef {Object} SDK.Services.Configuration.CdnFallbackConfiguration
 * @since 20.0.0
 * @property {Boolean} isEnabled
 * @property {Number} defaultTimeout
 * @property {Number} fallbackLimit
 *
 */
export interface CdnFallbackConfiguration {
    isEnabled: boolean;
    defaultTimeout: number;
    fallbackLimit: number;
}

/**
 *
 * @access private
 *
 */
export const CdnFallbackConfigurationTypedef = {
    isEnabled: Types.boolean,
    defaultTimeout: Types.number,
    fallbackLimit: Types.number
};

/**
 *
 * @access private
 * @typedef {Object} SDK.Services.Configuration.CdnFallbackConfiguration
 * @since 20.0.0
 * @property {Number} minimumBatchSize
 */
export interface PlaybackSnapshotEventConfiguration {
    minimumBatchSize: number;
}

/**
 *
 * @access private
 *
 */
export const PlaybackSnapshotEventConfigurationTypedef = {
    minimumBatchSize: Types.number
};

/**
 *
 * @access private
 * @typedef {Object} SDK.Services.Configuration.Application
 * @since 21.0.0
 * @property {string} version
 * @property {string} id
 * @property {string} name
 */
export interface Application {
    version: string;
    id: string;
    name: string;
}

/**
 *
 * @access private
 *
 */
export const ApplicationTypedef = {
    version: Types.nonEmptyString,
    id: Types.nonEmptyString,
    name: Types.nonEmptyString
};
