/**
 *
 * @module commercePlugin
 * @desc {SDK.Commerce.CommercePlugin} entry point.
 *
 */

import Commerce from './entry';
import CommerceService from '../services/commerce/entry';

import CommerceClient from '../services/commerce/commerceClient';

import CommerceManager from './commerceManager';
import CommerceApi from './commerceApi';

import ComcastApi from './comcast/comcastApi';
import IDealApi from './iDeal/iDealApi';
import KlarnaApi from './klarna/klarnaApi';
import MercadoApi from './mercado/mercadoApi';
import PaymentCardApi from './paymentCard/paymentCardApi';
import PayPalApi from './payPal/payPalApi';

import getSafe from '../services/util/getSafe';

import CommerceClientConfiguration from '../services/commerce/commerceClientConfiguration';
import CommerceClientExtrasMap from '../services/commerce/commerceClientExtrasMap';
import CommerceManagerConfiguration from '../services/configuration/commerceManagerConfiguration';
import CommerceManagerExtrasMap from '../services/configuration/commerceManagerExtrasMap';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Commerce, 'Commerce', typeof CommerceService> = {
    pluginName: 'Commerce',
    entry: Commerce,
    service: CommerceService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;

        const { commerceManager } = sdkSession.managers;

        sdkSession.commerceApi = new CommerceApi({
            commerceManager,
            logger
        });

        sdkSession.commerce = {
            comcastApi: new ComcastApi({
                commerceManager,
                logger
            }),
            iDealApi: new IDealApi({
                commerceManager,
                logger
            }),
            klarnaApi: new KlarnaApi({
                commerceManager,
                logger
            }),
            mercadoApi: new MercadoApi({
                commerceManager,
                logger
            }),
            paymentCardApi: new PaymentCardApi({
                commerceManager,
                logger
            }),
            payPalApi: new PayPalApi({
                commerceManager,
                logger
            })
        };
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const {
            managers,
            logger,
            httpClient,
            services,
            accessTokenProvider,
            browserInfoProvider,
            environmentConfiguration
        } = options;

        managers.commerceManager = new CommerceManager({
            config: services.commerce,
            client: new CommerceClient(
                services.commerce.client,
                logger,
                httpClient
            ),
            accessTokenProvider,
            browserInfoProvider,
            environmentConfiguration,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new CommerceClientConfiguration(
            services.commerce.client.baseUrl,
            serviceDefinitionsConfiguration.processEndpoints(
                services.commerce.client.endpoints
            ),
            new CommerceClientExtrasMap({
                clientId: getSafe(
                    () => services.commerce.client.extras.clientId
                ),
                namespaceId: getSafe(
                    () => services.commerce.client.extras.namespaceId
                ),
                createPaymentMethodRegionalEndpoints: getSafe(
                    () =>
                        services.commerce.client.extras
                            .createPaymentMethodRegionalEndpoints
                )
            })
        );

        serviceDefinitionsConfiguration.commerce =
            new CommerceManagerConfiguration(
                clientConfiguration,
                CommerceManagerExtrasMap.parse(services.commerce.extras),
                services.commerce.disabled
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
