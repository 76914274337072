/**
 *
 * @module payPalApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    CreateBraintreePaymentMethodRequest,
    CreateBraintreePaymentMethodRequestTypedef,
    GetCheckoutDetailsRequest,
    GetCheckoutDetailsRequestTypedef,
    SetCheckoutDetailsRequest,
    SetCheckoutDetailsRequestTypedef,
    UpdateBraintreePaymentMethodRequest,
    UpdateBraintreePaymentMethodRequestTypedef
} from './typedefs';

import {
    ClientTokenResponse,
    CreatePaymentMethodResponse,
    SetCheckoutDetailsResponse
} from '../../services/commerce/typedefs';

import CommerceManager from '../commerceManager';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';
import getSafe from '../../services/util/getSafe';
import Logger from '../../logging/logger';

const DustUrn = DustUrnReference.commerce.payPalApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.8.0
 * @desc Provides ability to access PayPal data.
 *
 */
export default class PayPalApi extends BaseApi {
    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Commerce.CommerceManager}
     *
     */
    private commerceManager: CommerceManager;

    /**
     *
     * @access private
     * @since 4.8.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Commerce.CommerceManager} options.commerceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        commerceManager: CommerceManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceManager: Types.instanceStrict(CommerceManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceManager } = options;

        this.commerceManager = commerceManager;

        this.dustEnabled = getSafe(
            () => this.commerceManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @deprecated
     * @access public
     * @since 4.8.0
     * @param {Object<SDK.Commerce.PayPal.GetCheckoutDetailsRequest>} request - request Information used to retrieve a paymentMethodId.
     * @desc Completes a PayPal transaction and returns a paymentMethodId to be used by the order service.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>} Response contains the paymentMethodId used by the order service.
     *
     */
    public async getCheckoutDetails(
        request: GetCheckoutDetailsRequest
    ): Promise<CreatePaymentMethodResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(GetCheckoutDetailsRequestTypedef)
        }
    })
    public async getCheckoutDetails(
        apiOptions: unknown
    ): Promise<CreatePaymentMethodResponse> {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.getCheckoutDetails(
            request,
            logTransaction
        );
    }

    /**
     *
     * @deprecated
     * @access public
     * @since 4.8.0
     * @param {Object<SDK.Commerce.PayPal.SetCheckoutDetailsRequest>} request - request Information required to begin a PayPal transaction.
     * @desc Begins a PayPal transaction.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.SetCheckoutDetailsResponse>} Response contains the PayPal token used to complete the transaction.
     *
     */
    public async setCheckoutDetails(
        request: SetCheckoutDetailsRequest
    ): Promise<SetCheckoutDetailsResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(SetCheckoutDetailsRequestTypedef)
        }
    })
    public async setCheckoutDetails(
        apiOptions: unknown
    ): Promise<SetCheckoutDetailsResponse> {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.setCheckoutDetails(
            request,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @desc Creates a new PayPal client token.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.ClientTokenResponse>} Returns a new Braintree client token.
     *
     */
    public async createBraintreeToken(): Promise<ClientTokenResponse>;

    @apiMethodDecorator()
    public async createBraintreeToken(
        apiOptions?: unknown
    ): Promise<ClientTokenResponse> {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.commerceManager.createBraintreeToken(logTransaction);
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} billingAgreementId - PayPal billing agreement id.
     * @desc Retrieves the Braintree payment method id for the PayPal payment method.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>} - Returns a PayPal payment method id.
     *
     */
    public async getBraintreePaymentMethodId(
        billingAgreementId: string
    ): Promise<CreatePaymentMethodResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            billingAgreementId: Types.nonEmptyString
        }
    })
    public async getBraintreePaymentMethodId(
        apiOptions: unknown
    ): Promise<CreatePaymentMethodResponse> {
        const {
            logTransaction,
            args: [billingAgreementId]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.getBraintreePaymentMethodId(
            billingAgreementId,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.CreateBraintreePaymentMethodRequest>} request - Data use to create a PayPal v2 payment method.
     * @desc Creates a PayPal v2 payment method to be used via Braintree.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>} Returns a PayPal payment method.
     *
     */
    public async createBraintreePaymentMethod(
        request: CreateBraintreePaymentMethodRequest
    ): Promise<CreatePaymentMethodResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(CreateBraintreePaymentMethodRequestTypedef)
        }
    })
    public async createBraintreePaymentMethod(
        apiOptions: unknown
    ): Promise<CreatePaymentMethodResponse> {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.createBraintreePaymentMethod(
            request,
            logTransaction
        );
    }

    /**
     *s
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.UpdateBraintreePaymentMethodRequest>} request - request Information required to begin a PayPal transaction.
     * @desc Begins a PayPal transaction.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} Response contains the PayPal token used to complete the transaction.
     *
     */
    public async updateBraintreePaymentMethod(
        request: UpdateBraintreePaymentMethodRequest
    ): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(UpdateBraintreePaymentMethodRequestTypedef)
        }
    })
    public async updateBraintreePaymentMethod(
        apiOptions: unknown
    ): Promise<void> {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        await this.commerceManager.updateBraintreePaymentMethod(
            request,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.PayPal.PayPalApi';
    }

    // #endregion
}
