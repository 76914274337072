/**
 *
 * @module commerceApi
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/CommerceApi.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/commerce.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { ComcastUpdatePaymentMethodRequestTypedef } from './comcast/typedefs';
import { IDealUpdatePaymentMethodRequestTypedef } from './iDeal/typedefs';
import { KlarnaUpdatePaymentMethodRequestTypedef } from './klarna/typedefs';
import { MercadoUpdatePaymentMethodRequestTypedef } from './mercado/typedefs';
import { PayPalUpdatePaymentMethodRequestTypedef } from './payPal/typedefs';
import { CardUpdatePaymentMethodRequestTypedef } from './paymentCard/typedefs';

import {
    AccountResumeRequest,
    AccountResumeRequestTypedef,
    AssociateAuthValuesWithPaymentMethodRequest,
    AssociateAuthValuesWithPaymentMethodRequestTypedef,
    PaymentMethod,
    PlanSwitchRequest,
    PlanSwitchRequestTypedef,
    PriceOrderRequest,
    PriceOrderRequestTypedef,
    RedeemRequest,
    RedeemRequestTypedef,
    RestartSubscriptionRequest,
    RestartSubscriptionRequestTypedef,
    SubmitOrderWithPaymentMethodRequest,
    SubmitOrderWithPaymentMethodRequestTypedef,
    UpdateOrderRequest,
    UpdateOrderRequestTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from './typedefs';

import { PaymentMethodType, PaymentType } from '../services/commerce/enums';

import CommerceManager from './commerceManager';
import BaseApi from '../baseApi';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import Logger from '../logging/logger';
import {
    PriceOrderResponse,
    QueryOrderResponse,
    RedeemResponse,
    ZipLocation
} from '../services/commerce/typedefs';

const DustUrn = DustUrnReference.commerce.commerceApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides ability to access commerce data and link subscriptions to the commerce.
 *
 */
export default class CommerceApi extends BaseApi {
    /**
     *
     * @access private
     * @type {CommerceManager}
     *
     */
    private commerceManager: CommerceManager;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Commerce.CommerceManager} options.commerceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        commerceManager: CommerceManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceManager: Types.instanceStrict(CommerceManager),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceManager } = options;

        this.commerceManager = commerceManager;

        this.dustEnabled = getSafe(
            () => this.commerceManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object<SDK.Commerce.PriceOrderRequest>} request - A description of products to be priced.
     * May include location information for results with taxes calculated.
     * @desc Gets information that can be used to display product pricing to users. Cannot submit an
     * order without this information. If user's location is provided, tax will be calculated.
     * @throws {UnpriceableOrderException} Order could not be priced due to issues with the provided data.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Commerce.PriceOrderResponse>>} A promise that completes
     * when the operation has succeeded and returns pricing information for an order, given products,
     * discount vouchers, and (optionally) the user's location.
     *
     */
    public async priceOrder(
        request: PriceOrderRequest
    ): Promise<PriceOrderResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(PriceOrderRequestTypedef)
        }
    })
    public async priceOrder(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.priceOrder(request, logTransaction);
    }

    /**
     *
     * @access public
     * @param {String} zipCode - The zip code to be used for looking up potential cities and states.
     * @desc Gets a list of potential cities and states that can be used as a billing address.
     * @throws {InvalidZipCodeException} Invalid or nonexistent zip code. E.g. `asdf` or `00000` would trigger this.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Array<Object<SDK.Services.Commerce.ZipLocation>>>} A promise that completes when the operation
     * has succeeded and returns address information for potential cities and states based on the given zip code.
     *
     */
    public async getLocationCandidates(
        zipCode: string
    ): Promise<Array<ZipLocation>>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            zipCode: Types.nonEmptyString
        }
    })
    public async getLocationCandidates(apiOptions: unknown) {
        const {
            logTransaction,
            args: [zipCode]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.lookupByZipCode(
            zipCode,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {Object<SDK.Commerce.RedeemRequest>} request
     * @desc Redeem and entitle a user without any payment information.
     * @throws {InvalidRedemptionCodeException} Redemption code is invalid or already redeemed.
     * @throws {ProductBlockedException} Product Blocked user has active subscription for a similar product.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Commerce.RedeemResponse>>}
     *
     */
    public async redeem(request: RedeemRequest): Promise<RedeemResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(RedeemRequestTypedef)
        }
    })
    public async redeem(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.redeem(request, logTransaction);
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {Object<SDK.Commerce.SubmitOrderWithPaymentMethodRequest>} request - A description
     * of products to be ordered and information necessary to process the order.
     * @param {String} [deviceProfile] - A String taken from RequestConnectedDeviceDetailsResult.deviceProfile if this
     * purchase was made as part of a LicensePlate flow. Otherwise null should be provided.
     * @desc Submits an order for purchasing. If the order is successfully placed, a unique identifier for the order is returned.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} A promise that completes when the operation has succeeded
     * and returns a unique identifier for the order.
     *
     */
    public async submitOrderWithPaymentMethod(
        request: SubmitOrderWithPaymentMethodRequest,
        deviceProfile?: string
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(SubmitOrderWithPaymentMethodRequestTypedef),
            deviceProfile: Types.nonEmptyString.optional
        }
    })
    public async submitOrderWithPaymentMethod(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        const {
            logTransaction,
            args: [request, deviceProfile]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.submitOrderWithPaymentMethod(
            request,
            deviceProfile,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.7.0
     * @param {Object<SDK.Commerce.UpdateOrderRequest>} request - Information used to patch an order.
     * @desc Patches an order to notify of a successful 3DS challenge.
     * @throws {InvalidOrderIdException} Id does not exist.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the operation has succeeded.
     *
     */
    public async updateOrder(request: UpdateOrderRequest): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(UpdateOrderRequestTypedef)
        }
    })
    public async updateOrder(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.updateOrder(request, logTransaction);
    }

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {String} orderId - The identifier for the order.
     * @desc Checks the status of the order specified by the passed in guid. Returns true if the order is complete.
     * @throws {OrderProductBlockedException} Indicated order was blocked, product is already owned by the user.
     * @throws {OrderCountryInvalidException} Indicated order failed because the geo-location country code of the user does not match the wallet BIN country code.
     * @throws {OrderFraudException} Indicated order failed to pass fraud prevention checks.
     * @throws {OrderPaymentDeclinedException} Indicated order failed due to the payment method being declined.
     * @throws {OrderTimeoutException} The order could not be verified in a timely fashion. Requests Limit reached for an order.
     * @throws {OrderStandaloneExceedsBundleException} Indicate to any subscribers with select 2+ standalone services
     * that they are not able to purchase the Disney Bundle on D+.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Commerce.QueryOrderResponse>>} A promise that completes when the operation has
     * succeeded and returns an indication of whether or not the order was successfully processed.
     *
     */
    public async validateOrder(orderId: string): Promise<QueryOrderResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            orderId: Types.nonEmptyString
        }
    })
    public async validateOrder(apiOptions: unknown) {
        const {
            logTransaction,
            args: [orderId]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.validateOrder(
            orderId,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 3.5.0
     * @desc Retrieves the default payment method for the user which case be used to submit an order.
     * @throws {PaymentMethodNotFoundException} Unable to retrieve the payment method as it was not found.
     * @throws {PaymentMethodConsentRequiredException} The user must consent to share their default payment method on file across partners.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>} Information about the payment method that was successfully retrieved.
     *
     */
    public async getDefaultPaymentMethod(): Promise<PaymentMethod>;

    @apiMethodDecorator()
    public async getDefaultPaymentMethod(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.commerceManager.getDefaultPaymentMethod(
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {Object<SDK.Commerce.UpdatePaymentMethodBase>} request - Update information for a payment method.
     * @desc Updates and retrieves information about a specific payment method.
     * @throws {CardUpdateRejectedException} The request was rejected by the service, please try again.
     * @throws {PaymentMethodNotFoundException} Unable to retrieve the payment method as it was not found.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>} Information about the payment method that was successfully updated.
     *
     */
    public async updatePaymentMethod(
        request: UpdatePaymentMethodBase
    ): Promise<PaymentMethod>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(UpdatePaymentMethodBaseTypedef)
        }
    })
    public async updatePaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        const { updateType, paymentType } = this.getUpdatePaymentMethodInfo(
            request.type
        );

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            typecheck(updateType, request);
        }

        return await this.commerceManager.updatePaymentMethod(
            request,
            paymentType,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 10.0.0
     * @desc List all payment methods in the account wallet.
     * @throws {PaymentMethodNotFoundException} Unable to retrieve the payment method as it was not found.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Array<Object<SDK.Commerce.PaymentMethod>>>} A list of all payment methods in the account wallet.
     *
     */
    public async listAllPaymentMethods(): Promise<Array<PaymentMethod>>;

    @apiMethodDecorator()
    public async listAllPaymentMethods(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.commerceManager.listAllPaymentMethods(logTransaction);
    }

    /**
     *
     * @access public
     * @since 4.7.0
     * @param {Object<SDK.Commerce.AccountResumeRequest>} request - The account resume request containing purchase and subscription information.
     * @param {String} [deviceProfile] - A String taken from RequestConnectedDeviceDetailsResult.deviceProfile if this
     * purchase was made as part of a LicensePlate flow. Otherwise null should be provided.
     * @desc Submit payment and subscription information to remove an account hold from user's account.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>}
     *
     */
    public async resumeAccount(request: AccountResumeRequest): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(AccountResumeRequestTypedef),
            deviceProfile: Types.nonEmptyString.optional
        }
    })
    public async resumeAccount(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request, deviceProfile]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.resumeAccount(
            request,
            deviceProfile,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 4.8.0
     * @desc Request a JWT token used as the DDC Session Id for 3DS2 Two-Factor Authentication.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>}
     *
     */
    public async getDdcJwtToken(): Promise<string>;

    @apiMethodDecorator()
    public async getDdcJwtToken(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.commerceManager.getDdcJwtToken(logTransaction);
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object<SDK.Commerce.PlanSwitchRequest>} request - Information used to switch plans.
     * @desc Switch a product, plan, or offering.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} A promise that completes when the operation has succeeded
     * and returns a unique identifier for the order.
     *
     */
    public async planSwitch(request: PlanSwitchRequest): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(PlanSwitchRequestTypedef)
        }
    })
    public async planSwitch(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.planSwitch(request, logTransaction);
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 8.0.0
     * @desc Allows the default payment method on an account to be shared across a user's identity.
     * @throws {PaymentMethodNotFoundException} Unable to retrieve the payment method as it was not found.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public async shareDefaultPaymentMethod(): Promise<PaymentMethod>;

    @apiMethodDecorator()
    public async shareDefaultPaymentMethod(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.commerceManager.shareDefaultPaymentMethod(
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.RestartSubscriptionRequest>} request - Information about the cancelled subscription that should be restarted.
     * @desc Restart/Revert the subscription after cancellation. If the subscription is successfully restarted,
     * a unique identifier for the order is returned.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} A promise that completes when the operation has succeeded and returns a unique identifier for the order.
     *
     */
    public async restartSubscription(
        request: RestartSubscriptionRequest
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(RestartSubscriptionRequestTypedef)
        }
    })
    public async restartSubscription(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.restartSubscription(
            request,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {String} paymentMethodId - Payment method ID retrieved from a request to create a payment method.
     * @desc Retrieves information about a specific payment method.
     * @throws {PaymentMethodNotFoundException} Unable to retrieve the payment method as it was not found.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public async getPaymentMethod(
        paymentMethodId: string
    ): Promise<PaymentMethod>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            paymentMethodId: Types.nonEmptyString
        }
    })
    public async getPaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [paymentMethodId]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.getPaymentMethod(
            paymentMethodId,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 12.0.0
     * @param {Object<SDK.Commerce.AssociateAuthValuesWithPaymentMethodRequest>} request - Used to associate authentication values with a payment method.
     * @desc Adds or updates specific Payment Method attributes which may only be persisted in the Wallet for a limited amount of time, currently 4 hours.
     * @throws {CardUpdateRejectedException} The request was rejected by the service, please try again.
     * @throws {PaymentMethodNotFoundException} Unable to retrieve the payment method as it was not found.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     */
    public async associateAuthValuesWithPaymentMethod(
        request: AssociateAuthValuesWithPaymentMethodRequest
    ): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(
                AssociateAuthValuesWithPaymentMethodRequestTypedef
            )
        }
    })
    public async associateAuthValuesWithPaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        await this.commerceManager.associateAuthValuesWithPaymentMethod(
            request,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} token - UBA token in string format.
     * @desc Stores the UBA token for necessary commerce service calls. This should be set before any of those calls are made.
     * @returns {Void}
     *
     */
    public setUbaToken(token: string): void {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                token: Types.nonEmptyString
            };

            typecheck(this, 'setUbaToken', params, arguments);
        }

        this.commerceManager.setUbaToken(token);
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @param {String<SDK.Services.Commerce.PaymentMethodType>} type - object containing one of the six different payment method types that can be updated.
     * @returns {Object} - returns the typedef obj and update endpoint path for a payment type.
     * @note Disney Rewards cannot be updated via updatePaymentMethod.
     *
     */
    private getUpdatePaymentMethodInfo(type: keyof typeof PaymentMethodType): {
        updateType: TodoAny;
        paymentType: TodoAny;
    } {
        let updateType;
        let paymentType;

        switch (type) {
            case PaymentMethodType.PaymentCard:
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    updateType = CardUpdatePaymentMethodRequestTypedef;
                }
                paymentType = PaymentType.paymentCards;
                break;

            case PaymentMethodType.PayPalExpress:
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    updateType = PayPalUpdatePaymentMethodRequestTypedef;
                }
                paymentType = PaymentType.paypalExpress;
                break;

            case PaymentMethodType.MercadoPagoPayment:
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    updateType = MercadoUpdatePaymentMethodRequestTypedef;
                }
                paymentType = PaymentType.mercadopagoPayments;
                break;

            case PaymentMethodType.KlarnaPayment:
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    updateType = KlarnaUpdatePaymentMethodRequestTypedef;
                }
                paymentType = PaymentType.klarnaPayments;
                break;

            case PaymentMethodType.IDealPayment:
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    updateType = IDealUpdatePaymentMethodRequestTypedef;
                }
                paymentType = PaymentType.idealPayments;
                break;

            case PaymentMethodType.ComcastPayment:
                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    updateType = ComcastUpdatePaymentMethodRequestTypedef;
                }
                paymentType = PaymentType.comcastPayments;
                break;
        }

        return { updateType, paymentType };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.CommerceApi';
    }
}
