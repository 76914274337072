/**
 *
 * @module entitlementApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/entitlement.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import BaseApi from '../baseApi';

import EntitlementManager from './entitlementManager';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import Logger from '../logging/logger';

const DustUrn = DustUrnReference.entitlement.entitlementApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface EntitlementApiOptions {
    entitlementManager: EntitlementManager;
    logger: Logger;
}

/**
 *
 * @access public
 * @since 4.9.0
 * @desc Provides ability to get entitlement information.
 *
 */
export default class EntitlementApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Entitlement.EntitlementManager}
     *
     */
    private entitlementManager: EntitlementManager;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled?: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Entitlement.EntitlementManager} options.entitlementManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: EntitlementApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    entitlementManager: Types.instanceStrict(EntitlementManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { entitlementManager } = options;

        this.entitlementManager = entitlementManager;
        this.dustEnabled = getSafe(
            () => this.entitlementManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {String} mediaId - The mediaId to verify account has sufficient entitlements to watch.
     * @desc Requests DRM service to verify this account has the necessary entitlements to watch this content.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {BlackoutException} The current user is not allowed to access the media for the requested license because they are in a blackout location.
     * @throws {KidsModeEnabledException} The current user is not allowed to access the media due to parental controls restrictions on the account.
     * @throws {LoginRequiredException} The requested license can only be obtained after authentication.
     * @throws {MediaUnavailableException} The requested media is not available.
     * @throws {NotEntitledException} The current user does not have sufficient privileges to access the media for the requested license.
     * @throws {ParentalControlsRestrictedException} The current user is not allowed to access the media for the requested license due to parental controls restrictions on the profile.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     */
    public async verifyMediaRights(mediaId: string): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            mediaId: Types.nonEmptyString
        }
    })
    public async verifyMediaRights(apiOptions: unknown): Promise<TodoAny> {
        const {
            logTransaction,
            args: [mediaId]
        } = apiOptions as ApiOptions;

        return await this.entitlementManager.verifyMediaRights(
            mediaId,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Entitlement.EntitlementApi';
    }

    // #endregion
}
