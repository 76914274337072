/**
 *
 * @module offDeviceRedemptionFlow
 *
 */

/**
 *
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Orchestration.OffDeviceRedemptionFlow
 * @desc Hint about what flow was taken on the web side, i.e. user registration or user login.
 *
 * @property {String} Register - Since `4.18.0`.
 * @property {String} Login - Since `4.18.0`.
 *
 */
enum OffDeviceRedemptionFlow {
    Register = 'Register',
    Login = 'Login'
}

export default OffDeviceRedemptionFlow;
