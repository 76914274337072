/**
 *
 * @module mediaApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Media/MediaApi.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Media/DigitalRightsManagement.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Media/PlaybackScenarios.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import PlatformMetricsProvider from './../platform/platformMetricsProvider';
import MediaManager from './mediaManager';
import MediaDescriptor from './mediaDescriptor';
import MediaItem from './mediaItem';
import PlayerAdapter from './playerAdapter';

import SessionManager from '../session/sessionManager';

import Presentation from '../services/media/presentation';
import PlaybackContext from '../services/media/playbackContext';
import PlaylistType from './../services/media/playlistType';

import NetworkType from '../services/qualityOfService/networkType';
import PlaybackActivity from '../services/qualityOfService/playbackActivity';
import QoePlaybackError from '../services/qualityOfService/qoePlaybackError';
import PlaybackEventData from '../services/qualityOfService/playbackEventData';
import PlaybackExitedCause from '../services/qualityOfService/playbackExitedCause';
import PlaybackIntent from '../services/qualityOfService/playbackIntent';
import PlaybackSessionTransferredEventData from '../services/qualityOfService/playbackSessionTransferredEventData';
import PlaybackStartupEventData from '../services/qualityOfService/playbackStartupEventData';
import ProductType from '../services/qualityOfService/productType';
import StartupActivity from '../services/qualityOfService/startupActivity';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import DustCategory from '../services/internal/dust/dustCategory';

import ExceptionReference from '../services/exception/exceptionReference';
import ServiceException from '../services/exception/serviceException';

import getSafe from '../services/util/getSafe';
import { getDataVersion } from './playbackTelemetryDispatcher';

import { ThumbnailResolution } from './enums';
import type Logger from '../logging/logger';
import type DrmType from '../services/media/drmType';
import PlaybackSession from './playbackSession';
import SpriteThumbnailSet from '../services/media/spriteThumbnailSet';
import BifThumbnailSet from '../services/media/bifThumbnailSet';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;
const DustUrn = DustUrnReference.media.mediaApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides a resource that can be used to help load, control, and monitor media playback.
 *
 */
export default class MediaApi extends BaseApi {
    /**
     *
     * @access private
     * @type {SDK.Media.MediaManager}
     *
     */
    private mediaManager: MediaManager;

    /**
     *
     * @access private
     * @since 20.0.0
     * @type {SDK.Session.SessionManager}
     *
     */
    private sessionManager: SessionManager;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Platform.PlatformMetricsProvider}
     *
     */
    private platformMetricsProvider: PlatformMetricsProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging.
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Media.MediaManager} options.mediaManager
     * @param {SDK.Session.SessionManager} options.sessionManager
     * @param {SDK.Platform.PlatformMetricsProvider} options.platformMetricsProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        mediaManager: MediaManager;
        sessionManager: SessionManager;
        platformMetricsProvider: PlatformMetricsProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaManager: Types.instanceStrict(MediaManager),
                    sessionManager: Types.instanceStrict(SessionManager),
                    platformMetricsProvider: Types.instanceStrict(
                        PlatformMetricsProvider
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const { mediaManager, sessionManager, platformMetricsProvider } =
            options;

        this.mediaManager = mediaManager;
        this.sessionManager = sessionManager;
        this.platformMetricsProvider = platformMetricsProvider;
        this.dustEnabled = getSafe(() => this.mediaManager.client.dustEnabled);

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Media.PlayerAdapter} playerAdapter - The player adapter you would like to create a new media session for.
     * @param {Array<SDK.Services.Media.DrmType>} [priorityDrms=null] - override DRM with a specified DRM or order of DRMs.
     * @desc Creates a new instance of PlaybackSession initialized with the supplied playerAdapter.
     * This is the entry point into all further interactions with media related SDK features.
     * @returns {Promise<SDK.Media.PlaybackSession>} Injected with all dependencies.
     *
     * @example const playbackSession = await mediaApi.createPlaybackSession(playerAdapter);
     *
     */
    public async createPlaybackSession(
        playerAdapter: PlayerAdapter,
        priorityDrms?: Array<ValueOf<typeof DrmType>>
    ): Promise<PlaybackSession>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            playerAdapter: Types.instanceStrict(PlayerAdapter),
            priorityDrms: Types.array.of.nonEmptyString.optional
        }
    })
    public async createPlaybackSession(apiOptions: unknown) {
        const {
            args: [playerAdapter, priorityDrms = null]
        } = apiOptions as ApiOptions;

        return await this.mediaManager.createPlaybackSession(
            playerAdapter,
            priorityDrms
        );
    }

    /**
     *
     * @access public
     * @since 3.3.0
     * @param {Object} options
     * @param {SDK.Media.MediaDescriptor} options.mediaDescriptor - Supplies the media that should be played and
     * any relevant playback rules.
     * @param {SDK.Services.Media.PlaylistType} options.preferredPlaylistType - Specifies playlist to prepare (COMPLETE or SLIDE).
     * @param {SDK.Services.Media.PlaybackContext} [options.playbackContext] Information about the media playback context.
     * @desc Fetches a media payload.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {AuthorizationExpiredException} Could not reauthorize before attempting to access media.
     * @throws {BlackoutException} The current user is not allowed to access the media because they are in a blackout location.
     * @throws {KidsModeEnabledException} The current user is not allowed to access the media due to parental controls restrictions on the account.
     * @throws {LoginRequiredException} The requested media can only be viewed after authentication.
     * @throws {MediaExperienceContextBodyInvalidException} Invalid experience context (bumper) configuration for QC playback.
     * @throws {MediaNotAllowedException} The current user is not allowed to access the media.
     * @throws {MediaUnavailableException} The requested media is not available.
     * @throws {NotEntitleException} The current user does not have sufficient privileges to access the media for the requested license.
     * @throws {ParentalControlsRestrictedException} The current user is not allowed to access the media for the requested license due to parental controls restrictions on the profile.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {SdkMisconfiguredException} The SDK is misconfigured and does not include any playback scenario defaults.
     * @throws {StreamConcurrencyException} The current user is not allowed to access the media for the requested license because they have too many concurrent streams.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Media.MediaItem>}
     *
     */
    public async fetch(options: {
        mediaDescriptor: MediaDescriptor;
        preferredPlaylistType: ValueOf<typeof PlaylistType>;
        playbackContext?: PlaybackContext;
    }): Promise<MediaItem>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object({
                mediaDescriptor: Types.instanceStrict(MediaDescriptor),
                preferredPlaylistType: Types.in(PlaylistType),
                playbackContext: Types.instanceStrict(PlaybackContext).optional
            })
        }
    })
    public async fetch(apiOptions: unknown) {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions;

        const { mediaDescriptor, preferredPlaylistType, playbackContext } =
            options;

        if (Check.assigned(playbackContext)) {
            playbackContext.setPlaybackRequestedAtTimestamp();
        }

        return await this.mediaManager.getMediaItem({
            mediaDescriptor,
            preferredPlaylistType,
            playbackContext,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {SDK.Media.MediaDescriptor} mediaDescriptor - Supplies the media that should be played and any relevant playback rules.
     * @desc Derives the playback scenario based on the media descriptor.
     * @returns {String}
     *
     */
    public deriveBaseDeviceCapability(mediaDescriptor: MediaDescriptor) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                mediaDescriptor: Types.instanceStrict(MediaDescriptor)
            };

            typecheck(this, 'deriveBaseDeviceCapability', params, arguments);
        }

        return this.mediaManager.deriveBaseDeviceCapability(mediaDescriptor);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @desc Derives the playback attributes for selection based on the media descriptor.
     * @param {SDK.Media.MediaDescriptor} mediaDescriptor
     * @param {SDK.Services.Media.PlaybackContext} [playbackContext]
     * @returns {SDK.Services.Media.MediaPlaybackSelectionPayload}
     *
     */
    public derivePlaybackSelectionAttributes(
        mediaDescriptor: MediaDescriptor,
        playbackContext?: PlaybackContext
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                mediaDescriptor: Types.instanceStrict(MediaDescriptor),
                playbackContext: Types.instanceStrict(PlaybackContext).optional
            };

            typecheck(
                this,
                'derivePlaybackSelectionAttributes',
                params,
                arguments
            );
        }

        return this.mediaManager.derivePlaybackSelectionAttributes(
            mediaDescriptor,
            playbackContext
        );
    }

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {Object} options
     * @param {SDK.Services.QualityOfService.PlaybackIntent} options.playbackIntent - Indicates the intent which started playback.
     * @param {String<SDK.Services.QualityOfService.ProductType>} productType - productType Indicates the type of product (Live or VOD).
     * @param {Boolean} [options.isPreBuffering=false] - Indicates that pre buffering is occurring if true (the video will be loaded before we are ready to show it).
     * @param {Boolean} [options.offline] - Indicates that the media is offline/previously downloaded.
     * @param {String} [options.interactionId] - The client-side generated unique ID representing a single element interaction within the container.
     * The interactionId will correspond with one of the defined interactionTypes. The interactionId will be used as the primary key for all interactions.
     * @param {Boolean} options.disablePlaybackSnapshotEventsOverride - Since `20.0.0` - An application override to explicitly disable the Playback QoE PlaybackSnapshot event.
     * @param {String} [options.pqmGroupId] - Since `20.0.0` - The binary version of the Playback Quality Manager (PQM) currently used in this playback session.
     * Conditionally required if PQM is supported.
     * @param {String} [options.pqmVersion] - Since `20.0.0` - Used in PQM to distinguish different client-side ABR algorithm experiment groups. Conditionally required if PQM is supported.
     * @param {Object} [options.startupContext] - Associated key-value pairs to be serialized with all QoE startup events. This should include data such as the
     * playbackConfigVersions received from the Playback Configuration Service (PCS).
     * @param {Object} options.contentKeys - Associated content identifiers for the media, such as `contentId` and `mediaId`.
     * @param {Object} [options.data] - Partner specific data to provide additional context for all playback events.
     * @param {Boolean} [options.artificialDelay] - Indicates that a delay occurred or will occur during the startup sequence of playing.
     * content, such as displaying the Negative Stereotype Advisory warning.
     * @desc Called when initiating the action of playing media, capturing intent to play content, leading into video playback.
     * @note Create a new PlaybackSessionId and log a PlaybackStartup QoE event with the `requested` startup activity.
     * @note Use the SDK.Session.SessionManager to detect the presence and value of the `wpnx-playback-snapshot-events-enabled` for QoE Playback Snapshot events.
     * @returns {SDK.Services.Media.PlaybackContext}
     *
     */
    public initializePlaybackContext(options: {
        playbackIntent: ValueOf<typeof PlaybackIntent>;
        productType: ValueOf<typeof ProductType>;
        isPreBuffering?: boolean;
        offline?: boolean;
        interactionId?: string;
        disablePlaybackSnapshotEventsOverride: boolean;
        pqmGroupI?: string;
        pqmVersion?: string;
        startupContext?: Record<string, unknown>;
        contentKeys: Record<string, unknown>;
        data?: Record<string, unknown>;
        artificialDelay?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackIntent: Types.in(PlaybackIntent),
                    productType: Types.in(ProductType),
                    isPreBuffering: Types.boolean.optional,
                    offline: Types.boolean.optional,
                    interactionId: Types.nonEmptyString.optional,
                    disablePlaybackSnapshotEventsOverride: Types.boolean,
                    pqmGroupId: Types.nonEmptyString.optional,
                    pqmVersion: Types.nonEmptyString.optional,
                    startupContext: Types.object().optional,
                    contentKeys: Types.object(),
                    data: Types.object().optional,
                    artificialDelay: Types.boolean.optional
                })
            };

            typecheck(this, 'initializePlaybackContext', params, arguments);
        }

        const {
            playbackIntent,
            productType,
            isPreBuffering = false,
            startupContext,
            contentKeys,
            data = {}
        } = options;

        const {
            dustEnabled,
            logger,
            platformMetricsProvider
            // sessionManager
        } = this;
        const { analyticsProvider } = logger;

        const cpuPercentage =
            platformMetricsProvider.availableCpuPercentage() || undefined;
        const freeMemory =
            platformMetricsProvider.availableMemoryMb() || undefined;

        // TODO: when this is implemented, we need to update
        // sessionManager#getFeatureFlags to be synchronous so that
        // #initializePlaybackContext can remain synchronous.

        // const featureFlags = sessionManager.getFeatureFlags() || {};
        // const snapshotEventFeatureFlagEnabled =
        //     (featureFlags[
        //         'wpnx-playback-snapshot-events-enabled'
        //     ] as boolean) || false;

        const snapshotEventFeatureFlagEnabled = false;

        const playbackContext = new PlaybackContext({
            ...options,
            isPreBuffering,
            data,
            snapshotEventFeatureFlagEnabled
        });

        if (dustEnabled) {
            let eventData;

            if (analyticsProvider) {
                const commonProperties =
                    analyticsProvider.getCommonProperties();

                eventData = {
                    ...data,
                    ...commonProperties
                };
            }

            const playbackStartupEventData = new PlaybackStartupEventData({
                startupActivity: StartupActivity.requested,
                playbackSessionId: playbackContext.playbackSessionId,
                productType,
                networkType: NetworkType.unknown,
                playbackIntent,
                mediaPreBuffer: isPreBuffering,
                cpuPercentage,
                freeMemory,
                contentKeys,
                data: eventData,
                startupContext
            });

            Object.keys(playbackStartupEventData).forEach((key) => {
                if (Check.assigned(data) && key in data) {
                    logger.warn(
                        this.toString(),
                        `Incoming data for key '${key}' with value '${
                            data[key]
                        }' will override value '${
                            (
                                playbackStartupEventData as Indexable<
                                    typeof playbackStartupEventData
                                >
                            )[key]
                        }'`
                    );
                }
            });

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn: QualityOfServiceDustUrnReference.playbackStartup,
                data: {
                    ...playbackStartupEventData
                },
                skipLogTransaction: true,
                dataVersion: getDataVersion(
                    QualityOfServiceDustUrnReference.playbackStartup
                )
            });

            dustLogUtility.log();
        }

        return playbackContext;
    }

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Services.Media.PlaybackContext} playbackContext - The value returned from the call to initializePlaybackContext.
     * @desc Called when initiating the action of transferring a playback session to another device.
     *
     */
    public transferPlaybackContext(playbackContext: PlaybackContext) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackContext: Types.instanceStrict(PlaybackContext)
            };

            typecheck(this, 'transferPlaybackContext', params, arguments);
        }

        const { dustEnabled, logger } = this;

        const playbackSessionTransferredEventData =
            new PlaybackSessionTransferredEventData(
                playbackContext.playbackSessionId
            );

        if (dustEnabled) {
            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn: QualityOfServiceDustUrnReference.playbackSessionTransferred,
                data: {
                    ...playbackSessionTransferredEventData
                },
                skipLogTransaction: true,
                dataVersion: getDataVersion(
                    QualityOfServiceDustUrnReference.playbackSessionTransferred
                )
            });

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to Fetch.
     * @desc Fetches the details about the spritesheet thumbnail images that players can show to users when scrubbing video.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ThumbnailsNotAvailableException} No thumbnails are available for the given media.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Array<SDK.Services.Media.SpriteThumbnailSet>>} A promise that returns a spritesheet
     * thumbnail set object describing the location and information about the thumbnails for all resolutions.
     *
     */
    public getSpriteSheetThumbnailSets(
        mediaItem: MediaItem
    ): Promise<Array<SpriteThumbnailSet>>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            mediaItem: Types.instanceStrict(MediaItem)
        }
    })
    public getSpriteSheetThumbnailSets(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [mediaItem]
        } = apiOptions as ApiOptions;

        const spritesheet = getSafe(
            () => mediaItem.payload.thumbnails.spritesheet
        );

        if (Check.not.nonEmptyObject(spritesheet)) {
            const thumbnailsNotAvailableException = new ServiceException({
                exceptionData: ExceptionReference.media.thumbnailsNotAvailable
            });

            return Promise.reject(thumbnailsNotAvailableException);
        }

        dustLogUtility.logData({
            thumbnailUrl: spritesheet.href
        });

        return this.mediaManager.getSpriteSheetThumbnailSets(
            mediaItem,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to Fetch.
     * @param {String} resolution - The desired resolution of thumbnails to fetch.
     * @desc Fetches the details about the spritesheet thumbnail images that players can show to users when scrubbing video.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ThumbnailsNotAvailableException} No thumbnails are available for the given media.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Media.SpriteThumbnailSet>} A promise that returns a spritesheet thumbnail set
     * object describing the location and information about the thumbnails at the given resolution.
     *
     */
    public getSpriteSheetThumbnailSet(
        mediaItem: MediaItem,
        resolution: string
    ): Promise<SpriteThumbnailSet>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            mediaItem: Types.instanceStrict(MediaItem),
            resolution: Types.in(ThumbnailResolution)
        }
    })
    public getSpriteSheetThumbnailSet(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [mediaItem, resolution]
        } = apiOptions as ApiOptions;

        const spritesheet = getSafe(
            () => mediaItem.payload.thumbnails.spritesheet
        );

        if (Check.not.nonEmptyObject(spritesheet)) {
            const thumbnailsNotAvailableException = new ServiceException({
                exceptionData: ExceptionReference.media.thumbnailsNotAvailable
            });

            return Promise.reject(thumbnailsNotAvailableException);
        }

        dustLogUtility.logData({
            thumbnailUrl: spritesheet.href,
            thumbnailResolution: resolution
        });

        return this.mediaManager.getSpriteSheetThumbnailSet(
            mediaItem,
            resolution,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Services.Media.Presentation} presentation - The thumbnail presentation to download. Typically chosen by desired resolution.
     * @param {Number} index - The index of the thumbnail in the pod to download.
     * @desc Fetches the details about the spritesheet thumbnail images that players can show to users when scrubbing video.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<ArrayBuffer>} Fetches an actual spritesheet thumbnail image that players can show to users when scrubbing video.
     *
     */
    public getSpriteSheetThumbnail(
        presentation: Presentation
    ): Promise<ArrayBuffer>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            presentation: Types.instanceStrict(Presentation),
            index: Types.number
        }
    })
    public getSpriteSheetThumbnail(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [presentation, index]
        } = apiOptions as ApiOptions;

        dustLogUtility.logData({
            spriteSheetUrl: presentation.paths[index]
        });

        return this.mediaManager.getSpriteSheetThumbnail(
            presentation,
            index,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to Fetch.
     * @desc Fetches the details about the BIF thumbnail images that players can show to users when scrubbing video.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ThumbnailsNotAvailableException} No thumbnails are available for the given media.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Array<SDK.Services.Media.BifThumbnailSet>>} A promise that returns a BIF thumbnail set object
     * describing the location and information about the thumbnails for all resolutions.
     *
     */
    public getBifThumbnailSets(
        mediaItem: MediaItem
    ): Promise<Array<BifThumbnailSet>>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            mediaItem: Types.instanceStrict(MediaItem)
        }
    })
    public getBifThumbnailSets(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [mediaItem]
        } = apiOptions as ApiOptions;

        const bif = getSafe(() => mediaItem.payload.thumbnails.bif);

        if (Check.not.nonEmptyObject(bif)) {
            const thumbnailsNotAvailableException = new ServiceException({
                exceptionData: ExceptionReference.media.thumbnailsNotAvailable
            });

            return Promise.reject(thumbnailsNotAvailableException);
        }

        dustLogUtility.logData({
            thumbnailUrl: bif.href
        });

        return this.mediaManager.getBifThumbnailSets(mediaItem, logTransaction);
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to Fetch.
     * @param {String<SDK.Media.ThumbnailResolution>} resolution - The desired resolution of thumbnails to fetch.
     * @desc Fetches the details about the BIF thumbnail images that players can show to users when scrubbing video.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ThumbnailsNotAvailableException} No thumbnails are available for the given media.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Media.BifThumbnailSet>} A promise that returns a BIF thumbnail set object
     * describing the location and information about the thumbnails for the desired resolution.
     *
     */
    public getBifThumbnailSet(
        mediaItem: MediaItem,
        resolution: ThumbnailResolution
    ): Promise<BifThumbnailSet>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            mediaItem: Types.instanceStrict(MediaItem),
            resolution: Types.in(ThumbnailResolution)
        }
    })
    public getBifThumbnailSet(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [mediaItem, resolution]
        } = apiOptions as ApiOptions;

        const bif = getSafe(() => mediaItem.payload.thumbnails.bif);

        if (Check.not.nonEmptyObject(bif)) {
            const thumbnailsNotAvailableException = new ServiceException({
                exceptionData: ExceptionReference.media.thumbnailsNotAvailable
            });

            return Promise.reject(thumbnailsNotAvailableException);
        }

        dustLogUtility.logData({
            thumbnailUrl: bif.href,
            thumbnailResolution: resolution
        });

        return this.mediaManager.getBifThumbnailSet(
            mediaItem,
            resolution,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 15.1.0
     * @param {SDK.Services.Media.PlaybackContext} playbackContext
     * @desc This function serves as a signal that the playback attempt has finished. This function is expected to be
     * called by application developers when tearing down playback before the playerAdapter is initialized.
     * (ex: User uses the back button).
     * @note Using typecheck.warn here since it is possible for `playbackContext` to not exist yet.
     *
     */
    public endPlaybackContext(playbackContext: PlaybackContext) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackContext: Types.instanceStrict(PlaybackContext)
            };

            typecheck.warn(this, 'endPlaybackContext', params, arguments);
        }

        const { dustEnabled, logger } = this;
        const { analyticsProvider } = logger;
        const {
            playbackSessionId,
            productType,
            contentKeys = {},
            data = {}
        } = playbackContext || {};

        if (dustEnabled) {
            let eventData;

            if (analyticsProvider) {
                const commonProperties =
                    analyticsProvider.getCommonProperties();

                eventData = {
                    ...data,
                    ...commonProperties
                };
            }

            const playbackEventData = new PlaybackEventData({
                playbackActivity: PlaybackActivity.ended,
                playbackSessionId,
                productType,
                cause: PlaybackExitedCause.user,
                playbackError: QoePlaybackError.unknown,
                contentKeys,
                data: eventData
            });

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn: QualityOfServiceDustUrnReference.playback,
                data: {
                    ...playbackEventData
                },
                skipLogTransaction: true,
                dataVersion: getDataVersion(
                    QualityOfServiceDustUrnReference.playback
                )
            });

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.MediaApi';
    }
}
