/**
 *
 * @module entry
 * @desc {SDK.Purchase} entry point.
 *
 */

import PurchaseApi from './purchaseApi';
import PurchaseManager from './purchaseManager';
import AccessStatus from './accessStatus';
import Receipt from './receipt';
import PurchaseType from './purchaseType';
import RedeemRetryHandler from './redeemRetryHandler';

/**
 *
 * @typedef {Object} SDK.Purchase
 *
 * @property {PurchaseApi} PurchaseApi
 * @property {PurchaseManager} PurchaseManager
 * @property {AccessStatus} AccessStatus
 * @property {Receipt} Receipt
 * @property {SDK.Purchase.PurchaseType} PurchaseType
 * @property {RedeemRetryHandler} RedeemRetryHandler
 *
 */
export default {
    PurchaseApi,
    PurchaseManager,
    AccessStatus,
    Receipt,
    PurchaseType,
    RedeemRetryHandler
};
