/**
 *
 * @module retryPolicy
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Provides service retry rules.
 *
 */
export default class RetryPolicy {
    /**
     *
     * @param {Number} retryBasePeriod
     * @param {Number} retryMultiplier
     * @param {Number} retryMaxAttempts
     * @param {Number} retryMaxPeriod
     *
     */
    constructor(
        retryBasePeriod,
        retryMultiplier,
        retryMaxAttempts,
        retryMaxPeriod
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                retryBasePeriod: Types.number.warn,
                retryMultiplier: Types.number.warn,
                retryMaxAttempts: Types.number.warn,
                retryMaxPeriod: Types.number.warn
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the retry base period for activation in seconds.
         *
         */
        this.retryBasePeriod = retryBasePeriod;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the retry multiplier for activation.
         *
         */
        this.retryMultiplier = retryMultiplier;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the maximum number of retry attempts for activation.
         *
         */
        this.retryMaxAttempts = retryMaxAttempts;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the maximum period for retry attempts for activation.
         *
         */
        this.retryMaxPeriod = retryMaxPeriod;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.RetryPolicy';
    }

    // #endregion
}
