/**
 *
 * @module errorEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/error/v1/error.yaml
 *
 */

import { v4 as uuidv4 } from 'uuid';
import { Check, Types, typecheck } from '@dss/type-checking';

import ApplicationContext from './applicationContext';
import ErrorLevel from './errorLevel';
import ErrorSource from './errorSource';
import NetworkType from './networkType';
import QoePlaybackError from './qoePlaybackError';
import ProductType from './productType';

import ErrorReason from '../exception/errorReason';

import { AdInsertionType, PresentationType } from './enums';

import {
    AdPodDataTypedef,
    AdPodPlacementTypedef,
    AdSlotDataTypedef
} from './typedefs';

/**
 *
 * @since 13.0.0
 * @desc This event should be sent out every time there is an error in the application as soon as it's known.
 * @note urn:dss:event:qoe:client:error:v1
 *
 */
export default class ErrorEventData {
    /**
     *
     * @param {Object} [options={}]
     * @param {String<SDK.Services.QualityOfService.ApplicationContext>} options.applicationContext
     * @param {Boolean} [options.isFatal]
     * @param {String<SDK.Services.QualityOfService.ErrorSource>} [options.source]
     * @param {String} [options.errorId]
     * @param {String<SDK.Services.QualityOfService.ErrorLevel>} [options.errorLevel]
     * @param {String<SDK.Services.QualityOfService.QoePlaybackError>} [options.errorName]
     * @param {String} [options.playbackSessionId]
     * @param {String<SDK.Services.QualityOfService.ProductType>} [options.productType]
     * @param {Number} [options.playheadPosition=-1]
     * @param {Number} [options.videoBitrate=0]
     * @param {Number} [options.videoAverageBitrate=0]
     * @param {Number} [options.audioBitrate=0]
     * @param {Number} [options.maxAllowedVideoBitrate]
     * @param {Number} [options.segmentPosition]
     * @param {String} [options.cdnName='null']
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType]
     * @param {Number} [options.liveLatencyAmount]
     * @param {String} [options.errorLocalizationKey]
     * @param {String} [options.dictionaryVersion]
     * @param {SDK.Services.Exception.ErrorReason} [options.underlyingSdkError]
     * @param {String} [options.errorMessage]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Object<String, String>} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {String<SDK.Services.QualityOfService.PresentationType>} [options.presentationType=PresentationType.unknown]
     * @param {String<SDK.Services.QualityOfService.AdInsertionType>} [options.adInsertionType=AdInsertionType.none]
     * @param {String<SDK.Services.Media.SubscriptionType>|undefined} [options.subscriptionType]
     * @param {String|undefined} [options.adSessionId]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData} [options.adSlotData]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    applicationContext: Types.in(ApplicationContext),
                    isFatal: Types.boolean.optional,
                    source: Types.in(ErrorSource).optional,
                    errorLevel: Types.in(ErrorLevel).optional,
                    errorName: Types.in(QoePlaybackError).optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    playheadPosition: Types.number.optional,
                    videoBitrate: Types.number.optional,
                    videoAverageBitrate: Types.number.optional,
                    audioBitrate: Types.number.optional,
                    maxAllowedVideoBitrate: Types.number.optional,
                    segmentPosition: Types.number.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    liveLatencyAmount: Types.number.optional,
                    errorLocalizationKey: Types.nonEmptyString.optional,
                    dictionaryVersion: Types.nonEmptyString.optional,
                    underlyingSdkError:
                        Types.instanceStrict(ErrorReason).optional,
                    errorMessage: Types.nonEmptyString.optional,
                    contentKeys: Types.object().optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    data: Types.object().optional,
                    presentationType: Types.in(PresentationType).optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    subscriptionType: Types.string.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            applicationContext,
            isFatal,
            source,
            errorLevel,
            errorName,
            playbackSessionId,
            productType,
            playheadPosition,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            cdnName,
            networkType,
            liveLatencyAmount,
            errorLocalizationKey,
            dictionaryVersion,
            underlyingSdkError,
            errorMessage,
            clientGroupIds,
            serverGroupIds,
            contentKeys,
            data = {},
            presentationType,
            adInsertionType,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData
        } = options || {};

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ApplicationContext>}
         * @desc Used to distinguish where in the application the error is being sent from.
         * @note `player` should be sent when an error occurs anywhere in the video player, beginning from playback intent to playback ended.
         * @note `ad` should be sent when an error is related to ads, both inside and outside the video player.
         *
         */
        this.applicationContext = applicationContext;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean|undefined}
         * @desc Indicates if the error was fatal to the application.
         * @note When `applicationContext` is `player` or `ad`, `isFatal` = TRUE would mean errors that lead to playback stopping or being blocked.
         *
         */
        this.isFatal = Check.assigned(isFatal) ? isFatal : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ErrorSource>|undefined}
         * @desc Where the error originated from.
         *
         */
        this.source = source;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String}
         * @desc Client generated ID of the unique error.
         *
         */
        this.errorId = uuidv4();

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ErrorLevel>|undefined}
         * @desc The error impact level.
         *
         */
        this.errorLevel = errorLevel;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.QoePlaybackError>}
         * @desc The name of the error.
         * @note If `applicationContext` is not `player` or `ad`, set to `PlaybackError::Unknown` by default.
         * @note If an error occurred, SDKs should convert either the associated MediaFetchError or a PlaybackError to a QoePlaybackError.
         * @note For ad-specific errors such as `adBeaconError` or `adServerError`, source from the associated `SDK.Media.AdPlaybackEndedEvent.AdErrorData`.
         *
         */
        this.errorName = this.setErrorName(applicationContext, errorName);

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The name of the error.
         * @desc Client generated ID of the stream/playback session.
         * @note Required if `applicationContext` is `player` or `ad`.
         *
         */
        this.playbackSessionId = playbackSessionId;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
         * @desc The Product type, Live or VOD.
         * @note Required if `applicationContext` is `player` or `ad`.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.productType = productType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
         * @note Required if `applicationContext` is `player`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.playheadPosition = Check.assigned(playheadPosition)
            ? playheadPosition
            : -1;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The peak video bitrate of the current presentationType, in bps.
         * @note Required if `applicationContext` is `player`.
         * @note The SDK should keep track of the most recent videoBitrate value from the `PlaybackEventListener.onBitrateChanged` and
         * `PlaybackEventListener.onPresentationTypeChanged` event.
         * @note If value is 0 or not available return 0.
         *
         */
        this.videoBitrate = Check.assigned(videoBitrate) ? videoBitrate : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The average video bitrate of the current presentationType, in bps.
         * @note Required if `applicationContext` is `player`.
         * @note The SDK should keep track of the most recent videoAverageBitrate value from the `PlaybackEventListener.onBitrateChanged`
         * and `PlaybackEventListener.onPresentationTypeChanged` event.
         * @note If value is 0 or not available return 0.
         *
         */
        this.videoAverageBitrate = Check.assigned(videoAverageBitrate)
            ? videoAverageBitrate
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant
         * last played before the event, as reported in the HLS or DASH manifest.
         * @note Required if `applicationContext` is `player`. If the value is 0 or not available, return 0. If value is muxed, send the bitrate of the combined content.
         * @note The SDK should keep track of the most recent audioBitrate value from the `PlaybackEventListener.onAudioBitrateChanged`
         * and `PlaybackEventListener.onPresentationTypeChanged` event.
         *
         */
        this.audioBitrate = Check.assigned(audioBitrate) ? audioBitrate : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The highest video bitrate (in bps) available for the currently selected presentationType in the current DASH/HLS
         * manifest that is allowed to play. The user may not reach this bitrate in their session.
         * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
         * resolution caps based on video window resolution/orientation.
         * @note The value should default to 0 if unavailable or unknown.
         * @note Required if `applicationContext` is `player`.
         * @note Source from the cached `maxAllowedVideoBitrate` value.
         *
         */
        this.maxAllowedVideoBitrate = Check.assigned(maxAllowedVideoBitrate)
            ? maxAllowedVideoBitrate
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc This should be the epoch time in milliseconds of the video.
         * @note Required if `applicationContext` is `player` and `productType` is `Live`.
         * @note Source from the `SDK.Media.PlaybackEndedEvent`.
         *
         */
        this.segmentPosition = Check.assigned(segmentPosition)
            ? Math.floor(segmentPosition)
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String}
         * @desc The CDN running the server.
         * @note Required if `applicationContext` is `player`.
         * @note A string value of "null" needs to be provided if not run by a CDN.
         *
         */
        this.cdnName = cdnName || 'null';

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.NetworkType>}
         * @desc The type of network connection currently in use by the client.
         * @note Required if `applicationContext` is `player` or `ad`.
         *
         */
        this.networkType = networkType || NetworkType.unknown;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
         * @note Required if `applicationContext` is `player` and `productType` is `Live`.
         * @note Source from the `SDK.Media.PlaybackEndedEvent`.
         *
         */
        this.liveLatencyAmount = Check.assigned(liveLatencyAmount)
            ? Math.floor(liveLatencyAmount)
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The localization key the client used to lookup the user-facing error string in the strings dictionary.
         * @note Required if fatal error. Temporarily optional if `applicationContext` is `player`.
         * @note Ideally, this would be supplied for any fatal error. However there may be platform/timing constraints
         * @note on getting this information from the application. For now, supply this value if it's available.
         * @note Source from the `QoeError` passed in via `SDK.UserActivity.UserActivityApi.logQoeError`.
         *
         */
        this.errorLocalizationKey = errorLocalizationKey;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The dictionary version the client used to look up the errorLocalizationKey (user-facing error string)
         * when errorLocalizationKey is a resource key for user-facing errors.
         * @note Required if fatal error.
         * @note Source from the `SDK.Logging.AnalyticsProvider`.
         *
         */
        this.dictionaryVersion = dictionaryVersion;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {SDK.Services.Exception.ErrorReason|undefined}
         * @desc Information about the reason(s) the error occurred. When the error occurred is an SDK error, use the SDK error cause.
         * @note Source from the `QoeError` passed in via `SDK.UserActivity.UserActivityApi.logQoeError`.
         *
         */
        this.underlyingSdkError = underlyingSdkError;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Supporting error text for additional context. Source from the `Bam.Sdk.Media.PlaybackEndedEvent` or associated `QoeError`
         * passed in via `SDK.UserActivity.UserActivityApi.logQoeError`.
         * @note For ad-specific errors such as `adBeaconError` or `adServerError`, source from the associated `Bam.Sdk.Media.AdPlaybackEndedEvent.AdErrorData`.
         *
         */
        this.errorMessage = errorMessage;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when testing PQM.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.clientGroupIds = clientGroupIds;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.serverGroupIds = serverGroupIds;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Object<String, String>}
         * @desc Associated content keys for the media item.
         * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
         * @note Required if `applicationContext` is `player` or `ad`.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.contentKeys = contentKeys || {};

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.Media.ThumbnailPresentationType}
         * @desc The type of presentation currently being played.
         * @note Required if `applicationContext` is `player` or `ad`.
         * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
         * event or the `Bam.Sdk.Media.PlaybackStartedEvent` object.
         *
         */
        this.presentationType = presentationType || PresentationType.unknown;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.AdInsertionType>}
         * @desc The way ads are inserted into the stream.
         * @note Source by converting the `SDK.Media.MediaDescriptor.AssetInsertionStrategy`.
         *
         */
        this.adInsertionType = adInsertionType;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.AdInsertionType>|undefined}
         * @desc An identifier that provides insight into the tier of service associated with the subscription that is entitled for playback.
         * @note Required if `adInsertionType` != `none`.
         * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
         *
         */
        this.subscriptionType = subscriptionType || '';

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String|undefined}
         * @desc Random UUID assigned by the ad server.
         * @note Required if `adInsertionType` != `none`.
         * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
         *
         */
        this.adSessionId = adSessionId;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>|undefined}
         * @desc Placement information relevant to ad pods.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the associated `PlaybackEventListener` event arguments.
         *
         */
        this.adPodPlacement = adPodPlacement;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdPodData>|undefined}
         * @desc Metadata relevant to ad pods.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.adPodData = adPodData;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdSlotData>|undefined}
         * @desc Metadata relevant to ad slots.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
         *
         */
        this.adSlotData = adSlotData;

        /**
         *
         * @deprecated Value will be reported as 'deprecated'. Replaced by `presentationType`.
         * @access public
         * @since 20.0.1
         * @type {String}
         *
         */
        this.periodType = 'deprecated';

        this.setData(data);
    }

    // #region private

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    setData(data) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {String<SDK.Services.QualityOfService.ApplicationContext>} appContext
     * @param {String<SDK.Services.QualityOfService.QoePlaybackError>} [errorName]
     * @desc Assigns `errorName` property.
     * @returns {String<SDK.Services.QualityOfService.QoePlaybackError>}
     *
     */
    setErrorName(appContext, errorName = QoePlaybackError.unknown) {
        if (
            appContext !== ApplicationContext.player &&
            appContext !== ApplicationContext.ad
        ) {
            return QoePlaybackError.unknown;
        }

        return errorName;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.ErrorEventData';
    }

    // #endregion
}
