/**
 *
 * @module socketApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import BaseApi from '../baseApi';
import EdgeEvent from './../internal/dust/edgeEvent';

import FlowControlPolicy from './flowControlPolicy';
import socketConnectionState from './socketConnectionState';
import socketConnectionStateMapping from './socketConnectionStateMapping';
import SocketEvent from './socketEvent';
import SocketManager from './socketManager';
import DustDecorators from '../services/internal/dust/dustDecorators';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import getSafe from '../services/util/getSafe';
import type Logger from '../logging/logger';

const DustUrn = DustUrnReference.socket.socketApi;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.9.0
 * @desc Provides ability to access sockets.
 *
 */
export default class SocketApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Socket.SocketManager} options.socketManager
     *
     */
    public constructor(options: {
        logger: Logger;
        socketManager: SocketManager;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    socketManager: Types.instanceStrict(SocketManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { socketManager } = options;

        this.socketManager = socketManager;
        // @ts-ignore TODO: make more type-safe?
        this.dustEnabled = getSafe(() => this.socketManager.client.dustEnabled);

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc The current state of the socket connection.
     * @returns {SocketConnectionStateMapping}
     *
     */
    public get connectionState() {
        const readyState = this.socketManager.currentSocketReadyState;

        if (!this.socketManager.enabled) {
            return socketConnectionState.disabled;
        }

        return (
            // @ts-ignore TODO work around compiler error
            socketConnectionStateMapping[readyState] ||
            socketConnectionState.closed
        );
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Enables the Events@Edge websocket connection, if configured
     * @throws {SDK.Services.Exception.ServiceException}
     * @returns {Promise<Void>} Starts management of socket connections, opening a connection if necessary. This method has no effect if the SDK is already managing a socket connection.
     *
     */
    @apiMethodDecorator()
    public async start() {
        return await this.socketManager.start();
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Terminates the Events@Edge websocket connection
     * @returns {Promise<Boolean>} Closes the current connection and suspends management of socket connections.
     *
     */

    @apiMethodDecorator()
    public async stop() {
        return await this.socketManager.stop();
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {SocketEvent} socketEvent - the event to send
     * @desc Serializes the data into an Edge service envelope and sends it over the socket connection.
     * @returns {Promise<Object>} Will return an Object representing the full message envelope sent through the socket.
     *
     */
    public async sendMessage(socketEvent: SocketEvent): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            socketEvent: Types.instanceStrict(SocketEvent)
        }
    })
    public async sendMessage(apiOptions?: unknown) {
        const {
            args: [socketEvent]
        } = apiOptions as ApiOptions;

        return await this.socketManager.sendMessage(socketEvent);
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Allows access to socket messages from the underlying SDK socket connection.
     * @param {String} urn - The name of the event.
     * @param {Function} listener - The callback function.
     * @returns {EventEmitter}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public on(urn: string, listener: (...args: Array<any>) => void) {
        return this.socketManager.on(urn, listener);
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Allows access to unsubscribe from socket messages on the underlying SDK socket connection.
     * @param {String} urn - The name of the event.
     * @param {Function} listener - The callback function.
     * @returns {EventEmitter}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public off(urn: string, listener: (...args: Array<any>) => void) {
        return this.socketManager.off(urn, listener);
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @desc Request a specific flow rate of events from the Event-Edge platform
     * @param {Array<SDK.Socket.FlowControlPolicy>} policies
     * @returns {Promise<Void>}
     *
     */
    public async requestFlowControl(policies: Array<FlowControlPolicy>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                policies: Types.array.of.instanceStrict(FlowControlPolicy)
            };

            typecheck(this, 'requestFlowControl', params, arguments);
        }

        return await this.socketManager.requestFlowControl(policies);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {Object} [options]
     * @param {Number} [options.pingInterval] - The number of seconds between ping attempts.
     * @param {Number} [options.pongTimeout] - The number of seconds to wait for a pong response after sending a ping.
     * @param {Number} [options.pingMaxAttempts] - The number of unsuccessful ping attempts before trying to reestablish
     * the connection.
     * @desc Enables pinging the socket server
     * @returns {Void}
     *
     */
    public enablePing(options?: {
        pingInterval?: number;
        pongTimeout?: number;
        pingMaxAttempts?: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    pingInterval: Types.number.optional,
                    pongTimeout: Types.number.optional,
                    pingMaxAttempts: Types.number.optional
                }).optional
            };

            typecheck(this, 'enablePing', params, arguments);
        }

        this.socketManager.enablePing(options);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @desc Disables pinging the socket server
     * @returns {Void}
     *
     */
    public disablePing() {
        this.socketManager.disablePing();
    }

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Internal.Dust.EdgeEvent} edgeEvent
     * @desc Sends EdgeEvent for Dust over Sockets
     * @returns {Promise<Void>}
     *
     */
    public async postEdgeEvent(edgeEvent: EdgeEvent) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                edgeEvent: Types.instanceStrict(EdgeEvent)
            };

            typecheck(this, 'postEdgeEvent', params, arguments);
        }

        await this.socketManager.postEdgeEvent(edgeEvent);
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.SocketApi';
    }
}
