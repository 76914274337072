/**
 *
 * @module customerServiceClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/customer-service-code.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import CustomerServiceClientConfiguration from './customerServiceClientConfiguration';
import CustomerServiceClientEndpoint from './customerServiceClientEndpoint';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import LogTransaction from '../../logging/logTransaction';

const CustomerServiceClientDustUrnReference =
    DustUrnReference.services.customerService.customerServiceClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access customer services.
 *
 */
export default class CustomerServiceClient {
    /**
     *
     * @access private
     * @type {SDK.Services.CustomerService.CustomerServiceClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    public config: CustomerServiceClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {HttpClient}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @access private
     * @param {SDK.Services.CustomerService.CustomerServiceClientConfiguration} customerServiceClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        customerServiceClientConfiguration: CustomerServiceClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                customerServiceClientConfiguration: Types.instanceStrict(
                    CustomerServiceClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = customerServiceClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates a customer service code for this user for use in support.
     * @returns {Promise<String>}
     *
     */
    public async createSupportCode(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<string> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken)
            };

            typecheck(this, 'createSupportCode', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = CustomerServiceClientEndpoint.createSupportCode;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Requesting customer service support code.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CustomerServiceClientDustUrnReference.createSupportCode,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                return String(response.data);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Account.AccountClientEndpoint} options.rel - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {GetPayloadResult} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof CustomerServiceClientEndpoint;
        body?: object;
    }): GetPayloadResult {
        const { accessToken, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];

        const { href, headers } = endpoint;

        const requestBody = body ? JSON.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.CustomerService.CustomerServiceClient';
    }
}
