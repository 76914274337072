/**
 *
 * @module mediaPayloadStream
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    InsertionTypedef,
    QosDecisionsResponseTypedef,
    SourceInfoTypedef,
    AdsQosTypedef
} from './typedefs';

import PlaybackAttributes from './playbackAttributes';
import PlaybackRenditions from './playbackRenditions';
import PlaybackVariant from './playbackVariant';
import StreamingType from './streamingType';

/**
 *
 * @access private
 * @since 4.0.0
 *
 */
export default class MediaPayloadStream {
    /**
     *
     * @param {Object} options
     * @param {Object<SDK.Services.Media.AdsQos>} [options.adsQos] - Qos metrics for the ads in this stream.
     * @param {Array<SDK.Services.Media.SourceInfo>} options.sources - Media sources and source-specific analytics data.
     * @param {Array<SDK.Services.Media.PlaybackVariant>} options.variants - Video variants.
     * @param {SDK.Services.Media.PlaybackRenditions} options.renditions - Audio and subtitle renditions.
     * @param {SDK.Services.Media.PlaybackAttributes} [options.attributes] - Media Attributes
     * @param {Object<SDK.Services.Media.QosDecisionsResponse>} [options.qosDecisions] - QoS data for slide and complete playlists.
     * @param {String<SDK.Services.Media.StreamingType>} options.streamingType - Indicates vod or live streaming.
     * @param {Object<SDK.Services.Media.Insertion>} [options.insertion] - SGAI/SSAI media insertion data.
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    adsQos: Types.object(AdsQosTypedef).optional,
                    sources: Types.array.of.object(SourceInfoTypedef),
                    variants: Types.array.of.instanceStrict(PlaybackVariant),
                    renditions: Types.instanceStrict(PlaybackRenditions),
                    attributes:
                        Types.instanceStrict(PlaybackAttributes).optional,
                    qosDecisions: Types.object(QosDecisionsResponseTypedef)
                        .optional,
                    streamingType: Types.in(StreamingType),
                    insertion: Types.object(InsertionTypedef).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            adsQos,
            sources,
            variants,
            renditions,
            attributes,
            qosDecisions,
            streamingType,
            insertion
        } = options;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {Object<SDK.Services.Media.AdsQos>}
         * @desc Qos metrics for the ads in this stream.
         * @note Currently optional because it does not exist yet in the service.
         *
         */
        this.adsQos = adsQos;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {Array<SourceInfo>}
         * @desc Media sources and source-specific analytics data.
         *
         */
        this.sources = sources;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Array<PlaybackVariant>}
         * @desc Video variants
         *
         */
        this.variants = variants;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {PlaybackRenditions}
         *
         */
        this.renditions = renditions;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {SDK.Services.Media.PlaybackAttributes|undefined}
         *
         */
        this.attributes = attributes;

        /**
         *
         * @access public
         * @since 15.0.0
         * @type {Object<SDK.Services.Media.QosDecisionsResponse>}
         *
         */
        this.qosDecisions = qosDecisions || {};

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {String<StreamingType>}
         * @desc Indicates VOD or LIVE streaming.
         *
         */
        this.streamingType = streamingType;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {Insertion|undefined}
         * @desc SGAI/SSAI media insertion data.
         *
         */
        this.insertion = insertion;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaPayloadStream';
    }

    // #endregion
}
