/**
 *
 * @module socketPlugin
 * @desc {SDK.Socket.SocketPlugin} entry point.
 *
 */

import Events from './../events';
import OffDeviceTokenRefreshEvent from './../offDeviceTokenRefreshEvent';

import Socket from './entry';
import SocketEvents from './socketEvents';
import SocketManager from './socketManager';
import SocketApi from './socketApi';

import SocketService from '../services/socket/entry';

import RetryPolicy from '../services/configuration/retryPolicy';

import SocketClientConfiguration from './../services/socket/socketClientConfiguration';
import SocketClientExtrasMap from './../services/socket/socketClientExtrasMap';
import SocketManagerConfiguration from './../services/configuration/socketManagerConfiguration';
import SocketManagerExtrasMap from './../services/configuration/socketManagerExtrasMap';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Socket, 'Socket', typeof SocketService> = {
    pluginName: 'Socket',
    entry: Socket,
    service: SocketService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;

        const { socketManager } = sdkSession.managers;

        sdkSession.socketApi = new SocketApi({
            socketManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const {
            managers,
            logger,
            services,
            bootstrapConfiguration,
            deviceAttributeProvider
        } = options;

        const { tokenManager, sessionManager, deviceManager } = managers;

        const socketManager = new SocketManager({
            config: services.socket,
            clientConfig: services.socket.client,
            tokenManager,
            sessionManager,
            logger,
            environmentConfiguration: deviceManager.environmentConfiguration,
            bootstrapConfiguration,
            deviceAttributeProvider
        });

        managers.socketManager = socketManager;
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    onSdkSessionCreated(options) {
        const { sdkSession, logger } = options;

        const { socketManager } = sdkSession.managers;

        socketManager.on(
            SocketEvents.tokenRefreshRequested,
            async (refreshData: TodoAny) => {
                const { reason } = refreshData;

                await sdkSession.reauthorize({ reason });
                logger.info(
                    sdkSession.toString(),
                    'Token refreshed due to web socket request.'
                );
            }
        );

        socketManager.on(
            SocketEvents.offDeviceTokenRefresh,
            (offDeviceTokenRefreshOptions: TodoAny) => {
                logger.info(
                    sdkSession.toString(),
                    'Dispatch OffDeviceTokenRefresh event.'
                );

                sdkSession.emit(
                    Events.OffDeviceTokenRefresh,
                    new OffDeviceTokenRefreshEvent(offDeviceTokenRefreshOptions)
                );
            }
        );
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { client: socketClient, extras: socketExtras } = services.socket;
        const { endpoints: socketClientEndpoints, extras: socketClientExtras } =
            socketClient;
        const {
            retryPolicy: socketAutoRefreshPolicy,
            messageDeduplicationStoreSize,
            pingPolicy,
            unacknowledgedEventBuffer
        } = socketExtras;

        const socketRetryPolicy = new RetryPolicy(
            socketAutoRefreshPolicy.retryBasePeriod,
            socketAutoRefreshPolicy.retryMultiplier,
            socketAutoRefreshPolicy.retryMaxAttempts,
            socketAutoRefreshPolicy.retryMaxPeriod
        );

        const clientConfiguration = new SocketClientConfiguration(
            socketClient.baseUrl,
            serviceDefinitionsConfiguration.processEndpoints(
                socketClientEndpoints
            ),
            new SocketClientExtrasMap({
                ...{ headers: serviceDefinitionsConfiguration.commonHeaders },
                ...socketClientExtras
            })
        );

        serviceDefinitionsConfiguration.socket = new SocketManagerConfiguration(
            clientConfiguration,
            new SocketManagerExtrasMap({
                retryPolicy: socketRetryPolicy,
                messageDeduplicationStoreSize,
                pingPolicy,
                unacknowledgedEventBuffer
            }),
            services.socket.disabled
        );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
