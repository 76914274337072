/**
 *
 * @module flowControlErrorMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import SocketEvents from './socketEvents';
import type { RawSocketMessage } from './socketEvent';
import getSafe from '../services/util/getSafe';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import type SocketManager from './socketManager';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.11.0
 * @desc Handles the flow control error socket message
 *
 */
export default class FlowControlErrorMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.11.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        const messageType = SocketUrns.socketManager.flowControlError;

        super(messageType);

        this.socketManager = socketManager;
    }

    /**
     *
     * @access protected
     * @since 4.11.0
     * @param {Object} messageData
     * @desc Emits the `flowControlError` event
     *
     */
    public process(messageData: RawSocketMessage) {
        const errors = getSafe(() => (messageData.data as TodoAny).errors);

        const exception = this.createServiceException(errors);

        this.socketManager.emit(SocketEvents.flowControlError, exception);
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.FlowControlErrorMessageProcessor';
    }
}
