/**
 *
 * @module externalActivationClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/external-activation.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import ActivationToken from './activationToken';
import TemporaryDefaultAccessActivationStatus from './temporaryDefaultAccessActivationStatus';
import ExternalActivationResult from './externalActivationResult';
import ExternalActivationClientEndpoint from './externalActivationClientEndpoint';
import ExternalActivationClientConfiguration from './externalActivationClientConfiguration';
import LogTransaction from '../../logging/logTransaction';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';

const ExternalActivationClientDustUrnReference =
    DustUrnReference.services.externalActivation.externalActivationClient;

/**
 *
 * @access protected
 *
 */
export default class ExternalActivationClient {
    /**
     *
     * @access private
     * @type {SDK.Services.ExternalActivation.ExternalActivationClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: ExternalActivationClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {CoreHttpClientProvider}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {String}
     * @desc Determines if you should refresh the access token.
     *
     */
    private readonly refreshAccessTokenHeader =
        'X-BAMTech-Refresh-Access-Token';

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {String}
     * @desc An indicator of whether or not temporary access has been granted in the meantime.
     *
     */
    private readonly temporaryAccessGrantedHeader =
        'X-BAMTech-Temporary-Access-Granted';

    /**
     *
     * @access private
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.ExternalActivation.ExternalActivationClientConfiguration} externalActivationClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        externalActivationClientConfiguration: ExternalActivationClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                externalActivationClientConfiguration: Types.instanceStrict(
                    ExternalActivationClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = externalActivationClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @since 4.2.0
     * @param {String} providerId - The provider the activation token was obtained from.
     * @param {String} activationTokenString - The activation token as a String.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Verifies the bundled purchase contained within the token with the
     * third-party service identified by `providerId`, and grants subscription + entitlements.
     * @note Send as 'linkToken' in the json payload.
     * @note This method should use the `activateBundle` endpoint.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the
     * ExternalActivationResult which can be inspected by the application developer to
     * verify temporaryAccessGranted & resultStatus.
     *
     */
    public async redeemBundle(
        providerId: string,
        activationTokenString: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<ExternalActivationResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                providerId: Types.nonEmptyString,
                activationTokenString: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'redeemBundle', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = ExternalActivationClientEndpoint.activateBundle;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                linkToken: activationTokenString
            },
            data: {
                providerId
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.redeemBundle,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return this.redeemResponseHandler(response, dustLogUtility);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @param {String} providerId - The provider the activation token was obtained from.
     * @param {String} activationTokenString - The activation token as a String.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Verifies the purchase contained within the token with the third-party service
     * identified by `providerId`, and grants subscription + entitlements.
     * @note Send as 'linkToken' in the json payload.
     * @note This method should use the `activateToken` endpoint.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the
     * ExternalActivationResult which can be inspected by the application developer to
     * verify temporaryAccessGranted & resultStatus.
     *
     */
    public async redeemToken(
        providerId: string,
        activationTokenString: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<ExternalActivationResult> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                providerId: Types.nonEmptyString,
                activationTokenString: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'redeemToken', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = ExternalActivationClientEndpoint.activateToken;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                linkToken: activationTokenString
            },
            data: {
                providerId
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.redeemToken,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return this.redeemResponseHandler(response, dustLogUtility);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @since 4.2.0
     * @param {String} providerId - The provider the redemption token will be redeemed with.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates a proof-of-purchase token for redemption with an external service.
     * @note Currently very Hulu specific (the endpoint is templated, however only the Hulu provider is supported).
     * @note This method will be deprecated post unification.
     * @note Uses the legacy service. Will be replaced by the generateActivationToken flow.
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} Returns an
     * Object containing the redemption token.
     *
     */
    public async createExternalRedemptionToken(
        providerId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<ActivationToken> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                providerId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createExternalRedemptionToken', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = ExternalActivationClientEndpoint.getActivationToken;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                providerId
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.createExternalRedemptionToken,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data = {} } = response;
                const { linkToken } = data;

                const activationToken = new ActivationToken(linkToken);

                return Promise.resolve(activationToken);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access protected
     * @since 14.1.0
     * @param {String} contentProvider - Content Provider of the entitlement -- who will provide the user with the content they purchased.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Generate a JWT activation token based on the user's entitlements. Authorized third party should be able to
     * validate this token and grant the user access to content.
     * @see https://github.bamtech.co/pages/activation/openapi-specs/content-provider-activation-api.html
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} Returns an
     * Object containing the activation token.
     *
     */
    public async generateActivationToken(
        contentProvider: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<ActivationToken> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                contentProvider: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'generateActivationToken', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey =
            ExternalActivationClientEndpoint.generateActivationToken;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                contentProvider
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.generateActivationToken,
            data: {
                contentProviderId: contentProvider
            },
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data = {} } = response;
                const { activationToken } = data;

                return new ActivationToken(activationToken);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @param {Object} response
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>}
     *
     */
    private async redeemResponseHandler(
        response: TodoAny,
        dustLogUtility: DustLogUtility
    ): Promise<ExternalActivationResult> {
        const { refreshAccessTokenHeader, temporaryAccessGrantedHeader } = this;
        const { headers, status, data } = response;
        const { errors } = data;

        const refreshAccessToken = !!headers.get(refreshAccessTokenHeader);
        const temporaryAccessGranted = !!headers.get(
            temporaryAccessGrantedHeader
        );

        let resultStatus: TemporaryDefaultAccessActivationStatus;

        const externalActivationResult = new ExternalActivationResult({
            refreshAccessToken,
            temporaryAccessGranted,
            errors
        });

        return checkResponseCode(response, dustLogUtility)
            .then(() => {
                switch (status) {
                    case 200:
                        resultStatus =
                            TemporaryDefaultAccessActivationStatus.activationSucceeded;
                        break;
                    case 202:
                        if (temporaryAccessGranted) {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationRetryingWithTda;
                        } else {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationRetryingWithoutTda;
                        }
                        break;
                }

                externalActivationResult.resultStatus = resultStatus;

                return externalActivationResult;
            })
            .catch((exception) => {
                switch (exception.status) {
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                    case 504:
                        if (temporaryAccessGranted) {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationFailedWithTda;
                        } else {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationFailedWithoutTda;
                        }
                        break;
                    default:
                        resultStatus = exception;
                }

                externalActivationResult.resultStatus = resultStatus;

                return externalActivationResult;
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.ExternalActivation.ExternalActivationClientEndpoint} options.endpointKey
     * @param {Object} [options.body]
     * @param {Object} [options.data]
     * @returns {Object} The payload for the client call.
     *
     */
    public getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof ExternalActivationClientEndpoint;
        body?: object;
        data?: {
            providerId?: string;
            contentProvider?: string;
        };
    }): GetPayloadResult {
        const { accessToken, endpointKey, body, data } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, headers } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        let url = href;

        if (endpoint.templated && data && data.providerId) {
            url = url.replace(/\{providerId\}/gi, data.providerId);
        }

        if (endpoint.templated && data && data.contentProvider) {
            url = url.replace(/\{contentProvider\}/gi, data.contentProvider);
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.ExternalActivation.ExternalActivationClient';
    }
}
