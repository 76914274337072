/**
 *
 * @module playbackAdEventData
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import MediaSegmentType from './mediaSegmentType';
import NetworkType from './networkType';
import PlaybackExitedCause from './playbackExitedCause';
import ProductType from './productType';
import ServerRequest from './serverRequest';

import { AdActivity, AdInsertionType } from './enums';

import { SubscriptionType } from './../media/enums';

import {
    AdAudioData,
    AdAudioDataTypedef,
    AdErrorData,
    AdErrorDataTypedef,
    AdPodData,
    AdPodDataTypedef,
    AdPodPlacement,
    AdPodPlacementTypedef,
    AdSlotData,
    AdSlotDataTypedef,
    AdStartupData,
    AdStartupDataTypedef,
    AdSubtitleData,
    AdSubtitleDataTypedef,
    AdVideoData,
    AdVideoDataTypedef
} from './typedefs';

interface PlaybackAdEventDataOptions {
    adActivity?: ValueOf<typeof AdActivity>;
    adSessionId?: string;
    adInsertionType?: ValueOf<typeof AdInsertionType>;
    subscriptionType?: ValueOf<typeof SubscriptionType> | string;
    serverRequest?: ServerRequest;
    mediaSegmentType?: ValueOf<typeof MediaSegmentType>;
    cause?: ValueOf<typeof PlaybackExitedCause>;
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
    adStartupData?: AdStartupData;
    adErrorData?: AdErrorData;
    playbackSessionId?: string;
    productType?: ValueOf<typeof ProductType>;
    networkType?: ValueOf<typeof NetworkType>;
    monotonicTimestamp?: number;
    contentKeys?: Record<string, unknown>;
    data?: Record<string, unknown>;
}

/**
 *
 * @since 19.0.0
 * @desc This event represents ad related activities that occur during ad-playback.
 * @note urn:dss:event:client:playback:ad:v1
 * @note This event should not be sent for playback sessions where `adInsertionType` == `none`.
 *
 */
export default class PlaybackAdEventData {
    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.AdActivity>|undefined}
     * @desc Used to identify what ad-related activity is occurring in playback.
     *
     */
    public adActivity?: ValueOf<typeof AdActivity>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Random UUID assigned by the ad server.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
     *
     */
    public adSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.AdInsertionType>|undefined}
     * @desc The way ads are inserted into the stream.
     * @note Source by converting the `Bam.Sdk.Media.MediaDescriptor.AssetInsertionStrategy`.
     *
     */
    public adInsertionType?: ValueOf<typeof AdInsertionType>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.Media.SubscriptionType>}
     * @desc An identifier that provides insight into the tier of service associated with the subscription that is entitled for playback.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.subscriptionType field.
     *
     */
    public subscriptionType?: ValueOf<typeof SubscriptionType> | string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.ServerRequest|undefined}
     * @desc Data about a request/response to a server.
     * @note Required if `adActivity` is `adPodFetched`, `adMultivariantFetched`, or `adVariantFetched`.
     * @note Source from the associated `Bam.Sdk.Media.AdPodFetchedEvent`, `Bam.Sdk.Media.AdMultivariantFetchedEvent` or `Bam.Sdk.Media.AdVariantFetchedEvent`.
     *
     */
    public serverRequest?: ServerRequest;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.MediaSegmentType>|undefined}
     * @desc What type of content a media segment contains.
     * @note Required if `adActivity` is `adVariantFetched`.
     * @note Source from the associated `Bam.Sdk.Media.AdVariantFetchedEvent`.
     *
     */
    public mediaSegmentType?: ValueOf<typeof MediaSegmentType>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.PlaybackExitedCause>|undefined}
     * @desc The reason for ending ad playback.
     * @note Required if `adActivity` is `adPlaybackEnded`.
     * @note Source from the associated `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public cause?: ValueOf<typeof PlaybackExitedCause>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>|undefined}
     * @desc Placement information relevant to ad pods.
     * @note Source from the associated `PlaybackEventListener` events.
     *
     */
    public adPodPlacement?: AdPodPlacement;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodData>|undefined}
     * @desc Metadata relevant to ad pods.
     * @note Required if `adActivity` is `adPodFetched`, `adMultivariantFetched`, `adVariantFetched`, `adPlaybackStarted`, or `adPlaybackEnded`.
     * @note Source from the associated `Bam.Sdk.Media.AdPodFetchedEvent`, `Bam.Sdk.Media.AdMultivariantFetchedEvent`,
     * `Bam.Sdk.Media.AdVariantFetchedEvent`, `Bam.Sdk.Media.AdPlaybackStartedEvent`, or `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public adPodData?: AdPodData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdSlotData>|undefined}
     * @desc Metadata relevant to ad slots.
     * @note Required if `adActivity` is `adMultivariantFetched`, `adVariantFetched`, `adPlaybackStarted`, or `adPlaybackEnded`.
     * @note Source from the associated `Bam.Sdk.Media.AdMultivariantFetchedEvent`, `Bam.Sdk.Media.AdVariantFetchedEvent`, `Bam.Sdk.Media.AdPlaybackStartedEvent`,
     * or `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public adSlotData?: AdSlotData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdVideoData>|undefined}
     * @desc Metadata relevant to the video in ads.
     * @note Required if `adInsertionType` is `sgai` and `adActivity` is `adPlaybackStarted` or `adPlaybackEnded`.
     * @note While not explicitly required, it's recommended that SDKs provide this field if `adActivity` is `adVariantFetched` and `mediaSegmentType` is `video`.
     * @note Source from the associated `Bam.Sdk.Media.AdPlaybackStartedEvent` or `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public adVideoData?: AdVideoData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdAudioData>|undefined}
     * @desc Metadata relevant to the audio in ads.
     * @note Required if `adInsertionType` is `sgai` and `adActivity` is `adPlaybackStarted` or `adPlaybackEnded`.
     * @note While not explicitly required, it's recommended that SDKs provide this field if `adActivity` is `adVariantFetched` and `mediaSegmentType` is `audio`.
     * @note Source from the associated `Bam.Sdk.Media.AdVariantFetchedEvent`, `Bam.Sdk.Media.AdPlaybackStartedEvent`, or `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public adAudioData?: AdAudioData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdSubtitleData>|undefined}
     * @desc Metadata relevant to the subtitles in ads.
     * @note Required if `adInsertionType` is `sgai` and `adActivity` is `adPlaybackStarted` or `adPlaybackEnded`.
     * @note While not explicitly required, it's recommended that SDKs provide this field if `adActivity` is `adVariantFetched` and `mediaSegmentType` is `subtitle`.
     * @note Source from the associated `Bam.Sdk.Media.AdVariantFetchedEvent`, `Bam.Sdk.Media.AdPlaybackStartedEvent`, or `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public adSubtitleData?: AdSubtitleData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdStartupData>|undefined}
     * @desc Metadata relevant to ad startup time.
     * @note Required if `adActivity` is `adPodFetched`, `adMultivariantFetched`, or `adVariantFetched`.
     *
     */
    public adStartupData?: AdStartupData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdErrorData>|undefined}
     * @desc Metadata relevant to ad-related errors.
     * @note While not explicitly required, it's recommended that SDKs provide this field when `adActivity` is `adPlaybackEnded` AND `cause` = `error`.
     * @note Note: Error information will still need to be captured on the `urn:dss:event:qoe:client:error:v1` event.
     * @note Source from the associated `Bam.Sdk.Media.AdPlaybackEndedEvent`.
     *
     */
    public adErrorData?: AdErrorData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Client generated ID of the stream/playback session.
     *
     */
    public playbackSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
     * @desc The Product type, Live or VOD.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     *
     */
    public productType?: ValueOf<typeof ProductType>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.NetworkType>|undefined}
     * @desc The type of network connection currently in use by the client.
     *
     */
    public networkType?: ValueOf<typeof NetworkType>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `SDK.MonotonicTimestampManager.getTimestamp()`.
     *
     */
    public monotonicTimestamp?: number;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public contentKeys: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object}
     * @desc Additional partner specific data provided by the `SDK.Logging.AnalyticsProvider` and `MediaApi.initializePlaybackContext`.
     * @note The properties provided in this should be flattened such that there is no `data` property but the information contained within it lives on the root of this class.
     *
     */
    public data: Record<string, unknown>;

    /**
     *
     * @param {Object} [options={}]
     * @param {String<SDK.Services.QualityOfService.AdActivity>} [options.adActivity]
     * @param {String} [options.adSessionId]
     * @param {String<SDK.Services.QualityOfService.AdInsertionType>} [options.adActivity]
     * @param {String<SDK.Services.Media.SubscriptionType>} [options.subscriptionType]
     * @param {SDK.Services.QualityOfService.ServerRequest} [options.serverRequest]
     * @param {String<SDK.Services.QualityOfService.MediaSegmentType>} [options.mediaSegmentType]
     * @param {String<SDK.Services.QualityOfService.PlaybackExitedCause>} [options.cause]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData>} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData>} [options.adSlotData]
     * @param {Object<SDK.Services.QualityOfService.AdVideoData>} [options.adVideoData]
     * @param {Object<SDK.Services.QualityOfService.AdAudioData>} [options.adAudioData]
     * @param {Object<SDK.Services.QualityOfService.AdSubtitleData>} [options.adSubtitleData]
     * @param {Object<SDK.Services.QualityOfService.AdStartupData>} [options.adStartupData]
     * @param {Object<SDK.Services.QualityOfService.AdErrorData>} [options.adErrorData]
     * @param {String} [options.playbackSessionId]
     * @param {String<SDK.Services.QualityOfService.ProductType>} [options.productType]
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType]
     * @param {Number} [options.monotonicTimestamp]
     * @param {Object} [options.contentKeys]
     * @param {Object} [options.data]
     *
     */
    public constructor(options: PlaybackAdEventDataOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    adActivity: Types.in(AdActivity).optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    subscriptionType: Types.string.optional,
                    serverRequest: Types.instanceStrict(ServerRequest).optional,
                    mediaSegmentType: Types.in(MediaSegmentType).optional,
                    cause: Types.in(PlaybackExitedCause).optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional,
                    adVideoData: Types.object(AdVideoDataTypedef).optional,
                    adAudioData: Types.object(AdAudioDataTypedef).optional,
                    adSubtitleData: Types.object(AdSubtitleDataTypedef)
                        .optional,
                    adStartupData: Types.object(AdStartupDataTypedef).optional,
                    adErrorData: Types.object(AdErrorDataTypedef).optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    networkType: Types.in(NetworkType).optional,
                    monotonicTimestamp: Types.number.optional,
                    contentKeys: Types.object().optional,
                    data: Types.object().optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            adActivity,
            adSessionId,
            adInsertionType,
            subscriptionType,
            serverRequest,
            mediaSegmentType,
            cause,
            adPodPlacement,
            adPodData,
            adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData,
            adStartupData,
            adErrorData,
            playbackSessionId,
            productType,
            networkType,
            monotonicTimestamp,
            contentKeys,
            data
        } = options || {};

        this.adActivity = adActivity;
        this.adSessionId = adSessionId;
        this.adInsertionType = adInsertionType;
        this.subscriptionType = subscriptionType || '';
        this.serverRequest = serverRequest;
        this.mediaSegmentType = mediaSegmentType;
        this.cause = cause;
        this.adPodPlacement = adPodPlacement;
        this.adPodData = adPodData;
        this.adSlotData = adSlotData;
        this.adVideoData = adVideoData;
        this.adAudioData = adAudioData;
        this.adSubtitleData = adSubtitleData;
        this.adStartupData = adStartupData;
        this.adErrorData = adErrorData;
        this.playbackSessionId = playbackSessionId;
        this.productType = productType;
        this.networkType = networkType || NetworkType.unknown;
        this.monotonicTimestamp = monotonicTimestamp;
        this.contentKeys = contentKeys || {};
        this.data = data || {};
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.PlaybackAdEventData';
    }

    // #endregion
}
