/**
 *
 * @module pvrTextureQuality
 *
 */

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.PVRTextureQuality
 * @desc Controls quality of image texture for variant images generated in PVR format. This parameter is applicable only when format parameter is set to PVR.
 *
 * @property {String} etcFast - Since `17.0.0`
 * @property {String} etcFastPerceptual - Since `17.0.0`
 * @property {String} etcSlow - Since `17.0.0`
 * @property {String} etcSlowPerceptual - Since `17.0.0`
 * @property {String} numETCModes - Since `17.0.0`
 *
 * @example
 * SDK.Ripcut.PVRTextureQuality.etcFast // 'ETCFast'
 *
 */
export default {
    etcFast: 'ETCFast',
    etcFastPerceptual: 'ETCFastPerceptual',
    etcSlow: 'ETCSlow',
    etcSlowPerceptual: 'ETCSlowPerceptual',
    numETCModes: 'NumETCModes'
};
