/**
 *
 * @module eventBuffer
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from './../../logging/logger';
import InternalEvents from './../../internalEvents';

import TelemetryEvent from './telemetryEvent';
import LogTransaction from '../../logging/logTransaction';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DiagnosticFeature from '../../diagnosticFeature';

import TokenManager from './../../token/tokenManager';
import TelemetryClient from './../../services/internal/telemetry/telemetryClient';
import TelemetryClientEndpoint from './../../services/internal/telemetry/telemetryClientEndpoint';
import TelemetryBufferConfiguration from './../../services/configuration/telemetryBufferConfiguration';

import ExceptionReference from './../../services/exception/exceptionReference';
import circularReplacer from './../../services/util/circularReplacer';

const DustUrn = DustUrnReference.services.internal.telemetry.eventBuffer;

/**
 *
 * @access private
 * @since 15.0.0
 * @param {Number} retryCount - The number of retries.
 * @param {Number} maxBackOffRetrySeconds - The max amount of time before allowing telemetry to be retried after a failure.
 * @desc Given a number of retries calculate when to retry again.
 * @returns {Number} a new replyAfter value to delay a next retry.
 *
 */
function retryBackOffFormula(retryCount, maxBackOffRetrySeconds) {
    const maxBackOff = maxBackOffRetrySeconds * 1000;

    return Math.min(2 ** retryCount + Math.random() * 5, maxBackOff);
}

/**
 *
 * @access protected
 * @since 3.1.0
 * @desc Provides a base class used for publishing and queueing Telemetry Events via a service client
 *
 */
export default class EventBuffer extends EventEmitter {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.bufferConfiguration
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Services.Internal.TelemetryClient} options.telemetryClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {Object} options.endpoint
     * @param {Object} options.prohibited
     * @param {Object} [options.fastTrack=null]
     * @param {SDK.DiagnosticFeature} [diagnosticFeature]
     *
     */
    constructor(options) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    tokenManager: Types.instanceStrict(TokenManager),
                    telemetryClient: Types.instanceStrict(TelemetryClient),
                    logger: Types.instanceStrict(Logger),
                    endpoint: Types.nonEmptyObject,
                    prohibited: Types.object({
                        urns: Types.array
                    }),
                    fastTrack: Types.object({
                        urns: Types.array.of.nonEmptyString
                    }).optional,
                    diagnosticFeature: Types.in(DiagnosticFeature).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            bufferConfiguration,
            tokenManager,
            telemetryClient,
            logger,
            endpoint,
            prohibited,
            diagnosticFeature,
            fastTrack = null
        } = options;

        /**
         *
         * @access private
         * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
         * @note if a `bufferConfiguration` isn't provided this will default to `bufferConfigurationDefault` in
         * `SDK.Internal.Telemetry.TelemetryManagerExtrasMap`
         * @note the spec states that this property should be an instance of `SDK.Configuration.ConfigurationProvider`
         *
         */
        this.config = bufferConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Token.TokenManager}
         *
         */
        this.tokenManager = tokenManager;

        /**
         *
         * @access private
         * @type {SDK.Services.Internal.Telemetry.TelemetryClient}
         *
         */
        this.client = telemetryClient;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {Object}
         *
         */
        this.endpoint = endpoint;

        /**
         *
         * @access private
         * @since 3.10.0
         * @type {Object}
         * @desc contains information related to fast tracking events
         *
         */
        this.fastTrack = fastTrack;

        /**
         *
         * @access private
         * @since 4.8.0
         * @type {Object}
         * @desc the filter for prohibited telemetry events.
         *
         */
        this.prohibited = prohibited;

        /**
         *
         * @access private
         * @type {Boolean}
         *
         */
        this.isScheduled = false;

        /**
         *
         * @access private
         * @type {Number}
         * @note defaults to `config.replyAfterFallback` until a `TelemetryResponse` is received and this value is updated
         *
         */
        this.replyAfter = this.config.replyAfterFallback;

        /**
         *
         * @access private
         * @type {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>}
         * @desc The manager will persist telemetry events in a queue prior to
         * forwarding to the client. This queue should survive an app restart.
         * this queue should be specific to this instances type - stream events or dust events
         * @todo set in Storage
         * @todo drain queue when authorization fails
         *
         */
        this.queue = [];

        /**
         *
         * @access private
         * @type {Boolean}
         * @desc if this is true then telemetry events will not be published
         *
         */
        this.disabled = this.config.disabled;

        /**
         *
         * @access protected
         * @since 14.0.0
         * @type {DiagnosticFeature|undefined}
         * @desc Determines which type of validation to perform when diagnostics is enabled for this event buffer.
         *
         */
        this.diagnosticFeature = diagnosticFeature;

        /**
         *
         * @access private
         * @since 4.11.0
         * @type {Boolean}
         * @desc Determines whether events will be validated. When enabled, validation errors will be surfaced through
         * the logging system and the events will not be recorded for analytics purposes.
         *
         */
        this.validateEvents = false;

        /**
         *
         * @access private
         * @since 16.0.0
         * @type {Boolean}
         * @desc Proxy of traffic through the normal DUST endpoint is to be prevented in publicly released applications.
         * @note Applications should only pass `useProxy=true` in dev builds.
         */
        this.useProxy = false;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {Number}
         * @desc The number of times a telemetry client send has happened
         *
         */
        this.retryCount = 0;

        this.tokenManager.on(
            InternalEvents.TokenRefreshFailed,
            (tokenRefreshFailed) => {
                // Avoid disabling sockets when we still have a valid anonymous token state
                if (
                    tokenRefreshFailed &&
                    tokenRefreshFailed.hasResetToAnonymousToken
                ) {
                    return;
                }

                this.disabled = true;
            }
        );

        this.tokenManager.on(InternalEvents.AccessChanged, () => {
            this.disabled = this.config.disabled;
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Internal.Telemetry.TelemetryEvent} telemetryEvent
     * @desc Post an event to the telemetry queue for upload to the ce-telemetry-service
     * @note `fastTrack` events need to be sent immediately
     *
     */
    postEvent(telemetryEvent) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                telemetryEvent: Types.instanceStrict(TelemetryEvent)
            };

            typecheck(this, 'postEvent', params, arguments);
        }

        if (this.disabled) {
            this.logger.warn(
                this.toString(),
                'Buffering is disabled, events will not be posted.'
            );

            return;
        }

        const telemetryPayload = telemetryEvent.getPayload();

        const event = telemetryPayload.client.event;
        const isFastTrackEvent = this.fastTrack.urns.includes(event);
        const isProhibited = this.prohibited.urns.includes(event);

        if (isProhibited) {
            this.logger.warn(
                this.toString(),
                `Event will not be posted because it is prohibited: ${event}`
            );

            return;
        }

        if (isFastTrackEvent) {
            this.logger.info(
                this.toString(),
                `Event added to beginning of queue: ${event}`
            );

            // calling async without await - it should not wait for processBatch - it's a fire-and-forget..
            this.processBatch(telemetryPayload);
        } else {
            this.queue.push(telemetryPayload);
            this.logger.info(
                this.toString(),
                `Event added to end of queue: ${event}`
            );

            if (this.config.queueLimit) {
                while (this.queue.length > this.config.queueLimit) {
                    this.queue.shift();
                }
            }

            this.scheduleNextBatch(
                this.replyAfter || this.config.replyAfterFallback
            );
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @desc Sends all events and then empties the queue.
     *
     */
    async drain() {
        if (this.queue.length === 0) {
            return;
        }

        const { accessToken, useProxy, queue: telemetryPayloads } = this;

        this.queue = [];
        this.clearSchedule();

        const telemetryResponse = await this.client.postDustEvents({
            accessToken,
            telemetryPayloads,
            useProxy
        });

        this.replyAfter =
            telemetryResponse.replyAfter || this.config.replyAfterFallback;

        this.scheduleNextBatch(this.replyAfter);
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Returns whether any type of validation is enabled for this `EventBuffer`'s `DiagnosticFeature`.
     * @returns {Boolean}
     *
     */
    isValidationEnabled() {
        return this.validateEvents || this.useProxy;
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @param {Boolean} useProxy - Proxy validation setting.
     * @desc Enables validation either via the direct validation endpoint or proxied through the normal endpoint.
     *
     */
    enableValidation(useProxy) {
        this.validateEvents = !useProxy;
        this.useProxy = useProxy;
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Disables validation.
     *
     */
    disableValidation() {
        this.validateEvents = false;
        this.useProxy = false;
    }

    // #region private

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    get accessToken() {
        return this.tokenManager.getAccessToken();
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Internal.Telemetry.TelemetryPayload} [fastTrackEvent]
     * @returns {Promise<Void>}
     * @note client returns {TelemetryResponse} with `replyAfter` and `requestId`
     * @note the config has a `replyAfterFallback` prop in case `replyAfter` returns null
     * @note `isFastTrack` event bypasses the timer and sends the `fastTrackEvent` immediately
     * @note only send events from the queue when `queue.length >= config.minimumBatchSize`
     * @note if there is service error the failed events are added to the beginning of the queue to be retried
     *
     */
    async processBatch(fastTrackEvent) {
        let telemetryPayloads;

        if (this.disabled) {
            this.clearSchedule();

            return;
        }

        try {
            this.retryCount++;

            if (Check.assigned(fastTrackEvent)) {
                telemetryPayloads = [fastTrackEvent];
            } else if (this.queue.length < this.config.minimumBatchSize) {
                this.clearSchedule();

                return;
            } else {
                telemetryPayloads = this.queue.splice(
                    0,
                    this.config.batchLimit
                );
            }

            this.logger.info(
                this.toString(),
                `Posting ${telemetryPayloads.length} events`
            );

            // refreshing the token
            await this.tokenManager.refreshAccessToken();

            const { accessToken, useProxy, validateEvents, diagnosticFeature } =
                this;

            let telemetryResponse;

            if (this.endpoint.rel === TelemetryClientEndpoint.postEvent) {
                telemetryResponse = LogTransaction.wrapLogTransaction({
                    file: this.toString(),
                    urn: DustUrn.processBatch,
                    logger: this.logger,
                    action: async (logTransaction) =>
                        await this.client.postEvents(
                            {
                                accessToken,
                                telemetryPayloads,
                                useProxy
                            },
                            logTransaction
                        )
                });
            } else {
                if (validateEvents) {
                    if (
                        diagnosticFeature ===
                        DiagnosticFeature.glimpseValidation
                    ) {
                        telemetryResponse =
                            await this.client.validateDustEvents(
                                accessToken,
                                telemetryPayloads
                            );
                    } else {
                        telemetryResponse = await this.client.validateQoeEvents(
                            accessToken,
                            telemetryPayloads
                        );
                    }
                } else {
                    telemetryResponse = await this.client.postDustEvents({
                        accessToken,
                        telemetryPayloads,
                        useProxy
                    });
                }
            }

            if (telemetryResponse.results) {
                try {
                    this.logger.diagnostics(
                        'TelemetryValidation',
                        JSON.stringify(
                            telemetryResponse,
                            circularReplacer(),
                            '  '
                        )
                    );
                } catch (ex) {
                    this.logger.diagnostics(
                        'TelemetryValidation',
                        `Could not stringify the validation response: ${ex}`
                    );
                }

                this.emit(InternalEvents.ValidationResultsReceived, {
                    telemetryPayloads,
                    telemetryResponse
                });
            }

            this.retryCount = 0;

            this.replyAfter =
                telemetryResponse.replyAfter || this.config.replyAfterFallback;

            this.clearSchedule();
            this.scheduleNextBatch(this.replyAfter);
        } catch (ex) {
            const { status, data: { name: exceptionName } = {} } = ex;

            if (
                status < 400 ||
                status >= 500 ||
                exceptionName === ExceptionReference.common.network
            ) {
                this.queue.unshift(...telemetryPayloads);

                this.replyAfter = retryBackOffFormula(
                    this.retryCount,
                    this.config.maxBackOffRetrySeconds
                );

                this.clearSchedule();
                this.scheduleNextBatch(this.replyAfter);
            }

            this.logger.warn(this.toString(), ex);
        }
    }

    /**
     *
     * @access private
     * @param {Number} replyAfter - Seconds to wait before processing batch.
     * @desc The client may return a replyAfter value in its response. If so,
     * the manager should note the time and not send any additional
     * events until after the specified wait period. If the client fails, the manager
     * should retry at a later time using the replyAfter value if available, or a configured value
     * if the replyAfter value is not available.
     * @note If the application restarts, the telemetry manager should send any queued events.
     *
     */
    scheduleNextBatch(replyAfter) {
        // check for a current replyAfter timer, if already set then bail
        if (this.isScheduled) {
            return;
        }

        this.isScheduled = true;

        this.nextBatchTimeoutId = setTimeout(
            this.processBatch.bind(this),
            replyAfter * 1000
        );
    }

    /**
     *
     * @access private
     * @desc Clears the last scheduled batch
     *
     */
    clearSchedule() {
        this.isScheduled = false;
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @desc Cleans up the `EventBuffer`.
     *
     */
    dispose() {
        if (process.env.NODE_ENV === 'test') {
            this.disabled = true;
        }

        clearTimeout(this.nextBatchTimeoutId);
        this.clearSchedule();

        // should clear on dispose
        this.queue = [];
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Internal.Telemetry.EventBuffer';
    }

    // #endregion
}
