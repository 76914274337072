/**
 *
 * @module getSafe
 * @see https://github.com/tc39/proposal-optional-chaining
 *
 */

/**
 *
 * @param {Function} fn - function executed inside try...catch block
 * @param {*} [defaultValue] - default value to use
 * @desc Little utility that helps avoid common `TypeError` and `ReferenceError` scenarios
 * and extraneous boilerplate code. Allows you to fetch deep properties in a single line of code.
 * @returns {*} result of `fn` call or provided `defaultValue`
 *
 * @example getSafe(() => access.context.token)
 * @example getSafe(() => account.client.base.url, 'some default value')
 * @example getSafe(() => 1 < 2 && access.context.token, 'some default value')
 *
 */

function getSafe<TFn>(fn: () => TFn): TFn;
function getSafe<TFn>(
    fn: () => TFn,
    defaultValue?: TFn
): Exclude<TFn, undefined>;
function getSafe<TFn>(fn: () => TFn, defaultValue?: TFn) {
    try {
        const value = fn();

        return value !== void 0 ? value : defaultValue;
    } catch (error) {
        return defaultValue;
    }
}

export default getSafe;
