/**
 *
 * @module playerAdapter
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import DrmProvider from '../drm/drmProvider';

import Logger from '../logging/logger';
import TokenRefreshFailure from '../token/tokenRefreshFailure';

import PlaybackMetricsProvider from './playbackMetricsProvider';
import Playlist from './playlist';

/**
 *
 * @abstract
 * @desc Interface used to communicate with the media player.
 *
 */
export default class PlayerAdapter extends PlaybackMetricsProvider {
    /**
     *
     * @access private
     * @type {NativePlayer}
     * @since 2.0.0
     *
     */
    public nativePlayer: TodoAny;

    /**
     *
     * @access private
     * @type {SDK.Media.PlaybackEventListener}
     * @since 2.0.0
     *
     */
    public listener?: TodoAny;

    /**
     *
     * @access public
     * @type {SDK.Drm.DrmProvider}
     * @since 3.2.0
     *
     */
    public drmProvider?: Nullable<DrmProvider>;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public accessToken?: Nullable<string>;

    /**
     *
     * @access private
     * @type {Object}
     * @since 2.0.0
     *
     */
    protected boundHandlers: TodoAny;

    /**
     *
     * @access private
     * @type {Number}
     * @since 2.0.0
     * @desc Value provided if the metric (playhead or bitrate) is not available.
     *
     */
    private unavailableMetric?: Nullable<number>;

    /**
     *
     * @access private
     * @type {Object}
     * @since 3.6.0
     * @desc used to store adEngine data which is populated from the playlist service in playerAdapter.prepare()
     *
     */
    protected adEngineData: TodoAny;

    /**
     *
     * @access private
     * @type {Object}
     * @since 7.0.0
     * @desc used to determine cdnFallback settings
     *
     */
    public cdnFallback: TodoAny;

    /**
     *
     * @access private
     * @since 15.2.0
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     *
     */
    public constructor(options: {
        nativePlayer: TodoAny;
        videoPlayerName: string;
        videoPlayerVersion: string;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.assigned,
                    videoPlayerName: Types.nonEmptyString,
                    videoPlayerVersion: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { nativePlayer, videoPlayerName, videoPlayerVersion } = options;

        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.nativePlayer = nativePlayer;
        this.listener = null;
        this.drmProvider = null;
        this.accessToken = null;
        this.boundHandlers = {};
        this.unavailableMetric = null;
        this.adEngineData = {};
        this.cdnFallback = {};
        this.logger = Logger.instance;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setSource(playlist: Playlist): Promise<void> {
        return Promise.reject(
            new Error(
                `${this.toString()}.setSource(playlist) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setDrmProvider(drmProvider?: DrmProvider): Promise<void> {
        return Promise.reject(
            new Error(
                `${this.toString()}.setDrmProvider(drmProvider) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @param {String} accessToken
     * @desc Notifies the player adapter that the access token has been updated.
     * Used for key service authentication.
     * @returns {Promise<Void>}
     *
     */
    public onAccessChanged(accessToken: string): Promise<void> {
        if (Check.string(accessToken) && Check.nonEmptyString(accessToken)) {
            this.accessToken = accessToken;
        }

        return Promise.resolve();
    }

    /**
     *
     * @access public
     * @param {SDK.Token.TokenRefreshFailure} tokenRefreshFailure
     * @desc Notifies the application that the token refresh has failed.
     * Key decryption will fail on next attempt.
     * @returns {Promise<SDK.Token.TokenRefreshFailure>}
     *
     */
    public onAccessFailed(tokenRefreshFailure: TokenRefreshFailure) {
        return Promise.resolve(tokenRefreshFailure);
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public clean(): void {
        throw new Error(`${this.toString()}.clean() - not-implemented`);
    }

    /**
     *
     * @access private
     * @param {Object} adEngineData
     * @desc overwrites local adEngine object in playbackSession.prepare()
     * @note used in xhrCallbacks for bam-hls and other platforms that need to reuse adEngine data
     *
     */
    public setAdEngineData(adEngineData: TodoAny): void {
        this.adEngineData = adEngineData;
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackStartedEventData} args
     *
     */
    public onPlaybackStarted(args: TodoAny): void {
        const { listener } = this;

        listener.onPlaybackStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackPausedEventData} args
     *
     */
    public onPlaybackPaused(args: TodoAny): void {
        const { listener } = this;

        listener.onPlaybackPaused(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackResumedEventData} args
     *
     */
    public onPlaybackResumed(args: TodoAny): void {
        const { listener } = this;

        listener.onPlaybackResumed(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.RebufferingStartedEventData} args
     *
     */
    public onRebufferingStarted(args: TodoAny): void {
        const { listener } = this;

        listener.onRebufferingStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.RebufferingEndedEventData} args
     *
     */
    public onRebufferingEnded(args: TodoAny): void {
        const { listener } = this;

        listener.onRebufferingEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackEndedEventData} args
     *
     */
    public onPlaybackEnded(args: TodoAny): void {
        const { listener } = this;

        listener.onPlaybackEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackInitializedEventData} args
     *
     */
    public onPlaybackInitialized(args: TodoAny): void {
        const { listener } = this;

        listener.onPlaybackInitialized(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackReadyEventData} args
     *
     */
    public onPlaybackReady(args: TodoAny): void {
        const { listener } = this;

        listener.onPlaybackReady(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Media.PlaybackSeekStartedEvent} args
     *
     */
    public onPlaybackSeekStarted(args: TodoAny): void {
        this.listener.onPlaybackSeekStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Media.PlaybackSeekEndedEvent} args
     *
     */
    public onPlaybackSeekEnded(args: TodoAny): void {
        this.listener.onPlaybackSeekEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.AudioChangedEventData} args
     *
     */
    public onAudioChanged(args: TodoAny): void {
        const { listener } = this;

        listener.onAudioChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.SubtitleChangedEventData} args
     *
     */
    public onSubtitleChanged(args: TodoAny): void {
        const { listener } = this;

        listener.onSubtitleChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.BitrateChangedEventData} args
     *
     */
    public onBitrateChanged(args: TodoAny): void {
        const { listener } = this;

        listener.onBitrateChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Services.QualityOfService.MultivariantPlaylistFetchedEventData} args
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    public onMultivariantPlaylistFetched(args: TodoAny): void {
        const { listener } = this;

        listener.onMultivariantPlaylistFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.VariantPlaylistFetchedEventData} args
     *
     */
    public onVariantPlaylistFetched(args: TodoAny): void {
        const { listener } = this;

        listener.onVariantPlaylistFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.MediaSegmentFetchedEventData} args
     *
     */
    public onMediaSegmentFetched(args: TodoAny): void {
        this.listener.onMediaSegmentFetched(args);
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {SDK.Services.QualityOfService.DrmKeyFetchedEventData} args
     *
     */
    public onDrmKeyFetched(args: TodoAny): void {
        this.listener.onDrmKeyFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {Object} args
     *
     */
    public onSuccessfulPlaylistLoad(args: TodoAny): void {
        this.listener.onSuccessfulPlaylistLoad(args);
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} args
     *
     */
    public onPlaybackReattempt(args: TodoAny): void {
        this.listener.onPlaybackReattempt(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} args
     *
     */
    public onAdBeaconError(args: TodoAny): void {
        this.listener.onAdBeaconError(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} args
     *
     */
    public onAdRequestedError(args: TodoAny): void {
        this.listener.onAdRequestedError(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPodRequested(args: TodoAny): void {
        this.listener.onAdPodRequested(args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPodFetched(args: TodoAny): void {
        this.listener.onAdPodFetched(args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdMultivariantFetched(args: TodoAny): void {
        this.listener.onAdMultivariantFetched(args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPlaybackStarted(args: TodoAny): void {
        this.listener.onAdPlaybackStarted(args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPlaybackEnded(args: TodoAny): void {
        this.listener.onAdPlaybackEnded(args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onPresentationTypeChanged(args: TodoAny): void {
        this.listener.onPresentationTypeChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdVariantFetched(args: TodoAny): void {
        this.listener.onAdVariantFetched(args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} args
     *
     */
    public onPlaybackError(args: TodoAny): void {
        this.listener.onPlaybackError(this, args);
    }

    /**
     *
     * @access protected
     * @desc Normalizes playhead to ensure it is not negative or NaN
     * @returns {Number|null}
     *
     */
    public normalizePlayhead(playhead: TodoAny): Nullable<number> {
        return playhead >= 0 ? playhead : this.unavailableMetric;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter';
    }
}
