/**
 *
 * @module personalizationEvents
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/user-activity.md#known-events
 *
 */

import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import PersonalizationEvent from './personalizationEvent';

const PersonalizationDustUrnReference =
    DustUrnReference.userActivity.personalization;

/**
 *
 * @access public
 * @type {Object<String, SDK.UserActivity.PersonalizationEvent>})
 * @note the IIFE is required as the application developer will not be expecting to invoke this method
 *
 */
export default {
    contentRecommendationAward: (() =>
        new PersonalizationEvent(
            PersonalizationDustUrnReference.contentRecommendationAward
        ))()
};
