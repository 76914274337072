/**
 *
 * @module logEvent
 *
 */

import { InterimLogEventExtraData } from '../services/internal/dust/dustLogUtility';
import LogLevel from './logLevel';

/**
 *
 * @desc Represents an event that can be logged by the Logger to its registered ILogSink instances.
 *
 */
export default class LogEvent {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public source: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public message: string;

    /**
     *
     * @access public
     * @type {Boolean}
     *
     */
    public isPublic: boolean;

    /**
     *
     * @access private
     * @type {LogLevel}
     *
     */
    public level: LogLevel;

    /**
     *
     * @access public
     * @type {Object<String, JSONSerializable>}
     *
     */
    public extraData: {
        isEdge?: boolean;
    };

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {String} options.source - The source object that generated the event.
     * @param {String} options.message - The message describing the event.
     * @param {Boolean} options.isPublic - A value indicating whether or not the event is publicly visible.
     * @param {LogLevel} options.level - The severity level of the event.
     * @param {Object<String, JSONSerializable>} [options.extraData={}] - The dictionary containing any
     * extra data that should be logged with the event.
     *
     */
    public constructor(options: {
        source: string;
        message: string;
        isPublic: boolean;
        level: LogLevel;
        extraData?: object;
    }) {
        const { isPublic, level, source, message, extraData } = options;

        this.isPublic = isPublic;
        this.level = level;
        this.source = source;
        this.message = message;
        this.extraData = extraData || {};
    }

    public static getExtraData(rawData?: object) {
        const data: { extraData?: object } = rawData || {};
        const extraData = data.extraData || {};

        return extraData as InterimLogEventExtraData;
    }

    /**
     *
     * @access private
     * @returns {String}
     *
     */
    public toJSON() {
        return JSON.stringify({
            isPublic: this.isPublic,
            level: LogLevel[this.level],
            source: this.source,
            message: this.message,
            extraData: this.extraData
        });
    }

    /**
     *
     * @access private
     * @returns {String}
     *
     */
    public toString() {
        return `(${LogLevel[this.level]}): ${this.source} - ${this.message}`;
    }
}
