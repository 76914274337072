/**
 *
 * @module exchangeDeviceGrantForAccessTokenRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';

/**
 *
 * @since 10.0.0
 *
 */
export default class ExchangeDeviceGrantForAccessTokenRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: { deviceGrant: string; huluUserToken?: string }) {
        const query = ExchangeDeviceGrantForAccessTokenRequest.query;

        const variables = {
            input
        };

        super({
            query,
            variables,
            operationName: 'exchangeDeviceGrantForAccessToken'
        });
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/orchestration_graphql/query/exchangeDeviceGrantForAccessToken.md
     * @returns {String}
     *
     */
    public static get query() {
        return `mutation exchangeDeviceGrantForAccessToken($input: ExchangeDeviceGrantForAccessTokenInput!) {
            exchangeDeviceGrantForAccessToken(exchangeDeviceGrantForAccessToken: $input) {
                accepted
            }
        }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.ExchangeDeviceGrantForAccessTokenRequest';
    }
}
