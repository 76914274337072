/**
 *
 * @module scalingAlgorithm
 *
 */

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.ScalingAlgorithm
 * @desc Which alogirthm to use for scaling.
 *
 * @property {String} bilinear - Since `17.0.0`
 * @property {String} bicubic - Since `17.0.0`
 * @property {String} bspline - Since `17.0.0`
 * @property {String} lanczos3 - Since `17.0.0`
 * @property {String} nearestNeighbor - Since `17.0.0`
 *
 * @example
 * SDK.Ripcut.ScalingAlgorithm.bilinear // 'bilinear'
 *
 */
export default {
    bilinear: 'bilinear',
    bicubic: 'bicubic',
    bspline: 'bspline',
    lanczos3: 'lanczos3',
    nearestNeighbor: 'nearest-neighbor'
};
