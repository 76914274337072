/**
 *
 * @module paywallApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/paywall.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import BaseApi from '../baseApi';

import PaywallManager from './paywallManager';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import TokenManager from '../token/tokenManager';
import Logger from '../logging/logger';
import Paywall from '../services/paywall/paywall';
import PurchaseContext from '../services/orchestration/purchaseContext';

const DustUrn = DustUrnReference.paywall.paywallApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 3.9.0
 * @desc Provides available product information and account context.
 *
 */
export default class PaywallApi extends BaseApi {
    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Paywall.PaywallManager}
     *
     */
    private paywallManager: PaywallManager;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Paywall.PaywallManager} options.paywallManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        paywallManager: PaywallManager;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    paywallManager: Types.instanceStrict(PaywallManager),
                    tokenManager: Types.instanceStrict(TokenManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { paywallManager, tokenManager } = options;

        this.paywallManager = paywallManager;

        this.tokenManager = tokenManager;

        this.dustEnabled = getSafe(
            () => this.paywallManager.client.dustEnabled,
            false
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 3.9.0
     * @param {String} [purchaseContextId] - A context ID used by paywall to lookup the correct SKU.
     * @note purchaseContextId is required when purchaseContext is also passed in.
     * @param {String<SDK.Services.Paywall.PurchaseContext>} [purchaseContext] - Purchase context to identify the page this request originated from.
     * @note PurchaseContext is a flexible enum.
     * @desc Returns a paywall object containing sku, campaign and account context information for a given country, platform and account/device.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Paywall.Paywall>}
     *
     */
    public async getPaywall(
        purchaseContextId?: string,
        purchaseContext?: OpenEnumOf<typeof PurchaseContext>
    ): Promise<Paywall>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            purchaseContextId: Types.nonEmptyString.optional
            //purchaseContext: Types.in(PurchaseContext).or.nonEmptyString.optional
        }
    })
    public async getPaywall(
        apiOptions?: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<Paywall> {
        const {
            logTransaction,
            args: [purchaseContextId, purchaseContext]
        } = apiOptions as ApiOptions;

        return await this.paywallManager.getPaywall(
            purchaseContextId,
            purchaseContext,
            logTransaction
        );
    }

    /**
     *
     * @access public
     *
     */
    public override toString() {
        return 'SDK.Paywall.PaywallApi';
    }
}
