/**
 *
 * @module subtitleRendition
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Describes a subtitle track contained in the stream.
 * @since 4.0.0
 *
 */
export default class SubtitleRendition {
    /**
     *
     * @param {Object} [options]
     * @param {String} [options.name]
     * @param {String} [options.language]
     * @param {Boolean} [options.forced]
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    name: Types.nonEmptyString.optional,
                    language: Types.nonEmptyString.optional,
                    forced: Types.boolean.optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const { name, language, forced } = options || {};

        /**
         *
         * @access public
         * @type {String}
         * @desc The name of the subtitle track.
         *
         */
        this.name = name;

        /**
         *
         * @access public
         * @type {String}
         * @desc The language of the subtitle track.
         *
         */
        this.language = language;

        /**
         *
         * @access public
         * @type {Boolean}
         * @desc Indicates if the subtitle track should be forced to be shown.
         *
         */
        this.forced = forced;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.SubtitleRendition';
    }

    // #endregion
}
