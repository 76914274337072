/**
 *
 * @module edgeSink
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#edge-logging
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#sdk-instance-id
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../../logging/logger';

import SocketApi from './../../socket/socketApi';

import EdgeEvent from './edgeEvent';

import LogSink from '../../logging/logSink';
import LogEvent from '../../logging/logEvent';

/**
 *
 * @desc Sink implementation that logs to DUST Service via `SocketApi.postEdgeEvent`.
 *
 */
export default class EdgeSink extends LogSink {
    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Socket.SocketApi}
     *
     */
    private socketApi: Nullable<SocketApi>;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc Returns `true` when `SDK.Internal.Telemetry.TelemetryManager.EventBuffer` is ready for use.
     *
     */
    private eventBufferReady: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc Determines if dust logs are to be considered for queuing or sending to the Socket service.
     * @note The isEnabled boolean will discard all events if false (enabled by default).
     *
     */
    public isEnabled: boolean;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     *
     */
    public initialized: boolean;

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(logger: Logger) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger)
            };

            typecheck(this, params, arguments);
        }

        this.socketApi = null;
        this.eventBufferReady = false;
        this.isEnabled = true;
        this.logger = logger;
        this.initialized = false;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @param {Object} event - The event to be logged.
     * @desc receives an event from logger.log and creates a dust event and posts it to the socket.
     * @note `event.isPublic` indicates this is a `Dust-like` event and extraData.isEdge indicates that
     * it is intended to be sent to the socket, i.e. not a `StreamSample` event
     * @note if `isEnabled` is false, the event is ignored and not processed further.
     * @returns {Promise<Void>}
     *
     */
    public log(event: LogEvent) {
        const extraData = LogEvent.getExtraData(event);

        if (this.isEnabled) {
            if (event.isPublic && extraData.isEdge) {
                this.postEvent(event);
            }
        }

        return Promise.resolve();
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @desc Enables the `EdgeSink`.
     * @note called from `SdkSession.enableDustSink`
     *
     */
    public enableEdgeSink() {
        this.isEnabled = true;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @desc Disables the DUST sink.
     * @note called from `SdkSession.createSdkSession`
     * @note this disables the `EdgeSink` for the remainder of the SDK lifecycle
     *
     */
    public disableEdgeSink() {
        this.isEnabled = false;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @param {Object} options
     * @param {SocketApi} options.socketApi
     * @note this method must be called before `this.enableDustSink`
     *
     */
    public initializeEdgeSink(options: { socketApi: SocketApi }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    socketApi: Types.instanceStrict(SocketApi)
                })
            };

            typecheck(this, 'initializeEdgeSink', params, arguments);
        }

        const { socketApi } = options;

        this.socketApi = socketApi;
        this.initialized = true;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} event
     * @desc creates an instance of `SDK.Internal.Dust.EdgeEvent`
     * @returns {SDK.Internal.Dust.EdgeEvent}
     *
     */
    public createEdgeEvent(event: LogEvent) {
        const {
            urn,
            file,
            line,
            serviceRequests,
            startTime,
            totalDuration,
            error,
            sdkInstanceId
        } = LogEvent.getExtraData(event);

        return new EdgeEvent({
            invocation: {
                urn,
                location: {
                    file,
                    line
                }
            },
            startTime,
            totalDuration,
            services: serviceRequests,
            error,
            sdkInstanceId
        });
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} event - The event to be posted.
     *
     */
    private postEvent(event: LogEvent) {
        try {
            const { socketApi } = this;

            const edgeEvent = this.createEdgeEvent(event);

            socketApi?.postEdgeEvent(edgeEvent);
        } catch (ex) {
            this.logger.warn(
                this.toString(),
                `Error creating SDK.Internal.Dust.EdgeEvent ${
                    (ex as Error).message
                }`
            );
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Dust.EdgeSink';
    }
}
