/**
 *
 * @module tokenManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import RetryPolicy from './retryPolicy';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class TokenManagerExtrasMap {
    /**
     *
     * @param {Object} options
     * @param {Number} [options.refreshThreshold=0.9]
     * @param {RetryPolicy} options.autoRefreshRetryPolicy
     * @param {Object} [options.subjectTokenTypes={...}]
     * @param {Boolean} [options.disableTokenRefresh=false]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    refreshThreshold: Types.number,
                    autoRefreshRetryPolicy: Types.instanceStrict(RetryPolicy),
                    subjectTokenTypes: Types.nonEmptyObject,
                    disableTokenRefresh: Types.boolean
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            refreshThreshold,
            autoRefreshRetryPolicy,
            subjectTokenTypes,
            disableTokenRefresh
        } = options || {};

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets a value that indicates when the access context should be refreshed.
         *
         */
        this.refreshThreshold = refreshThreshold || 0.9;

        /**
         *
         * @access public
         * @type {RetryPolicy}
         * @desc Gets or sets the retry policy to help drive the retry behavior for service failures.
         *
         */
        this.autoRefreshRetryPolicy = autoRefreshRetryPolicy;

        /**
         *
         * @access public
         * @type {Object}
         * @desc Gets or sets the `subject_token_type` values to use with each grant type.
         *
         */
        this.subjectTokenTypes =
            subjectTokenTypes || this.getDefaultSubjectTokenTypes();

        /**
         *
         * @access public
         * @since 16.0.0
         * @type {Boolean}
         * @desc Disables token refresh timer
         *
         */
        this.disableTokenRefresh = disableTokenRefresh || false;
    }

    // #region private

    /**
     *
     * @access private
     * @desc Default subjectTokenTypes, act as a fallback for the configuration values.
     * @returns {Object}
     *
     */
    getDefaultSubjectTokenTypes() {
        return {
            device: 'urn:bamtech:params:oauth:token-type:device',
            account: 'urn:bamtech:params:oauth:token-type:account',
            temporary: 'urn:ietf:params:oauth:token-type:jwt'
        };
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.TokenManagerExtrasMap';
    }

    // #endregion
}
