/**
 *
 * @module playbackPausedCause
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 5.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackPausedCause
 * @desc Reason playback paused.
 *
 * @property {String} user - Since `5.0.0` - If the user pressed pause.
 * @property {String} stall - Since `5.0.0` - If the player paused due to insufficient buffer.
 * @property {String} applicationBackgrounded - Since `5.0.0` - When the application was sent to background during playback.
 * Note if playback continues in PiP, it is not considered backgrounded.
 *
 */
export default keyMirror({
    user: null,
    stall: null,
    applicationBackgrounded: null
});
