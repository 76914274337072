/**
 *
 * @module subscriptionCancellation
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    SubscriptionCancellationReason,
    SubscriptionCancellationStatus
} from './enums';

/**
 *
 * @since 4.7.0
 * @desc Represents the reason and status for a subscription cancellation.
 *
 */
export default class SubscriptionCancellation {
    /**
     *
     * @access public
     * @since 4.7.0
     * @type {String<SDK.Services.Subscription.SubscriptionCancellationReason>}
     * @desc Gets the reason of the subscription cancellation.
     *
     */
    public reason: ValueOf<typeof SubscriptionCancellationReason>;

    /**
     *
     * @access public
     * @since 4.7.0
     * @type {String<SDK.Services.Subscription.SubscriptionCancellationStatus>}
     * @desc Gets the status of the subscription cancellation.
     *
     */
    public status: ValueOf<typeof SubscriptionCancellationStatus>;

    /**
     *
     * @param {Object} options
     * @param {String} options.reason
     * @param {String} [options.status]
     *
     */
    public constructor(options: { reason: string; status?: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    reason: Types.nonEmptyString,
                    status: Types.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { reason, status } = options;

        const reasonKey = Object.keys(SubscriptionCancellationReason).find(
            (key) =>
                SubscriptionCancellationReason[
                    key as keyof typeof SubscriptionCancellationReason
                ] === reason
        );

        const statusKey = Object.keys(SubscriptionCancellationStatus).find(
            (key) =>
                SubscriptionCancellationStatus[
                    key as keyof typeof SubscriptionCancellationStatus
                ] === status
        );

        this.reason = reasonKey
            ? SubscriptionCancellationReason[
                  reasonKey as keyof typeof SubscriptionCancellationReason
              ]
            : SubscriptionCancellationReason.other;

        this.status = statusKey
            ? SubscriptionCancellationStatus[
                  statusKey as keyof typeof SubscriptionCancellationStatus
              ]
            : SubscriptionCancellationStatus.other;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Subscription.SubscriptionCancellation';
    }
}
