/**
 *
 * @module device.regexs
 *
 */

/* eslint-disable prefer-template */

const OPEN = '(';
const CLOSE = ')';
const CASE = 'i';
const PIPE = '|';
const OTHER = 'desktop';
const VERSION = 'version';

/**
 *
 * OS / Device
 *
 */
const IOS = '(ip[honead]+)(?:.*os\\s([\\w]+)*\\slike\\smac|;\\sopera)';
const BB = '\\((bb)(10);|(blackberry)\\w*\\/?([\\w\\.]+)*|Rim';
const ANDROID = '(android)[\\/\\s-]?([\\w\\.]+)*';
const WINDOWS =
    '(windows\\sphone(?:\\sos)*|windows\\smobile|windows)[\\s\\/]?([ntce\\d\\.\\s]+\\w)';
const LINUX =
    '(Linux)(?!.*(?:Android|Tizen|CrKey|Tivo|Web[0|O]S))[\\/\\s]([\\w\\.]+)';
const MAC = '(mac\\sos\\sx)\\s?([\\w\\s\\.]+\\w)*';
const CHROMEBOOK = '(cros)\\s[\\w]+\\s([\\w\\.]+\\w)';
const WINDOWS_PHONE = 'Trident(?:.*?Touch(?:.*?Mobile))';
const WINDOWS_PHONE_DS = 'WPDesktop'; // Windows Phone desktop mode
const WINDOWS_PHONE_OLD = 'windows phone';
const CHROMECAST = '(CrKey)[\\/\\s]([\\w\\.]+)';
//const HTC = '(htc)[;_\\s-]+([\\w\\s]+(?=\\))|\\w+)*';
const KINDLE = '(kf[A-z]+)\\sbuild\\/[\\w\\.]+.*silk\\/';
const TESLA = '(Tesla|QtCarBrowser)';
const NETCAST = '(NetCast)[\\/\\s]([\\w\\.]+)';
const PANASONICTV = '(Viera)[\\/\\s]([\\w\\.]+)';
const SONYBRAVIA = '(SonyCEBrowser)[\\/\\s]([\\w\\.]+)';
const TIZEN = '(Tizen)[\\/\\s]([\\w\\.]+)';
const WEBOS = '(Web[0|O]S)(?!Linux)[\\/\\s-]?([\\w\\.]+)*';

/**
 *
 * Form Factor
 *
 */
const DESKTOP =
    OPEN +
    '?:' +
    [MAC, WINDOWS, LINUX, CHROMEBOOK].join(PIPE) +
    '(?!.*(Tizen|SMART-TV|Tivo|Web[0|O]S))' +
    CLOSE;

const FORMFACTORS = {
    desktop: 'desktop',
    other: OTHER,
    phone: 'phone',
    tablet: 'tablet',
    smarttv: 'smarttv',
    connecteddevice: 'connecteddevice'
};

/**
 *
 * @function re
 * @param {String} pattern
 * @param {String} [flags]
 * @returns {RegExp}
 *
 */
function re(pattern, flags) {
    return new RegExp(pattern, flags || CASE);
}

/**
 *
 * @type {Array}
 * @desc Array of supported devices regular expressions.
 * The order of regular expressions is important.
 * They should go from most specific to least specific. First one that hits, wins.
 *
 */
const devices = [
    {
        family: 'microsoft',
        regExes: [
            re('(edg/)'), // chromium edge
            re('[\\s\\(;](xbox(?:\\sone)?)[\\s\\);]'), // xbox
            re(
                '(windows\\sphone|windows\\smobile|windows)(?!.*(?:xbox|WPDesktop))'
            ), // windows or windows phone
            re('(?:windows).+(WPDesktop)') // windows phone desktop mode
        ],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            const windowsList = [
                'windows',
                'windows phone',
                'windows mobile',
                'wpdesktop'
            ];

            match = match.toLowerCase();

            if (match === 'edg/') {
                match = 'chromium';
            } else if (windowsList.indexOf(match) !== -1) {
                match = 'windows';
            }

            return match;
        }
    },
    {
        family: 'apple',
        regExes: [re(IOS), re(MAC)],
        groupIndex: 1
    },
    {
        family: 'amazon',
        regExes: [re(KINDLE)],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            if (match.toLowerCase() === 'kftt') {
                match = 'kindle fire';
            }

            return match;
        }
    },
    {
        family: 'samsung',
        regExes: [
            re('(?:Smart-TV|Linux).+(Tizen).+(?:TV)?'),
            re('(?:Smart-TV).+(Maple)'),
            re('((s[cgp]h-\\w+|gt-\\w+|galaxy\\snexus|sm-n900))')
        ],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            match = match.toLowerCase();

            if (/^(s[cgp]h-|gt-|galaxy\snexus|sm-n)/gi.test(match)) {
                match = 'galaxy';
            } else if (match === 'tizen') {
                match = 'tizenbrowser';
            }

            return match;
        }
    },
    {
        family: 'google',
        regExes: [re(CHROMECAST), re(CHROMEBOOK)],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            match = match.toLowerCase();

            if (match === 'crkey') {
                match = 'chromecast';
            } else if (match === 'cros') {
                match = 'chromium';
            }

            return match;
        }
    },
    {
        family: 'blackberry',
        regExes: [re('(PlayBook|Blackberry 9900|BB10)')],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            match = match.toLowerCase();

            if (match === 'blackberry 9900') {
                match = 'bold';
            }

            return match;
        }
    },
    {
        family: 'tesla',
        regExes: [re(TESLA)],
        groupIndex: 1
    },
    {
        family: 'sony',
        regExes: [re('(SonyCEBrowser)')],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            match = match.toLowerCase();
            if (match === 'sonycebrowser') {
                match = 'sonybraviabrowser';
            }

            return match;
        }
    },
    {
        family: 'panasonic',
        regExes: [re('(Viera)')],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            match = match.toLowerCase();
            if (match === 'viera') {
                match = 'panasonictvbrowser';
            }

            return match;
        }
    },
    {
        family: 'lg',
        regExes: [re('(Web[0|O]S).*(?:SmartTV)'), re('(NetCast).*(?:TV)')],
        groupIndex: 1,
        /**
         *
         * @param {String} match
         * @returns {String}
         *
         */
        done: (match) => {
            match = match.toLowerCase();

            if (match === 'web0s' || match === 'webos') {
                match = 'webosbrowser';
            } else if (match === 'netcast') {
                match = 'netcastbrowser';
            }

            return match;
        }
    },
    {
        family: 'android',
        regExes: [re(ANDROID)],
        groupIndex: 1
    },
    /**
     *
     * This is a catch all. Linux should stay last in this Array
     * because platforms like Tivo and Tizen use Linux.
     *
     */
    {
        family: 'linux',
        regExes: [re(LINUX)],
        groupIndex: 1
    }
];

/**
 *
 * @type {Object}
 * @desc Object representing supported device platform regular expressions.
 *
 */
const platforms = {
    IOS: re(IOS),
    WINDOWSPHONE: re(
        [WINDOWS_PHONE, WINDOWS_PHONE_OLD, WINDOWS_PHONE_DS].join(PIPE)
    ),
    WINDOWS: re(WINDOWS),
    ANDROID: re(ANDROID),
    MACINTOSH: re(MAC),
    CHROMECAST: re(CHROMECAST),
    CHROMEBOOK: re(CHROMEBOOK),
    BLACKBERRY: re(BB),
    TESLA: re(TESLA),
    TIZEN: re(TIZEN),
    NETCAST: re(NETCAST),
    PANASONICTV: re(PANASONICTV),
    SONYBRAVIA: re(SONYBRAVIA),
    WEBOS: re(WEBOS),
    LINUX: re(LINUX) // This is a catch all. Linux should stay last because platforms like Tivo and Tizen use linux.
};

/**
 *
 * @type {RegExp}
 * @desc Regular expression for getting the platform version.
 *
 */
const platformVersion = {
    IOS: re(IOS),
    WINDOWSPHONE: re(WINDOWS),
    WINDOWS: re(WINDOWS),
    ANDROID: re(ANDROID),
    MACINTOSH: re(MAC),
    CHROMECAST: re(CHROMECAST),
    CHROMEBOOK: re(CHROMEBOOK),
    BLACKBERRY: re(BB),
    TESLA: re(TESLA),
    TIZEN: re(TIZEN),
    NETCAST: re(NETCAST),
    PANASONICTV: re(PANASONICTV),
    SONYBRAVIA: re(SONYBRAVIA),
    WEBOS: re(WEBOS),
    LINUX: re(LINUX) // This is a catch all. Linux should stay last because platforms like Tivo and Tizen use linux.
};

/**
 *
 * @type {Object}
 * @desc Object representing supported browser regular expressions.
 * The group match is the browser's version. The order of regular expressions is
 * important. They should go from most specific to least specific.
 * First one that hits, wins.
 *
 */
const browser = {
    ANDROID: re(
        '(?:android).+version\\/([\\w\\.]+)\\s+(?:mobile\\s?safari|safari)'
    ),
    MSIE: [
        re('(?:ms|\\()(?:ie)[\\/\\s]([\\w\\.]+)'),
        re('(?:trident).+rv[:\\s]([\\w\\.]+).+like\\sgecko')
    ],
    EDGE: re('(?:edge|edg)\\/((\\d+)?[\\w\\.]+)'),
    CHROME: [
        re('(?:chrome)\\/v?([\\w\\.]+)'),
        re('(?:(?:android.+)crmo|crios)\\/([\\w\\.]+)')
    ],
    FIREFOX: re('(?:firefox|FxiOS)\\/([\\w\\.-]+)'),
    OPERA: [
        re('(?:opera(?!.*?mini)\\s[mobiletab]+).+version\\/([\\w\\.-]+)'),
        re('(?:opera(?!.*?mini)).+version\\/([\\w\\.]+)'),
        re('(?:opera(?!.*?mini))[\\/\\s]+([\\w\\.]+)'),
        re('(?:OPiOS)[\\/\\s]+([\\w\\.]+)')
    ],
    OPERAMINI: re('(?:opera\\smini)\\/([\\w\\.-]+)'),
    SILK: re('(?:silk)\\/([\\w\\.-]+)'),
    SAFARI: re('version\\/([\\w\\.]+).+?(?:mobile\\s?safari|safari)'),
    PHANTOMJS: re('(?:PhantomJS)\\/([\\w\\.-]+)')
};

/**
 *
 * @type {Object}
 * @desc Object representing supported browser core regular expressions.
 *
 */
const browserCore = {
    WEBKIT: re('AppleWebKit'),
    GECKO: re('gecko'),
    TRIDENT: re('trident'),
    EDGE: re('Edge')
};

/**
 *
 * @type {Object}
 * @desc Object representing supported form factor regular expressions.
 * @todo Revisit this whole default vs. microsoft thing.
 * @todo NT+winp can report incorrect matches for touch enabled win 8 desktop
 *
 */
const formFactor = {
    default: {
        /**
         *
         * Use Mobi for android partial match to also include Opera mobile for android
         *
         */
        tablet: re(
            OPEN +
                [
                    'Tablet|iPad',
                    '\\sNT.*?' + WINDOWS_PHONE,
                    ANDROID + '(?!.*Mobi)',
                    'silk',
                    BB + '.*?Tablet'
                ].join(PIPE) +
                CLOSE
        ),
        phone: re(
            OPEN + ['Mobi|Mobile', IOS, WINDOWS_PHONE, BB].join(PIPE) + CLOSE
        ),
        smarttv: re(
            OPEN +
                ['Tizen', 'SMART-TV', 'SmartTV', 'NetCast', 'Viera'].join(
                    PIPE
                ) +
                CLOSE
        ),
        connecteddevice: re(
            OPEN + ['CrKey', 'Tivo', 'SonyCEBrowser'].join(PIPE) + CLOSE
        ),
        desktop: re(DESKTOP)
    },
    ms: {
        /**
         *
         * Microsoft platform specific form factor tests to address their UA nonsense
         * without affecting other platforms treating Xbox as Tablet to avoid
         * Flash issues / push into apps when appropriate
         *
         */
        tablet: re(
            OPEN +
                [
                    'Tablet|iPad',
                    '\\sNT.*?' + WINDOWS_PHONE,
                    ANDROID + '(?!.*Mobi)',
                    'silk',
                    BB + '.*?Tablet',
                    'xbox'
                ].join(PIPE) +
                CLOSE
        ),
        phone: re(
            OPEN +
                ['Mobi|Mobile', 'WPDesktop', IOS, WINDOWS_PHONE, BB].join(
                    PIPE
                ) +
                CLOSE
        ),
        smarttv: re(
            OPEN + ['Tizen', 'SMART-TV', 'NetCast', 'Viera'].join(PIPE) + CLOSE
        ),
        connecteddevice: re(
            OPEN + ['Xbox', 'CrKey', 'Tivo', 'SonyCEBrowser'].join(PIPE) + CLOSE
        ),
        desktop: re('(?:Windows NT(?!.*WPDesktop)(?!.*Xbox))')
    }
};

/**
 *
 * @access protected
 *
 */
export default {
    constants: {
        OPEN,
        CLOSE,
        CASE,
        PIPE,
        OTHER,
        VERSION,
        FORMFACTORS
    },
    re,
    devices,
    platforms,
    platformVersion,
    browser,
    browserCore,
    formFactor
};
