/**
 *
 * @module subtitleChangedEventData
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.0.0
 * @desc These events represent playback related events. This event represents a subtitle stream change.
 *
 */
export default class SubtitleChangedEventData {
    /**
     *
     * @param {Object} [options={}]
     * @param {String} [options.subtitleLanguage]
     * @param {String} [options.subtitleName]
     * @param {Boolean} [options.subtitleVisibility]
     *
     */
    constructor(options = {}) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    subtitleLanguage: Types.nonEmptyString.optional,
                    subtitleName: Types.nonEmptyString.optional,
                    subtitleVisibility: Types.boolean.optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const { subtitleLanguage, subtitleName, subtitleVisibility } =
            options || {};

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Subtitle language as defined by a variant playlist
         *
         */
        this.subtitleLanguage = subtitleLanguage;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Name of the subtitle
         *
         */
        this.subtitleName = subtitleName;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Boolean|undefined}
         * @desc Subtitle visibility
         *
         */
        this.subtitleVisibility = Check.assigned(subtitleVisibility)
            ? subtitleVisibility
            : undefined;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.SubtitleChangedEventData';
    }

    // #endregion
}
