/**
 *
 * @module entitlementPlugin
 * @desc {SDK.Entitlement.EntitlementPlugin} entry point.
 *
 */

import Entitlement from './entry';
import EntitlementApi from './entitlementApi';
import EntitlementManager from './entitlementManager';

import EntitlementClient from '../services/entitlement/entitlementClient';
import EntitlementService from '../services/entitlement/entry';

import getSafe from '../services/util/getSafe';

import EntitlementClientConfiguration from './../services/entitlement/entitlementClientConfiguration';
import EntitlementManagerConfiguration from './../services/configuration/entitlementManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<
    typeof Entitlement,
    'Entitlement',
    typeof EntitlementService
> = {
    pluginName: 'Entitlement',
    entry: Entitlement,
    service: EntitlementService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { entitlementManager } = sdkSession.managers;

        sdkSession.entitlementApi = new EntitlementApi({
            entitlementManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services, accessTokenProvider } =
            options;

        managers.entitlementManager = new EntitlementManager({
            config: services.entitlement,
            client: new EntitlementClient({
                entitlementClientConfiguration: services.entitlement.client,
                logger,
                httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new EntitlementClientConfiguration(
            getSafe(() => services.entitlement.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.entitlement.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.entitlement =
            new EntitlementManagerConfiguration(
                clientConfiguration,
                undefined,
                services.entitlement.disabled
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
