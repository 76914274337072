/**
 *
 * @module tokenClient
 * @desc Provides a data client that can be used to access token services.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md
 * @see https://github.bamtech.co/api-services-eng/token-service/blob/master/doc/api/WEB.md
 * @see https://github.bamtech.co/api-services-eng/token-service/tree/master/doc/api/token-exchange
 * @see https://github.bamtech.co/api-services-eng/token-service/blob/master/doc/api/token-exchange/ACCESS-TOKEN.md
 * @see https://tools.ietf.org/html/rfc6749
 * @see https://jwt.io/ (decode, verify and generate JSON Web Tokens)
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import Logger from './../../logging/logger';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import AccessContext from './accessContext';
import TokenRequest from './tokenRequest';
import TokenClientEndpoint from './tokenClientEndpoint';
import TokenClientConfiguration from './tokenClientConfiguration';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from './../internal/dust/dustLogUtility';
import DustUrnReference from './../internal/dust/dustUrnReference';
import LogTransaction from '../../logging/logTransaction';
import HttpMethod from '../configuration/httpMethod';

const TokenClientDustUrnReference = DustUrnReference.services.token.tokenClient;

/**
 *
 * @access protected
 * @desc Stateless client object that is capable of calling the service to
 * exchange a TokenRequest for an AccessContext.
 *
 */
export default class TokenClient {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Token.TokenClientConfiguration} options.tokenClientConfiguration
     * @param {String} [options.platformId]
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    tokenClientConfiguration: Types.instanceStrict(
                        TokenClientConfiguration
                    ),
                    platformId: Types.nonEmptyString.optional,
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { tokenClientConfiguration, platformId, logger, httpClient } =
            options;

        /**
         *
         * @access private
         * @type {SDK.Services.Token.TokenClientConfiguration}
         * @desc The configuration information to use.
         *
         */
        this.config = tokenClientConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {HttpClient}
         * @desc The object responsible for making HTTP requests.
         *
         */
        this.httpClient = httpClient;

        /**
         *
         * @access private
         * @type {Object}
         * @desc Utility Object for storing platform data.
         *
         */
        this.platformData = {
            platform: platformId || this.config.extras.platformId
        };

        /**
         *
         * @access private
         * @type {Boolean}
         * @note needs to default to true to collect dust events before the configuration is fetched and we can
         * determine if this should be enabled
         *
         */
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.TokenRequest} tokenRequest - An object that contains the
     * information required for making an exchange request.
     * @param {String} apiKey - The API key to provide as authorization for the request.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Attempts to exchange a token for an access token.
     * @returns {Promise<SDK.Services.Token.AccessContext>} Result indicating activation success and any active
     * entitlements.
     *
     */
    exchange(tokenRequest, apiKey, logTransaction) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                tokenRequest: Types.instanceStrict(TokenRequest),
                apiKey: Types.nonEmptyString,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'exchange', params, arguments);
        }

        const { dustEnabled, httpClient, logger, platformData } = this;
        const { grantType, tokenData, latitude, longitude } = tokenRequest;

        const endpointKey = TokenClientEndpoint.exchange;

        const payload = this.getPayload({
            apiKey,
            endpointKey,
            body: {
                ...{
                    grant_type: grantType,
                    latitude,
                    longitude
                },
                ...tokenData,
                ...platformData
            }
        });

        logger.info(
            this.toString(),
            `Exchange token request:
            originating grantType: "${tokenData.subject_token_type}",
            latitude: "${latitude}",
            longitude: "${longitude}",
            platform: "${platformData.platform}"
        `.replace(/(\s(?=\s))/g, '')
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: TokenClientDustUrnReference.exchange,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data, headers } = response;
                const {
                    access_token: token,
                    token_type: tokenType,
                    refresh_token: refreshToken,
                    expires_in: expiresIn
                } = data;

                /**
                 *
                 * access_token - The access token issued by the authorization server.
                 * token_type - The type of the Access Token. Will always be bearer.
                 * expires_in - The lifetime in seconds of the Access Token. Also known as the Access Token Expiration.
                 * refresh_token - The Refresh Token, which can be used to obtain a new Access Token.
                 *
                 */
                return new AccessContext({
                    token,
                    tokenType,
                    refreshToken,
                    expiresIn,
                    region: headers.get('x-bamtech-region')
                });
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.apiKey
     * @param {SDK.Services.Token.TokenClientEndpoint} options.endpointKey
     * @param {Object} options.body
     * @returns {Object} The payload for the client call.
     *
     */
    getPayload(options) {
        const { apiKey, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, headers } = endpoint;
        const requestBody = body ? queryString.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return { replacer: '{apiKey}', value: apiKey };
                }
            },
            headers
        );

        return {
            url: href,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.TokenClient';
    }

    // #endregion
}
