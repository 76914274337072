/**
 *
 * @module samsungTizenPlayerAdapter
 * @desc PlayerAdapter for Samsung Tizen TVs
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see http://developer.samsung.com/tv/develop/api-references/samsung-product-api-references/avplay-api
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import DrmProvider from '../../drm/drmProvider';
import PlayerAdapter from './../playerAdapter';
import PlaybackMetrics from './../playbackMetrics';
import PlaybackEventListener from './../playbackEventListener';
import Playlist from './../playlist';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

/**
 *
 * @desc Interface used to communicate with the media player.
 *
 */
export default class SamsungTizenPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer
     * @param {Object|null} [options.clientListener=null]
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The SamsungTizenPlayerAdapter requires nativePlayer.setStreamingProperty,
     * nativePlayer.open, and nativePlayer.setListener
     *
     */
    constructor(options) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        setStreamingProperty: Types.function,
                        setListener: Types.function,
                        open: Types.function
                    }),
                    clientListener: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientListener = null } = options;

        /**
         *
         * @access public
         * @type {String}
         *
         */
        this.streamingProperty = null;

        /**
         *
         * @access public
         * @type {Object}
         * @desc Exposed listeners for when application developer needs to call
         * nativePlayer.setListener after a playbackSession.prepare
         * @since 2.2.1
         *
         */
        this.playerListener = null;

        /**
         *
         * @access private
         * @type {Object}
         * @desc Listeners that are set by application developer
         * @since 2.2.1
         *
         */
        this.clientListener = clientListener;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @throws {InvalidStateException} Unable to set playlistUri on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        return new Promise((resolve, reject) => {
            this.playlistUri = playlist.streamUri;

            try {
                if (Check.assigned(this.accessToken)) {
                    const streamingProperty = `|COOKIE={Authorization=${this.accessToken};}`;

                    this.playlistUri = playlist.streamUri;
                    this.streamingProperty = streamingProperty;

                    this.nativePlayer.open(playlist.streamUri);
                    this.nativePlayer.setStreamingProperty(
                        'ADAPTIVE_INFO',
                        streamingProperty
                    );

                    return resolve();
                }

                const errorMsg = `${this.toString()}.setSource(playlist) needs valid accessToken`;

                const reasons = [new ErrorReason('', errorMsg)];
                const exceptionData = ExceptionReference.common.invalidState;

                return reject(new ServiceException({ reasons, exceptionData }));
            } catch (ex) {
                const errorMsg = `${this.toString()}.setSource(playlist) unable to set playlistUri on NativePlayer`;

                const reasons = [new ErrorReason('', `${errorMsg} - ${ex}`)];
                const exceptionData = ExceptionReference.common.invalidState;

                return reject(new ServiceException({ reasons, exceptionData }));
            }
        });
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        this.playlistUri = '';
        this.boundHandlers = {};
        this.drmProvider = null;

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }
    }

    /**
     *
     * @access public
     * @since 11.0.0
     * @desc Trigger when playback has been exited.
     *
     */
    async playbackEndedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        this.onPlaybackEnded({
            error: null,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    // region protected

    /**
     *
     * @access protected
     * @since 2.0.0
     * @desc Gets a snapshot of information about media playback.
     * @throws {InvalidStateException} Unable to get NativePlayer playhead or bitrate data
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note nativePlayer.getCurrentTime - Time returned in milliseconds.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        if (Check.not.function(this.nativePlayer.getCurrentStreamInfo)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer playhead data`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        if (Check.not.function(this.nativePlayer.getCurrentTime)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer bitrate data`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        const currentStreamInfo = this.nativePlayer.getCurrentStreamInfo();
        const currentBitrate = this.getBitrate(currentStreamInfo);
        const currentPlayhead = this.nativePlayer.getCurrentTime() / 1000;

        return new PlaybackMetrics({ currentBitrate, currentPlayhead });
    }

    /**
     *
     * @access protected
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     * @note Samsung Tizen has several events that can be fired by the player.
     * Callbacks for these events are defined and then set to the player.
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { boundHandlers, nativePlayer, clientListener } = this;
        const { playbackStartedEvent, playbackEndedEvent } = this;

        if (Check.function(nativePlayer.setListener)) {
            this.listener = listener;

            boundHandlers.onPlay = playbackStartedEvent.bind(this);
            boundHandlers.onPlayedToCompletion = playbackEndedEvent.bind(this);

            const playerListener = {
                onbufferingstart: boundHandlers.onPlay,
                onstreamcompleted: boundHandlers.onPlayedToCompletion
            };

            if (Check.assigned(clientListener)) {
                // eslint-disable-next-line guard-for-in
                for (const key in clientListener) {
                    const hasClientListener =
                        Check.assigned(clientListener[key]) &&
                        Check.function(clientListener[key]);

                    const hasPlayerListener =
                        Check.assigned(playerListener[key]) &&
                        Check.function(playerListener[key]);

                    if (hasClientListener && hasPlayerListener) {
                        playerListener[key] = (value) => {
                            clientListener[key](value);
                            playerListener[key](value);
                        };
                    }

                    if (hasClientListener && !hasPlayerListener) {
                        playerListener[key] = clientListener[key];
                    }
                }
            }

            this.playerListener = playerListener;

            nativePlayer.setListener(this.playerListener);
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer } = this;

        if (
            Check.function(nativePlayer.setListener) &&
            this.listener === listener
        ) {
            this.listener = null;
            this.playerListener = null;

            nativePlayer.onbufferingstart = null;
            nativePlayer.onstreamcompleted = null;
        }
    }

    /**
     *
     * @access protected
     * @since 3.2.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    setDrmProvider(drmProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProvider: Types.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProvider', params, arguments);
        }

        this.drmProvider = drmProvider;

        return Promise.resolve();
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     * @since 11.0.0
     * @desc Trigger when playback starts.
     *
     */
    playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access public
     * @since 3.1.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
        this.playerListener = null;
    }

    /**
     *
     * @access private
     * @since 2.0.0
     * @param {Array} currentStreamInfo
     * @returns {Number|null}
     *
     */
    getBitrate(currentStreamInfo) {
        let bitrate = null;

        for (let i = 0; i < currentStreamInfo.length; i += 1) {
            if (currentStreamInfo[i].type === 'VIDEO') {
                const extraInfo = JSON.parse(currentStreamInfo[i].extra_info);

                bitrate = parseInt(extraInfo.Bit_rate, 10);
            }
        }

        return bitrate;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.SamsungTizenPlayerAdapter';
    }

    // #endregion
}
