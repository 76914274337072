/**
 *
 * @module exceptionDefinitions
 *
 */

type ErrorCase = {
    method: Array<string>;
    status: Array<number>;
    code?: string;
};

export type Exception = {
    name: string;
    message: string;
    errorCases?: Array<ErrorCase>;
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.AccountExceptions
 * @since 13.0.0
 * @property {Object} accountBlocked - `AccountBlockedException`: Thrown when the Account service returns a HTTP 403 and `code`: `idp.error.identity.blocked`.
 * The request to obtain an `AccountGrant` or create a new account with the supplied identity token returns an HTTP 403 and `code`: `account.blocked`.
 * The Orchestration service returns a HTTP 200 `GraphQlError` and `code`: `account.blocked` or `idp.error.identity.blocked`.
 * @property {Object} accountLoginFailed - `AccountLoginFailedException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.login.failed`.
 * @property {Object} accountNotFound - `AccountNotFoundException`: Thrown when the Account service returns a HTTP 401
 * or 404 and `code`: `account.get.failed` or `account.not.found` or
 * the Orchestration service returns a HTTP 200 `GraphQlError` and `code: `account.get.failed`, `account.not.found`.
 * @property {Object} actionGrantRejected - `ActionGrantRejectedException`: Thrown when the Orchestration service returns
 * a HTTP 200 `GraphQlError` and `code`: `account.error.action-grant.invalid`.
 * @property {Object} identityAlreadyUsed - `IdentityAlreadyUsedException`: Thrown when the Orchestration service returns
 * a `GraphQlError` and `code`: `identity.already.used`.
 * @property {Object} invalidToken - `InvalidTokenException`: Thrown when the request to obtain an `AccountGrant` or create
 * a new account with the supplied identity token returns `code`: `payload.id-token.invalid` or the Orchestration service returns
 * a `GraphQlError` and `code`: `authorization.jwt.invalid` or `authorization.token.invalid`.
 * @property {Object} linkSubscriptionPartial - `LinkSubscriptionPartialException`: Thrown when the Subscription service returns
 * a HTTP 202 and `code`: `copy.failure` or `copy.limit.reached`.
 *
 */

export const AccountExceptions: Record<string, Exception> = {
    accountBlocked: {
        name: 'AccountBlockedException',
        message: 'The account is blocked.'
    },
    accountLoginFailed: {
        name: 'AccountLoginFailedException',
        message: 'Unable to get the account grant for the credentials.'
    },
    accountNotFound: {
        name: 'AccountNotFoundException',
        message: 'The account was not found.'
    },
    actionGrantRejected: {
        name: 'ActionGrantRejectedException',
        message: 'ActionGrant was not accepted by the service.'
    },
    identityAlreadyUsed: {
        name: 'IdentityAlreadyUsedException',
        message: 'Identity is already linked to another account.'
    },
    invalidToken: {
        name: 'InvalidTokenException',
        message: 'External identity token was invalid.'
    },
    linkSubscriptionPartial: {
        name: 'LinkSubscriptionPartialException',
        message: 'Copy partial Error.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.CommerceExceptions
 * @since 13.0.0
 * @property {Object} cardUpdateRejected - `CardUpdateRejectedException`: Throw when the payment service returns a HTTP 400 and `code`: `request.rejected`.
 * @property {Object} comcastConsentRefused - `ComcastConsentRefusedException`: Thrown when the Comcast service returns `code`: `comcast.commerce.403`.
 * @property {Object} comcastServerError - `ComcastServerErrorException`: Thrown when the Comcast service returns `code`: `comcast.commerce.500`.
 * @property {Object} comcastServiceUnavailable - `ComcastServiceUnavailableException`: Thrown when the Comcast service returns `code`: `comcast.commerce.503`.
 * @property {Object} invalidData - `InvalidDataException`: Thrown when the commerce service returns a HTTP 400.
 * @property {Object} invalidOrderId - `InvalidOrderIdException`: Thrown when the order service returns a HTTP 400 and `code`: `invalid.order.id`.
 * @property {Object} invalidPaymentInformation - `InvalidPaymentInformationException`: Thrown when the payment service returns a HTTP 422.
 * @property {Object} invalidRedemptionCode - `InvalidRedemptionCodeException`: Thrown when the redeem service returns a HTTP 422
 * and `code`: `redemption.redeem.invalid.redemptionCode`.
 * @property {Object} invalidZipCode - `InvalidZipCodeException`: Thrown when the zip code service returns a HTTP 403.
 * @property {Object} orderTimeout - `OrderTimeoutException`: Thrown when the order status service returns a HTTP 429.
 * @property {Object} orderProductBlocked - `OrderProductBlockedException`: Thrown when the order status service returns a HTTP 422
 * and `code`: `order.product.blocked`.
 * @property {Object} orderCountryInvalid - `OrderCountryInvalidException`: Thrown when the order status service returns a HTTP 422 and `code`: `order.bin.country.invalid`.
 * @property {Object} orderFraud - `OrderFraudException`: Thrown when the order status service returns a HTTP 422 and `code`: `order.ft.fraud`.
 * @property {Object} orderPaymentDeclined - `OrderPaymentDeclinedException`: Thrown when the order status service returns a HTTP 422 and `code`: `order.failed`.
 * @property {Object} orderStandaloneExceedsBundle - `OrderStandaloneExceedsBundleException`: Thrown when the order status service returns a HTTP 422 and `code`: `order.standalone.exceeds.bundle`.
 * @property {Object} paymentMethodNotFound - `PaymentMethodNotFoundException`: Thrown when the payment service returns a HTTP 404
 * and `code`: `payment-method.not-found`.
 * @property {Object} paymentMethodConsentRequired - `PaymentMethodConsentRequiredException`: Thrown when the payment service returns
 * a HTTP 404 and `code`: `payment-method.consent-required`.
 * @property {Object} productBlocked - `ProductBlockedException`: Thrown when the order status service returns a HTTP 422 and `code`: `order.product.blocked`.
 * @property {Object} unpriceableOrder - `UnpriceableOrderException`: Thrown when the service returns a HTTP 400 or 422.
 *
 */
export const CommerceExceptions: Record<string, Exception> = {
    cardUpdateRejected: {
        name: 'CardUpdateRejectedException',
        message: 'The request was rejected by the service, please try again.'
    },
    comcastConsentRefused: {
        name: 'ComcastConsentRefusedException',
        message:
            'The request to the Comcast service failed due to a bad request.'
    },
    comcastServerError: {
        name: 'ComcastServerErrorException',
        message: 'There was a failure from the Comcast service.'
    },
    comcastServiceUnavailable: {
        name: 'ComcastServiceUnavailableException',
        message: 'The Comcast service is unavailable.'
    },
    invalidData: {
        name: 'InvalidDataException',
        message: 'Unable to create payment information due to invalid data.',
        errorCases: [
            {
                method: [
                    'submitOrderWithPaymentMethod',
                    'submitOrderWithCardPaymentMethod'
                ],
                status: [400]
            }
        ]
    },
    invalidOrderId: {
        name: 'InvalidOrderIdException',
        message: 'The order Id is invalid or does not exist.'
    },
    invalidPaymentInformation: {
        name: 'InvalidPaymentInformationException',
        message:
            'Unable to submit the order due to invalid payment information.',
        errorCases: [
            {
                method: ['createCardPaymentMethod'],
                status: [422]
            }
        ]
    },
    invalidRedemptionCode: {
        name: 'InvalidRedemptionCodeException',
        message: 'Redemption code is invalid or already redeemed.'
    },
    invalidZipCode: {
        name: 'InvalidZipCodeException',
        message: 'Invalid or nonexistent zip code.',
        errorCases: [
            {
                method: ['lookupByZipCode'],
                status: [403]
            }
        ]
    },
    orderTimeout: {
        name: 'OrderTimeoutException',
        message:
            'The order could not be verified in a timely fashion. Requests Limit reached for an order.',
        errorCases: [
            {
                method: ['getOrderStatus'],
                status: [429]
            }
        ]
    },
    orderProductBlocked: {
        name: 'OrderProductBlockedException',
        message:
            'Indicated order was blocked, product is already owned by the user.'
    },
    orderCountryInvalid: {
        name: 'OrderCountryInvalidException',
        message:
            'Indicated order failed because the geo-location country code of the user does not match the wallet BIN country code.',
        errorCases: [
            {
                method: ['getOrderStatus'],
                status: [422]
            }
        ]
    },
    orderFraud: {
        name: 'OrderFraudException',
        message: 'Indicated order failed to pass fraud prevention checks.'
    },
    orderPaymentDeclined: {
        name: 'OrderPaymentDeclinedException',
        message:
            'Indicated order failed due to the payment method being declined.'
    },
    orderStandaloneExceedsBundle: {
        name: 'OrderStandaloneExceedsBundleException',
        message:
            'Indicate to any subscribers with select 2+ standalone services that they are not able to purchase the Disney Bundle on D+.'
    },
    paymentMethodNotFound: {
        name: 'PaymentMethodNotFoundException',
        message: 'Unable to retrieve the payment method as it was not found.',
        errorCases: [
            {
                method: ['getDefaultPaymentMethod'],
                status: [404],
                code: 'payment-method.not-found'
            },
            {
                method: ['getPaymentCard'],
                status: [404],
                code: 'payment-method.not-found'
            }
        ]
    },
    paymentMethodConsentRequired: {
        name: 'PaymentMethodConsentRequiredException',
        message:
            'The user must consent to share their default payment method on file across partners.',
        errorCases: [
            {
                method: ['getDefaultPaymentMethod'],
                status: [404],
                code: 'payment-method.consent-required'
            }
        ]
    },
    productBlocked: {
        name: 'ProductBlockedException',
        message:
            'Product Blocked user has active subscription for a similar product.'
    },
    unpriceableOrder: {
        name: 'UnpriceableOrderException',
        message:
            'Order could not be priced due to issues with the provided data.',
        errorCases: [
            {
                method: ['priceOrder'],
                status: [400, 422]
            }
        ]
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.CommonExceptions
 * @since 13.0.0
 * @property {Object} authenticationExpired - `AuthenticationExpiredException`: Thrown when the request to the exchange token service returns `code`: `invalid_grant`.
 * @property {Object} clientUnsupportedFeature - `ClientUnsupportedFeatureException`: Thrown when a service returns `code`: `client.unsupported-feature`.
 * @property {Object} invalidState - `InvalidStateException`: Thrown by the SDK when it is in an invalid state.
 * @property {Object} locationAcquisition - `LocationAcquisitionException`: Thrown when the `GeoProvider` is unable to acquire
 * location. Only applicable on certain platforms.
 * @property {Object} locationNotAllowed - `LocationNotAllowedException`: Thrown when the request to exchange token returns a HTTP
 * 400 and `code`: `unauthorized_client`, and `error_description`: `unknown-location`, `unreliable-location`, or `forbidden-location`,
 * or the Orchestration service returns a HTTP 200 `GraphQlError` and `code`: `token.service.unauthorized.client`.
 * @property {Object} network - `NetworkException`: Thrown when unable to contact the service for any reason (no server response received).
 * @property {Object} requestBlocked - `RequestBlockedException`: Thrown when the service returns a HTTP 403, does `NOT` have a
 * `X-Request-Id` header and has a `Content-Type` value of `text/html`.
 * @property {Object} requestTemporarilyBlocked - `RequestTemporarilyBlockedException`: Thrown when the service returns a HTTP 403 and `code`: `forbidden`.
 * @property {Object} temporarilyThrottled - `TemporarilyThrottledException`: Thrown when the service returns a HTTP 429 and `code`: `throttled`.
 * The SDK should check for the presence of the
 * `X-Rate-Limit-Duration` header, and if present map it to the `throttleTimeout` property using the `ThrottleTimeoutError`.
 * @property {Object} temporarilyUnavailable - `TemporarilyUnavailableException`: Thrown when the service returns a HTTP 5xx.
 * @property {Object} unexpectedError - `UnexpectedErrorException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError`
 * and `code: `graph.local.unexpected`.
 * @property {Object} upgradeRequired - `UpgradeRequiredException`: Thrown when the service returns `code`: `platform.client.upgrade.required`.
 *
 */
export const CommonExceptions: Record<string, Exception> = {
    authenticationExpired: {
        name: 'AuthenticationExpiredException',
        message:
            'Authentication has expired and needs to be updated before proceeding.'
    },
    clientUnsupportedFeature: {
        name: 'ClientUnsupportedFeatureException',
        message:
            "An error needs to be emitted that wasn't anticipated during planning."
    },
    invalidState: {
        name: 'InvalidStateException',
        message:
            'The operation being attempted is not valid for the current state.'
    },
    locationAcquisition: {
        name: 'LocationAcquisitionException',
        message:
            'Unable to get user location due to location service being disabled.'
    },
    locationNotAllowed: {
        name: 'LocationNotAllowedException',
        message: 'User is not allowed to use the app in their current location.'
    },
    network: {
        name: 'NetworkException',
        message: 'Problem with the underlying network connection.'
    },
    requestBlocked: {
        name: 'RequestBlockedException',
        message: 'Device IP address is blocked.'
    },
    requestTemporarilyBlocked: {
        name: 'RequestTemporarilyBlockedException',
        message: 'Device IP address is temporarily blocked.'
    },
    temporarilyThrottled: {
        name: 'TemporarilyThrottledException',
        message: 'Device IP address is temporarily throttled.'
    },
    temporarilyUnavailable: {
        name: 'TemporarilyUnavailableException',
        message: 'There was a general error while processing the request'
    },
    unexpectedError: {
        name: 'UnexpectedErrorException',
        message: 'An unexpected error has occurred.'
    },
    upgradeRequired: {
        name: 'UpgradeRequiredException',
        message:
            'The profile requires a PIN but no PIN was supplied and X-BAMTech-PIN-Unsupported header is set.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.ConfigurationExceptions
 * @since 13.0.0
 * @property {Object} configurationNotFound - `ConfigurationNotFoundException`: Thrown when the SDK config service could not find the requested config.
 *
 */
export const ConfigurationExceptions: Record<string, Exception> = {
    configurationNotFound: {
        name: 'ConfigurationNotFoundException',
        message:
            'Unable to locate configuration data based on the supplied bootstrap parameter.',
        errorCases: [
            {
                method: ['getConfiguration'],
                status: [404]
            }
        ]
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.ContentExceptions
 * @since 13.0.0
 * @property {Object} invalidRequest - `InvalidRequestException`: Thrown when the content service returns a HTTP 400. Response body is ignored.
 *
 */
export const ContentExceptions: Record<string, Exception> = {
    invalidRequest: {
        name: 'InvalidRequestException',
        message: 'The content query request was invalid.',
        errorCases: [
            {
                method: ['query'],
                status: [400]
            }
        ]
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.DrmExceptions
 * @since 13.0.0
 * @property {Object} downgrade - `DowngradeException`: Thrown when the key service returns a HTTP 403 and `code`: `security-level.insufficient` or `downgrade`.
 * @property {Object} rejected - `RejectedException`: Thrown when the key service a returns HTTP 403 and `code`: `rejected`.
 *
 */
export const DrmExceptions: Record<string, Exception> = {
    downgrade: {
        name: 'DowngradeException',
        message:
            'The device does not meet the security requirements for the requested content quality and should retry at a lower quality setting.'
    },
    rejected: {
        name: 'RejectedException',
        message: 'The device should be considered blocked.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.EligibilityExceptions
 * @since 13.0.0
 * @property {Object} accountResolution - `AccountResolutionException`: Thrown when the eligibility service returns a HTTP 500 and `code`: `account.resolution.failure`.
 * @property {Object} huluService - `HuluServiceException`: Thrown when the eligibility service returns a HTTP 500 and `code`: `hulu.service.failure`.
 * @property {Object} invalidRequest - `InvalidRequestException`: Thrown when the eligibility service returns a HTTP 400 and `code`: `invalid.request`.
 *
 */
export const EligibilityExceptions: Record<string, Exception> = {
    accountResolution: {
        name: 'AccountResolutionException',
        message: 'The account could not be resolved.'
    },
    huluService: {
        name: 'HuluServiceException',
        message: 'There was a general Hulu error while processing the request.'
    },
    invalidRequest: {
        name: 'InvalidRequestException',
        message: 'The eligibility request was invalid.',
        errorCases: [
            {
                method: ['getEligibilityStatus'],
                status: [400]
            }
        ]
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.InternalExceptions
 * @since 13.0.0
 * @property {Object} invalidDustConfiguration - `InvalidDustConfigurationException`: Thrown when an incorrect or incomplete `application`
 * is provided with `SDK.Services.Configuration.BootstrapConfiguration` and the SDK Client Config or when the `environment` cannot be determined.
 *
 */
export const InternalExceptions: Record<string, Exception> = {
    invalidDustConfiguration: {
        name: 'InvalidDustConfigurationException',
        message:
            'Dust Configuration is incomplete or incorrect - most likely the application or environment Object is incomplete.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.MediaExceptions
 * @since 13.0.0
 * @property {Object} accountIdMissing - `AccountIdMissingException`: Thrown when the request to obtain a license, or obtain or renew the offline
 * license returns a HTTP 400 and `code`: `account-id.missing`.
 * @property {Object} authorizationExpired - `AuthorizationExpiredException`: Thrown when the playlist service returns a HTTP 401 and
 * `code`: `access-token.expired`.
 * @property {Object} blackout - `BlackoutException`: Thrown when the playlist service returns a HTTP 403 and `code`: `blackout`.
 * @property {Object} downloadLimitReached - `DownloadLimitReachedException`: Thrown when the request to obtain an offline license returns a
 * HTTP 403 and `code`: `not-permitted-offline` or `not-permitted-renewal` and `description`: `download-limit-reached`.
 * @property {Object} drm - `DrmException`: Thrown when a media service returns a HTTP 403 and `code`: `unspecified-drm-error`.
 * @property {Object} fraudDetectionViolation - `FraudDetectionViolationException`: Thrown when the request to obtain a offline license returns
 * a HTTP 403 and `code`: `not-permitted-offline` or `not-permitted-renewal` and `description`: `fraud-access-revoked`.
 * @property {Object} keyNotFound - `KeyNotFoundException`: Thrown when the key service returns a HTTP 404 and `code`: `key.not-found` or `content-key.not-found`.
 * @property {Object} kidsModeEnabled - `KidsModeEnabledException`: Thrown when the playlist service returns a HTTP 403 and `code: `kids-mode-enabled`.
 * @property {Object} loginRequired - `LoginRequiredException`: Thrown when the playlist service returns a HTTP 401 and `code`: `login.required`.
 * @property {Object} mediaExperienceContextBodyInvalid - `MediaExperienceContextBodyInvalidException`: Thrown when the playlist service returns
 * a HTTP 400 and `code`: `experience.context.body-invalid`.
 * @property {Object} mediaNotAllowed - `MediaNotAllowedException`: Thrown when the playlist service returns a HTTP 403 and `code`: `not-entitled`,
 * `blackout`, `kids-mode-enabled`, `parental-controls-restricted`, or `stream-concurrency-violation`.
 * @property {Object} mediaUnavailable - `MediaUnavailableException`: Thrown when the playlist service returns a HTTP 404 and `code`: `media.not-playable`,
 * `media.missing`, `channel.not-found`, or `channel.no-airing`.
 * @property {Object} notEntitled - `NotEntitledException`: Throw when the key service returns a HTTP 403 and `code`: `not-entitled`.
 * @property {Object} offlineNotPermitted - `OfflineNotPermittedException`: Thrown when the key service returns a HTTP 403 and `code`: `not-permitted-offline`.
 * @property {Object} parentalControlsRestricted - `ParentalControlsRestrictedException`: Thrown when the playlist service returns a HTTP 403 and
 * `code`: `parental-controls-restricted`.
 * @property {Object} profilePersonalInfoMissing - Since 18.0.0 - `ProfilePersonalInfoMissingException`: Thrown when the playlist service returns a HTTP 403 and
 * `code`: `profile.personal-info-missing`.
 * @property {Object} sdkMisconfigured - `SdkMisconfiguredException`: Thrown when the remote configuration does not contain any fallback values.
 * @property {Object} streamConcurrency - `StreamConcurrencyException`: Thrown when the playlist service returns a HTTP 403 and `code`: `stream-concurrency-violation`.
 * @property {Object} thumbnailsNotAvailable - `ThumbnailsNotAvailableException`: Thrown when the thumbnail service returns a HTTP 404 and `code`: `thumbnails-unavailable`.
 *
 */
export const MediaExceptions: Record<string, Exception> = {
    accountIdMissing: {
        name: 'AccountIdMissingException',
        message:
            'Request for license has been made with device authorization instead of account authorization.'
    },
    authorizationExpired: {
        name: 'AuthorizationExpiredException',
        message: 'Could not reauthorize before attempting to access media.'
    },
    blackout: {
        name: 'BlackoutException',
        message:
            'The current user is not allowed to access the media for the requested license because they are in a blackout location.'
    },
    downloadLimitReached: {
        name: 'DownloadLimitReachedException',
        message:
            'The device has downloaded the maximum number of videos permitted.'
    },
    drm: {
        name: 'DrmException',
        message: 'General DRM error.'
    },
    fraudDetectionViolation: {
        name: 'FraudDetectionViolationException',
        message:
            'Account has been flagged for potential fraud due to a high number of downloads on different devices.'
    },
    keyNotFound: {
        name: 'KeyNotFoundException',
        message: 'The requested license is not available.'
    },
    kidsModeEnabled: {
        name: 'KidsModeEnabledException',
        message:
            'The current user is not allowed to access the media for the requested license due to parental controls restrictions on the account.'
    },
    loginRequired: {
        name: 'LoginRequiredException',
        message: 'The requested media can only be viewed after authentication.'
    },
    mediaExperienceContextBodyInvalid: {
        name: 'MediaExperienceContextBodyInvalidException',
        message:
            'Invalid experience context (bumper) configuration for QC playback.'
    },
    mediaNotAllowed: {
        name: 'MediaNotAllowedException',
        message:
            'The current user is not allowed to access the media for the requested license.'
    },
    mediaUnavailable: {
        name: 'MediaUnavailableException',
        message: 'The requested media is not available.'
    },
    notEntitled: {
        name: 'NotEntitledException',
        message:
            'The user is not entitled to the requested media for the requested license.'
    },
    offlineNotPermitted: {
        name: 'OfflineNotPermittedException',
        message:
            'The current user is not allowed to obtain the requested license because the media is not available for offline viewing.'
    },
    parentalControlsRestricted: {
        name: 'ParentalControlsRestrictedException',
        message:
            'The current user is not allowed to access the media for the requested license due to parental controls restrictions on the profile.'
    },
    profilePersonalInfoMissing: {
        name: 'ProfilePersonalInfoMissingException',
        message:
            'The user has not yet validated or provided personal info in their profile, required for ad serving.'
    },
    sdkMisconfigured: {
        name: 'SdkMisconfiguredException',
        message:
            'The SDK is misconfigured and does not include any playback scenario defaults.'
    },
    streamConcurrency: {
        name: 'StreamConcurrencyException',
        message:
            'The current user is not allowed to access the media for the requested license because they have too many concurrent streams.'
    },
    thumbnailsNotAvailable: {
        name: 'ThumbnailsNotAvailableException',
        message: 'No thumbnails are available for the given media.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.OrchestrationExceptions
 * @since 13.0.0
 * @property {Object} accountArchived - Since `15.0.0` - `AccountArchivedException`: The Orchestration service returns 200~ with a `GraphQlError` and `extensions.code:` = `account.archived`.
 * @property {Object} accountNotEligibleAdTier - Since `18.0.0` - `AccountNotEligibleAdTierException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.ad-tier.not-eligible`.
 * @property {Object} accountSecurityFlagged - `AccountSecurityFlaggedException`: Thrown when The Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `account.security-flagged`.
 * @property {Object} accountVerificationRequired - `AccountVerificationRequiredException`: Thrown when The Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `account.verification.required` or `idp.error.identity.verification.required`.
 * @property {Object} identityPasswordResetRequired - Since `15.0.0` - `IdentityPasswordResetRequiredException`: Thrown when The Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `idp.error.identity.password-reset-required`.
 * @property {Object} attributeValidation - `AttributeValidationException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.payload.fields.incorrect` or `payload.fields.incorrect`.
 * @property {Object} authenticationBlocked - `AuthenticationBlockedException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError`
 * and `code`: `account.blocked` or `idp.error.identity.blocked`.
 * @property {Object} clientIdNotFound - Since `13.0.0` - `ClientIdNotFoundException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `payload.client-id.not.found`.
 * @property {Object} deviceIdInvalid - Since `15.0.0` - `DeviceIdInvalidException`: The Orchestration service returns 200, with a `GraphQlError` and `extensions.code:` = `device.error.device.id.invalid`.
 * @property {Object} documentsRetrievalFailed - `DocumentsRetrievalFailedException`: Thrown when the Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `documents.retrieval.failure`.
 * @property {Object} identityAlreadyExists - `IdentityAlreadyExistsException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.identity.already-exists`.
 * @property {Object} identityNotFound - `IdentityNotFoundException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.identity.not-found`.
 * @property {Object} invalidCredentials - `InvalidCredentialsException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.identity.bad-credentials`.
 * @property {Object} invalidEmail - `InvalidEmailException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and `code`:
 * `idp.error.identity.invalid-email-update` or `idp.error.params.invalid-email`.
 * @property {Object} invalidPasscode - `InvalidPasscodeException`: Thrown when he Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.otp.invalid-passcode`.
 * @property {Object} invalidPassword - `InvalidPasswordException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.password.invalid-value`.
 * @property {Object} invalidPreAuthToken - `InvalidPreAuthTokenException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.pre-auth.invalid-token`.
 * @property {Object} licensePlateNotFound - `LicensePlateNotFoundException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `device.error.license.not.found`.
 * @property {Object} marketingPreferenceUpdateFailed - `MarketingPreferenceUpdateFailedException`: Thrown when the Orchestration service returns
 * a HTTP 200 `GraphQlError` and `code`: `idp.error.marketing.update.failed`.
 * @property {Object} offDeviceActionGrantInvalid - `OffDeviceActionGrantInvalidException`: Thrown when the Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `device.error.invalid.action.grant.
 * @property {Object} offDeviceGrantInvalid - `OffDeviceGrantInvalidException`: Thrown when the Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `device.error.offdevicegrant.invalid`.
 * @property {Object} offDeviceMissingActionGrant - `OffDeviceMissingActionGrantException`: Thrown when the Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `device.error.missing.action.grant`.
 * @property {Object} offDeviceMissingRedemptionFlow - `OffDeviceMissingRedemptionFlowException`: Thrown when the Orchestration service returns
 * a HTTP 200 `GraphQlError` and `code`: `device.error.missing.redemption.flow`.
 * @property {Object} rateLimited - `RateLimitedException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.auth.otp.throttled` or `idp.error.auth.pre-auth.throttled`.
 * @property {Object} resourceTimedOut - `ResourceTimedOutException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `resource.db.timeout.error`.
 * @property {Object} tokenAlreadyUsed - `TokenAlreadyUsedException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.reset-token.already-used`.
 * @property {Object} tokenInvalid - `TokenInvalidException`: Thrown when the Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `idp.error.reset-token.invalid`.
 * @property {Object} maximumProfilesReached - `MaximumProfilesReachedException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profiles.max.exceeded`.
 * @property {Object} partnerNotSupported - Since `18.0.0` - `PartnerNotSupportedException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `graph.local.partner.not-supported`.
 * @property {Object} profileCreationProtected - `ProfileCreationProtectedException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profile.creation.protected`.
 * @property {Object} profilePinInvalid - `ProfilePinInvalidException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profile.pin.invalid`.
 * @property {Object} profileRetrieval - `ProfileRetrievalException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profile.read.failed`.
 * @property {Object} redirectUriInvalid - Since `13.0.0` - `RedirectUriInvalidException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `payload.redirect-uri.invalid`.
 * @property {Object} redirectUriNotAllowed - Since `13.0.0` - `RedirectUriNotAllowedException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `payload.redirect-uri.not.allowed`.
 * @property {Object} userProfileDeleteFailed - `UserProfileDeleteFailedException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profile.delete.failed`.
 * @property {Object} userProfileNotFound - `UserProfileNotFoundException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profile.not.found` or `profile.lookup.id.failed`.
 * @property {Object} userProfileUpdateFailed - `UserProfileUpdateFailedException`: Thrown when Orchestration service returns a HTTP 200 `GraphQlError` and
 * `code`: `account.profile.update.failed`.
 *
 */
export const OrchestrationExceptions: Record<string, Exception> = {
    accountArchived: {
        name: 'AccountArchivedException',
        message:
            'The orchestration service was unable to get the account grant due to the account being archived.'
    },
    accountNotEligibleAdTier: {
        name: 'AccountNotEligibleAdTierException',
        message:
            'The operation (such as update date of birth) is applied to a not eligible account.'
    },
    accountSecurityFlagged: {
        name: 'AccountSecurityFlaggedException',
        message: 'The account has been security flagged.'
    },
    accountVerificationRequired: {
        name: 'AccountVerificationRequiredException',
        message: 'The account is not verified.'
    },
    identityPasswordResetRequired: {
        name: 'IdentityPasswordResetRequiredException',
        message: 'A password reset is required for this account.'
    },
    attributeValidation: {
        name: 'AttributeValidationException',
        message: 'One or more attributes supplied are invalid.'
    },
    authenticationBlocked: {
        name: 'AuthenticationBlockedException',
        message:
            'The users account should be blocked for being a minor or due to inappropriate behavior.'
    },
    clientIdNotFound: {
        name: 'ClientIdNotFoundException',
        message: 'The provided client ID was not found.'
    },
    deviceIdInvalid: {
        name: 'DeviceIdInvalidException',
        message: 'The access token contains an invalid device ID.'
    },
    documentsRetrievalFailed: {
        name: 'DocumentsRetrievalFailedException',
        message: 'The documents could not be retrieved.'
    },
    identityAlreadyExists: {
        name: 'IdentityAlreadyExistsException',
        message: 'The new email address is already taken.'
    },
    identityNotFound: {
        name: 'IdentityNotFoundException',
        message: 'The requested identity does not exist.'
    },
    invalidAuthenticationUrl: {
        name: 'InvalidAuthenticationUrlException',
        message: 'The SCA response contained an invalid URL.'
    },
    invalidCampaign: {
        name: 'InvalidCampaignException',
        message: 'The provided campaign data is not valid.'
    },
    invalidCredentials: {
        name: 'InvalidCredentialsException',
        message:
            'Invalid credential values received. This could be incorrect email or password, or mismatched credentials.'
    },
    invalidEmail: {
        name: 'InvalidEmailException',
        message: 'The new email address is invalid.'
    },
    invalidOrder: {
        name: 'InvalidOrderException',
        message: 'Order was invalid because it did not contain any line items.'
    },
    invalidPasscode: {
        name: 'InvalidPasscodeException',
        message: 'The passcode is invalid.'
    },
    invalidPassword: {
        name: 'InvalidPasswordException',
        message:
            'The user entered a password that does not meet the minimum password requirements.'
    },
    invalidPaymentMethod: {
        name: 'InvalidPaymentMethodException',
        message: 'Invalid payment method ID.'
    },
    invalidPreAuthToken: {
        name: 'InvalidPreAuthTokenException',
        message:
            'The pre-auth token has expired, is invalid or was faked by the user.'
    },
    invalidSecurityCode: {
        name: 'InvalidSecurityCodeException',
        message: 'The payment method security code is invalid.'
    },
    invalidVoucher: {
        name: 'InvalidVoucherException',
        message: 'The provided voucher data is not valid.'
    },
    licensePlateNotFound: {
        name: 'LicensePlateNotFoundException',
        message: 'The license plate was not found.'
    },
    marketingPreferenceUpdateFailed: {
        name: 'MarketingPreferenceUpdateFailedException',
        message: 'Update of marketing preferences failed.'
    },
    offDeviceActionGrantInvalid: {
        name: 'OffDeviceActionGrantInvalidException',
        message: 'The action grant was invalid.'
    },
    offDeviceGrantInvalid: {
        name: 'OffDeviceGrantInvalidException',
        message: 'The device grant was invalid.'
    },
    offDeviceMissingActionGrant: {
        name: 'OffDeviceMissingActionGrantException',
        message: 'The action grant is missing.'
    },
    offDeviceMissingRedemptionFlow: {
        name: 'OffDeviceMissingRedemptionFlowException',
        message: 'The redemption flow is missing.'
    },
    rateLimited: {
        name: 'RateLimitedException',
        message: 'Too many passcodes have been requested.'
    },
    resourceTimedOut: {
        name: 'ResourceTimedOutException',
        message: 'Call to dynamodb in the resource-service timed out.'
    },
    tokenAlreadyUsed: {
        name: 'TokenAlreadyUsedException',
        message: 'Unable to use the reset token, it has already been used.'
    },
    tokenInvalid: {
        name: 'TokenInvalidException',
        message:
            'Unable to reset the password as the reset token is not recognized by the service.'
    },
    maximumProfilesReached: {
        name: 'MaximumProfilesReachedException',
        message:
            "The maximum number of profiles for the user's account has already been reached."
    },
    partnerNotSupported: {
        name: 'PartnerNotSupportedException',
        message: 'The partner supplied to the operation is not supported.'
    },
    profileCreationProtected: {
        name: 'ProfileCreationProtectedException',
        message:
            'Creation of a profile on this account requires authentication.'
    },
    profilePinDeleteFailed: {
        name: 'ProfilePinDeleteFailedException',
        message: 'The pin delete failed.'
    },
    profilePinInvalid: {
        name: 'ProfilePinInvalidException',
        message: 'The provided PIN is not the correct pin for this profile.'
    },
    profilePinRetrievalFailed: {
        name: 'ProfilePinRetrievalFailedException',
        message: 'Failed retrieving pin for a profile.'
    },
    profileRetrieval: {
        // cannot be called profileRetrievalError because is fails name to key correlation test
        name: 'ProfileRetrievalException',
        message: "The profiles for the user's account could not be retrieved."
    },
    redirectUriInvalid: {
        name: 'RedirectUriInvalidException',
        message: 'The provided redirect URI is invalid.'
    },
    redirectUriNotAllowed: {
        name: 'RedirectUriNotAllowedException',
        message: 'The provided redirect URI is not allowed.'
    },
    userProfileDeleteFailed: {
        name: 'UserProfileDeleteFailedException',
        message: 'The userProfile failed to be deleted.'
    },
    selfAccountHasActiveD2cSubscription: {
        name: 'SelfAccountHasActiveD2cSubscriptionException',
        message:
            'The account has an active D2C subscription which is not pending cancel, and a delete request cannot be made for accounts with an active subscription.'
    },
    selfAccountNotActive: {
        name: 'SelfAccountNotActiveException',
        message:
            'The account is not active, and a delete request cannot be made for accounts unless they are active.'
    },
    selfAccountNotFound: {
        name: 'SelfAccountNotFoundException',
        message:
            'The orchestration service could not find the account when requesting an account be deleted.'
    },
    selfRequestDuplicate: {
        name: 'SelfRequestDuplicateException',
        message:
            'There is already an open request for this account to be deleted.'
    },
    userProfileNotFound: {
        name: 'UserProfileNotFoundException',
        message: 'The userProfile was not found.'
    },
    userProfileUpdateFailed: {
        name: 'UserProfileUpdateFailedException',
        message: 'The userProfile update failed.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.PurchaseExceptions
 * @since 13.0.0
 * @property {Object} activationBadRequest - `ActivationBadRequestException`: Thrown when the purchase service returns a HTTP 400 and `code`: `token.invalid` or `token.missing`.
 * @property {Object} activationFailed - `ActivationFailedException`: Thrown when the purchase service returns a HTTP 202 and `temporaryAccessGranted`: `false`.
 * @property {Object} activationForbidden - `ActivationForbiddenException`: Thrown when the purchase service returns a HTTP 403 and `code`: `linked.elsewhere` or `token.forbidden`.
 * @property {Object} activationTokenExpired - `ActivationTokenExpiredException`: Thrown when the purchase service returns a HTTP 400 and `code`: `token.expired`.
 * @property {Object} bundleForbidden - `BundleForbiddenException`: Thrown when the purchase service returns a HTTP 403 and `code`: `expected.subscriptions.not.found`.
 * @property {Object} retryFailed - `RetryFailedException`: Thrown when the retry policy limit has been reached.
 * @property {Object} providerNotEnabled - Since `14.1.0` - `ProviderNotEnabledException`: Thrown if the service responds with a 400 status and `code`: `provider.not.enabled`.
 *
 */
export const PurchaseExceptions: Record<string, Exception> = {
    activationBadRequest: {
        name: 'ActivationBadRequestException',
        message: 'The service failed to activate due to a bad request.'
    },
    activationFailed: {
        name: 'ActivationFailedException',
        message: 'The service failed to activate the receipt.'
    },
    activationForbidden: {
        name: 'ActivationForbiddenException',
        message:
            'The service failed to activate due to linking being forbidden.'
    },
    activationTokenExpired: {
        name: 'ActivationTokenExpiredException',
        message:
            'The service failed to activate due to the activation token being expired.'
    },
    bundleForbidden: {
        name: 'BundleForbiddenException',
        message:
            'The service failed to return the ActivationToken for the bundle purchase.'
    },
    retryFailed: {
        name: 'RetryFailedException',
        message:
            'The SDK exhausted all retry attempts without successfully activating.'
    },
    providerNotEnabled: {
        name: 'ProviderNotEnabledException',
        message: 'The specified content provider is not enabled.'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.SocketExceptions
 * @since 13.0.0
 * @property {Object} flowControlPolicyConflict - `FlowControlPolicyConflictException`: Thrown when the socket service returns with `code`: `policy.conflict`.
 * @property {Object} flowControlPolicyInvalid - `FlowControlPolicyInvalidException`: Thrown when the socket service returns with `code`: `policy.invalid`.
 * @property {Object} flowControlEventInvalid - `FlowControlEventInvalidException`: Thrown when the socket service returns with `code`: `event.invalid`.
 *
 */
export const SocketExceptions: Record<string, Exception> = {
    flowControlPolicyConflict: {
        name: 'FlowControlPolicyConflictException',
        message: 'Conflicting policies are applied for an eventUrn.'
    },
    flowControlPolicyInvalid: {
        name: 'FlowControlPolicyInvalidException',
        message: 'An invalid policy name is supplied.'
    },
    flowControlEventInvalid: {
        name: 'FlowControlEventInvalidException',
        message: 'An event-type URN provided is an invalid format (not URN).'
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.SubscriptionExceptions
 * @since 13.0.0
 * @property {Object} subscriptionNotFound - `SubscriptionNotFoundException`: Thrown when the subscription service returns a HTTP 404 and `code`: `subscription.not.found`.
 *
 */
export const SubscriptionExceptions: Record<string, Exception> = {
    subscriptionNotFound: {
        name: 'SubscriptionNotFoundException',
        message: 'No subscription with that id has been found for the user.',
        errorCases: [
            {
                method: ['getAccountSubscription', 'getSubscriberInfo'],
                status: [404]
            }
        ]
    }
};

/**
 *
 * @typedef {Object} SDK.Services.Exception.UserProfileExceptions
 * @since 13.0.0
 * @property {Object} ageNotVerified - `AgeNotVerifiedException`: Thrown when the playlist service request returns a HTTP 403
 * and `code`: `age-not-verified`.
 * @property {Object} ageNotVerifiedKr - `AgeNotVerifiedKrException`: Thrown when the playlist service request returns a HTTP 403
 * and `code`: `age-not-verified-kr`.
 * @property {Object} profilePinExpired - `ProfilePinExpiredException`: Thrown when the user profile service returns a HTTP 403
 * and `code`: `pin-expired`.
 * @property {Object} profileMissing - `ProfileMissingException`: Thrown when the user profile service returns a HTTP 403 with `code`: `profile-missing`.
 * @property {Object} profilePinMissing - `ProfilePinMissingException`: Thrown when the user profile service returns a HTTP 403 with `code`: `pin-missing`.
 * @property {Object} profilePinNotEligible - `ProfilePinNotEligibleException`: Thrown when the Orchestration service returns a HTTP 200
 * `GraphQlError` and `code`: `account.profile.pin.not-eligible`.
 * @property {Object} profilePinUpdateFailed - `ProfilePinUpdateFailedException`: Thrown when the Orchestration service returns
 * a HTTP 200 `GraphQlError` and `code`: `account.profile.pin.update.failed`.
 *
 */
export const UserProfileExceptions: Record<string, Exception> = {
    ageNotVerified: {
        name: 'AgeNotVerifiedException',
        message:
            'The age for the users profile has not been verified and needs to be before proceeding.'
    },
    ageNotVerifiedKr: {
        name: 'AgeNotVerifiedKrException',
        message:
            'The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.'
    },
    profilePinExpired: {
        name: 'ProfilePinExpiredException',
        message:
            'The profile pin has expired and needs to be updated before proceeding.'
    },
    profileMissing: {
        name: 'ProfileMissingException',
        message:
            'There was no active profile in the token or the service was unable to use it.'
    },
    profilePinMissing: {
        name: 'ProfilePinMissingException',
        message: 'The profile requires a PIN but no PIN was supplied.'
    },
    profilePinNotEligible: {
        name: 'ProfilePinNotEligibleException',
        message: 'The profile is not eligible for setting a pin'
    },
    profilePinUpdateFailed: {
        name: 'ProfilePinUpdateFailedException',
        message: 'The pin update failed'
    }
};
