/**
 *
 * @module unauthenticatedMessageProcessor
 *
 */

import MessageProcessorBase, {
    ErrorCodeDescription
} from './messageProcessorBase';
import type { RawSocketMessage } from './socketEvent';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import type SocketManager from './socketManager';
import TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.13.0
 * @desc Handles the reloading the access token when an unauthenticated socket message returns
 *
 */
export default class UnauthenticatedMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.13.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 4.13.0
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 4.13.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {SDK.Socket.SocketManager} options.socketManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        socketManager: SocketManager;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        const { socketManager, tokenManager, logger } = options;

        const messageType = SocketUrns.socketManager.unauthenticated;

        super(messageType);

        this.socketManager = socketManager;
        this.tokenManager = tokenManager;
        this.logger = logger;
    }

    /**
     *
     * @access protected
     * @since 4.13.0
     * @type {Object} message
     * @desc Handles reloading the token when unauthenticated socket message arrives
     *
     */
    public async process(message: RawSocketMessage) {
        const { data = {} } = message;
        const { code, description } = data as ErrorCodeDescription;
        const { socketManager } = this;

        if (!socketManager.enabled) {
            this.logger.error(
                'UnauthenticatedMessageProcessor',
                'Socket token refresh disabled.'
            );

            return;
        }

        if (
            code === 'access-token.invalid' &&
            ['auth.invalidated', 'auth.expired'].includes(description)
        ) {
            await this.tokenManager.refreshAccessToken();
        } else {
            this.logger.error(
                'UnauthenticatedMessageProcessor',
                `Unauthenticated message with unknown data: ${JSON.stringify(
                    message
                )}`
            );
        }
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.UnauthenticatedMessageProcessor';
    }
}
