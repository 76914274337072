/**
 *
 * @module paywallClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/paywall.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/paywall.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import Logger from '../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import Assertion from './assertion';
import Paywall from './paywall';
import PaywallClientConfiguration from './paywallClientConfiguration';
import PaywallClientEndpoint from './paywallClientEndpoint';
import Product from './product';
import LogTransaction from '../../logging/logTransaction';

import appendQuerystring from '../util/appendQuerystring';

import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import PurchaseContext from './purchaseContext';

const PaywallClientDustUrnReference =
    DustUrnReference.services.paywall.paywallClient;

/**
 *
 * @access protected
 * @since 3.9.0
 * @desc Provides a data client that can be used to access paywall services.
 *
 */
export default class PaywallClient {
    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Services.Paywall.PaywallClientConfiguration}
     *
     */
    private config: PaywallClientConfiguration;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {CoreHttpClientProvider}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {String}
     * @desc determines if you should refresh the access token
     *
     */
    private refreshAccessTokenHeader: string;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.Paywall.PaywallClientConfiguration} paywallClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        paywallClientConfiguration: PaywallClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                paywallClientConfiguration: Types.instanceStrict(
                    PaywallClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = paywallClientConfiguration;

        this.logger = logger;

        this.httpClient = httpClient;

        this.refreshAccessTokenHeader = 'X-BAMTech-Refresh-Access-Token';

        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 3.9.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @param {String} [purchaseContextId]
     * @param {String<SDK.Services.Paywall.PurchaseContext>} [purchaseContext]
     * @desc Retrieves paywall information contextually sensitive to the environment and requester.
     * @note PurchaseContext is a flexible enum.
     * @returns {Promise<SDK.Services.Paywall.Paywall>}
     *
     */
    public getPaywall(
        accessToken: AccessToken,
        logTransaction: LogTransaction,
        purchaseContextId?: string,
        purchaseContext?: keyof typeof PurchaseContext
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction),
                purchaseContextId: Types.nonEmptyString.optional,
                purchaseContext: Types.nonEmptyString.optional
            };

            typecheck(this, 'getPaywall', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = PaywallClientEndpoint.paywall;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                purchaseContext,
                purchaseContextId
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: PaywallClientDustUrnReference.getPaywall,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;
                const {
                    paywallHash,
                    skus,
                    reason,
                    context,
                    assertions,
                    currentSubscription
                } = data;

                const products = skus.map((sku: TodoAny) => Product.parse(sku));
                const processedAssertions = assertions.map(
                    (item: TodoAny) => new Assertion(item)
                );

                const paywall = new Paywall({
                    accountEntitlementContext: context,
                    paywallHash,
                    products,
                    assertions: processedAssertions,
                    reason,
                    currentSubscription
                });

                return paywall;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} [options.accessToken]
     * @param {SDK.Services.Paywall.PaywallClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @param {String} [options.bodyType] - The expected response data type, executed after initial JSON attempt.
     * @returns {Object} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken?: AccessToken;
        endpointKey: keyof typeof PaywallClientEndpoint;
        data?: Record<string, unknown>;
        body?: Record<string, unknown>;
        bodyType?: string;
    }) {
        const { accessToken, endpointKey, body, data = {}, bodyType } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, headers } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        const url = appendQuerystring(href, queryString.stringify(data));

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: (accessToken as AccessToken).token
                    };
                }
            },
            headers
        );

        return {
            url,
            body: requestBody,
            bodyType,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.PaywallClient';
    }
}
