/**
 *
 * @module insertionPointPlacement
 * @see https://github.com/STRML/keyMirror
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointPlacement
 *
 * @property {String} BUMPER_PREROLL - Since `18.0.0`.
 * @property {String} PREROLL - Since `18.0.0`.
 * @property {String} MIDROLL - Since `18.0.0`.
 *
 * @example
 * InsertionPointPlacement.BUMPER_PREROLL // 'BUMPER_PREROLL'
 *
 */
export default keymirror({
    BUMPER_PREROLL: null,
    PREROLL: null,
    MIDROLL: null
});
