/**
 *
 * @module flexManagerConfiguration
 *
 */

import FlexClientConfiguration from '../flex/flexClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing Flex.
 *
 */
export default class FlexManagerConfiguration extends ServiceConfiguration<FlexClientConfiguration> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.FlexManagerConfiguration';
    }
}
