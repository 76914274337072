/**
 *
 * @module updateDeviceOperatingSystemRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';

/**
 *
 * @since 9.0.0
 *
 */
export default class UpdateDeviceOperatingSystemRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: TodoAny) {
        const query = UpdateDeviceOperatingSystemRequest.query;

        const variables = {
            input
        };

        super({
            query,
            variables,
            operationName: 'updateDeviceOperatingSystem'
        });
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/orchestration_graphql/query/updateDeviceOperatingSystemRequest.md
     * @returns {String}
     *
     */
    public static get query() {
        return `mutation updateDeviceOperatingSystem($input: UpdateDeviceOperatingSystemInput!) {
            updateDeviceOperatingSystem(updateDeviceOperatingSystem: $input) {
                accepted
            }
        }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.UpdateDeviceOperatingSystemRequest';
    }
}
