/**
 *
 * @module playbackEventListener
 *
 */

import PlaybackMetricsProvider from './playbackMetricsProvider';

/**
 *
 * @since 2.0.0
 *
 */
export default abstract class PlaybackEventListener {
    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackStartedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackStarted(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackPausedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackPaused(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackResumedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackResumed(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingStartedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onRebufferingStarted(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingEndedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onRebufferingEnded(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackEndedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackEnded(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackInitializedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackInitialized(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackReadyEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackReady(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekStartedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackSeekStarted(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekEndedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackSeekEnded(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AudioChangedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAudioChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.SubtitleChangedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onSubtitleChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.BitrateChangedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onBitrateChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AudioBitrateChangedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAudioBitrateChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MultivariantPlaylistFetchedEventData>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    public abstract onMultivariantPlaylistFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onVariantPlaylistFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MediaSegmentFetchedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onMediaSegmentFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.DrmKeyFetchedEventData} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onDrmKeyFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.PlaybackStartupEventData} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPlaybackReattempt(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 15.1.0
     * @param {Object} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public abstract onSuccessfulPlaylistLoad(args: TodoAny): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PresentationTypeChangedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onPresentationTypeChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.SnapshotCreatedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onSnapshotCreated(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {Object<SDK.Media.AdPodRequestedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public abstract onAdPodRequested(args: TodoAny): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {Object<SDK.Media.AdPodFetchedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAdPodFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {Object<SDK.Media.AdMultivariantFetchedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAdMultivariantFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {Object<SDK.Media.AdVariantFetchedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAdVariantFetched(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {Object<SDK.Media.AdPlaybackStartedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAdPlaybackStarted(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {Object<SDK.Media.AdPlaybackEndedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public abstract onAdPlaybackEnded(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ): void;

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.PlaybackEventListener';
    }
}
