/**
 *
 * @module accountDelegationRefreshTokenStorage
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from '../logging/logger';
import Events from '../events';
import PlatformProviders from '../services/providers/platformProviders';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';

/**
 *
 * @since 16.0.0
 * @desc Provides a storage mechanism for storing an account delegation refresh token.
 *
 */
export default class AccountDelegationRefreshTokenStorage extends EventEmitter {
    /**
     *
     * @access private
     * @since 16.0.0
     * @type {String}
     *
     */
    private clientId: string;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {String}
     *
     */
    private environment: string;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {String|null}
     *
     */
    private accountDelegationRefreshToken: Nullable<string>;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     *
     */
    private cacheKey: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Logging.Logger} options.logger
     * @param {String} [options.accountDelegationRefreshToken=null]
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        logger: Logger;
        accountDelegationRefreshToken?: string;
        storage: CoreStorageProvider;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    accountDelegationRefreshToken:
                        Types.nonEmptyString.optional,
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            clientId,
            environment,
            logger,
            accountDelegationRefreshToken,
            storage
        } = options;

        this.clientId = clientId;

        this.environment = environment;

        this.logger = logger;

        this.accountDelegationRefreshToken =
            accountDelegationRefreshToken ?? null;

        this.storage = storage;

        this.cacheKey = `
            __bam_sdk_account_delegation_refresh_token--${this.clientId}_${this.environment}
        `.replace(/(?:\n\s+)/g, '');

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {String} accountDelegationRefreshToken
     * @desc Stores given account delegation refresh token in memory and in Storage
     * @returns {Promise<Void>}
     *
     */
    public async saveAccountDelegationRefreshToken(
        accountDelegationRefreshToken: string
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accountDelegationRefreshToken: Types.nonEmptyString
            };

            typecheck(
                this,
                'saveAccountDelegationRefreshToken',
                params,
                arguments
            );
        }

        this.logger.info(
            this.toString(),
            `Saving account delegation refresh token to Storage for key: "${this.cacheKey}".`
        );

        this.accountDelegationRefreshToken = accountDelegationRefreshToken;

        await this.storage.set(
            this.cacheKey,
            this.accountDelegationRefreshToken
        );

        this.emit(
            Events.AccountDelegationRefreshTokenChanged,
            accountDelegationRefreshToken
        );
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @desc Gets account delegation refresh token from memory
     * @returns {String|null}
     *
     */
    public getAccountDelegationRefreshToken() {
        return this.accountDelegationRefreshToken;
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @desc Deletes the current account delegation refresh token from memory and from Storage
     * @returns {Promise<Void>}
     *
     */
    public async clear() {
        this.logger.warn(
            this.toString(),
            `Deleting account delegation refresh token from Storage for key: "${this.cacheKey}".`
        );

        this.accountDelegationRefreshToken = null;

        await this.storage.remove(this.cacheKey);
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @desc Loads account delegation refresh token from Storage into Memory.
     * @note Checks Storage to determine if a refresh token was previously stored.
     * @note Should fail silently if no refresh token found but still returns it from memory if there is one.
     * @returns {Promise<String|null>}
     *
     */
    public async loadAccountDelegationRefreshTokenFromStorage() {
        this.logger.info(
            this.toString(),
            `Loading account delegation refresh token from Storage for key: "${this.cacheKey}".`
        );

        try {
            const accountDelegationRefreshToken = await this.storage.get(
                this.cacheKey
            );

            if (accountDelegationRefreshToken) {
                this.accountDelegationRefreshToken =
                    accountDelegationRefreshToken;

                return this.accountDelegationRefreshToken;
            }
        } catch (ex) {} // eslint-disable-line no-empty

        if (Check.assigned(this.accountDelegationRefreshToken)) {
            this.logger.info(
                this.toString(),
                'Could not load account delegation refresh token from Storage but it is in memory.'
            );
        } else {
            this.logger.info(
                this.toString(),
                'Could not load account delegation refresh token from Storage and memory is null.'
            );
        }

        return this.accountDelegationRefreshToken;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Token.AccountDelegationRefreshTokenStorage';
    }
}
