/**
 *
 * @module playbackAttributes
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import DrmType from './drmType';
import PlaybackSecurity from './playbackSecurity';

/**
 *
 * @since 4.0.0
 * @desc Contains information about the playlist streams.
 *
 */
export default class PlaybackAttributes {
    /**
     *
     * @param {Object} options
     * @param {String} [options.adPartner]
     * @param {Array<SDK.Services.Media.DrmType>} options.drms
     * @param {SDK.Services.Media.PlaybackSecurity} options.security
     * @param {String} options.encryptionType
     * @param {Array<String>} options.audioSegmentTypes
     * @param {Array<String>} options.videoSegmentTypes
     * @param {Array<String>} options.videoRanges
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    adPartner: Types.nonEmptyString.optional,
                    drms: Types.array.of.in(DrmType),
                    security: Types.instanceStrict(PlaybackSecurity),
                    encryptionType: Types.nonEmptyString,
                    audioSegmentTypes: Types.array.of.nonEmptyString,
                    videoSegmentTypes: Types.array.of.nonEmptyString,
                    videoRanges: Types.array.of.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            adPartner,
            drms,
            security,
            encryptionType,
            audioSegmentTypes,
            videoSegmentTypes,
            videoRanges
        } = options;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc Gets the ad partner ID
         *
         */
        this.adPartner = adPartner;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Array<SDK.Services.Media.DrmType>}
         * @desc Gets the list of DRMs supported by this content
         *
         */
        this.drms = drms;

        /**
         *
         * @access public
         * @since 8.0.0
         * @type {SDK.Services.Media.PlaybackSecurity}
         * @desc Gets the media playback security object
         *
         */
        this.security = security;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String}
         *
         */
        this.encryptionType = encryptionType;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Array<String>}
         *
         */
        this.audioSegmentTypes = audioSegmentTypes;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Array<String>}
         *
         */
        this.videoSegmentTypes = videoSegmentTypes;

        /**
         *
         * @access public
         * @since 4.5.0
         * @type {Array<String>}
         *
         */
        this.videoRanges = videoRanges;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} data
     * @desc Returns a PlaybackAttributes object or undefined if data is not assigned.
     * @returns {SDK.Services.Media.PlaybackAttributes|undefined}
     *
     */
    static parse(data) {
        if (data) {
            const security = new PlaybackSecurity(data.security);

            return new PlaybackAttributes({
                ...data,
                security
            });
        }

        return undefined;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackAttributes';
    }

    // #endregion
}
