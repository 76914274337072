/**
 *
 * @module storage
 *
 */

import CoreStorageProvider from '../shared/coreStorageProvider';
import Logger from './../../../logging/logger'; // eslint-disable-line no-unused-vars
import getScope from './../../util/getScope';
import hasLocalStorage from '../../util/hasLocalStorage';
import LocalStore from '../store/localStore';
import MemoryStore from '../store/memoryStore';
import MemoryFirstStore from '../store/memoryFirstStore';
import JsonStorageMiddlewareStore from '../store/jsonStorageMiddlewareStore';

interface StorageOptions {
    logger: Logger;
    useMemoryFirst?: boolean;
}

/**
 *
 * @access protected
 * @desc Browser based Storage provider implementation. Based on localStorage for browser and Node.
 *
 */
export default class Storage extends CoreStorageProvider {
    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {Boolean} [options.useMemoryFirst=true]
     *
     */
    public constructor(options: StorageOptions) {
        let platformStore;
        let store;

        const { logger, useMemoryFirst = true } = options;

        if (hasLocalStorage()) {
            const scope: TodoAny = getScope();
            const localStorage = scope.localStorage;

            platformStore = new LocalStore(localStorage);

            platformStore = new JsonStorageMiddlewareStore(platformStore);
        }

        if (platformStore) {
            if (useMemoryFirst) {
                store = new MemoryFirstStore(platformStore);
            } else {
                store = platformStore;
            }
        } else {
            logger.warn(
                'Storage',
                'No local storage implementation detected. Using memory storage'
            );

            store = new MemoryStore();
        }

        super({ logger, store });

        this.logger.log(this.toString(), 'Created Browser Provider.');
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Providers.Browser.Storage';
    }
}
