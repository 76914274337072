/**
 *
 * @module serviceEnvironmentName
 *
 */

/**
 *
 * @access public
 * @enum
 * @since 4.10.0
 * @typedef {Object} SDK.Services.Configuration.ServiceEnvironmentName
 * @desc Enum for the possible environment values in the BootstrapConfiguration
 *
 * @property {String} prod - Since `4.10.0`.
 * @property {String} qa - Since `4.10.0`.
 * @property {String} mock - Since `4.10.0`.
 * @property {String} rapid - Since `4.10.0`.
 *
 */

enum ServiceEnvironmentName {
    prod = 'prod',
    qa = 'qa',
    mock = 'mock',
    rapid = 'rapid'
}

export default ServiceEnvironmentName;
