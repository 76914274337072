/**
 *
 * @module invoiceClient
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import {
    Invoice,
    InvoiceTypedef,
    PaginatedInvoicesResponse,
    PaginatedInvoicesResponseTypedef
} from './typedefs';

import Logger from '../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';

import InvoiceClientConfiguration from './invoiceClientConfiguration';
import InvoiceClientEndpoint from './invoiceClientEndpoint';

import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import LogTransaction from '../../logging/logTransaction';

import appendQuerystring from '../util/appendQuerystring';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';

const InvoiceClientDustUrnReference =
    DustUrnReference.services.invoice.invoiceClient;

/**
 *
 * @access protected
 * @since 4.12.0
 * @desc Provides a data client that can be used to access invoice services.
 *
 */
export default class InvoiceClient {
    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Services.Invoice.InvoiceClientConfiguration}
     *
     */
    private config: InvoiceClientConfiguration;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {CoreHttpClientProvider}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access public
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.Invoice.InvoiceClientConfiguration} invoiceClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        invoiceClientConfiguration: InvoiceClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                invoiceClientConfiguration: Types.instanceStrict(
                    InvoiceClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = invoiceClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {Object} options
     * @param {String} options.link - path used to get a specific invoice.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Invoice.Invoice>>}
     *
     */
    public getInvoice(options: {
        link: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<Invoice> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    link: Types.nonEmptyString,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getInvoice', params, arguments);
        }

        const { link, accessToken, logTransaction } = options;
        const { dustEnabled, logger } = this;

        const endpointKey = InvoiceClientEndpoint.getInvoice;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                link
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: InvoiceClientDustUrnReference.getInvoice,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(InvoiceTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {Object} options
     * @param {String} [options.pageToken] - token containing the information necessary to retrieve the next page of results.
     * @param {Number} [options.pageSize] - The maximum number of results to return in a single response.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Invoice.PaginatedInvoicesResponse>>}
     *
     */
    public getPaginatedInvoices(options: {
        pageToken?: string;
        pageSize?: number;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<PaginatedInvoicesResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    pageToken: Types.nonEmptyString.optional,
                    pageSize: Types.number.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPaginatedInvoices', params, arguments);
        }

        const { pageToken, pageSize, accessToken, logTransaction } = options;
        const { dustEnabled, logger } = this;

        const endpointKey = InvoiceClientEndpoint.getPaginatedInvoices;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                pageToken,
                pageSize
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: InvoiceClientDustUrnReference.getPaginatedInvoices,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(PaginatedInvoicesResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Invoice.InvoiceClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {Object} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof InvoiceClientEndpoint;
        data: { link?: string; pageToken?: string; pageSize?: number };
        body?: object;
    }): GetPayloadResult {
        const { accessToken, endpointKey, data = {}, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, headers } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        let url;

        if (endpointKey === InvoiceClientEndpoint.getPaginatedInvoices) {
            url = appendQuerystring(href, queryString.stringify(data));
        }

        if (endpointKey === InvoiceClientEndpoint.getInvoice) {
            url = `${href}${data.link}`;
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: url as string,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Invoice.InvoiceClient';
    }
}
