/**
 *
 * @module sdkSessionConfiguration
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/logging.md#service-calls
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../../logging/logger';

import ServiceDefinitionsConfiguration from './serviceDefinitionsConfiguration';
import PlatformProviders from './../providers/platformProviders';
import type { Application } from './typedefs';
import EnvironmentConfiguration from '../providers/browser/environmentConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary to create an SdkSession instance.
 *
 */
export default class SdkSessionConfiguration {
    /**
     *
     * @access public
     * @type {Object}
     * @desc Gets or sets the collection of headers to be used for all service requests.
     * @note Common header structure:
     *
     *   "commonHeaders": {
     *     "X-Application-Version": "{applicationVersion}",
     *     "X-BAMSDK-Client-ID": "disney-svod-3d9324fc",
     *     "X-BAMSDK-Platform": "{SDKPlatform}",
     *     "X-BAMSDK-Version": "{SDKVersion}",
     *     "X-DSS-Edge-Accept": "vnd.dss.edge+json; version=2"
     *   }
     *
     */
    public commonHeaders: Record<string, string>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Object}
     * @desc Gets or sets the collection of values to be used for all service requests.
     * @note Common values structure:
     *
     *   "commonValues": {
     *     "platformId": "macbook"
     *   }
     *
     */
    public commonValues: Record<string, unknown>;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ServiceDefinitionsConfiguration}
     *
     */
    public services: ServiceDefinitionsConfiguration;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Object}
     *
     */
    public application: Application;

    /**
     *
     * @param {Object} options
     * @param {Object} options.commonHeaders
     * @param {Object} [options.commonValues]
     * @param {Object} options.services
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} options.environmentConfiguration
     * @param {Object} options.application
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        commonHeaders: Record<string, string>;
        commonValues?: object;
        services: object;
        environmentConfiguration: EnvironmentConfiguration;
        application: Application;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commonHeaders: Types.nonEmptyObject,
                    commonValues: Types.nonEmptyObject.optional,
                    services: Types.nonEmptyObject,
                    environmentConfiguration: Types.instanceStrict(
                        PlatformProviders.EnvironmentConfiguration
                    ),
                    application: Types.nonEmptyObject,
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            application,
            commonHeaders,
            commonValues,
            environmentConfiguration,
            logger,
            services
        } = options;

        this.commonHeaders = {};
        this.commonValues = {};
        this.services = {} as ServiceDefinitionsConfiguration;
        this.application = application;

        /**
         *
         * @desc process plain config Object and create configuration instances
         *
         */
        this.processConfig(
            {
                commonHeaders,
                commonValues,
                services,
                application
            },
            environmentConfiguration,
            logger
        );
    }

    /**
     *
     * @access private
     * @param {Object} config - JSON configuration object returned from configuration service endpoint.
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} environmentConfiguration
     * @param {SDK.Logging.Logger} logger
     * @desc Initialize SdkSessionConfiguration by processing config object.
     *
     */
    private processConfig(
        config: TodoAny,
        environmentConfiguration: EnvironmentConfiguration,
        logger: Logger
    ) {
        const { commonHeaders, commonValues = {}, services } = config;
        const {
            sdkVersion,
            applicationVersion,
            constructedPlatformIdentifier
        } = environmentConfiguration;

        this.commonHeaders = commonHeaders;
        this.commonValues = commonValues;

        const versionHeader = this.commonHeaders['X-BAMSDK-Version'].replace(
            /\{SDKVersion\}/gi,
            sdkVersion
        );
        const platformHeader = this.commonHeaders['X-BAMSDK-Platform'].replace(
            /\{SDKPlatform\}/gi,
            constructedPlatformIdentifier
        );
        const appVersionHeader = this.commonHeaders[
            'X-Application-Version'
        ].replace(/\{applicationVersion\}/gi, applicationVersion);

        /**
         *
         * @desc The sdk version which does not include a "v" prefix, e.g. `1.0.2` (major.minor for the JS SDK, e.g. 13.0)
         *
         */
        this.commonHeaders['X-BAMSDK-Version'] = versionHeader;

        /**
         *
         * @desc The SDK platform is constructed from `deviceFamily`, `applicationRuntime`, and `deviceProfile`, e.g. `microsoft/uwp/xbox`
         * @note Javascript will have `applicationRuntime` and `deviceProfile` swapped to align with the config url
         * @note Javascript will use the value of `javascript` for `deviceFamily` instead of `browser`
         * @note Javascript example: `javascript/samsung/tizen`
         * @example
         *   SDK: "{deviceFamily}/{applicationRuntime}/{deviceProfile}"
         *   JS SDK: "javascript/{deviceProfile}/{applicationRuntime}"
         *
         */
        this.commonHeaders['X-BAMSDK-Platform'] = platformHeader;

        /**
         *
         * @desc The application version provided by the application developer and will be required.
         *
         */
        this.commonHeaders['X-Application-Version'] = appVersionHeader;

        this.services = new ServiceDefinitionsConfiguration({
            services,
            commonHeaders,
            commonValues,
            logger
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.SdkSessionConfiguration';
    }
}
