/**
 *
 * @module eligibilityPlugin
 * @desc {SDK.Eligibility.EligibilityPlugin} entry point.
 *
 */

import Eligibility from './entry';
import EligibilityManager from './eligibilityManager';

import EligibilityService from '../services/eligibility/entry';
import EligibilityClient from '../services/eligibility/eligibilityClient';

import getSafe from '../services/util/getSafe';

import EligibilityClientConfiguration from './../services/eligibility/eligibilityClientConfiguration';
import EligibilityManagerConfiguration from './../services/configuration/eligibilityManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<
    typeof Eligibility,
    'Eligibility',
    typeof EligibilityService
> = {
    pluginName: 'Eligibility',
    entry: Eligibility,
    service: EligibilityService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services, accessTokenProvider } =
            options;

        managers.eligibilityManager = new EligibilityManager({
            config: services.eligibility,
            client: new EligibilityClient({
                eligibilityClientConfiguration: services.eligibility.client,
                logger,
                httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new EligibilityClientConfiguration(
            getSafe(() => services.eligibility.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.eligibility.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.eligibility =
            new EligibilityManagerConfiguration(
                clientConfiguration,
                undefined,
                services.eligibility.disabled
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
