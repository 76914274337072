/**
 *
 * @module sdk
 * @desc {BAM.SDK} entry point (core).
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#configuration
 *
 */

import { Config } from '@dss/type-checking';

import type { IPlugin, IPluginTypes } from './IPlugin';

import Configuration from './configuration/entry';
import Device from './device/entry';
import Internal from './internal/entry';
import Logging from './logging/entry';
import Orchestration from './orchestration/entry';
import Services from './services/entry';
import Session from './session/entry';
import Subscription from './subscription/entry';
import Token from './token/entry';
import VersionInfo from './versionInfo';

import SdkSession from './sdkSession';
import InternalEvents from './internalEvents';
import Events from './events';
import OffDeviceTokenRefreshEvent from './offDeviceTokenRefreshEvent';
import ReauthorizationFailure from './reauthorizationFailure';
import MonotonicTimestampManager from './monotonicTimestampManager';
import Endpoint from './endpoint';
import DiagnosticsApi from './diagnosticsApi';
import DiagnosticFeature from './diagnosticFeature';
import SessionInfoChangedEvent from './sessionInfoChangedEvent';
import AccessChangedEvent from './accessChangedEvent';
import FeatureFlagsChangedEvent from './featureFlagsChangedEvent';
import InitializationState from './initializationState';
import PlatformProviders from './services/providers/platformProviders';
import AgeVerificationFlowType from './ageVerificationFlowType';

/* istanbul ignore else */
if (__SDK_TYPECHECK__) {
    Config.setup({
        ErrorType: Services.Exception.InvalidArgumentException,
        logger: Logging
    });
}

const SDKCore = {
    /**
     *
     * @access public
     * @returns {VersionInfo}
     *
     */
    get VersionInfo() {
        return VersionInfo;
    },

    /**
     *
     * @param {Object} options
     * @param {Function<HttpClient>} options.HttpClient - an `HttpClient` class that implements `CoreHttpClientProvider`
     * @param {Function<SDK.Services.Configuration.BaseEnvironmentConfiguration>} options.EnvironmentConfiguration - an `EnvironmentConfiguration` class
     * @param {Function<Storage>} options.Storage - A `Storage` class
     * @note we can't typecheck the specific providers due to circular reference issues
     * in builds so we do an at minimum function/object structure validation.
     *
     */
    bootstrapProviders(options: TodoAny) {
        PlatformProviders.setProviders(options);
    },

    /**
     *
     * @access public
     * @returns {SDK.Configuration}
     *
     */
    get Configuration() {
        return Configuration;
    },

    /**
     *
     * @access public
     * @returns {SDK.Device}
     *
     */
    get Device() {
        return Device;
    },

    /**
     *
     * @access public
     * @since 9.0.0
     * @returns {SDK.InitializationState}
     *
     */
    get InitializationState() {
        return InitializationState;
    },

    /**
     *
     * @access private
     * @returns {SDK.Internal}
     *
     */
    get Internal() {
        return Internal;
    },

    /**
     *
     * @access public
     * @returns {SDK.Logging}
     *
     */
    get Logging() {
        return Logging;
    },

    /**
     *
     * @access public
     * @since 4.17.0
     * @returns {SDK.Orchestration}
     *
     */
    get Orchestration() {
        return Orchestration;
    },

    /**
     *
     * @access private
     * @returns {SDK.Services}
     *
     */
    get Services() {
        return Services;
    },

    /**
     *
     * @access public
     * @returns {SDK.Subscription}
     *
     */
    get Subscription() {
        return Subscription;
    },

    /**
     *
     * @access public
     * @returns {SDK.Token}
     *
     */
    get Token() {
        return Token;
    },

    /**
     *
     * @access public
     * @returns {SDK.Session}
     *
     */
    get Session() {
        return Session;
    },

    /**
     *
     * @access public
     * @returns {SdkSession}
     *
     */
    get SdkSession() {
        return SdkSession;
    },

    /**
     *
     * @access private
     * @since 10.0.0
     * @returns {SDK.InternalEvents}
     *
     */
    get InternalEvents() {
        return InternalEvents;
    },

    /**
     *
     * @access public
     * @since 10.0.0
     * @returns {SDK.Events}
     *
     */
    get Events() {
        return Events;
    },

    /**
     *
     * @access public
     * @since 4.15.0
     * @returns {OffDeviceTokenRefreshEvent}
     *
     */
    get OffDeviceTokenRefreshEvent() {
        return OffDeviceTokenRefreshEvent;
    },

    /**
     *
     * @access public
     * @returns {ReauthorizationFailure}
     *
     */
    get ReauthorizationFailure() {
        return ReauthorizationFailure;
    },

    /**
     *
     * @access public
     * @since 20.0.0
     * @returns {MonotonicTimestampManager}
     *
     */
    get MonotonicTimestampManager() {
        return MonotonicTimestampManager;
    },

    /**
     *
     * @access public
     * @returns {Endpoint}
     *
     */
    get Endpoint() {
        return Endpoint;
    },

    /**
     *
     * @access public
     * @since 4.11.0
     * @returns {DiagnosticsApi}
     *
     */
    get DiagnosticsApi() {
        return DiagnosticsApi;
    },

    /**
     *
     * @access public
     * @since 4.11.0
     * @returns {DiagnosticFeature}
     *
     */
    get DiagnosticFeature() {
        return DiagnosticFeature;
    },

    /**
     *
     * @access public
     * @returns {SessionInfoChangedEvent}
     *
     */
    get SessionInfoChangedEvent() {
        return SessionInfoChangedEvent;
    },

    /**
     *
     * @access public
     * @since 9.0.0
     * @returns {AccessChangedEvent}
     *
     */
    get AccessChangedEvent() {
        return AccessChangedEvent;
    },

    /**
     *
     * @access public
     * @since 15.0.0
     * @returns {FeatureFlagsChangedEvent}
     *
     */
    get FeatureFlagsChangedEvent() {
        return FeatureFlagsChangedEvent;
    },

    /**
     *
     * @access public
     * @since 12.0.0
     * @returns {AgeVerificationFlowType}
     *
     */
    get AgeVerificationFlowType() {
        return AgeVerificationFlowType;
    }
};

type GetPluginName<T extends IPluginTypes> = T['pluginName'];

type GetPluginEntry<T extends IPluginTypes> = T['entry'];

type GetServicePluginName<T> = T extends IPlugin<unknown, string, unknown>
    ? T['pluginName']
    : never;

type GetServicePlugin<T> = T extends IPlugin<unknown, string, unknown>
    ? T['service']
    : never;

type extendTypesWithPlugins<TAry extends Array<IPluginTypes>> = {
    [K in TAry[number] as GetPluginName<K>]: GetPluginEntry<K>;
};

type extendTypesWithService<TAry extends Array<IPluginTypes>> = {
    [K in TAry[number] as GetServicePluginName<K>]: GetServicePlugin<K>;
};

export type ISDK<TPlugins extends Array<IPluginTypes>> = typeof SDKCore &
    extendTypesWithPlugins<TPlugins> & {
        Services: extendTypesWithService<TPlugins>;
    };

/**
 *
 * @desc The BAM SDK manages all BAM platform service API interactions and simplifies client-side integrations.
 *
 */

function extendSDKWithPlugins<T extends typeof SDKCore>(
    plugins: Array<IPluginTypes>
): T {
    const extendedSdk = { ...SDKCore } as ISDK<typeof plugins>;

    for (const plugin of plugins) {
        SdkSession.attachPlugin(plugin);

        const { service, pluginName, entry } = plugin as IPlugin<
            unknown,
            string,
            unknown
        >;

        extendedSdk[pluginName] = entry;

        if (service) {
            extendedSdk.Services[pluginName] = service;
        }
    }

    return extendedSdk as unknown as T;
}

export default { ...SDKCore, extendSDKWithPlugins };
