/**
 *
 * @module invoiceManagerConfiguration
 *
 */

import InvoiceClientConfiguration from '../invoice/invoiceClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for invoice.
 *
 */
export default class InvoiceManagerConfiguration extends ServiceConfiguration<InvoiceClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.InvoiceManagerConfiguration';
    }

    // #endregion
}
