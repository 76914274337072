/**
 *
 * @module applicationContext
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ApplicationContext
 * @desc Used to distinguish where in the application the error is being sent from.
 * @note `player` should be sent when an error occurs anywhere in the video player for most playback (content, bumpers, dub cards, etc.),
 * beginning from playback intent to playback ended.
 * @note `ad` should be sent when an error is related to ads, both inside and outside the video player.
 *
 * @property {String} player - Since `13.0.0`
 * @property {String} account - Since `13.0.0`
 * @property {String} login - Since `13.0.0`
 * @property {String} onboarding - Since `13.0.0`
 * @property {String} ad - Since `19.0.0`
 *
 * @example
 * SDK.Services.QualityOfService.ApplicationContext.player // 'player'
 *
 */
export default keyMirror({
    player: null,
    account: null,
    login: null,
    onboarding: null,
    ad: null
});
