/**
 *
 * @module entry
 * @desc {SDK.Services.Ripcut} entry point.
 *
 */

import RipcutClient from './ripcutClient';
import RipcutClientConfiguration from './ripcutClientConfiguration';

/**
 *
 * @typedef {Object} SDK.Services.Ripcut
 *
 * @property {RipcutClient} RipcutClient
 * @property {RipcutClientConfiguration} RipcutClientConfiguration
 *
 */
export default {
    RipcutClient,
    RipcutClientConfiguration
};
