/**
 *
 * @module accessContext
 * @desc The AccessContext contains tokens which prove the client has
 * been granted authorization to use BAM services. Additionally, content feature
 * responses have the ability to be user and platform specific based on a given AccessContext.
 *
 */

import RefreshGrant from './refreshGrant';

/**
 *
 * @desc Represents the response from exchanging a token and provides
 * access context for subsequent service requests.
 * @note Also acts as a TokenRequestBuilder (via RefreshGrant) to refresh itself.
 *
 */
export default class AccessContext extends RefreshGrant {
    /**
     *
     * @param {Object} options
     * @param {String} options.token
     * @param {String} options.tokenType
     * @param {String<SDK.Services.Token.AccessTokenType>} [options.accessTokenType]
     * @param {Number} [options.expiresIn=600]
     * @param {Number} [options.issuedAt=Date.now()]
     * @param {String} [options.region]
     * @param {String} [options.refreshToken]
     * @param {Object} [options.tokenData={}]
     * @param {String} [options.tokenData.refresh_token]
     *
     */
    constructor(options) {
        const {
            token,
            tokenType,
            accessTokenType,
            expiresIn,
            issuedAt,
            region,
            refreshToken,
            tokenData = {}
        } = options;

        super(refreshToken || tokenData.refresh_token);

        /**
         *
         * @access public
         * @type {String}
         * @desc The access token issued by the authorization server.
         *
         */
        this.token = token;

        /**
         *
         * @access private
         * @type {String}
         * @desc Gets or sets the token type.
         *
         */
        this.tokenType = tokenType;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The lifetime in seconds of the access token from the time that it was issued.
         *
         */
        this.expiresIn = expiresIn || 600;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The timestamp of when the access token was issued (set by the SDK as now).
         * @note Services do not provide us with this data.
         * @note Returns the number of milliseconds elapsed since 1 January 1970 00:00:00 UTC.
         *
         */
        this.issuedAt = issuedAt || Date.now();

        /**
         *
         * @access public
         * @since 4.6.0
         * @type {String|undefined}
         * @desc The edge region that was used to process the token exchange.
         * @note This value comes from the x-bamtech-region response header, not the json.
         *
         */
        this.region = region;

        /**
         *
         * @access public
         * @since 4.17.0
         * @type {String<SDK.Services.Token.AccessTokenType>|undefined}
         * @desc The type of the accessToken issued.
         *
         */
        this.accessTokenType = accessTokenType;
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {Number} refreshThreshold - The refresh threshold (setting in the config)
     * @desc Determines the timestamp of when the access token should be refreshed
     * @returns {Number}
     *
     */
    getRefreshAt(refreshThreshold) {
        return this.issuedAt + this.expiresIn * refreshThreshold * 1000;
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {Number} refreshThreshold - The refresh threshold (setting in the config)
     * @desc Determines whether the access is expired
     * @returns {Boolean}
     *
     */
    isAccessTokenExpired(refreshThreshold) {
        const refreshAt = this.getRefreshAt(refreshThreshold);

        const now = Date.now();

        if (now >= refreshAt) {
            return true;
        }

        // If the current system clock time is before the token's issuedAt time then assume that the user has
        // changed their system clock to a time in the past. This will help guard against cases where we get a
        // false negative when checking if the token has expired.
        return now < this.issuedAt;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.AccessContext';
    }

    // #endregion
}
