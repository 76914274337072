/**
 *
 * @module grant
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Provides a base implementation for a grant.
 *
 */
export default class Grant {
    /**
     *
     * @param {String} [grantType]
     * @param {String} assertion
     *
     */
    constructor(grantType, assertion) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                grantType: Types.nonEmptyString.optional,
                assertion: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the grant type. This must be a valid type that is recognized by the service.
         *
         */
        this.grantType = grantType;

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the packet of security information for the token.
         *
         */
        this.assertion = assertion;

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the subject token type key used to lookup
         * the subject_token_type value needed during token exchange.
         * @note for lookup purposes only, should not be included in a request body.
         *
         */
        this.subjectTokenTypeKey = '';
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.Grant';
    }

    // #endregion
}
