/**
 *
 * @module playbackSelectionAttributes
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AudioType from './audioType';
import HdrType from './hdrType';
import VideoResolution from './videoResolution';
import CodecAttributes from './codecAttributes';
import Protocol from './protocol';

import {
    AssetInsertionStrategy,
    PlaybackInitiationContext,
    SlugDuration
} from './../../media/enums';

/**
 *
 * @since 4.18.0
 *
 */
export default class PlaybackSelectionAttributes {
    /**
     *
     * @param {Object} [options={}]
     * @param {Array<SDK.Services.Media.AudioType>} [options.audioTypes]
     * @param {Array<SDK.Services.Media.HdrType>} [options.hdrTypes]
     * @param {SDK.Services.Media.VideoResolution} [options.resolution]
     * @param {SDK.Services.Media.CodecAttributes} [options.codecs]
     * @param {SDK.Services.Media.Protocol} [options.protocol]
     * @param {String} [options.ads]
     * @param {String<SDK.Media.AssetInsertionStrategy>} options.assetInsertionStrategy
     * @param {String<SDK.Media.PlaybackInitiationContext>} [options.playbackInitiationContext]
     * @param {Array<Number>} [options.frameRates]
     * @param {String<SDK.Media.SlugDuration} [options.slugDuration]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    audioTypes: Types.array.of.in(AudioType).optional,
                    hdrTypes: Types.array.of.in(HdrType).optional,
                    resolution: Types.instanceStrict(VideoResolution).optional,
                    codecs: Types.instanceStrict(CodecAttributes).optional,
                    protocol: Types.in(Protocol).optional,
                    ads: Types.nonEmptyString.optional,
                    assetInsertionStrategy: Types.in(AssetInsertionStrategy),
                    playbackInitiationContext: Types.in(
                        PlaybackInitiationContext
                    ).optional,
                    frameRates: Types.array.of.number.optional,
                    slugDuration: Types.in(SlugDuration).optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            audioTypes,
            hdrTypes,
            resolution,
            codecs,
            protocol,
            ads,
            assetInsertionStrategy,
            playbackInitiationContext,
            frameRates,
            slugDuration
        } = options || {};

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {Array<SDK.Services.Media.AudioType>|undefined}
         * @desc Array of additional supported audio types for the device. e.g. ["ATMOS"].
         *
         */
        this.audioTypes = audioTypes;

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {Array<SDK.Services.Media.HdrType>|undefined}
         * @desc Array of additional supported hdr types for the device. Acceptable values: ["DOLBY_VISION","HDR10"].
         * @note This property is called `hdrTypes` in spec but needs to be serialized
         * as `videoRanges`.
         *
         */
        this.videoRanges = hdrTypes;

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {SDK.Services.Media.VideoResolution|undefined}
         * @desc Contains an array of resolutions used for filtering during playback selection to limit media quality.
         * Acceptable values: ["1920x1080","1280x720"].
         *
         */
        this.resolution = resolution;

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {SDK.Services.Media.CodecAttributes|undefined}
         * @desc Protocol string used for filtering during playback selection.
         *
         */
        this.codecs = codecs;

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {SDK.Services.Media.Protocol|undefined}
         * @desc String to indicate whether `https` is required, `http` is default.
         *
         */
        this.protocol = protocol;

        /**
         *
         * @access public
         * @since 7.0.0
         * @type {String|undefined}
         * @desc String to indicate which ad partner to use.
         * @note Some platforms may not be able to access the `SDK.Media` namespace from this model and will use
         * `SDK.Services.Media` instead.
         *
         */
        this.ads = ads;

        /**
         *
         * @access public
         * @since 17.0.0
         * @type {String<SDK.Media.AssetInsertionStrategy>}
         * @desc Indicates the ad insertion strategy used, if any.
         * @note Omit from PlaybackSelectionAttributes if AssetInsertionStrategy evaluates to `none`.
         *
         */
        this.assetInsertionStrategy = assetInsertionStrategy;

        /**
         *
         * @access public
         * @since 17.0.0
         * @type {String<SDK.Media.PlaybackInitiationContext>|undefined}
         * @desc Indicates the context for initiating playback. SDK may default to legacy ad support if not provided.
         *
         */
        this.playbackInitiationContext = playbackInitiationContext;

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {Array<Number>|undefined}
         * @desc Number to indicate max frameRate to use in playback selection. Main use case is low frame rate
         * for devices that can't play higher than 30fps.
         * @note Serialized as the property 'frameRates'.
         *
         */
        this.frameRates = frameRates;

        /**
         *
         * @access public
         * @since 21.0.0
         * @type {String<SDK.Media.SlugDuration>|undefined}
         * @desc Controls what duration slug is inserted around applicable breaks.
         *
         */
        this.slugDuration = slugDuration;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackSelectionAttributes';
    }

    // #endregion
}
