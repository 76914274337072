/**
 *
 * @module serviceEndpoint
 *
 */

import HttpMethod from './httpMethod';

/**
 *
 * @access protected
 * @desc Provides configuration information for a service endpoint (i.e. link).
 *
 */
export default class ServiceEndpoint {
    /**
     *
     * @param {Object} options
     * @param {String} options.rel
     * @param {String} options.href
     * @param {Integer} options.ttl
     * @param {Integer} options.timeout
     * @param {Boolean} options.templated
     * @param {String} [options.method=HttpMethod.Get]
     * @param {Object} [options.headers=null]
     * @param {Object} [options.optionalHeaders=null]
     *
     */
    constructor(options) {
        const {
            rel,
            href,
            ttl,
            timeout,
            templated,
            method = HttpMethod.GET,
            headers = null,
            optionalHeaders = null
        } = options;

        /**
         *
         * @access public
         * @type {String}
         *
         */
        this.rel = rel;

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the URL for the service request.
         *
         */
        this.href = href;

        /**
         *
         * @access public
         * @type {Integer}
         *
         */
        this.ttl = ttl;

        /**
         *
         * @access public
         * @type {Integer}
         * @desc Gets or sets the timeout for the service request.
         *
         */
        this.timeout = timeout;

        /**
         *
         * @access public
         * @type {Boolean}
         * @desc Gets or sets a value that indicates whether or not the href requires
         * template string substitution prior to sending the service request.
         *
         */
        this.templated = templated;

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the HTTP method that should be used for requests to the endpoint.
         *
         */
        this.method = method;

        /**
         *
         * @access public
         * @type {Object|null}
         * @desc Gets or sets the headers to append to the service request.
         *
         */
        this.headers = headers;

        /**
         *
         * @access public
         * @since 4.15.0
         * @type {Object|null}
         * @desc Gets or sets the optionalHeaders to append to the service request.
         *
         */
        this.optionalHeaders = optionalHeaders;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.ServiceEndpoint';
    }

    // #endregion
}
