/**
 *
 * @module fairPlayDrmProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md#fairplay
 * @see https://github.bamtech.co/services-commons/mdrm/blob/master/FairPlayStreaming_PG.pdf
 *
 */

import { Check, Types } from '@dss/type-checking';

import Events from './../events';
import DrmProvider from './drmProvider';
import FairPlayCertificateStorage from './fairPlayCertificateStorage';
import DrmType from './../services/media/drmType';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import type DrmManager from './drmManager';
import type TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';
import type MediaItem from '../media/mediaItem';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';

const DustUrn = DustUrnReference.drm.fairPlayDrmProvider;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @since 3.2.0
 * @desc FairPlay is the Apple content encryption standard.
 *
 */
export default class FairPlayDrmProvider extends DrmProvider {
    /**
     *
     * @access private
     * @type {SDK.Drm.FairPlayCertificateStorage}
     * @desc references the Storage API to get/set a certificate
     *
     */
    private certificateStorage: FairPlayCertificateStorage;

    /**
     *
     * @param {Object} options
     * @param {SDK.Drm.DrmManager} options.drmManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {String} [options.endpointKey]
     *
     */
    public constructor(options: {
        drmManager: DrmManager;
        tokenManager: TokenManager;
        logger: Logger;
        mediaItem: MediaItem;
        endpointKey?: keyof typeof DrmClientEndpoint;
    }) {
        super({
            ...options,
            type: DrmType.FAIRPLAY
        });

        this.certificateStorage = new FairPlayCertificateStorage({
            clientId: getSafe(() => this.drmManager.clientId),
            environment: getSafe(() => this.drmManager.environment),
            storage: getSafe(() => this.drmManager.storage),
            logger: this.logger
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {Uint8Array} serverPlaybackContext
     * @desc Gets a license required for decrypting FairPlay protected content.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public async getFairPlayLicense(
        serverPlaybackContext: Uint8Array
    ): Promise<Uint8Array>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            serverPlaybackContext: Types.arrayBufferView
        }
    })
    public async getFairPlayLicense(apiOptions: unknown): Promise<Uint8Array> {
        const {
            logTransaction,
            args: [serverPlaybackContext]
        } = apiOptions as ApiOptions;

        const { mediaItem, endpointKey } = this;

        try {
            return await this.drmManager.getFairPlayLicense({
                serverPlaybackContext,
                mediaItem,
                endpointKey,
                logTransaction
            });
        } catch (exception) {
            this.logger.error(
                this.toString(),
                'FairPlay license request failure.'
            );
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    /**
     *
     * @access protected
     * @param {IGNORE-PARAMS}
     * @desc Gets a certificate required for decrypting FairPlay protected content. First we attempt to retrieve the
     * certificate from storage, if that does not exist we make a new request for a new certificate, store that and
     * then return the certificate.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<Uint8Array>}
     *
     */
    public async getFairPlayCertificate(): Promise<Uint8Array>;

    @apiMethodDecorator()
    public async getFairPlayCertificate(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        const { mediaItem } = this;

        try {
            const storedCertificate =
                await this.certificateStorage.getStoredCertificate();

            if (Check.assigned(storedCertificate)) {
                return storedCertificate;
            }

            const certificate = await this.drmManager.getFairPlayCertificate(
                mediaItem,
                logTransaction
            );

            await this.certificateStorage.storeCertificate(certificate);

            return await this.certificateStorage.getStoredCertificate();
        } catch (exception) {
            this.logger.error(
                this.toString(),
                'FairPlay certificate request failure.'
            );
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    protected override toString() {
        return 'SDK.Drm.FairPlayDrmProvider';
    }

    // #endregion
}
