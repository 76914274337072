/**
 *
 * @module comcastApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    ComcastConsentRequest,
    ComcastConsentRequestTypedef,
    ComcastConsentResponse,
    ComcastPaymentRequest,
    ComcastPaymentRequestTypedef,
    CreateComcastPaymentMethodRequest,
    CreateComcastPaymentMethodRequestTypedef
} from './typedefs';

import {
    PaymentRedirectResponse,
    CreatePaymentMethodResponse
} from '../../services/commerce/typedefs';

import CommerceManager from '../commerceManager';
import BaseApi from '../../baseApi';
import Logger from './../../logging/logger';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';
import getSafe from '../../services/util/getSafe';

const DustUrn = DustUrnReference.commerce.comcastApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface ComcapstApiOptions {
    commerceManager: CommerceManager;
    logger: Logger;
}

/**
 *
 * @access public
 * @since 5.0.0
 * @desc Provides ability to access comcast data.
 *
 */
export default class ComcastApi extends BaseApi {
    /**
     *
     * @access private
     * @since 5.0.0
     * @type {SDK.Commerce.CommerceManager}
     *
     */
    private commerceManager: CommerceManager;

    /**
     *
     * @access private
     * @since 5.0.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Commerce.CommerceManager} options.commerceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: ComcapstApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceManager: Types.instanceStrict(CommerceManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceManager } = options;

        this.commerceManager = commerceManager;
        this.dustEnabled = getSafe(
            () => this.commerceManager.client.dustEnabled,
            false
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object<SDK.Commerce.Comcast.ComcastPaymentRequest>} request - request Information used to retrieve a paymentMethodId.
     * @desc Completes a Comcast transaction and returns a `paymentMethodId`.
     * @throws {ComcastConsentRefusedException} The request to the Comcast service failed due to a bad request.
     * @throws {ComcastServerErrorException} There was a failure from the Comcast service.
     * @throws {ComcastServiceUnavailableException} The Comcast service is unavailable.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>}
     *
     */
    public async submitPayment(
        request: ComcastPaymentRequest
    ): Promise<PaymentRedirectResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(ComcastPaymentRequestTypedef)
        }
    })
    public async submitPayment(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.submitComcastPayment(
            request,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.1.0
     * @param {Object<SDK.Commerce.Comcast.CreateComcastPaymentMethodRequest>} request
     * @desc Creates a new Comcast payment method which case be used later to submit an order.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public async createComcastPaymentMethod(
        request: CreateComcastPaymentMethodRequest
    ): Promise<CreatePaymentMethodResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(CreateComcastPaymentMethodRequestTypedef)
        }
    })
    public async createComcastPaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.createComcastPaymentMethod(
            request,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 10.1.0
     * @param {Object<SDK.Commerce.Comcast.ComcastConsentRequest>} request - Information used to retrieve consent information from Comcast.
     * @desc Returns a comcast post consent token object which can then be used in conjunction with the comcast consent api.
     * @throws {ComcastConsentRefusedException} The request to the Comcast service failed due to a bad request.
     * @throws {ComcastServerErrorException} There was a failure from the Comcast service.
     * @throws {ComcastServiceUnavailableException} The Comcast service is unavailable.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Commerce.Comcast.ComcastConsentResponse>>}
     *
     */
    public async getComcastConsent(
        request: ComcastConsentRequest
    ): Promise<ComcastConsentResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(ComcastConsentRequestTypedef)
        }
    })
    public async getComcastConsent(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.getComcastConsent(
            request,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.Comcast.ComcastApi';
    }

    // #endregion
}
