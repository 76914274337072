/**
 *
 * @module clearWatchlistQuery
 *
 */

import { Check } from '@dss/type-checking';

import ContentQuery from './../contentQuery';
import GraphQlPersistedQuery from './../graphQlPersistedQuery';

/**
 *
 * @desc Represents a Content query with context `watchlistPersisted` that is used to clear a user's watchlist.
 *
 */
export default class ClearWatchlistQuery extends ContentQuery {
    /**
     *
     * @param {String} [queryIdDefault='core/ClearWatchlist'] - Default query Id value from the config.
     *
     */
    public constructor(queryIdDefault: string) {
        const queryId = Check.nonEmptyString(queryIdDefault)
            ? queryIdDefault
            : 'core/ClearWatchlist';

        const graphQlPersistedQuery = new GraphQlPersistedQuery(queryId);

        super('watchlistPersisted', graphQlPersistedQuery);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.Watchlist.ClearWatchlistQuery';
    }
}
