/**
 *
 * @module drmClientEndpoint
 * @see https://github.com/STRML/keyMirror
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Drm.DrmClientEndpoint
 *
 * @property {String} silkKey
 * @property {String} fairPlayCertificate
 * @property {String} fairPlayLicense
 * @property {String} fairPlayLinearLicense - Since `9.0.0`.
 * @property {String} widevineCertificate
 * @property {String} widevineLicense
 * @property {String} widevineLinearLicense - Since `9.0.0`.
 * @property {String} playReadyLicense
 * @property {String} playReadyLinearLicense - Since `9.0.0`.
 * @property {String} nagraCertificate - Since `4.4.0`.
 * @property {String} nagraLicense - Since `4.4.0`.
 * @property {String} nagraLinearLicense - Since `9.0.0`.
 * @property {String} individualization - Since `4.4.0`.
 *
 */

enum DrmClientEndpoint {
    silkKey = 'silkKey',
    fairPlayCertificate = 'fairPlayCertificate',
    fairPlayLicense = 'fairPlayLicense',
    fairPlayLinearLicense = 'fairPlayLinearLicense',
    widevineCertificate = 'widevineCertificate',
    widevineLicense = 'widevineLicense',
    widevineLinearLicense = 'widevineLinearLicense',
    playReadyLicense = 'playReadyLicense',
    playReadyLinearLicense = 'playReadyLinearLicense',
    nagraCertificate = 'nagraCertificate',
    nagraLicense = 'nagraLicense',
    nagraLinearLicense = 'nagraLinearLicense',
    individualization = 'individualization'
}

export default DrmClientEndpoint;
