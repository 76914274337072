/**
 *
 * @module purchaseManagerConfiguration
 *
 */

import PurchaseClientConfiguration from '../purchase/purchaseClientConfiguration';
import PurchaseManagerExtrasMap from './purchaseManagerExtrasMap';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing receipt activation.
 *
 */
export default class PurchaseManagerConfiguration extends ServiceConfiguration<
    PurchaseClientConfiguration,
    PurchaseManagerExtrasMap
> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.PurchaseManagerConfiguration';
    }

    // #endregion
}
