/**
 *
 * @module entry
 * @desc {SDK.Services.Flex} entry point.
 *
 */

import FlexClient from './flexClient';
import FlexClientConfiguration from './flexClientConfiguration';

/**
 *
 * @typedef {Object} SDK.Services.Flex
 *
 * @property {FlexClient} FlexClient
 * @property {FlexClientConfiguration} FlexClientConfiguration
 *
 */
export default {
    FlexClient,
    FlexClientConfiguration
};
