/**
 *
 * @module adEngineManagerConfiguration
 *
 */

import AdEngineClientConfiguration from '../media/adEngine/adEngineClientConfiguration';
import AdEngineManagerExtrasMap from './adEngineManagerExtrasMap';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing adEngine.
 *
 */
export default class AdEngineManagerConfiguration extends ServiceConfiguration<
    AdEngineClientConfiguration,
    AdEngineManagerExtrasMap
> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.AdEngineManagerConfiguration';
    }

    // #endregion
}
