/**
 *
 * @module bamHlsPlayerAdapter
 * @desc PlayerAdapter for bam-hls devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/API.md
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/API.md#drm-configuration
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/demo/index.html
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/development/API.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import BamHlsErrorMapping from './../bamHlsErrorMapping';

import PublicEvents from './../../events';
import Logger from './../../logging/logger';
import PlayerAdapter from './../playerAdapter';
import PlaybackMetrics from './../playbackMetrics';
import PlaybackEventListener from './../playbackEventListener';
import Playlist from './../playlist';
import DrmType from '../../services/media/drmType';
import DrmProvider from '../../drm/drmProvider';

import AudioRendition from '../../services/media/audioRendition';
import PlaybackVariant from '../../services/media/playbackVariant';
import SubtitleRendition from '../../services/media/subtitleRendition';
import PlaylistType from '../../services/media/playlistType';

import FetchStatus from '../../services/qualityOfService/fetchStatus';
import PlaybackExitedCause from '../../services/qualityOfService/playbackExitedCause';
import PlaybackPausedCause from '../../services/qualityOfService/playbackPausedCause';
import PlaybackResumedCause from '../../services/qualityOfService/playbackResumedCause';
import ServerRequest from '../../services/qualityOfService/serverRequest';
import getSafe from '../../services/util/getSafe';

import circularReplacer from '../../services/util/circularReplacer';
import adEngineRegex from '../../services/util/adEngineRegex';
import parseUrl from '../../services/util/parseUrl';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

/**
 *
 * @desc Interface used to communicate with the media player.
 * @note BAM internal web player adapter
 *
 */
export default class BamHlsPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer An instance of the web-based bam-video-players
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The BamHlsPlayerAdapter requires nativePlayer.on && nativePlayer.off
     *
     */
    constructor(options) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        on: Types.function
                    })
                })
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Array<SDK.Drm.DrmProvider>}
         * @desc Set of DRM providers
         *
         */
        this.drmProviders = [];

        /**
         *
         * @access private
         * @type {HlsStream}
         *
         */
        this.hlsStatic = this.nativePlayer.getClass();

        /**
         *
         * @access private
         * @type {HlsStream.Events}
         * @note Events enum
         *
         */
        this.Events = this.hlsStatic.Events;

        /**
         *
         * @access private
         * @type {HlsStream.States}
         * @note States Enum
         *
         */
        this.States = this.hlsStatic.States;

        /**
         *
         * @access private
         * @since 4.6.0
         * @type {HlsStream.PlaybackStates}
         * @note States Enum
         *
         */
        this.PlaybackStates = this.hlsStatic.PlaybackStates;

        /**
         *
         * @access private
         * @type {HlsStream.DataTypes}
         * @note DataTypes Enum
         *
         */
        this.DataTypes = this.hlsStatic.DataTypes;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = Logger.instance;

        /**
         *
         * @access private
         * @since 5.0.0
         * @type {Boolean}
         * @desc Indicates when player is buffering.
         *
         */
        this.isBuffering = false;

        /**
         *
         * @access private
         * @since 5.0.0
         * @type {Boolean}
         * @desc Indicates when application is backgrounded or foregrounded.
         *
         */
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        // only attempt to assign this if the user hasn't already done so when initializing bam-hls
        if (Check.not.assigned(this.nativePlayer.setupXhrCallback)) {
            this.setupXhrCallback();
        }

        return new Promise((resolve) => {
            this.playlistUri = playlist.streamUri;

            return resolve();
        });
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @desc Sets the application state flag to backgrounded.
     *
     */
    setApplicationBackgrounded() {
        this.isApplicationBackgrounded = true;
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @desc Sets the application state flag to foregrounded.
     *
     */
    setApplicationForegrounded() {
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        this.playlistUri = '';
        this.drmProvider = null;
        this.drmProviders = [];

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
    }

    // #region protected

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {SDK.Services.Exception.ServiceException} Unable to add PlaybackEventListener
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Void}
     * @note messageDetailed is the error message pre-formatted with error, message and cause
     * from bam-hls STREAM_ERROR event
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, boundHandlers, Events } = this;

        const {
            playbackStartedEvent,
            playbackPausedEvent,
            playbackResumedEvent,
            playbackInitializedEvent,
            playbackReadyEvent,
            bitrateChangedEvent,
            rebufferingStartedEvent,
            rebufferingEndedEvent,
            subtitleChangedEvent,
            multivariantPlaylistFetchedEvent,
            variantPlaylistFetchedEvent,
            audioChangedEvent
        } = this;

        const {
            PLAYBACK_PLAYING,
            PLAYBACK_PAUSED,
            STREAM_STATE_CHANGED,
            STREAM_PLAYBACK,
            STREAM_CANPLAYTHROUGH,
            STREAM_VARIANTS_CHANGED,
            STREAM_BUFFERING_STARTED,
            STREAM_BUFFERING_FINISHED,
            STREAM_CURRENTSUBTITLERENDITION_CHANGED,
            MANIFEST_LOADED,
            VARIANT_UPDATED,
            STREAM_CURRENTAUDIOTRACK_CHANGED,
            STREAM_ERROR
        } = Events;

        if (Check.assigned(nativePlayer.on)) {
            this.listener = listener;

            boundHandlers.playbackStartedEvent =
                playbackStartedEvent.bind(this);
            boundHandlers.playbackPausedEvent = playbackPausedEvent.bind(this);
            boundHandlers.playbackResumedEvent =
                playbackResumedEvent.bind(this);
            boundHandlers.playbackInitializedEvent =
                playbackInitializedEvent.bind(this);
            boundHandlers.playbackReadyEvent = playbackReadyEvent.bind(this);
            boundHandlers.bitrateChangedEvent = bitrateChangedEvent.bind(this);
            boundHandlers.rebufferingStartedEvent =
                rebufferingStartedEvent.bind(this);
            boundHandlers.rebufferingEndedEvent =
                rebufferingEndedEvent.bind(this);
            boundHandlers.subtitleChangedEvent =
                subtitleChangedEvent.bind(this);
            boundHandlers.audioChangedEvent = audioChangedEvent.bind(this);
            boundHandlers.multivariantPlaylistFetchedEvent =
                multivariantPlaylistFetchedEvent.bind(this);
            boundHandlers.variantPlaylistFetchedEvent =
                variantPlaylistFetchedEvent.bind(this);

            boundHandlers.onStreamError = (ex = {}) => {
                const { fatal = false, messageDetailed = STREAM_ERROR } = ex;

                if (fatal && ex.error === 'DRM_FAILED') {
                    const errorCase =
                        this.constructErrorCaseFromBamHlsError(ex);

                    this.emit(PublicEvents.MediaFailure, errorCase);

                    let error = null;

                    try {
                        error = JSON.stringify(errorCase, circularReplacer());
                    } catch (exx) {
                        error = 'Cannot stringify original error';
                    }

                    boundHandlers.playbackEndedEvent.call(this, error);

                    // removes listener on fatal error
                    return this.removeListener(this.listener);
                }

                if (fatal && Check.assigned(this.listener)) {
                    boundHandlers.playbackEndedEvent.call(
                        this,
                        messageDetailed
                    );

                    // removes listener on fatal error
                    return this.removeListener(this.listener);
                }

                return undefined;
            };

            nativePlayer.on(
                PLAYBACK_PLAYING,
                boundHandlers.playbackStartedEvent
            );
            nativePlayer.on(PLAYBACK_PAUSED, boundHandlers.playbackPausedEvent);
            nativePlayer.on(
                STREAM_STATE_CHANGED,
                boundHandlers.playbackResumedEvent
            );
            nativePlayer.on(
                STREAM_PLAYBACK,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.on(
                STREAM_CANPLAYTHROUGH,
                boundHandlers.playbackReadyEvent
            );
            nativePlayer.on(
                STREAM_VARIANTS_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.on(
                STREAM_BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.on(
                STREAM_BUFFERING_FINISHED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.on(
                STREAM_CURRENTSUBTITLERENDITION_CHANGED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.on(
                STREAM_CURRENTAUDIOTRACK_CHANGED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.on(
                MANIFEST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.on(
                VARIANT_UPDATED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.on(STREAM_ERROR, boundHandlers.onStreamError);
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener
     * @throws {SDK.Services.Exception.ServiceException} Unable to remove PlaybackEventListener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer, boundHandlers, Events } = this;

        const {
            PLAYBACK_PLAYING,
            PLAYBACK_PAUSED,
            STREAM_STATE_CHANGED,
            STREAM_PLAYBACK,
            STREAM_CANPLAYTHROUGH,
            STREAM_VARIANTS_CHANGED,
            STREAM_BUFFERING_STARTED,
            STREAM_BUFFERING_FINISHED,
            STREAM_CURRENTSUBTITLERENDITION_CHANGED,
            MANIFEST_LOADED,
            VARIANT_UPDATED,
            STREAM_CURRENTAUDIOTRACK_CHANGED,
            STREAM_ERROR
        } = Events;

        if (Check.assigned(nativePlayer.off) && this.listener === listener) {
            this.listener = null;

            nativePlayer.off(
                PLAYBACK_PLAYING,
                boundHandlers.playbackStartedEvent
            );
            nativePlayer.off(
                PLAYBACK_PAUSED,
                boundHandlers.playbackPausedEvent
            );
            nativePlayer.off(
                STREAM_STATE_CHANGED,
                boundHandlers.playbackResumedEvent
            );
            nativePlayer.off(
                STREAM_PLAYBACK,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.off(
                STREAM_CANPLAYTHROUGH,
                boundHandlers.playbackReadyEvent
            );
            nativePlayer.off(
                STREAM_VARIANTS_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.off(
                STREAM_BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.off(
                STREAM_BUFFERING_FINISHED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.off(
                STREAM_CURRENTSUBTITLERENDITION_CHANGED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.off(
                STREAM_CURRENTAUDIOTRACK_CHANGED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.off(
                MANIFEST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.off(
                VARIANT_UPDATED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.off(STREAM_ERROR, boundHandlers.onStreamError);
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @throws {SDK.Services.Exception.ServiceException} This playerAdapter needs multiple SDK.Drm.DrmProvider
     * instances, please use BamHlsPlayerAdapter#setDrmProviders(drmProviders) which takes in an Array of
     * SDK.Drm.DrmProvider instances
     *
     */
    // eslint-disable-next-line no-unused-vars
    setDrmProvider(drmProvider) {
        const errorMsg = `${this.toString()}.setDrmProvider(drmProvider) - this playerAdapter needs multiple SDK.Drm.DrmProvider instances, please use ${this.toString()}#setDrmProviders(drmProviders) which takes in an Array of SDK.Drm.DrmProvider instances`;

        const reasons = [new ErrorReason('', errorMsg)];
        const exceptionData = ExceptionReference.common.invalidState;

        return Promise.reject(new ServiceException({ reasons, exceptionData }));
    }

    /**
     *
     * @access protected
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    async setDrmProviders(drmProviders) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer, setDrmConfiguration } = this;

        this.drmProviders = drmProviders;

        const drmCapabilities = await nativePlayer.getDrmCapabilities();

        const { keySystems } = drmCapabilities;

        let drmProvidersIndex = drmProviders.length;

        while (drmProvidersIndex--) {
            if (
                !getSafe(
                    // eslint-disable-next-line no-loop-func
                    () =>
                        keySystems[drmProviders[drmProvidersIndex].type]
                            .supported
                )
            ) {
                drmProviders.splice(drmProvidersIndex, 1);
            }
        }

        nativePlayer.drmConfiguration = setDrmConfiguration.bind(this)();
    }

    /**
     *
     * @access protected
     * @desc Gets a snapshot of information about media playback.
     * @returns {SDK.Media.PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note (chosenBitRate / 1000) - need to convert to Kbps
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        const currentPlayhead = this.nativePlayer.currentTime;

        let currentBitrate;
        let currentBitrateAvg;
        let currentBitratePeak;
        let currentThroughput;

        if (Check.not.number(currentPlayhead)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer playhead data`;

            this.logger.warn(this.toString(), errorMsg);
        }

        if (Check.not.function(this.nativePlayer.getNetworkMetrics)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer bitrate data`;

            this.logger.warn(this.toString(), errorMsg);
        } else {
            const metrics = this.nativePlayer.getNetworkMetrics();

            currentBitrate = metrics.chosenBitrate / 1000; // converting to Kbps
            currentBitrateAvg = metrics.chosenBitrate;
            currentBitratePeak = metrics.chosenMaxBitrate;
            currentThroughput = metrics.throughput;
        }

        return new PlaybackMetrics({
            currentBitrate,
            currentPlayhead,
            currentBitrateAvg,
            currentBitratePeak,
            currentThroughput
        });
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {null}
     *
     */
    getCdnName() {
        return null;
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} exception - the exception object that bam-hls throws
     * @note exception.cause will be one value from the DRM section of this list:
     * https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/src/hlsjs/errors.js
     * @desc Converts a bam-hls error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given bam-hls error
     *
     */
    constructErrorCaseFromBamHlsError(exception) {
        const errorCode =
            BamHlsErrorMapping[exception.cause] || BamHlsErrorMapping.default;
        const reasons = [new ErrorReason(errorCode.code, null)];

        return new ServiceException({
            reasons,
            exceptionData: errorCode.exceptionData
        });
    }

    /**
     *
     * @access private
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    setupXhrCallback() {
        const {
            nativePlayer,
            accessToken,
            DataTypes,
            adEngineData = {}
        } = this;
        const { ssess } = adEngineData;

        nativePlayer.xhrSetupCallback = (xhr, url, type) => {
            if (DataTypes) {
                const isKeyCall = type === DataTypes.KEY;
                const isAdEngineCall =
                    adEngineRegex(url) && type === DataTypes.CHUNK;

                xhr.withCredentials = false; // Ensure no false positive from any cookies

                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                }

                if (isKeyCall) {
                    xhr.setRequestHeader('Authorization', accessToken);
                } else if (isAdEngineCall && Check.assigned(ssess)) {
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('ssess', ssess);
                }
            }
        };
    }

    /**
     *
     * @access private
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    setDrmConfiguration() {
        const { drmProviders } = this;

        const keySystems = [];

        for (const drmProvider of drmProviders) {
            const keySystem = drmProvider.type;
            const licenseRequestUri = getSafe(
                () => drmProvider.licenseRequestUri
            );

            keySystems.push({
                keySystem,
                licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                /**
                 *
                 * @note bam-hls calls `Promise.resolve(this._keySystemConfig.serverCertificate)`
                 * where serverCertificate is a reference to this function, in EME.js.
                 * We need to use an IIFE here so we setup a promise since they are not executing this function
                 * only expecting a Promise(cert), Promise(undefined), cert or undefined
                 *
                 */
                serverCertificate: ((innerKeySystem) => {
                    return () => {
                        if (innerKeySystem === DrmType.FAIRPLAY) {
                            return drmProvider.getFairPlayCertificate();
                        }

                        if (innerKeySystem === DrmType.WIDEVINE) {
                            return drmProvider.getWidevineCertificate();
                        }

                        // bam-hls expects undefined so they don't attempt to process this as a certificate
                        return Promise.resolve(undefined);
                    };
                })(keySystem)
            });
        }

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the PLAYBACK_PLAYING event
     *
     */
    playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the PLAYBACK_PAUSED event
     *
     */
    playbackPausedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        let cause = null;

        if (this.isApplicationBackgrounded) {
            cause = PlaybackPausedCause.applicationBackgrounded;
        } else if (this.isBuffering) {
            cause = PlaybackPausedCause.stall;
        } else {
            cause = PlaybackPausedCause.user;
        }

        this.onPlaybackPaused({
            cause,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_STATE_CHANGED event
     *
     */
    playbackResumedEvent() {
        const { nativePlayer, States, PlaybackStates } = this;
        const playbackMetrics = this.getPlaybackMetrics();

        if (
            nativePlayer.state === States.PLAYBACK &&
            nativePlayer.playbackState === PlaybackStates.PLAYING
        ) {
            const cause = PlaybackResumedCause.user;

            this.onPlaybackResumed({
                cause,
                playheadPosition: playbackMetrics.currentPlayhead
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_PLAYBACK event
     *
     */
    playbackInitializedEvent() {
        const { nativePlayer, playlistUri: streamUrl } = this;
        const {
            subtitleRenditions = [],
            areSubtitlesEnabled = false,
            currentSubtitleRendition
        } = nativePlayer;
        const {
            audioTracks = [],
            variants = [],
            currentAudioTrack = 0
        } = nativePlayer;

        const playbackMetrics = this.getPlaybackMetrics();

        const subtitleRendition =
            subtitleRenditions[currentSubtitleRendition] || {};
        const audioRendition = audioTracks[currentAudioTrack] || {};

        const metrics = nativePlayer.getNetworkMetrics();
        const chosenVariant = metrics.chosenVariant;

        const _variant = variants[chosenVariant] || {};

        const {
            audioCodec = [],
            videoCodec,
            videoRange,
            frameRate,
            attrs = {}
        } = _variant;
        const codec = audioCodec.join() || null;

        const { BANDWIDTH: bandwidth, RESOLUTION: resolution } = attrs;
        const averageBandwidth = attrs['AVERAGE-BANDWIDTH'];
        const frameRateAttr = attrs['FRAME-RATE'];

        let frameRateNumber;

        if (Check.assigned(frameRate)) {
            frameRateNumber = Number(frameRate);
        } else if (Check.assigned(frameRateAttr)) {
            frameRateNumber = Number(frameRateAttr);
        }

        const variant = new PlaybackVariant({
            bandwidth: Check.assigned(bandwidth) ? Number(bandwidth) : null,
            resolution,
            videoBytes: null,
            maxAudioRenditionBytes: null,
            maxSubtitleRenditionBytes: null,
            audioChannels: null,
            videoRange,
            videoCodec,
            audioType: null,
            audioCodec: codec,
            averageBandwidth: Check.assigned(averageBandwidth)
                ? Number(averageBandwidth)
                : null,
            frameRate: frameRateNumber
        });

        this.onPlaybackInitialized({
            variant,
            audio: new AudioRendition(audioRendition),
            subtitle: new SubtitleRendition(subtitleRendition),
            areSubtitlesVisible: areSubtitlesEnabled,
            startupBitrate: null,
            startupAverageBitrate: null,
            streamUrl,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_CANPLAYTHROUGH event
     *
     */
    playbackReadyEvent() {
        this.onPlaybackReady({});
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_VARIANTS_CHANGED event
     * @note bitrateType is not included in the BitrateChangedEventData because there is no way to determine its origin
     * based on how the manifest is parsed.
     *
     */
    bitrateChangedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        this.onBitrateChanged({
            bitrateAvg: playbackMetrics.currentBitrateAvg,
            bitratePeak: playbackMetrics.currentBitratePeak,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {String} [error]
     * @note Handles the PLAYBACK_ENDED and STREAM_ERROR events
     *
     */
    playbackEndedEvent(error) {
        const { nativePlayer } = this;
        const { isEnded } = nativePlayer;

        const playbackMetrics = this.getPlaybackMetrics();

        const { currentPlayhead } = playbackMetrics;

        let cause;

        if (Check.assigned(error)) {
            cause = PlaybackExitedCause.error;
        } else if (isEnded) {
            cause = PlaybackExitedCause.playedToEnd;
        } else {
            cause = PlaybackExitedCause.user;
        }

        this.onPlaybackEnded({
            cause,
            playheadPosition: currentPlayhead,
            errorDetail: error
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_BUFFERING_STARTED event
     *
     */
    rebufferingStartedEvent() {
        this.isBuffering = true;

        const playbackMetrics = this.getPlaybackMetrics();

        const { currentPlayhead } = playbackMetrics;

        this.onRebufferingStarted({
            playheadPosition: currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_BUFFERING_FINISHED event
     *
     */
    rebufferingEndedEvent() {
        this.isBuffering = false;

        const playbackMetrics = this.getPlaybackMetrics();

        const { currentPlayhead } = playbackMetrics;

        this.onRebufferingEnded({
            playheadPosition: currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} eventData
     * @note Handles the STREAM_CURRENTSUBTITLERENDITION_CHANGED event
     *
     */
    subtitleChangedEvent(eventData) {
        const { nativePlayer } = this;
        const { subtitleRenditions = [], areSubtitlesEnabled = false } =
            nativePlayer;

        let options = {};

        if (Check.object(eventData)) {
            const { currentSubtitleRendition } = eventData;
            const subtitleRendition =
                subtitleRenditions[currentSubtitleRendition] || {};

            options = {
                subtitle: new SubtitleRendition(subtitleRendition),
                areSubtitlesVisible: areSubtitlesEnabled
            };
        }

        this.onSubtitleChanged(options);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} eventData
     * @note Handles the STREAM_CURRENTAUDIOTRACK_CHANGED event
     * @note If the chosen variant contains more than one audio codec then the audioCodec array is converted to a
     * comma-separated list before passing it to the AudioChangedEventData constructor.
     *
     */
    audioChangedEvent(eventData = {}) {
        const { nativePlayer, States } = this;
        const { audioTracks = [], variants = [] } = nativePlayer;

        const audioTrack = audioTracks[eventData.currentAudioTrack] || {};

        let chosenVariant = 0;

        // Checks to see if it's loading since getNetworkMetrics is not populated during load
        if (nativePlayer.state !== States.LOADING) {
            chosenVariant = nativePlayer.getNetworkMetrics().chosenVariant;
        }

        const _variant = variants[chosenVariant] || {};

        const {
            audioCodec = [],
            videoCodec,
            videoRange,
            attrs = {}
        } = _variant;
        const codec = audioCodec.join() || null;

        const { BANDWIDTH: bandwidth, RESOLUTION: resolution } = attrs;
        const averageBandwidth = attrs['AVERAGE-BANDWIDTH'];
        const frameRate = attrs['FRAME-RATE'];

        const audio = new AudioRendition(audioTrack);

        const variant = new PlaybackVariant({
            bandwidth: Check.assigned(bandwidth) ? Number(bandwidth) : null,
            resolution,
            videoBytes: null,
            maxAudioRenditionBytes: null,
            maxSubtitleRenditionBytes: null,
            audioChannels: null,
            videoRange,
            videoCodec,
            audioType: null,
            audioCodec: codec,
            averageBandwidth: Check.assigned(averageBandwidth)
                ? Number(averageBandwidth)
                : null,
            frameRate: Check.assigned(frameRate) ? Number(frameRate) : null
        });

        this.onAudioChanged({
            audio,
            variant
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MANIFEST_LOADED event
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    multivariantPlaylistFetchedEvent(eventData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                eventData: Types.object()
            };

            typecheck.warn(
                this,
                'multivariantPlaylistFetchedEvent',
                params,
                arguments
            );
        }

        const { nativePlayer } = this;
        const { isLive, isSlidingWindow } = nativePlayer;

        if (Check.object(eventData)) {
            const { requestSynopsis = {} } = eventData;
            const { url } = requestSynopsis;
            const { host, path } = parseUrl(url);

            let playlistLiveType;

            if (isLive && isSlidingWindow) {
                playlistLiveType = PlaylistType.SLIDE;
            } else {
                playlistLiveType = PlaylistType.COMPLETE;
            }

            const serverRequest = new ServerRequest({
                host,
                path,
                status: FetchStatus.completed
            });

            this.onMultivariantPlaylistFetched({
                playlistLiveType,
                serverRequest
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} eventData
     * @note Handles the VARIANT_UPDATED event
     * @note The VariantPlaylistFetchedEventData.serverRequest.status is only set if there were no errors loading
     * the variant, e.g. nativePlayer.variants[eventData.level].loadError = 0. The status is not set if loadError > 0
     * because the nativePlayer does not expose the reason for the errors.
     *
     */
    variantPlaylistFetchedEvent(eventData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                eventData: Types.object()
            };

            typecheck.warn(
                this,
                'variantPlaylistFetchedEvent',
                params,
                arguments
            );
        }

        const { nativePlayer } = this;
        const { variants = [] } = nativePlayer;

        if (Check.object(eventData)) {
            const { level, details = {} } = eventData;
            const variant = variants[level] || {};
            const { attrs = {}, loadError } = variant;

            const {
                BANDWIDTH: bandwidth,
                CHANNELS: channels,
                NAME: name,
                LANGUAGE: language,
                RESOLUTION: resolution
            } = attrs;

            const averageBandwidth = attrs['AVERAGE-BANDWIDTH'];
            const { url } = details;
            const { host, path } = parseUrl(url);
            const status = loadError === 0 ? FetchStatus.completed : null;

            const serverRequest = new ServerRequest({
                host,
                path,
                status
            });

            this.onVariantPlaylistFetched({
                playlistAverageBandwidth: Check.assigned(averageBandwidth)
                    ? Number(averageBandwidth)
                    : null,
                playlistBandwidth: Check.assigned(bandwidth)
                    ? Number(bandwidth)
                    : null,
                playlistChannels: channels,
                playlistName: name,
                playlistLanguage: language,
                playlistResolution: resolution,
                serverRequest
            });
        }
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.BamHlsPlayerAdapter';
    }

    // #endregion
}
