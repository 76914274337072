/**
 *
 * @module bamWebPlayerAdapter
 * @desc PlayerAdapter for the web-based bam-video-players
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/fed-app-media-platform/bam-video-players/tree/master/jsdoc
 * @see https://github.bamtech.co/fed-app-media-platform/bam-video-players/tree/master/jsdoc#playerevents--enum
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/API.md
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/API.md#drm-configuration
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/demo/index.html
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import DrmType from '../../services/media/drmType';
import DrmProvider from '../../drm/drmProvider';
import PlayerAdapter from './../playerAdapter';
import PlaybackMetrics from './../playbackMetrics';
import PlaybackEventListener from './../playbackEventListener';
import Playlist from './../playlist';
import getSafe from '../../services/util/getSafe';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

/**
 *
 * @desc Interface used to communicate with the media player.
 * @note BAM internal web player adapter
 *
 */
export default class BamWebPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer An instance of the web-based bam-video-players
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The BamWebPlayerAdapter requires nativePlayer.dispatcher
     *
     */
    constructor(options) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        dispatcher: Types.object()
                    })
                })
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Array<SDK.Drm.DrmProvider>}
         * @since 3.2.0
         * @desc Set of DRM providers
         *
         */
        this.drmProviders = [];
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        return new Promise((resolve) => {
            this.playlistUri = playlist.streamUri;

            return resolve();
        });
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }

        this.playlistUri = '';
        this.boundHandlers = {};
        this.drmProvider = null;
        this.drmProviders = [];
    }

    /**
     *
     * @access public
     * @since 3.1.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
    }

    /**
     *
     * @access public
     * @since 4.16.2
     * @param {Object} [eventData={}]
     * @desc Trigger when playback has been exited.
     * @note in version `4.6.0` this was renamed from `playbackExitedEvent` to `playbackEndedEvent`
     *
     */
    playbackEndedEvent(eventData = {}) {
        const { message: error = null } = eventData;

        this.onPlaybackEnded({
            cause: null,
            error,
            playheadPosition: this.nativePlayer.getCurrentTimeInSecs()
        });
    }

    // #region protected

    /**
     *
     * @access protected
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, boundHandlers } = this;
        const {
            playbackStartedEvent,
            playbackResumedEvent,
            playbackPausedEvent,
            playbackEndedEvent
        } = this;
        const { dispatcher } = nativePlayer;
        const EventList = nativePlayer.events.getEventList();
        const { PlayerEvents } = EventList;
        const { MEDIA_START, MEDIA_RESUME, MEDIA_PAUSED, MEDIA_ERROR } =
            PlayerEvents;

        if (Check.assigned(dispatcher)) {
            this.listener = listener;

            boundHandlers.onPlay = playbackStartedEvent.bind(this);
            boundHandlers.onResume = playbackResumedEvent.bind(this);
            boundHandlers.onPause = playbackPausedEvent.bind(this);
            boundHandlers.onError = playbackEndedEvent.bind(this);

            dispatcher.on(MEDIA_START, boundHandlers.onPlay);
            dispatcher.on(MEDIA_RESUME, boundHandlers.onResume);
            dispatcher.on(MEDIA_PAUSED, boundHandlers.onPause);
            dispatcher.on(MEDIA_ERROR, boundHandlers.onError);
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener
     * @throws {InvalidStateException} Unable to remove PlaybackEventListener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer, boundHandlers } = this;
        const { dispatcher } = nativePlayer;
        const EventList = nativePlayer.events.getEventList();
        const { PlayerEvents } = EventList;
        const { MEDIA_START, MEDIA_RESUME, MEDIA_PAUSED, MEDIA_ERROR } =
            PlayerEvents;

        if (Check.assigned(dispatcher) && this.listener === listener) {
            this.listener = null;

            dispatcher.off(MEDIA_START, boundHandlers.onPlay);
            dispatcher.off(MEDIA_RESUME, boundHandlers.onResume);
            dispatcher.off(MEDIA_PAUSED, boundHandlers.onPause);
            dispatcher.off(MEDIA_ERROR, boundHandlers.onError);
        } else {
            const errorMsg = `${this.toString()}.removeListener(listener) unable to remove PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 3.2.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @throws {InvalidStateException} this PlayerAdapter needs multiple SDK.Drm.DrmProvider instances,
     * please use BamWebPlayerAdapter#setDrmProviders(drmProviders) which takes in an
     * Array of SDK.Drm.DrmProvider instances
     *
     */
    // eslint-disable-next-line no-unused-vars
    setDrmProvider(drmProvider) {
        const method = 'BamWebPlayerAdapter.setDrmProvider(drmProvider)';
        const exceptionData = ExceptionReference.common.invalidState;

        const reasons = [
            new ErrorReason(
                '',
                `
            ${method} - this PlayerAdapter needs multiple SDK.Drm.DrmProvider instances,
            please use BamWebPlayerAdapter#setDrmProviders(drmProviders) which takes in an
            Array of SDK.Drm.DrmProvider instances
        `
            )
        ];

        return Promise.reject(new ServiceException({ reasons, exceptionData }));
    }

    /**
     *
     * @access protected
     * @since 3.2.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @returns {Promise<Void>}
     *
     */
    setDrmProviders(drmProviders) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer, setDrmConfiguration } = this;

        this.drmProviders = drmProviders;

        nativePlayer.utils.drmConfigCallback(setDrmConfiguration.bind(this));

        return Promise.resolve();
    }

    /**
     *
     * @access protected
     * @since 2.0.0
     * @desc Gets a snapshot of information about media playback.
     * @throws {InvalidStateException} Unable to get NativePlayer playhead or bitrate data
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        if (Check.not.assigned(this.nativePlayer.bitrates)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer playhead data`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        if (Check.not.function(this.nativePlayer.getCurrentTimeInSecs)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer bitrate data`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        const currentBitrate = this.nativePlayer.bitrates.getCurrentKbps();

        return new PlaybackMetrics({
            currentBitrate,
            currentPlayhead: this.nativePlayer.getCurrentTimeInSecs()
        });
    }

    // #endregion

    // #region private:

    /**
     *
     * @access private
     * @since 3.2.0
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    setDrmConfiguration() {
        const { drmProviders } = this;
        const keySystems = [];

        for (const drmProvider of drmProviders) {
            const keySystem = drmProvider.type;
            const licenseRequestUri = getSafe(
                () => drmProvider.licenseRequestUri
            );

            keySystems.push({
                keySystem,
                licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                /**
                 *
                 * @note bam-hls calls `Promise.resolve(this._keySystemConfig.serverCertificate)`
                 * where serverCertificate is a reference to this function, in EME.js.
                 * We need to use an IIFE here so we setup a promise since they are not executing this function
                 * only expecting a Promise(cert), Promise(undefined), cert or undefined
                 *
                 */
                serverCertificate: ((innerKeySystem) => {
                    return () => {
                        if (innerKeySystem === DrmType.FAIRPLAY) {
                            return drmProvider.getFairPlayCertificate();
                        }

                        if (innerKeySystem === DrmType.WIDEVINE) {
                            return drmProvider.getWidevineCertificate();
                        }

                        // bam-hls expects undefined so they don't attempt to process this as a certificate
                        return Promise.resolve(undefined);
                    };
                })(keySystem)
            });
        }

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @since 4.5.1
     * @desc Trigger when playback starts.
     *
     */
    playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access private
     * @since 4.5.1
     * @param {Object} [eventData={}]
     * @param {String<SDK.Services.QualityOfService.PlaybackPausedCause>} [eventData.cause] - The reason playback was paused.
     * @desc Trigger when playback gets paused.
     *
     */
    playbackPausedEvent(eventData = {}) {
        const { cause } = eventData;

        this.onPlaybackPaused({
            cause,
            playheadPosition: this.nativePlayer.getCurrentTimeInSecs()
        });
    }

    /**
     *
     * @access private
     * @since 5.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback gets resumed.
     *
     */
    playbackResumedEvent(eventData = {}) {
        const { cause } = eventData;

        this.onPlaybackResumed({
            cause,
            playheadPosition: this.nativePlayer.getCurrentTimeInSecs()
        });
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.BamWebPlayerAdapter';
    }

    // #endregion
}
