/**
 *
 * @module fetchStatus
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.FetchStatus
 * @desc Defines the different types of fetch status.
 *
 * @property {String} completed - Since `4.0.0`.
 * @property {String} cancelled - Since `4.0.0`.
 * @property {String} noNetwork - Since `4.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.FetchStatus.completed // 'completed'
 *
 */
export default keyMirror({
    completed: null,
    cancelled: null,
    noNetwork: null
});
