/**
 *
 * @module playbackSessionTransferredEventData
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.0.0
 * @desc This event represents a transfer of playback from the current device to another
 * device, e.g. a Chromecast- or Airplay-capable device.
 *
 */
export default class PlaybackSessionTransferredEventData {
    /**
     *
     * @param {String} [playbackSessionId]
     *
     */
    constructor(playbackSessionId) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackSessionId: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|undefined}
         * @desc client generated ID of the stream/playback session
         *
         */
        this.playbackSessionId = playbackSessionId;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.PlaybackSessionTransferredEventData';
    }

    // #endregion
}
