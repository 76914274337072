/**
 *
 * @module subscriptionClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/subscription.md
 * @see https://github.bamtech.co/services-commons/api-docs/tree/master/v3.0.0-subs-api
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/subscription.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import SubscriptionClientEndpoint from './subscriptionClientEndpoint';
import SubscriptionClientConfiguration from './subscriptionClientConfiguration';

import appendQuerystring from '../util/appendQuerystring';

import {
    Subscription as SubscriptionV2,
    SubscriptionTypedef,
    SubscriberInfo,
    SubscriberInfoTypedef,
    Term
} from '../subscriber/typedefs';

import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import { SubscriptionState } from '../subscriber/enums';

import type Subscription from '../../subscription/subscription';

const SubscriptionClientDustReference =
    DustUrnReference.services.subscription.subscriptionClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access subscription services.
 *
 */
export default class SubscriptionClient {
    /**
     *
     * @access private
     * @type {SDK.Services.Subscription.SubscriptionClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: SubscriptionClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {CoreHttpClientProvider}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access public
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.Subscription.SubscriptionClientConfiguration} subscriptionClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        subscriptionClientConfiguration: SubscriptionClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                subscriptionClientConfiguration: Types.instanceStrict(
                    SubscriptionClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = subscriptionClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets a list of subscriptions associated with the provided AccessToken.
     * @returns {Promise<Array<Subscription>>}
     *
     */
    public getSubscriptions(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<Array<Subscription>> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getSubscriptions', params, arguments);
        }

        const { dustEnabled, logger } = this;
        const endpointKey = SubscriptionClientEndpoint.getSubscriptions;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(this.toString(), 'Attempting to get subscriptions.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.getSubscriptions,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;

                return Promise.resolve(data);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Associates the subscriptions from the device to the account, which are both defined in the AccessToken.
     * @returns {Promise<Void>}
     *
     */
    public linkSubscriptionsFromDevice(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<TodoAny> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'linkSubscriptionsFromDevice', params, arguments);
        }

        const { dustEnabled, logger } = this;
        const endpointKey =
            SubscriptionClientEndpoint.linkSubscriptionsFromDevice;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to link subscriptions from device.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.linkSubscriptionsFromDevice,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.13.0
     * @param {Object} options
     * @param {SDK.Services.Subscriber.SubscriptionState} [options.state] - When supplied, only returns subscriptions
     * with this status. If not supplied, returns all subscriptions except churned.
     * @param {Boolean} [options.includeChurned] - When supplied, indicates whether churned subscriptions should be returned. Default is false.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Returns subscriber's subscription information.
     * @returns {Promise<Object<SDK.Services.Subscriber.SubscriberInfo>>}
     *
     */
    public getSubscriberInfo(options: {
        state?: ValueOf<typeof SubscriptionState>;
        includeChurned?: boolean;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<SubscriberInfo> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    state: Types.in(SubscriptionState).optional,
                    includeChurned: Types.boolean.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSubscriberInfo', params, arguments);
        }

        const { state, includeChurned, accessToken, logTransaction } = options;
        const { dustEnabled, logger } = this;
        const endpointKey = SubscriptionClientEndpoint.getSubscriberInfo;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        let href = payload.url as string;

        if (Check.assigned(state)) {
            href = appendQuerystring(href, `state=${state}`);
        }
        if (Check.assigned(includeChurned)) {
            href = appendQuerystring(href, `includeChurned=${includeChurned}`);
        }

        payload.url = href;

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.getSubscriberInfo,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'getSubscriberInfo'
                );
            })
            .then((response) => {
                const { data } = response;

                data.subscriptions.forEach((subscription: TodoAny) => {
                    const term = subscription.term;

                    this.normalizeTerm(term);
                });

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(SubscriberInfoTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.13.0
     * @param {String} subscriptionId - The subscription identifier.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns a subscription by id.
     * @returns {Promise<Object<SDK.Services.Subscriber.Subscription>>}
     *
     */
    public getAccountSubscription(
        subscriptionId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<SubscriptionV2> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                subscriptionId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getAccountSubscription', params, arguments);
        }

        const { dustEnabled, logger } = this;
        const endpointKey = SubscriptionClientEndpoint.getAccountSubscription;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        payload.url = payload.url.replace(
            /\{subscriptionId\}/gi,
            subscriptionId
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.getAccountSubscription,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return this.httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'getAccountSubscription'
                );
            })
            .then((response) => {
                const { data } = response;
                const term = data.term;

                this.normalizeTerm(term);

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(SubscriptionTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {String<SDK.Services.Subscription.SubscriptionClientEndpoint>} options.endpointKey
     * @param {Object} options.body
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof SubscriptionClientEndpoint;
        body?: object;
    }): GetPayloadResult {
        const { accessToken, endpointKey, body } = options;
        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { headers, href } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     * @since 4.17.0
     * @param {Object<SDK.Services.Subscriber.Term>} term
     * @returns {Void}
     *
     */
    private normalizeTerm(term: Term): void {
        if (term) {
            const processDate = (date?: Date) => {
                return date ? new Date(date) : date;
            };

            term.purchaseDate = processDate(term.purchaseDate);
            term.startDate = processDate(term.startDate);
            term.expiryDate = processDate(term.expiryDate);
            term.nextRenewalDate = processDate(term.nextRenewalDate);
            term.pausedDate = processDate(term.pausedDate);
            term.churnedDate = processDate(term.churnedDate);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Subscription.SubscriptionClient';
    }
}
