/**
 *
 * @module externalActivationPlugin
 * @desc {SDK.ExternalActivation.ExternalActivationPlugin} entry point.
 *
 */

import ExternalActivation from './entry';
import ExternalActivationManager from './externalActivationManager';

import ExternalActivationService from '../services/externalActivation/entry';
import ExternalActivationClient from '../services/externalActivation/externalActivationClient';

import getSafe from '../services/util/getSafe';

import ExternalActivationClientConfiguration from './../services/externalActivation/externalActivationClientConfiguration';
import ExternalActivationManagerConfiguration from './../services/configuration/externalActivationManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<
    typeof ExternalActivation,
    'ExternalActivation',
    typeof ExternalActivationService
> = {
    pluginName: 'ExternalActivation',
    entry: ExternalActivation,
    service: ExternalActivationService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services } = options;

        const { tokenManager } = managers;

        managers.externalActivationManager = new ExternalActivationManager({
            config: services.externalActivation,
            client: new ExternalActivationClient(
                services.externalActivation.client,
                logger,
                httpClient
            ),
            tokenManager,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new ExternalActivationClientConfiguration(
            getSafe(() => services.externalActivation.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.externalActivation.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.externalActivation =
            new ExternalActivationManagerConfiguration(
                clientConfiguration,
                undefined
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
