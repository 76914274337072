/**
 *
 * @module entitlementManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/entitlement.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import AccessTokenProvider from '../token/accessTokenProvider';
import EntitlementClient from '../services/entitlement/entitlementClient';
import EntitlementManagerConfiguration from '../services/configuration/entitlementManagerConfiguration';
import LogTransaction from '../logging/logTransaction';
import AccessToken from '../token/accessToken';

interface EntitlementManagerOptions {
    config: EntitlementManagerConfiguration;
    client: EntitlementClient;
    accessTokenProvider: AccessTokenProvider;
    logger: Logger;
}

/**
 *
 * @access protected
 * @since 4.9.0
 * @desc Manages the entitlement and serves as a go between for the public APIs and the EntitlementClient.
 *
 */
export default class EntitlementManager {
    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Services.Configuration.EntitlementManagerConfiguration}
     *
     */
    public config: EntitlementManagerConfiguration;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Services.Entitlement.EntitlementClient}
     *
     */
    public client: EntitlementClient;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.EntitlementManagerConfiguration} options.config
     * @param {SDK.Services.Entitlement.EntitlementClient} options.client
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: EntitlementManagerOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        EntitlementManagerConfiguration
                    ),
                    client: Types.instanceStrict(EntitlementClient),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, accessTokenProvider, logger } = options;

        this.config = config;
        this.client = client;
        this.accessTokenProvider = accessTokenProvider;
        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {String} mediaId
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async verifyMediaRights(
        mediaId: string,
        logTransaction: LogTransaction
    ): Promise<TodoAny> {
        const { client, accessToken } = this;

        return await client.verifyMediaRights(
            mediaId,
            accessToken,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     * @since 4.9.0
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Entitlement.EntitlementManager';
    }

    // #endregion
}
