/**
 *
 * @module monotonicTimestampManager
 *
 */

import monotonicClock from './services/util/monotonicClock';

/**
 *
 * @access public
 * @since 20.0.0
 * @desc A static manager for accessing the current monotonic timestamp with
 * millisecond precision.
 * @note The intent of this manager is to provide a way to capture values that can
 * be used to calculate accurate time intervals across events, as other
 * timestamps are subject to local clock changes and may fluctuate.
 *
 */
export default class MonotonicTimestampManager {
    private static startTimestamp: number = monotonicClock.timestamp();

    /**
     *
     * @access public
     * @since 20.0.0
     * @desc Returns a timestamp in milliseconds relative to the startTimestamp
     * initialized within the manager. The value of the timestamp strictly
     * increases.
     * @returns {Number} As an Integer
     *
     */
    public static getTimestamp(): number {
        const timestamp = Math.round(
            monotonicClock.timestamp() -
                MonotonicTimestampManager.startTimestamp
        );

        return timestamp;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.MonotonicTimestampManager';
    }
}
