/**
 *
 * @module httpClient
 * @see https://github.github.io/fetch/
 * @see https://fetch.spec.whatwg.org/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * @see https://github.com/bitinn/node-fetch/blob/master/LIMITS.md
 *
 */
import { Check, Types, typecheck } from '@dss/type-checking';

import fetch from './../../lib/whatwg-fetch';
import CoreHttpClientProvider from './../shared/coreHttpClientProvider';

import HttpMethod from '../../configuration/httpMethod';

const { Request, Headers } = fetch;

/**
 *
 * @access protected
 * @desc Browser based HttpClient implementation, powered by `whatwg-fetch`.
 * @example
 * const httpClient = new HttpClient(logger, tokenUpdater);
 *
 */
export default class HttpClient extends CoreHttpClientProvider {
    /**
     *
     * @param {SDK.Logging.Logger} logger
     * @param {TokenUpdater} tokenUpdater
     *
     */
    constructor(logger, tokenUpdater) {
        super(logger, tokenUpdater);

        this.logger.log(this.toString(), 'Created Browser Provider.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @note By default, the `credentials` option is `include`, this can be
     * overridden if necessary, however in general its expected to be `include` for
     * majority of POST requests.
     * @throws {NetworkException}
     * @returns {Promise<ServerResponse>}
     *
     * @example
     * httpClient.post(options).then((response) => { console.log(response.data); });
     *
     */
    post(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.like(this.optionsBaseline)
            };

            typecheck(this, 'post', params, arguments);
        }

        const { credentials = 'include' } = options;

        return this.fetchRequest({
            method: HttpMethod.POST,
            credentials,
            ...options
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @note By default, the `credentials` option is `undefined`, this is by design as
     * the majority of GET requests do not need this option. There are certain edge-cases
     * that need this option.
     * @note When the `credentials` option is `undefined` and passed in to a newly
     * constructed `Request`, the `Request` `Object` will default to the correct value.
     * @throws {NetworkException}
     * @returns {Promise<ServerResponse>}
     *
     * @example
     * httpClient.get(options).then((response) => { console.log(response.data); });
     *
     */
    get(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.like(this.optionsBaseline)
            };

            typecheck(this, 'get', params, arguments);
        }

        return this.fetchRequest({
            method: HttpMethod.GET,
            mode: 'cors',
            ...options
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @note When the `credentials` option is `undefined` and passed in to a newly
     * constructed `Request`, the `Request` `Object` will default to the correct value.
     * @throws {NetworkException}
     * @returns {Promise<Object>}
     *
     * @example
     * httpClient.del(options).then((response) => { console.log(response.data); });
     *
     */
    del(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.like(this.optionsBaseline)
            };

            typecheck(this, 'del', params, arguments);
        }

        return this.fetchRequest({
            method: HttpMethod.DELETE,
            ...options
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @note When the `credentials` option is `undefined` and passed in to a newly
     * constructed `Request`, the `Request` `Object` will default to the correct value.
     * @throws {NetworkException}
     * @returns {Promise<Object>}
     *
     * @example
     * httpClient.patch(options).then((response) => { console.log(response.data); });
     *
     */
    patch(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.like(this.optionsBaseline)
            };

            typecheck(this, 'patch', params, arguments);
        }

        return this.fetchRequest({
            method: HttpMethod.PATCH,
            ...options
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @note When the `credentials` option is `undefined` and passed in to a newly
     * constructed `Request`, the `Request` `Object` will default to the correct value.
     * @throws {NetworkException}
     * @returns {Promise<Object>}
     *
     * @example
     * httpClient.put(options).then((response) => { console.log(response.data); });
     *
     */
    put(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.like(this.optionsBaseline)
            };

            typecheck(this, 'put', params, arguments);
        }

        return this.fetchRequest({
            method: HttpMethod.PUT,
            ...options
        });
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} [response={}] - The response Object.
     * @param {String} [bodyType=null] - The expected response data type, executed after initial JSON attempt
     * @desc Returns a consistent Object containing status, headers,
     * and data (parsed JSON output). If the response cannot be parsed, provide the
     * response as a plain String
     * @returns {Promise<Object>}
     *
     */
    async getResponseData(response = {}, bodyType = null) {
        const hasBodyType = Check.assigned(bodyType);
        const hasArrayBufferType = bodyType === 'arrayBuffer';
        const hasArrayBufferSupport = Check.assigned(response.arrayBuffer);

        if (hasBodyType && hasArrayBufferType && hasArrayBufferSupport) {
            return {
                data: await response.arrayBuffer(),
                dataType: 'arrayBuffer'
            };
        }

        return super.getResponseData(response);
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {String} options.url
     * @param {SDK.Services.Configuration.HttpMethod} options.method a `HttpMethod` value (GET, POST, etc...)
     * @param {Object} options.headers
     * @param {Object} options.body
     * @desc Executes the underlying HTTP network call.
     * @returns {Promise<Object>}
     *
     */
    rawFetch(options) {
        const { url, headers, method } = options;

        const methodUpperCase = method.toUpperCase();

        // Some getPayload helpers might tack on a 'body' property but it
        // would be undefined for GET/HEAD and would throw an error in
        // native Request object inputs - so we just delete it.
        if (
            methodUpperCase === HttpMethod.GET ||
            methodUpperCase === HttpMethod.HEAD
        ) {
            delete options.body;
        }

        const request = new Request(url, {
            ...options,
            headers: new Headers(headers)
        });

        return fetch(request);
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Providers.Browser.HttpClient';
    }

    // #endregion
}
