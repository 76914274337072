/**
 *
 * @module drmUtils
 *
 */

import DrmType from '../media/drmType';
import DrmClientEndpoint from './drmClientEndpoint';

/**
 *
 * @since 8.0.0
 *
 */
export default class DrmUtils {
    /**
     *
     * @access public
     * @since 8.0.0
     * @param {String} endpointKey
     * @desc Gets DRM type based on endpoint Uri.
     * @returns {String}
     *
     */
    public static getDrmType(endpointKey: keyof typeof DrmClientEndpoint) {
        switch (endpointKey) {
            case DrmClientEndpoint.fairPlayLicense:
            case DrmClientEndpoint.fairPlayLinearLicense:
            case DrmClientEndpoint.fairPlayCertificate:
                return DrmType.FAIRPLAY;

            case DrmClientEndpoint.playReadyLicense:
            case DrmClientEndpoint.playReadyLinearLicense:
                return DrmType.PLAYREADY;

            case DrmClientEndpoint.widevineLicense:
            case DrmClientEndpoint.widevineLinearLicense:
            case DrmClientEndpoint.widevineCertificate:
                return DrmType.WIDEVINE;

            case DrmClientEndpoint.nagraLicense:
            case DrmClientEndpoint.nagraLinearLicense:
            case DrmClientEndpoint.nagraCertificate:
                return DrmType.PRMNAGRA;

            case DrmClientEndpoint.silkKey:
                return DrmType.SILK;

            default:
                return '';
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Services.Media.DrmType} type
     * @desc Gets License endpoint Uri based on DRM type.
     * @returns {String}
     *
     */
    public static getEndpointLicense(type: keyof typeof DrmType) {
        switch (type) {
            case DrmType.FAIRPLAY:
                return DrmClientEndpoint.fairPlayLicense;

            case DrmType.PLAYREADY:
                return DrmClientEndpoint.playReadyLicense;

            case DrmType.WIDEVINE:
                return DrmClientEndpoint.widevineLicense;

            case DrmType.PRMNAGRA:
                return DrmClientEndpoint.nagraLicense;

            case DrmType.SILK:
                return DrmClientEndpoint.silkKey;

            default:
                return '';
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Services.Media.DrmType} type
     * @desc Gets Certificate endpoint Uri based on DRM type.
     * @returns {String}
     *
     */
    public static getEndpointCertificate(type: keyof typeof DrmType) {
        switch (type) {
            case DrmType.FAIRPLAY:
                return DrmClientEndpoint.fairPlayCertificate;

            case DrmType.WIDEVINE:
                return DrmClientEndpoint.widevineCertificate;

            case DrmType.PRMNAGRA:
                return DrmClientEndpoint.nagraCertificate;

            default:
                return '';
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Drm.DrmUtils';
    }
}
