/**
 *
 * @module ripcutImageRequestMode
 * @see https://github.com/STRML/keyMirror
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.RipcutImageRequestMode
 * @desc Specifies image retrieval option such as compositing or scaling logic to be applied by Ripcut to the returned image.
 *
 * @property {String} main - Since `17.0.0` - The original image associated with the master ID. If no image is found, a default black image is returned.
 * @property {String} mainScale - Since `17.0.0` - A new image of the requested size, created by scaling the master image. If no width and height dimensions provided the endpoint will return an original master image.
 * @property {String} mainCompose - Since `17.0.0` - A new image of the requested size, created by compositing master image with a mask image or another master image. The composited image then changed to the new aspect ratio if the new aspect ratio value provided by aspectRatio parameter and further transformed according to provided parameters.
 * @property {String} mainBadging - Since `17.0.0` - A new image of the requested size, created by scaling the master image and placing a label image on top of the new image. If no width and height dimensions provided the endpoint will return a new image with a label on top of it with dimensions of the master image.
 *
 * @example
 * SDK.Ripcut.RipcutImageRequestMode.main // 'main'
 *
 */
export default keymirror({
    main: null,
    mainScale: null,
    mainCompose: null,
    mainBadging: null
});
