/**
 *
 * @module thumbnailPresentationType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Media.ThumbnailPresentationType
 * @desc specifies the type of Thumbnail you are using
 * @note Flexible enum
 *
 * @property {String} BUMPER
 * @property {String} MAIN
 * @property {String} DUB_CARD
 *
 * @example
 * ThumbnailPresentationType.BUMPER // 'BUMPER'
 *
 */
export default keyMirror({
    BUMPER: null,
    MAIN: null,
    DUB_CARD: null
});
