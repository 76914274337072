/**
 *
 * @module productType
 *
 */

/**
 *
 * @access protected
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ProductType
 * @desc Defines the Product type, Live or VOD.
 *
 * @property {String} live - `Since 13.0.0` - Indicates that the content type is Live.
 * @property {String} vod - `Since 13.0.0` - Indicates that the content type is VOD.
 *
 * @example
 * SDK.Services.QualityOfService.ProductType.live // 'Live'
 *
 */
export default {
    live: 'Live',
    vod: 'VOD'
};
