/**
 *
 * @module purchaseActivation
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ErrorReason from '../exception/errorReason';
import PurchaseActivationStatus from './purchaseActivationStatus';

/**
 *
 * @access public
 * @since 3.5.0
 * @desc The information about the purchase activation.
 *
 */
export default class PurchaseActivation {
    /**
     *
     * @access private
     * @type {Array<String>}
     * @desc The product ids, as given by the subscription service.
     *
     */
    private products: Array<string>;

    /**
     *
     * @access private
     * @type {String}
     * @desc The SKU, as given by the 3rd party store.
     *
     */
    private sku: string;

    /**
     *
     * @access private
     * @type {String<SDK.Services.Purchase.PurchaseActivationStatus>}
     * @desc The status of the purchase. One of ["ACTIVE", "EXPIRED", "FAILED", "IGNORED", "UNKNOWN"].
     * This is tightly coupled with the reason field.
     *
     */
    private status: ValueOf<typeof PurchaseActivationStatus>;

    /**
     *
     * @access private
     * @type {SDK.Services.Exception.ErrorReason}
     * @desc The reason that the purchase was ignored or the subscription may not be in effect.
     * This is tightly coupled with the status field.
     *
     */
    private reason?: ErrorReason;

    /**
     *
     * @param {Array<String>} [products=[]]
     * @param {String} [sku='']
     * @param {String<SDK.Services.Purchase.PurchaseActivationStatus>} status
     * @param {ErrorReason} [reason]
     *
     */
    public constructor(options: {
        products?: Array<string>;
        sku?: string;
        status: ValueOf<typeof PurchaseActivationStatus>;
        reason?: ErrorReason;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    products: Types.array.of.nonEmptyString.optional,
                    sku: Types.nonEmptyString.optional,
                    status: Types.in(PurchaseActivationStatus),
                    reason: Types.instanceStrict(ErrorReason).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { products = [], sku = '', status, reason } = options;

        this.products = products;
        this.sku = sku;
        this.status = status;
        this.reason = reason;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Purchase.PurchaseActivation';
    }
}
