/**
 *
 * @module configurationClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/configuration.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import PlatformProviders from './../providers/platformProviders';
import DustLogUtility from './../internal/dust/dustLogUtility';
import DustUrnReference from './../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import HttpMethod from './httpMethod';
import ConfigurationClientConfiguration from './configurationClientConfiguration';
import ConfigurationClientEndpoint from './configurationClientEndpoint';
import SdkSessionConfiguration from './sdkSessionConfiguration';
import BootstrapConfiguration from './bootstrapConfiguration';
import LogTransaction from '../../logging/logTransaction';
import getSafe from '../util/getSafe';

const ConfigurationClientDustReference =
    DustUrnReference.services.configuration.configurationClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access configuration services.
 *
 */
export default class ConfigurationClient {
    /**
     *
     * @param {SDK.Services.Configuration.ConfigurationClientConfiguration} configurationClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    constructor(configurationClientConfiguration, logger, httpClient) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                configurationClientConfiguration: Types.instanceStrict(
                    ConfigurationClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Services.Configuration.ConfigurationClientConfiguration}
         * @desc The configuration information to use.
         *
         */
        this.config = configurationClientConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {HttpClient}
         * @desc The object responsible for making HTTP requests.
         *
         */
        this.httpClient = httpClient;

        /**
         *
         * @access private
         * @type {Boolean}
         * @note needs to default to true to collect dust events before the configuration is fetched and we can
         * determine if this should be enabled
         *
         */
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @param {SDK.Services.Configuration.BootstrapConfiguration} bootstrapConfiguration - The bootstrap configuration
     * information used to construct the service request.
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} environmentConfiguration - The environment
     * configuration information used to construct the service request.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Retrieves the SdkSessionConfiguration information necessary to create an SdkSession instance.
     * @returns {Promise<SdkSessionConfiguration>} The configuration information.
     *
     */
    getConfiguration(
        bootstrapConfiguration,
        environmentConfiguration,
        logTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                bootstrapConfiguration: Types.instanceStrict(
                    BootstrapConfiguration
                ),
                environmentConfiguration: Types.instanceStrict(
                    PlatformProviders.EnvironmentConfiguration
                ),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getConfiguration', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const payload = this.getPayload({
            bootstrapConfiguration,
            environmentConfiguration
        });

        const endpointKey = ConfigurationClientEndpoint.getConfiguration;

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ConfigurationClientDustReference.getConfiguration,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        logger.info(this.toString(), `GET ${payload.url}`);

        return httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility, endpointKey);
            })
            .then((response) => {
                const { data } = response;
                const { commonHeaders, commonValues, services, application } =
                    data;

                const options = {
                    commonHeaders,
                    commonValues,
                    services,
                    environmentConfiguration,
                    logger,
                    application
                };

                const sdkSessionConfiguration = new SdkSessionConfiguration(
                    options
                );

                return Promise.resolve(sdkSessionConfiguration);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Configuration.BootstrapConfiguration} options.bootstrapConfiguration
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} options.environmentConfiguration
     * @returns {Object} payload
     *
     */
    getPayload(options) {
        const { bootstrapConfiguration, environmentConfiguration } = options;
        const {
            sdkVersion,
            deviceFamily,
            deviceProfile,
            applicationRuntime,
            configVersion
        } = environmentConfiguration;

        const {
            debugEnabled,
            environment,
            clientId,
            configHostName,
            configHostOverride,
            configHostUrlOverride
        } = bootstrapConfiguration;

        const { endpoints, extras } = this.config;
        const { bootstrap } = endpoints;
        const { headers, href, templated } = bootstrap;

        let url = (debugEnabled && configHostUrlOverride) || href;

        if (templated) {
            const baseUrl =
                configHostOverride ||
                getSafe(() => extras.configHostParams[configHostName].baseUrl);

            url = url
                .replace(/\{baseUrl\}/gi, baseUrl)
                .replace(/\{configVersion\}/gi, configVersion)
                .replace(/\{clientId\}/gi, clientId)
                .replace(/\{deviceFamily\}/gi, deviceFamily)
                .replace(/\{sdkVersion\}/gi, sdkVersion)
                .replace(/\{applicationRuntime\}/gi, applicationRuntime)
                .replace(/\{deviceProfile\}/gi, deviceProfile)
                .replace(/\{environment\}/gi, environment);
        }

        return {
            url,
            headers
        };
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.ConfigurationClient';
    }

    // #endregion
}
