/**
 *
 * @module nagraCertificateStorage
 *
 */

import CertificateStorage from './certificateStorage';
import DrmType from '../services/media/drmType';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import type Logger from '../logging/logger';

/**
 *
 * @since 4.4.0
 * @desc Provides a storage mechanism for storing a Nagra Certificate.
 *
 */
export default class NagraCertificateStorage extends CertificateStorage {
    /**
     *
     * @param {Object} options
     * @param {String} clientId
     * @param {String} environment
     * @param {SDK.Services.PlatformProviders.Storage} storage
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        storage: CoreStorageProvider;
        logger: Logger;
    }) {
        super({ ...options, drmType: DrmType.PRMNAGRA });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @desc gets the stored certificate if found
     * @note the certificate has to be stringified because our internal storage mechanism runs JSON.parse on it before
     * the JS SDK can use it
     * @returns {Promise<Uint8Array | string | undefined>}
     *
     */
    public override async getStoredCertificate(): Promise<Uint8Array> {
        const { cacheKey, storage } = this;

        let certificate;

        try {
            certificate = await storage.get(cacheKey);

            return new Uint8Array(
                JSON.stringify(certificate)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            );
        } catch (ex) {
            return certificate;
        }
    }

    /**
     *
     * @access private
     * @param {ArrayBuffer} certificate
     * @desc attempts to store a certificate via the Storage API
     * @returns {Promise<Void>}
     *
     */
    public override async storeCertificate(certificate: ArrayBuffer) {
        const { cacheKey, storage } = this;

        return await storage.set(cacheKey, certificate);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.NagraCertificateStorage';
    }

    // #endregion private
}
