/**
 *
 * @module playbackEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/playback/v1/playback.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import BufferType from './bufferType';
import NetworkType from './networkType';
import PlaybackActivity from './playbackActivity';
import QoePlaybackError from './qoePlaybackError';
import PlayerSeekDirection from './playerSeekDirection';
import ProductType from './productType';
import SeekDirection from './seekDirection';

import { AdInsertionType, PresentationType } from './enums';

import {
    AdPodPlacementTypedef,
    AdPodDataTypedef,
    AdSlotDataTypedef
} from './typedefs';

/**
 *
 * @since 13.0.0
 * @desc These events represent playback related events. This event should be sent every time the `playbackActivity` changes.
 * @note urn:dss:event:client:playback:event:v1
 *
 */
export default class PlaybackEventData {
    /**
     *
     * @param {Object} [options={}]
     * @param {String<SDK.Services.QualityOfService.PlaybackActivity>} options.playbackActivity
     * @param {String} [options.streamUrl='null']
     * @param {String} [options.playbackSessionId]
     * @param {String<SDK.Services.QualityOfService.ProductType>} [options.productType]
     * @param {Number} [options.playheadPosition=-1]
     * @param {Number} [options.videoBitrate=0]
     * @param {Number} [options.videoAverageBitrate=0]
     * @param {Number} [options.audioBitrate=0]
     * @param {Number} [options.maxAllowedVideoBitrate=0]
     * @param {Number} [options.segmentPosition]
     * @param {String} [options.cdnName='null']
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType=SDK.Services.QualityOfService.NetworkType.unknown]
     * @param {Number} [options.liveLatencyAmount]
     * @param {String} [options.cause]
     * @param {String<SDK.Services.QualityOfService.BufferType>} [options.bufferType]
     * @param {Number} [options.duration]
     * @param {String<SDK.Services.QualityOfService.PlayerSeekDirection>} [options.playerSeekDirection]
     * @param {Number} [options.seekDistance]
     * @param {String<SDK.Services.QualityOfService.SeekDirection>} [options.seekDirection]
     * @param {Number} [options.seekSize]
     * @param {String<SDK.Services.QualityOfService.QoePlaybackError>} [options.playbackError]
     * @param {String} [options.playbackErrorDetail]
     * @param {Array<String>} [options.cdnRequestedTrail=[]]
     * @param {Array<String>} [options.cdnFailedTrail=[]]
     * @param {Number} [options.cdnFallbackCount=0]
     * @param {Boolean} [options.isCdnFallback=false]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Number} [options.monotonicTimestamp]
     * @param {Object<String, String>} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {String<SDK.Services.QualityOfService.PresentationType>} [options.presentationType=SDK.Services.QualityOfService.unknown]
     * @param {String<SDK.Services.QualityOfService.AdInsertionType>} [options.adInsertionType=SDK.Services.QualityOfService.none]
     * @param {String<SDK.Services.Media.SubscriptionType>} [options.subscriptionType]
     * @param {String} [options.adSessionId]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData>} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData>} [options.adSlotData]
     * @param {Number} [options.adPlayheadPosition]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackActivity: Types.in(PlaybackActivity),
                    streamUrl: Types.nonEmptyString.optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    playheadPosition: Types.number.optional,
                    videoBitrate: Types.number.optional,
                    videoAverageBitrate: Types.number.optional,
                    audioBitrate: Types.number.optional,
                    maxAllowedVideoBitrate: Types.number.optional,
                    segmentPosition: Types.number.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    liveLatencyAmount: Types.number.optional,
                    cause: Types.nonEmptyString.optional,
                    bufferType: Types.in(BufferType).optional,
                    duration: Types.number.optional,
                    playerSeekDirection: Types.in(PlayerSeekDirection).optional,
                    seekDistance: Types.number.optional,
                    seekDirection: Types.in(SeekDirection).optional,
                    seekSize: Types.number.optional,
                    playbackError: Types.in(QoePlaybackError).optional,
                    playbackErrorDetail: Types.nonEmptyString.optional,
                    cdnRequestedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFailedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFallbackCount: Types.number.optional,
                    isCdnFallback: Types.boolean.optional,
                    contentKeys: Types.object().optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    monotonicTimestamp: Types.number.optional,
                    data: Types.object().optional,
                    presentationType: Types.in(PresentationType).optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    subscriptionType: Types.string.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional,
                    adPlayheadPosition: Types.number.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            playbackActivity,
            streamUrl,
            playbackSessionId,
            productType,
            playheadPosition,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            cdnName,
            networkType,
            liveLatencyAmount,
            cause,
            bufferType,
            duration,
            playerSeekDirection,
            seekDistance,
            seekDirection,
            seekSize,
            playbackError,
            playbackErrorDetail,
            cdnRequestedTrail,
            cdnFailedTrail,
            cdnFallbackCount,
            isCdnFallback,
            clientGroupIds,
            serverGroupIds,
            monotonicTimestamp,
            contentKeys,
            data = {},
            presentationType,
            adInsertionType,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            adPlayheadPosition
        } = options || {};

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.PlaybackActivity>}
         * @desc Used to identify what activity is occurring.
         *
         */
        this.playbackActivity = playbackActivity;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String}
         * @desc The selected stream URL from the MediaPayload.
         *
         */
        this.streamUrl = streamUrl || 'null';

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Client generated ID of the stream/playback session.
         * @note Is added in the `logQoeEvent` method on `SDK.Media.PlaybackTelemetryDispatcher`.
         *
         */
        this.playbackSessionId = playbackSessionId;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
         * @desc The Product type, Live or VOD.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.productType = productType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackStartedEvent`).
         *
         */
        this.playheadPosition = Check.assigned(playheadPosition)
            ? playheadPosition
            : -1;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The peak video bitrate in bps.
         * @note The SDK should keep track of the most recent videoBitrate value from the `PlaybackEventListener.onBitrateChanged` event.
         * @note If value is 0 or not available return 0.
         *
         */
        this.videoBitrate = Check.assigned(videoBitrate) ? videoBitrate : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The average video bitrate in bps.
         * @note The SDK should keep track of the most recent videoAverageBitrate value from the `PlaybackEventListener.onBitrateChanged` event.
         * @note If value is 0 or not available return 0.
         *
         */
        this.videoAverageBitrate = Check.assigned(videoAverageBitrate)
            ? videoAverageBitrate
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant last
         * played before the event, as reported in the HLS or DASH manifest.
         * @note The SDK should keep track of the most recent audioBitrate value from the `PlaybackEventListener.onAudioBitrateChanged` event.
         * @note If the value is 0 or not available, return 0. If value is muxed, send the bitrate of the combined content.
         *
         */
        this.audioBitrate = Check.assigned(audioBitrate) ? audioBitrate : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The highest video bitrate (in bps) available in the current DASH/HLS manifest that is allowed to play.
         * The user may not reach this bitrate in their session.
         * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
         * resolution caps based on video window resolution/orientation.
         * @note The value should default to 0 if unavailable or unknown.
         * @note If the `PlaybackActivity` is `started`, SDKs should source this from the associated `SDK.Media.PlaybackStartedEvent`
         * event and update the cached `maxAllowedVideoBitrate` value.
         * @note If the `PlaybackActivity` is not `started`, SDKs should source this from the cached `maxAllowedVideoBitrate` value.
         *
         */
        this.maxAllowedVideoBitrate = Check.assigned(maxAllowedVideoBitrate)
            ? maxAllowedVideoBitrate
            : 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc This should be the epoch time in milliseconds of the video.
         * @note Required if `productType` is `Live`.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `Bam.Sdk.Media.PlaybackStartedEvent`).
         *
         */
        this.segmentPosition = Check.assigned(segmentPosition)
            ? Math.floor(segmentPosition)
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String}
         * @desc The CDN running the server.
         * @note A string value of 'null' needs to be provided if not run by a CDN.
         *
         */
        this.cdnName = cdnName || 'null';

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.NetworkType>}
         * @desc The type of network connection currently in use by the client.
         *
         */
        this.networkType = networkType || NetworkType.unknown;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Latency of the current playback position in milliseconds, with the live head (live head - current position >= 0).
         * Required if `productType` is `Live`.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackStartedEvent`).
         *
         */
        this.liveLatencyAmount = Check.assigned(liveLatencyAmount)
            ? Math.floor(liveLatencyAmount)
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @description The reason for ending playback, pausing, seeking or resuming. Required if `playbackActivity` is `ended`,
         * `seekStarted`, `seekEnded`, `paused` or `resumed`.
         * @note Values for `ended` are defined by `PlaybackExitedCause`.
         * @note Values for `pause` are defined by `PlaybackPausedCause`.
         * @note Values for `resume` are defined by `PlaybackResumedCause`.
         * @note Values for `seekStarted`/`seekEnded` are defined by `PlaybackSeekCause`.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackPausedEvent`).
         *
         */
        this.cause = cause;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The type of buffering that is happening.
         * @note Required if `playbackActivity` is `rebufferingStarted` or `rebufferingEnded`.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.RebufferingStartedEvent`
         * or `SDK.Media.RebufferingEndedEvent`).
         *
         */
        this.bufferType = bufferType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc The amount of wall clock time in milliseconds, between rebuffering started and rebuffering ended.
         * @note Required if `playbackActivity` is `rebufferingEnded`.
         * @note Source from the `SDK.Media.RebufferingEndedEvent`.
         *
         */
        this.duration = duration;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.PlayerSeekDirection>|undefined}
         * @desc Where the intended seek position is relative to current position.
         * @note Required if `playbackActivity` is `seekStarted` or `seekEnded`.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackSeekStartedEvent`
         * or `SDK.Media.PlaybackSeekEndedEvent`).
         *
         */
        this.playerSeekDirection = playerSeekDirection;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc How far the player seeked in stream time, in milliseconds. This is the actual value, not a measure of intent.
         * @note Required if `playbackActivity` is `seekStarted` or `seekEnded`.
         * @note This value should always be positive. If a player is attempting the seek while already in the middle of a seek, it
         * is the distance from where the previous seek was to.
         * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `Bam.Sdk.Media.PlaybackSeekStartedEvent`
         * or `Bam.Sdk.Media.PlaybackSeekEndedEvent`).
         *
         */
        this.seekDistance = seekDistance;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.SeekDirection>|undefined}
         * @desc To provide indication that the user is seeking to/from a specific point of playback eg. live playback (live edge).
         * @note Heimdall 1.0 field. Can optionally be provided if `productType` is `Live` and if `playbackActivity` is `seekStarted`.
         * @note Source from the associated `Bam.Sdk.Media.PlaybackSeekStartedEvent`.
         *
         */
        this.seekDirection = seekDirection;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Indication that the user is seeking by a fixed time (size) e.g. +30, -30.
         * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `seekStarted`.
         * @note Source from the associated `Bam.Sdk.Media.PlaybackSeekStartedEvent`.
         *
         */
        this.seekSize = seekSize;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.QoePlaybackError>|undefined}
         * @desc An error representing a fatal playback failure. `null` if no error occurred.
         * @note Can optionally be provided if `playbackActivity` is `ended`.
         * @note If an error occurred, SDKs should convert either the associated MediaFetchError or a PlaybackError to a QoePlaybackError.
         *
         */
        this.playbackError = playbackError;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Supporting error text for `playbackError` property where this can be provided for additional context.
         * @note Unless requested otherwise, set to native platform error message if available.
         * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `ended`.
         * @note Source from the `SDK.Media.PlaybackEndedEvent`.
         *
         */
        this.playbackErrorDetail = playbackErrorDetail;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Array<String>}
         * @desc List of CDNs attempted in case of failure cases e.g. {Fastly, Akamai, Level-3}.
         * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `ended`.
         *
         */
        this.cdnRequestedTrail = cdnRequestedTrail || [];

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Array<String>}
         * @desc List of CDNs failed e.g. {Fastly, Akamai}.
         * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `ended`.
         *
         */
        this.cdnFailedTrail = cdnFailedTrail || [];

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Value increments from 0 for each fallback action. This value initializes at zero for the
         * first CDN attempt, and will increment by 1 for each fallback action that occurs.
         * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `ended`.
         *
         */
        this.cdnFallbackCount = cdnFallbackCount || 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean}
         * @desc False if first cdn selected successful i.e. `cdnFailedTrail` empty, True otherwise.
         * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `ended`.
         *
         */
        this.isCdnFallback = isCdnFallback || false;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when testing PQM.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.clientGroupIds = clientGroupIds;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.serverGroupIds = serverGroupIds;

        /**
         *
         * @access public
         * @since 20.0.0
         * @type {Number|undefined}
         * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
         * @note Source from `SDK.MonotonicTimestampManager.getTimestamp()`.
         *
         */
        this.monotonicTimestamp = monotonicTimestamp;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Object<String, String>}
         * @desc Associated content keys for the media item.
         * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.contentKeys = contentKeys || {};

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.PresentationType>}
         * @desc The type of presentation currently being played.
         * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
         * event or the `Bam.Sdk.Media.PlaybackStartedEvent` object.
         *
         */
        this.presentationType = presentationType || PresentationType.unknown;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.AdInsertionType>}
         * @desc The way ads are inserted into the stream.
         * @note Source by converting the `Bam.Sdk.Media.MediaDescriptor.AssetInsertionStrategy`.
         *
         */
        this.adInsertionType = adInsertionType || AdInsertionType.none;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.Media.SubscriptionType>}
         * @desc An identifier that provides insight into the tier of service associated with the subscription that
         * is entitled for playback.
         * @note Required if `adInsertionType` != `none`.
         * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.subscriptionType field.
         *
         */
        this.subscriptionType = subscriptionType || '';

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String}
         * @desc Random UUID assigned by the ad server.
         * @note Required if `adInsertionType` != `none`.
         * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.id field.
         *
         */
        this.adSessionId = adSessionId;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>}
         * @desc Placement information relevant to ad pods.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the associated `PlaybackEventListener` event arguments.
         *
         */
        this.adPodPlacement = adPodPlacement;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdPodData>}
         * @desc Metadata relevant to ad pods.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the associated `PlaybackEventListener` event arguments.
         *
         */
        this.adPodData = adPodData;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdSlotData>}
         * @desc Metadata relevant to ad slots.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the associated `PlaybackEventListener` event arguments.
         *
         */
        this.adSlotData = adSlotData;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Object<SDK.Services.QualityOfService.AdSlotData>}
         * @desc The location of the current ad playhead, measured as a millisecond offset from the start time of the
         * current ad pod.
         * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
         * @note Source from the associated `PlaybackEventListener` event arguments.
         *
         */
        this.adPlayheadPosition = adPlayheadPosition;

        /**
         *
         * @deprecated Value will be reported as 'deprecated'. Replaced by `presentationType`.
         * @access public
         * @since 20.0.1
         * @type {String}
         *
         */
        this.periodType = 'deprecated';

        this.setData(data);
    }

    // #region private

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    setData(data) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.PlaybackEventData';
    }

    // #endregion
}
