/**
 *
 * @module entry
 * @desc {SDK.CustomerService} entry point.
 *
 */

import CustomerServiceManager from './customerServiceManager';

/**
 *
 * @typedef {Object} SDK.CustomerService
 *
 * @property {CustomerServiceManager} CustomerServiceManager
 *
 */
export default {
    CustomerServiceManager
};
