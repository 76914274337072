/**
 *
 * @module purchaseContext
 *
 */

/**
 *
 * @since 4.15.0
 * @typedef {Object} SDK.Services.Orchestration.PurchaseContext
 * @desc Purchase context to identify the page a request originated from.
 *
 * @property {String} PlanPicker - Since `4.15.0`.
 * @property {String} EarlyAccessPlanPicker - Since `4.15.0`.
 * @property {String} AccountDetails - Since `4.15.0`.
 * @property {String} EarlyAccess - Since `4.15.0`.
 *
 */
enum PurchaseContext {
    PlanPicker = 'PlanPicker',
    EarlyAccessPlanPicker = 'EarlyAccessPlanPicker',
    AccountDetails = 'AccountDetails',
    EarlyAccess = 'EarlyAccess'
}

export default PurchaseContext;
