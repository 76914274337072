/**
 *
 * @module platformProviders
 *
 */

import { Types, typecheck } from '@dss/type-checking';

let httpClientProvider;
let environmentConfigurationProvider;
let storageProvider;

/**
 *
 * @access protected
 * @desc Provides a way to inject and pull core platform provider implementations.
 *
 */
export default class PlatformProviders {
    /**
     *
     * @access public
     * @since 4.14.0
     * @param {Object} options
     * @param {Function<SDK.Services.Configuration.BaseEnvironmentConfiguration>} options.EnvironmentConfiguration
     * @param {Function<CoreHttpClientProvider>} options.HttpClient
     * @param {Function<Storage>} options.Storage
     * @desc Allows configuration of core infrastructure providers
     *
     */
    static setProviders(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    EnvironmentConfiguration: Types.function,
                    HttpClient: Types.function,
                    Storage: Types.function
                })
            };

            typecheck(PlatformProviders, 'setProviders', params, arguments);
        }

        const { EnvironmentConfiguration, HttpClient, Storage } = options;

        environmentConfigurationProvider = EnvironmentConfiguration;
        httpClientProvider = HttpClient;
        storageProvider = Storage;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {SDK.Services.Configuration.BaseEnvironmentConfiguration}
     *
     */
    static get EnvironmentConfiguration() {
        if (!environmentConfigurationProvider) {
            throw new Error('EnvironmentConfiguration provider not specified');
        }

        return environmentConfigurationProvider;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {CoreHttpClientProvider}
     *
     */
    static get HttpClient() {
        if (!httpClientProvider) {
            throw new Error('HttpClient provider not specified');
        }

        return httpClientProvider;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {CoreStorageProvider}
     *
     */
    static get Storage() {
        if (!storageProvider) {
            throw new Error('Storage provider not specified');
        }

        return storageProvider;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Providers.PlatformProviders';
    }

    // #endregion
}
