/**
 *
 * @module AampUvePlayerAdapter
 * @desc PlayerAdapter for aamp uve devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/fed-ce-espnplus/espn-comcast-x1-app/blob/main/espn-src/src/lib/player/ESPNVideoPlayback/NativeVideoPlayer/AampUvePlayerAdapter.js
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import InternalEvents from './../../internalEvents';

import PlaybackEventListener from './../playbackEventListener';
import PlaybackMetrics from './../playbackMetrics';
import PlayerAdapter from './../playerAdapter';

import PlaybackExitedCause from './../../services/qualityOfService/playbackExitedCause';
import PlaybackPausedCause from '../../services/qualityOfService/playbackPausedCause';
import PlaybackResumedCause from '../../services/qualityOfService/playbackResumedCause';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import StartupActivity from '../../services/qualityOfService/startupActivity';

import Playlist from '../playlist';

import type DrmProvider from '../../drm/drmProvider';

import { MediaAnalyticsKey } from './../enums';

const PlayerEvents = {
    playbackStarted: 'playbackStarted',
    playbackStateChanged: 'playbackStateChanged',
    playbackFailed: 'playbackFailed',
    bitrateChanged: 'bitrateChanged'
};

const PlayerStatesEnum = {
    idle: 0,
    initializing: 1,
    initialized: 2,
    buffering: 5,
    paused: 6,
    seeking: 7,
    playing: 8
};

/**
 *
 * @since 15.0.0
 * @desc Interface used to communicate with the media player.
 *
 */
export default class AampUvePlayerAdapter extends PlayerAdapter {
    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Boolean}
     * @desc A flag to keep track of whether playback has started.
     *
     */
    private hasStarted: boolean;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Media.Playlist|null}
     * @desc The playlist to be used during playback.
     *
     */
    private playlist: Nullable<Playlist>;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Services.QualityOfService.PlaybackStartupEventData|undefined}
     * @desc Keeps track of CDN fallback for QoS support.
     *
     */
    private playbackStartupEventData?: PlaybackStartupEventData;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Number}
     * @desc The ID for the CDN fallback timer.
     *
     */
    private cdnFallbackTimerId: number;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Boolean}
     * @desc A flag to keep track of whether the CDN fallback timer has timed out.
     *
     */
    private cdnFallbackTimeoutReached: boolean;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @throws {InvalidArgumentException}
     * @note The AampUvePlayerAdapter requires nativePlayer.load
     *
     */
    public constructor(options: {
        nativePlayer: TodoAny;
        videoPlayerName: string;
        videoPlayerVersion: string;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        load: Types.function
                    })
                })
            };

            typecheck(this, params, arguments);
        }

        this.hasStarted = false;
        this.playlist = null;
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });
        this.cdnFallbackTimerId = 0;
        this.cdnFallbackTimeoutReached = false;
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @throws {InvalidStateException} Unable to set playlistUri on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    public override async setSource(playlist: Playlist) {
        const { nativePlayer } = this;

        this.playlist = playlist;
        this.playlistUri = playlist.streamUri as string;
        this.currentStreamUrl = playlist.streamUri as string;

        const qos = playlist.getTrackingData(MediaAnalyticsKey.qos) as TodoAny;

        // keeps track of the CDN fallback attempts
        this.playbackStartupEventData?.fallbackAttempt(qos.cdnVendor);

        if (this.playbackStartupEventData?.isCdnFallback) {
            this.playbackStartupEventData.setQosData(qos);
        }

        if (this.cdnFallbackTimerId === 0) {
            this.cdnFallbackTimerId = setTimeout(() => {
                this.cdnFallbackTimeoutReached = true;
            }, this.cdnFallback.defaultTimeout * 1000) as unknown as number;
        }

        nativePlayer.load(playlist.streamUri);

        nativePlayer.addCustomHTTPHeader(
            'Cookie',
            `Authorization=${this.accessToken}`
        );

        nativePlayer.addCustomHTTPHeader('ssess', this.adEngineData.ssess);
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @desc Gets a snapshot of information about media playback.
     * @throws {InvalidStateException} Unable to get NativePlayer playhead or bitrate data
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    public override getPlaybackMetrics() {
        const currentBitrate =
            this.nativePlayer.getCurrentVideoBitrate() / 1000;
        const currentPlayhead = this.nativePlayer.getCurrentPosition();

        return new PlaybackMetrics({ currentBitrate, currentPlayhead });
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {PlaybackEventListener} listener
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const {
            boundHandlers,
            nativePlayer,
            playbackStartedEvent,
            playbackStateChangedEvent,
            bitrateChangedEvent,
            playbackError
        } = this;

        const {
            playbackStarted,
            playbackStateChanged,
            playbackFailed,
            bitrateChanged
        } = PlayerEvents;

        if (Check.assigned(nativePlayer.addEventListener)) {
            this.listener = listener;

            boundHandlers.playbackStartedEvent =
                playbackStartedEvent.bind(this);
            boundHandlers.playbackStateChangedEvent =
                playbackStateChangedEvent.bind(this);
            boundHandlers.playbackError = playbackError.bind(this);
            boundHandlers.bitrateChangedEvent = bitrateChangedEvent.bind(this);

            nativePlayer.addEventListener(
                playbackStarted,
                boundHandlers.playbackStartedEvent
            );
            nativePlayer.addEventListener(
                playbackStateChanged,
                boundHandlers.playbackStateChangedEvent
            );
            nativePlayer.addEventListener(
                playbackFailed,
                boundHandlers.playbackError
            );
            nativePlayer.addEventListener(
                bitrateChanged,
                boundHandlers.bitrateChangedEvent
            );
        }
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    public override removeListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer, boundHandlers } = this;

        const {
            playbackStarted,
            playbackStateChanged,
            playbackFailed,
            bitrateChanged
        } = PlayerEvents;

        if (
            Check.assigned(nativePlayer.addEventListener) &&
            this.listener === listener
        ) {
            this.listener = null;

            nativePlayer.removeEventListener(
                playbackStarted,
                boundHandlers.playbackStartedEvent
            );
            nativePlayer.removeEventListener(
                playbackStateChanged,
                boundHandlers.playbackStateChangedEvent
            );
            nativePlayer.removeEventListener(
                playbackFailed,
                boundHandlers.playbackError
            );
            nativePlayer.removeEventListener(
                bitrateChanged,
                boundHandlers.bitrateChangedEvent
            );
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Trigger when player state changes.
     *
     */
    private playbackStateChangedEvent(event: TodoAny) {
        switch (event.state) {
            case PlayerStatesEnum.paused:
                this.playbackPausedEvent();
                break;

            case PlayerStatesEnum.playing:
                this.playbackResumedEvent();
                break;

            case PlayerStatesEnum.initialized:
                this.playbackInitializedEvent();
                break;
        }
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    public override setDrmProvider(drmProvider: DrmProvider) {
        this.drmProvider = drmProvider;

        return Promise.resolve();
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener } = this;

        this.playlistUri = '';
        this.drmProvider = null;
        this.cdnFallbackTimerId = 0;
        this.cdnFallbackTimeoutReached = false;
        this.hasStarted = false;

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        if (listener) {
            this.removeListener(listener);
        }

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @desc Trigger when playback has been exited.
     * @note in version `4.6.0` this was renamed from `playbackExitedEvent` to `playbackEndedEvent`
     *
     */
    public playbackEndedEvent(isEnded: boolean, error: TodoAny) {
        const playbackMetrics = this.getPlaybackMetrics();
        const { code, description } = error || {};

        let cause;
        let isAdvanceableError = false;

        if (error) {
            cause = PlaybackExitedCause.error;
            isAdvanceableError = code === 10;
        } else if (isEnded) {
            cause = PlaybackExitedCause.playedToEnd;
        } else {
            cause = PlaybackExitedCause.user;
        }

        const {
            cdnFallback,
            playlist,
            hasStarted,
            playbackStartupEventData,
            cdnFallbackTimeoutReached
        } = this;

        const cdnFallbackCount = playbackStartupEventData?.cdnFallbackCount;
        const playbackEndedEventData = {
            cause,
            errorDetail: description,
            playheadPosition: playbackMetrics.currentPlayhead,
            cdnRequestedTrail: playbackStartupEventData?.cdnRequestedTrail,
            cdnFailedTrail: playbackStartupEventData?.cdnFailedTrail,
            cdnFallbackCount,
            isCdnFallback: playbackStartupEventData?.isCdnFallback
        };

        if (Check.assigned(error)) {
            // sets isCdnFallback to true and updates the cdnFailedTrail property
            playbackStartupEventData?.fallbackFailed();
        }

        this.onPlaybackEnded(playbackEndedEventData);

        if (
            isAdvanceableError &&
            cdnFallback.isEnabled &&
            !cdnFallbackTimeoutReached &&
            cdnFallbackCount !== undefined &&
            cdnFallbackCount < cdnFallback.fallbackLimit &&
            !hasStarted &&
            playlist &&
            playlist.advanceNextSource()
        ) {
            const { mediaSourceIndex, mediaSources } = playlist;
            const mediaSource = mediaSources[mediaSourceIndex] || {};

            this.emit(InternalEvents.UpdateAdEngine, mediaSource.priority);
            this.setSource(playlist);
        }
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    public dispose() {
        this.clean();

        this.nativePlayer = null;
        this.accessToken = null;
        this.adEngineData = null;
        this.cdnFallbackTimerId = 0;
        this.playbackStartupEventData = undefined;
    }

    /* #region private: */

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Trigger when playback errors.
     *
     */
    private playbackError(eventData = {}) {
        const { shouldRetry, code, description } = eventData as TodoAny;

        const error = { code, description };

        const startupFailCode = 10;

        if (!shouldRetry || code === startupFailCode) {
            this.playbackEndedEvent(true, error);

            if (code !== startupFailCode) {
                // removes listener on fatal error
                this.removeListener(this.listener);
            }
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Trigger when playback starts.
     *
     */
    private playbackStartedEvent() {
        this.hasStarted = true;

        const { currentPlayhead } = this.getPlaybackMetrics();

        const playbackStartedEventData = {
            playheadPosition: currentPlayhead
        };

        this.onPlaybackStarted(playbackStartedEventData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Trigger when playback gets paused.
     *
     */
    private playbackPausedEvent() {
        const { currentPlayhead } = this.getPlaybackMetrics();

        const playbackPausedEventData = {
            cause: PlaybackPausedCause.user,
            playheadPosition: currentPlayhead
        };

        this.onPlaybackPaused(playbackPausedEventData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Trigger when playback gets resumed.
     *
     */
    private playbackResumedEvent() {
        const { currentPlayhead } = this.getPlaybackMetrics();

        const playbackResumedEventData = {
            cause: PlaybackResumedCause.user,
            playheadPosition: currentPlayhead
        };

        this.onPlaybackResumed(playbackResumedEventData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Handles the bitrateChanged event
     *
     */
    private bitrateChangedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        this.onBitrateChanged({
            bitrateAvg: playbackMetrics.currentBitrateAvg,
            bitratePeak: playbackMetrics.currentBitratePeak,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @note Handles the initialized state when player changed event is triggered
     *
     */
    private playbackInitializedEvent() {
        const { currentStreamUrl: streamUrl } = this;

        const { currentPlayhead } = this.getPlaybackMetrics();

        const playbackInitializedEventData = {
            streamUrl,
            playheadPosition: currentPlayhead
        };

        this.onPlaybackInitialized(playbackInitializedEventData);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.AampUvePlayerAdapter';
    }
}
