/**
 *
 * @module playbackExitedCause
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackExitedCause
 * @desc Defines the different end causes of playback.
 *
 * @property {String} applicationExit - Since `4.0.0` - When the application was terminated during playback.
 * @property {String} applicationBackground - Since `4.0.0` - When the application was sent to background during playback.
 * Note if playback continues in PiP, it is not considered to have ended.
 * @property {String} user - Since `4.0.0` - When the user exits the player.
 * @property {String} error - Since `4.0.0` - When a fatal error occurs which prevents playback from continuing.
 * @property {String} playedToEnd - Since `4.0.0` - When the media plays to completion and the player automatically closes.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackExitedCause.applicationExit // 'applicationExit'
 *
 */
export default keyMirror({
    applicationExit: null,
    applicationBackground: null,
    user: null,
    error: null,
    playedToEnd: null
});
