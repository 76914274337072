/**
 *
 * @module addToWatchlistQuery
 *
 */

import { Check } from '@dss/type-checking';

import ContentQuery from './../contentQuery';
import GraphQlPersistedQuery from './../graphQlPersistedQuery';

/**
 *
 * @desc Represents a Content query with context `watchlistPersisted` that is used to add items to a user's watchlist.
 *
 */
export default class AddToWatchlistQuery extends ContentQuery {
    /**
     *
     * @param {Array<String>} contentIds
     * @param {String} [queryIdDefault='core/AddToWatchlist'] - Default query Id value from the config.
     *
     */
    public constructor(contentIds: Array<string>, queryIdDefault?: string) {
        const queryId = Check.nonEmptyString(queryIdDefault as string)
            ? queryIdDefault
            : 'core/AddToWatchlist';

        const graphQlPersistedQuery = new GraphQlPersistedQuery(
            queryId as string,
            {
                contentIds
            }
        );

        super('watchlistPersisted', graphQlPersistedQuery);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.Watchlist.AddToWatchlistQuery';
    }
}
