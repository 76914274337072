/**
 *
 * @module accessTokenProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from '../logging/logger';
import Access from './access';
import AccessStorage from './accessStorage';
import AccessToken from './accessToken';

/**
 *
 * @see https://nodejs.org/api/events.html
 * @desc Provides the Access token and associated state.
 *
 */
export default class AccessTokenProvider extends EventEmitter {
    /**
     *
     * @access protected
     * @since 8.0.0
     * @type {SDK.Token.AccessStorage}
     *
     */
    public storage: AccessStorage;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessToken|null}
     *
     */
    private accessToken: Nullable<AccessToken>;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @param {SDK.Token.AccessStorage} storage
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(storage: AccessStorage, logger: Logger) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                storage: Types.instanceStrict(AccessStorage),
                logger: Types.instanceStrict(Logger)
            };

            typecheck(this, params, arguments);
        }

        this.storage = storage;

        this.accessToken = this.getAccessToken();

        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @returns {SDK.Token.AccessToken|null}
     *
     */
    public getAccessToken() {
        const nullableAccess = this.storage.getAccess();

        if (Check.not.assigned(nullableAccess)) {
            return null;
        }

        const access = nullableAccess as Access;

        const accessToken = new AccessToken(
            access.context.token,
            access.context.region
        );

        this.accessToken = accessToken;

        return this.accessToken;
    }

    /**
     *
     * @access private
     * @returns {SDK.Services.Token.AccessContext|null}
     *
     */
    private getAccessContext() {
        const storage = this.storage;
        const access = storage && storage.getAccess();

        if (Check.not.assigned(access)) {
            return null;
        }

        return (access as Access).context;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Token.AccessTokenProvider';
    }
}
