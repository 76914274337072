/**
 *
 * @module seekDirection
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 9.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SeekDirection
 * @desc Defines the different directions of seek.
 *
 * @property {String} fromLiveEdge - Since `9.0.0` - User is seeking backwards from live event.
 * @property {String} toLiveEdge - Since `9.0.0` - User is seeking towards live playback.
 *
 * @example
 * SDK.Services.QualityOfService.SeekDirection.toLiveEdge // 'toLiveEdge'
 *
 */
export default keyMirror({
    fromLiveEdge: null,
    toLiveEdge: null
});
