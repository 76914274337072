/**
 *
 * @module diagnosticsApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import DiagnosticFeature from './diagnosticFeature';
import TelemetryManager from './internal/telemetry/telemetryManager';
import BaseApi from './baseApi';
import type EventBuffer from './internal/telemetry/eventBuffer';
import type Logger from './logging/logger';

/**
 *
 * @access public
 * @since 4.11.0
 * @desc Provides the ability to validate Glimpse and QoE DUST events against the event and field definitions contained
 * in the Schema Registry
 *
 */
export default class DiagnosticsApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.11.0
     * @type {SDK.Internal.Telemetry.TelemetryManager}
     *
     */
    private telemetryManager: TelemetryManager;

    /**
     *
     * @access private
     * @since 4.11.0
     * @type {SDK.Internal.Telemetry.EventBuffer}
     *
     */
    private glimpseBuffer: EventBuffer;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Internal.Telemetry.EventBuffer}
     *
     */
    private qoeBuffer: EventBuffer;

    /**
     *
     * @param {Object} options
     * @param {SDK.Internal.Telemetry.TelemetryManager} options.telemetryManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        telemetryManager: TelemetryManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    telemetryManager: Types.instanceStrict(TelemetryManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { telemetryManager } = options;

        this.telemetryManager = telemetryManager;
        this.glimpseBuffer = telemetryManager.trackingBuffer;
        this.qoeBuffer = telemetryManager.qoeBuffer;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @param {SDK.DiagnosticFeature} diagnosticFeature - Specifies the feature that will have its events validated.
     * @param {Boolean} [useProxy=false] - Proxy validation setting.
     * @desc Enables the validation of events for the specified {@link SDK.DiagnosticFeature}
     * @note Proxy of traffic through the normal DUST endpoint is to be prevented in publicly released applications.
     * @note To achieve this, applications should only pass `useProxy=true` in dev builds.
     *
     */
    public enable(
        diagnosticFeature: ValueOf<typeof DiagnosticFeature>,
        useProxy = false
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                diagnosticFeature: Types.in(DiagnosticFeature),
                useProxy: Types.boolean.optional
            };

            typecheck(this, 'enable', params, arguments);
        }

        const eventBuffer = this.getEventBuffer(diagnosticFeature);

        eventBuffer.enableValidation(useProxy);
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @param {SDK.DiagnosticFeature} diagnosticFeature - Specifies the feature that will have its events validated.
     * @desc Disables the validation of events for the specified DiagnosticFeature
     *
     */
    public disable(diagnosticFeature: ValueOf<typeof DiagnosticFeature>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                diagnosticFeature: Types.in(DiagnosticFeature)
            };

            typecheck(this, 'disable', params, arguments);
        }

        const eventBuffer = this.getEventBuffer(diagnosticFeature);

        eventBuffer.disableValidation();
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @param {SDK.DiagnosticFeature} diagnosticFeature - Specifies the feature that will have its events validated.
     * @desc Returns whether validation has been enabled for the specified DiagnosticFeature
     * @returns {Boolean}
     *
     */
    public isEnabled(diagnosticFeature: ValueOf<typeof DiagnosticFeature>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                diagnosticFeature: Types.in(DiagnosticFeature)
            };

            typecheck(this, 'isEnabled', params, arguments);
        }

        const eventBuffer = this.getEventBuffer(diagnosticFeature);

        return eventBuffer.isValidationEnabled();
    }

    /**
     *
     * @access private
     * @since 4.11.0
     * @desc Returns the correct EventBuffer for the specified DiagnosticFeature
     * @returns {SDK.Internal.Telemetry.EventBuffer}
     *
     */
    private getEventBuffer(
        diagnosticFeature: ValueOf<typeof DiagnosticFeature>
    ) {
        switch (diagnosticFeature) {
            case DiagnosticFeature.glimpseValidation:
                return this.glimpseBuffer;

            default:
                return this.qoeBuffer;
        }
    }

    /**
     *
     * @access private
     * @since 4.11.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.DiagnosticsApi';
    }
}
