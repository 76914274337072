/**
 *
 * @module eligibilityManagerConfiguration
 *
 */

import EligibilityClientConfiguration from '../eligibility/eligibilityClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for eligibility.
 *
 */
export default class EligibilityManagerConfiguration extends ServiceConfiguration<EligibilityClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.EligibilityManagerConfiguration';
    }

    // #endregion
}
