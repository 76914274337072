/**
 *
 * @module offDeviceTokenRefreshMessageProcessor
 *
 */

import LogTransaction from '../logging/logTransaction';
import MessageProcessorBase from './messageProcessorBase';
import SocketEvents from './socketEvents';
import type { RawSocketMessage } from './socketEvent';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import OffDeviceRedemptionFlow from '../services/orchestration/offDeviceRedemptionFlow';
import toUpperFirst from '../services/util/toUpperFirst';
import type SocketManager from './socketManager';
import type TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.15.0
 * @desc Handles the Off-device Token Refresh message
 *
 */
export default class OffDeviceTokenRefreshMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.15.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    public socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Token.TokenManager}
     *
     */
    public tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     * @param {Object} options
     * @param {SDK.Socket.SocketManager} options.socketManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        socketManager: SocketManager;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        const { socketManager, tokenManager, logger } = options;

        const messageType = SocketUrns.socketManager.offDeviceTokenRefresh;

        super(messageType);

        this.socketManager = socketManager;
        this.tokenManager = tokenManager;
        this.logger = logger;
    }

    /**
     *
     * @access protected
     * @since 4.15.0
     * @param {Object} messageData
     * @desc Processes the message to use the received `RefreshToken` to obtain a new `AccessToken`.
     * @note For Connected Device flows, actionGrant and offDeviceRedemptionFlow were added in 4.18.0
     * @note The EDGE service returns offDeviceRedemptionFlow as uppercase however the SDK spec and
     * user services expect a format where the first character is capitalized.
     *
     */
    public async process(messageData: RawSocketMessage) {
        const { tokenManager, logger } = this;
        const { refreshToken, actionGrant, offDeviceRedemptionFlow } =
            messageData.data as TodoAny;

        const offDeviceRedemptionFlowTransform = toUpperFirst(
            offDeviceRedemptionFlow
        );
        const offDeviceRedemptionFlowEnum =
            // @ts-ignore TODO
            OffDeviceRedemptionFlow[offDeviceRedemptionFlowTransform];

        await LogTransaction.wrapLogTransaction({
            urn: SocketUrns.socketManager.offDeviceTokenRefresh,
            file: this.toString(),
            logger,
            /**
             *
             * @param {SDK.Logging.LogTransaction} logTransaction
             *
             */
            action: async (logTransaction) => {
                try {
                    const deviceGrant =
                        await tokenManager.deviceManager.getDeviceGrant(
                            undefined,
                            logTransaction
                        );

                    await tokenManager.reset({ deviceGrant }, logTransaction);

                    const accessState = await tokenManager.getAccessState();

                    accessState.data.refreshToken = refreshToken;

                    await tokenManager.restoreAccessState(
                        JSON.stringify(accessState),
                        logTransaction
                    );

                    this.socketManager.emit(
                        SocketEvents.offDeviceTokenRefresh,
                        {
                            actionGrant,
                            offDeviceRedemptionFlow: offDeviceRedemptionFlowEnum
                        }
                    );
                } catch (exception) {
                    this.socketManager.emit(
                        SocketEvents.offDeviceTokenRefresh,
                        {
                            exception
                        }
                    );
                }
            }
        });
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.OffDeviceTokenRefreshMessageProcessor';
    }
}
