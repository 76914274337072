/**
 *
 * @module timeUnit
 * @see https://github.com/STRML/keyMirror
 *
 */

/**
 *
 * @enum
 * @since 3.9.0
 * @typedef {Object} SDK.Services.Paywall.TimeUnit
 * @desc Defines the time unit types for creating an IntroPrice object
 *
 * @property {String} day - Since `3.9.0`.
 * @property {String} week - Since `3.9.0`.
 * @property {String} month - Since `3.9.0`.
 * @property {String} year - Since `3.9.0`.
 *
 */
enum TimeUnit {
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year'
}
export default TimeUnit;
