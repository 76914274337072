/**
 *
 * @module orchestrationClientConfiguration
 *
 */

import ServiceClientConfiguration from './../configuration/serviceClientConfiguration';
import OrchestrationClientEndpoint from './orchestrationClientEndpoint';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the `OrchestrationClient`.
 *
 */
export default class OrchestrationClientConfiguration extends ServiceClientConfiguration<
    typeof OrchestrationClientEndpoint
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.OrchestrationClientConfiguration';
    }
}
