/**
 *
 * @module clientBase
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccessToken from './token/accessToken';
import LogTransaction from '../logging/logTransaction';

import OrchestrationClient from './orchestration/orchestrationClient';
import OrchestrationRequest from './orchestration/internal/orchestrationRequest';

/**
 *
 * @access protected
 * @desc FeatureServiceClient base.
 *
 */
export default class ClientBase<TEndpoints> {
    /**
     *
     * @access private
     * @type {OrchestrationClient}
     * @since 5.0.0
     * @desc The configuration information to use.
     *
     */
    public orchestrationClient: OrchestrationClient;

    /**
     *
     * @access private
     * @type {Object}
     * @since 5.0.0
     * @desc The endpoints.
     *
     */
    private endpoints: IEndpoints<TEndpoints>;

    /**
     *
     * @access private
     * @type {Object}
     * @since 5.0.0
     * @desc The clientEndpoint.
     *
     */
    private clientEndpoint: Record<string, string>;

    /**
     *
     * @access private
     * @type {Object}
     * @since 5.0.0
     * @desc The sub class of dust urn with keys to reference.
     *
     */
    private clientDustUrnReference: Record<string, string>;

    /**
     *
     * @param {Object} options
     * @param {OrchestrationClient} options.orchestrationClient
     * @param {Object} options.endpoints
     * @param {Object} options.clientEndpoint
     * @param {Object} options.clientDustUrnReference
     *
     */
    public constructor(options: {
        orchestrationClient: OrchestrationClient;
        endpoints: IEndpoints<TEndpoints>;
        clientEndpoint: Record<string, string>;
        clientDustUrnReference: Record<string, string>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    orchestrationClient:
                        Types.instanceStrict(OrchestrationClient),
                    endpoints: Types.object(),
                    clientEndpoint: Types.object(),
                    clientDustUrnReference: Types.object()
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            orchestrationClient,
            endpoints,
            clientEndpoint,
            clientDustUrnReference
        } = options;

        this.orchestrationClient = orchestrationClient;
        this.endpoints = endpoints;
        this.clientEndpoint = clientEndpoint;
        this.clientDustUrnReference = clientDustUrnReference;
    }

    /**
     *
     * @since 5.0.0
     * @access protected
     * @param {Object} options
     * @param {String} options.nameAndKey - The name/key of the operation used to lookup urn and endpoint configurations as well as graphql query data response
     * @param {Object} options.request
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @param {Function} [options.resultProcessor] - allows a custom result processing
     * @desc Allows generic handling of graphql requests.
     * @returns {Promise<Object>}
     *
     */
    public queryGraph(options: {
        nameAndKey: string;
        request: OrchestrationRequest;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
        resultProcessor?: (data: TodoAny) => void;
    }): Promise<Record<string, unknown>> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nameAndKey: Types.nonEmptyString,
                    request: Types.assigned,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction:
                        Types.instanceStrict(LogTransaction).optional
                })
            };

            typecheck(this, 'queryGraph', params, arguments);
        }

        const {
            nameAndKey,
            request,
            accessToken,
            resultProcessor,
            logTransaction
        } = options;

        return this.orchestrationClient
            .query({
                request,
                accessToken,
                logTransaction
            })
            .then((data: TodoAny) => {
                if (resultProcessor) {
                    return resultProcessor(data);
                }

                return data[nameAndKey];
            });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.ClientBase';
    }
}
