/**
 *
 * @module adEngineClientEndpoint
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Media.AdEngine.AdEngineClientEndpoint
 *
 * @property {String} setTokenPost - Since `11.0.0`.
 * @property {String} setTokenRedirectPost - Since `11.0.0`.
 *
 */
export default keyMirror({
    setTokenPost: null,
    setTokenRedirectPost: null
});
