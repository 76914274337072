/**
 *
 * @module tokenManagerConfiguration
 *
 */

import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing token.
 *
 */
export default class TokenManagerConfiguration extends ServiceConfiguration {
    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.TokenManagerConfiguration';
    }

    // #endregion
}
