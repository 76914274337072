/**
 *
 * @module configurationHostName
 * @see https://github.com/STRML/keyMirror
 *
 */

/**
 *
 * @access public
 * @enum
 * @typedef {Object} SDK.Services.Configuration.ConfigurationHostName
 * @desc Enum for the possible host name values in the ConfigurationClientConfiguration
 *
 * @property {String} dev
 * @property {String} prod
 * @property {String} staging - Since `4.10.0`.
 *
 */

enum ConfigurationHostName {
    dev = 'dev',
    prod = 'prod',
    staging = 'staging'
}

export default ConfigurationHostName;
