/**
 *
 * @module playbackSeekCause
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackSeekCause
 * @desc Defines the different types of playlists.
 *
 * @property {String} app - Since `4.0.0` - When the player jumps to live or the application decides the target playhead.
 * @property {String} seek - Since `4.0.0` - To be used when the user seeks to a specific point by tapping on the
 * timeline or dragging the playhead to a new position on the timeline.
 * @property {String} scrub - Since `4.0.0` - When a new position is reached via linear reverse or forward playback at a non-1x rate.
 * @property {String} skip - Since `4.0.0` - When the user skips to a milestone (intro skip, recap end).
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackSeekCause.app // 'app'
 *
 */
export default keyMirror({
    app: null,
    seek: null,
    scrub: null,
    skip: null
});
