/**
 *
 * @module entry
 * @desc {SDK.Commerce.Comcast} entry point.
 *
 */

import ComcastApi from './comcastApi';

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast
 *
 * @property {ComcastApi} ComcastApi
 *
 */
export default {
    ComcastApi
};
