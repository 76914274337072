/**
 *
 * @module advertisingIdProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1676/
 * @see https://wiki.bamtechmedia.com/pages/viewpage.action?pageId=61506803
 * @see https://docs.google.com/spreadsheets/d/1KNynrJ3Lrzk8FIxQtE3D3s2GHeydwQInDH60YOjhX_0/edit#gid=0
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';
import LimitAdTrackingEnabled from './limitAdTrackingEnabled';

/**
 *
 * @access public
 * @since 18.0.0
 * @desc Provider used to supply advertising data.
 * @note A different (AdEngine specific) version was originally introduced in version `3.6.0`.
 *
 */
export default class AdvertisingIdProvider {
    /**
     *
     * @access private
     * @since 18.0.0
     * @type {String}
     * @desc Hardcoded value, because advertising id cannot be determined.
     *
     */
    private advertisingId: string;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Logging.Logger} [logger]
     *
     */
    public constructor(logger?: Logger) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger).optional
            };

            typecheck(this, params, arguments);
        }

        this.logger = logger || Logger.instance;

        this.advertisingId = '00000000-0000-0000-0000-000000000000';

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @desc Tracking identifier used by publishers and advertisers to
     * identify a device for the purposes of ad targeting and measurement.
     * @note Not all platforms will have the ability to access a System API to retrieve this value.
     * @note If the advertising id cannot be determined use the default value of "00000000-0000-0000-0000-000000000000".
     * @note Also known as advertising id, IDFA (iOS), RIDA (Roku), AAID, etc...
     * @returns {String}
     *
     */
    public getId() {
        this.logger.info(
            this.toString(),
            `Advertising id cannot be determined, sending: ${this.advertisingId}.`
        );

        return this.advertisingId;
    }

    /**
     *
     * @access public
     * @desc Returns a `SDK.Advertising.LimitAdTrackingEnabled` enum value.
     * @note If the election is possible but the client isn’t sending it, either because it’s
     * not yet wired up or there is an error, we expect `LimitAdTrackingEnabled.ERROR`.
     * @note These elections are typically made in a device's settings and apply to all apps on that device.
     * @note Limit Ad Tracking (LAT) is a standard offering in modern mobile, web and CTV applications,
     * allowing users to opt-out of ad tracking. iOS devices moved away from LAT
     * to ATT (App Tracking Transparency) with the release of iOS 14.
     * @returns {SDK.Advertising.LimitAdTrackingEnabled}
     *
     */
    public getLimitAdTrackingEnabled() {
        this.logger.info(
            this.toString(),
            `LimitAdTrackingEnabled: ${LimitAdTrackingEnabled.ERROR}.`
        );

        return LimitAdTrackingEnabled.ERROR;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Advertising.AdvertisingIdProvider';
    }

    // #endregion
}
