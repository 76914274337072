/**
 *
 * @module playbackSelectionTracking
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.18.0
 *
 */
export default class PlaybackSelectionTracking {
    /**
     *
     * @param {String} [playbackSessionId]
     *
     */
    constructor(playbackSessionId) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackSessionId: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {String|undefined}
         *
         */
        this.playbackSessionId = playbackSessionId;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackSelectionTracking';
    }

    // #endregion
}
