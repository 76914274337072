/**
 *
 * @module imageFormat
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.ImageFormat
 * @desc File format of the desired variant image. The PVR and jpeg2000 formats only when the library parameter is present on request.
 *
 * @property {String} jpeg - Since `17.0.0`
 * @property {String} png - Since `17.0.0`
 * @property {String} jpeg2000 - Since `17.0.0`
 * @property {String} pvr - Since `17.0.0`
 *
 * @example
 * SDK.Ripcut.ImageFormat.jpeg // 'jpeg'
 *
 */
export default keymirror({
    jpeg: null,
    png: null,
    jpeg2000: null,
    pvr: null
});
