/**
 *
 * @module jsonStorageMiddlewareStore
 *
 */

import { Check } from '@dss/type-checking';
import { IStore } from './IStore'; // eslint-disable-line no-unused-vars
import ProviderException from '../../exception/providerException';

/**
 *
 * @access protected
 * @since 17.0.0
 *
 */
export default class JsonStorageMiddlewareStore implements IStore {
    /**
     *
     * @access private
     * @since 17.0.0
     * @type {IStore}
     * @desc local store instance to wrap with JSON handling
     *
     */
    private store: IStore;

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Storage} localStorage
     *
     */
    public constructor(store: IStore) {
        this.store = store;
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} key
     * @param {*} value
     * @returns {Promise<Void>}
     *
     */
    public async setItem(key: string, value: TodoAny) {
        try {
            const stringifiedValue = JSON.stringify(value);

            await this.store.setItem(key, stringifiedValue);
        } catch (error) {
            const errorMessage = `${this.toString()}.setItem(key, value) cannot complete successfully - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} key
     * @returns {Promise<*>}
     *
     */
    public async getItem(key: string) {
        try {
            let parsedValue;

            const value = await this.store.getItem(key);

            // @ts-ignore
            if (Check.nonEmptyString(value)) {
                // @ts-ignore
                parsedValue = JSON.parse(value);
            }

            return parsedValue;
        } catch (error) {
            const errorMessage = `${this.toString()}.getItem(key) unable to parse value - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} key
     * @returns {Promise<Void>}
     *
     */
    public async removeItem(key: string) {
        try {
            await this.store.removeItem(key);
        } catch (error) {
            const errorMessage = `${this.toString()}.removeItem(key) cannot complete successfully - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Store.JsonStorageMiddlewareStore';
    }
}
