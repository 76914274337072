/**
 *
 * @module userActivityAction
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 3.4.0
 * @typedef {Object} SDK.UserActivity.Personalization.UserActivityAction
 *
 * @property {String} CLICK
 *
 */
export default keyMirror({
    CLICK: null
});
