/**
 *
 * @module registerDeviceRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';

import DeviceAttributes from '../../../device/deviceAttributes';
import DeviceMetadata from '../../../device/deviceMetadata';

//
// @note: DeviceMetadata contains attributes: DeviceAttributes
// omitting attributes from DeviceMetadata here and checking
// a slimmed-down DeviceAttributes type instead
//
export type RegisterDeviceInput = Omit<DeviceMetadata, 'attributes'> & {
    deviceLanguage: string | null;
    attributes: Omit<
        DeviceAttributes,
        'deviceLanguage' | 'modelFamily' | 'passwordResetRequired'
    >;
    huluUserToken?: string;
};

/**
 *
 * @since 9.0.0
 *
 */
export default class RegisterDeviceRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: RegisterDeviceInput) {
        const query = RegisterDeviceRequest.query;

        const variables = {
            input
        };

        super({ query, variables, operationName: 'registerDevice' });
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/orchestration_graphql/query/registerDevice.md
     * @returns {String}
     *
     */
    public static get query() {
        return `mutation registerDevice($input: RegisterDeviceInput!) {
            registerDevice(registerDevice: $input) {
                grant {
                    grantType
                    assertion
                }
            }
        }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.RegisterDeviceRequest';
    }
}
