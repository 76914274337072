/**
 *
 * @module purchaseApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/purchase.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/PurchaseApi.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/external-activation.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/eligibility.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import EligibilityManager from '../eligibility/eligibilityManager';
import SubscriptionManager from '../subscription/subscriptionManager';
import ExternalActivationManager from '../externalActivation/externalActivationManager';
import PurchaseManager from './purchaseManager';
import RedeemRetryHandler from './redeemRetryHandler';
import Receipt from './receipt';
import BaseApi from '../baseApi';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import Logger from '../logging/logger';
import AccessStatus from './accessStatus';
import ExternalActivationResult from '../services/externalActivation/externalActivationResult';
import ActivationToken from '../services/externalActivation/activationToken';
import EligibilityStatusResponse from '../services/eligibility/eligibilityStatusResponse';

const DustUrn = DustUrnReference.purchase.purchaseApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides a way to redeem and restore purchases.
 *
 */
export default class PurchaseApi extends BaseApi {
    /**
     *
     * @access private
     * @type {PurchaseManager}
     *
     */
    private purchaseManager: PurchaseManager;

    /**
     *
     * @access private
     * @type {SDK.Subscription.SubscriptionManager}
     *
     */
    private subscriptionManager: SubscriptionManager;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.ExternalActivation.ExternalActivationManager}
     *
     */
    private externalActivationManager: ExternalActivationManager;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Eligibility.EligibilityManager}
     *
     */
    private eligibilityManager: EligibilityManager;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Purchase.PurchaseManager} options.purchaseManager
     * @param {SDK.Subscription.SubscriptionManager} options.subscriptionManager
     * @param {SDK.ExternalActivation.ExternalActivationManager} options.externalActivationManager
     * @param {SDK.Eligibility.EligibilityManager} options.eligibilityManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        purchaseManager: PurchaseManager;
        subscriptionManager: SubscriptionManager;
        externalActivationManager: ExternalActivationManager;
        eligibilityManager: EligibilityManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    purchaseManager: Types.instanceStrict(PurchaseManager),
                    subscriptionManager:
                        Types.instanceStrict(SubscriptionManager),
                    externalActivationManager: Types.instanceStrict(
                        ExternalActivationManager
                    ),
                    eligibilityManager: Types.instanceStrict(EligibilityManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            purchaseManager,
            subscriptionManager,
            externalActivationManager,
            eligibilityManager
        } = options;

        this.purchaseManager = purchaseManager;
        this.subscriptionManager = subscriptionManager;
        this.externalActivationManager = externalActivationManager;
        this.eligibilityManager = eligibilityManager;
        this.dustEnabled = getSafe(
            () => this.purchaseManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Receipt} receipt - The Receipt for the purchase being redeemed.
     * @desc Redeem new purchases with the activation service and apply them to the access context.
     * @throws {ActivationFailedException} The service failed to activate the receipt.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<AccessStatus>} Returns an asynchronous action that
     * will send a notification when it is complete.
     *
     */
    public redeem(receipt: Receipt): Promise<AccessStatus>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            receipt: Types.instanceStrict(Receipt)
        }
    })
    public redeem(apiOptions: unknown): Promise<AccessStatus> {
        const {
            logTransaction,
            args: [receipt]
        } = apiOptions as ApiOptions;

        const handler = new RedeemRetryHandler(this.purchaseManager, receipt);

        return handler.run(logTransaction);
    }

    /**
     *
     * @access public
     * @param {Receipt} receipt - The Receipt for the purchase being restored.
     * @desc Restore existing purchases by validating them with the activation service and applying them to the access context.
     * @throws {ActivationFailedException} The service failed to activate the receipt.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<AccessStatus>} Returns an asynchronous action that will send a notification when it is complete.
     *
     */
    public restore(receipt: Receipt): Promise<AccessStatus>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            receipt: Types.instanceStrict(Receipt)
        }
    })
    public restore(apiOptions: unknown): Promise<AccessStatus> {
        const {
            logTransaction,
            args: [receipt]
        } = apiOptions as ApiOptions;

        return this.purchaseManager.restorePurchases(
            receipt.receiptCredentials,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} providerId - The provider the activation token was obtained from ('hulu' when activating a Hulu bundle).
     * @param {String} activationTokenString - The activation token as a String.
     * @desc Creates a new link between account in header and account in token body. Checks if any subscription exists, then
     * new account is linked to that subscription as well. Can only be called for bundle linking.
     * @note Send as `linkToken` in the JSON payload.
     * @note Calls the `ExternalActivationManager.redeemBundle()` function.
     * @throws {ActivationTokenExpiredException} The service failed to activate due to the activation token being expired.
     * @throws {ActivationBadRequestException} The service failed to activate due to a bad request.
     * @throws {ActivationForbiddenException} The service failed to activate due to linking being forbidden.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the `ExternalActivationResult`
     * which can be inspected by the application developer to verify `temporaryAccessGranted` & `resultStatus`.
     *
     * @example <caption>Checking the external activation result to verify activation status</caption>
     *
     * const ExternalActivation = SDK.Services.ExternalActivation;
     * const TemporaryDefaultAccessActivationStatus = ExternalActivation.TemporaryDefaultAccessActivationStatus;
     * const externalActivationResult = await purchaseApi.redeemBundle(providerId, activationTokenString);
     * const resultStatus = externalActivationResult.resultStatus;
     *
     * if (resultStatus === TemporaryDefaultAccessActivationStatus.activationSucceeded) {
     *     console.log('Activation Succeeded.');
     * }
     *
     */
    public async redeemBundle(
        providerId: string,
        activationTokenString: string
    ): Promise<ExternalActivationResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            providerId: Types.nonEmptyString,
            activationTokenString: Types.nonEmptyString
        }
    })
    public async redeemBundle(
        apiOptions: unknown
    ): Promise<ExternalActivationResult> {
        const {
            logTransaction,
            dustLogUtility,
            args: [providerId, activationTokenString]
        } = apiOptions as ApiOptions;

        this.logger.info(
            this.toString(),
            `Redeem bundle for provider: ${providerId}`
        );

        const externalActivationResult =
            await this.externalActivationManager.redeemBundle(
                providerId,
                activationTokenString,
                logTransaction
            );

        const temporaryAccessGranted =
            externalActivationResult.temporaryAccessGranted;
        const resultStatus = externalActivationResult.resultStatus;

        if (temporaryAccessGranted) {
            this.logger.info(
                this.toString(),
                'Temporary access has been granted.'
            );
        }

        if (resultStatus) {
            this.logger.info(
                this.toString(),
                `Temporary access, result status: ${resultStatus}`
            );
        }

        dustLogUtility.logData({
            result: externalActivationResult
        });

        return externalActivationResult;
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {String} providerId - The provider the activation token was obtained from.
     * @param {String} activationTokenString - The activation token as a String.
     * @desc Verifies the purchase contained within the token with the third-party service identified by `providerId`, and grants subscription + entitlements.
     * @note Send as `linkToken` in the JSON payload.
     * @note Calls the `ExternalActivationManager.redeemToken()` function.
     * @throws {ActivationTokenExpiredException} The service failed to activate due to the activation token being expired.
     * @throws {ActivationBadRequestException} The service failed to activate due to a bad request.
     * @throws {ActivationForbiddenException} The service failed to activate due to linking being forbidden.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the `ExternalActivationResult`
     * which can be inspected by the application developer to verify `temporaryAccessGranted` & `resultStatus`.
     *
     * @example <caption>Checking the external activation result to verify activation status</caption>
     *
     * const ExternalActivation = SDK.Services.ExternalActivation;
     * const TemporaryDefaultAccessActivationStatus = ExternalActivation.TemporaryDefaultAccessActivationStatus;
     * const externalActivationResult = await purchaseApi.redeemToken(providerId, activationTokenString);
     * const resultStatus = externalActivationResult.resultStatus;
     *
     * if (resultStatus === TemporaryDefaultAccessActivationStatus.activationSucceeded) {
     *     console.log('Activation Succeeded.');
     * }
     *
     */
    public async redeemToken(
        providerId: string,
        activationTokenString: string
    ): Promise<ExternalActivationResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            providerId: Types.nonEmptyString,
            activationTokenString: Types.nonEmptyString
        }
    })
    public async redeemToken(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [providerId, activationTokenString]
        } = apiOptions as ApiOptions;

        this.logger.info(
            this.toString(),
            `Redeem token for provider: ${providerId}`
        );

        const externalActivationResult =
            await this.externalActivationManager.redeemToken(
                providerId,
                activationTokenString,
                logTransaction
            );

        const temporaryAccessGranted =
            externalActivationResult.temporaryAccessGranted;
        const resultStatus = externalActivationResult.resultStatus;

        if (temporaryAccessGranted) {
            this.logger.info(
                this.toString(),
                'Temporary access has been granted.'
            );
        }

        if (resultStatus) {
            this.logger.info(
                this.toString(),
                `Temporary access, result status: ${resultStatus}`
            );
        }

        dustLogUtility.logData({
            result: externalActivationResult
        });

        return externalActivationResult;
    }

    /**
     *
     * @access public
     * @param {String} providerId - The provider the token will be used to activate with ('hulu' when activating a Hulu bundle).
     * @desc Creates a JWT link token based on user's bundle purchase. Authorized third
     * party should be able to validate this token and grant user access. Can only be called from D+ partner.
     * @note Calls the `ExternalActivationManager.createExternalRedemptionToken()` function.
     * @note Currently very Hulu specific (the endpoint is templated, however only the Hulu provider is supported).
     * @note This method will be deprecated post unification.
     * @note Uses the legacy service. Will be replaced by the generateActivationToken flow.
     * @throws {BundleForbiddenException} The service failed to return the ActivationToken for the bundle purchase.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} A promise that returns information about the link token when it succeeds.
     *
     */
    public async createExternalRedemptionToken(
        providerId: string
    ): Promise<ActivationToken>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            providerId: Types.nonEmptyString
        }
    })
    public async createExternalRedemptionToken(
        apiOptions: unknown
    ): Promise<ActivationToken> {
        const {
            logTransaction,
            args: [providerId]
        } = apiOptions as ApiOptions;

        this.logger.info(
            this.toString(),
            `Create external redemption token for provider: ${providerId}`
        );

        return await this.externalActivationManager.createExternalRedemptionToken(
            providerId,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 14.1.0
     * @param {String} contentProvider - Content Provider of the entitlement -- who will provide the user with the content they purchased.
     * @desc Generate a JWT activation token based on the user's entitlements. Authorized third party should be able to
     * validate this token and grant the user access to content.
     * @throws {BundleForbiddenException}
     * @throws {ProviderNotEnabledException}
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} Returns an object containing the activation token.
     *
     */
    public async generateActivationToken(
        contentProvider: string
    ): Promise<ActivationToken>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            contentProvider: Types.nonEmptyString
        }
    })
    public async generateActivationToken(
        apiOptions: unknown
    ): Promise<ActivationToken> {
        const {
            logTransaction,
            args: [contentProvider]
        } = apiOptions as ApiOptions;

        this.logger.info(
            this.toString(),
            `Generate ActivationToken for content provider: ${contentProvider}`
        );

        return await this.externalActivationManager.generateActivationToken(
            contentProvider,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} [sku]
     * @desc Request eligibility status for a given product.
     * @throws {AccountResolutionException} The account could not be resolved.
     * @throws {HuluServiceException} There was a general Hulu error while processing the request.
     * @throws {InvalidRequestException} Response returned in case of invalid request.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Eligibility.EligibilityStatusResponse>} Returns an asynchronous action that
     * will send a notification when it is complete.
     *
     */
    public async getEligibilityStatus(
        sku?: string
    ): Promise<EligibilityStatusResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            sku: Types.nonEmptyString.optional
        }
    })
    public async getEligibilityStatus(
        apiOptions: unknown
    ): Promise<EligibilityStatusResponse> {
        const {
            logTransaction,
            args: [sku]
        } = apiOptions as ApiOptions;

        return await this.eligibilityManager.getEligibilityStatus(
            sku,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Purchase.PurchaseApi';
    }
}
