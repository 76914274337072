/**
 *
 * @module ripcutApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/ripcut.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import Logger from '../logging/logger';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';

import RipcutManager from './ripcutManager';

import { RipcutOptions } from './typedefs';

const DustUrn = DustUrnReference.ripcut.ripcutApi;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface RipcutApiOptions {
    logger: Logger;
    ripcutManager: RipcutManager;
}

/**
 *
 * @access public
 * @since 17.0.0
 * @desc Provides an object for retrieving images from Ripcut.
 *
 */
export default class RipcutApi extends BaseApi {
    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Ripcut.RipcutManager}
     *
     */
    private ripcutManager: RipcutManager;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {Boolean}
     * @desc Used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Ripcut.RipcutManager} options.ripcutManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: RipcutApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    ripcutManager: Types.instanceStrict(RipcutManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { ripcutManager } = options;

        this.ripcutManager = ripcutManager;
        this.dustEnabled = getSafe(() => this.ripcutManager.client.dustEnabled);

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Object} options
     * @param {String} options.imageId - The Id of the requested image.
     * @param {String<RipcutImageRequestMode>} options.imageRequestMode - Specifies image retrieval option such as
     * compositing or scaling logic to be applied by Ripcut to the returned image.
     * @param {Object<RipcutQueryParams>} [options.queryParams] - Parameters specifying image details such as
     * scaling/formatting/label placement, attached as query params in the request.
     * @desc Returns the result/image from the request to Ripcut and any error also returned.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Ripcut.RipcutResult>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImage(
        options: SDK.Ripcut.RipcutOptionsOptions
    ): Promise<SDK.Services.Ripcut.RipcutResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object(RipcutOptions)
        }
    })
    public async getImage(
        apiOptions: unknown
    ): Promise<SDK.Services.Ripcut.RipcutResult> {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions;

        return await this.ripcutManager.getImage(options, logTransaction);
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Object} options
     * @param {String} options.imageId - The Id of the requested image.
     * @param {String<RipcutImageRequestMode>} options.imageRequestMode - Specifies image retrieval option such as
     * compositing or scaling logic to be applied by Ripcut to the returned image.
     * @param {Object<RipcutQueryParams>} [options.queryParams] - Parameters specifying image details such as
     * scaling/formatting/label placement, attached as query params in the request.
     * @desc Returns an image url string.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImageUrl(
        options: SDK.Ripcut.RipcutOptionsOptions
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object(RipcutOptions)
        }
    })
    public async getImageUrl(apiOptions: unknown): Promise<string> {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions;

        return await this.ripcutManager.getImageUrl(options, logTransaction);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Ripcut.RipcutApi';
    }
}
