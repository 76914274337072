/**
 *
 * @module accountManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/account.md
 *
 */
import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import TokenManager from '../token/tokenManager';
import AccountClient from '../services/account/accountClient';

import OnboardAccountToStarRequest from '../services/account/onboardAccountToStarRequest';
import OnboardProfileToStarRequest from '../services/account/onboardProfileToStarRequest';
import UpdateProtectProfileCreationWithActionGrantRequest from '../services/account/updateProtectProfileCreationWithActionGrantRequest';
import AccountManagerConfiguration from '../services/configuration/accountManagerConfiguration';
import LogTransaction from '../logging/logTransaction';
import CreateAccountResult from '../services/account/createAccountResult';
import AccountGrant from '../services/account/accountGrant';
import Account from '../services/account/account';
import AccessToken from '../token/accessToken';

interface AccountManagerOptions {
    config: AccountManagerConfiguration;
    client: AccountClient;
    logger: Logger;
    tokenManager: TokenManager;
}

/**
 *
 * @access protected
 * @desc Manages the current account and serves as a go between for the public APIs and the AccountClient.
 *
 */
export default class AccountManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.AccountManagerConfiguration}
     *
     */
    private config: AccountManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Account.AccountClient}
     *
     */
    public client: AccountClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.AccountManagerConfiguration} options.config
     * @param {SDK.Services.Account.AccountClient} options.client
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.TokenManager} options.tokenManager
     *
     */
    public constructor(options: AccountManagerOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(AccountManagerConfiguration),
                    client: Types.instanceStrict(AccountClient),
                    logger: Types.instanceStrict(Logger),
                    tokenManager: Types.instanceStrict(TokenManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, logger, tokenManager } = options;

        this.config = config;
        this.client = client;
        this.logger = logger;
        this.tokenManager = tokenManager;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.identityToken - An identity token representing the validated identity.
     * @param {Object} options.attributes - A set of attributes to associate with the new account. Note: certain
     * attributes may be required while others are optional.
     * @param {Object} [options.metadata] - Contains additional info on the account being created like `isTest`
     * to indicate if the account being made is for testing purposes and should have a temporary lifespan
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Creates a new account and associates with the supplied identity.
     * @returns {Promise<SDK.Services.Account.CreateAccountResult>}
     *
     * @todo Remove as part of https://jira.disneystreaming.com/browse/SDKMRJS-4038
     * see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1522 for reference
     *
     */
    public async createAccount(options: {
        identityToken: string;
        attributes: object;
        metadata?: object;
        logTransaction: LogTransaction;
    }): Promise<CreateAccountResult> {
        const { accessToken } = this;

        return await this.client.createAccount({
            ...options,
            accessToken
        });
    }

    /**
     *
     * @access private
     * @param {String} identityToken - An identity token representing the validated identity.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets an account grant for the identified user.
     * @returns {Promise<SDK.Services.Account.AccountGrant>}
     *
     * @todo Remove as part of https://jira.disneystreaming.com/browse/SDKMRJS-4038
     * see https://github.bamtech.co/sdk-doc/spec-sdk/pull/1522 for reference
     *
     */
    public async createAccountGrant(
        identityToken: string,
        logTransaction: LogTransaction
    ): Promise<AccountGrant> {
        return await this.client.createAccountGrant(
            identityToken,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @since 3.1.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets the current account.
     * @note Attributes are not cached in the SDK, each time the app developer accesses `Account.attributes`
     * it will retrieve them from the service.
     * @returns {Promise<SDK.Services.Account.Account>} An account object containing the account ID and a dictionary
     * of account attributes.
     *
     */
    public async getAccount(logTransaction: LogTransaction): Promise<Account> {
        const { account, refreshAccessToken: forceRefresh } =
            await this.client.getCurrentAccount(
                this.accessToken,
                logTransaction
            );

        if (forceRefresh) {
            await this.tokenManager.refreshAccessToken({
                forceRefresh,
                logTransaction
            });
        }

        return account;
    }

    /**
     *
     * @access private
     * @param {Object} attributes - The attributes to change.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Updates the supplied attributes. Omitted attributes are not updated.
     * @returns {Promise<Void>}
     *
     */
    public async updateAttributes(
        attributes: object,
        logTransaction: LogTransaction
    ): Promise<void> {
        return await this.client.updateAttributes(
            attributes,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc  Onboards the active profile to Star.
     * @returns {Promise<Object>} A promise that returns an Object when the operation has succeeded.
     * has succeeded.
     *
     */
    public async onboardProfileToStar(
        logTransaction: LogTransaction
    ): Promise<object> {
        const request = new OnboardProfileToStarRequest();

        return await this.client.onboardProfileToStar(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Onboards an account to Star.
     * @returns {Promise<Object>} A promise that returns an Object when the operation has succeeded.
     * has succeeded.
     *
     */
    public async onboardAccountToStar(
        logTransaction: LogTransaction
    ): Promise<object> {
        const request = new OnboardAccountToStarRequest();

        return await this.client.onboardAccountToStar(
            request,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {Object} input
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Updates the isProfileCreationProtected value for an account.
     * @returns {Promise<Object>} A promise that completes when the operation has succeeded.
     *
     */
    public async updateProtectProfileCreationWithActionGrant(
        input: object,
        logTransaction: LogTransaction
    ): Promise<object> {
        const request = new UpdateProtectProfileCreationWithActionGrantRequest(
            input
        );

        return await this.client.updateProtectProfileCreationWithActionGrant(
            request,
            this.accessToken,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the TokenManager instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken(): AccessToken {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Account.AccountManager';
    }

    // #endregion
}
