/**
 *
 * @module entry
 * @desc {SDK.Content.Watchlist} entry point.
 *
 */

import AddToWatchlistQuery from './addToWatchlistQuery';
import ClearWatchlistQuery from './clearWatchlistQuery';
import RemoveFromWatchlistQuery from './removeFromWatchlistQuery';
import WatchlistQuery from './watchlistQuery';

/**
 *
 * @typedef {Object} SDK.Content.Watchlist
 *
 * @property {SDK.Content.Watchlist.AddToWatchlistQuery} AddToWatchlistQuery
 * @property {SDK.Content.Watchlist.ClearWatchlistQuery} ClearWatchlistQuery
 * @property {SDK.Content.Watchlist.RemoveFromWatchlistQuery} RemoveFromWatchlistQuery
 * @property {SDK.Content.Watchlist.WatchlistQuery} WatchlistQuery
 *
 */
export default {
    AddToWatchlistQuery,
    ClearWatchlistQuery,
    RemoveFromWatchlistQuery,
    WatchlistQuery
};
