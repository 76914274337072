/**
 *
 * @module encryptionMode
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.EncryptionMode
 * @desc An enumeration of DRM encryption types. Used to differentiate cbcs, ctr or silk in base device playback name.
 *
 * @property {String} cbcs - Since `4.18.0` - String used in base playback scenario.
 * @property {String} ctr - Since `4.18.0` - String used in base playback scenario.
 * @property {String} silk - Since `14.0.0` - String used in base playback scenario to indicate that the device requires silk playlists.
 *
 * @example
 * EncryptionMode.cbcs // 'cbcs'
 *
 */
export default keyMirror({
    /**
     *
     * @note CBCS (short for Cipher Block Chaining Sample) is the common encryption mode for HLS / Apple based devices.
     *
     */
    cbcs: null,
    /**
     *
     * @note CTR (short for counter) is the common encryption mode for Android and Browser Based devices.
     *
     */
    ctr: null,
    silk: null
});
