/**
 *
 * @module flexApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/flex.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/growth-life-client-api.yaml
 * @see https://wiki.disneystreaming.com/pages/viewpage.action?pageId=444577632
 * @see https://wiki.disneystreaming.com/display/DEVOPS/FLEX+API
 * @see https://www.typescriptlang.org/cheatsheets
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from './../baseApi';
import FlexManager from './flexManager';
import DustUrnReference from './../services/internal/dust/dustUrnReference';
import DustDecorators from './../services/internal/dust/dustDecorators';
import Logger from './../logging/logger';
import getSafe from './../services/util/getSafe';

import { FlexOptions } from './typedefs';

const DustUrn = DustUrnReference.flex.flexApi;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 16.0.0
 * @desc Provides an object for all GrowthLife related flows.
 * @note FLEX is the gateway layer we interact with, for accessing the overall Unified GrowthLife ecosystem.
 * @note Flex hits the SPLAT service which is an orchestration like layer that interacts with different services and obtains
 * all the necessary data. Once all the data is gathered, Flex determines how the UI should be painted.
 *
 */
export default class FlexApi extends BaseApi {
    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Flex.FlexManager}
     *
     */
    private flexManager: FlexManager;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Flex.FlexManager} options.flexManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: { logger: Logger; flexManager: FlexManager }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    flexManager: Types.instanceStrict(FlexManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { flexManager } = options;

        this.flexManager = flexManager;
        this.dustEnabled = getSafe(() => this.flexManager.client.dustEnabled);

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {Object<SDK.Flex.FlexOptions>} options - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {String<SDK.Services.Flex.FlexClientEndpoint>} options.key - Used to determine the endpoint to hit. EX: "billingHistory", "subscriptionSummary", "cancelFlow"
     * @param {String} options.version - Version provided by app dev (app dev determines which version they want/support). EX: "v1"
     * @param {Object} [options.flexParams] - Flex parameters to be applied as query params (GET) or (POST, PUT) data to be sent over.
     * @param {Function} [options.configOverride] - Optional override function. Right before the SDK makes a Flex api call we grab the config (if found, "default" for #getScreen, "execution" for #execute if not found) based on the key. If this function is defined, allows app devs to manipulate the configuration by adding to the "default", or "execution" href value, changing the HTTP method, etc...
     * @desc Returns the template for a given page.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Flex.Screen>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getScreen(
        options: SDK.Flex.FlexOptionsOptions
    ): Promise<SDK.Services.Flex.Screen>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object(FlexOptions)
        }
    })
    public async getScreen(
        apiOptions?: unknown
    ): Promise<SDK.Services.Flex.Screen> {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions;

        return await this.flexManager.getScreen(options, logTransaction);
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Object<SDK.Flex.FlexOptions>} options - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {String<SDK.Services.Flex.FlexClientEndpoint>} options.key - Used to determine the endpoint to hit. EX: "subscriptionRestart", "subscriptionCancel", "subscriptionSwitch"
     * @param {String} options.version - Version provided by app dev (app dev determines which version they want/support). EX: "v1"
     * @param {Object} [options.flexParams] - Flex parameters to be applied as query params (GET) or (POST, PUT) data to be sent over.
     * @param {Function} [options.configOverride] - Optional override function. Right before the SDK makes a Flex api call we grab the config (if found, "default" for #getScreen, "execution" for #execute if not found) based on the key. If this function is defined, allows app devs to manipulate the configuration by adding to the "default", or "execution" href value, changing the HTTP method, etc...
     * @desc A generic execute for interaction with Flex endpoints.
     * @note Gives application developers the ability to hit Flex endpoints and get some kind of data back.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Flex.ExecutionResponse>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async execute(
        options: SDK.Flex.FlexOptionsOptions
    ): Promise<SDK.Services.Flex.ExecutionResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object(FlexOptions)
        }
    })
    public async execute(
        apiOptions: unknown
    ): Promise<SDK.Services.Flex.ExecutionResponse> {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions;

        return await this.flexManager.execute(options, logTransaction);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Flex.FlexApi';
    }
}
