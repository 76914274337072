/**
 *
 * @module getAccountResult
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Account from './account';

/**
 *
 * @desc Describes the result of an attempt to retrieve an Account.
 *
 */
export default class GetAccountResult {
    /**
     *
     * @access public
     * @since 4.3.0
     * @type {Boolean}
     * @desc Gets an indicator that the access token should be refreshed before proceeding.
     *
     */
    public refreshAccessToken: boolean;

    /**
     *
     * @access public
     * @since 4.3.0
     * @type {SDK.Services.Account.Account}
     *
     */
    public account: Account;

    /**
     *
     * @param {Boolean} refreshAccessToken
     * @param {SDK.Services.Account.Account} account
     *
     */
    public constructor(refreshAccessToken: boolean, account: Account) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                refreshAccessToken: Types.boolean,
                account: Types.instanceStrict(Account)
            };

            typecheck(this, params, arguments);
        }

        this.refreshAccessToken = refreshAccessToken;
        this.account = account;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Account.GetAccountResult';
    }
}
