/**
 *
 * @module playbackVariant
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.0.0
 *
 */
export default class PlaybackVariant {
    /**
     *
     * @param {Object} [options={}]
     * @param {Number|null} [options.bandwidth=null]
     * @param {String|null} [options.resolution=null]
     * @param {Number|null} [options.videoBytes=null]
     * @param {Number|null} [options.maxAudioRenditionBytes=null]
     * @param {Number|null} [options.maxSubtitleRenditionBytes=null]
     * @param {Number|null} [options.audioChannels=null]
     * @param {String|null} [options.videoRange=null]
     * @param {String|null} [options.videoCodec=null]
     * @param {String|null} [options.audioType=null]
     * @param {String|null} [options.audioCodec=null]
     * @param {Number|null} [options.averageBandwidth=null]
     * @param {Number|null} [options.frameRate=null]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bandwidth: Types.number.optional,
                    resolution: Types.nonEmptyString.optional,
                    videoBytes: Types.number.optional,
                    maxAudioRenditionBytes: Types.number.optional,
                    maxSubtitleRenditionBytes: Types.number.optional,
                    audioChannels: Types.number.optional,
                    videoRange: Types.nonEmptyString.optional,
                    videoCodec: Types.nonEmptyString.optional,
                    audioType: Types.nonEmptyString.optional,
                    audioCodec: Types.nonEmptyString.optional,
                    averageBandwidth: Types.number.optional,
                    frameRate: Types.number.optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            bandwidth,
            resolution,
            videoBytes,
            maxAudioRenditionBytes,
            maxSubtitleRenditionBytes,
            audioChannels,
            videoRange,
            videoCodec,
            audioType,
            audioCodec,
            averageBandwidth,
            frameRate
        } = options || {};

        /**
         *
         * @access public
         * @type {Number|null}
         *
         */
        this.bandwidth = Check.assigned(bandwidth) ? bandwidth : null;

        /**
         *
         * @access public
         * @type {String|null}
         *
         */
        this.resolution = resolution || null;

        /**
         *
         * @access public
         * @type {Number|null}
         *
         */
        this.videoBytes = Check.assigned(videoBytes) ? videoBytes : null;

        /**
         *
         * @access public
         * @type {Number|null}
         *
         */
        this.maxAudioRenditionBytes = Check.assigned(maxAudioRenditionBytes)
            ? maxAudioRenditionBytes
            : null;

        /**
         *
         * @access public
         * @type {Number|null}
         *
         */
        this.maxSubtitleRenditionBytes = Check.assigned(
            maxSubtitleRenditionBytes
        )
            ? maxSubtitleRenditionBytes
            : null;

        /**
         *
         * @access public
         * @type {Number|null}
         *
         */
        this.audioChannels = Check.assigned(audioChannels)
            ? audioChannels
            : null;

        /**
         *
         * @access public
         * @type {String|null}
         *
         */
        this.videoRange = videoRange || null;

        /**
         *
         * @access public
         * @type {String|null}
         *
         */
        this.videoCodec = videoCodec || null;

        /**
         *
         * @access public
         * @type {String|null}
         *
         */
        this.audioType = audioType || null;

        /**
         *
         * @access public
         * @type {String|null}
         *
         */
        this.audioCodec = audioCodec || null;

        /**
         *
         * @access public
         * @since 4.13.0
         * @type {Number|null}
         *
         */
        this.averageBandwidth = Check.assigned(averageBandwidth)
            ? averageBandwidth
            : null;

        /**
         *
         * @access public
         * @since 4.13.0
         * @type {Number|null}
         *
         */
        this.frameRate = Check.assigned(frameRate) ? frameRate : null;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackVariant';
    }

    // #endregion
}
