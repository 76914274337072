/**
 *
 * @module errorLevel
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ErrorLevel
 * @desc Impact level. In addition to indicating the level of impact that an event may have on the client, it also affects the verbosity of error reporting.
 *
 * @property {String} info - `Since 13.0.0` - Events which are never expected to lead to serious malfunction but may still be interesting for troubleshooting purposes.
 * @property {String} warn - `Since 13.0.0` - Events which are unlikely to be problematic on their own but repeated or frequent occurrences may indicate a serious problem.
 * @property {String} error - `Since 13.0.0` - Events which may result in serious malfunction, loss of function or termination.
 *
 * @example
 * SDK.Services.QualityOfService.ErrorLevel.warn // 'warn'
 *
 */
export default keyMirror({
    info: null,
    warn: null,
    error: null
});
