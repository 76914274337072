/**
 *
 * @module commerceClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/commerce.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import {
    ComcastConsentRequest,
    ComcastConsentRequestTypedef,
    ComcastConsentResponse,
    ComcastConsentResponseTypedef,
    ComcastPaymentMethodTypedef,
    ComcastPaymentRequest,
    ComcastPaymentRequestTypedef,
    CreateComcastPaymentMethodRequest,
    CreateComcastPaymentMethodRequestTypedef
} from '../../commerce/comcast/typedefs';

import { DisneyRewardsPaymentMethodTypedef } from '../../commerce/disneyRewards/typedefs';
import {
    IDealPaymentMethodTypedef,
    IDealPaymentRequest,
    IDealPaymentRequestTypedef
} from '../../commerce/iDeal/typedefs';
import {
    KlarnaPaymentMethodTypedef,
    KlarnaPaymentRequest,
    KlarnaPaymentRequestTypedef
} from '../../commerce/klarna/typedefs';
import {
    MercadoPaymentMethodTypedef,
    MercadoPaymentRequest,
    MercadoPaymentRequestTypedef
} from '../../commerce/mercado/typedefs';
import {
    CardPaymentMethodTypedef,
    CreateCardPaymentMethodRequest,
    CreateCardPaymentMethodRequestTypedef
} from '../../commerce/paymentCard/typedefs';
import {
    PayPalPaymentMethodTypedef,
    GetCheckoutDetailsRequest,
    GetCheckoutDetailsRequestTypedef,
    SetCheckoutDetailsRequest,
    SetCheckoutDetailsRequestTypedef,
    CreateBraintreePaymentMethodRequest,
    CreateBraintreePaymentMethodRequestTypedef,
    UpdateBraintreePaymentMethodRequest,
    UpdateBraintreePaymentMethodRequestTypedef
} from '../../commerce/payPal/typedefs';

import {
    AccountResumeRequest,
    AccountResumeRequestTypedef,
    AssociateAuthValuesWithPaymentMethodRequest,
    AssociateAuthValuesWithPaymentMethodRequestTypedef,
    OrderSubmissionRequest,
    OrderSubmissionRequestTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    PlanSwitchRequest,
    PlanSwitchRequestTypedef,
    PriceOrderRequest,
    PriceOrderRequestTypedef,
    RedeemRequest,
    RedeemRequestTypedef,
    RestartSubscriptionRequest,
    RestartSubscriptionRequestTypedef,
    UpdateOrderRequest,
    UpdateOrderRequestTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../../commerce/typedefs';

import {
    ClientTokenResponse,
    CreatePaymentMethodResponse,
    CreatePaymentMethodResponseTypedef,
    OrderResponse,
    OrderResponseTypedef,
    PaymentRedirectResponse,
    PaymentRedirectResponseTypedef,
    PriceOrderResponse,
    PriceOrderResponseTypedef,
    QueryOrderResponse,
    RedeemResponse,
    RedeemResponseTypedef,
    SetCheckoutDetailsResponse,
    SetCheckoutDetailsResponseTypedef,
    ZipLocation,
    ZipLocationTypedef
} from './typedefs';

import { PaymentMethodType, PaymentType } from './enums';

import AccessToken from '../token/accessToken';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';

import BrowserInfo from './browserInfo';
import CommerceClientConfiguration from './commerceClientConfiguration';
import CommerceClientEndpoint from './commerceClientEndpoint';

import ServiceException from '../exception/serviceException';
import ErrorReason from '../exception/errorReason';
import ExceptionReference from '../exception/exceptionReference';

import appendQuerystring from '../util/appendQuerystring';
import checkResponseCode from '../util/checkResponseCode';
import getSafe from '../util/getSafe';
import replaceHeaders from '../util/replaceHeaders';
import HttpMethod from '../configuration/httpMethod';

const CommerceClientDustUrnReference =
    DustUrnReference.services.commerce.commerceClient;

const regionResetTimerTime = 60000;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access commerce services.
 *
 */
export default class CommerceClient {
    /**
     *
     * @access private
     * @type {SDK.Services.Commerce.CommerceClientConfiguration}
     *
     */
    public config: CommerceClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {HttpClient}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private regionHeader: string;

    /**
     *
     * @access private
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @access private
     * @type {String}
     * @note When the `priceOrder` call is made we might get back a region header. Store it locally for a short time and apply it to the `getOrderStatus` call to assist in region affinity calculations
     *
     */
    private recentRegionHeader?: Nullable<string>;

    /**
     *
     * @access private
     * @type {String}
     * @desc 21.0.0
     *
     */
    private ubaToken: Nullable<string>;

    /**
     *
     * @param {SDK.Services.Commerce.CommerceClientConfiguration} commerceClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        commerceClientConfiguration: CommerceClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                commerceClientConfiguration: Types.instanceStrict(
                    CommerceClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = commerceClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.regionHeader = 'x-bamtech-region';
        this.dustEnabled = true;
        this.ubaToken = null;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object<SDK.Commerce.PriceOrderRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PriceOrderResponse>>}
     *
     */
    public priceOrder(
        request: PriceOrderRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PriceOrderResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(PriceOrderRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'priceOrder', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.priceOrder;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        logger.info(this.toString(), 'Attempting a price order request.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.priceOrder,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'priceOrder'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const { regionHeader } = this;

                const region = getSafe(
                    () => response.headers.get(regionHeader),
                    null
                );

                this.recentRegionHeader = region;

                this.beginRegionResetTimer();

                const priceOrderResponse = {
                    ...data,
                    region
                };

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        priceOrderResponse: Types.object(
                            PriceOrderResponseTypedef
                        )
                    };

                    typecheck.warn(responseType, [priceOrderResponse]);
                }

                return priceOrderResponse;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @since 21.0.0
     *
     */
    private beginRegionResetTimer() {
        setTimeout(() => {
            this.recentRegionHeader = undefined;
        }, regionResetTimerTime);
    }

    /**
     *
     * @access public
     * @param {String} zipCode
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Array<Object<SDK.Services.Commerce.ZipLocation>>>}
     *
     */
    public lookupByZipCode(
        zipCode: string,
        logTransaction: LogTransaction
    ): Promise<Array<ZipLocation>> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                zipCode: Types.nonEmptyString,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'lookupByZipCode', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.lookupByZipCode;

        const payload: GetPayloadResult = this.getPayload({
            endpointKey
        });

        payload.url = payload.url.replace(/\{zipcode\}/, zipCode);

        logger.info(
            this.toString(),
            `Retrieving locations by zipCode: "${zipCode}".`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.lookupByZipCode,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'lookupByZipCode'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.array.of.object(ZipLocationTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {Object<SDK.Commerce.RedeemRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.RedeemResponse>>}
     *
     */
    public redeem(
        request: RedeemRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<RedeemResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(RedeemRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'redeem', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.redeem;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.redeem,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(RedeemResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {Object} options
     * @param {Object<SDK.Commerce.OrderSubmissionRequest>} options.request
     * @param {SDK.Services.Commerce.BrowserInfo} options.browserInfo
     * @param {String} options.applicationRuntime
     * @param {String} [options.deviceProfile]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.OrderResponse>>}
     *
     */
    public submitOrderWithPaymentMethod(options: {
        request: OrderSubmissionRequest;
        browserInfo: BrowserInfo;
        applicationRuntime: string;
        deviceProfile?: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<OrderResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.object(OrderSubmissionRequestTypedef),
                    browserInfo: Types.instanceStrict(BrowserInfo),
                    applicationRuntime: Types.nonEmptyString,
                    deviceProfile: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'submitOrderWithPaymentMethod', params, arguments);
        }

        const {
            request,
            browserInfo,
            applicationRuntime,
            deviceProfile,
            accessToken,
            logTransaction
        } = options;
        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.submitOrderWithPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request,
            data: {
                browserInfo
            },
            optionalData: {
                applicationRuntime,
                deviceProfile
            }
        });

        logger.info(
            this.toString(),
            'Attempting to submit an order request with payment method.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.submitOrderWithPaymentMethod,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'submitOrderWithPaymentMethod'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(OrderResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.7.0
     * @param {Object<SDK.Commerce.UpdateOrderRequest>} request
     * @param {SDK.Services.Commerce.BrowserInfo} browserInfo
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async updateOrder(
        request: UpdateOrderRequest,
        browserInfo: BrowserInfo,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<void> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(UpdateOrderRequestTypedef),
                browserInfo: Types.instanceStrict(BrowserInfo),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'updateOrder', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.updateOrder;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request,
            data: {
                browserInfo
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.updateOrder,
            payload,
            method: HttpMethod.PATCH,
            endpointKey,
            logTransaction
        });

        try {
            const response: TodoAny = await httpClient.patch(payload);

            checkResponseCode(response, dustLogUtility);
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {String} guid
     * @param {Number} counter
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.QueryOrderResponse>>}
     * @note the spec does not contain a `counter` argument this is solely for the JS SDK
     *
     */
    public getOrderStatus(
        guid: string,
        counter: number,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<QueryOrderResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                guid: Types.nonEmptyString,
                counter: Types.number,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getOrderStatus', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.getOrderStatus;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        payload.url = payload.url.replace(/\{guid\}/, guid);
        payload.url = payload.url.replace(/\{counter\}/, counter.toString());

        payload.credentials = 'include';

        if (this.recentRegionHeader && payload?.headers) {
            payload.headers[this.regionHeader] = this.recentRegionHeader;
        }

        logger.info(
            this.toString(),
            `Attempting to retrieve the order status for guid: "${guid}".`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getOrderStatus,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'getOrderStatus'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const { guid: guidResponse, orderStatus, sca } = data;

                const queryOrderResponse = {
                    guid: guidResponse,
                    orderStatus,
                    strongCustomerAuth: sca
                };

                if (!guidResponse || !orderStatus) {
                    /**
                     *
                     * @note This check needs to fail so commerceManager.validateOrder(...) will retry the request.
                     *
                     */
                    const reasons = [new ErrorReason('', 'Invalid order')];
                    const exceptionData =
                        ExceptionReference.commerce.invalidOrderId;

                    throw new ServiceException({ reasons, exceptionData });
                }

                return queryOrderResponse;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {Object} options
     * @param {Object<SDK.Commerce.CreateCardPaymentMethodRequest>} options.request
     * @param {String} [options.region]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note attempts to use `region`, if provided, to determine a region-specific endpoint to be used - if one isn't
     * found the JS SDK will use the default `createCardPaymentMethod` endpoint from the SDK client config
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public createCardPaymentMethod(options: {
        request: CreateCardPaymentMethodRequest;
        region?: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<CreatePaymentMethodResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.object(
                        CreateCardPaymentMethodRequestTypedef
                    ),
                    region: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'createCardPaymentMethod', params, arguments);
        }

        const { request, region = '', accessToken, logTransaction } = options;
        const { config, dustEnabled, httpClient, logger } = this;
        const { extras, endpoints } = config;
        const { createPaymentMethodRegionalEndpoints = {} } = extras;

        const regionalEndpoint = createPaymentMethodRegionalEndpoints[region];
        const regionalEndpointKey = endpoints[regionalEndpoint]
            ? (endpoints[regionalEndpoint]
                  .rel as keyof typeof CommerceClientEndpoint)
            : undefined;

        const endpointKey = Check.assigned(regionalEndpointKey)
            ? regionalEndpointKey
            : CommerceClientEndpoint.createPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        logger.info(this.toString(), 'Attempting to create payment method.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.createCardPaymentMethod,
            payload,
            method: HttpMethod.POST,
            data: {
                region
            },
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'createCardPaymentMethod'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(CreatePaymentMethodResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public getDefaultPaymentMethod(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getDefaultPaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger, getPaymentMethodType } = this;

        const endpointKey = CommerceClientEndpoint.getDefaultPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to get default payment method.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getDefaultPaymentMethod,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'getDefaultPaymentMethod'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const paymentMethodType = getPaymentMethodType(data);

                    const responseType = {
                        data: Types.object(paymentMethodType)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {Object<SDK.Commerce.UpdatePaymentMethodBase>} request
     * @param {String<SDK.Services.Commerce.PaymentType>} paymentType
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     * @note This is a PATCH request.
     *
     */
    public updatePaymentMethod(
        request: UpdatePaymentMethodBase,
        paymentType: keyof typeof PaymentType,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(UpdatePaymentMethodBaseTypedef),
                paymentType: Types.in(PaymentType),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'updatePaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger, getPaymentMethodType } = this;
        const { paymentMethodId, type, ...updateRequest } = request;

        const endpointKey = CommerceClientEndpoint.updatePaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: updateRequest
        });

        payload.url = payload.url.replace(/\{paymentType\}/, paymentType);
        payload.url = payload.url.replace(
            /\{paymentMethodId\}/,
            paymentMethodId
        );

        logger.info(
            this.toString(),
            `Attempting to update ${type} payment method.`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.updatePaymentMethod,
            payload,
            method: HttpMethod.PATCH,
            endpointKey,
            logTransaction
        });

        return httpClient
            .patch(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'updatePaymentMethod'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const paymentMethodType = getPaymentMethodType(data);

                    const responseType = {
                        data: Types.object(paymentMethodType)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Array<Object<SDK.Commerce.PaymentMethod>>>}
     *
     */
    public listAllPaymentMethods(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<Array<PaymentMethod>> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'listAllPaymentMethods', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.listAllPaymentMethods;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to get all stored payment methods.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.listAllPaymentMethods,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'listAllPaymentMethods'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.array.of.object(PaymentMethodTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.Klarna.KlarnaPaymentRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PaymentRedirectResponse>>}
     *
     */
    public submitKlarnaPayment(
        request: KlarnaPaymentRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentRedirectResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(KlarnaPaymentRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'submitKlarnaPayment', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.createKlarnaPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.submitKlarnaPayment,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(PaymentRedirectResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.7.0
     * @param {Object} options
     * @param {Object<SDK.Commerce.AccountResumeRequest>} options.request
     * @param {SDK.Services.Commerce.BrowserInfo} options.browserInfo
     * @param {String} [options.deviceProfile]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.OrderResponse>>}
     *
     */
    public resumeAccount(options: {
        request: AccountResumeRequest;
        browserInfo: BrowserInfo;
        deviceProfile?: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<OrderResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.object(AccountResumeRequestTypedef),
                    browserInfo: Types.instanceStrict(BrowserInfo),
                    deviceProfile: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'resumeAccount', params, arguments);
        }

        const {
            request,
            browserInfo,
            deviceProfile,
            accessToken,
            logTransaction
        } = options;

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.resumeAccount;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request,
            data: {
                browserInfo
            },
            optionalData: {
                deviceProfile
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.resumeAccount,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(OrderResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public getDdcJwtToken(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<string> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getDdcJwtToken', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.getDdcJwtToken;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getDdcJwtToken,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 4.19.0
     * @param {Object<SDK.Commerce.Mercado.MercadoPaymentRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PaymentRedirectResponse>>}
     *
     */
    public submitMercadoPayment(
        request: MercadoPaymentRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentRedirectResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(MercadoPaymentRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'submitMercadoPayment', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.submitMercadoPayment;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.submitMercadoPayment,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(PaymentRedirectResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object} options
     * @param {Object<SDK.Commerce.PlanSwitchRequest>} options.request
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.OrderResponse>>}
     *
     */
    public planSwitch(options: {
        request: PlanSwitchRequest;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }): Promise<OrderResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.object(PlanSwitchRequestTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'planSwitch', params, arguments);
        }

        const { request, accessToken, logTransaction } = options;

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.planSwitch;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.planSwitch,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(OrderResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object<SDK.Commerce.Comcast.ComcastPaymentRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public submitComcastPayment(
        request: ComcastPaymentRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(ComcastPaymentRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'submitComcastPayment', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.submitComcastPayment;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.submitComcastPayment,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(CreatePaymentMethodResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object} options
     * @param {Object<SDK.Commerce.IDeal.IDealPaymentRequest>} options.request
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.PaymentRedirectResponse>>}
     *
     */
    public submitIDealPayment(
        request: IDealPaymentRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentRedirectResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(IDealPaymentRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'submitIDealPayment', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.submitIDealPayment;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.submitIDealPayment,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(PaymentRedirectResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.PayPal.GetCheckoutDetailsRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public getCheckoutDetails(
        request: GetCheckoutDetailsRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(GetCheckoutDetailsRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getCheckoutDetails', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.getPayPalCheckoutDetails;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getCheckoutDetails,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(CreatePaymentMethodResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.PayPal.SetCheckoutDetailsRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.SetCheckoutDetailsResponse>>}
     *
     */
    public setCheckoutDetails(
        request: SetCheckoutDetailsRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<SetCheckoutDetailsResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(SetCheckoutDetailsRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'setCheckoutDetails', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.setPayPalCheckoutDetails;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.setCheckoutDetails,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const { TOKEN, BUILD, TIMESTAMP, CORRELATIONID, VERSION, ACK } =
                    data;

                const setCheckoutDetailsResponse = {
                    token: TOKEN,
                    timestamp: TIMESTAMP,
                    correlationId: CORRELATIONID,
                    payPalAcknowledgement: ACK,
                    payPalVersion: VERSION,
                    payPalBuild: BUILD
                };

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        setCheckoutDetailsResponse: Types.object(
                            SetCheckoutDetailsResponseTypedef
                        )
                    };

                    typecheck.warn(responseType, [setCheckoutDetailsResponse]);
                }

                return setCheckoutDetailsResponse;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public shareDefaultPaymentMethod(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'shareDefaultPaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger, getPaymentMethodType } = this;

        const endpointKey = CommerceClientEndpoint.shareDefaultPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.shareDefaultPaymentMethod,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const paymentMethodType = getPaymentMethodType(data);

                    const responseType = {
                        data: Types.object(paymentMethodType)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.RestartSubscriptionRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.OrderResponse>>}
     *
     */
    public restartSubscription(
        request: RestartSubscriptionRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<OrderResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(RestartSubscriptionRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'restartSubscription', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.restartSubscription;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.restartSubscription,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(OrderResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {String} paymentMethodId
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.PaymentMethod>>}
     *
     */
    public getPaymentMethod(
        paymentMethodId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<PaymentMethod> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                paymentMethodId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getPaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger, getPaymentMethodType } = this;

        const endpointKey = CommerceClientEndpoint.getPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        payload.url = payload.url.replace(
            /\{paymentMethodId\}/,
            paymentMethodId
        );

        logger.info(this.toString(), 'Attempting to get payment card.');

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getPaymentMethod,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(
                    response,
                    dustLogUtility,
                    'getPaymentMethod'
                );
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const paymentMethodType = getPaymentMethodType(data);

                    const responseType = {
                        data: Types.object(paymentMethodType)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 10.1.0
     * @param {Object<SDK.Commerce.Comcast.CreateComcastPaymentMethodRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Commerce.CreatePaymentMethodResponse>>}
     *
     */
    public createComcastPaymentMethod(
        request: CreateComcastPaymentMethodRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(CreateComcastPaymentMethodRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createComcastPaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = CommerceClientEndpoint.createComcastPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: request
        });

        logger.info(
            this.toString(),
            'Attempting to create a comcast payment method.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.createComcastPaymentMethod,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        return httpClient
            .post(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(CreatePaymentMethodResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 10.1.0
     * @param {Object<SDK.Commerce.Comcast.ComcastConsentRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Commerce.Comcast.ComcastConsentResponse>>}
     *
     */
    public getComcastConsent(
        request: ComcastConsentRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<ComcastConsentResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(ComcastConsentRequestTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getComcastConsent', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;
        const { skus } = request as { skus: Array<string> };

        const endpointKey = CommerceClientEndpoint.getComcastConsent;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        if (Check.nonEmptyArray(skus)) {
            request.skus = skus.join();
        }

        payload.url = appendQuerystring(
            payload.url,
            queryString.stringify(request)
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getComcastConsent,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(ComcastConsentResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 12.0.0
     * @param {Object<SDK.Commerce.AssociateAuthValuesWithPaymentMethodRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async associateAuthValuesWithPaymentMethod(
        request: AssociateAuthValuesWithPaymentMethodRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<void> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(
                    AssociateAuthValuesWithPaymentMethodRequestTypedef
                ),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(
                this,
                'associateAuthValuesWithPaymentMethod',
                params,
                arguments
            );
        }

        const { dustEnabled, httpClient, logger } = this;
        const { paymentMethodId, dateOfBirth, pin } = request;

        const endpointKey =
            CommerceClientEndpoint.associateAuthValuesWithPaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                dateOfBirth,
                pin
            }
        });

        payload.url = payload.url.replace(
            /\{paymentMethodId\}/,
            paymentMethodId
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.associateAuthValuesWithPaymentMethod,
            payload,
            method: HttpMethod.PATCH,
            endpointKey,
            logTransaction
        });

        try {
            const response: TodoAny = await httpClient.patch(payload);

            checkResponseCode(response, dustLogUtility);
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Commerce.ClientTokenResponse>}
     *
     */
    public async createBraintreeToken(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<ClientTokenResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createBraintreeToken', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey =
            CommerceClientEndpoint.createPayPalBraintreeClientToken;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.createBraintreeToken,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        try {
            const response: TodoAny = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} billingAgreementId
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>}
     *
     */
    public async getBraintreePaymentMethodId(
        billingAgreementId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                billingAgreementId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getBraintreePaymentMethodId', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey =
            CommerceClientEndpoint.getPayPalBraintreePaymentMethodId;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                billingAgreementId
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.getBraintreePaymentMethodId,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.CreateBraintreePaymentMethodRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Commerce.CreatePaymentMethodResponse>}
     *
     */
    public async createBraintreePaymentMethod(
        request: CreateBraintreePaymentMethodRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<CreatePaymentMethodResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(
                    CreateBraintreePaymentMethodRequestTypedef
                ),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createBraintreePaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey =
            CommerceClientEndpoint.createPayPalBraintreePaymentMethod;

        const payload: GetPayloadResult = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                ...request
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.createBraintreePaymentMethod,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.UpdateBraintreePaymentMethodRequest>} request
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async updateBraintreePaymentMethod(
        request: UpdateBraintreePaymentMethodRequest,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<void> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(
                    UpdateBraintreePaymentMethodRequestTypedef
                ),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'updateBraintreePaymentMethod', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;
        const { paymentMethodId, ...updateRequest } = request;

        const endpointKey =
            CommerceClientEndpoint.updatePayPalBraintreePaymentMethod;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                ...updateRequest
            }
        });

        payload.url = payload.url.replace(
            /\{paymentMethodId\}/,
            paymentMethodId
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: CommerceClientDustUrnReference.updateBraintreePaymentMethod,
            payload,
            method: HttpMethod.PATCH,
            endpointKey,
            logTransaction
        });

        try {
            const response: TodoAny = await httpClient.patch(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} token
     * @returns {Void}
     *
     */
    public setUbaToken(token: string): void {
        this.ubaToken = token;
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @param {Object<SDK.Commerce.PaymentMethod>} paymentMethodObj - object containing one of the seven different payment method types.
     * @returns {Object} - returns the typedef obj to typecheck against.
     *
     */
    public getPaymentMethodType(paymentMethodObj: PaymentMethod) {
        const { type } = paymentMethodObj;

        let paymentMethod;

        switch (type) {
            case PaymentMethodType.PaymentCard:
                paymentMethod = CardPaymentMethodTypedef;
                break;

            case PaymentMethodType.PayPalExpress:
                paymentMethod = PayPalPaymentMethodTypedef;
                break;

            case PaymentMethodType.MercadoPagoPayment:
                paymentMethod = MercadoPaymentMethodTypedef;
                break;

            case PaymentMethodType.KlarnaPayment:
                paymentMethod = KlarnaPaymentMethodTypedef;
                break;

            case PaymentMethodType.IDealPayment:
                paymentMethod = IDealPaymentMethodTypedef;
                break;

            case PaymentMethodType.ComcastPayment:
                paymentMethod = ComcastPaymentMethodTypedef;
                break;

            case PaymentMethodType.DisneyRewards:
                paymentMethod = DisneyRewardsPaymentMethodTypedef;
                break;
        }

        return paymentMethod;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} [options.accessToken] - The access token.
     * @param {SDK.Services.Commerce.CommerceClientEndpoint} options.endpointKey - Endpoint to be referenced.
     * @param {Object} [options.data={}] - Additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @param {Object} [options.optionalData] - Optional data for optional headers.
     * @param {Object} [options.body] - Body to be serialized and passed with the request.
     * @returns {Object} The payload for the client request.
     *
     */
    public getPayload(options: {
        accessToken?: AccessToken;
        endpointKey?: keyof typeof CommerceClientEndpoint;
        data?: {
            browserInfo?: BrowserInfo | undefined;
        };
        optionalData?: {
            applicationRuntime?: string | undefined;
            deviceProfile?: string | undefined;
        };
        body?: object;
    }): GetPayloadResult {
        const { accessToken, endpointKey, data, optionalData, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey as string];
        const { headers, optionalHeaders } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';
        const { applicationRuntime, deviceProfile } = optionalData || {};
        const { browserInfo } = data || {};

        const replacerConfig = {
            Authorization: () => {
                return {
                    replacer: '{accessToken}',
                    value: accessToken?.token
                };
            },
            'X-BAMTech-Purchase-Platform': () => {
                return {
                    replacer: '{applicationRuntime}',
                    value: applicationRuntime
                };
            },
            'X-BAMTech-Sales-Platform': () => {
                return {
                    replacer: '{deviceProfile}',
                    value: deviceProfile
                };
            },
            'X-BAMTech-Browser-Info': () => {
                return {
                    replacer: '{browserInfo}',
                    value: browserInfo
                };
            },
            'X-BAMTECH-CTF-UBA-ID': () => {
                return {
                    replacer: '{ubaToken}',
                    value: this.ubaToken,
                    removeIfEmpty: true
                };
            }
        };

        const requestHeaders = replaceHeaders(
            replacerConfig,
            headers,
            optionalHeaders
        );

        return {
            url: endpoint.href,
            body: requestBody,
            headers: requestHeaders as Record<string, string> // TODO: fix once we convert services/util/replaceHeaders to typescript
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Commerce.CommerceClient';
    }
}
