/**
 *
 * @module videoResolution
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ResolutionMax from './resolutionMax';

/**
 *
 * @since 4.18.0
 *
 */
export default class VideoResolution {
    /**
     *
     * @param {Array<SDK.Services.Media.ResolutionMax>} [max]
     *
     */
    constructor(max) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                max: Types.array.of.in(ResolutionMax).optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {Array<SDK.Services.Media.ResolutionMax>|undefined}
         *
         */
        this.max = max;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.VideoResolution';
    }

    // #endregion
}
