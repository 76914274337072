/**
 *
 * @module entry
 * @desc {SDK.UserActivity.Personalization} entry point.
 *
 */

import PersonalizationEvent from './personalizationEvent';
import PersonalizationEvents from './personalizationEvents';
import UserActivityAction from './userActivityAction';

/**
 *
 * @typedef {Object} SDK.UserActivity.Personalization
 *
 * @property {PersonalizationEvent} PersonalizationEvent
 * @property {PersonalizationEvents} PersonalizationEvents
 * @property {SDK.UserActivity.Personalization.UserActivityAction} UserActivityAction
 *
 */
export default {
    PersonalizationEvent,
    PersonalizationEvents,
    UserActivityAction
};
