/**
 *
 * @module configurationClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Used to expose the list of configuration host names and corresponding baseUrls
 * in the `ConfigurationClientConfiguration`
 *
 */
export default class ConfigurationClientExtrasMap {
    /**
     *
     * @param {Object} configHostParams
     *
     */
    constructor(configHostParams) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                configHostParams: Types.nonEmptyObject
            };

            typecheck.warn(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Object}
         * @desc The object containing the list of configuration host names and corresponding baseUrls
         *
         */
        this.configHostParams = configHostParams;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.ConfigurationClientExtrasMap';
    }

    // #endregion
}
