/**
 *
 * @module playbackIntent
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackIntent
 * @desc Defines the different types of playback intent.
 *
 * @property {String} autoplay - Since `4.0.0` - When the application automatically plays a video. e.g. the frontmost cell of a content carousel.
 * @property {String} userAction - Since `4.0.0` - When the user clicks or taps on a video to begin playback.
 * @property {String} autoAdvance - Since `4.0.0` - When a new media item automatically plays after the previous has
 * ended. e.g. when viewing a series season or playlist.
 * @property {String} background - Since `4.0.0` - When video is part of a UI element, such as a hero on a series detail page.
 * @property {String} pip - Since `4.0.0` - When the user explicitly enables picture in picture, or when implicitly enabled via backgrounding the app.
 * @property {String} transferred - Since `4.0.0` - When the user transfers the video to Chromecast or Airplay.
 * @property {String} userActionRestartButton - Since `4.8.0` - When the user clicks the restart button to resume playback from where they left off.
 * @property {String} tileFocus - Since `4.8.0` - When the user moves focus to a tile to begin playback, such as animated brand tiles.
 * @property {String} feedSwitch - Since `9.0.0` - To be used to indicate that a user has switched between feeds in live content.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackIntent.autoplay // 'autoplay'
 *
 */
export default keyMirror({
    autoplay: null,
    userAction: null,
    autoAdvance: null,
    background: null,
    pip: null,
    transferred: null,
    userActionRestartButton: null,
    tileFocus: null,
    feedSwitch: null
});
