/**
 *
 * @module insertionPointContentType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointContentType
 *
 * @property {String} AUXILIARY_CONTENT - Since `18.0.0`.
 * @property {String} AD_SERVICE_CONTENT - Since `18.0.0`.
 *
 * @example
 * InsertionPointContentType.AUXILIARY_CONTENT // 'AUXILIARY_CONTENT'
 *
 */
export default keymirror({
    AUXILIARY_CONTENT: null,
    AD_SERVICE_CONTENT: null
});
