/**
 *
 * @module bifThumbnailSet
 *
 */

import ThumbnailSet from './thumbnailSet';

export default class BifThumbnailSet extends ThumbnailSet {
    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.BifThumbnailSet';
    }

    // #endregion
}
