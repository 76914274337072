/**
 *
 * @module tokenClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 *
 */
export default class TokenClientExtrasMap {
    /**
     *
     * @param {Object} options
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    platformId: Types.nonEmptyString
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const { platformId } = options || {};

        /**
         *
         * @access public
         * @type {String|undefined}
         * @desc Gets or sets the platform ID.
         *
         */
        this.platformId = platformId;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.TokenClientExtrasMap';
    }

    // #endregion
}
