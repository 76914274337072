/**
 *
 * @module userProfilePlugin
 * @desc {SDK.UserProfile.UserProfilePlugin} entry point.
 *
 */

import UserProfile from './entry';
import UserProfileApi from './userProfileApi';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof UserProfile, 'UserProfile'> = {
    pluginName: 'UserProfile',
    entry: UserProfile,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;

        const { accountManager, tokenManager } = sdkSession.managers;

        sdkSession.userProfileApi = new UserProfileApi({
            accountManager,
            tokenManager,
            logger
        });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
