/**
 *
 * @module mediaDescriptor
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/playback-mapping.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import MediaPreferences from './mediaPreferences';

import { AssetInsertionStrategy, PlaybackInitiationContext } from './enums';

/**
 *
 * @access public
 * @since 2.0.0
 * @desc A description of an item available from the media service.
 *
 */
export default class MediaDescriptor {
    /**
     *
     * @access public
     * @since 4.2.0
     * @type {String}
     * @desc Gets the absolute URL for the media service request.
     *
     */
    public playbackUrl: string;

    /**
     *
     * @access public
     * @since 3.6.0
     * @type {Object}
     * @desc Custom adTargeting object to be used when setting cookies related to AdEngine.
     *
     */
    public adTargeting?: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {String|undefined}
     * @desc ID for cache resolution of every cached `MediaItem`.
     * @note Since offline downloads is not a requirement on JS, contentId is optional.
     *
     */
    public contentId?: string;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {SDK.Media.MediaPreferences|undefined}
     * @desc Object of media related preference enums provided by the app developer, used in scenario string
     * manipulation.
     *
     */
    public mediaPreferences?: MediaPreferences;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {String|undefined}
     * @desc String used by app devs to override the base device capability derived by SDK.
     *
     */
    public baseDeviceCapabilityOverride?: string;

    /**
     *
     * @access public
     * @since 17.0.0
     * @type {String<SDK.Media.AssetInsertionStrategy>}
     * @desc The asset insertion strategy to be used with the media.
     *
     */
    public assetInsertionStrategy: ValueOf<typeof AssetInsertionStrategy>;

    /**
     *
     * @access public
     * @since 17.0.0
     * @type {String<SDK.Media.PlaybackInitiationContext>|undefined}
     * @desc The context will be used by playback orchestration to determine the types of assets that will be
     * inserted during breaks. For online, it should typically be sgai if it is supported on the device. Otherwise
     * it will be ssai. offline, groupWatch, and sharePlay are all expected to be ssai eg bumpers with no ads.
     *
     */
    public playbackInitiationContext?: ValueOf<
        typeof PlaybackInitiationContext
    >;

    /**
     *
     * @param {Object} options
     * @param {String} options.playbackUrl - The absolute URL for the media service request.
     * @param {Object} [options.adTargeting={}] - Custom adTargeting object to be used when setting cookies related to AdEngine.
     * @param {String} [options.contentId] - ID for cache resolution of every cached MediaItem.
     * @param {SDK.Media.MediaPreferences} [options.mediaPreferences] - Object of media related preference enums
     * @param {String} [options.baseDeviceCapabilityOverride] - String used by app devs to override the base device capability
     * derived by SDK.
     * @param {String<SDK.Media.AssetInsertionStrategy>} options.assetInsertionStrategy - The asset insertion strategy to be used with the media.
     * @param {String<SDK.Media.PlaybackInitiationContext>} [options.playbackInitiationContext] - The context will be used by
     * playback orchestration to determine the types of assets that will be inserted during breaks. For online, it should
     * typically be sgai if it is supported on the device. Otherwise it will be ssai. offline, groupWatch, and sharePlay are all expected
     * to be ssai eg bumpers with no ads.
     *
     */
    public constructor(options: {
        playbackUrl: string;
        adTargeting?: Record<string, unknown>;
        contentId?: string;
        mediaPreferences?: MediaPreferences;
        baseDeviceCapabilityOverride?: string;
        assetInsertionStrategy: ValueOf<typeof AssetInsertionStrategy>;
        playbackInitiationContext?: ValueOf<typeof PlaybackInitiationContext>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackUrl: Types.nonEmptyString,
                    adTargeting: Types.object().optional,
                    contentId: Types.nonEmptyString.optional,
                    mediaPreferences:
                        Types.instanceStrict(MediaPreferences).optional,
                    baseDeviceCapabilityOverride: Types.nonEmptyString.optional,
                    assetInsertionStrategy: Types.in(AssetInsertionStrategy),
                    playbackInitiationContext: Types.in(
                        PlaybackInitiationContext
                    ).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            playbackUrl,
            adTargeting,
            contentId,
            mediaPreferences,
            baseDeviceCapabilityOverride,
            assetInsertionStrategy,
            playbackInitiationContext
        } = options;

        this.playbackUrl = playbackUrl;
        this.adTargeting = adTargeting || {};
        this.contentId = contentId;
        this.mediaPreferences = mediaPreferences;
        this.baseDeviceCapabilityOverride = baseDeviceCapabilityOverride;
        this.assetInsertionStrategy = assetInsertionStrategy;
        this.playbackInitiationContext = playbackInitiationContext;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaDescriptor';
    }
}
