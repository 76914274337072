/**
 *
 * @module codecAttributes
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import SupportedCodec from './supportedCodec';

/**
 *
 * @since 4.18.0
 *
 */
export default class CodecAttributes {
    /**
     *
     * @param {Array<SDK.Services.Media.SupportedCodec>} [video]
     * @note supportsMultiCodecMaster is apple only
     *
     */
    constructor(video) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                video: Types.array.of.in(SupportedCodec).optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {Array<SDK.Services.Media.SupportedCodec>|undefined}
         *
         */
        this.video = video;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.CodecAttributes';
    }

    // #endregion
}
