/**
 *
 * @module entry
 * @desc {SDK.Drm} entry point.
 *
 */

import DrmManager from './drmManager';
import DrmProvider from './drmProvider';
import FairPlayDrmProvider from './fairPlayDrmProvider';
import PlayReadyDrmProvider from './playReadyDrmProvider';
import SilkDrmProvider from './silkDrmProvider';
import WidevineDrmProvider from './widevineDrmProvider';
import NagraDrmProvider from './nagraDrmProvider';
import CertificateStorage from './certificateStorage';
import WidevineCertificateStorage from './widevineCertificateStorage';
import FairPlayCertificateStorage from './fairPlayCertificateStorage';
import NagraCertificateStorage from './nagraCertificateStorage';

/**
 *
 * @typedef {Object} SDK.Drm
 *
 * @property {DrmManager} DrmManager
 * @property {DrmProvider} DrmProvider
 * @property {FairPlayDrmProvider} FairPlayDrmProvider
 * @property {PlayReadyDrmProvider} PlayReadyDrmProvider
 * @property {SilkDrmProvider} SilkDrmProvider
 * @property {WidevineDrmProvider} WidevineDrmProvider
 * @property {NagraDrmProvider} NagraDrmProvider
 * @property {CertificateStorage} CertificateStorage
 * @property {WidevineCertificateStorage} WidevineCertificateStorage
 * @property {FairPlayCertificateStorage} FairPlayCertificateStorage
 * @property {NagraCertificateStorage} NagraCertificateStorage
 *
 */
export default {
    DrmManager,
    DrmProvider,
    FairPlayDrmProvider,
    PlayReadyDrmProvider,
    SilkDrmProvider,
    WidevineDrmProvider,
    NagraDrmProvider,
    CertificateStorage,
    WidevineCertificateStorage,
    FairPlayCertificateStorage,
    NagraCertificateStorage
};
