/**
 *
 * @module mediaClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playback-session.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playhead.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/skynet/media-service/blob/master/docs/api/README.md
 * @see https://github.bamtech.co/skynet/playback-orchestration-service/blob/master/docs/api/WEB.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/thumbnail.md
 * @see https://github.bamtech.co/skynet/playback-orchestration-service/blob/master/public/swagger-2.0/swagger.json
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from './../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import MonotonicTimestampManager from '../../monotonicTimestampManager';

import DustLogUtility from './../internal/dust/dustLogUtility';
import DustUrnReference from './../internal/dust/dustUrnReference';
import DustCategory from './../internal/dust/dustCategory';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import AudioRendition from './audioRendition';
import MediaFetchErrorMapping from './mediaFetchErrorMapping';
import MediaPayload from './mediaPayload';
import MediaPayloadStream from './mediaPayloadStream';
import MediaPlayhead from './mediaPlayhead';
import MediaPlayheadStatus from './mediaPlayheadStatus';
import MediaClientEndpoint from './mediaClientEndpoint';
import MediaClientConfiguration from './mediaClientConfiguration';
import MediaPlaybackSelectionPayload from './mediaPlaybackSelectionPayload';
import PlaybackAttributes from './playbackAttributes';
import PlaybackContext from './playbackContext';
import PlaybackRenditions from './playbackRenditions';
import PlaybackVariant from './playbackVariant';
import PlaylistType from './playlistType';
import MediaThumbnailLink from './mediaThumbnailLink';
import MediaThumbnailLinks from './mediaThumbnailLinks';
import StreamingType from './streamingType';
import SubtitleRendition from './subtitleRendition';

import SpriteThumbnailSet from './spriteThumbnailSet';
import BifThumbnailSet from './bifThumbnailSet';
import Presentation from './presentation';
import LogTransaction from '../../logging/logTransaction';

import ApplicationContext from '../qualityOfService/applicationContext';
import ErrorEventData from '../qualityOfService/errorEventData';
import ErrorLevel from '../qualityOfService/errorLevel';
import ErrorSource from '../qualityOfService/errorSource';
import NetworkType from '../qualityOfService/networkType';
import PlaybackActivity from '../qualityOfService/playbackActivity';
import QoePlaybackError from '../qualityOfService/qoePlaybackError';
import PlaybackEventData from '../qualityOfService/playbackEventData';
import PlaybackExitedCause from '../qualityOfService/playbackExitedCause';
import PlaybackStartupEventData from '../qualityOfService/playbackStartupEventData';
import StartupActivity from '../qualityOfService/startupActivity';

import { AdInsertionType } from '../qualityOfService/enums';
import getSafe from '../util/getSafe';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import { getDataVersion } from '../../media/playbackTelemetryDispatcher';

import FetchStatus from '../qualityOfService/fetchStatus';
import QosHttpMethod from '../qualityOfService/httpMethod';
import NetworkError from '../qualityOfService/networkError';
import ServerRequest from '../qualityOfService/serverRequest';
import ServiceException from '../exception/serviceException';
import ExceptionReference from '../exception/exceptionReference';

import { AssetInsertionStrategy } from './../../media/enums';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;
const MediaClientDustUrnReference = DustUrnReference.services.media.mediaClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access media services.
 *
 */
export default class MediaClient {
    /**
     *
     * @param {SDK.Services.Media.MediaClientConfiguration} mediaClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    constructor(mediaClientConfiguration, logger, httpClient) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                mediaClientConfiguration: Types.instanceStrict(
                    MediaClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Services.Media.MediaClientConfiguration}
         * @desc The configuration information to use.
         *
         */
        this.config = mediaClientConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {HttpClient}
         * @desc The object responsible for making HTTP requests.
         *
         */
        this.httpClient = httpClient;

        /**
         *
         * @access private
         * @type {Boolean}
         * @note needs to default to true to collect dust events before the configuration is fetched and we can
         * determine if this should be enabled
         *
         */
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {Object} options
     * @param {String} options.playbackUrl - The absolute URL of the playlist service request.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {String} options.baseDeviceCapability - The base device capability used to construct the service request.
     * @param {SDK.Services.Media.MediaPlaybackSelectionPayload} options.playbackSelectionPayload
     * @param {SDK.Services.Media.PlaybackContext} [options.playbackContext]
     * @param {String} [options.preferredPlaylistType]
     * @param {String} [options.qcPlaybackExperienceContext] - String provided by qc viewer team to switch bumpers for the playback experience.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves the MediaPayload from the media location using the supplied playback scenario.
     * @note we remap several items from the playlist service before constructing `SDK.Services.Media.MediaPayload`
     * @note qcPlaybackExperienceContext should replace the value of the optional `X-Bamtech-Playback-Experience-Context` header on media payload requests'
     * @returns {Promise<SDK.Services.Media.MediaPayload>}
     *
     */
    async mediaPayload(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackUrl: Types.nonEmptyString,
                    accessToken: Types.instanceStrict(AccessToken),
                    baseDeviceCapability: Types.nonEmptyString,
                    playbackSelectionPayload: Types.instanceStrict(
                        MediaPlaybackSelectionPayload
                    ),
                    playbackContext:
                        Types.instanceStrict(PlaybackContext).optional,
                    preferredPlaylistType: Types.in(PlaylistType),
                    qcPlaybackExperienceContext: Types.string.optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'mediaPayload', params, arguments);
        }

        const {
            playbackUrl,
            accessToken,
            baseDeviceCapability,
            playbackSelectionPayload,
            playbackContext,
            preferredPlaylistType,
            qcPlaybackExperienceContext,
            logTransaction
        } = options;

        const { dustEnabled, httpClient, logger } = this;

        const { assetInsertionStrategy } =
            playbackSelectionPayload.playback.attributes;

        let endpointKey;

        // Fallback to V5 playlist for specific assetInsertionStrategy
        switch (assetInsertionStrategy) {
            case AssetInsertionStrategy.ADPARTNER:
            case AssetInsertionStrategy.NONE:
                endpointKey = MediaClientEndpoint.mediaPayloadV5;
                break;
            default:
                endpointKey = MediaClientEndpoint.mediaPayload;
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                ...playbackSelectionPayload
            },
            data: {
                qcPlaybackExperienceContext,
                url: playbackUrl.replace(/\{scenario\}/, baseDeviceCapability)
            }
        });

        logger.info(
            this.toString(),
            `Sending post media payload request: ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.mediaPayload,
            payload,
            method: HttpMethod.POST,
            endpointKey,
            logTransaction
        });

        let mediaPayload;
        let fallbackQos;
        let error;
        let qosDecision;
        let reasons;
        let errorMessage;
        let playlist;

        try {
            const response = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            const { data } = response;
            const { stream, tracking, playhead, thumbnails } = data || {};

            const mediaPlayhead = this.getMediaPlayhead(playhead);
            const mediaPayloadStream = this.parseMediaPayloadStream(stream);

            const mediaPayloadStreamSource = mediaPayloadStream.sources[0];

            if (preferredPlaylistType === PlaylistType.COMPLETE) {
                playlist =
                    mediaPayloadStreamSource.complete ||
                    mediaPayloadStreamSource.slide;
                qosDecision = mediaPayloadStream.qosDecisions.complete;
            } else if (preferredPlaylistType === PlaylistType.SLIDE) {
                playlist =
                    mediaPayloadStreamSource.slide ||
                    mediaPayloadStreamSource.complete;
                qosDecision = mediaPayloadStream.qosDecisions.slide;
            }

            fallbackQos = getSafe(() => playlist.tracking.qos, {});

            let mediaThumbnailLinks;

            if (Check.assigned(thumbnails)) {
                mediaThumbnailLinks = new MediaThumbnailLinks(thumbnails);
            }

            mediaPayload = {
                stream: mediaPayloadStream,
                tracking,
                playhead: mediaPlayhead,
                thumbnails: mediaThumbnailLinks
            };

            return new MediaPayload(mediaPayload);
        } catch (exception) {
            reasons = exception.reasons || [];
            errorMessage =
                getSafe(() => exception.data.message) || exception.message;

            error = this.constructErrorFromMediaPayloadException(exception);

            throw exception;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                const qoeEventDataArray = [];

                const {
                    playbackSessionId,
                    productType,
                    playbackIntent,
                    isPreBuffering,
                    contentKeys = {},
                    startupContext
                } = playbackContext;

                const { analyticsProvider } = logger;
                const attributes = getSafe(
                    () => mediaPayload.stream.attributes
                );
                const playbackVariants = getSafe(() => {
                    return mediaPayload.stream.playbackVariants;
                }, []);

                const variants = playbackVariants.map(
                    (item) => new PlaybackVariant(item)
                );
                const serverRequest =
                    this.getQosServerRequestData(dustLogUtility);

                const payloadQos = getSafe(
                    () => mediaPayload.tracking.qos,
                    null
                );
                const qos = { ...payloadQos, ...fallbackQos };
                const { clientDecisions = {}, serverDecisions = {} } =
                    qosDecision || {};
                const adInsertionType =
                    AdInsertionType[assetInsertionStrategy.toLowerCase()];
                const adsQos = getSafe(() => mediaPayload.stream.adsQos, null);
                const insertion = getSafe(
                    () => mediaPayload.stream.insertion,
                    null
                );
                const monotonicTimestamp =
                    MonotonicTimestampManager.getTimestamp();

                let data = Check.assigned(playbackContext)
                    ? playbackContext.data
                    : {};
                let dictionaryVersion;
                let adSessionId;
                let subscriptionType = '';
                let totalPodCount = 0;
                let totalSlotCount;
                let totalAdLength = 0;
                let createAdSessionResponseCode;
                let getPodsResponseCode;

                if (analyticsProvider) {
                    const commonProperties =
                        analyticsProvider.getCommonProperties();

                    data = {
                        ...data,
                        ...commonProperties
                    };

                    dictionaryVersion =
                        analyticsProvider.getDictionaryVersion();
                }

                if (adInsertionType !== AdInsertionType.none) {
                    adSessionId = getSafe(
                        () => adsQos.adSession.id,
                        '00000000-0000-0000-0000-000000000000'
                    );
                    subscriptionType = getSafe(() => adsQos.subscriptionType);
                    createAdSessionResponseCode = getSafe(
                        () => adsQos.adSession.responseCode
                    );

                    if (adInsertionType === AdInsertionType.ssai) {
                        getPodsResponseCode = getSafe(
                            () => adsQos.getPods.responseCode
                        );
                    }
                }

                if (insertion && insertion.points) {
                    totalPodCount = insertion.points.length;

                    if (adInsertionType === AdInsertionType.ssai) {
                        insertion.points.forEach((insertionPoint) => {
                            const { plannedSlotCount, duration } =
                                insertionPoint;

                            totalSlotCount = totalSlotCount
                                ? totalSlotCount + plannedSlotCount
                                : plannedSlotCount;
                            totalAdLength = totalAdLength
                                ? totalAdLength + duration
                                : duration;
                        });
                    }
                }

                const playbackStartupEventData = new PlaybackStartupEventData({
                    startupActivity: StartupActivity.fetched,
                    playbackSessionId,
                    productType,
                    networkType: NetworkType.unknown,
                    playbackIntent,
                    mediaPreBuffer: isPreBuffering,
                    playbackUrl,
                    serverRequest,
                    playbackScenario: baseDeviceCapability,
                    attributes,
                    variants,
                    mediaFetchError: error,
                    clientGroupIds: clientDecisions.clientGroupIds,
                    serverGroupIds: serverDecisions.serverGroupIds,
                    contentKeys,
                    subscriptionType,
                    adSessionId,
                    totalPodCount,
                    totalSlotCount,
                    totalAdLength,
                    createAdSessionResponseCode,
                    getPodsResponseCode,
                    monotonicTimestamp,
                    adInsertionType,
                    data,
                    qos,
                    startupContext
                });

                qoeEventDataArray.push({
                    eventDataUrn:
                        QualityOfServiceDustUrnReference.playbackStartup,
                    eventData: playbackStartupEventData
                });

                if (Check.assigned(error)) {
                    const errorEventData = new ErrorEventData({
                        applicationContext: ApplicationContext.player,
                        playbackSessionId,
                        isFatal: true,
                        source: ErrorSource.service,
                        errorName: QoePlaybackError.unknown,
                        errorLevel: ErrorLevel.error,
                        productType,
                        cdnName: serverRequest.cdnName,
                        dictionaryVersion,
                        underlyingSdkError: reasons[0],
                        errorMessage,
                        contentKeys,
                        clientGroupIds: clientDecisions.clientGroupIds,
                        serverGroupIds: serverDecisions.serverGroupIds,
                        adInsertionType,
                        subscriptionType,
                        data
                    });

                    const playbackEventData = new PlaybackEventData({
                        playbackActivity: PlaybackActivity.ended,
                        playbackSessionId,
                        productType,
                        cdnName: serverRequest.cdnName,
                        cause: PlaybackExitedCause.error,
                        clientGroupIds: clientDecisions.clientGroupIds,
                        serverGroupIds: serverDecisions.serverGroupIds,
                        contentKeys,
                        data
                    });

                    qoeEventDataArray.push({
                        eventDataUrn: QualityOfServiceDustUrnReference.error,
                        eventData: errorEventData
                    });
                    qoeEventDataArray.push({
                        eventDataUrn: QualityOfServiceDustUrnReference.playback,
                        eventData: playbackEventData
                    });
                }

                qoeEventDataArray.forEach((item) => {
                    const qosLogUtility = new DustLogUtility({
                        category: DustCategory.qoe,
                        logger,
                        source: this.toString(),
                        urn: item.eventDataUrn,
                        data: {
                            ...item.eventData
                        },
                        dataVersion: getDataVersion(item.eventDataUrn),
                        skipLogTransaction: true
                    });

                    qosLogUtility.log();
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates a cookie based on the accessToken
     * @returns {Promise<Void>}
     *
     */
    createAuthCookie(accessToken, logTransaction) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createAuthCookie', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = MediaClientEndpoint.playbackCookie;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        payload.credentials = 'include';

        logger.info(
            this.toString(),
            `Sending create auth cookie request: ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.createAuthCookie,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {Object} options
     * @param {SDK.Services.Media.MediaThumbnailLink} options.thumbnailLink
     * @param {String} [options.resolution]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note overwrites each `presentation` field returned from the service after converting that `Object` into
     * an instance of `SDK.Services.Media.Presentation`
     * @returns {Promise<Array<SDK.Services.Media.SpriteThumbnailSet>>}
     *
     */
    getSpriteSheetThumbnails(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    thumbnailLink: Types.instanceStrict(MediaThumbnailLink),
                    resolution: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSpriteSheetThumbnails', params, arguments);
        }

        const { thumbnailLink, resolution, accessToken, logTransaction } =
            options;

        const { method } = thumbnailLink;
        const { dustEnabled, httpClient, logger } = this;

        const url = this.createThumbnailUrl(thumbnailLink, resolution);

        const endpointKey = MediaClientEndpoint.spriteSheetThumbnails;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                url,
                headers: thumbnailLink.headers
            }
        });

        logger.info(
            this.toString(),
            `request spritesheet thumbnails ${thumbnailLink.url}`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.getSpriteSheetThumbnails,
            payload,
            method,
            endpointKey,
            logTransaction
        });

        return httpClient[thumbnailLink.method.toLowerCase()](payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;
                const { spritesheets } = data;

                if (Check.not.nonEmptyArray(spritesheets)) {
                    const thumbnailsNotAvailableException =
                        new ServiceException({
                            exceptionData:
                                ExceptionReference.media.thumbnailsNotAvailable
                        });

                    return Promise.reject(thumbnailsNotAvailableException);
                }

                const spriteThumbnailSets = spritesheets.map((spritesheet) => {
                    const presentations = spritesheet.presentations.map(
                        (presentation) => new Presentation(presentation)
                    );

                    return new SpriteThumbnailSet({
                        ...spritesheet,
                        ...{ presentations }
                    });
                });

                return Promise.resolve(spriteThumbnailSets);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Services.Media.Presentation} presentation
     * @param {Number} index
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note fetches the Byte[] version of the .jpeg file - unlikely this will be used on JS platforms as it generally
     * means saving the byte array to disk and loading into memory later on
     * @returns {Promise<ArrayBuffer>}
     *
     */
    getSpriteSheetThumbnail(presentation, index, logTransaction) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                presentation: Types.instanceStrict(Presentation),
                index: Types.number,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getSpriteSheetThumbnail', params, arguments);
        }

        const { dustEnabled, httpClient, logger } = this;

        const endpointKey = MediaClientEndpoint.spriteSheetThumbnail;

        const payload = this.getPayload({
            endpointKey,
            data: {
                url: presentation.paths[index]
            },
            bodyType: 'arrayBuffer'
        });

        logger.info(
            this.toString(),
            `request spritesheet thumbnail ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.getSpriteSheetThumbnail,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        return httpClient
            .get(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;

                return Promise.resolve(data);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {Object} options
     * @param {SDK.Services.Media.MediaThumbnailLink} options.thumbnailLink
     * @param {String} [options.resolution]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note overwrites each `presentation` field returned from the service after converting that `Object` into
     * an instance of `SDK.Services.Media.Presentation`
     * @returns {Promise<Array<SDK.Services.Media.BifThumbnailSet>>}
     *
     */
    getBifThumbnails(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    thumbnailLink: Types.instanceStrict(MediaThumbnailLink),
                    resolution: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getBifThumbnails', params, arguments);
        }

        const { thumbnailLink, resolution, accessToken, logTransaction } =
            options;
        const { method } = thumbnailLink;
        const { dustEnabled, httpClient, logger } = this;

        const url = this.createThumbnailUrl(thumbnailLink, resolution);

        const endpointKey = MediaClientEndpoint.bifThumbnails;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                url,
                headers: thumbnailLink.headers
            }
        });

        logger.info(
            this.toString(),
            `request bif thumbnails ${thumbnailLink.url}`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.getBifThumbnails,
            payload,
            method,
            endpointKey,
            logTransaction
        });

        return httpClient[thumbnailLink.method.toLowerCase()](payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                const { data } = response;
                const { bifs } = data;

                if (Check.not.nonEmptyArray(bifs)) {
                    const thumbnailsNotAvailableException =
                        new ServiceException({
                            exceptionData:
                                ExceptionReference.media.thumbnailsNotAvailable
                        });

                    return Promise.reject(thumbnailsNotAvailableException);
                }

                const bifThumbnailSets = bifs.map((bif) => {
                    const presentations = bif.presentations.map(
                        (presentation) => new Presentation(presentation)
                    );

                    return new BifThumbnailSet({
                        ...bif,
                        ...{ presentations }
                    });
                });

                return Promise.resolve(bifThumbnailSets);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @note the JS SDK does not support this method
     * @returns {Promise<Void>}
     *
     */
    async downloadBifThumbnail() {
        throw new Error(`${this}.downloadBifThumbnail() - not-implemented`);
    }

    // #region private

    /**
     *
     * @access private
     * @param {Object} [stream={}] - playhead data object
     * @desc Constructs a SDK.Services.Media.MediaPlayhead instance based on the provided playhead data, returns null
     * if the data is not available. Please note, this method checks against the status property because
     * its guaranteed to exist if the playhead exists.
     * @returns {SDK.Services.Media.MediaPayloadStream}
     *
     */
    parseMediaPayloadStream(stream) {
        const {
            adsQos,
            sources = this.translateMediaPayloadV5(stream),
            insertion,
            attributes,
            variants,
            renditions,
            qosDecisions,
            streamingType = StreamingType.VOD // Playlist version 5 does not support streamingType and thus defaults to VOD
        } = stream || {};

        const playbackAttributes = PlaybackAttributes.parse(attributes);

        const playbackVariants = variants.map((v) => new PlaybackVariant(v));

        const playbackRenditions = new PlaybackRenditions({
            audio: renditions.audio.map((a) => new AudioRendition(a)),
            subtitles: renditions.subtitles.map((s) => new SubtitleRendition(s))
        });

        return new MediaPayloadStream({
            adsQos,
            sources,
            variants: playbackVariants,
            renditions: playbackRenditions,
            attributes: playbackAttributes,
            qosDecisions,
            streamingType,
            insertion
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} [stream={}] - playhead data object
     * @desc Translates version 5 media payload structure to version 6
     * @returns {Array<Object>}
     *
     */
    translateMediaPayloadV5(stream) {
        const { complete, slide } = stream || {};

        let sources = [];

        const completeSources = this.translateSourceInfo(complete, 'complete');
        const slideSources = this.translateSourceInfo(slide, 'slide');

        if (completeSources.length >= slideSources.length) {
            sources = this.mergeSources(completeSources, slideSources);
        } else {
            sources = this.mergeSources(slideSources, completeSources);
        }

        return sources;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Array<Object>} sources
     * @param {Array<Object>} toMerge
     * @desc Translates version 5 media payload structure to version 6
     * @returns {Array<Object>}
     *
     */
    mergeSources(sources, toMerge) {
        const mergedSources = sources.map((item) => {
            const foundElement = toMerge.find(
                (element) => element.priority === item.priority
            );

            return {
                ...item,
                ...foundElement
            };
        });

        return mergedSources;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Array<Object>} [source=[]]
     * @param {String} type
     * @desc Translates version 5 media payload structure to version 6
     * @returns {Array<Object>}
     *
     */
    translateSourceInfo(source, type) {
        if (!source) {
            return [];
        }

        return source.map((sourceInfo) => {
            return {
                priority: sourceInfo.priority,
                [type]: {
                    url: sourceInfo.url,
                    tracking: sourceInfo.tracking
                }
            };
        });
    }

    /**
     *
     * @access private
     * @param {Object} [data={}] - playhead data object
     * @desc Constructs a SDK.Services.Media.MediaPlayhead instance based on the provided playhead data, returns null
     * if the data is not available. Please note, this method checks against the status property because
     * its guaranteed to exist if the playhead exists.
     * @note `last_updated` and `last_modified` and `content_id` are remapped to align with JS
     * and SDK conventions, services returns the variable names with underscores
     * @todo remove the default for lastUpdated when services are settled
     * @returns {SDK.Services.Media.MediaPlayhead|null}
     * @see https://github.bamtech.co/skynet/playback-orchestration-service/blob/master/docs/api/WEB.md
     *
     */
    getMediaPlayhead(data = {}) {
        const {
            position,
            last_updated: updated,
            last_modified: modified
        } = data;
        const { status, content_id: contentId } = data;

        const lastUpdated = updated || modified;

        const remap = {
            PlayheadFound: MediaPlayheadStatus.Success
        };

        const playheadStatus =
            MediaPlayheadStatus[status] ||
            remap[status] ||
            MediaPlayheadStatus.Unavailable;

        const options = {
            position,
            lastUpdated,
            status: playheadStatus,
            contentId
        };

        return new MediaPlayhead(options);
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @returns {SDK.QualityOfService.ServerRequest}
     *
     */
    getQosServerRequestData(dustLogUtility) {
        if (Check.not.instanceStrict(dustLogUtility, DustLogUtility)) {
            return new ServerRequest();
        }

        const {
            roundTripTime,
            host,
            path,
            method = '',
            statusCode
        } = dustLogUtility.server;

        let networkError;
        let status = FetchStatus.completed;

        if (statusCode >= 400) {
            if (statusCode === 401 || statusCode === 403) {
                networkError = NetworkError.prohibited;
            } else if (statusCode >= 500) {
                networkError = NetworkError.notConnected;
                status = FetchStatus.noNetwork;
            } else {
                networkError = NetworkError.unknown;
            }
        }

        return new ServerRequest({
            host,
            path,
            statusCode,
            roundTripTime,
            method: QosHttpMethod[method.toLowerCase()],
            status,
            error: networkError
        });
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Media.MediaThumbnailLink} thumbnailLink
     * @param {String} resolution
     * @returns {String}
     *
     */
    createThumbnailUrl(thumbnailLink, resolution) {
        const queryChar = thumbnailLink.href.includes('?') ? '&' : '?';
        const thumbnailResolution = resolution
            ? `resolution=${resolution}`
            : null;
        const queryString = thumbnailResolution
            ? `${queryChar}${thumbnailResolution}`
            : '';

        return `${thumbnailLink.href}${queryString}`;
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @param {SDK.Services.Exception.ServiceException} exception
     * @returns {SDK.Services.QualityOfService.MediaFetchError}
     *
     */
    constructErrorFromMediaPayloadException(exception) {
        return (
            MediaFetchErrorMapping[exception.name] ||
            MediaFetchErrorMapping.default
        );
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} [options.accessToken]
     * @param {SDK.Services.Media.MediaClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @param {String} [options.bodyType] - The expected response data type, executed after initial JSON attempt.
     * @returns {Object} The payload for the client request.
     *
     */
    getPayload(options) {
        const { accessToken, endpointKey, data = {}, body, bodyType } = options;
        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { href, optionalHeaders } = endpoint;
        const headers = data.headers
            ? { ...data.headers, ...endpoint.headers }
            : endpoint.headers;
        const url = data.url ? data.url : href;
        const requestBody = body ? JSON.stringify(body) : undefined;

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken?.token
                    };
                },
                'X-Bamtech-Playback-Experience-Context': () => {
                    return {
                        replacer: '{qcPlaybackExperienceContext}',
                        value: data.qcPlaybackExperienceContext
                    };
                }
            },
            headers,
            optionalHeaders
        );

        return {
            url,
            body: requestBody,
            bodyType,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaClient';
    }

    // #endregion
}
