/**
 *
 * @module graphQlQuery
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import queryString from 'query-string';
import HttpMethod from './../services/configuration/httpMethod';
import QueryBuilder from './../services/content/queryBuilder';

import appendQuerystring from '../services/util/appendQuerystring';

export default class GraphQlQuery extends QueryBuilder {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public query: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public operationName: string;

    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public variables: unknown;

    /**
     *
     * @param {String} query
     * @param {String} [operationName=null]
     * @param {Object} [variables=null]
     *
     */
    public constructor(
        query: string,
        operationName: Nullable<string> = null,
        variables: unknown = null
    ) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                query: Types.nonEmptyString,
                operationName: Types.string.optional,
                variables: Types.object().optional
            };

            typecheck(this, params, arguments);
        }

        this.query = query;
        this.operationName = operationName || '';
        this.variables = variables || {};
    }

    /**
     *
     * @access public
     * @param {String} url - The URL for which to build the query.
     * @param {HttpMethod} method - The method for which to build the query.
     * @desc Returns the URL for the query.
     * @returns {String} The URL for the query.
     *
     */
    public override createQueryUrl(
        url: string,
        method: keyof typeof HttpMethod
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                url: Types.nonEmptyString,
                method: Types.in(HttpMethod)
            };

            typecheck(this, params, arguments);
        }

        let queryUrl = url;

        if (method === HttpMethod.GET) {
            const urlQueryString = queryString.stringify({
                query: this.query || '',
                operationName: this.operationName || '',
                variables: this.variables ? JSON.stringify(this.variables) : ''
            });

            queryUrl = appendQuerystring(queryUrl, urlQueryString);
        }

        return queryUrl;
    }

    /**
     *
     * @access private
     * @desc Returns the post content for the query.
     * @returns {String} The post content for the query.
     *
     */
    public override createPostContent() {
        return JSON.stringify(this);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.GraphQlQuery';
    }
}
