/**
 *
 * @module playerSeekDirection
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlayerSeekDirection
 * @desc Information about the player seek direction.
 *
 * @property {String} forward - `Since 13.0.0` - User is seeking towards live playback.
 * @property {String} backward - `Since 13.0.0` - User is seeking backwards from live event.
 * @property {String} same - `Since 13.0.0` - Odd edge-case here. But some players don't prevent you from seeking to the current position.
 *
 * @example
 * SDK.Services.QualityOfService.PlayerSeekDirection.forward // 'forward'
 *
 */
export default keyMirror({
    forward: null,
    backward: null,
    same: null
});
