/**
 *
 * @module mediaPreferences
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AudioType from '../services/media/audioType';
import MediaQuality from '../services/media/mediaQuality';
import EncryptionMode from '../services/media/encryptionMode';
import Protocol from '../services/media/protocol';
import SupportedCodec from '../services/media/supportedCodec';
import VideoResolution from '../services/media/videoResolution';

/**
 *
 * @since 4.2.0
 * @desc Object of media related preference enums provided by the app developer, used in scenario string manipulation
 *
 */
export default class MediaPreferences {
    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String<SDK.Services.Media.MediaQuality>|undefined}
     * @desc enum to indicate media quality in playback selection
     *
     */
    public preferredMediaQuality?: ValueOf<typeof MediaQuality>;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String<SDK.Services.Media.AudioType>|undefined}
     * @desc enum to indicate audio quality in playback selection
     *
     */
    public preferredAudioType?: ValueOf<typeof AudioType>;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String<SDK.Services.Media.EncryptionMode>|undefined}
     * @desc enum to indicate DRM\ encryption type in base device playback name
     *
     */
    public preferredEncryptionMode?: ValueOf<typeof EncryptionMode>;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String<SDK.Services.Media.Protocol>|undefined}
     * @desc enum to indicate HTTPS protocol in playback selection. HTTP is default
     * in all base device maps in Playback Selection Service
     *
     */
    public preferredProtocol?: ValueOf<typeof Protocol>;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {Number|undefined}
     * @desc Number to indicate frameRate in playback selection.
     *
     */
    public preferredFrameRate?: number;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {SDK.Services.Media.VideoResolution|undefined}
     * @desc enum to indicate resolution in playback selection.
     *
     */
    public preferredResolution?: VideoResolution;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Optional: String to indicate the AdPartner used during media requests.
     * @note Allow clients to switch ad partners at runtime.
     *
     */
    public preferredAdPartner?: string;

    /**
     *
     * @access public
     * @since 10.0.0
     * @type {String|undefined}
     * @desc Optional: String provided by qc viewer team to switch bumpers for the playback experience.
     *
     */
    public qcPlaybackExperienceContext?: string;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {String<SDK.Services.Media.SupportedCodec>|undefined}
     * @desc Optional: enum to indicate the preferred codec in playback selection.
     * @note When not null, this value overrides the codecs returned by MediaCapabilitiesProvider.getSupportedCodecs() during playback selection.
     *
     */
    public preferredCodec?: ValueOf<typeof SupportedCodec>;

    /**
     *
     * @param {Object} [options]
     * @param {String<SDK.Services.Media.MediaQuality>} [options.preferredMediaQuality] - Optional: enum to indicate media quality in
     * playback selection
     * @param {String<SDK.Services.Media.AudioType>} [options.preferredAudioType] - Optional: enum to indicate audio quality in
     * playback selection
     * @param {String<SDK.Services.Media.EncryptionMode>} [options.preferredEncryptionMode] - Optional: enum to indicate DRM
     * encryption type in base device playback name
     * @param {String<SDK.Services.Media.Protocol>} [options.preferredProtocol] - Optional: enum to indicate HTTPS protocol in
     * playback selection. HTTP is default in all base device maps in Playback Selection Service
     * @param {Number} [options.preferredFrameRate] - Optional: Number to indicate frameRate in playback selection.
     * @param {SDK.Services.Media.VideoResolution} [options.preferredResolution] - Optional: enum to indicate resolution
     * @param {String} [options.preferredAdPartner] - Optional: String to indicate the AdPartner used during media requests.
     * @param {String} [options.qcPlaybackExperienceContext] - Optional: String provided by qc viewer team to switch bumpers for the playback experience.
     * @note Sent as property of `X-Bamtech-Playback-Experience-Context` header if present
     * @param {String<SDK.Services.Media.SupportedCodec>} [options.preferredCodec] - Optional: enum to indicate the preferred codec in playback selection.
     * @note When not null, this value overrides the codecs returned by MediaCapabilitiesProvider.getSupportedCodecs() during playback selection.
     *
     *
     */
    public constructor(options?: {
        preferredMediaQuality?: ValueOf<typeof MediaQuality>;
        preferredAudioType?: ValueOf<typeof AudioType>;
        preferredEncryptionMode?: ValueOf<typeof EncryptionMode>;
        preferredProtocol?: ValueOf<typeof Protocol>;
        preferredFrameRate?: number;
        preferredResolution?: VideoResolution;
        preferredAdPartner?: string;
        qcPlaybackExperienceContext?: string;
        preferredCodec?: ValueOf<typeof SupportedCodec>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    preferredMediaQuality: Types.in(MediaQuality).optional,
                    preferredAudioType: Types.in(AudioType).optional,
                    preferredEncryptionMode: Types.in(EncryptionMode).optional,
                    preferredProtocol: Types.in(Protocol).optional,
                    preferredFrameRate: Types.number.optional,
                    preferredResolution:
                        Types.instanceStrict(VideoResolution).optional,
                    preferredAdPartner: Types.nonEmptyString.optional,
                    qcPlaybackExperienceContext: Types.string.optional,
                    preferredCodec: Types.in(SupportedCodec).optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            preferredMediaQuality,
            preferredAudioType,
            preferredEncryptionMode,
            preferredProtocol,
            preferredFrameRate,
            preferredResolution,
            preferredAdPartner,
            qcPlaybackExperienceContext,
            preferredCodec
        } = options || {};

        this.preferredMediaQuality = preferredMediaQuality;
        this.preferredAudioType = preferredAudioType;
        this.preferredEncryptionMode = preferredEncryptionMode;
        this.preferredProtocol = preferredProtocol;
        this.preferredFrameRate = preferredFrameRate;
        this.preferredResolution = preferredResolution;
        this.preferredAdPartner = preferredAdPartner;
        this.qcPlaybackExperienceContext = qcPlaybackExperienceContext;
        this.preferredCodec = preferredCodec;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaPreferences';
    }
}
