/**
 *
 * @module playbackContext
 *
 */

import { v4 as uuidv4 } from 'uuid';
import { Types, typecheck, Check } from '@dss/type-checking';

import PlaybackIntent from '../qualityOfService/playbackIntent';
import ProductType from '../qualityOfService/productType';

/**
 *
 * @since 4.0.0
 *
 */
export default class PlaybackContext {
    /**
     *
     * @param {Object} options
     * @param {String} [options.interactionId]
     * @param {Boolean} [options.offline=false]
     * @param {String<SDK.Services.QualityOfService.PlaybackIntent>} options.playbackIntent
     * @param {String<SDK.Services.QualityOfService.ProductType>} options.productType
     * @param {Boolean} options.isPreBuffering
     * @param {Boolean} options.disablePlaybackSnapshotEventsOverride
     * @param {Boolean} options.snapshotEventFeatureFlagEnabled
     * @param {Boolean} [options.artificialDelay=null]
     * @param {String} [options.pqmGroupId]
     * @param {String} [options.pqmVersion]
     * @param {Object} [options.startupContext={}]
     * @param {Object} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    interactionId: Types.nonEmptyString.optional,
                    offline: Types.boolean.optional,
                    playbackIntent: Types.in(PlaybackIntent),
                    productType: Types.in(ProductType),
                    isPreBuffering: Types.boolean,
                    disablePlaybackSnapshotEventsOverride: Types.boolean,
                    snapshotEventFeatureFlagEnabled: Types.boolean,
                    artificialDelay: Types.boolean.optional,
                    pqmGroupId: Types.nonEmptyString.optional,
                    pqmVersion: Types.nonEmptyString.optional,
                    startupContext: Types.object().optional,
                    contentKeys: Types.object(),
                    data: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            interactionId,
            offline,
            playbackIntent,
            productType,
            isPreBuffering,
            disablePlaybackSnapshotEventsOverride,
            snapshotEventFeatureFlagEnabled,
            artificialDelay,
            pqmGroupId,
            pqmVersion,
            startupContext,
            contentKeys,
            data
        } = options;

        /**
         *
         * @access public
         * @since 4.5.0
         * @type {String|undefined}
         * @desc The client-side generated unique ID representing a single element interaction within the container. The interactionId
         * will correspond with one of the defined interactionTypes. The interactionId will be used as the primary key for all interactions.
         * @note Map from the client provided value in the constructor of this object. Service property will be interaction_id.
         *
         */
        this.interactionId = interactionId;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Boolean}
         * @desc Indicates that the media is offline/previously downloaded.
         *
         */
        this.offline = offline || false;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String}
         * @desc The SDK generated unique ID for the playback session.
         * @note Generate a unique ID in the constructor of this object.
         *
         */
        this.playbackSessionId = uuidv4();

        /**
         *
         * @access public
         * @since 14.0.0
         * @type {String<SDK.Services.QualityOfService.PlaybackIntent>}
         * @desc Indicates the intent which started playback.
         *
         */
        this.playbackIntent = playbackIntent;

        /**
         *
         * @access public
         * @since 14.0.0
         * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
         * @desc Indicates the type of product (Live or VOD).
         *
         */
        this.productType = productType;

        /**
         *
         * @access public
         * @since 14.0.0
         * @type {Boolean}
         * @desc Indicates that pre buffering is occurring if true (the video will be loaded before we are ready to show it).
         *
         */
        this.isPreBuffering = isPreBuffering;

        /**
         *
         * @access public
         * @since 14.0.0
         * @type {Object}
         * @desc A collection of associated content identifiers for the media, such as `contentId` and `mediaId`.
         *
         */
        this.contentKeys = contentKeys || {};

        /**
         *
         * @access public
         * @since 14.0.0
         * @type {Object}
         * @desc Partner specific data to provide additional context for all playback events.
         *
         */
        this.data = data || {};

        /**
         *
         * @access public
         * @since 15.0.0
         * @type {Boolean|null}
         * @description Indicates that a delay occurred / will occur during the startup sequence of playing content, such as
         * displaying the Negative Stereotype Advisory warning.
         *
         */
        this.artificialDelay = Check.assigned(artificialDelay)
            ? artificialDelay
            : null;

        /**
         *
         * @access protected
         * @since 15.0.0
         * @type {Number}
         * @desc Time when playback was initially requested in milliseconds.
         * @note this value is set in `MediaApi.fetch()`
         *
         */
        this.playbackRequestedAtTimestamp = null;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {Object}
         * @desc Associated key-value pairs to be serialized with all QoE startup events. This should
         * include data such as the playbackConfigVersions received from the Playback Configuration Service (PCS).
         * @note This should be provided by the client via `SDK.Media.MediaApi.initializePlaybackContext()`.
         *
         */
        this.startupContext = startupContext || {};

        /**
         *
         * @access public
         * @since 20.0.0
         * @type {Boolean}
         * @desc An application override to explicitly disable the Playback QoE PlaybackSnapshot event.
         *
         */
        this.disablePlaybackSnapshotEventsOverride =
            disablePlaybackSnapshotEventsOverride;

        /**
         *
         * @access public
         * @since 20.0.0
         * @type {Boolean}
         * @desc A flag representing whether the `wpnx-playback-snapshot-events-enabled` orchestration feature flag was
         * present and contained a `true` value.
         *
         */
        this.snapshotEventFeatureFlagEnabled = snapshotEventFeatureFlagEnabled;

        /**
         *
         * @access public
         * @since 20.0.0
         * @type {String|undefined}
         * @desc The binary version of the Playback Quality Manager (PQM) currently used in this playback session.
         *
         */
        this.pqmGroupId = pqmGroupId;

        /**
         *
         * @access public
         * @since 20.0.0
         * @type {String|undefined}
         * @desc Used in PQM to distinguish different client-side ABR algorithm experiment groups.
         *
         */
        this.pqmVersion = pqmVersion;
    }

    // #region protected

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Sets values for `playbackRequestedAtTimestamp`.
     *
     */
    setPlaybackRequestedAtTimestamp() {
        this.playbackRequestedAtTimestamp = Date.now();
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackContext';
    }

    // #endregion
}
