/**
 *
 * @module entry
 * @desc {SDK.Services.Eligibility} entry point.
 *
 */

import EligibilityClient from './eligibilityClient';
import EligibilityStatus from './eligibilityStatus';
import EligibilityStatusResponse from './eligibilityStatusResponse';
import Partner from './partner';

/**
 *
 * @typedef {Object} SDK.Services.Eligibility
 *
 * @property {EligibilityClient} EligibilityClient
 * @property {SDK.Services.Eligibility.EligibilityStatus} EligibilityStatus
 * @property {EligibilityStatusResponse} EligibilityStatusResponse
 * @property {Partner} Partner
 *
 */
export default {
    EligibilityClient,
    EligibilityStatus,
    EligibilityStatusResponse,
    Partner
};
