/**
 *
 * @module ripcutLabelConfigurationRequestMode
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.RipcutLabelConfigurationRequestMode
 * @desc Specifies label configuration (badging or compose) for specific details on supported labels for the partner.
 *
 * @property {String} badgingConfig - Since `17.0.0` - A label's configuration used by Ripcut. The returned configuration includes all supported labels and their details such as language, mapping to background image width, and default placement.
 * @property {String} composeConfig - Since `17.0.0`- A label's configuration for compose endpoint. The returned configuration includes all supported labels for requested partner.
 *
 * @example
 * SDK.Ripcut.RipcutLabelConfigurationRequestMode.badgingConfig // 'badgingConfig'
 *
 */
export default keymirror({
    badgingConfig: null,
    composeConfig: null
});
