/**
 *
 * @module externalActivationManagerConfiguration
 *
 */

import ExternalActivationClientConfiguration from '../externalActivation/externalActivationClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for external activation.
 *
 */
export default class ExternalActivationManagerConfiguration extends ServiceConfiguration<ExternalActivationClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.ExternalActivationManagerConfiguration';
    }

    // #endregion
}
