/**
 *
 * @module paywallSubscription
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import SubscriptionProvider from '../../subscription/subscriptionProvider';
import { SubscriptionSourceType } from '../subscription/enums';
import PaymentPeriod from './paymentPeriod';

/**
 *
 * @since 4.9.0
 *
 */
export default class PaywallSubscription {
    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String<SDK.Services.Subscription.SubscriptionSourceType>}
     *
     */
    public sourceType: keyof typeof SubscriptionSourceType;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String<SDK.Subscription.SubscriptionProvider>}
     *
     */
    public sourceProvider: keyof typeof SubscriptionProvider;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String<SDK.Services.Paywall.PaymentPeriod>}
     * @note PaymentPeriod is a flexible enum.
     *
     */
    public paymentPeriod: ValueOf<typeof PaymentPeriod>;

    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.Subscription.SubscriptionSourceType>} options.sourceType
     * @param {String<SDK.Subscription.SubscriptionProvider>} options.sourceProvider
     * @param {String<SDK.Services.Paywall.PaymentPeriod>} options.paymentPeriod
     * @note PaymentPeriod, SubscriptionProvider and SubscriptionSourceType are flexible enums.
     *
     */
    public constructor(options: {
        sourceType: keyof typeof SubscriptionSourceType;
        sourceProvider: keyof typeof SubscriptionProvider;
        paymentPeriod: ValueOf<typeof PaymentPeriod>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sourceType: Types.nonEmptyString, // Types.in(SubscriptionSourceType).or.nonEmptyString,
                    sourceProvider: Types.nonEmptyString, // Types.in(SubscriptionProvider).or.nonEmptyString,
                    paymentPeriod: Types.nonEmptyString // Types.in(PaymentPeriod).or.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { sourceType, sourceProvider, paymentPeriod } = options;

        this.sourceType = sourceType;
        this.sourceProvider = sourceProvider;
        this.paymentPeriod = paymentPeriod;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.PaywallSubscription';
    }
}
