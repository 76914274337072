/**
 *
 * @module orchestrationApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/orchestration.md
 * @see https://github.bamtech.co/userservices/orchestration-api
 * @see https://github.bamtech.co/pages/userservices/orchestration-api-explorer/
 * @see https://docs.google.com/presentation/d/1SMBHXuYtxoJnl6QEobkY_gfitZugO62zbVqHaS7CBJU/edit#slide=id.g10a91c03c5d_0_0
 * @see https://wiki.disneystreaming.com/display/SDKC/ESPN+OrchestrationApi+Explorer+tips+for+SDK+and+Client+developers
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import OrchestrationManager, { QueryOptions } from './orchestrationManager';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import Logger from './../logging/logger';

const OrchestrationApiDustUrnReference =
    DustUrnReference.orchestration.orchestrationApi;

const DustUrn = OrchestrationApiDustUrnReference;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.17.0
 * @desc Provides ability to access orchestration data.
 *
 */
export default class OrchestrationApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Orchestration.OrchestrationManager}
     *
     */
    private orchestrationManager: OrchestrationManager;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Orchestration.OrchestrationManager} options.orchestrationManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        logger: Logger;
        orchestrationManager: OrchestrationManager;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    orchestrationManager:
                        Types.instanceStrict(OrchestrationManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { orchestrationManager } = options;

        this.orchestrationManager = orchestrationManager;
        this.dustEnabled = this.orchestrationManager?.client?.dustEnabled;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {Object} options
     * @param {String} options.query - A custom query that returns only the specified set of data.
     * @param {String} options.operationName - The operation name.
     * @param {Object} [options.variables] - The query variables object.
     * @desc Allows a custom Orchestration query to be submitted and return only the data that was requested.
     * @throws {InvalidTokenException} The request is not authorized.
     * @throws {SDK.Services.Exception.OrchestrationExceptions} Exception cases for Orchestration requests.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that returns the Orchestration Object when the operation has succeeded.
     *
     * @example
     * const query = `query me {
     *    me {
     *      account {
     *        activeProfile {
     *          attributes {
     *            kidsModeEnabled
     *          }
     *        }
     *      }
     *    }
     * }`;
     *
     * const operationName = 'me';
     *
     * const data = await orchestrationApi.query<{ me: { account: { activeProfile: { attributes: { kidsModeEnabled: boolean } } } } } }>({ query, operationName });
     *
     * if (data.me.account.activeProfile.attributes.kidsModeEnabled) {
     *   // do something
     * }
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async query<T>(options: QueryOptions): Promise<T>;

    @apiMethodDecorator({
        skipDustLogUtility: true,
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object({
                query: Types.nonEmptyString,
                operationName: Types.nonEmptyString.warn, // do not throw an error, warn for backward compatibility
                variables: Types.object().optional
            })
        }
    })
    public async query<T>(apiOptions: unknown): Promise<T> {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions;

        const { operationName } = options;
        const { logger, dustEnabled } = this;

        const endpointKey = operationName || 'query';

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: OrchestrationApiDustUrnReference.getOperationUrn(endpointKey),
            endpointKey,
            logTransaction
        });

        try {
            return await this.orchestrationManager.query<T>(
                options,
                logTransaction
            );
        } catch (ex) {
            dustLogUtility.captureError(ex);

            throw ex;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Orchestration.OrchestrationApi';
    }
}
