/**
 *
 * @module paywallPlugin
 * @desc {SDK.Paywall.PaywallPlugin} entry point.
 *
 */

import Paywall from './entry';
import PaywallApi from './paywallApi';
import PaywallManager from './paywallManager';

import PaywallService from '../services/paywall/entry';
import PaywallClient from '../services/paywall/paywallClient';

import getSafe from '../services/util/getSafe';

import PaywallManagerConfiguration from './../services/configuration/paywallManagerConfiguration';
import PaywallClientConfiguration from './../services/paywall/paywallClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Paywall, 'Paywall', typeof PaywallService> = {
    pluginName: 'Paywall',
    entry: Paywall,
    service: PaywallService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;

        const { paywallManager, tokenManager } = sdkSession.managers;

        sdkSession.paywallApi = new PaywallApi({
            paywallManager,
            tokenManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services } = options;

        const { tokenManager } = managers;

        managers.paywallManager = new PaywallManager({
            config: services.paywall,
            client: new PaywallClient(
                services.paywall.client,
                logger,
                httpClient
            ),
            tokenManager,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new PaywallClientConfiguration(
            getSafe(() => services.paywall.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                services.paywall.client.endpoints
            )
        );

        serviceDefinitionsConfiguration.paywall =
            new PaywallManagerConfiguration(
                clientConfiguration,
                undefined,
                services.paywall.disabled
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
