/**
 *
 * @module entry
 * @desc {SDK.Eligibility} entry point.
 *
 */

import EligibilityManager from './eligibilityManager';

/**
 *
 * @typedef {Object} SDK.Eligibility
 *
 * @property {EligibilityManager} EligibilityManager
 *
 */
export default {
    EligibilityManager
};
