/**
 *
 * @module mediaManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playback-session.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playhead.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import DrmManager from '../drm/drmManager';
import TokenManager from '../token/tokenManager';
import TelemetryManager from '../internal/telemetry/telemetryManager';
import PlatformMetricsProvider from '../platform/platformMetricsProvider';
import MediaItem from './mediaItem';
import MediaCapabilitiesProvider from './mediaCapabilitiesProvider';
import MediaDescriptor from './mediaDescriptor';
import PlaybackTelemetryDispatcher from './playbackTelemetryDispatcher';
import PlaybackTelemetryConfiguration from './playbackTelemetryConfiguration';
import PlaybackSession from './playbackSession';
import AuthCookieProvider from './authCookieProvider';
import AdEngineManager from './adEngine/adEngineManager';

import AccessToken from '../services/token/accessToken';
import CodecAttributes from '../services/media/codecAttributes';
import MediaClient from '../services/media/mediaClient';
import MediaManagerConfiguration from '../services/configuration/mediaManagerConfiguration';
import MediaPlaybackSelectionPayload from '../services/media/mediaPlaybackSelectionPayload';
import PlaybackContext from '../services/media/playbackContext';
import PlaybackSelectionAdTracking from '../services/media/playbackSelectionAdTracking';
import PlaybackSelectionAttributes from '../services/media/playbackSelectionAttributes';
import PlaybackSelectionProperties from '../services/media/playbackSelectionProperties';
import PlaybackSelectionTracking from '../services/media/playbackSelectionTracking';
import PlaylistType from '../services/media/playlistType';
import AdvertisingIdProvider from '../advertising/advertisingIdProvider';

import LogTransaction from '../logging/logTransaction';

import {
    AssetInsertionStrategy,
    PlaybackInitiationContext,
    ThumbnailResolution
} from './enums';

import getSafe from '../services/util/getSafe';
import PlayerAdapter from './playerAdapter';
import DrmType from '../services/media/drmType';
import Presentation from '../services/media/presentation';

/**
 *
 * @access protected
 * @desc Manages a media player to help load, control, and monitor playback on your behalf.
 *
 */
export default class MediaManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.MediaManagerConfiguration}
     *
     */
    public config: MediaManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {SDK.Drm.DrmManager}
     *
     */
    private drmManager: DrmManager;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.TelemetryManager}
     *
     */
    private telemetryManager: TelemetryManager;

    /**
     *
     * @access private
     * @type {SDK.Media.AdEngine.AdEngineManager}
     *
     */
    private adEngineManager: AdEngineManager;

    /**
     *
     * @access private
     * @type {SDK.Media.MediaCapabilitiesProvider}
     *
     */
    private mediaCapabilitiesProvider: MediaCapabilitiesProvider;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Platform.PlatformMetricsProvider}
     *
     */
    private platformMetricsProvider: PlatformMetricsProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.Media.MediaClient}
     *
     */
    public client: MediaClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {SDK.Advertising.AdvertisingIdProvider}
     *
     */
    private advertisingIdProvider: AdvertisingIdProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @since 4.0.0
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.MediaManagerConfiguration} options.mediaManagerConfiguration
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Drm.DrmManager} options.drmManager
     * @param {SDK.Internal.Telemetry.TelemetryManager} options.telemetryManager
     * @param {SDK.Media.MediaCapabilitiesProvider} [options.mediaCapabilitiesProvider]
     * @param {SDK.Services.Media.MediaClient} options.mediaClient
     * @param {SDK.Media.AdEngine.AdEngineManager} options.adEngineManager
     * @param {SDK.Platform.PlatformMetricsProvider} options.platformMetricsProvider
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Advertising.AdvertisingIdProvider} options.advertisingIdProvider
     *
     */
    public constructor(options: {
        mediaManagerConfiguration: MediaManagerConfiguration;
        tokenManager: TokenManager;
        drmManager: DrmManager;
        telemetryManager: TelemetryManager;
        mediaCapabilitiesProvider?: MediaCapabilitiesProvider;
        mediaClient: MediaClient;
        adEngineManager: AdEngineManager;
        platformMetricsProvider: PlatformMetricsProvider;
        logger: Logger;
        advertisingIdProvider: AdvertisingIdProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaManagerConfiguration: Types.instanceStrict(
                        MediaManagerConfiguration
                    ),
                    tokenManager: Types.instanceStrict(TokenManager),
                    drmManager: Types.instanceStrict(DrmManager),
                    telemetryManager: Types.instanceStrict(TelemetryManager),
                    mediaCapabilitiesProvider: Types.instanceStrict(
                        MediaCapabilitiesProvider
                    ).optional,
                    mediaClient: Types.instanceStrict(MediaClient),
                    adEngineManager: Types.instanceStrict(AdEngineManager),
                    platformMetricsProvider: Types.instanceStrict(
                        PlatformMetricsProvider
                    ),
                    logger: Types.instanceStrict(Logger),
                    advertisingIdProvider: Types.instanceStrict(
                        AdvertisingIdProvider
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            mediaManagerConfiguration,
            tokenManager,
            drmManager,
            telemetryManager,
            mediaCapabilitiesProvider,
            platformMetricsProvider,
            mediaClient,
            adEngineManager,
            logger,
            advertisingIdProvider
        } = options;

        this.config = mediaManagerConfiguration;

        this.tokenManager = tokenManager;

        this.drmManager = drmManager;

        this.telemetryManager = telemetryManager;

        this.adEngineManager = adEngineManager;

        this.mediaCapabilitiesProvider =
            mediaCapabilitiesProvider || new MediaCapabilitiesProvider(logger);

        this.platformMetricsProvider = platformMetricsProvider;

        this.client = mediaClient;

        this.logger = logger;

        this.advertisingIdProvider = advertisingIdProvider;

        this.dustEnabled = getSafe(() => this.client.dustEnabled, false);

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Media.MediaDescriptor} options.mediaDescriptor
     * @param {SDK.Services.Media.PlaylistType} options.preferredPlaylistType
     * @param {SDK.Services.Media.PlaybackContext} [options.playbackContext]
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves a media item, based on the descriptor.
     * @returns {Promise<SDK.Media.MediaItem>}
     *
     */
    public async getMediaItem(options: {
        mediaDescriptor: MediaDescriptor;
        preferredPlaylistType: ValueOf<typeof PlaylistType>;
        playbackContext?: PlaybackContext;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaDescriptor: Types.instanceStrict(MediaDescriptor),
                    preferredPlaylistType: Types.in(PlaylistType),
                    playbackContext:
                        Types.instanceStrict(PlaybackContext).optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getMediaItem', params, arguments);
        }

        const {
            mediaDescriptor,
            preferredPlaylistType,
            playbackContext,
            logTransaction
        } = options;
        const { accessToken, client } = this;
        const { mediaPreferences } = mediaDescriptor;
        const { qcPlaybackExperienceContext } = mediaPreferences || {};

        const baseDeviceCapability = this.deriveBaseDeviceCapability(
            mediaDescriptor
        ) as string;

        const playbackSelectionPayload = this.derivePlaybackSelectionAttributes(
            mediaDescriptor,
            playbackContext
        );

        const mediaPayload = await client.mediaPayload({
            playbackUrl: mediaDescriptor.playbackUrl,
            accessToken,
            baseDeviceCapability,
            playbackSelectionPayload,
            preferredPlaylistType,
            qcPlaybackExperienceContext,
            logTransaction,
            playbackContext
        });

        const mediaItem = new MediaItem({
            mediaPayload,
            mediaDescriptor,
            preferredPlaylistType,
            playbackContext,
            insertion: mediaPayload.insertion
        });

        return mediaItem;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.PlayerAdapter} playerAdapter - The player adapter you would like to create a new media session
     * for.
     * @param {Array<SDK.Services.Media.DrmType>} [priorityDrms=null] - override drm with a specified drm or order
     * of Drms
     * @returns {Promise<PlaybackSession>} Injected with all dependencies.
     * @example const playbackSession = await mediaManager.createPlaybackSession(playerAdapter);
     *
     */
    public async createPlaybackSession(
        playerAdapter: PlayerAdapter,
        priorityDrms: Nullable<Array<keyof typeof DrmType>> = null
    ) {
        const {
            config,
            drmManager,
            tokenManager,
            telemetryManager,
            adEngineManager,
            logger,
            platformMetricsProvider,
            dustEnabled
        } = this;

        const { extras } = config;

        const authCookieProvider = new AuthCookieProvider(this);
        const streamSampleInterval = getSafe(
            () => config.extras.playbackSession.streamSampleInterval
        ) as number;
        const playbackTelemetryConfiguration =
            new PlaybackTelemetryConfiguration(streamSampleInterval);

        const telemetryDispatcher = new PlaybackTelemetryDispatcher({
            playbackTelemetryConfiguration,
            telemetryManager,
            logger,
            platformMetricsProvider
        });

        playerAdapter.cdnFallback = extras.cdnFallback;

        logger.info(this.toString(), 'Create playback session.');

        const options = {
            tokenManager,
            drmManager,
            mediaManager: this,
            telemetryDispatcher,
            logger,
            playerAdapter,
            authCookieProvider,
            adEngineManager,
            priorityDrms,
            dustEnabled
        };

        return new PlaybackSession(options);
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates an auth cookie
     * @returns {Promise<Void>}
     *
     */
    public async createAuthCookie(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createAuthCookie', params, arguments);
        }

        return await this.client.createAuthCookie(accessToken, logTransaction);
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Derives the playback attributes for selection based on the media descriptor.
     * @param {SDK.Media.MediaDescriptor} mediaDescriptor
     * @returns {String}
     *
     */
    public deriveBaseDeviceCapability(mediaDescriptor: MediaDescriptor) {
        const { config } = this;
        const { extras } = config;
        const { playbackEncryptionDefault, mediaQualityDefault } = extras;
        const { mediaPreferences, baseDeviceCapabilityOverride } =
            mediaDescriptor;
        const { preferredEncryptionMode, preferredMediaQuality } =
            mediaPreferences || {};

        if (Check.assigned(baseDeviceCapabilityOverride)) {
            return baseDeviceCapabilityOverride;
        }

        let baseDeviceCapability;

        if (Check.not.assigned(preferredEncryptionMode)) {
            baseDeviceCapability = playbackEncryptionDefault;
        } else {
            baseDeviceCapability = preferredEncryptionMode;
        }

        if (Check.not.assigned(preferredMediaQuality)) {
            baseDeviceCapability = `${baseDeviceCapability}-${mediaQualityDefault}`;
        } else {
            baseDeviceCapability = `${baseDeviceCapability}-${preferredMediaQuality}`;
        }

        return baseDeviceCapability;
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Derives the playback attributes for selection based on the media descriptor.
     * @param {SDK.Media.MediaDescriptor} mediaDescriptor
     * @param {SDK.Services.Media.PlaybackContext} [playbackContext]
     * @returns {SDK.Services.Media.MediaPlaybackSelectionPayload}
     *
     */
    public derivePlaybackSelectionAttributes(
        mediaDescriptor: MediaDescriptor,
        playbackContext?: PlaybackContext
    ) {
        const { mediaCapabilitiesProvider, config } = this;
        const { extras } = config;
        const {
            playlistUrlHttpProtocolDefault,
            adPartner,
            isContentAccessRestricted
        } = extras;
        const {
            mediaPreferences,
            assetInsertionStrategy,
            playbackInitiationContext
        } = mediaDescriptor;
        const { playbackSessionId } = playbackContext || {};
        const { preferredResolution, preferredAudioType, preferredAdPartner } =
            mediaPreferences || {};
        const { preferredProtocol, preferredFrameRate, preferredCodec } =
            mediaPreferences || {};

        const frameRates = preferredFrameRate
            ? [preferredFrameRate]
            : undefined;
        const protocol = preferredProtocol || playlistUrlHttpProtocolDefault;
        const slugDuration = mediaCapabilitiesProvider.getSlugDuration();

        let ads;
        let audioTypes;
        let codecs;
        let hdrTypes;
        let context;

        if (!isContentAccessRestricted) {
            const supportedHdrTypes =
                mediaCapabilitiesProvider.getSupportedHdrTypes();
            const supportsAtmos = mediaCapabilitiesProvider.supportsAtmos();

            audioTypes =
                preferredAudioType && supportsAtmos
                    ? [preferredAudioType]
                    : undefined;
            hdrTypes = Check.nonEmptyArray(supportedHdrTypes)
                ? supportedHdrTypes
                : undefined;

            if (preferredCodec) {
                codecs = new CodecAttributes([preferredCodec]);
            } else {
                const supportedCodecs =
                    mediaCapabilitiesProvider.getSupportedCodecs();

                codecs = Check.nonEmptyArray(supportedCodecs)
                    ? new CodecAttributes(supportedCodecs)
                    : undefined;
            }
        }

        if (assetInsertionStrategy === AssetInsertionStrategy.ADPARTNER) {
            ads = preferredAdPartner || adPartner || 'adengine';
        } else {
            context =
                playbackInitiationContext || PlaybackInitiationContext.ONLINE;
        }

        const attributes = new PlaybackSelectionAttributes({
            audioTypes,
            hdrTypes,
            resolution: preferredResolution,
            codecs,
            protocol,
            ads,
            frameRates,
            assetInsertionStrategy,
            playbackInitiationContext: context,
            slugDuration
        });

        const limitAdTracking =
            this.advertisingIdProvider.getLimitAdTrackingEnabled();
        const advertisingId = this.advertisingIdProvider.getId();

        const adTracking = new PlaybackSelectionAdTracking(
            limitAdTracking,
            advertisingId
        );
        const tracking = playbackSessionId
            ? new PlaybackSelectionTracking(playbackSessionId)
            : undefined;
        const playback = new PlaybackSelectionProperties(
            attributes,
            adTracking,
            tracking
        );

        return new MediaPlaybackSelectionPayload(playback);
    }

    /**
     *
     * @access private
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to
     * Fetch.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Fetches the details about the spritesheet thumbnail images that players can show to users when scrubbing
     * video.
     * @returns {Promise<Array<SDK.Services.Media.SpriteThumbnailSet>>}
     *
     */
    public getSpriteSheetThumbnailSets(
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        const { accessToken, client } = this;

        const thumbnailLink = getSafe(
            () => mediaItem.payload.thumbnails.spritesheet
        );

        const options = {
            thumbnailLink,
            accessToken,
            logTransaction
        };

        return client.getSpriteSheetThumbnails(options);
    }

    /**
     *
     * @access private
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to
     * Fetch.
     * @param {String} resolution - The desired resolution of thumbnails to fetch
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Fetches the details about the spritesheet thumbnail images that players can show to users when scrubbing
     * video.
     * @returns {Promise<SDK.Services.Media.SpriteThumbnailSet>}
     *
     */
    public async getSpriteSheetThumbnailSet(
        mediaItem: MediaItem,
        resolution: string,
        logTransaction: LogTransaction
    ) {
        const { accessToken } = this;

        const thumbnailLink = getSafe(
            () => mediaItem.payload.thumbnails.spritesheet
        );

        const options = {
            thumbnailLink,
            resolution,
            accessToken,
            logTransaction
        };

        const thumbnailSets = await this.client.getSpriteSheetThumbnails(
            options
        );

        return thumbnailSets[0];
    }

    /**
     *
     * @access private
     * @since 3.8.0
     * @param {SDK.Services.Media.Presentation} presentation - The thumbnail presentation to download. Typically chosen
     * by desired resolution.
     * @param {Number} index - The index of the thumbnail in the pod to download.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Fetches the details about the spritesheet thumbnail images that players can show to users when scrubbing
     * video.
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public getSpriteSheetThumbnail(
        presentation: Presentation,
        index: number,
        logTransaction: LogTransaction
    ) {
        const { client } = this;

        return client.getSpriteSheetThumbnail(
            presentation,
            index,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to
     * Fetch.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Fetches the details about the BIF thumbnail images that players can show to users when scrubbing video.
     * @returns {Promise<Array<SDK.Services.Media.BifThumbnailSet>>}
     *
     */
    public getBifThumbnailSets(
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        const { accessToken, client } = this;

        const thumbnailLink = getSafe(() => mediaItem.payload.thumbnails.bif);

        const options = {
            thumbnailLink,
            accessToken,
            logTransaction
        };

        return client.getBifThumbnails(options);
    }

    /**
     *
     * @access private
     * @since 3.8.0
     * @param {SDK.Media.MediaItem} mediaItem - The MediaItem for the current media that was returned by a call to
     * Fetch.
     * @param {String<SDK.Media.ThumbnailResolution>} resolution - The desired resolution of thumbnails to fetch.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Fetches the details about the BIF thumbnail images that players can show to users when scrubbing video.
     * @returns {Promise<SDK.Services.Media.BifThumbnailSet>}
     *
     */
    public async getBifThumbnailSet(
        mediaItem: MediaItem,
        resolution: ThumbnailResolution,
        logTransaction: LogTransaction
    ) {
        const { accessToken } = this;

        const thumbnailLink = getSafe(() => mediaItem.payload.thumbnails.bif);

        const options = {
            thumbnailLink,
            resolution,
            accessToken,
            logTransaction
        };

        const thumbnailSets = await this.client.getBifThumbnails(options);

        return thumbnailSets[0];
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaManager';
    }
}
