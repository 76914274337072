/**
 *
 * @module entry
 * @desc {SDK.Session} entry point.
 *
 */

import FeatureFlagsStorage from './featureFlagsStorage';
import SessionManager from './sessionManager';
import SessionInfoStorage from './sessionInfoStorage';

/**
 *
 * @typedef {Object} SDK.Session
 *
 * @property {FeatureFlagsStorage} FeatureFlagsStorage
 * @property {SessionManager} SessionManager
 * @property {SessionInfoStorage} SessionInfoStorage
 *
 */
export default {
    FeatureFlagsStorage,
    SessionManager,
    SessionInfoStorage
};
