/**
 *
 * @module entry
 * @desc {SDK.Commerce.PayPal} entry point.
 *
 */

import PayPalApi from './payPalApi';

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal
 *
 * @property {PayPalApi} PayPalApi
 *
 */
export default {
    PayPalApi
};
