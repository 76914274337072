/**
 *
 * @module externalActivationManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/external-activation.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import ExternalActivationClient from '../services/externalActivation/externalActivationClient';
import ExternalActivationManagerConfiguration from '../services/configuration/externalActivationManagerConfiguration';

import TokenManager from '../token/tokenManager';
import LogTransaction from '../logging/logTransaction';
import AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @since 4.2.0
 *
 */
export default class ExternalActivationManager {
    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Services.Configuration.ExternalActivationManagerConfiguration}
     *
     */
    private config: ExternalActivationManagerConfiguration;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Services.ExternalActivation.ExternalActivationClient}
     *
     */
    private client: ExternalActivationClient;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Services.Configuration.ExternalActivationManagerConfiguration} options.config
     * @param {SDK.Services.ExternalActivation.ExternalActivationClient} options.client
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        config: ExternalActivationManagerConfiguration;
        client: ExternalActivationClient;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        ExternalActivationManagerConfiguration
                    ),
                    client: Types.instanceStrict(ExternalActivationClient),
                    tokenManager: Types.instanceStrict(TokenManager),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, tokenManager, logger } = options;

        this.config = config;

        this.client = client;

        this.tokenManager = tokenManager;

        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} providerId
     * @param {String} activationTokenString
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note prior to multi-provider support, this method was named activateToken
     * the naming was updated in version 4.4.0
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>}
     *
     */
    public async redeemBundle(
        providerId: string,
        activationTokenString: string,
        logTransaction: LogTransaction
    ) {
        const externalActivationResult = await this.client.redeemBundle(
            providerId,
            activationTokenString,
            this.accessToken,
            logTransaction
        );

        const forceRefresh = externalActivationResult.refreshAccessToken;

        if (forceRefresh) {
            await this.tokenManager.refreshAccessToken({
                forceRefresh,
                logTransaction
            });
        }

        return externalActivationResult;
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {String} providerId
     * @param {String} activationTokenString
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>}
     *
     */
    public async redeemToken(
        providerId: string,
        activationTokenString: string,
        logTransaction: LogTransaction
    ) {
        const externalActivationResult = await this.client.redeemToken(
            providerId,
            activationTokenString,
            this.accessToken,
            logTransaction
        );

        const forceRefresh = externalActivationResult.refreshAccessToken;

        if (forceRefresh) {
            await this.tokenManager.refreshAccessToken({
                forceRefresh,
                logTransaction
            });
        }

        return externalActivationResult;
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} providerId
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note prior to multi-provider support, this method was named getActivationToken
     * the naming was updated in version 4.4.0
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>}
     *
     */
    public async createExternalRedemptionToken(
        providerId: string,
        logTransaction: LogTransaction
    ) {
        return await this.client.createExternalRedemptionToken(
            providerId,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 14.1.0
     * @param {String} contentProvider
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>}
     *
     */
    public async generateActivationToken(
        contentProvider: string,
        logTransaction: LogTransaction
    ) {
        return await this.client.generateActivationToken(
            contentProvider,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the TokenManager instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.ExternalActivation.ExternalActivationManager';
    }
}
