/**
 *
 * @module telemetryManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/telemetry.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from './../../logging/logger';
import EventBuffer from './eventBuffer';
import DiagnosticFeature from './../../diagnosticFeature';
import InternalEvents from './../../internalEvents';
import TelemetryManagerConfiguration from '../../services/configuration/telemetryManagerConfiguration';
import TelemetryClient from '../../services/internal/telemetry/telemetryClient';
import TokenManager from '../../token/tokenManager';

/**
 *
 * @access protected
 * @desc Provides a manager that can be used to access post telemetry events.
 *
 */
export default class TelemetryManager extends EventEmitter {
    /**
     *
     * @access private
     * @param {SDK.Services.Configuration.TelemetryManagerConfiguration} telemetryManagerConfiguration
     * @param {SDK.Token.TokenManager} tokenManager
     * @param {SDK.Services.Internal.Telemetry.TelemetryClient} telemetryClient
     * @param {SDK.Logging.Logger} logger
     *
     */
    constructor(
        telemetryManagerConfiguration,
        tokenManager,
        telemetryClient,
        logger
    ) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                telemetryManagerConfiguration: Types.instanceStrict(
                    TelemetryManagerConfiguration
                ),
                tokenManager: Types.instanceStrict(TokenManager),
                telemetryClient: Types.instanceStrict(TelemetryClient),
                logger: Types.instanceStrict(Logger)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {SDK.Internal.Telemetry.EventBuffer}
         * @desc used for `StreamSampleEvents`
         *
         */
        this.streamSampleBuffer = new EventBuffer({
            bufferConfiguration:
                telemetryManagerConfiguration.extras
                    .streamSampleBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.postEvent,
            telemetryClient,
            logger,
            fastTrack: telemetryManagerConfiguration.extras.fastTrack,
            prohibited: telemetryManagerConfiguration.extras.prohibited
        });

        /**
         *
         * @access private
         * @type {SDK.Internal.Telemetry.EventBuffer}
         * @desc used for `Dust` events
         *
         */
        this.eventBuffer = new EventBuffer({
            bufferConfiguration:
                telemetryManagerConfiguration.extras.eventBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.dustEvent,
            telemetryClient,
            logger,
            fastTrack: telemetryManagerConfiguration.extras.fastTrack,
            prohibited: telemetryManagerConfiguration.extras.prohibited,
            diagnosticFeature: DiagnosticFeature.glimpseValidation
        });

        /**
         *
         * @access private
         * @type {SDK.Internal.Telemetry.EventBuffer}
         * @desc used for `UserActivity` related events
         *
         */
        this.trackingBuffer = new EventBuffer({
            bufferConfiguration:
                telemetryManagerConfiguration.extras.glimpseBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.dustEvent,
            telemetryClient,
            logger,
            fastTrack: telemetryManagerConfiguration.extras.fastTrack,
            prohibited: telemetryManagerConfiguration.extras.prohibited,
            diagnosticFeature: DiagnosticFeature.glimpseValidation
        });

        /**
         *
         * @access private
         * @type {SDK.Internal.Telemetry.EventBuffer}
         * @desc used for `QoE` events
         *
         */
        this.qoeBuffer = new EventBuffer({
            bufferConfiguration:
                telemetryManagerConfiguration.extras.qoeBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.dustEvent,
            telemetryClient,
            logger,
            fastTrack: telemetryManagerConfiguration.extras.fastTrack,
            prohibited: telemetryManagerConfiguration.extras.prohibited,
            diagnosticFeature: DiagnosticFeature.qoeValidation
        });

        this.trackingBuffer.on(
            InternalEvents.ValidationResultsReceived,
            (validation) => {
                this.emit(InternalEvents.ValidationResultsReceived, validation);
            }
        );

        this.qoeBuffer.on(
            InternalEvents.ValidationResultsReceived,
            (validation) => {
                this.emit(InternalEvents.ValidationResultsReceived, validation);
            }
        );

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @desc gives us a way to be sure telemetry is disabled in tests
     *
     */
    dispose() {
        this.qoeBuffer.dispose();
        this.trackingBuffer.dispose();
        this.eventBuffer.dispose();
        this.streamSampleBuffer.dispose();
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Internal.Telemetry.TelemetryManager';
    }

    // #endregion
}
