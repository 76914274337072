/**
 *
 * @module adEngineManager
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import type { IGeoProvider } from './../../providers/IGeoProvider';
import PlatformProviders from './../../providers/platformProviders';
import Logger from './../../logging/logger';

import AdvertisingIdProvider from './../../advertising/advertisingIdProvider';
import LimitAdTrackingEnabled from './../../advertising/limitAdTrackingEnabled';

import AdEngineClient from './../../services/media/adEngine/adEngineClient';
import AdEngineManagerConfiguration from './../../services/configuration/adEngineManagerConfiguration';

import TokenManager from './../../token/tokenManager';
import LogTransaction from '../../logging/logTransaction';

interface AdEngineManagerOptions {
    config: AdEngineManagerConfiguration;
    client: AdEngineClient;
    tokenManager: TokenManager;
    sdkver: string;
    logger: Logger;
    geoProvider?: IGeoProvider;
    advertisingIdProvider: AdvertisingIdProvider;
}

/**
 *
 * @since 3.6.0
 *
 */
export default class AdEngineManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.AdEngineManagerConfiguration}
     *
     */
    private config: AdEngineManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Media.AdEngine.AdEngineClient}
     *
     */
    public client: AdEngineClient;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {string}
     * @note sdkver comes from deviceManger.environmentConfiguration.sdkVersion
     *
     */
    private sdkver: string;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.GeoProvider}
     *
     */
    private geoProvider: IGeoProvider;

    /**
     *
     * @access private
     * @type {Object}
     * @desc contains some static values used for AdEngine tracking - pulled from the SDK Config
     * @note adTargeting comes from adEngine.extras.adTargeting
     *
     */
    private adTargeting: Record<string, unknown>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {SDK.Advertising.AdvertisingIdProvider}
     * @desc the AdvertisingIdProvider provides device-specific advertising information for the adEngine team
     *
     */
    private advertisingIdProvider: AdvertisingIdProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     *
     */
    public disabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.AdEngineManagerConfiguration} options.config
     * @param {SDK.Services.Media.AdEngine.AdEngineClient} options.client
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {String} options.sdkver
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.GeoProvider} [options.geoProvider=null]
     * @param {SDK.Advertising.AdvertisingIdProvider} options.advertisingIdProvider
     *
     */
    public constructor(options: AdEngineManagerOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(AdEngineManagerConfiguration),
                    client: Types.instanceStrict(AdEngineClient),
                    tokenManager: Types.instanceStrict(TokenManager),
                    geoProvider: Types.instanceStrict(
                        PlatformProviders.GeoProvider
                    ).optional,
                    sdkver: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    advertisingIdProvider: Types.instanceStrict(
                        AdvertisingIdProvider
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            config,
            client,
            tokenManager,
            sdkver,
            logger,
            advertisingIdProvider,
            geoProvider = null
        } = options;

        const { extras } = config;
        const { adTargeting = {} } = extras || {};

        this.config = config;
        this.client = client;
        this.tokenManager = tokenManager;
        this.sdkver = sdkver;
        this.logger = logger;
        this.adTargeting = { ...adTargeting, ...{ sdkver } };
        this.advertisingIdProvider = advertisingIdProvider;
        this.geoProvider = Check.assigned(geoProvider)
            ? geoProvider
            : new PlatformProviders.GeoProvider(logger);
        this.disabled = this.config.disabled;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc returns set-cookie headers from the service that are stored in the device's cookie jar
     * @note this method needs to catch a failure in order to prevent playback from failing
     * @returns {Promise<Void>}
     *
     */
    public async updateCookies(
        options: Record<string, unknown>,
        logTransaction: LogTransaction
    ): Promise<void> {
        const { client, accessToken, logger } = this;

        return this.constructAdEngineData(options).then((adEngineObject) => {
            return client
                .updateCookies(accessToken, adEngineObject, logTransaction)
                .catch((exception) => {
                    logger.warn(this.toString(), exception);

                    return Promise.resolve();
                });
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note this method needs to catch a failure in order to prevent playback from failing
     * @note in the event of a failure we return an empty Array
     * @note this method is not currently used but exists to align with the spec
     * @returns {Promise<Array<Cookie>|Array>}
     *
     */
    public getCookies(
        options: Record<string, unknown>,
        logTransaction: LogTransaction
    ): Promise<Array<TodoAny>> {
        const { client, accessToken, logger } = this;

        return this.constructAdEngineData(options).then((adEngineObject) => {
            return client
                .getCookies(accessToken, adEngineObject, logTransaction)
                .catch((exception) => {
                    logger.warn(this.toString(), exception);

                    return Promise.resolve([]);
                });
        });
    }

    /**
     *
     * @access public
     *
     */
    public toString() {
        return 'SDK.Media.AdEngine.AdEngineManager';
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @desc gets geoLocation and merges various tracking information into a temporary, flat object
     * @note xny needs to be a String w/o spaces - e.g. '[1,2]'
     * @note xny should default to undefined to avoid being serialized in the client
     * @note xny should be omitted if 0,0 i.e. not provided
     * @note lat maps true to 1 and false to 0 - must be 0|1
     * @note all adEngine query param keys are lowercase not camelCase
     * @note IMPORTANT: when constructing the adEngine object make sure that `this.adTargeting` is merged last to
     * avoid app developers from overriding values from the config
     * @returns {Promise<Object>}
     *
     */
    private async constructAdEngineData(
        options: Record<string, unknown>
    ): Promise<Record<string, unknown>> {
        const { adTargeting } = this;

        const geoLocation = await this.geoProvider.getGeoLocation();

        const { latitude, longitude } = geoLocation;

        const coordinates = `[${latitude},${longitude}]`;
        const advertisingId = this.advertisingIdProvider.getId();
        const limitAdTracking =
            this.advertisingIdProvider.getLimitAdTrackingEnabled();

        const sdkGenerated = {
            devid: advertisingId,
            lat: limitAdTracking === LimitAdTrackingEnabled.YES ? 1 : 0,
            xny: coordinates !== '[0,0]' ? coordinates : undefined
        };

        const adEngineObject = { ...sdkGenerated, ...options, ...adTargeting };

        return adEngineObject;
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken();
    }
}
