import { BroadcastServiceActions } from './actions';
import BroadcastService from './broadcast.service';
import UserService from './user.service';

type ContentReward = {
    rewardToken?: string;
    contentId?: string;
    action?: string;
    recommendedContentIds?: string;
};

export type SessionInfo = {
    id?: string;
    device?: {
        id?: string
    };
    account?: {
        id?: string;
    },
    experiments: {
        [key: string]: {
            variantId: string;
            version: number;
        }
    };
};

class SdkService {
    static instance: SdkService;
    private _bootstrapConfiguration;
    private _sdkSession: any;
    private _sessionInfo: SessionInfo;
    private _isLoggedIn: boolean;

    static getInstance(SDK: any, userService: UserService, broadcastService: BroadcastService) {
        if (!SdkService.instance) {
            SdkService.instance = new SdkService(SDK, userService, broadcastService);
        }

        return SdkService.instance;
    }

    constructor (private _SDK: any, private _userService: UserService, private _broadcastService: BroadcastService) {
        const serverPath = (window as any).server_path;
        const urlParams = new URLSearchParams(window.location.search);
        const sdkConfigHostName = urlParams.get('sdkConfigHostName');

        if (sdkConfigHostName) {
            serverPath.sdk.configHostName = sdkConfigHostName;
        }

        this._bootstrapConfiguration = new this.SDK.Services.Configuration.BootstrapConfiguration(serverPath.sdk);
    }

    public get SDK(): any {
        return this._SDK;
    }

    public get sdkSession(): any {
        return this._sdkSession;
    }

    public set sdkSession(v: any) {
        this._sdkSession = v;
    }

    public get sessionInfo(): SessionInfo {
        return this._sessionInfo;
    }

    public async setSessionInfo() {
        this._sessionInfo = await this.sdkSession.getSessionInfo();
        this.setUserDssId(this.sessionInfo);
    }

    public get experiments() {
        return this._sessionInfo.experiments;
    }

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public set isLoggedIn(v: boolean) {
        this._isLoggedIn = v;
    }

    private async setupUser() {
        await this.setSessionInfo();
        this.isLoggedIn = await this.sdkSession.isAuthorized('espn');
        this._userService.subscriptions = await this.getSubscriptions();
    }

    public async login(jwt, retry = false): Promise<void> {
        const accountApiService = this.sdkSession.accountApi;

        try {
            await accountApiService.authorize(jwt, this.sdkSession.clientId);

            await this.setupUser();
        } catch (e) {
            try {
                await accountApiService.createAccount(jwt);

                // Attempt to authorize the user against the SDK one more time after account creation
                return this.login(jwt);
            } catch (ex) {
                console.error('createAccount fail', ex);
                await this.logout();
                if (!retry) {
                    await this.login(jwt, true);
                }
            }
        }
    }

    public setUserDssId(sessionInfo: SessionInfo) {
        const sessionId = sessionInfo.id || 'error';
        const deviceId = sessionInfo.device?.id ||  'error';
        const accountId = sessionInfo.account?.id || '';

        this._userService.dssId = `${deviceId},${sessionId}`;

        if (accountId) {
            this._userService.dssId = `${this._userService.dssId},${accountId}`;
        }
    }

    public async logout(): Promise<void> {
        return await this.sdkSession.reset();
    }

    public async getInitializationState() {
        return await this.sdkSession.getInitializationState();
    }

    public async initialize(sdkInitialState?): Promise<void> {
        const bootstrapConfiguration = this._bootstrapConfiguration;
        this.sdkSession = await this._SDK.SdkSession.createSdkSession({ bootstrapConfiguration });

        try {
            if (sdkInitialState) {
                const initialState = await this._SDK.InitializationState.deserialize(sdkInitialState);

                await this.sdkSession.initializeWithState(initialState);
                await this.setSessionInfo();
            } else {
                await this.sdkSession.initialize();
                await this.setSessionInfo();
            }
        } catch (err) {
            console.error(err);

            try {
                await this.sdkSession.initialize();
                await this.setSessionInfo();
            } catch (ex) {
                this.notifySdkSessionResetOnError();

                throw ex;
            }
        }
    }

    public async getSubscriptions() {
        return await this.sdkSession.subscriptionApi.getSubscriptions();
    }

    public async getSessionToken() {
        return await this.sdkSession.getSessionToken();
    }

    public async sendContentReward(data: ContentReward) {
        const userActivityApi = this.sdkSession.userActivityApi;
        const action = data.action || 'CLICK';
        return userActivityApi.sendContentReward(
            data.rewardToken,
            data.contentId,
            action,
            data.recommendedContentIds
        );
    }

    public onReauthorizationFailure(handleExpiredBtmToken) {
        const exceptionReference = this._SDK.Services.Exception.ExceptionReference;
        const authenticationExpired = exceptionReference.common.authenticationExpired;

        /* unable to trigger sdkSession reauthorization failure for testing */
        /* istanbul ignore next */
        this.sdkSession.on('ReauthorizationFailure', (reauthorizationFailure) => {
            if (reauthorizationFailure &&
                reauthorizationFailure.error &&
                reauthorizationFailure.error.data &&
                reauthorizationFailure.error.data.name === authenticationExpired.name) {
                handleExpiredBtmToken();
            }
        });
    }

    private async notifySdkSessionResetOnError() {
        await this.sdkSession.reset();
        this._broadcastService.emit(BroadcastServiceActions.SdkService.sdkSessionResetOnError, new Error('SdkSessionResetOnError'));
    }
}

export default SdkService;
