/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import AudioRendition from '../services/media/audioRendition';
import PlaybackVariant from '../services/media/playbackVariant';
import SubtitleRendition from '../services/media/subtitleRendition';
import PlaylistType from '../services/media/playlistType';

import ApplicationContext from '../services/qualityOfService/applicationContext';
import BufferType from '../services/qualityOfService/bufferType';
import ErrorLevel from '../services/qualityOfService/errorLevel';
import MediaSegmentType from '../services/qualityOfService/mediaSegmentType';
import QoePlaybackError from '../services/qualityOfService/qoePlaybackError';
import PlaybackExitedCause from '../services/qualityOfService/playbackExitedCause';
import PlaybackPausedCause from '../services/qualityOfService/playbackPausedCause';
import PlaybackResumedCause from '../services/qualityOfService/playbackResumedCause';
import PlaybackSeekCause from '../services/qualityOfService/playbackSeekCause';
import PlayerSeekDirection from '../services/qualityOfService/playerSeekDirection';
import ServerRequest from '../services/qualityOfService/serverRequest';

import { PresentationType } from '../services/qualityOfService/enums';

import {
    AdPodData,
    AdPodDataTypedef,
    AdPodPlacement,
    AdPodPlacementTypedef,
    AdSlotData,
    AdSlotDataTypedef,
    AdVideoData,
    AdSubtitleData,
    AdAudioData,
    AdVideoDataTypedef,
    AdAudioDataTypedef,
    AdSubtitleDataTypedef,
    AdErrorData,
    AdErrorDataTypedef
} from '../services/qualityOfService/typedefs';

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdMetadata
 * @since 19.0.0
 * @desc The location of the current ad playhead, measured as a millisecond offset from the start time of the current ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} adPodPlacement - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} adPodData - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} adSlotData - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {Number} adPlayheadPosition - Since `19.0.0` - The location of the current ad playhead, measured as a millisecond offset
 * from the start time of the current ad pod.
 *
 */
export interface AdMetadata {
    adPodPlacement: AdPodPlacement;
    adPodData: AdPodData;
    adSlotData: AdSlotData;
    adPlayheadPosition: number;
}

/**
 *
 * @access private
 *
 */
export const AdMetadataTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef),
    adPodData: Types.object(AdPodDataTypedef),
    adSlotData: Types.object(AdSlotDataTypedef),
    adPlayheadPosition: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AudioBitrateChangedEvent
 * @since 13.0.0
 * @desc Provides details about the onAudioBitrateChanged event.
 * @property {Number} [bitrateAvg] - The average audio bitrate the player switched to (in bps).
 *
 */
export interface AudioBitrateChangedEvent {
    bitrateAvg?: number;
}

/**
 *
 * @access private
 *
 */
export const AudioBitrateChangedEventTypedef = {
    bitrateAvg: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AudioChangedEvent
 * @since 13.0.0
 * @desc Provides details about the onAudioChanged event.
 * @property {Number} [audioChannels] - Audio channels of selected audio rendition
 * @property {String} [audioCodec] - Audio codec of selected audio rendition
 * @property {String} [audioLanguage] - Audio language of selected audio rendition
 * @property {String} [audioName] - Audio name of selected audio rendition
 *
 */
export interface AudioChangedEvent {
    audioChannels?: number;
    audioCode?: string;
    audioLanguage?: string;
    audioName?: string;
}

/**
 *
 * @access private
 *
 */
export const AudioChangedEventTypedef = {
    audioChannels: Types.number.optional,
    audioCodec: Types.nonEmptyString.optional,
    audioLanguage: Types.nonEmptyString.optional,
    audioName: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.BitrateChangedEvent
 * @since 13.0.0
 * @desc Provides details about the onBitrateChanged event.
 * @property {Number} [bitrateAvg] - The average bitrate the player switched to, for the currently selected presentationType, in bps.
 * @property {Number} [bitratePeak] - The peak bitrate the player switched to, for the currently selected presentationType, in bps.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface BitrateChangedEvent {
    bitrateAvg?: number;
    bitratePeak?: number;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const BitrateChangedEventTypedef = {
    bitrateAvg: Types.number.optional,
    bitratePeak: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.MultivariantPlaylistFetchedEvent
 * @since 15.0.0
 * @desc Provides details about the onMultivariantPlaylistFetched event.
 * @property {String<SDK.Services.Media.PlaylistType>} [playlistLiveType] - The variety of live playlist
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Data about a request/response to a server.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time.
 * @note Renamed to 'Multivariant' in recent inclusive language efforts
 *
 */
export interface MultivariantPlaylistFetchedEvent {
    playlistLiveType?: ValueOf<typeof PlaylistType>;
    serverRequest?: ServerRequest;
    playheadPosition?: number;
}

/**
 *
 * @access private
 *
 */
export const MultivariantPlaylistFetchedEventTypedef = {
    playlistLiveType: Types.in(PlaylistType).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    playheadPosition: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.MediaSegmentFetchedEvent
 * @since 13.0.0
 * @desc Provides details about the onMediaSegmentFetched event.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Data about a request/response to a server.
 *
 */
export interface MediaSegmentFetchedEvent {
    serverRequest?: ServerRequest;
}

/**
 *
 * @access private
 *
 */
export const MediaSegmentFetchedEventTypedef = {
    serverRequest: Types.instanceStrict(ServerRequest).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackEndedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackEnded event.
 * @note For CDN fallback logic - when the PlayerAdapter picks up the onPlaybackEnded event and the cause is PlaybackExitedCause.error
 * and the error is PlaybackError.serviceError, the SDK is expected to call PlayerAdapter.setSource with the return value of Playlist.getNextUrl.
 * @property {String<SDK.Services.QualityOfService.PlaybackExitedCause>} [cause] - The reason for exiting playback.
 * @property {String<SDK.Services.QualityOfService.QoePlaybackError>} [error] - The error describing the failure. Null if no error occured.
 * @property {String} [errorDetail] - Supporting error text for `error` property where this can be provided for additional context. If `error` == `adBeaconError`,
 * this field should be populated with the `beacon_url` that failed.
 * @property {String<SDK.Services.QualityOfService.ApplicationContext>} [applicationContext] - Since `19.0.0` - The failure context. Null if no error occurred.
 * This is expected to be either `player` or `ad`.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. This should be supplied if playback ended due
 * to an error related to an `ad` or while preparing/playing an ad. Required if the current `applicationContext` is `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackEndedEvent {
    cause?: ValueOf<typeof PlaybackExitedCause>;
    error?: ValueOf<typeof QoePlaybackError>;
    errorDetail?: string;
    applicationContext?: ValueOf<typeof ApplicationContext>;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackEndedEventTypedef = {
    cause: Types.in(PlaybackExitedCause).optional,
    error: Types.in(QoePlaybackError).optional,
    errorDetail: Types.nonEmptyString.optional,
    applicationContext: Types.in(ApplicationContext).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackInitializedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackInitialized event.
 * @property {SDK.Services.Media.PlaybackVariant} [variant] - The variant that playback is starting with.
 * @property {SDK.Services.Media.AudioRendition} [audio] - Selected audio rendition.
 * @property {SDK.Services.Media.SubtitleRendition} [subtitle] - Selected subtitle rendition. Null if not selected.
 * @property {Number} [startupBitrate] - Starting peak bitrate (in bps), if set by application.
 * @property {Boolean} [areSubtitlesVisible] - Subtitle visibility.
 * @property {String} [streamUrl] - This should be retrieved from whichever `PrioritizedUrl` is active on the `Playlist`.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 *
 */
export interface PlaybackInitializedEvent {
    variant?: PlaybackVariant;
    audio?: AudioRendition;
    subtitle?: SubtitleRendition;
    startupBitrate?: number;
    areSubtitlesVisible?: boolean;
    streamUrl?: string;
    playheadPosition?: number;
}

/**
 *
 * @access private
 *
 */
export const PlaybackInitializedEventTypedef = {
    variant: Types.instanceStrict(PlaybackVariant).optional,
    audio: Types.instanceStrict(AudioRendition).optional,
    subtitle: Types.instanceStrict(SubtitleRendition).optional,
    startupBitrate: Types.number.optional,
    areSubtitlesVisible: Types.boolean.optional,
    streamUrl: Types.nonEmptyString.optional,
    playheadPosition: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackPausedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackPaused event.
 * @property {String<SDK.Services.QualityOfService.PlaybackPausedCause>} [cause] - The reason for pausing playback.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackPausedEvent {
    cause?: ValueOf<typeof PlaybackPausedCause>;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackPausedEventTypedef = {
    cause: Types.in(PlaybackPausedCause).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackReadyEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackReady event.
 * @property {Number} [bufferSegmentDuration] - The amount of video currently fully buffered in milliseconds.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 *
 */
export interface PlaybackReadyEvent {
    bufferSegmentDuration?: number;
    playheadPosition?: number;
}

/**
 *
 * @access private
 *
 */
export const PlaybackReadyEventTypedef = {
    bufferSegmentDuration: Types.number.optional,
    playheadPosition: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackResumedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackResumed event.
 * @property {String<SDK.Services.QualityOfService.PlaybackResumedCause>} [cause] - The reason for resuming playback.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackResumedEvent {
    cause?: ValueOf<typeof PlaybackResumedCause>;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackResumedEventTypedef = {
    cause: Types.in(PlaybackResumedCause).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackSeekEndedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackSeekEnded event.
 * @property {String<SDK.Services.QualityOfService.PlaybackSeekCause>} [cause] - Reason playback seeked.
 * @property {String<SDK.Services.QualityOfService.PlayerSeekDirection>} [playerSeekDirection] - Where the intended seek position is relative to current position.
 * @property {Number} [seekDistance] - How far the player is attempting to seek in stream time, in milliseconds.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackSeekEndedEvent {
    cause?: ValueOf<typeof PlaybackSeekCause>;
    playerSeekDirection?: ValueOf<typeof PlayerSeekDirection>;
    seekDistance?: number;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackSeekEndedEventTypedef = {
    cause: Types.in(PlaybackSeekCause).optional,
    playerSeekDirection: Types.in(PlayerSeekDirection).optional,
    seekDistance: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackSeekStartedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackSeekStarted event.
 * @property {String<SDK.Services.QualityOfService.PlaybackSeekCause>} [cause] - Reason playback seeked.
 * @property {String<SDK.Services.QualityOfService.PlayerSeekDirection>} [playerSeekDirection] - Where the intended seek position is relative to current position.
 * @property {Number} [seekDistance] - How far the player is attempting to seek in stream time, in milliseconds.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackSeekStartedEvent {
    cause?: ValueOf<typeof PlaybackSeekCause>;
    playerSeekDirection?: ValueOf<typeof PlayerSeekDirection>;
    seekDistance?: number;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackSeekStartedEventTypedef = {
    cause: Types.in(PlaybackSeekCause).optional,
    playerSeekDirection: Types.in(PlayerSeekDirection).optional,
    seekDistance: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackStartedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackStarted event.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [videoBitrate] - Nominal peak (encoded) bitrate of the currently selected presentationType or of the presentationType/variant last played, as
 * reported in the HLS or DASH manifest.
 * @property {Number} [videoAverageBitrate] - Nominal average (encoded) bitrate of the currently selected presentationType or of the presentationType/variant last
 * played, as reported in the HLS or DASH manifest.
 * @property {Number} [audioBitrate] - Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant
 * last played before the event, as reported in the HLS or DASH manifest.
 * @property {Number} [maxAllowedVideoBitrate] - The highest video bitrate (in bps) available for the currently selected presentationType in the current DASH/HLS
 * manifest that is allowed to play. The user may not reach this bitrate in their session.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {String<SDK.Services.QualityOfService.PresentationType>} [presentationType] - Since `19.0.0` - The type of presentation currently being played. Return unknown
 * if in playing state but presentation type is unknown.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackStartedEvent {
    playheadPosition?: number;
    videoBitrate?: number;
    videoAverageBitrate?: number;
    audioBitrate?: number;
    maxAllowedVideoBitrate?: number;
    segmentPosition?: number;
    presentationType?: ValueOf<typeof PresentationType>;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackStartedEventTypedef = {
    playheadPosition: Types.number.optional,
    videoBitrate: Types.number.optional,
    videoAverageBitrate: Types.number.optional,
    audioBitrate: Types.number.optional,
    maxAllowedVideoBitrate: Types.number.optional,
    segmentPosition: Types.number.optional,
    presentationType: Types.in(PresentationType).optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.RebufferingEndedEvent
 * @since 13.0.0
 * @desc Provides details about the onRebufferingEndedEvent event.
 * @property {String<SDK.Services.QualityOfService.BufferType>} [bufferType] - The type of buffering that is happening.
 * @property {Number} [duration] - The amount of wall clock time, in milliseconds, that occurred during rebuffering.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface RebufferingEndedEvent {
    bufferType?: ValueOf<typeof BufferType>;
    duration?: number;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const RebufferingEndedEventTypedef = {
    bufferType: Types.in(BufferType).optional,
    duration: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.RebufferingStartedEvent
 * @since 13.0.0
 * @desc Provides details about the onRebufferingStarted event.
 * @property {String<SDK.Services.QualityOfService.BufferType>} [bufferType] - The type of buffering that is happening.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface RebufferingStartedEvent {
    bufferType?: ValueOf<typeof BufferType>;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const RebufferingStartedEventTypedef = {
    bufferType: Types.in(BufferType).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.SubtitleChangedEvent
 * @since 13.0.0
 * @desc Provides details about the onSubtitleChanged event.
 * @property {String} [subtitleLanguage] - Subtitle language of selected subtitle rendition.
 * @property {String} [subtitleName] - Subtitle name of selected subtitle rendition.
 * @property {Boolean} [subtitleVisibility] - Subtitle visibility
 *
 */
export interface SubtitleChangedEvent {
    subtitleLanguage?: string;
    subtitleName?: string;
    subtitleVisibility?: string;
}

/**
 *
 * @access private
 *
 */
export const SubtitleChangedEventTypedef = {
    subtitleLanguage: Types.nonEmptyString.optional,
    subtitleName: Types.nonEmptyString.optional,
    subtitleVisibility: Types.boolean.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.VariantPlaylistFetchedEvent
 * @since 13.0.0
 * @desc Provides details about the onVariantPlaylistFetched event.
 * @property {Number} [playlistAverageBandwidth] - Value of `AVERAGE-BANDWIDTH` attribute of variant playlist (if a video variant).
 * @property {Number} [playlistBandwidth] - Value of `BANDWIDTH` attribute of variant playlist.
 * @property {String} [playlistChannels] - Value of `CHANNELS` attribute of variant playlist (if an audio variant).
 * @property {String} [playlistName] - Value of `NAME` attribute (for audio and subtitle variants).
 * @property {String} [playlistLanguage] - Value of `LANGUAGE` attribute (for audio and subtitle variants).
 * @property {String<SDK.Services.QualityOfService.MediaSegmentType>} [mediaSegmentType] - What type of content a media segment contains.
 * @property {String} [playlistResolution] - Resolution as defined by a variant playlist.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Data about a request/response to a server.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 *
 */
export interface VariantPlaylistFetchedEvent {
    playlistAverageBandwidth?: number;
    playlistBandwidth?: number;
    playlistChannels?: string;
    playlistName?: string;
    playlistLanguage?: string;
    mediaSegmentType?: ValueOf<typeof MediaSegmentType>;
    playlistResolution?: string;
    serverRequest?: ServerRequest;
    playheadPosition?: number;
}

/**
 *
 * @access private
 *
 */
export const VariantPlaylistFetchedEventTypedef = {
    playlistAverageBandwidth: Types.number.optional,
    playlistBandwidth: Types.number.optional,
    playlistChannels: Types.nonEmptyString.optional,
    playlistName: Types.nonEmptyString.optional,
    playlistLanguage: Types.nonEmptyString.optional,
    mediaSegmentType: Types.in(MediaSegmentType).optional,
    playlistResolution: Types.nonEmptyString.optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    playheadPosition: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PresentationTypeChangedEvent
 * @since 19.0.0
 * @desc Provides details about the onPresentationTypeChanged event.
 * @property {SDK.Services.QualityOfService.PresentationType} [presentationType] - Since `19.0.0` - The new presentation type that the player has transitioned to.
 * @property {Number} [videoBitrate] - Since 19.0.0 - Nominal peak (encoded) bitrate of the currently selected presentationType or
 * of the presentationType/variant last played, as reported in the HLS or DASH manifest. For HLS Manifest, use the 'BANDWIDTH' value
 * and for DASH, use the '@bandwidth' value. If value is not available, default to 0.
 * @property {Number} [videoAverageBitrate] - Since 19.0.0 - Nominal average (encoded) bitrate of the currently selected presentationType
 * or of the presentationType/variant last played, as reported in the HLS or DASH manifest. For HLS Manifest, use the 'AVERAGE-BANDWIDTH' value.
 * If value is not available, default to 0.
 * @property {Number} [audioBitrate] - Since 19.0.0 - Nominal average (encoded) bitrate of the currently selected audio representation or of the audio
 * representation/variant last played before the event, as reported in the HLS or DASH manifest. If value is not available, default to 0. If value is muxed,
 * send the bitrate of the combined content.
 * @property {Number} [audioChannels] - Since 19.0.0 - Value of `CHANNELS` attribute of the selected variant playlist (if an audio variant).
 * @property {String} [audioCodec] - Since 19.0.0 - The audio codec of the selected variant playlist.
 * @property {SDK.Services.Media.AudioRendition} [audio] - Since `19.0.0` - The audio codec of the selected variant playlist.
 * @property {SDK.Services.Media.SubtitleRendition} [subtitle] - Since `19.0.0` - Selected subtitle rendition. Null if not selected or subtitles are disabled.
 * @property {Boolean} [areSubtitlesVisible] - Since 19.0.0 - Subtitle visibility
 *
 */
export interface PresentationTypeChangedEvent {
    presentationType?: ValueOf<typeof PresentationType>;
    videoBitrate?: number;
    videoAverageBitrate?: number;
    audioBitrate?: number;
    audioChannels?: number;
    audioCodec?: string;
    audio?: AudioRendition;
    subtitle?: SubtitleRendition;
    areSubtitlesVisible?: boolean;
}

/**
 *
 * @access private
 *
 */
export const PresentationTypeChangedEventTypedef = {
    presentationType: Types.in(PresentationType).optional,
    videoBitrate: Types.number.optional,
    videoAverageBitrate: Types.number.optional,
    audioBitrate: Types.number.optional,
    audioChannels: Types.number.optional,
    audioCodec: Types.nonEmptyString.optional,
    audio: Types.instanceStrict(AudioRendition).optional,
    subtitle: Types.instanceStrict(SubtitleRendition).optional,
    areSubtitlesVisible: Types.boolean.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.NonFatalPlaybackErrorEvent
 * @since 19.0.0
 * @desc Provides details about the onNonFatalPlaybackError event.
 * @property {SDK.Media.QoePlaybackError} [error] - Since `19.0.0` - The error describing the non-fatal playback error.
 * @property {SDK.Services.QualityOfService.ErrorLevel} [errorLevel] - Since `19.0.0` - The error impact level.
 * @property {SDK.Services.QualityOfService.ApplicationContext} [applicationContext] - Since `19.0.0` - This is expected to be either `player` or `ad`.
 * @property {String} [errorDetail] - Since `19.0.0` - Supporting error text for `error` property where this can be provided for additional context.
 * Unless requested otherwise, set to native platform error message if available. If `error` == `adBeaconError`, this field should be populated with the `beacon_url` that failed.
 * @property {Number} [playheadPosition] - Since `19.0.0` - The location of the current playhead, measured as a millisecond offset from the start time.  -1 if value is not available.
 * @property {Number} [segmentPosition] - Since `19.0.0` - This should be the epoch time in milliseconds of the video. Required if productType is 'Live'.
 * @property {Number} [liveLatencyAmount] - Since `19.0.0` - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * Required if productType is 'Live'.
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `applicationContext` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface NonFatalPlaybackErrorEvent {
    error?: ValueOf<typeof QoePlaybackError>;
    errorLevel?: ValueOf<typeof ErrorLevel>;
    applicationContext?: ValueOf<typeof ApplicationContext>;
    errorDetail?: string;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const NonFatalPlaybackErrorEventTypedef = {
    error: Types.in(QoePlaybackError).optional,
    errorLevel: Types.in(ErrorLevel).optional,
    applicationContext: Types.in(ApplicationContext).optional,
    errorDetail: Types.nonEmptyString.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.SnapshotCreatedEvent
 * @since 19.0.0
 * @desc Provides details about the created `SDK.QualityOfService.SnapshotEvent`.
 * @property {Object<SDK.Services.QualityOfService.SnapshotEvent>} [snapshotEvent] - Since `19.0.0` - Subtitle language of selected subtitle rendition.
 *
 */
export interface SnapshotCreatedEvent {
    snapshotEvent?: Record<string, unknown>;
}

/**
 *
 * @access private
 *
 */
export const SnapshotCreatedEventTypedef = {
    snapshotEvent: Types.object().optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPodRequestedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPodRequested event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 *
 */
export interface AdPodRequestedEvent {
    adPodPlacement?: AdPodPlacement;
}

/**
 *
 * @access private
 *
 */
export const AdPodRequestedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPodFetchedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPodRequested event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Since `19.0.0` - Data about the request/response that occurred with
 * the Disney Ad Server for fetching this ad pod.
 *
 */
export interface AdPodFetchedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    serverRequest?: ServerRequest;
    startTimestamp?: number;
}

/**
 *
 * @access private
 *
 */
export const AdPodFetchedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    startTimestamp: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdMultivariantFetchedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdMultivariantFetched event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Since `19.0.0` - Data about the request/response that occurred with
 *
 */
export interface AdMultivariantFetchedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    serverRequest?: ServerRequest;
    startTimestamp?: number;
}

/**
 *
 * @access private
 *
 */
export const AdMultivariantFetchedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    startTimestamp: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdVariantFetchedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdVariantFetched event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Since `19.0.0` - Data about the request/response that occurred with
 * @property {String<SDK.Services.QualityOfService.MediaSegmentType>} [mediaSegmentType] - Since `19.0.0` - The type of variant downloaded.
 * @property {Object<SDK.Services.QualityOfService.AdVideoData>} [adVideoData] - Since `19.0.0` - Descriptive information about the downloaded video variant.
 * Required if `mediaSegmentType = video`.
 * @property {Object<SDK.Services.QualityOfService.AdAudioData>} [adAudioData] - Since `19.0.0` - Descriptive information about the downloaded audio variant.
 * Required if `mediaSegmentType = audio`.
 * @property {Object<SDK.Services.QualityOfService.AdSubtitleData>} [adSubtitleData] - Since `19.0.0` - Descriptive information about the downloaded subtitle variant.
 * Required if `mediaSegmentType = subtitle`.
 *
 */
export interface AdVariantFetchedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    serverRequest?: ServerRequest;
    mediaSegmentType?: ValueOf<typeof MediaSegmentType>;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
    startTimestamp?: number;
}

/**
 *
 * @access private
 *
 */
export const AdVariantFetchedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    mediaSegmentType: Types.in(MediaSegmentType).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional,
    startTimestamp: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPlaybackStartedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPlaybackStarted event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {Object<SDK.Services.QualityOfService.AdVideoData>} [adVideoData] - Since `19.0.0` - Descriptive information about the downloaded video variant.
 * Required if `mediaSegmentType = video`.
 * @property {Object<SDK.Services.QualityOfService.AdAudioData>} [adAudioData] - Since `19.0.0` - Descriptive information about the downloaded audio variant.
 * Required if `mediaSegmentType = audio`.
 * @property {Object<SDK.Services.QualityOfService.AdSubtitleData>} [adSubtitleData] - Since `19.0.0` - Descriptive information about the downloaded subtitle variant.
 * Required if `mediaSegmentType = subtitle`.
 *
 */
export interface AdPlaybackStartedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
}

/**
 *
 * @access private
 *
 */
export const AdPlaybackStartedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPlaybackEndedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPlaybackEnded event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {Object<SDK.Services.QualityOfService.AdVideoData>} [adVideoData] - Since `19.0.0` - Descriptive information about the downloaded video variant.
 * Required if `mediaSegmentType = video`.
 * @property {Object<SDK.Services.QualityOfService.AdAudioData>} [adAudioData] - Since `19.0.0` - Descriptive information about the downloaded audio variant.
 * Required if `mediaSegmentType = audio`.
 * @property {Object<SDK.Services.QualityOfService.AdSubtitleData>} [adSubtitleData] - Since `19.0.0` - Descriptive information about the downloaded subtitle variant.
 * Required if `mediaSegmentType = subtitle`.
 * @property {String<SDK.Services.QualityOfService.PlaybackExitedCause>} [cause] - Since `19.0.0` - The reason for ending ad playback.
 * @property {Object<SDK.Services.QualityOfService.AdErrorData>} [adErrorData] - Since `19.0.0` - Metadata relevant to ad-related errors. Required if `cause` = `error`.
 *
 */
export interface AdPlaybackEndedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
    cause?: ValueOf<typeof PlaybackExitedCause>;
    adErrorData?: AdErrorData;
}

/**
 *
 * @access private
 *
 */
export const AdPlaybackEndedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional,
    cause: Types.in(PlaybackExitedCause).optional,
    adErrorData: Types.object(AdErrorDataTypedef).optional
};
