/**
 *
 * @module bufferType
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.BufferType
 * @desc Describes type of buffering that is happening.
 *
 * @property {String} initializing - `Since 13.0.0` - When the user starts playing some playback (content or ads).
 * @property {String} seeking - `Since 13.0.0` - When the user seeks to a determined time on the playback (content or ads).
 * @property {String} resuming - `Since 13.0.0` - When the user resumes a playback (content or ads) that has paused.
 * @property {String} buffering - `Since 13.0.0` - When the type of playback changes, e.g. ad to content, content to bumper, etc.
 * @property {String} rebuffering - `Since 13.0.0` - When the user’s network connection slows down.
 * @property {String} stalePlaylist - `Since 13.0.0` - When one or more HLS variant refreshes (or MPD updates) fail to add new segments
 * to the end of the playback timeline resulting in buffer underrun.
 * @property {String} segmentGap - `Since 13.0.0` - When one or more segments in an HLS variant (or one or more fragments in the MPD)
 * are marked as Gaps (or missing) in the active buffer zone which would result in a jump by playback into the live
 * head holdback zone and the player prevents it by buffering instead.
 * @property {String} segmentDownloadFailure - `Since 13.0.0` - When one or more segments in an HLS variant (or one or more fragments)
 * fail to download in the active buffer zone which would result in a jump by playback into the live head holdback zone
 * and the player prevents it by buffering instead.
 * @property {String} unknown - Since 20.0.0 - The default value for `bufferType`, indicating that a `bufferType` can not be determined.
 *
 * @example
 * SDK.Services.QualityOfService.BufferType.initializing // 'initializing'
 *
 */
export default keyMirror({
    initializing: null,
    seeking: null,
    resuming: null,
    buffering: null,
    rebuffering: null,
    stalePlaylist: null,
    segmentGap: null,
    segmentDownloadFailure: null,
    unknown: null
});
