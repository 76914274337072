/**
 *
 * @module flexClientEndpoint
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @access protected
 * @since 16.0.0
 * @typedef {Object} SDK.Services.Flex.FlexClientEndpoint
 *
 * @property {String} billingHistory - Since `16.0.0`.
 * @property {String} subscriptionSummary - Since `16.0.0`.
 * @property {String} cancelFlow - Since `16.0.0`.
 * @property {String} cancelSuccess - Since `17.0.0`.
 * @property {String} lrPlanSwitch - Since `21.0.0`.
 * @property {String} lrPlanSwitchSuccess - Since `21.0.0`.
 * @property {String} lrBillingCadence - Since `21.0.0`.
 * @property {String} planSwitch - Since `17.0.0`.
 * @property {String} planSwitchLedger - Since `17.0.0`.
 * @property {String} planSwitchSuccess - Since `21.0.0`.
 * @property {String} subscriptionRestart - Since `17.0.0`.
 * @property {String} subscriptionCancel - Since `17.0.0`.
 * @property {String} subscriptionSwitch - Since `17.0.0`.
 * @property {String} execution - Since `17.0.0`.
 * @property {String} default - Since `16.0.0`.
 *
 */
enum FlexClientEndpoint {
    billingHistory = 'billingHistory',
    subscriptionSummary = 'subscriptionSummary',
    cancelFlow = 'cancelFlow',
    cancelSuccess = 'cancelSuccess',
    lrPlanSwitch = 'lrPlanSwitch',
    lrPlanSwitchSuccess = 'lrPlanSwitchSuccess',
    lrBillingCadence = 'lrBillingCadence',
    planSwitch = 'planSwitch',
    planSwitchLedger = 'planSwitchLedger',
    planSwitchSuccess = 'planSwitchSuccess',
    subscriptionRestart = 'subscriptionRestart',
    subscriptionCancel = 'subscriptionCancel',
    subscriptionSwitch = 'subscriptionSwitch',
    execution = 'execution',
    default = 'default'
}

export default FlexClientEndpoint;
