/**
 *
 * @module ripcutManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/ripcut.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import SessionManager from '../session/sessionManager';
import RipcutClient from '../services/ripcut/ripcutClient';
import RipcutManagerConfiguration from '../services/configuration/ripcutManagerConfiguration';

import LogTransaction from '../logging/logTransaction';

interface RipcutManagerOptions {
    ripcutManagerConfiguration: RipcutManagerConfiguration;
    ripcutClient: RipcutClient;
    logger: Logger;
    sessionManager: SessionManager;
}

/**
 *
 * @access protected
 * @since 17.0.0
 *
 */
export default class RipcutManager {
    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Services.Configuration.RipcutManagerConfiguration}
     *
     */
    private config: RipcutManagerConfiguration;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Services.Ripcut.RipcutClient}
     *
     */
    public client: RipcutClient;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 17.0.0
     * @type {SDK.Session.SessionManager}
     *
     */
    private sessionManager: SessionManager;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.RipcutManagerConfiguration} options.ripcutManagerConfiguration
     * @param {SDK.Services.Ripcut.RipcutClient} options.ripcutClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Session.SessionManager} options.sessionManager
     *
     */
    public constructor(options: RipcutManagerOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    ripcutManagerConfiguration: Types.instanceStrict(
                        RipcutManagerConfiguration
                    ),
                    ripcutClient: Types.instanceStrict(RipcutClient),
                    logger: Types.instanceStrict(Logger),
                    sessionManager: Types.instanceStrict(SessionManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            ripcutManagerConfiguration,
            ripcutClient,
            logger,
            sessionManager
        } = options;

        this.config = ripcutManagerConfiguration;
        this.client = ripcutClient;
        this.logger = logger;
        this.sessionManager = sessionManager;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @since 17.0.0
     * @param {String} options.imageId
     * @param {String<RipcutImageRequestMode>} options.imageRequestMode
     * @param {Object<RipcutQueryParams>} [options.queryParams]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Ripcut.RipcutResult>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImage(
        options: {
            imageId: string;
            imageRequestMode: SDK.Ripcut.RipcutImageRequestMode;
            queryParams?: SDK.Ripcut.RipcutQueryParams;
        },
        logTransaction: LogTransaction
    ): Promise<SDK.Services.Ripcut.RipcutResult> {
        const { imageId, imageRequestMode, queryParams } = options;

        const session = await this.sessionManager.getInfo(logTransaction);
        const partnerId = session.partnerName;

        return await this.client.getImage(
            {
                imageId,
                imageRequestMode,
                partnerId,
                queryParams
            },
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @since 17.0.0
     * @param {String} options.imageId
     * @param {String<RipcutImageRequestMode>} options.imageRequestMode
     * @param {Object<RipcutQueryParams>} [options.queryParams]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImageUrl(
        options: {
            imageId: string;
            imageRequestMode: SDK.Ripcut.RipcutImageRequestMode;
            queryParams?: SDK.Ripcut.RipcutQueryParams;
        },
        logTransaction: LogTransaction
    ): Promise<string> {
        const { imageId, imageRequestMode, queryParams } = options;

        const session = await this.sessionManager.getInfo(logTransaction);
        const partnerId = session.partnerName;

        return await this.client.getImageUrl({
            imageId,
            imageRequestMode,
            partnerId,
            queryParams
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Ripcut.RipcutManager';
    }
}
