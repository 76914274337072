/**
 *
 * @module onboardProfileToStarRequest
 *
 */

import OrchestrationRequest from '../orchestration/internal/orchestrationRequest';

/**
 *
 * @since 4.17.0
 *
 */
export default class OnboardProfileToStarRequest extends OrchestrationRequest {
    public constructor() {
        const query = OnboardProfileToStarRequest.query;

        const variables = {};

        super({ query, variables, operationName: 'onboardProfileToStar' });
    }

    /**
     *
     * @access private
     * @since 4.17.0
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/orchestration_graphql/query/onboardProfileToStar.md
     * @returns {String}
     *
     */
    private static get query() {
        return `mutation onboardProfileToStar {
            onboardProfileToStar {
                accepted
            }
        }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Account.OnboardProfileToStarRequest';
    }
}
