/**
 *
 * @module ripcutManagerConfiguration
 *
 */

import RipcutClientConfiguration from '../ripcut/ripcutClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @since 17.0.0
 * @desc Provides configuration information necessary for managing ripcut.
 *
 */
export default class RipcutManagerConfiguration extends ServiceConfiguration<RipcutClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.RipcutManagerConfiguration';
    }

    // #endregion
}
