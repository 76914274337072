/**
 *
 * @module entry
 * @desc {SDK.Services.Util} entry point.
 *
 */

import checkResponseCode from './checkResponseCode';
import delay from './delay';
import getSafe from './getSafe';
import getScope from './getScope';
import adEngineRegex from './adEngineRegex';
import dateRegex from './dateRegex';
import circularReplacer from './circularReplacer';
import toUpperFirst from './toUpperFirst';
import appendQuerystring from './appendQuerystring';
import hasLocalStorage from './hasLocalStorage';
import replaceHeaders from './replaceHeaders';
import replaceUrl from './replaceUrl';
import monotonicClock from './monotonicClock';
import parseUrl from './parseUrl';

/**
 *
 * @typedef {Object} SDK.Services.Util
 *
 * @property {Function} checkResponseCode
 * @property {Function} delay
 * @property {Function} getSafe
 * @property {Function} getScope
 * @property {Function} adEngineRegex
 * @property {Function} circularReplacer
 * @property {Function} toUpperFirst
 * @property {Function} appendQuerystring
 * @property {Function} hasLocalStorage
 * @property {Function} replaceHeaders
 * @property {Function} replaceUrl
 * @property {Object} monotonicClock
 * @property {Function} parseUrl
 *
 */
export default {
    checkResponseCode,
    delay,
    getSafe,
    getScope,
    adEngineRegex,
    dateRegex,
    circularReplacer,
    toUpperFirst,
    appendQuerystring,
    hasLocalStorage,
    replaceHeaders,
    replaceUrl,
    monotonicClock,
    parseUrl
};
