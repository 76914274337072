/**
 *
 * @module drmClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/services-commons/mdrm/tree/master/basic_apis
 * @see https://github.bamtech.co/services-commons/mdrm/blob/master/license_acquisition_urls.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import checkResponseCode from '../util/checkResponseCode';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import DustCategory from '../internal/dust/dustCategory';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import DrmClientEndpoint from './drmClientEndpoint';
import DrmClientConfiguration from './drmClientConfiguration';
import DrmUtils from './drmUtils';
import PlayReadyMessage from './playReadyMessage';

import ErrorCode from '../exception/errorCode';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';

import { MediaAnalyticsKey } from '../../media/enums';
import MediaItem from '../../media/mediaItem';

import PlaylistType from '../media/playlistType';

import FetchStatus from '../qualityOfService/fetchStatus';
import Method from '../qualityOfService/httpMethod';
import NetworkError from '../qualityOfService/networkError';
import PlaybackStartupEventData from '../qualityOfService/playbackStartupEventData';
import ServerRequest from '../qualityOfService/serverRequest';
import StartupActivity from '../qualityOfService/startupActivity';

import getSafe from '../util/getSafe';
import LogTransaction from '../../logging/logTransaction';
import HttpClient from '../providers/browser/httpClient';
import DrmClientExtrasMap from './drmClientExtrasMap';
import PlaybackContext from '../media/playbackContext';
import { getDataVersion } from '../../media/playbackTelemetryDispatcher';

const DrmClientDustUrnReference = DustUrnReference.services.drm.drmClient;
const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;

const requiresArrayBufferEndpoints = [
    DrmClientEndpoint.fairPlayCertificate as keyof typeof DrmClientEndpoint,
    DrmClientEndpoint.widevineCertificate as keyof typeof DrmClientEndpoint
];

/**
 *
 * @access protected
 *
 */
export default class DrmClient {
    /**
     *
     * @access private
     * @type {SDK.Services.Drm.DrmClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: DrmClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {HttpClient}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: HttpClient;

    /**
     *
     * @access private
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.Drm.DrmClientConfiguration} drmClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        drmClientConfiguration: DrmClientConfiguration,
        logger: Logger,
        httpClient: HttpClient
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmClientConfiguration: Types.instanceStrict(
                    DrmClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = drmClientConfiguration;

        this.logger = logger;

        this.httpClient = httpClient;

        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.keyLocation - The URL of the decryption key.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {Number} [options.playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves a SILK decryption key from the key service.
     * @returns {Promise<Uint8Array>} The decryption key for this event.
     *
     */
    public async getSilkKey(options: {
        keyLocation: string;
        mediaItem: MediaItem;
        playheadPosition?: number;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    keyLocation: Types.nonEmptyString,
                    mediaItem: Types.instanceStrict(MediaItem),
                    playheadPosition: Types.number.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSilkKey', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const {
            keyLocation,
            mediaItem,
            playheadPosition,
            accessToken,
            logTransaction
        } = options;

        const { playbackContext } = mediaItem;

        const endpointKey =
            DrmClientEndpoint.silkKey as keyof typeof DrmClientEndpoint;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey,
            bodyType: 'json'
        });

        // set the payload URL based on the provided key location
        payload.url = keyLocation;

        logger.info(
            this.toString(),
            `Attempting to retrieve SilkKey from keyLocation: ${keyLocation}.`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getSilkKey,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.get(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmLicenseEvent({
                    mediaItem,
                    dustLogUtility,
                    playheadPosition
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Media.MediaItem} mediaItem
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Retrieves a Fairplay Certificate.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the
     * BAMTECH FairPlay Application Certificate as binary data.
     * @note This certificate is the same for all partners.
     * It will be hosted statically and can be stored on a long-term basis.
     *
     */
    public async getFairPlayCertificate(
        accessToken: AccessToken,
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                mediaItem: Types.instanceStrict(MediaItem),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getFairPlayCertificate', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;
        const { playbackContext } = mediaItem;

        const endpointKey = DrmClientEndpoint.fairPlayCertificate;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to retrieve FairPlay Certificate.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getFairPlayCertificate,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.get(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmCertificateEvent(
                    mediaItem,
                    dustLogUtility
                );
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Uint8Array|String} options.serverPlaybackContext - A server playback context created by the DRM client
     * (typically directly by the player or the platform).
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a FairPlay content key context from the license service to authorize and decrypt media.
     * @returns {Promise<Uint8Array>} A Promise fulfilled by a content key context from the license server.
     * @note This certificate is the same for all partners. It will be hosted statically and can be stored on a
     * long-term basis.
     *
     */
    public async getFairPlayLicense(options: {
        serverPlaybackContext: Uint8Array | string;
        endpointKey?: string;
        mediaItem: MediaItem;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    //serverPlaybackContext: Types.arrayBufferView.or.nonEmptyString,
                    endpointKey: Types.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem).optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getFairPlayLicense', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const {
            serverPlaybackContext,
            endpointKey,
            mediaItem,
            accessToken,
            logTransaction
        } = options;

        const { playbackContext } = mediaItem;

        const payload = this.getPayload({
            accessToken,
            rel: DrmClientEndpoint.fairPlayLicense,
            body: serverPlaybackContext,
            endpointKey
        });

        logger.info(
            this.toString(),
            `Attempting to retrieve FairPlay License: ${JSON.stringify(
                serverPlaybackContext
            )}.`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getFairPlayLicense,
            payload,
            method: HttpMethod.POST,
            endpointKey: endpointKey || DrmClientEndpoint.fairPlayLicense,
            logTransaction
        });

        try {
            const response = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmLicenseEvent({
                    mediaItem,
                    dustLogUtility
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Media.MediaItem} mediaItem
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Retrieves the BAMTECH Widevine Service Certificate.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the
     * BAMTECH Widevine Service Certificate as binary data.
     * @note This certificate is the same for all partners.
     * It will be hosted statically and can be stored on a long-term basis.
     *
     */
    public async getWidevineCertificate(
        accessToken: AccessToken,
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                mediaItem: Types.instanceStrict(MediaItem),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getWidevineCertificate', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;
        const { playbackContext } = mediaItem;

        const endpointKey = DrmClientEndpoint.widevineCertificate;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to retrieve Widevine Certificate.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getWidevineCertificate,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.get(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmCertificateEvent(
                    mediaItem,
                    dustLogUtility
                );
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Uint8Array} options.buffer - A protocol buffer conforming to
     * the Widevine specification identifying the asset and encryption mode.
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a Widevine License from the license service.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled with the Widevine license as binary data.
     * @note Nearly all Widevine DRM clients will provide a mechanism to create the Widevine protocol buffer. The
     * SDK shall leverage these capabilities wherever possible.
     *
     */
    public async getWidevineLicense(options: {
        buffer: Uint8Array;
        endpointKey?: keyof typeof DrmClientEndpoint;
        mediaItem: MediaItem;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    buffer: Types.arrayBufferView,
                    endpointKey: Types.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getWidevineLicense', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const { buffer, endpointKey, mediaItem, accessToken, logTransaction } =
            options;

        const { playbackContext } = mediaItem;

        const payload = this.getPayload({
            accessToken,
            rel: DrmClientEndpoint.widevineLicense,
            body: buffer,
            endpointKey
        });

        logger.info(
            this.toString(),
            `Attempting to retrieve Widevine License with: ${JSON.stringify(
                buffer
            )}.`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getWidevineLicense,
            payload,
            method: HttpMethod.POST,
            endpointKey: endpointKey || DrmClientEndpoint.widevineLicense,
            logTransaction
        });

        try {
            const response = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmLicenseEvent({
                    mediaItem,
                    dustLogUtility
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Services.Drm.PlayReadyMessage} options.message - A configured `PlayReadyMessage` containing
     * any required headers and a `PlayReadyObject`.
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a PlayReady license from the license server.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled with the PlayReady license as binary data.
     *
     */
    public async getPlayReadyLicense(options: {
        message: PlayReadyMessage;
        endpointKey?: keyof typeof DrmClientEndpoint;
        mediaItem: MediaItem;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    message: Types.instanceStrict(PlayReadyMessage),
                    endpointKey: Types.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPlayReadyLicense', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;

        const { message, endpointKey, mediaItem, accessToken, logTransaction } =
            options;

        const { playbackContext } = mediaItem;
        const { body = {} } = message;

        const payload = this.getPayload({
            accessToken,
            rel: DrmClientEndpoint.playReadyLicense,
            body: body.xmlData,
            endpointKey
        });

        logger.info(
            this.toString(),
            `Attempting to retrieve PlayReady License: message: ${JSON.stringify(
                message.body
            )}.`
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getPlayReadyLicense,
            payload,
            method: HttpMethod.POST,
            endpointKey: endpointKey || DrmClientEndpoint.playReadyLicense,
            logTransaction
        });

        try {
            const response = await httpClient.post(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmLicenseEvent({
                    mediaItem,
                    dustLogUtility
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Media.MediaItem} mediaItem
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Retrieves the BAMTECH Nagra Service Certificate (Open Vault).
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the
     * BAMTECH Nagra Service Certificate as a JSON Object.
     * @note This certificate is the same for all partners.
     * It will be hosted statically and can be stored on a long-term basis.
     *
     */
    public async getNagraCertificate(
        accessToken: AccessToken,
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                mediaItem: Types.instanceStrict(MediaItem),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getNagraCertificate', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;
        const { playbackContext } = mediaItem;

        const endpointKey = DrmClientEndpoint.nagraCertificate;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey,
            bodyType: 'json'
        });

        logger.info(
            this.toString(),
            'Attempting to retrieve Nagra Certificate.'
        );

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getNagraCertificate,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        try {
            const response = await httpClient.get(payload);

            await checkResponseCode(response, dustLogUtility);

            return response.data;
        } finally {
            if (dustEnabled && Check.assigned(playbackContext)) {
                await this.sendQoeDrmCertificateEvent(
                    mediaItem,
                    dustLogUtility
                );
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @returns {SDK.QualityOfService.ServerRequest}
     *
     */
    private getQosServerRequestData(dustLogUtility: DustLogUtility) {
        if (Check.not.instanceStrict(dustLogUtility, DustLogUtility)) {
            return new ServerRequest();
        }

        const { server, error } = dustLogUtility;
        const { roundTripTime, host, path, method = '', statusCode } = server;

        let networkError;
        let status = FetchStatus.completed as keyof typeof FetchStatus;

        if (error) {
            if (statusCode === 401 || statusCode === 403) {
                networkError = NetworkError.prohibited;
            } else if (statusCode && statusCode >= 500) {
                networkError = NetworkError.notConnected;
                status = FetchStatus.noNetwork;
            } else {
                networkError = NetworkError.unknown;
            }

            if (
                (error as ErrorData).code === ErrorCode.unspecifiedDrmError.code
            ) {
                networkError = NetworkError.unknown;
            }
        }

        return new ServerRequest({
            host,
            path,
            statusCode,
            roundTripTime,
            method: (Method as Indexable<typeof Method>)[method.toLowerCase()],
            status,
            error: networkError
        });
    }

    /**
     *
     * @access private
     * @since 15.2.0
     * @param {SDK.Services.Media.PlaybackContext} [playbackContext]
     * @returns {Object}
     *
     */
    private getCommonProperties(playbackContext?: PlaybackContext) {
        const { analyticsProvider } = this.logger;

        let data = playbackContext?.data ?? {};

        if (analyticsProvider) {
            const commonProperties = analyticsProvider.getCommonProperties();

            data = {
                ...data,
                ...commonProperties
            };
        }

        return data;
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {SDK.Media.MediaItem} mediaItem
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async getQosDecision(mediaItem: MediaItem) {
        const { qosDecisions = {} } = getSafe(() => mediaItem.payload.stream);

        const playlist = await mediaItem.getPreferredPlaylist();

        if (playlist.playlistType === PlaylistType.SLIDE) {
            return qosDecisions.slide;
        }

        return qosDecisions.complete;
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {SDK.Media.MediaItem} mediaItem
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async getCdnValues(mediaItem: MediaItem) {
        const playlist = await mediaItem.getPreferredPlaylist();

        if (mediaItem.priorityTracking) {
            return playlist.getTrackingData(
                MediaAnalyticsKey.qos,
                mediaItem.priorityTracking
            );
        }

        return playlist.getTrackingData(MediaAnalyticsKey.qos);
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {SDK.Media.MediaItem} mediaItem
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async sendQoeDrmCertificateEvent(
        mediaItem: MediaItem,
        dustLogUtility: DustLogUtility
    ) {
        const drmCertificateFetched = await this.createPlaybackStartupEventData(
            {
                mediaItem,
                dustLogUtility,
                startupActivity: StartupActivity.drmCertificateFetched
            }
        );

        const qosLogUtility = new DustLogUtility({
            category: DustCategory.qoe,
            logger: this.logger,
            source: this.toString(),
            urn: QualityOfServiceDustUrnReference.playbackStartup,
            data: {
                ...drmCertificateFetched
            },
            skipLogTransaction: true,
            dataVersion: getDataVersion(
                QualityOfServiceDustUrnReference.playbackStartup
            )
        });

        qosLogUtility.log();
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {Object} options
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Internal.Dust.DustLogUtility} options.dustLogUtility
     * @param {Number} [options.playheadPosition]
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async sendQoeDrmLicenseEvent(options: {
        mediaItem: MediaItem;
        dustLogUtility: DustLogUtility;
        playheadPosition?: number;
    }) {
        const drmKeyFetched = await this.createPlaybackStartupEventData({
            ...options,
            startupActivity: StartupActivity.drmKeyFetched
        });

        const qosLogUtility = new DustLogUtility({
            category: DustCategory.qoe,
            logger: this.logger,
            source: this.toString(),
            urn: QualityOfServiceDustUrnReference.playbackStartup,
            data: {
                ...drmKeyFetched
            },
            skipLogTransaction: true,
            dataVersion: getDataVersion(
                QualityOfServiceDustUrnReference.playbackStartup
            )
        });

        qosLogUtility.log();
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {Object} options
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.QualityOfService.StartupActivity} options.startupActivity
     * @param {SDK.Services.Internal.Dust.DustLogUtility} options.dustLogUtility
     * @param {Number} [options.playheadPosition]
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async createPlaybackStartupEventData(options: {
        mediaItem: MediaItem;
        startupActivity: keyof typeof StartupActivity;
        dustLogUtility: DustLogUtility;
        playheadPosition?: number;
    }) {
        const { mediaItem, startupActivity, dustLogUtility, playheadPosition } =
            options;

        const playbackContext = mediaItem.playbackContext;
        const qosDecision = (await this.getQosDecision(mediaItem)) || {};
        const { clientDecisions = {}, serverDecisions = {} } = qosDecision;

        const playbackSessionId = playbackContext?.playbackSessionId;
        const productType = playbackContext?.productType;
        const localMedia = playbackContext?.offline;
        const startupContext = playbackContext?.startupContext;
        const serverRequest = this.getQosServerRequestData(dustLogUtility);
        const data = this.getCommonProperties(playbackContext);
        const qos = await this.getCdnValues(mediaItem);

        return new PlaybackStartupEventData({
            startupActivity,
            playheadPosition,
            playbackSessionId,
            productType,
            localMedia,
            serverRequest,
            clientGroupIds: clientDecisions.clientGroupIds,
            serverGroupIds: serverDecisions.serverGroupIds,
            qos,
            data,
            startupContext
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Drm.DrmClientEndpoint} options.rel
     * @param {Object} [options.body]
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {String} [options.bodyType='arrayBuffer'] - default `bodyType` for DRM requests
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        rel: keyof typeof DrmClientEndpoint;
        body?: TodoAny;
        endpointKey?: string;
        bodyType?: string;
    }) {
        const {
            accessToken,
            rel,
            body,
            endpointKey,
            bodyType = 'arrayBuffer'
        } = options;

        const { endpoints = {}, extras = {} as DrmClientExtrasMap } =
            this.config;

        const endpoint = endpoints[rel];

        const drmType = DrmUtils.getDrmType(rel);

        const newEndpoint = getSafe(
            () => extras.licenseEndpoints[drmType][endpointKey as string]
        );

        let url;
        let headers;

        if (newEndpoint) {
            url = getSafe(() => endpoints[newEndpoint].href, endpoint.href);
            headers = getSafe(
                () => endpoints[newEndpoint].headers,
                endpoint.headers
            );
        } else {
            url = endpoint.href;
            headers = endpoint.headers;
        }

        let needArrayBuffer;

        if (requiresArrayBufferEndpoints.includes(rel)) {
            needArrayBuffer = true;
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: (accessToken as AccessToken).token
                    };
                }
            },
            headers
        );

        return {
            url,
            body,
            bodyType,
            headers: requestHeaders,
            needArrayBuffer
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Drm.DrmClient';
    }
}
