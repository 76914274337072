/**
 *
 * @module removeFromWatchlistQuery
 *
 */

import { Check } from '@dss/type-checking';

import ContentQuery from './../contentQuery';
import GraphQlPersistedQuery from './../graphQlPersistedQuery';

/**
 *
 * @desc Represents a Content query with context `watchlistPersisted` that is used to remove items from a user's watchlist.
 *
 */
export default class RemoveFromWatchlistQuery extends ContentQuery {
    /**
     *
     * @param {Array<String>} contentIds
     * @param {String} [queryIdDefault='core/DeleteFromWatchlist'] - Default query Id value from the config.
     *
     */
    public constructor(contentIds: Array<string>, queryIdDefault: string) {
        const queryId = Check.nonEmptyString(queryIdDefault)
            ? queryIdDefault
            : 'core/DeleteFromWatchlist';

        const graphQlPersistedQuery = new GraphQlPersistedQuery(queryId, {
            contentIds
        });

        super('watchlistPersisted', graphQlPersistedQuery);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.Watchlist.RemoveFromWatchlistQuery';
    }
}
