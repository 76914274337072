/**
 *
 * @module networkError
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.NetworkError
 * @desc Defines the different types of network errors.
 *
 * @property {String} dns - Since `4.0.0` - A DNS failure.
 * @property {String} timeout - Since `4.0.0` - A request timeout.
 * @property {String} notConnected - Since `4.0.0` - Network connectivity error.
 * @property {String} prohibited - Since `4.0.0` - Server certificate validation error.
 * @property {String} unknown - Since `4.0.0` - All other errors.
 *
 * @example
 * SDK.Services.QualityOfService.NetworkError.dns // 'dns'
 *
 */
export default keyMirror({
    dns: null,
    timeout: null,
    notConnected: null,
    prohibited: null,
    unknown: null
});
