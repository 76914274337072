/**
 *
 * @module entry
 * @desc {SDK.Paywall} entry point.
 *
 */

import PaywallApi from './paywallApi';
import PaywallManager from './paywallManager';

/**
 *
 * @typedef {Object} SDK.Paywall
 *
 * @property {PaywallApi} PaywallApi
 * @property {PaywallManager} PaywallManager
 *
 */
export default {
    PaywallApi,
    PaywallManager
};
