/**
 *
 * @module supportedCodec
 *
 */

/**
 *
 * @enum
 * @since 4.2.0
 * @typedef {Object} SDK.Services.Media.SupportedCodec
 * @desc Defines the supported video codecs.
 *
 * @property {String} h264
 * @property {String} h265
 *
 * @example
 * SupportedCodec.h264 // 'h.264'
 *
 */
export default {
    h264: 'h.264',
    h265: 'h.265'
};
