/**
 *
 * @module errorSource
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ErrorSource
 * @desc Defines where the error originated from.
 *
 * @property {String} app - `Since 13.0.0` - App errors come directly from the client and are present in the app itself (i.e. incorrect email format used for log in).
 * @property {String} player - `Since 13.0.0` - Player errors come directly from the player itself (i.e. HDCP errors).
 * @property {String} sdk - `Since 13.0.0` - SDK errors are ones rooted in the sdk (i.e. JSON serialization errors, misuse of the SDK).
 * @property {String} service - `Since 13.0.0` - Service errors are ones coming from back end service errors (i.e. unknown email used for log in).
 *
 * @example
 * SDK.Services.QualityOfService.ErrorSource.app // 'app'
 *
 */
export default keyMirror({
    app: null,
    player: null,
    sdk: null,
    service: null
});
