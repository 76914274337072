/**
 *
 * @module drmType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Media.DrmType
 *
 * @property {String} NONE - Used to signify that no DrmType is available.
 * @property {String} SILK - SILK is the existing encryption key mechanism.
 * @property {String} PLAYREADY - PlayReady is the Microsoft content encryption standard.
 * @property {String} WIDEVINE - Widevine is Google's content encryption standard.
 * @property {String} FAIRPLAY - FairPlay is the Apple content encryption standard.
 * @property {String} PRMNAGRA - Since `4.4.0` - Nagra is the Canal+ content encryption standard.
 *
 */
export default keyMirror({
    NONE: null,
    SILK: null,
    PLAYREADY: null,
    WIDEVINE: null,
    FAIRPLAY: null,
    PRMNAGRA: null
});
