/**
 *
 * @module playbackSnapshotEventData
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import NetworkType from './networkType';
import ProductType from './productType';

import { SnapshotEvent, SnapshotEventTypedef } from './typedefs';

import { PresentationType } from './enums';

interface PlaybackSnapshotEventDataOptions {
    pqmVersion?: string;
    pqmGroupId?: string;
    snapshotEvents?: Array<SnapshotEvent>;
    playbackSessionId?: string;
    productType?: keyof typeof ProductType;
    networkType?: keyof typeof NetworkType;
    presentationType?: keyof typeof PresentationType;
    monotonicTimestamp?: number;
    contentKeys?: Record<string, unknown>;
    data?: Record<string, unknown>;
}

/**
 *
 * @since 19.0.0
 * @desc An event that captures download information of media resources and various player states.
 * @note urn:dss:event:client:playback:snapshot:v1
 *
 */
export default class PlaybackSnapshotEventData {
    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc The binary version of the Playback Quality Manager (PQM) currently used in this playback session.
     * @note The Playback Configuration Service (PCS) will provide this to clients prior to a playback attempt.
     * @note Clients will pass via `MediaApi.initializePlaybackContext()`. Source from the Bam.Sdk.Media.PlaybackContext`.
     * @note If PQM is not used, or the pqmVersion is unavailable, send "0.0.0" as the default value.
     *
     */
    public pqmVersion: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Used in PQM to distinguish different client-side ABR algorithm experiment groups.
     * @note The Playback Configuration Service (PCS) will provide this to clients prior to a playback attempt.
     * @note Clients will pass via `MediaApi.initializePlaybackContext()`. Source from the Bam.Sdk.Media.PlaybackContext`.
     * @note If PQM is not used, or the pqmGroupId is unavailable, send "-1" as the default value.
     *
     */
    public pqmGroupId: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Array<SDK.Services.QualityOfService.SnapshotEvent>|undefined}
     * @desc Array of snapshot event objects which contain information about media resource downloading and player status
     * during playback sessions.
     * @note Source from the `Bam.Sdk.Media.PlaybackTelemetryDispatcher.snapshotEvents` buffer.
     *
     */
    public snapshotEvents?: Array<SnapshotEvent>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Client generated ID of the stream/playback session.
     *
     */
    public playbackSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
     * @desc The Product type, Live or VOD.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     *
     */
    public productType?: keyof typeof ProductType;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.NetworkType>|undefined}
     * @desc The type of network connection currently in use by the client.
     *
     */
    public networkType?: ValueOf<typeof NetworkType>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.PresentationType>|undefined}
     * @desc The type of presentation currently being played.
     * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
     * event or the `Bam.Sdk.Media.PlaybackStartedEvent` object.
     *
     */
    public presentationType?: keyof typeof PresentationType;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `Bam.Sdk.MonotonicTimestampManager.getTimestamp()`.
     *
     */
    public monotonicTimestamp?: number;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     *
     */
    public contentKeys: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object}
     * @desc Additional partner specific data provided by the `SDK.Logging.AnalyticsProvider` and `MediaApi.initializePlaybackContext`.
     * @note The properties provided in this should be flattened such that there is no `data` property but the information contained within it lives on the root of this class.
     *
     */
    public data: Record<string, unknown>;

    /**
     *
     * @param {Object} [options={}]
     * @param {String} [options.pqmVersion='0.0.0']
     * @param {String} [options.pqmGroupId='-1']
     * @param {Array<SDK.Services.QualityOfService.SnapshotEvent>} [options.snapshotEvents]
     * @param {String} [options.playbackSessionId]
     * @param {String<SDK.Services.QualityOfService.ProductType>} [options.productType]
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType]
     * @param {String<SDK.Services.QualityOfService.PresentationType>} [options.presentationType]
     * @param {Number} [options.monotonicTimestamp]
     * @param {Object} [contentKeys={}]
     * @param {Object} [data={}]
     *
     */
    public constructor(options: PlaybackSnapshotEventDataOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    pqmVersion: Types.nonEmptyString.optional,
                    pqmGroupId: Types.nonEmptyString.optional,
                    snapshotEvents:
                        Types.array.of.object(SnapshotEventTypedef).optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    networkType: Types.in(NetworkType).optional,
                    presentationType: Types.in(PresentationType).optional,
                    monotonicTimestamp: Types.number.optional,
                    contentKeys: Types.object().optional,
                    data: Types.object().optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            pqmVersion,
            pqmGroupId,
            snapshotEvents,
            playbackSessionId,
            productType,
            networkType,
            presentationType,
            monotonicTimestamp,
            contentKeys,
            data
        } = options || {};

        this.pqmVersion = pqmVersion || '0.0.0';
        this.pqmGroupId = pqmGroupId || '-1';
        this.snapshotEvents = snapshotEvents;
        this.playbackSessionId = playbackSessionId;
        this.productType = productType;
        this.networkType = networkType || NetworkType.unknown;
        this.presentationType = presentationType;
        this.monotonicTimestamp = monotonicTimestamp;
        this.contentKeys = contentKeys || {};
        this.data = data || {};
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.PlaybackSnapshotEventData';
    }

    // #endregion
}
