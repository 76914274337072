/**
 *
 * @module searchQuery
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import GraphQlQuery from './../graphQlQuery';
import ContentQuery from './../contentQuery';

/**
 *
 * @desc Represents a Content query with context `search` used to retrieve items based on input provided by a user.
 *
 */
export default class SearchQuery extends ContentQuery {
    /**
     *
     * @param {GraphQlQuery} query
     *
     */
    public constructor(query: GraphQlQuery) {
        super('search', query);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                query: Types.instanceStrict(GraphQlQuery)
            };

            typecheck(this, params, arguments);
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.Search.SearchQuery';
    }
}
