/**
 *
 * @module experimentsDetails
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ExperimentPlatform from './experimentPlatform';

/**
 *
 * @since 6.0.0
 * @desc This object contains the different variables that are required for experimentKeys when >= 1 experiment is in
 * progress.
 *
 */
export default class ExperimentsDetails {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.QualityOfService.ExperimentPlatform} options.experimentPlatform
     * @param {String} options.experimentFeatureId
     * @param {String} options.experimentId
     * @param {String} options.variantId
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    experimentPlatform: Types.in(ExperimentPlatform),
                    experimentFeatureId: Types.nonEmptyString,
                    experimentId: Types.nonEmptyString,
                    variantId: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            experimentPlatform,
            experimentFeatureId,
            experimentId,
            variantId
        } = options;

        /**
         *
         * @access public
         * @since 6.0.0
         * @type {SDK.Services.QualityOfService.ExperimentPlatform}
         * @desc Name of testing platform used to run A/B tests, primarily on Marketing Landing Pages (MLPs).
         *
         */
        this.experimentPlatform = experimentPlatform;

        /**
         *
         * @access public
         * @since 6.0.0
         * @type {String}
         * @desc The experiment ID value generated by Optimizely or the feature ID value generated by Weapon X.
         *
         */
        this.experimentFeatureId = experimentFeatureId;

        /**
         *
         * @access public
         * @since 6.0.0
         * @type {String}
         * @desc This value will replicate the experiment ID value generated by Optimizely or the feature ID value
         * generated by Weapon X.
         *
         */
        this.experimentId = experimentId;

        /**
         *
         * @access public
         * @since 6.0.0
         * @type {String}
         * @desc The variation ID value generated by Optimizely or the variant ID value generated by Weapon X.
         *
         */
        this.variantId = variantId;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.ExperimentsDetails';
    }

    // #endregion
}
