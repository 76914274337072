/**
 *
 * @module entry
 * @desc {SDK.Configuration} entry point.
 *
 */

import ConfigurationManager from './configurationManager';
import EmbeddedConfiguration from './embeddedConfiguration';

/**
 *
 * @typedef {Object} SDK.Configuration
 *
 * @property {ConfigurationManager} ConfigurationManager
 * @property {EmbeddedConfiguration} EmbeddedConfiguration
 *
 */
export default {
    ConfigurationManager,
    EmbeddedConfiguration
};
