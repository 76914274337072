/**
 *
 * @module playbackSelectionAdTracking
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import LimitAdTrackingEnabled from '../../advertising/limitAdTrackingEnabled';

/**
 *
 * @since 18.0.0
 *
 */
export default class PlaybackSelectionAdTracking {
    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Advertising.LimitAdTrackingEnabled}
     *
     */
    public limitAdTrackingEnabled: LimitAdTrackingEnabled;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {String|undefined}
     *
     */
    public deviceAdId: string;

    /**
     *
     * @param {SDK.Advertising.LimitAdTrackingEnabled} limitAdTrackingEnabled
     * @param {String} [deviceAdId]
     *
     */
    public constructor(
        limitAdTrackingEnabled: LimitAdTrackingEnabled,
        deviceAdId: string
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                limitAdTrackingEnabled: Types.in(LimitAdTrackingEnabled),
                deviceAdId: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.limitAdTrackingEnabled = limitAdTrackingEnabled;

        this.deviceAdId = deviceAdId;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackSelectionAdTracking';
    }

    // #endregion
}
