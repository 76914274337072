/**
 *
 * @module tokenClientConfiguration
 *
 */

import ServiceClientConfiguration from './../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the TokenClient.
 *
 */
export default class TokenClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @param {String} baseUrl
     * @param {Object} endpoints
     * @param {TokenClientExtrasMap} extras
     *
     */
    constructor(baseUrl, endpoints, extras) {
        super(baseUrl, endpoints);

        /**
         *
         * @access public
         * @type {TokenClientExtrasMap}
         *
         */
        this.extras = extras;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.TokenClientConfiguration';
    }

    // #endregion
}
