/**
 *
 * @module playbackStartupEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/playback/v1/playback-startup.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import MediaFetchError from './mediaFetchError';
import MediaSegmentType from './mediaSegmentType';
import NetworkType from './networkType';
import QoePlaybackError from './qoePlaybackError';
import PlaybackIntent from './playbackIntent';
import ProductType from './productType';
import ServerRequest from './serverRequest';
import StartupActivity from './startupActivity';

import AudioRendition from '../media/audioRendition';
import PlaybackAttributes from '../media/playbackAttributes';
import PlaybackVariant from '../media/playbackVariant';
import SubtitleRendition from '../media/subtitleRendition';
import PlaylistType from '../media/playlistType';

import { AdInsertionType } from './enums';

/**
 *
 * @since 13.0.0
 * @desc These events represent playback startup related events. This event should be sent every time the `startupActivity` changes.
 * @note urn:dss:event:client:playback:startup:v1
 *
 */
export default class PlaybackStartupEventData {
    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.QualityOfService.StartupActivity>} options.startupActivity
     * @param {String} [options.playbackSessionId]
     * @param {String<SDK.Services.QualityOfService.ProductType>} [options.productType]
     * @param {Number} [options.playheadPosition=-1]
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType=SDK.Services.QualityOfService.NetworkType.unknown]
     * @param {String<SDK.Services.QualityOfService.PlaybackIntent>} [options.playbackIntent]
     * @param {Boolean} [options.mediaPreBuffer=false]
     * @param {Number} [options.bufferSegmentDuration]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {String} [options.playbackUrl]
     * @param {String} [options.playbackScenario]
     * @param {String<SDK.Services.Media.PlaylistType>} [options.playlistLiveType]
     * @param {Boolean} [options.localMedia=false]
     * @param {SDK.Services.QualityOfService.ServerRequest} [options.serverRequest]
     * @param {Number} [options.cpuPercentage]
     * @param {Number} [options.freeMemory]
     * @param {SDK.Services.Media.AudioRendition} [options.audio]
     * @param {SDK.Services.Media.SubtitleRendition} [options.subtitle]
     * @param {SDK.Services.Media.PlaybackVariant} [options.variant]
     * @param {String} [options.streamUrl]
     * @param {SDK.Services.Media.PlaybackAttributes} [options.attributes]
     * @param {Array<SDK.Services.Media.PlaybackVariant>} [options.streamVariants]
     * @param {String<SDK.Services.QualityOfService.MediaFetchError>} [options.mediaFetchError]
     * @param {String} [options.drmKeyId]
     * @param {String} [options.playlistName]
     * @param {String} [options.playlistLanguage]
     * @param {String<SDK.Services.QualityOfService.MediaSegmentType>} [options.mediaSegmentType]
     * @param {Number} [options.monotonicTimestamp]
     * @param {Object} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {String<SDK.Services.QualityOfService.QoePlaybackError>} [options.playbackError]
     * @param {String} [options.playbackErrorDetail]
     * @param {Object} [options.qos={}]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Array<String>} [options.cdnRequestedTrail]
     * @param {Array<String>} [options.cdnFailedTrail]
     * @param {Number} [options.cdnFallbackCount]
     * @param {Boolean} [options.isCdnFallback]
     * @param {String<SDK.Services.QualityOfService.AdInsertionType>} [options.adInsertionType]
     * @param {String<SDK.Services.Media.SubscriptionType>} [options.subscriptionType]
     * @param {Number} [options.totalPodCount]
     * @param {String} [options.adSessionId]
     * @param {Number} [options.totalSlotCount]
     * @param {Number} [options.totalAdLength]
     * @param {Number} [options.createAdSessionResponseCode]
     * @param {Number} [options.getPodsResponseCode]
     * @param {Object} [options.startupContext]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    startupActivity: Types.in(StartupActivity),
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    playheadPosition: Types.number.optional,
                    networkType: Types.in(NetworkType).optional,
                    playbackIntent: Types.in(PlaybackIntent).optional,
                    mediaPreBuffer: Types.boolean.optional,
                    bufferSegmentDuration: Types.number.optional,
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional,
                    playbackUrl: Types.nonEmptyString.optional,
                    playbackScenario: Types.nonEmptyString.optional,
                    playlistLiveType: Types.in(PlaylistType).optional,
                    localMedia: Types.boolean.optional,
                    serverRequest: Types.instanceStrict(ServerRequest).optional,
                    cpuPercentage: Types.number.optional,
                    freeMemory: Types.number.optional,
                    audio: Types.instanceStrict(AudioRendition).optional,
                    subtitle: Types.instanceStrict(SubtitleRendition).optional,
                    variant: Types.instanceStrict(PlaybackVariant).optional,
                    streamUrl: Types.nonEmptyString.optional,
                    attributes:
                        Types.instanceStrict(PlaybackAttributes).optional,
                    streamVariants:
                        Types.array.of.instanceStrict(PlaybackVariant).optional,
                    mediaFetchError: Types.in(MediaFetchError).optional,
                    drmKeyId: Types.nonEmptyString.optional,
                    playlistName: Types.nonEmptyString.optional,
                    playlistLanguage: Types.nonEmptyString.optional,
                    mediaSegmentType: Types.in(MediaSegmentType).optional,
                    monotonicTimestamp: Types.number.optional,
                    contentKeys: Types.object().optional,
                    data: Types.object().optional,
                    playbackError: Types.in(QoePlaybackError).optional,
                    playbackErrorDetail: Types.nonEmptyString.optional,
                    qos: Types.object().optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    cdnRequestedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFailedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFallbackCount: Types.number.optional,
                    isCdnFallback: Types.boolean.optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    subscriptionType: Types.string.optional,
                    totalPodCount: Types.number.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    totalSlotCount: Types.number.optional,
                    totalAdLength: Types.number.optional,
                    createAdSessionResponseCode: Types.number.optional,
                    getPodsResponseCode: Types.number.optional,
                    startupContext: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            startupActivity,
            playbackSessionId,
            productType,
            playheadPosition,
            networkType,
            playbackIntent,
            mediaPreBuffer,
            bufferSegmentDuration,
            videoPlayerName,
            videoPlayerVersion,
            playbackUrl,
            playbackScenario,
            playlistLiveType,
            localMedia,
            serverRequest,
            cpuPercentage,
            freeMemory,
            audio,
            subtitle,
            variant,
            streamUrl,
            attributes,
            streamVariants,
            mediaFetchError,
            drmKeyId,
            playlistName,
            playlistLanguage,
            mediaSegmentType,
            monotonicTimestamp,
            contentKeys,
            data = {},
            playbackError,
            playbackErrorDetail,
            qos = {},
            clientGroupIds,
            serverGroupIds,
            cdnRequestedTrail,
            cdnFailedTrail,
            cdnFallbackCount,
            isCdnFallback,
            adInsertionType,
            subscriptionType,
            totalPodCount,
            adSessionId,
            totalSlotCount,
            totalAdLength,
            createAdSessionResponseCode,
            getPodsResponseCode,
            startupContext = {}
        } = options;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.StartupActivity>}
         * @desc Used to identify what startup activity is occurring.
         *
         */
        this.startupActivity = startupActivity;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc client generated ID of the stream/playback session.
         * @note Is added in the `logQoeEvent` method in `SDK.Media.PlaybackTelemetryDispatcher`.
         *
         */
        this.playbackSessionId = playbackSessionId;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.ProductType>|undefined}
         * @desc The Product type, Live or VOD.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.productType = productType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
         * @note Required if `startupActivity` is `ready`, `initialized`, `masterFetched`, `variantFetched`, `drmCertificateFetched`, or `drmKeyFetched`.
         * @note Omit if unavailable and the `startupActivity` is `requested` or `fetched`.
         * @note This is the intended startup position.
         * @note Source from the associated playback event that matches the `StartupActivity` (e.g. `SDK.Media.PlaybackInitializedEvent`).
         *
         */
        this.playheadPosition = Check.assigned(playheadPosition)
            ? playheadPosition
            : -1;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.NetworkType>}
         * @desc The type of network connection currently in use by the client.
         *
         */
        this.networkType = networkType || NetworkType.unknown;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.PlaybackIntent>|undefined}
         * @desc The intent which started playback.
         * @note Required if `startupActivity` is `requested`.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.playbackIntent = playbackIntent;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean}
         * @desc True if pre buffering is occurring, false if not.
         * @note Required if `startupActivity` is `requested`.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.mediaPreBuffer = Check.assigned(mediaPreBuffer)
            ? mediaPreBuffer
            : false;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc The amount of video currently fully buffered in milliseconds.
         * @note Required if `startupActivity` is `ready`.
         * @note Source from the `SDK.Media.PlaybackReadyEvent`.
         *
         */
        this.bufferSegmentDuration = bufferSegmentDuration;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The name of the video player used to play a stream.
         * @note Required if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlayerAdapter`.
         *
         */
        this.videoPlayerName = videoPlayerName;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The version of the video player used to play a stream.
         * @note Required if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlayerAdapter`.
         *
         */
        this.videoPlayerVersion = videoPlayerVersion;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The playbackUrl before playback scenario has been injected.
         * @note Required if `startupActivity` is `fetched`.
         * @note Source from the `SDK.Media.MediaDescriptor` passed into `SDK.Media.MediaApi.fetch()`.
         *
         */
        this.playbackUrl = playbackUrl;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc One of the pre-defined playback scenarios.
         * @note Required if `startupActivity` is `fetched`.
         * @note Source from the playback scenario derived during `SDK.Media.MediaApi.fetch()`.
         *
         */
        this.playbackScenario = playbackScenario;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.Media.PlaylistType>|undefined}
         * @desc The variety of live playlist.
         * @note Required if `startupActivity` is `masterFetched`.
         * @note Source from the `SDK.Services.Media.PlaylistType` passed into `SDK.Media.MediaApi.fetch()`.
         *
         */
        this.playlistLiveType = playlistLiveType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean}
         * @desc True if the media is being played from a local source, e.g. it has been downloaded for offline playback or was bundled in the application.
         * @note Required if `startupActivity` is `requested`, `fetched`, `masterfetched`, `variantFetched`, `drmCertificateFetched`, or `drmKeyFetched`.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.localMedia = Check.assigned(localMedia) ? localMedia : false;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {SDK.Services.QualityOfService.ServerRequest|undefined}
         * @desc Data about a request/response to a server.
         * @note Required if `startupActivity` is `fetched`, `masterFetched`, `variantFetched`, `drmCertificateFetched`, or `drmKeyFetched`.
         * @note ServerRequest information may not be available for all of the required startupActivity events on every platform.
         * Send the ServerRequest data when able to do so.
         *
         */
        this.serverRequest = serverRequest;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Current available CPU as a percentage.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `requested`.
         * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
         *
         */
        this.cpuPercentage = cpuPercentage;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Current available memory in MB.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `requested`.
         * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
         *
         */
        this.freeMemory = freeMemory;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Audio channels as defined by the selected playlist variant.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistAudioChannels = Check.assigned(variant)
            ? variant.audioChannels
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Audio codec as defined by the selected playlist variant.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistAudioCodec = Check.assigned(variant)
            ? variant.audioCodec
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Audio language as defined by the selected audio rendition.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistAudioLanguage = Check.assigned(audio)
            ? audio.language
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Audio name as defined by the selected audio rendition.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistAudioName = Check.assigned(audio) ? audio.name : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Starting bitrate (in bps), if set by application.
         * @note For HLS playlists this is the 'BANDWIDTH' property.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.mediaStartBitrate = Check.assigned(variant)
            ? variant.bandwidth
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Subtitle language as defined by a variant playlist.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistSubtitleLanguage = Check.assigned(subtitle)
            ? subtitle.language
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Name of the subtitle.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistSubtitleName = Check.assigned(subtitle)
            ? subtitle.name
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean|undefined}
         * @desc Subtitle visibility.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.subtitleVisibility = Check.assigned(subtitle)
            ? subtitle.forced
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Video codec as defined by a variant playlist.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistVideoCodec = Check.assigned(variant)
            ? variant.videoCodec
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Video range as defined by a variant playlist.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.playlistVideoRange = Check.assigned(variant)
            ? variant.videoRange
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The selected stream URL from the MediaPayload.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
         *
         */
        this.streamUrl = streamUrl;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {SDK.Services.Media.PlaybackAttributes|undefined}
         * @desc Value of `playlistResponse.stream.attributes`.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `fetched`.
         * @note Source from the `SDK.Media.MediaPayload` response while fetching.
         *
         */
        this.attributes = attributes;

        /**
         *
         * @access public
         * @since 16.1.0
         * @type {Array<SDK.Services.Media.PlaybackVariant>|undefined}
         * @desc Value of `playlistResponse.stream.variants`.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `fetched`.
         * @note Source from the `SDK.Media.MediaPayload` response while fetching.
         *
         */
        this.streamVariants = streamVariants;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {SDK.Services.QualityOfService.MediaFetchError|null}
         * @desc Additional error details when fetching media. `null` if no error occurred.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `fetched`.
         * @note Source from the reported error, if any, while fetching.
         *
         */
        this.mediaFetchError = mediaFetchError || null;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc ID representing the DRM Key. Obtained from the HLS Media Playlist, a new key is signalled by EXT-X-KEY tags.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `drmKeyFetched`.
         *
         */
        this.drmKeyId = drmKeyId;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc The peak video bitrate of the current presentationType, in bps.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized` or `variantFetched`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent` when `startupActivity` is `variantFetched`.
         *
         */
        this.videoBitrate = Check.assigned(variant)
            ? variant.bandwidth
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc The average video bitrate of the current presentationType, in bps.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized` or `variantFetched`.
         * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent` when `startupActivity` is `variantFetched`.
         *
         */
        this.videoAverageBitrate = Check.assigned(variant)
            ? variant.averageBandwidth
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Value of `CHANNELS` attribute of variant playlist (if an audio variant).
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
         *
         */
        this.playlistChannels =
            Check.assigned(variant) && Check.assigned(variant.audioChannels)
                ? variant.audioChannels.toString()
                : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Value of `NAME` attribute (for audio and subtitle variants).
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
         *
         */
        this.playlistName = playlistName;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Value of `LANGUAGE` attribute (for audio and subtitle variants).
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
         *
         */
        this.playlistLanguage = playlistLanguage;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.MediaSegmentType>|undefined}
         * @desc What type of content a media segment contains.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
         *
         */
        this.mediaSegmentType = mediaSegmentType;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Resolution as defined by a variant playlist.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
         *
         */
        this.playlistResolution = Check.assigned(variant)
            ? variant.resolution
            : undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number|undefined}
         * @desc Frame Rate as reported by the playlist service.
         * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
         * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
         *
         */
        this.playlistFrameRate = Check.assigned(variant)
            ? variant.frameRate
            : undefined;

        /**
         *
         * @access public
         * @since 20.0.0
         * @type {Number|undefined}
         * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
         * @note Source from `SDK.MonotonicTimestampManager.getTimestamp()`.
         *
         */
        this.monotonicTimestamp = monotonicTimestamp || undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Object}
         * @desc Associated content keys for the media item.
         * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
         * @note Source from the `SDK.Media.PlaybackContext`.
         *
         */
        this.contentKeys = contentKeys || {};

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String<SDK.Services.QualityOfService.QoePlaybackError>|undefined}
         * @desc An error representing a fatal playback failure. `null` if no error occurred.
         * @note Can optionally be provided if `playbackActivity` is `ended`.
         * @note If an error occurred, SDKs should convert either the associated MediaFetchError or a PlaybackError to a QoePlaybackError.
         *
         */
        this.playbackError = playbackError || null;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc Supporting error text for `playbackError` property where this can be provided for additional context.
         * @note Unless requested otherwise, set to native platform error message if available.
         * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
         * @note Source from the `SDK.Media.PlaybackEndedEvent`.
         *
         */
        this.playbackErrorDetail = playbackErrorDetail;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Array<String>|undefined}
         * @desc List of CDNs attempted in case of failure cases e.g. {Fastly, Akamai, Level-3}.
         * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
         *
         */
        this.cdnRequestedTrail = cdnRequestedTrail || undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Array<String>|undefined}
         * @desc List of CDNs failed e.g. {Fastly, Akamai}.
         * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
         *
         */
        this.cdnFailedTrail = cdnFailedTrail || undefined;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Number}
         * @desc Value increments from 0 for each fallback action. This value initializes at zero for the
         * first CDN attempt, and will increment by 1 for each fallback action that occurs.
         * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
         *
         */
        this.cdnFallbackCount = cdnFallbackCount || 0;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {Boolean}
         * @desc False if first cdn selected successful i.e. `cdnFailedTrail` empty, True otherwise.
         * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
         *
         */
        this.isCdnFallback = isCdnFallback || false;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when testing PQM.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.clientGroupIds = clientGroupIds;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {String|undefined}
         * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
         * @note Source from the playlist request payload response from the `qosDecisions` object.
         *
         */
        this.serverGroupIds = serverGroupIds;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.QualityOfService.AdInsertionType>|undefined}
         * @desc The way ads are inserted into the stream.
         * @note Conditionally required if `startupActivity` = `fetched`.
         * @note Source by converting the `SDK.Media.MediaDescriptor.AssetInsertionStrategy`.
         *
         */
        this.adInsertionType = adInsertionType;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String<SDK.Services.Media.SubscriptionType>}
         * @desc An identifier that provides insight into the tier of service associated with the subscription that
         * is entitled for playback.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` != `none`.
         * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
         *
         */
        this.subscriptionType = subscriptionType || '';

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Number|undefined}
         * @desc The number of total planned ad pods.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` != `none`.
         * @note Source by getting the count of the MediaPayload.MediaPayloadStream.Insertion.Points collection.
         * Each `InsertionPoint` represents an Ad Pod.
         *
         */
        this.totalPodCount = totalPodCount;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {String|undefined}
         * @desc Random UUID assigned by the ad server.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` != `none`.
         * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
         *
         */
        this.adSessionId = adSessionId;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Number|undefined}
         * @desc The total number of planned ad slots.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` = `ssai`.
         * @note Source by calculating the sum of each `InsertionPoint.plannedSlotCount` from the
         * MediaPayload.MediaPayloadStream.Insertion.Points.
         *
         */
        this.totalSlotCount = totalSlotCount;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Number|undefined}
         * @desc The total length of planned ads in milliseconds.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` = `ssai`.
         * @note Source by calculating the sum of each `InsertionPoint.duration` from the
         * MediaPayload.MediaPayloadStream.Insertion.Points.
         *
         */
        this.totalAdLength = totalAdLength;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Number}
         * @desc The status code of the create ad session request.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` is `ssai` or `sgai`.
         * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.responseCode field.
         *
         */
        this.createAdSessionResponseCode = createAdSessionResponseCode || 0;

        /**
         *
         * @access public
         * @since 19.0.0
         * @type {Number|undefined}
         * @desc The status code of the get pods request.
         * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` = `ssai`.
         * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.getPods.responseCode field.
         *
         */
        this.getPodsResponseCode = getPodsResponseCode;

        /**
         *
         * @access public
         * @since 21.1.1
         * @type {String}
         * @desc The CDN running the server.
         *
         */
        this.cdnName = 'null';

        /**
         *
         * @access public
         * @since 21.1.1
         * @type {String}
         * @desc The vendor of the CDN.
         *
         */
        this.cdnVendor = 'null';

        /**
         *
         * @access public
         * @since 21.1.1
         * @type {String}
         * @desc Name of the CDN, appended with Origin.
         *
         */
        this.cdnWithOrigin = 'null';

        this.setQosData(qos);
        this.setData(data);
        this.setData(startupContext);
    }

    // #region private

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [qos]
     * @desc Assign QoS data.
     * @note IMPORTANT: The key/value pairs from the qos HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "qos" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    setQosData(qos) {
        if (Check.nonEmptyObject(qos)) {
            this.fixProductType(qos);

            Object.assign(this, qos);
        }
    }

    /**
     *
     * @access private
     * @since 17.0.3
     * @param {Object} qos
     * @desc Fix the incoming productType to be the proper enum casing for qos as it differs from the media playlist tracking casing
     *
     */
    fixProductType(qos) {
        let fixedProductType = qos.productType;

        if (fixedProductType) {
            const valueLower = fixedProductType.toLowerCase();

            if (valueLower === ProductType.live.toLowerCase()) {
                fixedProductType = ProductType.live;
            } else if (valueLower === ProductType.vod.toLowerCase()) {
                fixedProductType = ProductType.vod;
            }

            qos.productType = fixedProductType;
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    setData(data) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {String} cdnVendor
     * @desc Used when CDN has failed and is attempting to fallback to the next.
     *
     */
    fallbackAttempt(cdnVendor = null) {
        if (this.isCdnFallback) {
            this.cdnFallbackCount++;
        }

        if (!this.cdnRequestedTrail) {
            this.cdnRequestedTrail = [cdnVendor];
        } else {
            this.cdnRequestedTrail.push(cdnVendor);
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @desc Used when CDN fails and builds the failure trail.
     *
     */
    fallbackFailed() {
        this.isCdnFallback = true;
        this.cdnFailedTrail = Array.from(this.cdnRequestedTrail);
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.PlaybackStartupEventData';
    }

    // #endregion
}
