/**
 *
 * @module hlsJsPlayerAdapter
 * @desc Standard HLS.js PlayerAdapter
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.com/video-dev/hls.js/blob/master/doc/API.md#runtime-events
 * @see https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import InternalEvents from './../../internalEvents';
import DrmProvider from '../../drm/drmProvider';
import PlayerAdapter from './../playerAdapter';
import PlaybackMetrics from './../playbackMetrics';
import PlaybackEventListener from './../playbackEventListener';
import Playlist from './../playlist';

import PlaybackExitedCause from '../../services/qualityOfService/playbackExitedCause';
import QoePlaybackError from '../../services/qualityOfService/qoePlaybackError';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import StartupActivity from './../../services/qualityOfService/startupActivity';

import circularReplacer from '../../services/util/circularReplacer';
import adEngineRegex from '../../services/util/adEngineRegex';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

import { MediaAnalyticsKey } from './../enums';

/**
 *
 * @desc Interface used to communicate with the media player.
 *
 */
export default class HlsJsPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The HlsJsPlayerAdapter requires nativePlayer.loadSource
     *
     */
    constructor(options) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        loadSource: Types.function
                    })
                })
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {Object}
         * @desc The {HTMLMediaElement} tied to the nativePlayer instance.
         *
         */
        this.mediaElement = this.nativePlayer.media;

        /**
         *
         * @access private
         * @since 5.0.0
         * @desc Callback for xhr requests for the player.
         *
         */
        this.xhrSetupCallback = this.setupXhrCallback.bind(this);

        /**
         *
         * @access private
         * @since 10.0.0
         * @type {Boolean}
         * @desc A flag to keep track of whether playback has started.
         *
         */
        this.hasStarted = false;

        /**
         *
         * @access private
         * @since 10.0.0
         * @type {SDK.Media.Playlist|null}
         * @desc The playlist to be used during playback.
         *
         */
        this.playlist = null;

        /**
         *
         * @access private
         * @since 10.0.0
         * @type {SDK.Services.QualityOfService.PlaybackStartupEventData}
         * @desc Keeps track of CDN fallback for QoS support.
         *
         */
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        /**
         *
         * @access private
         * @since 10.0.0
         * @type {Number}
         * @desc The ID for the CDN fallback timer.
         *
         */
        this.cdnFallbackTimerId = 0;

        /**
         *
         * @access private
         * @since 10.0.0
         * @type {Boolean}
         * @desc A flag to keep track of whether the CDN fallback timer has timed out.
         *
         */
        this.cdnFallbackTimeoutReached = false;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @throws {InvalidStateException} Unable to set playlistUri on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        const { nativePlayer, xhrSetupCallback, playbackStartupEventData } =
            this;

        return new Promise((resolve, reject) => {
            this.playlist = playlist;
            this.playlistUri = playlist.streamUri;
            this.currentStreamUrl = playlist.streamUri;

            try {
                const qos = playlist.getTrackingData(MediaAnalyticsKey.qos);

                // keeps track of the CDN fallback attempts
                playbackStartupEventData.fallbackAttempt(qos.cdnVendor);

                if (playbackStartupEventData.isCdnFallback) {
                    playbackStartupEventData.setQosData(qos);
                    this.onPlaybackReattempt(playbackStartupEventData);
                }

                nativePlayer.config.xhrSetup = (xhr, url) => {
                    xhrSetupCallback(xhr, url);
                };

                if (this.cdnFallbackTimerId === 0) {
                    this.cdnFallbackTimerId = setTimeout(() => {
                        this.cdnFallbackTimeoutReached = true;
                    }, this.cdnFallback.defaultTimeout * 1000);
                }

                nativePlayer.loadSource(playlist.streamUri);

                return resolve();
            } catch (ex) {
                const errorMsg = `${this.toString()}.setSource(playlist) unable to set playlistUri on NativePlayer`;

                const reasons = [new ErrorReason('', `${errorMsg} - ${ex}`)];
                const exceptionData = ExceptionReference.common.invalidState;

                return reject(new ServiceException({ reasons, exceptionData }));
            }
        });
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        this.playlistUri = '';
        this.drmProvider = null;
        this.playbackReattemptEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        this.cdnFallbackTimerId = 0;
        this.cdnFallbackTimeoutReached = false;
        this.hasStarted = false;

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }
        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @param {String} error
     * @param {Boolean} isEnded
     * @desc Trigger when playback has been exited.
     * @note in version `4.6.0` this was renamed from `playbackExitedEvent` to `playbackEndedEvent`
     *
     */
    playbackEndedEvent(error, isEnded) {
        let cause;
        let isAdvanceableError = false;

        if (Check.assigned(error)) {
            cause = PlaybackExitedCause.error;
            isAdvanceableError =
                error === QoePlaybackError.serviceError ||
                error === QoePlaybackError.unknown;
        } else if (isEnded) {
            cause = PlaybackExitedCause.playedToEnd;
        } else {
            cause = PlaybackExitedCause.user;
        }

        const playbackEndedEvent = {
            cause,
            errorDetail: error,
            playheadPosition: this.currentPlayhead
        };

        const {
            cdnFallback,
            playlist,
            hasStarted,
            playbackStartupEventData,
            cdnFallbackTimeoutReached
        } = this;

        if (Check.assigned(error)) {
            // sets isCdnFallback to true and updates the cdnFailedTrail property
            playbackStartupEventData.fallbackFailed();
        }

        playbackEndedEvent.cdnRequestedTrail =
            playbackStartupEventData.cdnRequestedTrail;
        playbackEndedEvent.cdnFailedTrail =
            playbackStartupEventData.cdnFailedTrail;
        playbackEndedEvent.cdnFallbackCount =
            playbackStartupEventData.cdnFallbackCount;
        playbackEndedEvent.isCdnFallback =
            playbackStartupEventData.isCdnFallback;

        this.onPlaybackEnded(playbackEndedEvent);

        if (
            isAdvanceableError &&
            cdnFallback.isEnabled &&
            !cdnFallbackTimeoutReached &&
            playbackEndedEvent.cdnFallbackCount < cdnFallback.fallbackLimit &&
            !hasStarted &&
            playlist &&
            playlist.advanceNextSource()
        ) {
            const { mediaSourceIndex, mediaSources } = playlist;
            const mediaSource = mediaSources[mediaSourceIndex] || {};

            this.emit(InternalEvents.UpdateAdEngine, mediaSource.priority);
            this.setSource(playlist);
        }
    }

    // #region protected

    /**
     *
     * @access protected
     * @since 2.0.0
     * @desc Gets a snapshot of information about media playback.
     * @throws {InvalidStateException} Unable to get NativePlayer playhead or bitrate data
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        if (Check.not.assigned(this.mediaElement.currentTime)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer playhead data`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        const currentPlayhead = this.mediaElement.currentTime;

        return new PlaybackMetrics({ currentPlayhead });
    }

    /**
     *
     * @access protected
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { boundHandlers, mediaElement, nativePlayer } = this;
        const {
            playbackStartedEvent,
            playbackPausedEvent,
            playbackEndedEvent,
            successfulPlaylistLoad
        } = this;

        if (Check.function(mediaElement.play)) {
            this.listener = listener;

            boundHandlers.onPlay = playbackStartedEvent.bind(this);
            boundHandlers.onPause = playbackPausedEvent.bind(this);
            boundHandlers.playbackEndedEvent = playbackEndedEvent.bind(this);
            boundHandlers.successfulPlaylistLoad =
                successfulPlaylistLoad.bind(this);

            boundHandlers.onPlayedToCompletion = () => {
                boundHandlers.playbackEndedEvent.call(this, null, true);
            };

            boundHandlers.onError = (ex = {}) => {
                let error = null;

                try {
                    error = JSON.stringify(ex, circularReplacer());
                } catch (exx) {
                    error = 'Cannot stringify original error';
                }

                boundHandlers.playbackEndedEvent.call(this, error);

                // removes listener on fatal error
                return this.removeListener(this.listener);
            };

            mediaElement.onplay = boundHandlers.onPlay;
            mediaElement.onpause = boundHandlers.onPause;
            mediaElement.onerror = boundHandlers.onError;
            mediaElement.onended = boundHandlers.onPlayedToCompletion;

            if (Check.assigned(nativePlayer.on)) {
                nativePlayer.on(
                    'MANIFEST_LOADED',
                    boundHandlers.successfulPlaylistLoad
                );
            }
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { mediaElement, boundHandlers, nativePlayer } = this;

        if (Check.function(mediaElement.play) && this.listener === listener) {
            this.listener = null;

            mediaElement.onplay = null;
            mediaElement.onpause = null;
            mediaElement.onerror = null;
            mediaElement.onended = null;

            if (Check.assigned(nativePlayer.off)) {
                nativePlayer.off(
                    'MANIFEST_LOADED',
                    boundHandlers.successfulPlaylistLoad
                );
            }
        }
    }

    /**
     *
     * @access protected
     * @since 3.2.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    setDrmProvider(drmProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProvider: Types.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProvider', params, arguments);
        }

        this.drmProvider = drmProvider;

        return Promise.resolve();
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {String}
     *
     */
    getCdnName() {
        const trackingInfo = this.playlist.getTrackingData(
            MediaAnalyticsKey.qos
        );

        return trackingInfo.cdnName;
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     * @since 5.0.0
     * @desc Trigger when playback starts.
     *
     */
    playbackStartedEvent() {
        if (!this.hasStarted) {
            this.onPlaybackStarted({});
        }

        this.hasStarted = true;
    }

    /**
     *
     * @access private
     * @since 5.0.0
     * @desc Trigger when playback gets paused.
     *
     */
    playbackPausedEvent() {
        this.onPlaybackPaused({});
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @param {Object} eventData
     * @note Handles the MANIFEST_LOADED event to update tracking when successful playlist loads.
     *
     */
    successfulPlaylistLoad(eventData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                eventData: Types.object()
            };

            typecheck(this, 'successfulPlaylistLoad', params, arguments);
        }

        const { playlist } = eventData;

        if (playlist && playlist.tracking) {
            this.onSuccessfulPlaylistLoad(playlist);
        }
    }

    /**
     *
     * @access private
     * @since 5.0.0
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    setupXhrCallback(xhr, url) {
        const { accessToken, adEngineData = {} } = this;
        const { ssess } = adEngineData;

        const isAdEngineCall = adEngineRegex(url);

        xhr.withCredentials = false;

        if (!xhr.readyState) {
            xhr.open('GET', url, true);
        }

        if (isAdEngineCall && Check.assigned(ssess)) {
            xhr.withCredentials = true;
            xhr.setRequestHeader('ssess', ssess);
        } else {
            if (
                url.indexOf('/keys/') > -1 ||
                url.indexOf('/obtain-license/') > -1
            ) {
                xhr.setRequestHeader('Authorization', accessToken);
            }
        }
    }

    /**
     *
     * @access public
     * @since 3.1.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
        this.mediaElement = null;
        this.playbackStartupEventData = null;
        this.cdnFallbackTimerId = 0;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.HlsJsPlayerAdapter';
    }

    // #endregion
}
