/**
 *
 * @module entry
 * @desc {SDK.Services.Socket} entry point.
 *
 */

/**
 *
 * @typedef {Object} SDK.Services.Socket
 *
 */
export default {};
