/**
 *
 * @module entry
 * @desc {SDK.Orchestration} entry point.
 *
 */

import OrchestrationApi from './orchestrationApi';
import OrchestrationManager from './orchestrationManager';

/**
 *
 * @typedef {Object} SDK.Orchestration
 *
 * @property {OrchestrationApi} OrchestrationApi
 * @property {OrchestrationManager} OrchestrationManager
 *
 */
export default {
    OrchestrationApi,
    OrchestrationManager
};
