/**
 *
 * @module paywallManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/paywall.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import PaywallClient from '../services/paywall/paywallClient';

import PaywallManagerConfiguration from '../services/configuration/paywallManagerConfiguration';
import TokenManager from '../token/tokenManager';
import LogTransaction from '../logging/logTransaction';
import AccessToken from '../token/accessToken';
import PurchaseContext from '../services/paywall/purchaseContext';

/**
 *
 * @access protected
 * @since 3.9.0
 * @desc Manages the paywall and serves as a go between for the public APIs and the PaywallClient.
 *
 */
export default class PaywallManager {
    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Services.Configuration.PaywallManagerConfiguration}
     *
     */
    private config: PaywallManagerConfiguration;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {SDK.Services.Paywall.PaywallClient}
     *
     */
    public client: PaywallClient;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 3.9.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Services.Configuration.PaywallManagerConfiguration} options.config
     * @param {SDK.Services.Paywall.PaywallClient} options.client
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        config: PaywallManagerConfiguration;
        client: PaywallClient;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(PaywallManagerConfiguration),
                    client: Types.instanceStrict(PaywallClient),
                    tokenManager: Types.instanceStrict(TokenManager),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, tokenManager, logger } = options;

        this.config = config;

        this.client = client;

        this.tokenManager = tokenManager;

        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 3.9.0
     * @param {String} [purchaseContextId] - A context ID used by paywall to lookup the correct SKU.
     * @param {SDK.Services.Paywall.PurchaseContext} [purchaseContext] - Purchase context to identify the page this request originated from.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns a paywall object containing sku, campaign and account context information for
     * a given country, platform and account/device.
     * @returns {Promise<SDK.Services.Paywall.Paywall>}
     *
     */
    public async getPaywall(
        purchaseContextId: string,
        purchaseContext: keyof typeof PurchaseContext,
        logTransaction: LogTransaction
    ) {
        return await this.client.getPaywall(
            this.accessToken as AccessToken,
            logTransaction,
            purchaseContextId,
            purchaseContext
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the TokenManager instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken();
    }

    /**
     *
     * @access public
     *
     */
    public toString() {
        return 'SDK.Paywall.PaywallManager';
    }
}
