/**
 *
 * @module purchaseClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Purchase.PurchaseClientEndpoint
 *
 * @property {String} redeemPurchases
 * @property {String} restorePurchases
 *
 */
enum PurchaseClientEndpoint {
    redeemPurchases = 'redeemPurchases',
    restorePurchases = 'restorePurchases'
}

export default PurchaseClientEndpoint;
