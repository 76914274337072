/**
 *
 * @module validationResult
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ValidationResultType from './validationResultType';

/**
 *
 * @access protected
 * @since 4.11.0
 * @desc Base class for all validation results. Subclass is determined by the validationResultType field.
 *
 */
export default class ValidationResult {
    /**
     *
     * @access public
     * @since 4.11.0
     * @type {SDK.Services.Internal.Telemetry.ValidationResultType}
     *
     */
    public validationResultType: ValueOf<typeof ValidationResultType>;

    /**
     *
     * @access public
     * @since 4.11.0
     * @type {String|undefined}
     *
     */
    public message?: string;

    /**
     *
     * @param {Object} options
     * @param {String} options._type
     * @param {String} [options.message]
     *
     */
    public constructor(options: { _type: string; message?: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    _type: Types.string,
                    message: Types.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { _type, message } = options;

        const type = Object.values(ValidationResultType).find(
            (t) => t === _type
        );

        this.validationResultType = type || ValidationResultType.other;
        this.message = message;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Telemetry.ValidationResult';
    }
}
