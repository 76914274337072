/**
 *
 * @module spriteThumbnailSet
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ThumbnailSet from './thumbnailSet';

export default class SpriteThumbnailSet extends ThumbnailSet {
    /**
     *
     * @param {Object} options
     * @param {Number} options.thumbnailRows
     * @param {Number} options.thumbnailColumns
     * @param {Number} options.quality
     * @note params below are checked in the base class
     * @param {String<SDK.Media.ThumbnailResolution>} options.resolution
     * @param {Number} options.thumbnailWidth
     * @param {Number} options.thumbnailHeight
     * @param {Number} options.intervalMilliseconds
     * @param {Number} options.totalBytes
     * @param {Array<SDK.Services.Media.Presentation>} options.presentations
     *
     */
    constructor(options) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    thumbnailRows: Types.number,
                    thumbnailColumns: Types.number,
                    quality: Types.number
                })
            };

            typecheck(this, params, arguments);
        }

        const { thumbnailRows, thumbnailColumns, quality } = options;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The number of rows of thumbnails in each sprite sheet
         *
         */
        this.thumbnailRows = thumbnailRows;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The number of rows of thumbnails in each sprite sheet
         *
         */
        this.thumbnailColumns = thumbnailColumns;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Quality equates to jpeg quality, which represents the loss due to compression
         *
         */
        this.quality = quality;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.SpriteThumbnailSet';
    }

    // #endregion
}
