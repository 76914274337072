/**
 *
 * @module refreshTokenRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';

/**
 *
 * @since 10.0.0
 *
 */
export default class RefreshTokenRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: { refreshToken: string }) {
        const query = RefreshTokenRequest.query;

        const variables = {
            input
        };

        super({ query, variables, operationName: 'refreshToken' });
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs//orchestration_graphql/query/refreshToken.md
     * @returns {String}
     *
     */
    public static get query() {
        return `mutation refreshToken($input: RefreshTokenInput!) {
            refreshToken(refreshToken: $input) {
                activeSession {
                    sessionId
                }
            }
        }`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.RefreshTokenRequest';
    }
}
