/**
 *
 * @module flowControlPolicyType
 *
 */

/**
 *
 * @enum
 * @since 4.11.0
 * @typedef {Object} SDK.Socket.FlowControlPolicyType
 * @desc Flow Control policy enum used by the SDK clients to set the associated
 * flow rate behavior for a specified event type.
 *
 * @property {String} unthrottledCustom - Since `4.11.0`.
 * @property {String} throttled - Since `4.11.0`.
 * @property {String} paused - Since `4.11.0`.
 *
 * @example
 * FlowControlPolicyType.unthrottledCustom // 'urn:dss:event-edge:flow-control:policy:unthrottled-custom'
 *
 */
export default {
    unthrottledCustom:
        'urn:dss:event-edge:flow-control:policy:unthrottled-custom',
    throttled: 'urn:dss:event-edge:flow-control:policy:throttled',
    paused: 'urn:dss:event-edge:flow-control:policy:paused'
};
