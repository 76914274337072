/**
 *
 * @module paymentCardApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    CardPaymentMethodInformation,
    CardPaymentMethodInformationTypedef,
    CreateCardPaymentMethodRequest,
    SubmitOrderWithCardPaymentMethodRequest,
    SubmitOrderWithCardPaymentMethodRequestTypedef
} from './typedefs';

import {
    PriceOrderResponse,
    PriceOrderResponseTypedef
} from '../../services/commerce/typedefs';

import CommerceManager from '../commerceManager';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';
import getSafe from '../../services/util/getSafe';
import Logger from '../../logging/logger';

const DustUrn = DustUrnReference.commerce.paymentCardApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface PaymentCardApiOptions {
    commerceManager: CommerceManager;
    logger: Logger;
}

/**
 *
 * @access public
 * @desc Provides ability to access payment card data.
 *
 */
export default class PaymentCardApi extends BaseApi {
    /**
     *
     * @access private
     * @type {CommerceManager}
     *
     */
    private commerceManager: CommerceManager;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Commerce.CommerceManager} options.commerceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: PaymentCardApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceManager: Types.instanceStrict(CommerceManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceManager } = options;

        this.commerceManager = commerceManager;

        this.dustEnabled = getSafe(
            () => this.commerceManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.PaymentCard.SubmitOrderWithCardPaymentMethodRequest>} request - A description of products to be
     * ordered and the card payment method necessary to process the order.
     * @param {String} [deviceProfile] - A `String` taken from `RequestConnectedDeviceDetailsResult.deviceProfile` if this
     * purchase was made as part of a LicensePlate flow. Otherwise null should be provided.
     * @desc Submit an order for purchasing using a previously stored card payment method. If the order is
     * successfully placed, a unique identifier for the order is returned.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @note the template value `{deviceProfile}` for the optional header `X-BAMTech-Sales-Platform` should be replaced
     * with the deviceProfile argument. If `deviceProfile` argument is null SDKs should send the same `deviceProfile`
     * value used when requesting a DeviceGrant.
     * @returns {Promise<String>} A promise that completes when the operation has succeeded and returns a unique identifier for the order.
     *
     */
    public async submitOrderWithCardPaymentMethod(
        request: SubmitOrderWithCardPaymentMethodRequest,
        deviceProfile?: string
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(
                SubmitOrderWithCardPaymentMethodRequestTypedef
            ),
            deviceProfile: Types.nonEmptyString.optional
        }
    })
    public async submitOrderWithCardPaymentMethod(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        const {
            logTransaction,
            args: [request, deviceProfile]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.submitOrderWithPaymentMethod(
            request,
            deviceProfile,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.PaymentCard.CardPaymentMethodInformation>} paymentInformation - info about the payment method to create.
     * @param {Object<SDK.Services.Commerce.PriceOrderResponse>} priceOrderResponse - Information retrieved from a call to `SDK.Commerce.CommerceApi.PriceOrder()`.
     * @desc Creates a new payment method which case be used later to submit an order.
     * @throws {InvalidPaymentInformationException} Unable to create payment information due to invalid data.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} The payment method ID for the payment that was added.
     *
     */
    public async createCardPaymentMethod(
        paymentInformation: CardPaymentMethodInformation,
        priceOrderResponse: PriceOrderResponse
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            paymentInformation: Types.object(
                CardPaymentMethodInformationTypedef
            ),
            priceOrderResponse: Types.object(PriceOrderResponseTypedef)
        }
    })
    public async createCardPaymentMethod(
        apiOptions: unknown,
        ignoredParam?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        const {
            logTransaction,
            args: [paymentInformation, priceOrderResponse]
        } = apiOptions as ApiOptions;

        const { commerceManager } = this;
        const { creditCardNumber, ...passthroughData } = paymentInformation;
        const { region } = priceOrderResponse;

        passthroughData.usage = passthroughData.isReusable
            ? 'multi_use'
            : 'single_use';

        const namespaceId = getSafe(
            () => commerceManager.client.config.extras.namespaceId
        );

        const request = {
            creditCardNumber,
            namespaceId,
            passthroughData
        } as CreateCardPaymentMethodRequest;

        return await commerceManager.createCardPaymentMethod(
            request,
            region,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.PaymentCard.PaymentCardApi';
    }

    // #endregion
}
