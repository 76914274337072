/**
 *
 * @module socketManagerConfiguration
 *
 */

import SocketClientConfiguration from '../socket/socketClientConfiguration';
import SocketManagerExtrasMap from './socketManagerExtrasMap';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing sockets.
 *
 */
export default class SocketManagerConfiguration extends ServiceConfiguration<
    SocketClientConfiguration,
    SocketManagerExtrasMap
> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.SocketManagerConfiguration';
    }

    // #endregion
}
