/**
 *
 * @module contentApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/content.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/ContentApi.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ContentManager from './contentManager';
import SearchOverrides from './searchOverrides';
import ContentQuery from './contentQuery';
import BookmarksQuery from './bookmarks/bookmarksQuery';
import SearchQuery from './search/searchQuery';
import GraphQlQuery from './graphQlQuery';
import BaseApi from '../baseApi';
import DustDecorators from '../services/internal/dust/dustDecorators';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import getSafe from '../services/util/getSafe';

import AddToWatchlistQuery from './watchlist/addToWatchlistQuery';
import ClearWatchlistQuery from './watchlist/clearWatchlistQuery';
import RemoveFromWatchlistQuery from './watchlist/removeFromWatchlistQuery';
import WatchlistQuery from './watchlist/watchlistQuery';
import type Logger from '../logging/logger';

const DustUrn = DustUrnReference.content.contentApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides a way to access and discover content.
 *
 */
export default class ContentApi extends BaseApi {
    /**
     *
     * @access private
     * @type {SDK.Content.ContentManager}
     *
     */
    private contentManager: ContentManager;

    /**
     *
     * @access private
     * @since 12.0.0
     * @type {Object}
     * @desc Config defaults for query id.
     *
     */
    private queryIdDefaults: Record<string, string>;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param options
     * @param {SDK.Content.ContentManager} options.contentManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        contentManager: ContentManager;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    contentManager: Types.instanceStrict(ContentManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { contentManager } = options;

        this.contentManager = contentManager;
        this.queryIdDefaults =
            // @ts-expect-error TODO: this doesn't appear to exist and doesn't work
            getSafe(() => this.contentManager.client.extras.queryIdDefaults) ||
            {};
        this.dustEnabled = getSafe(
            () => this.contentManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Content.ContentQuery} options.query - The GraphQL query object that defines the query criteria.
     * @param {SDK.Content.SearchOverrides} [options.overrides] - Object which contains override information on the time
     * of origin and or the country of origin to apply to the query request.
     * @param {String} [options.contentTransactionId] - An application developer provided value to be included with the
     * HTTP request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the content services.
     * @throws {InvalidRequestException} The content query request was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that completes when the operation has
     * succeeded and returns the JSON object or string to be interpreted by the application.
     *
     */
    public async query<T>(options: {
        query: ContentQuery;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
    }): Promise<T>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object({
                query: Types.instanceStrict(ContentQuery),
                overrides: Types.instanceStrict(SearchOverrides).optional,
                contentTransactionId: Types.nonEmptyString.optional
            })
        }
    })
    public async query<T>(apiOptions: unknown): Promise<T> {
        const {
            logTransaction,
            dustLogUtility,
            args: [options]
        } = apiOptions as ApiOptions;

        const { query, contentTransactionId } = options;

        dustLogUtility.logData({
            context: query.context,
            contentTransactionId
        });

        return (await this.contentManager.query({
            ...options,
            logTransaction
        })) as T; // TODO make this T flow through...
    }

    /**
     *
     * @access public
     * @param {String} dictionary - The predefined dictionary name to search.
     * @param {String} query - The predefined name of the search query.
     * @param {Number} [limit] - The maximum number of items that can be returned (must be an Integer).
     * @param {String} [filter] - The string filter that should be applied to the query.
     * @desc Gets a set of search suggestions based on end-user input. Useful for type-ahead feature.
     * @note Alternatively, the app dev may choose to perform a query instead to get a more rich set of search results as the user types.
     * @note Returns an Object and not an Array as stated in the spec. Mainly to avoid breaking apps that already integrated.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that completes when the operation has succeeded and
     * returns a Object containing an Array of string(s) search suggestions to allow the end-user to choose from.
     *
     */
    public async getSearchSuggestions(
        dictionary: string,
        query: string,
        limit?: number,
        filter?: string
    ): Promise<{
        suggestions: Array<string>;
    }>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            dictionary: Types.nonEmptyString,
            query: Types.nonEmptyString,
            limit: Types.integer.optional,
            filter: Types.nonEmptyString.optional
        }
    })
    public async getSearchSuggestions(
        apiOptions: unknown,
        ignoreParam1: string, // eslint-disable-line @typescript-eslint/no-unused-vars
        ignoreParam2?: number, // eslint-disable-line @typescript-eslint/no-unused-vars
        ignoreParam3?: string // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<{
        suggestions: Array<string>;
    }> {
        const {
            logTransaction,
            args: [dictionary, query, limit, filter]
        } = apiOptions as ApiOptions;

        return await this.contentManager.getSearchSuggestions(
            dictionary,
            query,
            limit,
            filter,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {SDK.Content.GraphQlQuery} query - Query to be used to retrieve bookmarks.
     * @param {String} [contentTransactionId] - An application developer-provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the bookmark service and returns a set of bookmark items.
     * @returns {Promise<Object>} The GraphQL JSON returned from the bookmark service.
     *
     */
    public async getBookmarks(
        query: GraphQlQuery,
        contentTransactionId?: string
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.instanceStrict(GraphQlQuery),
            contentTransactionId: Types.nonEmptyString.optional
        }
    })
    public async getBookmarks(
        apiOptions: unknown,
        ignoredParam1: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<unknown> {
        const {
            logTransaction,
            args: [query, contentTransactionId]
        } = apiOptions as ApiOptions;

        const bookmarkQuery = new BookmarksQuery(query);

        return await this.contentManager.query({
            query: bookmarkQuery,
            contentTransactionId,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {SDK.Content.GraphQlQuery} query - Query to be used to retrieve content.
     * @param {SDK.Content.SearchOverrides} [overrides] - Object that will override the default date and or location of the search.
     * @param {String} [contentTransactionId] - An application developer-provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the search service and returns a set of search items.
     * @returns {Promise<Object>} The GraphQL JSON returned from the search service.
     *
     */
    public async search(
        query: GraphQlQuery,
        overrides?: SearchOverrides,
        contentTransactionId?: string
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.instanceStrict(GraphQlQuery),
            overrides: Types.instanceStrict(SearchOverrides).optional,
            contentTransactionId: Types.nonEmptyString.optional
        }
    })
    public async search(
        apiOptions: unknown,
        ignoredParam1?: unknown, // eslint-disable-line @typescript-eslint/no-unused-vars
        ignoredParam2?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<unknown> {
        const {
            logTransaction,
            args: [query, overrides, contentTransactionId]
        } = apiOptions as ApiOptions;

        const searchQuery = new SearchQuery(query);

        return await this.contentManager.query({
            query: searchQuery,
            overrides,
            contentTransactionId,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {SDK.Content.GraphQlQuery} query
     * @param {String} [contentTransactionId] - An application developer-provided value to be included with the HTTP.
     * request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the watchlist service and returns a set of watchlist items.
     * @returns {Promise<Object>}
     *
     */
    public async getWatchlist(
        query: GraphQlQuery,
        contentTransactionId?: string
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.instanceStrict(GraphQlQuery),
            contentTransactionId: Types.nonEmptyString.optional
        }
    })
    public async getWatchlist(
        apiOptions: unknown,
        ignoredParam1?: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ): Promise<unknown> {
        const {
            logTransaction,
            args: [query, contentTransactionId]
        } = apiOptions as ApiOptions;

        const watchlistQuery = new WatchlistQuery(query);

        return await this.contentManager.query({
            query: watchlistQuery,
            contentTransactionId,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {Array<String>} contentIds
     * @param {String} [contentTransactionId] - An application developer-provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the watchlist service and removes a set of items from the watchlist.
     * @returns {Promise<Object>}
     *
     */
    public async removeFromWatchlist(
        contentIds: Array<string>,
        contentTransactionId?: string
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            contentIds: Types.array.of.nonEmptyString,
            contentTransactionId: Types.nonEmptyString.optional
        }
    })
    public async removeFromWatchlist(
        apiOptions: unknown,
        ignoredParam1: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        const {
            logTransaction,
            args: [contentIds, contentTransactionId]
        } = apiOptions as ApiOptions;

        const removeFromWatchlistQuery = new RemoveFromWatchlistQuery(
            contentIds,
            this.queryIdDefaults.removeFromWatchlist
        );

        return await this.contentManager.query({
            query: removeFromWatchlistQuery,
            contentTransactionId,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {Array<String>} contentIds
     * @param {String} [contentTransactionId] - An application developer-provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the watchlist service and adds a set of items from the watchlist.
     * @returns {Promise<Object>}
     *
     */
    public async addToWatchlist(
        contentIds: Array<string>,
        contentTransactionId?: string
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            contentIds: Types.array.of.nonEmptyString,
            contentTransactionId: Types.nonEmptyString.optional
        }
    })
    public async addToWatchlist(
        apiOptions: unknown,
        ignoredParam1: unknown // eslint-disable-line @typescript-eslint/no-unused-vars
    ) {
        const {
            logTransaction,
            args: [contentIds, contentTransactionId]
        } = apiOptions as ApiOptions;

        const addToWatchlistQuery = new AddToWatchlistQuery(
            contentIds,
            this.queryIdDefaults.addToWatchlist
        );

        return await this.contentManager.query({
            query: addToWatchlistQuery,
            contentTransactionId,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 4.8.0
     * @desc Queries the watchlist service and removes all items from the watchlist.
     * @returns {Promise<Object>}
     *
     */
    public async clearWatchlist(): Promise<unknown>;

    @apiMethodDecorator()
    public async clearWatchlist(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        const clearWatchlistQuery = new ClearWatchlistQuery(
            this.queryIdDefaults.clearWatchlist
        );

        return await this.contentManager.query({
            query: clearWatchlistQuery,
            logTransaction
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.ContentApi';
    }
}
