/**
 *
 * @module entry
 * @desc {SDK.Commerce} entry point.
 *
 */

import BrowserInfoProvider from './browserInfoProvider';
import CommerceApi from './commerceApi';
import CommerceManager from './commerceManager';

import Comcast from './comcast/entry';
import IDeal from './iDeal/entry';
import Klarna from './klarna/entry';
import Mercado from './mercado/entry';
import PaymentCard from './paymentCard/entry';
import PayPal from './payPal/entry';

/**
 *
 * @typedef {Object} SDK.Commerce
 *
 * @property {BrowserInfoProvider} BrowserInfoProvider
 * @property {CommerceApi} CommerceApi
 * @property {CommerceManager} CommerceManager
 * @property {SDK.Commerce.Comcast} Comcast
 * @property {SDK.Commerce.IDeal} IDeal
 * @property {SDK.Commerce.Mercado} Mercado
 * @property {PaymentMethodInformation} PaymentMethodInformation
 * @property {SDK.Commerce.PaymentCard} PaymentCard
 * @property {SDK.Commerce.PayPal} PayPal
 *
 */
export default {
    BrowserInfoProvider,
    CommerceApi,
    CommerceManager,
    Comcast,
    IDeal,
    Klarna,
    Mercado,
    PaymentCard,
    PayPal
};
