/**
 *
 * @module drmManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Media/DigitalRightsManagement.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccessTokenProvider from '../token/accessTokenProvider';
import Logger from '../logging/logger';

import DrmClient from '../services/drm/drmClient';
import DrmManagerConfiguration from '../services/configuration/drmManagerConfiguration';
import PlatformProviders from '../services/providers/platformProviders';
import getSafe from '../services/util/getSafe';
import type CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import type MediaItem from '../media/mediaItem';
import type LogTransaction from '../logging/logTransaction';
import type PlayReadyMessage from '../services/drm/playReadyMessage';
import AccessToken from '../token/accessToken';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';

type LicenseOptions = {
    endpointKey?: keyof typeof DrmClientEndpoint;
    mediaItem: MediaItem;
    playheadPosition?: number;
    logTransaction: LogTransaction;
};

/**
 *
 * @access protected
 * @since 3.2.0
 * @desc Provides a manager that can be used to facilitate DRM protected playback.
 *
 */
export default class DrmManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Drm.DrmManagerConfiguration}
     *
     */
    public config: DrmManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Drm.DrmClient}
     *
     */
    private client: DrmClient;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public clientId: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public environment: string;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    public storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @since 4.0.0
     * @desc used to enable dust logging
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {SDK.Services.Drm.DrmManagerConfiguration} drmManagerConfiguration
     * @param {SDK.Services.Drm.DrmClient} drmClient
     * @param {SDK.Token.AccessTokenProvider} accessTokenProvider
     * @param {String} clientId
     * @param {String} environment
     * @param {SDK.Token.Storage} storage
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(options: {
        drmManagerConfiguration: DrmManagerConfiguration;
        drmClient: DrmClient;
        accessTokenProvider: AccessTokenProvider;
        clientId: string;
        environment: string;
        storage: CoreStorageProvider;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    drmManagerConfiguration: Types.instanceStrict(
                        DrmManagerConfiguration
                    ),
                    drmClient: Types.instanceStrict(DrmClient),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider),
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    storage: Types.instanceStrict(PlatformProviders.Storage),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { drmManagerConfiguration, drmClient, accessTokenProvider } =
            options;
        const { clientId, environment, storage, logger } = options;

        /**
         *
         * @access private
         * @type {SDK.Services.Drm.DrmManagerConfiguration}
         *
         */
        this.config = drmManagerConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Services.Drm.DrmClient}
         *
         */
        this.client = drmClient;

        /**
         *
         * @access private
         * @type {SDK.Token.AccessTokenProvider}
         *
         */
        this.accessTokenProvider = accessTokenProvider;

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.clientId = clientId;

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.environment = environment;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {SDK.Services.PlatformProviders.Storage}
         *
         */
        this.storage = storage;

        /**
         *
         * @access private
         * @type {Boolean}
         * @since 4.0.0
         * @desc used to enable dust logging
         *
         */
        this.dustEnabled = getSafe(() => this.client.dustEnabled, false);

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.keyLocation - The URL of the decryption key.
     * @param {SDK.Media.MediaItem} options.mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {Number} [options.playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves a SILK decryption key from the key service.
     * @returns {Promise<Uint8Array>} The decryption key for this media asset.
     *
     */
    public async getSilkKey(options: {
        keyLocation: string;
        mediaItem: MediaItem;
        playheadPosition?: number;
        logTransaction: LogTransaction;
    }) {
        const { accessToken } = this;

        return await this.client.getSilkKey({
            ...options,
            accessToken
        });
    }

    /**
     *
     * @access public
     * @param {SDK.Media.MediaItem} mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Retrieves the BAMTECH FairPlay Application Certificate.
     * @note This certificate is the same for all partners. It will be hosted statically and can be stored on a long-term basis.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the BAMTECH FairPlay Application Certificate as binary data.
     *
     */
    public async getFairPlayCertificate(
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        return await this.client.getFairPlayCertificate(
            this.accessToken,
            mediaItem,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Uint8Array} options.serverPlaybackContext - A server playback context created by the
     * DRM client (typically directly by the player or the platform).
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {Number} [options.playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a FairPlay content key context from the license service
     * to authorize and decrypt media.
     * @note Nearly all FairPlay DRM clients will provide a mechanism to create the
     * server playback context. The SDK shall leverage these capabilities wherever possible.
     * @returns {Promise<Uint8Array>} A Promise fulfilled by a content key context from
     * the license server.
     *
     */
    public async getFairPlayLicense(
        options: LicenseOptions & {
            serverPlaybackContext: Uint8Array;
        }
    ) {
        const { accessToken } = this;

        return await this.client.getFairPlayLicense({
            ...options,
            accessToken
        });
    }

    /**
     *
     * @access public
     * @param {SDK.Media.MediaItem} mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets a certificate required for decrypting Widevine protected content.
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public async getWidevineCertificate(
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        return await this.client.getWidevineCertificate(
            this.accessToken,
            mediaItem,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Uint8Array} options.buffer - A protocol buffer conforming to the Widevine
     * specification identifying the asset and encryption mode.
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {Number} [options.playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a Widevine license from the license service.
     * @note Nearly all Widevine DRM clients will provide a mechanism to create the
     * Widevine protocol buffer. The SDK shall leverage these capabilities wherever possible.
     * @returns {Promise<Uint8Array>} A Promise fulfilled with the Widevine license as
     * binary data.
     *
     */
    public async getWidevineLicense(
        options: LicenseOptions & {
            buffer: Uint8Array;
        }
    ) {
        const { accessToken } = this;

        return await this.client.getWidevineLicense({
            ...options,
            accessToken
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Services.Drm.PlayReadyMessage} options.message - A configured
     * `PlayReadyMessage` containing any required headers and a PlayReadyObject body.
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {Number} [options.playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a PlayReady license from the license service.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled with the PlayReady license as
     * binary data.
     *
     */
    public async getPlayReadyLicense(
        options: LicenseOptions & {
            message: PlayReadyMessage;
        }
    ) {
        const { accessToken } = this;

        return await this.client.getPlayReadyLicense({
            ...options,
            accessToken
        });
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {SDK.Media.MediaItem} mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets a certificate (operator vault) required for decrypting Nagra protected content.
     * @returns {Promise<Object>}
     *
     */
    public async getNagraCertificate(
        mediaItem: MediaItem,
        logTransaction: LogTransaction
    ) {
        return await this.client.getNagraCertificate(
            this.accessToken,
            mediaItem,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Drm.DrmManager';
    }
}
