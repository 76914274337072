/**
 *
 * @module coreStorageProvider
 *
 */

import { Types, typecheck, Check } from '@dss/type-checking';

import Logger from '../../../logging/logger'; // eslint-disable-line no-unused-vars
import { IStore } from '../store/IStore';

interface CoreStorageProviderOptions {
    store: IStore;
    logger: Logger;
}

/**
 *
 * @access protected
 * @desc Core storage provider implementation.
 *
 */
export default class CoreStorageProvider {
    /**
     *
     * @access private
     * @type {IStore}
     * @desc Storage instance.
     *
     */
    private store: IStore;

    /**
     *
     * @access private
     * @type {Array<String>}
     * @desc Array of stored keys.
     *
     */
    private storedKeys: Array<string>;

    /**
     *
     * @access protected
     * @type {SDK.Logging.Logger}
     * @desc logger instance.
     *
     */
    protected logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {Object} options.store
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: CoreStorageProviderOptions) {
        const { store, logger } = options;

        this.store = store;
        this.storedKeys = [];
        this.logger = logger;
    }

    /**
     *
     * @access public
     * @param {String} key
     * @param {*} value
     * @desc Sets a key/value pair in storage.
     * @returns {Promise<Void>}
     *
     */
    public async set(key: string, value: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                key: Types.nonEmptyString,
                value: Types.skip
            };

            typecheck(this, 'set', params, arguments);
        }

        this.storedKeys.push(key);

        await this.store.setItem(key, value);
    }

    /**
     *
     * @access public
     * @param {String} key
     * @desc Retrieves a value for a given key from storage.
     * @returns {Promise<*>}
     *
     */
    public async get(key: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                key: Types.nonEmptyString
            };

            typecheck(this, 'get', params, arguments);
        }

        const value = await this.store.getItem(key);

        if (Check.not.assigned(value)) {
            // If data does not exist in memory or local storage return an empty object.
            this.logger.warn(
                'Storage',
                `${this.toString()}.get(key) reference cache key "${key}" does not exist`
            );
        }

        return value;
    }

    /**
     *
     * @access public
     * @param {String} key
     * @desc Removes a given key from storage.
     * @returns {Promise<Void>}
     *
     */
    public async remove(key: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                key: Types.nonEmptyString
            };

            typecheck(this, 'remove', params, arguments);
        }

        // remove item and key from reference
        this.storedKeys.splice(this.storedKeys.indexOf(key), 1);

        await this.store.removeItem(key);
    }

    /**
     *
     * @access public
     * @desc Removes all known keys from storage, items added outside of the
     * current Storage instance will be unaffected.
     * @returns {Promise<Void>}
     *
     */
    public async clear() {
        // remove all known keys
        await Promise.all(
            this.storedKeys.map(async (key) => {
                await this.store.removeItem(key);
            })
        );

        this.storedKeys = [];
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Shared.CoreStorageProvider';
    }
}
