/**
 *
 * @module playbackMetricsProvider
 *
 */

import { EventEmitter } from 'events';

import PlaybackMetrics from './playbackMetrics';

/**
 *
 * @see https://nodejs.org/api/events.html
 * @since 2.0.0
 *
 */
export default class PlaybackMetricsProvider extends EventEmitter {
    /**
     *
     * @access public
     * @type {String}
     * @since 7.0.0
     * @desc Used to indicate which stream url is currently being played in `cdnFallback` for QoE purposes.
     *
     */
    public currentStreamUrl: string;

    /**
     *
     * @access public
     * @type {String}
     * @since 2.0.0
     *
     */
    public playlistUri: string;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @since 4.0.0
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @since 4.0.0
     *
     */
    public videoPlayerVersion?: string;

    public constructor() {
        super();

        this.playlistUri = '';
        this.currentStreamUrl = '';
    }

    /**
     *
     * @access public
     * @desc Gets a snapshot of information about media playback.
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     *
     */
    public getPlaybackMetrics(): PlaybackMetrics {
        throw new Error(
            `${this.toString()}.getPlaybackMetrics() - not-implemented`
        );
    }

    /**
     *
     * @access public
     * @param {PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public addListener(listener: TodoAny): void {
        throw new Error(
            `${this.toString()}.addListener(listener) - not-implemented`
        );
    }

    /**
     *
     * @access public
     * @param {PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public removeListener(listener: TodoAny): void {
        throw new Error(
            `${this.toString()}.removeListener(listener) - not-implemented`
        );
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher`. Implemented in each `PlayerAdapter` instance.
     * @returns {Object}
     *
     */
    public getHeartbeatData(): TodoAny {
        return {};
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher`. Implemented in each `PlayerAdapter` instance.
     * @returns {String}
     *
     */
    public getCdnName(): string {
        return 'null';
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher. Implemented in each `PlayerAdapter` instance.
     * @returns {Number}
     *
     */
    public getAudioBitrate(): number {
        return 0;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher. Implemented in each `PlayerAdapter` instance.
     * @returns {Number}
     *
     */
    public getMaxAllowedVideoBitrate(): number {
        return 0;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlaybackMetricsProvider';
    }
}
