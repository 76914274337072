/**
 *
 * @module product
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import ProductEntitlement from './productEntitlement';
import ProductStatus from './productStatus';
import { BundleType } from '../services/subscription/enums';

import ErrorReason from '../services/exception/errorReason';
import ProductRule from '../services/subscription/productRule';

/**
 *
 * @desc Represents a product a user is subscribed to.
 *
 */
export default class Product {
    /**
     *
     * @access public
     * @type {Array<String>}
     * @desc An array of category codes associated with the product.
     *
     */
    public categoryCodes: Array<string>;

    /**
     *
     * @access public
     * @type {Number|undefined}
     * @desc Gets the ID.
     *
     */
    public id?: number;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @desc Gets the name of the product.
     *
     */
    public name?: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets the SKU.
     *
     */
    public sku: string;

    /**
     *
     * @access public
     * @type {SDK.Subscription.ProductStatus}
     *
     */
    public status: ValueOf<typeof ProductStatus>;

    /**
     *
     * @access public
     * @type {SDK.Services.Exception.ErrorReason|null}
     * @desc Gets the error reason if one is associated with the product.
     *
     */
    public error: Nullable<ErrorReason>;

    /**
     *
     * @access public
     * @type {Array<SDK.Subscription.ProductEntitlement>}
     * @desc Gets an array of product entitlements.
     *
     */
    public entitlements: Array<ProductEntitlement>;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {String|undefined}
     * @desc Gets the partner associated with the product.
     *
     */
    public partner?: string;

    /**
     *
     * @access private
     * @type {SDK.Services.Subscription.ProductRule|null}
     * @desc Gets the product rule.
     *
     */
    public rule: Nullable<ProductRule>;

    /**
     *
     * @access public
     * @since 5.0.0
     * @type {SDK.Services.Subscription.BundleType}
     * @desc Gets the type of bundle.
     *
     */
    public bundleType: ValueOf<typeof BundleType>;

    /**
     *
     * @param {Object} options
     * @param {Array<String>} [options.categoryCodes=[]]
     * @param {Object} [options.error=null]
     * @param {Number} [options.id]
     * @param {String} [options.name]
     * @param {String} [options.partner]
     * @param {Array<Object>} [options.productEntitlements=[]]
     * @param {Object} [options.rule=null]
     * @param {String} options.sku
     * @param {String} options.status
     * @param {SDK.Services.Subscription.BundleType} [options.bundleType]
     *
     */
    public constructor(options: {
        categoryCodes?: Array<string>;
        error?: Nullable<Record<string, string>>;
        id?: number;
        name?: string;
        partner?: string;
        productEntitlements?: Array<Record<string, unknown>>;
        rule?: Nullable<Record<string, unknown>>;
        sku: string;
        status: string;
        bundleType?: ValueOf<typeof BundleType>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    categoryCodes: Types.array.of.nonEmptyString.optional,
                    error: Types.object({
                        code: Types.nonEmptyString,
                        description: Types.nonEmptyString
                    }).optional,
                    id: Types.number.optional,
                    name: Types.nonEmptyString.optional,
                    partner: Types.nonEmptyString.optional,
                    productEntitlements: Types.array.of.nonEmptyObject.optional,
                    rule: Types.nonEmptyObject.optional,
                    sku: Types.nonEmptyString,
                    status: Types.nonEmptyString,
                    bundleType: Types.in(BundleType).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            categoryCodes = [],
            error,
            id,
            name,
            partner,
            productEntitlements = [],
            rule,
            sku,
            status,
            bundleType
        } = options;

        let errorReason: Nullable<ErrorReason> = null;

        if (Check.assigned(error)) {
            errorReason = new ErrorReason(
                error?.code as string,
                error?.description
            );
        }

        this.categoryCodes = categoryCodes;
        this.id = id;
        this.name = name;
        this.sku = sku;
        this.status =
            ProductStatus[status as ValueOf<typeof ProductStatus>] ||
            ProductStatus.UNKNOWN;
        this.error = errorReason;

        this.entitlements = productEntitlements.map(
            (entitlement: TodoAny) => new ProductEntitlement(entitlement)
        );

        this.partner = partner;
        this.rule = rule ? new ProductRule(rule) : null;
        this.bundleType = bundleType as ValueOf<typeof BundleType>;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Subscription.Product';
    }
}
