/**
 *
 * @module customerServiceManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/customer-service-code.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import CustomerServiceClient from '../services/customerService/customerServiceClient';

import CustomerServiceManagerConfiguration from '../services/configuration/customerServiceManagerConfiguration';
import LogTransaction from '../logging/logTransaction';
import AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @desc Provides a manager to be used to find a customer's current context.
 *
 */
export default class CustomerServiceManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.CustomerServiceManagerConfiguration}
     *
     */
    private config: CustomerServiceManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.CustomerService.CustomerServiceClient}
     *
     */
    private client: CustomerServiceClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     * @memberof {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Services.Configuration.CustomerServiceManagerConfiguration} config
     * @param {SDK.Services.CustomerService.CustomerServiceClient} customerServiceClient
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(
        config: CustomerServiceManagerConfiguration,
        customerServiceClient: CustomerServiceClient,
        logger: Logger
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                config: Types.instanceStrict(
                    CustomerServiceManagerConfiguration
                ),
                customerServiceClient: Types.instanceStrict(
                    CustomerServiceClient
                ),
                logger: Types.instanceStrict(Logger)
            };

            typecheck(this, params, arguments);
        }

        this.config = config;

        this.client = customerServiceClient;

        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @desc Creates a customer service code for this user for use in support.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<String>}
     *
     */
    public async createSupportCode(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<string> {
        const customerSupportCode = await this.client.createSupportCode(
            accessToken,
            logTransaction
        );

        this.logger.info(this.toString(), 'Customer support code created');

        return customerSupportCode;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.CustomerService.CustomerServiceManager';
    }
}
