/**
 *
 * @module entry
 * @desc {SDK.Flex} entry point.
 *
 */

import FlexApi from './flexApi';
import FlexManager from './flexManager';

/**
 *
 * @typedef {Object} SDK.Flex
 *
 * @property {FlexApi} FlexApi
 * @property {FlexManager} FlexManager
 *
 */
export default {
    FlexApi,
    FlexManager
};
