/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import InsertionMode from './insertionMode';
import InsertionPointPlacement from './insertionPointPlacement';

import { SubscriptionType, AssetInsertionStrategyQos } from './enums';

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AdSession
 * @since 18.0.0
 * @property {String} id - Since 18.0.0 - Session ID
 * @property {String} [getPodUrl] - Since 18.0.0 - Url to get the ad pod for the GetPod call.
 *
 */
export interface AdSession {
    id: string;
    getPodUrl?: string;
}

/**
 *
 * @access private
 *
 */
export const AdSessionTypedef = {
    id: Types.nonEmptyString,
    getPodUrl: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.InsertionPoint
 * @since 18.0.0
 * @desc Base class for SGAI or SSAI insertion points.
 * @property {String} id - Since 18.0.0 - insertion point ID.
 * @property {Number} offset - Since 18.0.0 - Time of insertion point in ms, relative to the beginning of the primary content.
 * @property {String<SDK.Services.Media.InsertionPointPlacement>} placement - Since 18.0.0 - insertion placement type.
 *
 */
export interface InsertionPoint {
    id: string;
    offset: number;
    placement: ValueOf<typeof InsertionPointPlacement>;
}

/**
 *
 * @access private
 *
 */
export const InsertionPointTypedef = {
    id: Types.nonEmptyString,
    offset: Types.number,
    placement: Types.in(InsertionPointPlacement)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.Insertion
 * @since 18.0.0
 * @property {String<SDK.Services.Media.InsertionMode>} mode - Since 18.0.0 - ssai or sgai insertion mode.
 * @property {Object<SDK.Services.Media.AdSession>} [adSession] - Since 18.0.0 - Ad session details associated with the requested playback.
 * @property {Array<Object<SDK.Services.Media.InsertionPoint>>} [points] - Since 18.0.0 - Insertion point data represented as `SgaiVodInsertionPoint` or `SsaiVodInsertionPoint`, depending on `Insertion.mode`. This field is optional, its presence depends on the types of media sources included in the response.
 *
 */
export interface Insertion {
    mode: ValueOf<typeof InsertionMode>;
    adSession?: AdSession;
    points?: Array<InsertionPoint>;
}

/**
 *
 * @access private
 *
 */
export const InsertionTypedef = {
    mode: Types.in(InsertionMode),
    adSession: Types.object(AdSessionTypedef).optional,
    points: Types.array.of.object(InsertionPointTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.InsertionUrlInfo
 * @since 18.0.0
 * @param {String} [options.base] - Since 18.0.0 - Url without parameters, example: "http://vod-amc-sa-east-s3-1.media.dssott.com/hint"
 * @param {String} [options.queryParams] - Since 18.0.0 - Request parameters, example: "?r=720&v=1"
 *
 */
export interface InsertionUrlInfo {
    base?: string;
    queryParams?: string;
}

/**
 *
 * @access private
 *
 */
export const InsertionUrlInfoTypedef = {
    base: Types.nonEmptyString.optional,
    queryParams: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.ClientDecisions
 * @since 15.0.0
 * @property {Object} clientSettings
 * @property {String} clientGroupIds
 *
 */
export interface ClientDecisions {
    clientSettings: Record<string, unknown>;
    clientGroupIds: string;
}

/**
 *
 * @access private
 *
 */
export const ClientDecisionsTypedef = {
    clientSettings: Types.object().optional,
    clientGroupIds: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.ServerDecisions
 * @since 15.0.0
 * @property {Object} serverSettings
 * @property {String} serverGroupIds
 *
 */
export interface ServerDecisions {
    serverSettings: Record<string, unknown>;
    serverGroupIds: string;
}

/**
 *
 * @access private
 *
 */
export const ServerDecisionsTypedef = {
    serverSettings: Types.object().optional,
    serverGroupIds: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.UrlInfo
 * @since 18.0.0
 * @param {String} [options.base] - Since 18.0.0 - Scheme and host, example: 'https://disney.com'
 * @param {String} [options.path] - Since 18.0.0 - URL path, example: '/beacon'
 * @param {String} [options.queryParams] - Since 18.0.0 - Request parameters, example: '?x=1&y=2'
 * @param {Object} [options.tracking] - Since 18.0.0 - Tracking data
 * @param {String} [options.url] - Since 18.0.0 - Media URL composed on the server side.
 *
 */
export interface UrlInfo {
    base?: string;
    path?: string;
    queryParams?: string;
    tracking?: Record<string, unknown>;
    url?: string;
}

/**
 *
 * @access private
 *
 */
export const UrlInfoTypedef = {
    base: Types.nonEmptyString.optional,
    path: Types.nonEmptyString.optional,
    queryParams: Types.nonEmptyString.optional,
    tracking: Types.nonEmptyObject.optional,
    url: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.SourceInfo
 * @since 18.0.0
 * @param {Number} options.priority - Since 18.0.0 - CDN fallback priority
 * @param {Object<SDK.Services.Media.UrlInfo>} [options.slide] - Since 18.0.0 - `slide` playlist
 * @param {Object<SDK.Services.Media.UrlInfo>} [options.complete] - Since 18.0.0 - `complete` playlist
 * @param {Object<SDK.Services.Media.InsertionUrlInfo>} [options.insertion] - Since 18.0.0 - media insertion URL
 *
 */
export interface SourceInfo {
    priority: number;
    slide?: UrlInfo;
    complete?: UrlInfo;
    insertion?: InsertionUrlInfo;
}

/**
 *
 * @access private
 *
 */
export const SourceInfoTypedef = {
    priority: Types.integer,
    slide: Types.object(UrlInfoTypedef).optional,
    complete: Types.object(UrlInfoTypedef).optional,
    insertion: Types.object(InsertionUrlInfoTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.QosDecisions
 * @since 15.0.0
 * @property {Object<SDK.Services.Media.ClientDecisions>} clientDecisions
 * @property {Object<SDK.Services.Media.ServerDecisions>} serverDecisions
 *
 */
export interface QosDecisions {
    clientDecisions: ClientDecisions;
    serverDecisions: ServerDecisions;
}

/**
 *
 * @access private
 *
 */
export const QosDecisionsTypedef = {
    clientDecisions: Types.object(ClientDecisionsTypedef).optional,
    serverDecisions: Types.object(ServerDecisionsTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.QosDecisionsResponse
 * @since 15.0.0
 * @property {Object} complete
 * @property {String} slide
 *
 */
export interface QosDecisionsResponse {
    complete: Record<string, unknown>;
    slide: string;
}

/**
 *
 * @access private
 *
 */
export const QosDecisionsResponseTypedef = {
    complete: Types.object(QosDecisionsTypedef).optional,
    slide: Types.object(QosDecisionsTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AdSessionQos
 * @since 18.0.0
 * @desc QoS metrics about the ad session.
 * @property {String} [id] - Since 18.0.0 - The ad session id.
 * @property {Number} responseCode - Since 18.0.0 - The response code from the createAdSession call to the ad server.
 * @property {String} [errorMessage] - Since 18.0.0 - The error message if the createAdSession call failed.
 *
 */
export interface AdSessionQos {
    id?: string;
    responseCode: number;
    errorMessage?: string;
}

/**
 *
 * @access private
 *
 */
export const AdSessionQosTypedef = {
    id: Types.nonEmptyString.optional,
    responseCode: Types.number,
    errorMessage: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.GetPodsQos
 * @since 18.0.0
 * @desc QoS metrics about the getPods call.
 * @property {Number} responseCode - Since 18.0.0 - The response code from the getPods call to the ad server.
 * @property {String} [errorMessage] - Since 18.0.0 - The error message if the getPods call failed.
 *
 */
export interface GetPodsQos {
    responseCode: number;
    errorMessage?: string;
}

/**
 *
 * @access private
 *
 */
export const GetPodsQosTypedef = {
    responseCode: Types.number,
    errorMessage: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AdsQos
 * @since 18.0.0
 * @desc Ad-related QoS metrics for a piece of media.
 * @property {String<SDK.Services.Media.SubscriptionType>} subscriptionType - Since 18.0.0 - The type of subscription used for this piece of media.
 * @property {String<SDK.Services.Media.AssetInsertionStrategyQos>} assetInsertionStrategy - Since 18.0.0 - The asset insertion strategy used for this piece of media.
 * @property {Object<SDK.Services.Media.AdSessionQos>} [adSession] - Since 18.0.0 - The QOS data from the createAdSession call to the ad service.
 * @property {Object<SDK.Services.Media.GetPodsQos>} [getPods] - Since 18.0.0 - The QOS data from the getPods call to the ad service. (Only for SSAI clients).
 *
 */
export interface AdsQos {
    subscriptionType: ValueOf<typeof SubscriptionType>;
    assetInsertionStrategy: ValueOf<typeof AssetInsertionStrategyQos>;
    adSession?: AdSessionQos;
    getPods?: GetPodsQos;
}

/**
 *
 * @access private
 *
 */
export const AdsQosTypedef = {
    subscriptionType: Types.in(SubscriptionType),
    assetInsertionStrategy: Types.in(AssetInsertionStrategyQos),
    adSession: Types.object(AdSessionQosTypedef).optional,
    getPods: Types.object(GetPodsQosTypedef).optional
};
