/**
 *
 * @module userActivityEvent
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @interface UserActivityEvent
 * @desc creates a key for referencing UserActivity-related events
 *
 */
export default class UserActivityEvent {
    /**
     *
     * @access private
     * @type {String}
     * @note stores the event urn referenced from `SDK.Services.Internal.Dust.DustUrnReference` or a custom urn
     *
     * @example new UserActivityEvent(SDK.Services.Internal.Dust.DustUrnReference.userActivity.glimpse.appLoaded)
     * @example new UserActivityEvent('urn:dss:something:custom') // when you need something custom
     *
     */
    public eventUrn: string;

    /**
     *
     * @param {String} eventUrn
     *
     */
    public constructor(eventUrn: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                eventUrn: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.eventUrn = eventUrn;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.UserActivity.UserActivityEvent';
    }
}
