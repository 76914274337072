/**
 *
 * @module contentManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/content.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';

import ContentClient from '../services/content/contentClient';
import ContentManagerConfiguration from '../services/configuration/contentManagerConfiguration';

import AccessTokenProvider from '../token/accessTokenProvider';
import SearchOverrides from './searchOverrides';
import LogTransaction from '../logging/logTransaction';

import type ContentQuery from './contentQuery';
import type AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @desc Provides a manager that can be used to access content data.
 *
 */
export default class ContentManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.ContentManagerConfiguration}
     *
     */
    private config: ContentManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.Content.ContentClient}
     *
     */
    public client: ContentClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Services.Configuration.ContentManagerConfiguration} contentManagerConfiguration
     * @param {SDK.Services.Content.ContentClient} contentClient
     * @param {SDK.Logging.Logger} logger
     * @param {SDK.Token.AccessTokenProvider} accessTokenProvider
     *
     */
    public constructor(
        contentManagerConfiguration: ContentManagerConfiguration,
        contentClient: ContentClient,
        logger: Logger,
        accessTokenProvider: AccessTokenProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                contentManagerConfiguration: Types.instanceStrict(
                    ContentManagerConfiguration
                ),
                contentClient: Types.instanceStrict(ContentClient),
                logger: Types.instanceStrict(Logger),
                accessTokenProvider: Types.instanceStrict(AccessTokenProvider)
            };

            typecheck(this, params, arguments);
        }

        this.config = contentManagerConfiguration;
        this.accessTokenProvider = accessTokenProvider;
        this.client = contentClient;
        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Content.ContentQuery} options.query Query to be used to retrieve content.
     * @param {SDK.Content.SearchOverrides} [options.overrides] - Object which contains override information on the time
     * of origin and or the country of origin to apply to the query request
     * @param {String} [options.contentTransactionId] - An application developer-provided value to be included with the
     * HTTP request as the value of the `X-Content-Transaction-ID` header.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the a content service based on query context.
     * @returns {Promise<Object>} The GraphQL JSON returned from the content service.
     *
     */
    public async query(options: {
        query: ContentQuery;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
        logTransaction: LogTransaction;
    }) {
        const { query, overrides, contentTransactionId, logTransaction } =
            options;

        this.logger.info(this.toString(), 'Getting content.');

        const queryOptions = {
            context: query.context,
            queryBuilder: query.query,
            accessToken: this.accessToken,
            overrides,
            contentTransactionId,
            logTransaction
        };

        return await this.client.query(queryOptions);
    }

    /**
     *
     * @access public
     * @param {String} dictionary - The predefined dictionary name to search.
     * @param {String} query - The predefined name of the search query.
     * @param {Number} [limit] - The maximum number of items that can be returned (must be an Integer).
     * @param {String} [filter] - The string filter that should be applied to the query.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets search suggestions.
     * @returns {Promise<Object>} An Object containing a list of search suggestions.
     *
     */
    public async getSearchSuggestions(
        dictionary: string,
        query: string,
        limit: number | undefined,
        filter: string | undefined,
        logTransaction: LogTransaction
    ): Promise<{
        suggestions: Array<string>;
    }> {
        return await this.client.getSearchSuggestions(
            dictionary,
            query,
            limit as TodoAny,
            filter,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh `AccessToken` from the `AccessTokenProvider` instance.
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Content.ContentManager';
    }
}
