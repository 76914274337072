/**
 *
 * @module logger
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/logging.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/master/docs/logging/Logging.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import LogSink from './logSink';
import LogEvent from './logEvent';
import LogLevel from './logLevel';
import ConsoleSink from './sinks/consoleSink';
import AnalyticsProvider from './analyticsProvider';

/**
 *
 * @desc A logging system that can represent both messages and metric events,
 * and push them to various Sinks (logging output locations). Each sink is responsible
 * for (optionally) processing the event and outputting it. Examples of sinks include
 * the platform console, AWS, files on disk, etc.
 *
 */
export default class Logger {
    /**
     *
     * @access protected
     * @desc default shared Logger instance
     * @returns {Logger}
     */
    public static instance: Logger = new Logger();
    /**
     *
     * @access protected
     * @since 15.0.0
     * @type {String}
     * @desc Root level GUID that groups all events within an instantiated version of an SDK.
     * @note This is assigned when SdkSession#createSdkSession is executed.
     * @note sdkInstanceId is not in Logger in the Spec
     *
     */
    public sdkInstanceId: string;
    /**
     *
     * @access protected
     * @since 15.2.0
     * @type {Object}
     *
     */
    public correlationIds: { [key: string]: string };
    /**
     *
     * @access protected
     * @since 13.0.0
     * @type {SDK.Logging.AnalyticsProvider}
     *
     */
    public analyticsProvider?: AnalyticsProvider;
    /**
     *
     * @access public
     * @type {LogSink[]}
     *
     */
    public sinks: Array<LogSink>;

    /**
     *
     * @access protected
     *
     */
    public constructor() {
        this.sinks = [];
        this.sdkInstanceId = '';
        this.correlationIds = {};
    }

    /**
     *
     * @access private
     * @param {LogSink} sink
     * @returns {Promise<Void>}
     *
     */
    public async addSink(sink: LogSink) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                sink: Types.instanceStrict(LogSink)
            };

            typecheck(this, 'addSink', params, arguments);
        }

        if (this.sinks.includes(sink) === false) {
            this.sinks.push(sink);
        }
    }

    /**
     *
     * @access private
     * @param {LogSink} sink
     * @returns {Promise<Void>}
     *
     */
    public async removeSink(sink: LogSink) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                sink: Types.instanceStrict(LogSink)
            };

            typecheck(this, 'removeSink', params, arguments);
        }

        this.sinks = this.sinks.filter((s) => s !== sink);
    }

    /*# region: public non-api */

    /**
     *
     * @param {Boolean} [state=true] - whether to enable the console sink
     * @returns {Promise<Void>}
     *
     */
    public async console(state = true) {
        this.sinks = this.sinks.filter((sink) =>
            Check.not.instanceStrict(sink, ConsoleSink)
        );

        if (state) {
            this.sinks.push(new ConsoleSink(LogLevel.none));
        }
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @returns {Promise<Void>}
     *
     */
    public log(
        source: string,
        metricOrMessage: object | string,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(source, metricOrMessage, isPublic);
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level none
     * @returns {Promise<Void>}
     *
     */
    public none(
        source: string,
        metricOrMessage: object | string,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.none
        );
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level trace
     * @returns {Promise<Void>}
     *
     */
    public trace(
        source: string,
        metricOrMessage: object | string,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.trace
        );
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level debug
     * @returns {Promise<Void>}
     *
     */
    public debug(
        source: string,
        metricOrMessage: object | string,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.debug
        );
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level info
     * @returns {Promise<Void>}
     *
     */
    public info(
        source: string,
        metricOrMessage: object | string,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.info
        );
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level warn
     * @returns {Promise<Void>}
     *
     */
    public warn(
        source: string,
        metricOrMessage: object | string | unknown,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.warn
        );
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level error
     * @returns {Promise<Void>}
     *
     */
    public error(
        source: string,
        metricOrMessage: object | string | unknown,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.error
        );
    }

    /**
     *
     * @access public
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @desc shorthand for #log with level diagnostics
     * @returns {Promise<Void>}
     *
     */
    public diagnostics(
        source: string,
        metricOrMessage: object | string,
        isPublic = false
    ) {
        return this.logWithOverrideLevel(
            source,
            metricOrMessage,
            isPublic,
            LogLevel.diagnostics
        );
    }

    // #region private

    /**
     *
     * @access private
     * @param {String} source
     * @param {Object|String} metricOrMessage
     * @param {Boolean} [isPublic=false]
     * @param {LogLevel} [level=LogLevel.none]
     * @returns {Promise<Void>}
     *
     */
    private logWithOverrideLevel(
        source: string,
        metricOrMessage: object | string | unknown,
        isPublic = false,
        level = LogLevel.none
    ) {
        // if there is only a source and no message, treat the source as the message
        if (Check.string(source) && Check.undefined(metricOrMessage)) {
            return this.logMessage({
                message: source,
                isPublic,
                level
            });
        }

        if (Check.string(metricOrMessage)) {
            return this.logMessage({
                source,
                message: metricOrMessage,
                isPublic,
                level
            });
        }

        const metric = {
            isPublic,
            level,
            ...(typeof metricOrMessage === 'object' ? metricOrMessage : {})
        };

        return this.logMetric(source, metric);
    }

    /**
     *
     * @access private
     * @param {String} source
     * @param {Object} metric - The DustLogUtility.generateLogEvent() object
     * @returns {Promise<Void>}
     *
     */
    private async logMetric(source: string, metric: TodoAny) {
        const { isPublic, level, name: message, extraData } = metric;

        const event = new LogEvent({
            isPublic,
            level: level || LogLevel.none,
            source,
            message,
            extraData
        });

        for (const sink of this.sinks) {
            sink.log(event);
        }
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} [options.source='']
     * @param {String} options.message
     * @param {Boolean} options.isPublic
     * @param {LogLevel} options.level
     * @returns {Promise<Void>}
     *
     */
    private async logMessage(options: {
        source?: string;
        message: string;
        isPublic: boolean;
        level: LogLevel;
    }) {
        const { source = '', message, isPublic, level } = options;

        const event = new LogEvent({
            source,
            message,
            isPublic,
            level
        });

        for (const sink of this.sinks) {
            sink.log(event);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.Logger';
    }

    // #endregion
}
