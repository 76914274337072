/**
 *
 * @module userActivityApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/user-activity.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/UserActivityApi.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import UserActivityEvent from './userActivityEvent';
import EventSchemataProvider from './eventSchemataProvider';
import BaseApi from '../baseApi';

import ErrorEventData from '../services/qualityOfService/errorEventData';
import QoeError from '../services/qualityOfService/qoeError';

import DustLogUtility from '../services/internal/dust/dustLogUtility';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustCategory from '../services/internal/dust/dustCategory';
import SessionManager from '../session/sessionManager';
import SocketManager from '../socket/socketManager';
import Logger from '../logging/logger';
import { getDataVersion } from '../media/playbackTelemetryDispatcher';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;
const PersonalizationApiDustUrnReference =
    DustUrnReference.userActivity.personalization;

/**
 *
 * @access public
 * @desc The User Activity Api is a public interface for DUST. This api will allow app devs to send custom events,
 * related to user activity while using an app.
 * @since 3.4.0
 *
 */
export default class UserActivityApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Session.SessionManager}
     *
     */
    private sessionManager: SessionManager;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @type {Object}
     * @desc global tracking parameters for Glimpse that we try to apply to all Glimpse payloads
     * @note event specific tracking parameters will overwrite what is in here
     * @note spec says public but we mark this private because we want you to use `this.setTrackingParameters`
     *
     */
    private trackingParameters: Record<string, unknown>;

    /**
     *
     * @access public
     * @type {SDK.UserActivity.EventSchemataProvider}
     * @desc stores and uses schemata to generate event payloads
     * @readonly
     *
     */

    public schemataProvider: EventSchemataProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SessionManager} options.sessionManager
     * @param {SocketManager} options.socketManager
     *
     */
    public constructor(options: {
        logger: Logger;
        sessionManager: SessionManager;
        socketManager: SocketManager;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sessionManager: Types.instanceStrict(SessionManager),
                    socketManager: Types.instanceStrict(SocketManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { sessionManager, socketManager } = options;

        this.sessionManager = sessionManager;

        this.socketManager = socketManager;

        this.trackingParameters = {};

        this.schemataProvider = new EventSchemataProvider();

        this.dustEnabled = true;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {String} rewardToken - The reward token associated with this content recommendation.
     * @param {String} contentId - The ID of content being rewarded, by the defined action.
     * @param {String} action - The action of the reward.
     * @param {Array<String>} recommendedContentIds - Array of content id(s) that were recommended and viewable
     * when the user action occurred.
     * @desc Posts an event for a users content recommendation reward.
     * @returns {Promise<Object>} returns the payload that is sent to dust (for debugging purposes only)
     *
     */
    public async sendContentReward(
        rewardToken: string,
        contentId: string,
        action: string,
        recommendedContentIds: Array<string>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                rewardToken: Types.nonEmptyString,
                contentId: Types.nonEmptyString,
                action: Types.nonEmptyString,
                recommendedContentIds: Types.array.of.nonEmptyString
            };

            typecheck(this, 'sendContentReward', params, arguments);
        }

        const { logger, dustEnabled } = this;

        const data = {
            rewardToken,
            rewardContentId: contentId,
            action,
            recommendedContentIds
        };

        if (dustEnabled) {
            const urn =
                PersonalizationApiDustUrnReference.contentRecommendationAward;

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.personalization,
                logger,
                source: this.toString(),
                urn,
                skipLogTransaction: true,
                data
            });

            dustLogUtility.log();
        }

        return data;
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.UserActivity.UserActivityEvent} event
     * @param {Object} parameters
     * @note we provide an additional `_failures` array on the debugging payload that is returned so the application
     * developer can determine if any schema values were not generated properly
     * @note generally we avoid using underscore pre-fixed names like `_failures` but in this case it's done to ensure
     * we don't accidentally overwrite a value provided by the app dev that is called `failures`
     * @returns {Promise<Object>} returns the payload that is sent to dust (for debugging purposes only)
     *
     */
    public async trackEvent(event: UserActivityEvent, parameters = {}) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                event: Types.instanceStrict(UserActivityEvent),
                parameters: Types.object().optional
            };

            typecheck(this, 'trackEvent', params, arguments);
        }

        const { logger, dustEnabled, schemataProvider } = this;

        const globalTrackingData = {
            ...this.trackingParameters,
            ...parameters
        };
        const schemata = schemataProvider.getSchemata(event);
        const debuggingPayload =
            schemata.generateEventPayload(globalTrackingData);

        if (dustEnabled) {
            const urn = event.eventUrn;

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.glimpse,
                logger,
                source: this.toString(),
                urn,
                skipLogTransaction: true,
                data: {
                    ...debuggingPayload,
                    _failures: undefined // make sure `_failures` is not included
                }
            });

            dustLogUtility.log();
        }

        return debuggingPayload;
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {Object} trackingParameters
     * @desc merges the input to this function with the trackingParameters field on the UserActivityApi - this will
     * overwrite any existing duplicate keys that currently exist
     * @note this method is not in the spec - we provide a setter so app devs don't modify the UserActivityApi instance
     * directly
     *
     */
    public setTrackingParameters(trackingParameters: Record<string, unknown>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                trackingParameters: Types.object()
            };

            typecheck(this, 'setTrackingParameters', params, arguments);
        }

        this.trackingParameters = Object.assign(
            this.trackingParameters,
            trackingParameters
        );
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {String} featureId
     * @desc Used to track a user's exposure to WeaponX experiments.
     * @returns {Promise<Void>}
     *
     */
    public async trackExperimentExposure(featureId: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                featureId: Types.nonEmptyString
            };

            typecheck(this, 'trackExperimentExposure', params, arguments);
        }

        const { sessionManager, socketManager } = this;

        const sessionInfo = await sessionManager.getInfo();
        const { id, account, device, experiments } = sessionInfo;

        const participantId = Check.assigned(account) ? account?.id : device.id;
        const feature = experiments[featureId];
        const { version: featureVersion, variantId } = feature;
        const sessionId = id;
        const exposureTimestamp = new Date().toISOString();
        const originatingSource = socketManager.source;

        const options = {
            participantId,
            featureId,
            featureVersion,
            variantId,
            sessionId,
            exposureTimestamp,
            originatingSource
        };

        return await socketManager.sendExposureExperiment(options);
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.QualityOfService.QoeError} qoeError - The qoeError containing the necessary error details.
     * @descAdds the qoeError to a queue to be logged at a configurable interval. This error may be sourced from the app, sdk, or service.
     * @returns {Promise<Void>}
     * @note App devs should use this method whenever the application encounters an error. This includes when the
     * SDK returns an error either from an internal SDK or service issue.
     *
     */
    public logQoeError(qoeError: QoeError) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                qoeError: Types.instanceStrict(QoeError)
            };

            typecheck(this, 'logQoeError', params, arguments);
        }

        const { logger } = this;
        const analyticsProvider = logger.analyticsProvider;

        let dictionaryVersion;
        let data = {};

        if (this.dustEnabled) {
            if (analyticsProvider) {
                dictionaryVersion = analyticsProvider.getDictionaryVersion();
                data = analyticsProvider.getCommonProperties();
            }

            const errorEventData = new ErrorEventData({
                ...qoeError,
                dictionaryVersion,
                data
            });

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn: QualityOfServiceDustUrnReference.error,
                data: {
                    ...errorEventData
                },
                // skipLogTransaction: true
                dataVersion: getDataVersion(
                    QualityOfServiceDustUrnReference.error
                )
            });

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.UserActivity.UserActivityApi';
    }
}
