/**
 *
 * @module assertion
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import DocumentCode from './documentCode';

//import DocumentCode from './documentCode';

/**
 *
 * @since 4.4.0
 *
 */
export default class Assertion {
    /**
     *
     * @access public
     * @since 4.4.0
     * @type {String<SDK.Services.Paywall.DocumentCode>}
     * @desc Document that consumers will have to review before proceeding to purchase.
     * @note Flexible enum
     *
     */
    public documentCode: keyof typeof DocumentCode;

    /**
     *
     * @access public
     * @since 4.4.0
     * @type {Boolean}
     * @desc Consumer must take action on the document.
     *
     */
    public requiresActiveConsent: boolean;

    /**
     *
     * @access public
     * @since 4.4.0
     * @type {Boolean}
     * @desc Consumer must review the document.
     *
     */
    public requiresActiveReview: boolean;

    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.Paywall.DocumentCode>} options.documentCode
     * @param {Boolean} options.requiresActiveConsent
     * @param {Boolean} options.requiresActiveReview
     *
     */
    public constructor(options: {
        documentCode: keyof typeof DocumentCode;
        requiresActiveConsent: boolean;
        requiresActiveReview: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    documentCode: Types.nonEmptyString, // Types.in(DocumentCode).or.nonEmptyString,
                    requiresActiveConsent: Types.boolean,
                    requiresActiveReview: Types.boolean
                })
            };

            typecheck(this, params, arguments);
        }

        const { documentCode, requiresActiveConsent, requiresActiveReview } =
            options;

        this.documentCode = documentCode;

        this.requiresActiveConsent = requiresActiveConsent;

        this.requiresActiveReview = requiresActiveReview;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.Assertion';
    }
}
