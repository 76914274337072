/**
 *
 * @module restQuery
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import QueryBuilder from './queryBuilder';

import ErrorReason from '../exception/errorReason';
import ExceptionReference from '../exception/exceptionReference';
import ServiceException from '../exception/serviceException';

/**
 *
 * @since 4.7.0
 *
 */
export default class RestQuery extends QueryBuilder {
    /**
     *
     * @access public
     * @since 4.7.0
     * @type {Object}
     *
     */
    public variables: Record<string, unknown>;

    /**
     *
     * @param {Object} variables
     *
     */
    public constructor(variables: Record<string, unknown>) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                variables: Types.object()
            };

            typecheck(this, params, arguments);
        }

        this.variables = variables;
    }

    /**
     *
     * @access private
     * @param {String} url - The URL for which to build the query.
     * @desc Returns the URL for the query.
     * @returns {String} The URL for the query.
     *
     */
    public override createQueryUrl(url: string): string {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                url: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        const { variables } = this;

        let queryUrl = url;

        Object.entries(variables).forEach(([key, value]) => {
            queryUrl = queryUrl.replace(
                `{${key}}`,
                encodeURIComponent(value as string)
            );
        });

        const queryVarError = this.validateQueryVars(queryUrl);

        if (queryVarError) {
            const reasons = [new ErrorReason('', queryVarError)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        return queryUrl;
    }

    /**
     *
     * @access private
     * @desc Returns the post content for the query.
     * @returns {String} The post content for the query.
     *
     */
    public override createPostContent(): string {
        return JSON.stringify(this);
    }

    /**
     *
     * @access private
     * @param {String} queryUrl - the URL to parse
     * @desc Checks for non replaced variables.
     * @returns {String|null} The variables that have not been replaced.
     *
     */
    private validateQueryVars(queryUrl: string): Nullable<string> {
        const urlRegex = /\{\w+\}/g;
        const matches = queryUrl.match(urlRegex);

        if (Check.not.assigned(matches)) {
            return null;
        }

        let listErrors = matches?.join(', ');

        listErrors = listErrors?.replace(/{|}/g, '');

        return `The following variables are missing: ${listErrors}.`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Content.RestQuery';
    }
}
