/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 17.0.0
 * @typedef {Object} SDK.Media.AssetInsertionStrategy
 * @desc An enumeration of possible asset insertion strategies.
 *
 * @property {String} NONE - Asset tags will not be provided in the playlist. Assets will not play (slate only).
 * @property {String} SGAI - SGAI (Server Guided Asset Insertion) - Asset tags will be provided in the playlist.
 * The client is expected to process tags and present assets.
 * @property {String} SSAI - SSAI (Server Side Asset Insertion)- Asset tags will not be provided in the playlist,
 * assets will be stitched into the main stream.
 * @property {String} ADPARTNER - Since `18.0.0` - Enables the legacy ads path for mlb and espn clients.
 *
 * @example
 * AssetInsertionStrategy.SSAI // 'SSAI'
 *
 */
export enum AssetInsertionStrategy {
    NONE = 'NONE',
    SGAI = 'SGAI',
    SSAI = 'SSAI',
    ADPARTNER = 'ADPARTNER'
}

/**
 *
 * @typedef {Object} SDK.Media.HdcpSecurityLevel
 * @since 4.4.0
 * @desc Defines the different types of HDCP support possible
 *
 * @property {String} unknown - Since `4.4.0` - The HDCP security level cannot be determined or an internal display is being used.
 * @property {String} none - Since `4.4.0` - No HDCP security is being used.
 * @property {String} basic - Since `4.4.0` - HDCP 1.0 or higher is supported.
 * @property {String} enhanced - Since `4.4.0` - HDCP 2.2 or higher is supported.
 *
 * @example
 * HdcpSecurityLevel.unknown // 'unknown'
 *
 */
export enum HdcpSecurityLevel {
    unknown = 'unknown',
    none = 'none',
    basic = 'basic',
    enhanced = 'enhanced'
}

/**
 *
 * @enum
 * @since 2.0.0
 * @typedef {Object} SDK.Media.MediaAnalyticsKey
 * @desc Enumerates the known keys for media tracking data.
 *
 * @property {String} conviva - The "conviva" key.
 * @property {String} telemetry - The "telemetry" key.
 * @property {String} qos - The "qos" key.
 * @property {String} adEngine - The "adEngine" key.
 *
 * @example
 * MediaAnalyticsKey.conviva // 'conviva'
 *
 */
export enum MediaAnalyticsKey {
    conviva = 'conviva',
    telemetry = 'telemetry',
    qos = 'qos',
    adEngine = 'adEngine'
}

/**
 *
 * @enum
 * @since 17.0.0
 * @typedef {Object} SDK.Media.PlaybackInitiationContext
 * @desc An enumeration of possible asset insertion contexts. This is a type 1 enum.
 *
 * @property {String} ONLINE - Asset insertion context is for online playback. Ads are expected to be included
 * during playback.
 * @property {String} OFFLINE - Asset insertion context is for offline playback. Ads may not be present during
 * playback, but bumpers will be.
 * @property {String} GROUPWATCH - Asset insertion context is for groupWatch playback. Ads may not be present
 * during playback, but bumpers will be.
 * @property {String} SHAREPLAY - Asset insertion context is for sharePlay playback. Ads may not be present
 * during playback, but bumpers will be.
 *
 * @example
 * PlaybackInitiationContext.ONLINE // 'ONLINE'
 *
 */
export enum PlaybackInitiationContext {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    GROUPWATCH = 'GROUPWATCH',
    SHAREPLAY = 'SHAREPLAY'
}

/**
 *
 * @enum
 * @since 21.0.0
 * @typedef {Object} SDK.Media.SlugDuration
 * @desc Supported slug durations.
 *
 * @property {String} SLUG_1000_MS - Since `21.0.0`
 * @property {String} SLUG_750_MS - Since `21.0.0`
 * @property {String} SLUG_500_MS - Since `21.0.0`
 *
 * @example
 * PlaybackInitiationContext.ONLINE // 'ONLINE'
 *
 */
export enum SlugDuration {
    SLUG_1000_MS = 'SLUG_1000_MS',
    SLUG_750_MS = 'SLUG_750_MS',
    SLUG_500_MS = 'SLUG_500_MS'
}

/**
 *
 * @enum
 * @since 3.8.0
 * @typedef {Object} SDK.Media.ThumbnailResolution
 * @desc Defines the possible thumbnail resolutions available
 *
 * @property {String} low - Supplies "LOW" as the resolution parameter to MediaManager or for identifying
 * the resolution of a `ThumbnailSet`. One of the following: [320x180, 240x180, 320x134].
 * @property {String} medium - Supplies "MED" as the resolution parameter to MediaManager or for identifying
 * the resolution of a `ThumbnailSet`. One of the following: [480x270, 360x270, 480x201].
 * @property {String} high - Supplies "HIGH" as the resolution parameter to MediaManager or for identifying
 * the resolution of a `ThumbnailSet`. One of the following: [640x360, 480x360, 640x268].
 * @property {String} none - Since `4.4.0` - Supplies "NONE" - thumbnail resolution intended for use in identifying
 * the resolution of a `ThumbnailSet` not for requesting a specific set.
 *
 * @example
 * ThumbnailResolution.low // 'LOW'
 *
 */
export enum ThumbnailResolution {
    low = 'LOW',
    medium = 'MED',
    high = 'HIGH',
    none = 'NONE'
}

/**
 *
 * @enum
 * @since 4.2.0
 * @typedef {Object} SDK.Media.WidevineSecurityLevel
 * @desc Defines the different levels of widevine security available.
 *
 * @property {String} level1
 * @property {String} level3
 *
 * @example
 * WidevineSecurityLevel.level1 // 'level1'
 *
 */
export enum WidevineSecurityLevel {
    level1 = 'level1',
    level3 = 'level3'
}
