/**
 *
 * @module accessTokenType
 *
 */

/**
 *
 * @enum
 * @since 4.17.0
 * @typedef {Object} SDK.Services.Token.AccessTokenType
 *
 * @property {String} device - Since `4.17.0`.
 * @property {String} account - Since `4.17.0`.
 * @property {String} accountWithoutActiveProfile - Since `4.17.0`.
 *
 */
export default {
    device: 'Device',
    account: 'Account',
    accountWithoutActiveProfile: 'AccountWithoutActiveProfile'
};
