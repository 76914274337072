/**
 *
 * @module receivedMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import type { RawSocketMessage } from './socketEvent';
import getSafe from '../services/util/getSafe';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import type SocketManager from './socketManager';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.11.1
 * @desc Handles message acknowledgement flows
 *
 */
export default class ReceivedMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.11.1
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        const messageType = SocketUrns.socketManager.received;

        super(messageType);

        this.socketManager = socketManager;
    }

    /**
     *
     * @access protected
     * @since 4.11.1
     * @param {Object} messageData
     * @desc Raises each message out of the SDK through the `SocketManager` `EventEmitter`
     * @note `message.accessToken` is deleted as to not send it on a regular retry message
     *
     */
    public process(messageData: RawSocketMessage) {
        const eventId = getSafe(() => (messageData.data as TodoAny).eventId);
        const status = getSafe(() => (messageData.data as TodoAny).status);
        const retriesExhausted = getSafe(
            () => (messageData.data as TodoAny).retriesExhausted
        );

        if (eventId) {
            for (
                let i = 0, len = this.socketManager.unacknowledgedBuffer.length;
                i < len;
                i++
            ) {
                const item = this.socketManager.unacknowledgedBuffer[i];

                if (eventId === item.id) {
                    // Always remove the message if we get a received event for it
                    this.socketManager.unacknowledgedBuffer.splice(i, 1);

                    const accessToken = item.accessToken;
                    const message = Object.assign({}, item);

                    delete message.accessToken;

                    if (!retriesExhausted) {
                        if (status === 'rejected.internal-failure') {
                            this.socketManager.sendSocketMessage(message);
                        } else if (
                            status === 'rejected.envelope-subject-invalid'
                        ) {
                            this.socketManager.sendSessionAuthentication({
                                message,
                                accessToken
                            });
                        }
                    }

                    break;
                }
            }
        }

        // This is the equivalent to receiving a pong so tell the pingPongMessageProcessor to reset it's ping timer
        this.socketManager.pingPongMessageProcessor.reset();
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.ReceivedMessageProcessor';
    }
}
