/**
 *
 * @module drmProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { EventEmitter } from 'events';

import Logger from '../logging/logger';
import TokenManager from '../token/tokenManager';
import DrmType from '../services/media/drmType';
import DrmUtils from '../services/drm/drmUtils';
import MediaItem from './../media/mediaItem';
import getSafe from '../services/util/getSafe';

import DrmManager from './drmManager';
import DrmClientConfiguration from '../services/drm/drmClientConfiguration';
import type AccessToken from '../token/accessToken';
import DrmClientExtrasMap from '../services/drm/drmClientExtrasMap';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';

/**
 *
 * @since 3.2.0
 * @see https://nodejs.org/api/events.html
 *
 */
export default class DrmProvider extends EventEmitter {
    /**
     *
     * @access protected
     * @type {SDK.Drm.DrmManager}
     *
     */
    protected drmManager: DrmManager;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access protected
     * @type {SDK.Logging.Logger}
     * @note this is `public` at the type level to satisfy the decorator constraint `ApiMethodDecoratorableClass`.
     */
    public logger: Logger;

    /**
     *
     * @access protected
     * @since 7.0.0
     * @type {String}
     * @desc A key that helps the DrmClient determine what endpoint to use.
     *
     */
    protected endpointKey?: keyof typeof DrmClientEndpoint;

    /**
     *
     * @access protected
     * @type {String<SDK.Services.Media.DrmType>}
     * @desc DRM identifier String
     *
     */
    protected type: keyof typeof DrmType;

    /**
     *
     * @access protected
     * @since 15.0.0
     * @type {SDK.Media.MediaItem}
     * @desc Includes context information about the playback attempt used to report playback analytics.
     *
     */
    protected mediaItem: MediaItem;

    /**
     *
     * @access private
     * @type {Boolean}
     * @since 13.0.0
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Drm.DrmManager} options.drmManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {String<SDK.Services.Media.DrmType>} [options.type=DrmType.NONE]
     * @param {String} [options.endpointKey]
     *
     */
    public constructor(options: {
        drmManager: DrmManager;
        tokenManager: TokenManager;
        logger: Logger;
        mediaItem: MediaItem;
        type?: keyof typeof DrmType; // TODO: remove optionality and refactor callers to pass in the type...
        endpointKey?: keyof typeof DrmClientEndpoint;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    drmManager: Types.instanceStrict(DrmManager),
                    tokenManager: Types.instanceStrict(TokenManager),
                    logger: Types.instanceStrict(Logger),
                    mediaItem: Types.instanceStrict(MediaItem),
                    type: Types.in(DrmType).optional,
                    endpointKey: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            drmManager,
            tokenManager,
            logger,
            mediaItem,
            type,
            endpointKey
        } = options;

        this.drmManager = drmManager;
        this.tokenManager = tokenManager;
        this.logger = logger;
        this.endpointKey = endpointKey;
        this.type = type || DrmType.NONE;
        this.mediaItem = mediaItem;
        this.dustEnabled = drmManager.dustEnabled;

        this.logger.log(this.toString(), `Created: ${this.type}`);
    }

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public get licenseRequestUri() {
        const client = getSafe(
            () => this.drmManager.config.client,
            {} as DrmClientConfiguration
        );

        const { endpoints = {}, extras = {} as DrmClientExtrasMap } = client;

        const endpointLicense = DrmUtils.getEndpointLicense(this.type);

        const defaultUri = getSafe(() => endpoints[endpointLicense].href, '');

        const endpointKey = this.endpointKey;

        if (endpointKey !== undefined) {
            const newEndpoint = getSafe(
                () => extras.licenseEndpoints[this.type][endpointKey]
            );

            if (newEndpoint) {
                return getSafe(() => endpoints[newEndpoint].href, defaultUri);
            }
        }

        return defaultUri;
    }

    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public get licenseRequestHeaders() {
        const client = getSafe(
            () => this.drmManager.config.client,
            {} as DrmClientConfiguration
        );

        const { endpoints = {}, extras = {} as DrmClientExtrasMap } = client;

        const endpointLicense = DrmUtils.getEndpointLicense(this.type);

        const defaultHeaders = getSafe(
            () => endpoints[endpointLicense].headers,
            {} as Record<string, string>
        );

        const endpointKey = this.endpointKey;

        if (endpointKey !== undefined) {
            const newEndpoint = getSafe(
                () => extras.licenseEndpoints[this.type][endpointKey]
            );

            return getSafe(
                () => endpoints[newEndpoint].headers,
                defaultHeaders
            );
        }

        return defaultHeaders;
    }

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public get certificateUri() {
        const endpointCertificate = DrmUtils.getEndpointCertificate(this.type);

        return getSafe(
            () =>
                this.drmManager.config.client.endpoints[endpointCertificate]
                    .href,
            ''
        );
    }

    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public get certificateHeaders() {
        const endpointCertificate = DrmUtils.getEndpointCertificate(this.type);

        return getSafe(
            () =>
                this.drmManager.config.client.endpoints[endpointCertificate]
                    .headers,
            {} as Record<string, string>
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access public
     * @desc Returns an object containing a modified copy of license headers if they exist
     * @returns {Object}
     *
     */
    public processLicenseRequestHeaders() {
        const headersCopy = { ...this.licenseRequestHeaders };
        const authHeader = headersCopy.Authorization;

        if (authHeader) {
            headersCopy.Authorization = authHeader.replace(
                '{accessToken}',
                this.accessToken?.token
            );
        }

        return headersCopy;
    }

    /**
     *
     * @access public
     * @param {Object} headers
     * @desc bam-hls expects the headers to come in an array rather than the typical Object format
     * @returns {Array}
     *
     */
    public formatRequestHeadersList(headers: Record<string, string>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                headers: Types.nonEmptyObject
            };

            typecheck(this, 'formatRequestHeadersList', params, arguments);
        }

        return Object.keys(headers).map((item) => {
            return {
                name: item,
                value: headers[item]
            };
        });
    }

    /**
     *
     * @access public
     * @desc Returns an object containing a modified copy of certificate headers if they exist
     * @returns {Object}
     *
     */
    public processCertificateHeaders() {
        const headersCopy = { ...this.certificateHeaders };
        const authHeader = headersCopy.Authorization;

        if (authHeader) {
            headersCopy.Authorization = authHeader.replace(
                '{accessToken}',
                this.accessToken?.token
            );
        }

        return headersCopy;
    }

    /**
     *
     * @access private
     *
     */
    protected override toString() {
        return 'SDK.Drm.DrmProvider';
    }

    // #endregion
}
