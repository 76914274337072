/**
 *
 * @module ripcutPlugin
 * @desc {SDK.Ripcut.RipcutPlugin} entry point.
 *
 */

import Ripcut from './entry';
import RipcutService from '../services/ripcut/entry';

import RipcutClientConfiguration from '../services/ripcut/ripcutClientConfiguration';
import RipcutClient from '../services/ripcut/ripcutClient';

import RipcutManagerConfiguration from '../services/configuration/ripcutManagerConfiguration';

import RipcutApi from './ripcutApi';
import RipcutManager from './ripcutManager';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Ripcut, 'Ripcut', typeof RipcutService> = {
    pluginName: 'Ripcut',
    entry: Ripcut,
    service: RipcutService,

    /**
     *
     * @access protected
     * @since 17.0.0
     * @param {Object} options
     *
     */
    createApi(options: TodoAny) {
        const { sdkSession, logger } = options;
        const { ripcutManager } = sdkSession.managers;

        sdkSession.ripcutApi = new RipcutApi({
            ripcutManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 17.0.0
     * @param {Object} options
     *
     */
    createManager(options: TodoAny) {
        const { managers, logger, httpClient, services } = options;

        const { sessionManager } = managers;

        managers.ripcutManager = new RipcutManager({
            ripcutManagerConfiguration: services.ripcut,
            ripcutClient: new RipcutClient({
                ripcutClientConfiguration: services.ripcut.client,
                logger,
                httpClient
            }),
            sessionManager,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 17.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration: TodoAny, services: TodoAny) {
        const ripcut = services?.ripcut;

        const clientConfiguration = new RipcutClientConfiguration(
            ripcut?.client?.baseUrl,
            serviceDefinitionsConfiguration.processEndpoints(
                ripcut?.client?.endpoints
            )
        );

        serviceDefinitionsConfiguration.ripcut = new RipcutManagerConfiguration(
            clientConfiguration,
            undefined,
            ripcut?.disabled
        );
    }
};

/**
 *
 * @access public
 * @since 17.0.0
 *
 */
export default plugin;
