/**
 *
 * @module accessStatus
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import RedeemRetryHandler from './redeemRetryHandler';
import PurchaseActivationResult from '../services/purchase/purchaseActivationResult';
import LogTransaction from '../logging/logTransaction';
import Logger from '../logging/logger';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import RetryPolicy from '../services/configuration/retryPolicy';
import ErrorReason from '../services/exception/errorReason';
import ExceptionReference from '../services/exception/exceptionReference';
import ServiceException from '../services/exception/serviceException';
import delay from '../services/util/delay';
import PurchaseActivation from '../services/purchase/purchaseActivation';

/**
 *
 *
 */
export default class AccessStatus {
    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc does a user have temporary access to some content
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/purchase.md#http-202
     *
     */
    public isTemporary: boolean;

    /**
     *
     * @access public
     * @type {Array<SDK.Services.Purchase.PurchaseActivation>}
     * @desc Purchase information for all activated SKUs.
     *
     */
    public purchases: Array<PurchaseActivation>;

    /**
     *
     * @access private
     * @type {SDK.Purchase.RedeemRetryHandler}
     * @desc handler used to retry redeem
     *
     */
    private handler?: RedeemRetryHandler;

    /**
     *
     * @access private
     * @type {Number}
     * @desc time between when to retry redeem in milliseconds
     *
     */
    private interval: number;

    /**
     *
     * @access private
     * @type {Number}
     * @desc max time between when to retry in milliseconds
     *
     */
    private retryMaxPeriod: number;

    /**
     *
     * @access private
     * @type {Number}
     * @desc maximum number of attempts to retry
     *
     */
    private retryMaxAttempts: number;

    /**
     *
     * @access private
     * @type {Number}
     * @desc amount to increase the interval
     *
     */
    private retryMultiplier: number;

    /**
     *
     * @access private
     * @type {Number}
     * @desc number of attempts to retry redeem of purchase
     *
     */
    private attempts: number;

    /**
     *
     * @param {SDK.Logging.Logger} logger
     * @param {SDK.Services.Purchase.PurchaseActivationResult} purchaseActivationResult
     * @param {SDK.Services.Configuration.RetryPolicy} policy
     * @param {SDK.Purchase.RedeemRetryHandler} [handler]
     *
     */
    public constructor(
        logger: Logger,
        purchaseActivationResult: PurchaseActivationResult,
        policy: RetryPolicy,
        handler?: RedeemRetryHandler
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger),
                purchaseActivationResult: Types.instanceStrict(
                    PurchaseActivationResult
                ),
                policy: Types.instanceStrict(RetryPolicy),
                handler: Types.instanceStrict(RedeemRetryHandler).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            retryBasePeriod,
            retryMaxPeriod,
            retryMaxAttempts,
            retryMultiplier
        } = policy;

        this.logger = logger;
        this.isTemporary =
            purchaseActivationResult.temporaryAccessGranted || false;
        this.purchases = purchaseActivationResult.purchases;
        this.handler = handler;
        this.interval = retryBasePeriod * 1000;
        this.retryMaxPeriod = retryMaxPeriod * 1000;
        this.retryMaxAttempts = retryMaxAttempts;
        this.retryMultiplier = retryMultiplier;
        this.attempts = 0;
    }

    /**
     *
     * @access public
     * @desc attempts to make purchase permanent
     * @returns {Promise<AccessStatus>}
     * @todo - does not exist in spec.
     *
     */
    public makePermanent(): Promise<AccessStatus> {
        const { interval, retryMaxPeriod } = this;
        const hasRetryMaxPeriod =
            Check.assigned(retryMaxPeriod) && retryMaxPeriod !== 0;

        let retryDelay;

        if (hasRetryMaxPeriod && interval > retryMaxPeriod) {
            retryDelay = retryMaxPeriod;
        } else {
            retryDelay = interval;
        }

        return delay(retryDelay).then(() => {
            return this.retryRedeem();
        });
    }

    /**
     *
     * @access private
     * @desc retries to redeem purchase using the handler
     * @returns {Promise<AccessStatus>}
     *
     */
    private retryRedeem(): Promise<AccessStatus> {
        const { handler, retryMaxAttempts, retryMultiplier } = this;

        if (handler) {
            return LogTransaction.wrapLogTransaction({
                urn: DustUrnReference.purchase.purchaseApi.redeem,
                file: this.toString(),
                logger: this.logger,
                /**
                 *
                 * @param {SDK.Logging.LogTransaction} logTransaction
                 *
                 */
                action: (logTransaction) => {
                    return handler.run(logTransaction).then((result) => {
                        this.attempts++;

                        if (result.isTemporary) {
                            if (this.attempts >= retryMaxAttempts) {
                                const reasons = [
                                    new ErrorReason('', 'Max Retries Reached')
                                ];
                                const exceptionData =
                                    ExceptionReference.common.invalidState;

                                throw new ServiceException({
                                    reasons,
                                    exceptionData
                                });
                            }

                            this.interval *= retryMultiplier;

                            return this.makePermanent();
                        }

                        return result;
                    });
                }
            });
        }
        const reasons = [new ErrorReason('', 'Invalid: For use with Redeem')];
        const exceptionData = ExceptionReference.common.invalidState;

        return Promise.reject(new ServiceException({ reasons, exceptionData }));
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Purchase.AccessStatus';
    }
}
