/**
 *
 * @module accountPlugin
 * @desc {SDK.Account.AccountPlugin} entry point.
 *
 */

import AccountApi from './accountApi';
import AccountManager from './accountManager';
import AccountClient from '../services/account/accountClient';

import AccountService from '../services/account/entry';
import Account from './entry';

import getSafe from '../services/util/getSafe';

import AccountClientConfiguration from '../services/account/accountClientConfiguration';
import AccountManagerConfiguration from '../services/configuration/accountManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Account, 'Account', typeof AccountService> = {
    pluginName: 'Account',
    entry: Account,
    service: AccountService,

    /**
     *
     * @access protected
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;

        const { accountManager, tokenManager, subscriptionManager } =
            sdkSession.managers;

        sdkSession.accountApi = new AccountApi({
            accountManager,
            tokenManager,
            subscriptionManager,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, services, httpClient, orchestrationClient } =
            options;

        const { tokenManager } = managers;

        managers.accountManager = new AccountManager({
            config: services.account,
            client: new AccountClient({
                accountClientConfiguration: services.account.client,
                logger,
                httpClient,
                orchestrationClient
            }),
            logger,
            tokenManager
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const clientConfiguration = new AccountClientConfiguration(
            getSafe(() => services.account.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.account.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.account =
            new AccountManagerConfiguration(
                clientConfiguration,
                undefined,
                getSafe(() => services.account.disabled)
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
