/**
 *
 * @module tokenRequestBuilder
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TokenRequest from './tokenRequest';

/**
 *
 * @desc Interface used to build a token exchange request.
 *
 */
export default class TokenRequestBuilder {
    constructor() {
        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the grant type. This must be a valid type that is recognized by the service.
         *
         */
        this.grantType = '';

        /**
         *
         * @access private
         * @type {Object}
         * @desc token data, used in a TokenRequest
         *
         */
        this.tokenData = Object.create(null);
    }

    /**
     *
     * @access public
     * @param {Number} latitude - The latitude of the device.
     * @param {Number} longitude - The longitude of the device.
     * @desc Builds the token request.
     * @returns {Promise<TokenRequest>} Data required to make a token request.
     *
     */
    async build(latitude, longitude) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                latitude: Types.number,
                longitude: Types.number
            };

            typecheck(this, 'build', params, arguments);
        }

        const { grantType, tokenData } = this;

        return new TokenRequest({ grantType, tokenData, latitude, longitude });
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Token.TokenRequestBuilder';
    }

    // #endregion
}
