/**
 *
 * @module socketEvents
 * @see https://github.com/STRML/keyMirror
 *
 */

/**
 *
 * @enum
 * @access private
 * @typedef {Object} SDK.Socket.SocketEvents
 * @desc Specifies the known socketApi emitted event names
 *
 * @example
 * SocketEvents.connectionStateChanged // 'connectionStateChanged'
 *
 * @property {String} message - Since `4.9.0`.
 * @property {String} exception - Since `4.9.0`.
 * @property {String} tokenRefreshRequested - Since `4.9.0`.
 * @property {String} connectionStateChanged - Since `4.9.0`.
 * @property {String} flowControlError - Since `4.11.0`.
 * @property {String} offDeviceTokenRefresh - Since `4.15.0`.
 * @property {String} ageVerificationChanged - Since `12.0.0`.
 *
 */
enum SocketEvents {
    message = 'message',
    /**
     *
     * @note using `error` here caused random unit test failures so we had to use `exception`
     *
     */
    exception = 'exception',
    tokenRefreshRequested = 'tokenRefreshRequested',
    connectionStateChanged = 'connectionStateChanged',
    flowControlError = 'flowControlError',
    offDeviceTokenRefresh = 'offDeviceTokenRefresh',
    ageVerificationChanged = 'ageVerificationChanged'
}

export default SocketEvents;
