/**
 *
 * @module mediaThumbnailLink
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 *
 */
export default class MediaThumbnailLink {
    /**
     *
     * @param {Object} options
     * @param {String} options.href
     * @param {String} options.rel
     * @param {String} options.method
     * @param {Object} [options.headers]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    href: Types.nonEmptyString,
                    rel: Types.nonEmptyString,
                    method: Types.nonEmptyString,
                    headers: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { href, rel, method, headers } = options;

        /**
         *
         * @access public
         * @type {String}
         * @desc href for a given thumbnail presentation
         *
         */
        this.href = href;

        /**
         *
         * @access public
         * @type {String}
         * @desc The name of the relationship - a HATEOAS header
         *
         */
        this.rel = rel;

        /**
         *
         * @access public
         * @type {String}
         * @desc the method used to fetch the thumbnail presentation - should always be GET
         *
         */
        this.method = method;

        /**
         *
         * @access public
         * @type {Object|undefined}
         * @desc custom headers for this thumbnail service - will be merged with headers from the SDK config
         *
         */
        this.headers = headers;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.MediaThumbnailLink';
    }

    // #endregion
}
