/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import ErrorReason from './../../services/exception/errorReason';

/**
 *
 * @typedef {Object} SDK.Internal.Dust.Location
 * @since 13.0.0
 * @property {String} file
 * @property {Number} [line]
 *
 */
export type Location = {
    file: string;
    line?: number;
};

/**
 *
 * @access private
 *
 */
export const LocationTypedef = {
    file: Types.nonEmptyString,
    line: Types.number.optional
};

/**
 *
 * @typedef {Object} SDK.Internal.Dust.Invocation
 * @since 13.0.0
 * @property {String} urn
 * @property {Object<SDK.Internal.Dust.Location>} [location]
 *
 */
export type Invocation = {
    urn: string;
    location?: Location;
};

/**
 *
 * @access private
 *
 */
export const InvocationTypedef = {
    urn: Types.nonEmptyString,
    location: Types.object(LocationTypedef).optional
};

/**
 *
 * @typedef {Object} SDK.Internal.Dust.ServiceRequest
 * @since 13.0.0
 * @property {String} host - Hostname of the request.
 * @property {String} path - Path of the request.
 * @property {String} method - HTTP method used in the request.
 *
 */
export type ServiceRequest = {
    host: string;
    path: string;
    method: string;
};

/**
 *
 * @access private
 *
 */
export const ServiceRequestTypedef = {
    host: Types.nonEmptyString,
    path: Types.nonEmptyString,
    method: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Internal.Dust.ServiceResponse
 * @since 13.0.0
 * @property {Number} statusCode - HTTP status code returned in the response.
 * @property {String} [requestId] - Platform request identifier, returned as X-Request-ID header in the response.
 * @property {Number} roundTripTime - Duration in milliseconds between request and response, as measured by the client.
 * @property {String} [region] - The AWS region that serviced this request, as indicated by the `X-BAMTech-Region` response header.
 * @property {String} [cloudFrontPop] - The CloudFront point-of-presence that serviced this request, as indicated by the `x-amz-cf-pop` response header.
 * @property {String} [cloudFrontId] - The CloudFront ID of this request, as indicated by the `x-amz-cf-id` response header.
 *
 */
export type ServiceResponse = {
    statusCode: number;
    requestId?: Nullable<string>;
    roundTripTime: number;
    region?: Nullable<string>;
    cloudFrontPop?: Nullable<string>;
    cloudFrontId?: Nullable<string>;
};

/**
 *
 * @access private
 *
 */
export const ServiceResponseTypedef = {
    statusCode: Types.number,
    requestId: Types.nonEmptyString.optional,
    roundTripTime: Types.number,
    region: Types.nonEmptyString.optional,
    cloudFrontPop: Types.nonEmptyString.optional,
    cloudFrontId: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Internal.Dust.ServiceTiming
 * @since 13.0.0
 * @property {Number} requestStart
 * @property {Number} requestEnd
 *
 */
export type ServiceTiming = {
    requestStart: number;
    requestEnd: number;
};

/**
 *
 * @access private
 *
 */
export const ServiceTimingTypedef = {
    requestStart: Types.number,
    requestEnd: Types.number
};

/**
 *
 * @typedef {Object} SDK.Internal.Dust.ServiceConfiguration
 * @since 13.0.0
 * @property {String} endpoint - The full JSON key path of the SDK configuration service endpoint
 *
 */
export type ServiceConfiguration = {
    endpoint: string;
};

/**
 *
 * @access private
 *
 */
export const ServiceConfigurationTypedef = {
    endpoint: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Internal.Dust.ServiceInteraction
 * @since 13.0.0
 * @property {Object<SDK.Internal.Dust.ServiceRequest>} request
 * @property {Object<SDK.Internal.Dust.ServiceResponse>} [response]
 * @property {Object<SDK.Internal.Dust.ServiceConfiguration>} configuration
 * @property {Object<SDK.Internal.Dust.ServiceTiming>} timing
 * @property {String} [error]
 *
 */
export type ServiceInteraction = {
    request: ServiceRequest;
    response?: ServiceResponse;
    configuration: ServiceConfiguration;
    timing: ServiceTiming;
    error?: Array<ErrorReason>;
};

/**
 *
 * @access private
 *
 */
export const ServiceInteractionTypedef = {
    request: Types.object(ServiceRequestTypedef),
    response: Types.object(ServiceResponseTypedef).optional,
    configuration: Types.object(ServiceConfigurationTypedef),
    timing: Types.object(ServiceTimingTypedef),
    error: Types.array.of.instanceStrict(ErrorReason).optional
};
