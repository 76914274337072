/**
 *
 * @module serverRequest
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import FetchStatus from './fetchStatus';
import HttpMethod from './httpMethod';
import NetworkError from './networkError';
import NetworkType from './networkType';

/**
 *
 * @since 4.0.0
 *
 */
export default class ServerRequest {
    /**
     *
     * @param {Object} [options={}]
     * @param {SDK.Services.QualityOfService.HttpMethod} [options.method=null]
     * @param {String} [options.host=null]
     * @param {String} [options.path=null]
     * @param {Number} [options.statusCode=null]
     * @param {String} [options.requestId=null]
     * @param {Number} [options.roundTripTime=null]
     * @param {String<SDK.Services.QualityOfService.FetchStatus>} [options.status=null]
     * @param {String} [options.serverIP=null]
     * @param {String} [options.cdnName=null]
     * @param {String<SDK.Services.QualityOfService.NetworkType>} [options.networkType=null]
     * @param {Number} [options.timeToFirstByte=null]
     * @param {String<SDK.Services.QualityOfService.NetworkError>} [options.error=null]
     *
     */
    constructor(options = {}) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    method: Types.in(HttpMethod).optional,
                    host: Types.nonEmptyString.optional,
                    path: Types.nonEmptyString.optional,
                    statusCode: Types.number.optional,
                    requestId: Types.nonEmptyString.optional,
                    roundTripTime: Types.number.optional,
                    status: Types.in(FetchStatus).optional,
                    serverIP: Types.nonEmptyString.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    timeToFirstByte: Types.number.optional,
                    error: Types.in(NetworkError).optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            method,
            host,
            path,
            statusCode,
            requestId,
            roundTripTime,
            status,
            serverIP,
            cdnName,
            networkType,
            timeToFirstByte,
            error
        } = options || {};

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {SDK.Services.QualityOfService.HttpMethod|null}
         *
         */
        this.method = method || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|null}
         *
         */
        this.host = host || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|null}
         *
         */
        this.path = path || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Number|null}
         *
         */
        this.statusCode = Check.assigned(statusCode) ? statusCode : null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|null}
         *
         */
        this.requestId = requestId || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Number|null}
         *
         */
        this.roundTripTime = Check.assigned(roundTripTime)
            ? roundTripTime
            : null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @desc the status of the service request.
         * @type {SDK.Services.QualityOfService.FetchStatus|null}
         *
         */
        this.status = status || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {String|null}
         *
         */
        this.serverIP = serverIP || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @desc The CDN running the server. `null` if not run by a CDN or cannot be determined.
         * @type {String}
         *
         */
        this.cdnName = cdnName || 'null';

        /**
         *
         * @access public
         * @since 4.0.0
         * @desc The type of network connection currently in use by the client. `null` if it cannot be determined.
         * @type {SDK.Services.QualityOfService.NetworkType|null}
         *
         */
        this.networkType = networkType || null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @desc The time between when request was issued and first byte received in milliseconds. `null` if no response.
         * @type {Number|null}
         *
         */
        this.timeToFirstByte = Check.assigned(timeToFirstByte)
            ? timeToFirstByte
            : null;

        /**
         *
         * @access public
         * @since 4.0.0
         * @desc An error representing a service request failure. `null` if no error occurred.
         * @type {SDK.Services.QualityOfService.NetworkError|null}
         *
         */
        this.error = error || null;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.ServerRequest';
    }

    // #endregion
}
