/**
 *
 * @module purchaseManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/purchase.md#x-bamtech-refresh-access-token-header
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import AccessStatus from './accessStatus';
import PurchaseClient from '../services/purchase/purchaseClient';
import PurchaseManagerConfiguration from '../services/configuration/purchaseManagerConfiguration';
import getSafe from '../services/util/getSafe';
import TokenManager from '../token/tokenManager';
import ReceiptCredentials from '../services/purchase/receiptCredentials';
import RedeemRetryHandler from './redeemRetryHandler';
import LogTransaction from '../logging/logTransaction';
import AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @desc Manages the redemption of purchases made through platform-specific stores.
 *
 */
export default class PurchaseManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.PurchaseManagerConfiguration}
     *
     */
    private config: PurchaseManagerConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Purchase.PurchaseClient}
     *
     */
    public client: PurchaseClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @param {SDK.Services.Configuration.PurchaseManagerConfiguration} purchaseManagerConfiguration
     * @param {SDK.Services.Purchase.PurchaseClient} purchaseClient
     * @param {SDK.Logging.Logger} logger
     * @param {SDK.Token.TokenManager} tokenManager
     *
     */
    public constructor(
        purchaseManagerConfiguration: PurchaseManagerConfiguration,
        purchaseClient: PurchaseClient,
        logger: Logger,
        tokenManager: TokenManager
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                purchaseManagerConfiguration: Types.instanceStrict(
                    PurchaseManagerConfiguration
                ),
                purchaseClient: Types.instanceStrict(PurchaseClient),
                logger: Types.instanceStrict(Logger),
                tokenManager: Types.instanceStrict(TokenManager)
            };

            typecheck(this, params, arguments);
        }

        this.config = purchaseManagerConfiguration;
        this.client = purchaseClient;
        this.logger = logger;
        this.tokenManager = tokenManager;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Purchase.ReceiptCredentials} receiptCredentials - Information
     * about the purchase made in the store.
     * @param {SDK.Purchase.RedeemRetryHandler} handler
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Uses the receipt provided to verify if pertains to a valid purchase
     * @returns {Promise<SDK.Purchase.AccessStatus>}
     *
     */
    public async redeemPurchases(
        receiptCredentials: ReceiptCredentials,
        handler: RedeemRetryHandler,
        logTransaction: LogTransaction
    ): Promise<AccessStatus> {
        const retryPolicy = getSafe(() => this.config.extras.retryPolicy);

        const purchaseActivationResult = await this.client.redeemPurchases(
            receiptCredentials,
            this.accessToken,
            logTransaction
        );

        const accessStatus = new AccessStatus(
            this.logger,
            purchaseActivationResult,
            retryPolicy,
            handler
        );

        const forceRefresh = purchaseActivationResult.refreshAccessToken;

        if (forceRefresh) {
            await this.tokenManager.refreshAccessToken({
                forceRefresh,
                logTransaction
            });
        }

        return accessStatus;
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Purchase.ReceiptCredentials} receiptCredentials - Information
     * about the purchase made in the store.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Uses the receipt provided to verify if it pertains to a valid purchase
     * @returns {Promise<SDK.Purchase.AccessStatus>}
     *
     */
    public async restorePurchases(
        receiptCredentials: ReceiptCredentials,
        logTransaction: LogTransaction
    ): Promise<AccessStatus> {
        const retryPolicy = getSafe(() => this.config.extras.retryPolicy);

        const purchaseActivationResult = await this.client.restorePurchases(
            receiptCredentials,
            this.accessToken,
            logTransaction
        );

        const accessStatus = new AccessStatus(
            this.logger,
            purchaseActivationResult,
            retryPolicy
        );

        const forceRefresh = purchaseActivationResult.refreshAccessToken;

        if (forceRefresh) {
            await this.tokenManager.refreshAccessToken({
                forceRefresh,
                logTransaction
            });
        }

        return accessStatus;
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken(): AccessToken {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Purchase.PurchaseManager';
    }
}
