/**
 *
 * @module eventSchema
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import ServiceException from '../services/exception/serviceException';

import EventValue from './eventValue';

/**
 *
 * @desc An event payload schema that can be customized by the application developer
 *
 */
export default class EventSchema {
    /**
     *
     * @private
     * @type {Object<String, SDK.UserActivity.EventValue>|Object}
     *
     */
    private values: Record<string, EventValue>;

    /**
     *
     * @param {Object<String, SDK.UserActivity.EventValue>} [values={}]
     *
     */
    public constructor(values = {}) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                values: Types.custom(
                    (vals: Record<string, EventValue>) =>
                        Check.object(vals) &&
                        Object.values(vals).every((value) => {
                            return Check.instanceStrict(value, EventValue);
                        })
                ).optional
            };

            typecheck(this, params, arguments);
        }

        this.values = values;
    }

    /**
     *
     * @access public
     * @param {String} name
     * @param {SDK.UserActivity.EventValue} value
     *
     */
    public setValue(name: string, value: EventValue) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                name: Types.nonEmptyString,
                value: Types.instanceStrict(EventValue)
            };

            typecheck(this, 'setValue', params, arguments);
        }

        this.values[name] = value;
    }

    /**
     *
     * @access public
     * @param {String} name
     *
     */
    public removeValue(name: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                name: Types.nonEmptyString
            };

            typecheck(this, 'removeValue', params, arguments);
        }

        delete this.values[name];
    }

    /**
     *
     * @access private
     * @param {Object} trackingParameters
     * @desc loops through all of the schemas values and generates a new value
     * @note the JS SDK passes in `trackingParameters` which is a reference to the combined global tracking parameters
     * and what the application developer passed into `UserActivityApi.trackEvent(event, trackingParameters)`
     * it's possible that `generateValue` ignores `trackingParameters` this depends on how the application developer
     * structured this callback when creating a new instance of `EventSchema` - if nothing was provided we ignore it
     * and return the result of this method
     * @note we wrap the function call in a try/catch to protect against possible errors when the application developer
     * provides the callback invoked by `.generateValue()` - the failed keys are added to an array and reported
     * in `UserActivityApi.trackEvent` and are stripped from the payload before sending to `Dust` - not part of the spec
     * @returns {Object}
     *
     */
    public generateEventData(trackingParameters: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                trackingParameters: Types.object()
            };

            typecheck(this, 'generateEventData', params, arguments);
        }

        const { values } = this;

        const eventData = {} as Record<string, unknown>;

        for (const item of Object.keys(values)) {
            try {
                eventData[item] =
                    values[item].generateValue(trackingParameters);
            } catch (ex) {
                const exception = ex as ServiceException;

                eventData._failures = Array.isArray(eventData._failures)
                    ? eventData._failures.push(exception.stack)
                    : (eventData._failures = [exception.stack]);
            }
        }

        return eventData;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.UserActivity.EventSchema';
    }
}
