/**
 *
 * @module entry
 * @desc {SDK.Token} entry point.
 *
 */

import GeoLocation from './geoLocation';
import Providers from '../providers/platformProviders';

import TokenManager from './tokenManager';
import TokenRefreshFailure from './tokenRefreshFailure';
import AccessStorage from './accessStorage';
import DeviceGrantStorage from './deviceGrantStorage';
import AccountDelegationRefreshTokenStorage from './accountDelegationRefreshTokenStorage';
import AccessContextState from './accessContextState';
import Access from './access';
import AccessTokenProvider from './accessTokenProvider';
import AccessTokenUpdater from './accessTokenUpdater';
import AccessToken from './accessToken';
import AccessState from './accessState';
import AccessStateData from './accessStateData';

/**
 *
 * @typedef {Object} SDK.Token
 *
 * @property {GeoLocation} GeoLocation
 * @property {GeoProvider} GeoProvider
 *
 * @property {TokenManager} TokenManager
 * @property {TokenRefreshFailure} TokenRefreshFailure
 * @property {AccessStorage} AccessStorage
 * @property {DeviceGrantStorage} DeviceGrantStorage
 * @property {AccountDelegationRefreshTokenStorage} AccountDelegationRefreshTokenStorage
 * @property {AccessContextState} AccessContextState
 * @property {Access} Access
 * @property {AccessTokenProvider} AccessTokenProvider
 * @property {AccessTokenUpdater} AccessTokenUpdater
 * @property {AccessToken} AccessToken
 * @property {GeoProvider} GeoProvider
 * @property {GeoLocation} GeoLocation
 * @property {AccessState} AccessState
 * @property {AccessStateData} AccessStateData
 *
 */
export default {
    GeoLocation,
    get GeoProvider() {
        return Providers.GeoProvider;
    },
    TokenManager,
    TokenRefreshFailure,
    AccessStorage,
    DeviceGrantStorage,
    AccountDelegationRefreshTokenStorage,
    AccessContextState,
    Access,
    AccessTokenProvider,
    AccessTokenUpdater,
    AccessToken,
    AccessState,
    AccessStateData
};
