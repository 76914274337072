/**
 *
 * @module contentQuery
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import QueryBuilder from './../services/content/queryBuilder';

/**
 *
 * @desc Represents a Content query with context and a GraphQlQuery.
 *
 */
export default class ContentQuery {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public context: string;

    /**
     *
     * @access public
     * @type {SDK.Services.Content.QueryBuilder}
     *
     */
    public query: QueryBuilder;

    /**
     *
     * @param {String} context
     * @param {SDK.Services.Content.QueryBuilder} query
     *
     */
    public constructor(context: string, query: QueryBuilder) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                context: Types.nonEmptyString,
                query: Types.instanceStrict(QueryBuilder)
            };

            typecheck(this, params, arguments);
        }

        this.context = context;
        this.query = query;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Content.ContentQuery';
    }
}
