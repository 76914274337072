/**
 *
 * @module mediaManagerExtrasMap
 *
 */

import { Types, typecheck, Check } from '@dss/type-checking';

import {
    CdnFallbackConfigurationTypedef,
    PlaybackSnapshotEventConfigurationTypedef
} from './typedefs';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class MediaManagerExtrasMap {
    /**
     *
     * @access public
     * @type {Object}
     * @desc Gets or sets configuration that applies to each playback session.
     *
     */
    public playbackSession: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String|undefined}
     *
     */
    public playbackEncryptionDefault?: string;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String|undefined}
     *
     */
    public mediaQualityDefault?: string;

    /**
     *
     * @access public
     * @since 4.19.0
     * @type {String|undefined}
     *
     */
    public playlistUrlHttpProtocolDefault?: string;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc Gets or sets flag to enable cookies.
     *
     */
    public cookieEnabled: boolean;

    /**
     *
     * @access public
     * @since 7.0.0
     * @type {String|undefined}
     *
     */
    public adPartner?: string;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Object|undefined}
     *
     */
    public securityCheckRequirements?: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Boolean}
     *
     */
    public isContentAccessRestricted: boolean;

    /**
     *
     * @access public
     * @since 7.0.0
     * @type {Object}
     *
     */
    public cdnFallback: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {Object}
     *
     */
    public playbackSnapshotEvents: Record<string, unknown>;

    /**
     *
     * @param {Object} options
     * @param {Object} options.playbackSession
     * @param {String} options.playbackEncryptionDefault
     * @param {String} options.mediaQualityDefault
     * @param {String} [options.playlistUrlHttpProtocolDefault]
     * @param {Object} [options.securityCheckRequirements]
     * @param {Boolean} [options.cookieEnabled=false]
     * @param {String} [options.adPartner]
     * @param {Boolean} [options.isContentAccessRestricted=true]
     * @param {Object} options.cdnFallback
     * @param {Object} options.playbackSnapshotEvents
     *
     */
    public constructor(options: {
        playbackSession: Record<string, unknown>;
        playbackEncryptionDefault: string;
        mediaQualityDefault: string;
        playlistUrlHttpProtocolDefault?: string;
        securityCheckRequirements?: Record<string, unknown>;
        cookieEnabled?: boolean;
        adPartner?: string;
        isContentAccessRestricted?: boolean;
        cdnFallback: Record<string, unknown>;
        playbackSnapshotEvents: Record<string, unknown>;
    }) {
        // do not assert checks here because we want to let it pass and just log when arguments are not correct

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackSession: Types.nonEmptyObject,
                    playbackEncryptionDefault: Types.nonEmptyString,
                    mediaQualityDefault: Types.nonEmptyString,
                    playlistUrlHttpProtocolDefault:
                        Types.nonEmptyString.optional,
                    securityCheckRequirements: Types.nonEmptyObject.optional,
                    cookieEnabled: Types.boolean,
                    adPartner: Types.nonEmptyString.optional,
                    isContentAccessRestricted: Types.boolean.optional,
                    cdnFallback: Types.object(CdnFallbackConfigurationTypedef),
                    playbackSnapshotEvents: Types.object(
                        PlaybackSnapshotEventConfigurationTypedef
                    )
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            playbackSession,
            playbackEncryptionDefault,
            mediaQualityDefault,
            playlistUrlHttpProtocolDefault,
            securityCheckRequirements,
            cookieEnabled = false,
            adPartner,
            isContentAccessRestricted = true,
            cdnFallback,
            playbackSnapshotEvents
        } = options || {};

        this.playbackSession = playbackSession;

        this.playbackEncryptionDefault = playbackEncryptionDefault;

        this.mediaQualityDefault = mediaQualityDefault;

        this.playlistUrlHttpProtocolDefault = playlistUrlHttpProtocolDefault;

        this.cookieEnabled = !!cookieEnabled;

        this.adPartner = adPartner;

        this.securityCheckRequirements = securityCheckRequirements;

        this.isContentAccessRestricted = !!isContentAccessRestricted;

        this.cdnFallback = Check.object(cdnFallback) ? cdnFallback : {};

        this.playbackSnapshotEvents = Check.object(playbackSnapshotEvents)
            ? playbackSnapshotEvents
            : {};
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.MediaManagerExtrasMap';
    }
}
