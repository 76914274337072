/**
 *
 * @module introPrice
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import TimeUnit from './timeUnit';

/**
 *
 * @since 3.9.0
 * @desc Defines introductory pricing and free trial information for IAP products.
 *
 */
export default class IntroPrice {
    /**
     *
     * @access public
     * @since 3.9.0
     * @type {Number}
     * @desc Length of intro period.
     *
     */
    public length: number;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {Number}
     * @desc Price during intro period. For free trials this would be 0.00.
     *
     */
    public price: number;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String<SDK.Services.Paywall.TimeUnit>}
     * @desc Unit of time.
     *
     */
    public unit: keyof typeof TimeUnit;

    /**
     *
     * @param {Object} options
     * @param {Number} options.length
     * @param {Number} options.price
     * @param {String<SDK.Services.Paywall.TimeUnit>} options.unit
     *
     */
    public constructor(options: {
        length: number;
        price: number;
        unit: keyof typeof TimeUnit;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    length: Types.number,
                    price: Types.number,
                    unit: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { length, price, unit } = options;

        this.length = length;

        this.price = price;

        this.unit = unit;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.IntroPrice';
    }
}
