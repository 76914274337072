/**
 *
 * @module osDeviceId
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/device.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import OsDeviceIdType from './osDeviceIdType';

/**
 *
 * @since 9.0.0
 *
 */
export default class OsDeviceId {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc System-provided identifier
     *
     */
    public identifier?: string;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {OsDeviceIdType}
     * @desc The type of system-provided identifier
     *
     */
    public type?: OsDeviceIdType;

    /**
     *
     * @param {Object} [options={}]
     * @param {String} [options.identifier]
     * @param {String<OsDeviceIdType>} [options.type]
     *
     */
    public constructor(options?: {
        identifier?: string;
        type?: OsDeviceIdType;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    identifier: Types.nonEmptyString.optional,
                    type: Types.in(OsDeviceIdType).optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const { identifier, type } = options || {};

        if (Check.assigned(identifier)) {
            this.identifier = identifier;
        }

        if (Check.assigned(type)) {
            this.type = type;
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Device.OsDeviceId';
    }
}
