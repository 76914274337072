/**
 *
 * @module offDeviceTokenRefreshEvent
 *
 */
import type OffDeviceRedemptionFlow from './services/orchestration/offDeviceRedemptionFlow';

/**
 *
 * @desc Provides an event that can share an `Error` in the case an offDeviceTokenRefresh socket message fails to process.
 * Additionally, to support Welch Connected Devices flows, this event also contains a actionGrant and redemption flow.
 *
 */
export default class OffDeviceTokenRefreshEvent {
    /**
     *
     * @access public
     * @since 4.15.0
     * @type {Error|undefined}
     *
     */
    public exception?: Error;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String|undefined}
     *
     */
    public actionGrant?: string;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {SDK.Services.Orchestration.OffDeviceRedemptionFlow|undefined}
     *
     */
    public offDeviceRedemptionFlow?: ValueOf<typeof OffDeviceRedemptionFlow>;

    /**
     *
     * @param {Object} options
     * @param {Error} [options.exception]
     * @param {String} [options.actionGrant]
     * @param {SDK.Services.Orchestration.OffDeviceRedemptionFlow} [options.offDeviceRedemptionFlow]
     *
     */
    public constructor(options: {
        exception?: Error;
        actionGrant?: string;
        offDeviceRedemptionFlow?: ValueOf<typeof OffDeviceRedemptionFlow>;
    }) {
        const { exception, actionGrant, offDeviceRedemptionFlow } = options;

        this.exception = exception;
        this.actionGrant = actionGrant;
        this.offDeviceRedemptionFlow = offDeviceRedemptionFlow;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.OffDeviceTokenRefreshEvent';
    }
}
