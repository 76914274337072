/**
 *
 * @module personalizationEvent
 *
 */

import UserActivityEvent from '../userActivityEvent';

/**
 *
 *
 */
export default class PersonalizationEvent extends UserActivityEvent {
    /**
     *
     * @param {String} eventUrn
     *
     */
    public constructor(eventUrn: string) {
        super(eventUrn);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.UserActivity.Personalization.PersonalizationEvent';
    }
}
