/**
 *
 * @module socketSchemaUrls
 *
 */

const createBaseSchemaUrl = (edgeSegment: string) =>
    `https://github.bamtech.co/schema-registry/schema-registry${edgeSegment}.oas2.yaml`;
const createEdgeEventUrl = (edgeSegment: string) =>
    createBaseSchemaUrl(`/blob/master/dss/event/${edgeSegment}`);
const createEdge1EventUrl = (edgeSegment: string) =>
    createEdgeEventUrl(`edge/1.0.0/sdk/${edgeSegment}`);

export default {
    authentication: createEdge1EventUrl('authentication'),
    event: createBaseSchemaUrl(
        '-sdk/blob/series/0.X.X/yaml/dss/event/sdk/client/api/event'
    ),
    exposure: createEdgeEventUrl(
        'experimentation/weaponx/2.0.0/weaponx-exposure'
    ),
    flowControl: createEdge1EventUrl('flow-control-request'),
    ping: createEdge1EventUrl('transport/ping'),
    reconnect: createEdge1EventUrl('reconnect'),
    sessionAuthentication: createEdge1EventUrl('session-authentication')
};
