/**
 *
 * @module drmPlugin
 * @desc {SDK.Drm.DrmPlugin} entry point.
 *
 */

import Drm from './entry';
import DrmManager from './drmManager';
import DrmService from '../services/drm/entry';
import DrmClient from '../services/drm/drmClient';

import getSafe from '../services/util/getSafe';

import DrmClientConfiguration from './../services/drm/drmClientConfiguration';
import DrmClientExtrasMap from './../services/drm/drmClientExtrasMap';
import DrmManagerConfiguration from './../services/configuration/drmManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Drm, 'Drm', typeof DrmService> = {
    pluginName: 'Drm',
    entry: Drm,
    service: DrmService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const {
            managers,
            logger,
            httpClient,
            services,
            accessTokenProvider,
            storage,
            metadata: { clientId, environment }
        } = options;

        const drmManager = new DrmManager({
            drmManagerConfiguration: services.drm,
            drmClient: new DrmClient(services.drm.client, logger, httpClient),
            accessTokenProvider,
            clientId,
            environment,
            storage,
            logger
        });

        managers.drmManager = drmManager;
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { client: drmClient } = services.drm;
        const { endpoints: drmClientEndpoints, extras: drmClientExtras } =
            drmClient;

        const clientConfiguration = new DrmClientConfiguration(
            getSafe(() => services.drm.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => drmClientEndpoints)
            ),
            new DrmClientExtrasMap(drmClientExtras)
        );

        serviceDefinitionsConfiguration.drm = new DrmManagerConfiguration(
            clientConfiguration,
            undefined,
            services.drm.disabled
        );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
