/**
 *
 * @module platform
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Configuration.Platform
 * @desc Enum for the possible platform values in the ConfigurationClientConfiguration
 *
 * @property {String} chromecast
 * @property {String} netcast
 * @property {String} panasonictv
 * @property {String} sonybravia
 * @property {String} tivo
 * @property {String} tizen
 * @property {String} trilithium
 * @property {String} vizio
 * @property {String} webos
 * @property {String} canalPlus
 * @property {String} skyq - Since `4.5.0`.
 * @property {String} cableVision - Since `4.10.0`.
 * @property {String} comcastx1 - Since `5.0.0`.
 * @property {String} telecentro - Since `5.1.0`.
 * @property {String} toshibaRegza - Since `8.0.0`.
 * @property {String} hisense - Since `8.0.0`.
 * @property {String} rogersx1 - Since `9.0.0`.
 * @property {String} coxx1 - Since `10.0.0`.
 * @property {String} xglobal - Since `11.0.0`.
 * @property {String} shawx1 - Since `19.0.0`.
 *
 */
export default keyMirror({
    chromecast: null,
    netcast: null,
    panasonictv: null,
    sonybravia: null,
    tivo: null,
    tizen: null,
    trilithium: null,
    vizio: null,
    webos: null,
    canalPlus: null,
    skyq: null,
    cableVision: null,
    comcastx1: null,
    telecentro: null,
    toshibaRegza: null,
    hisense: null,
    rogersx1: null,
    coxx1: null,
    xglobal: null,
    shawx1: null
});
