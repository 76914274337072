/**
 *
 * @module playbackSelectionProperties
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import PlaybackSelectionAttributes from './playbackSelectionAttributes';
import PlaybackSelectionAdTracking from './playbackSelectionAdTracking';
import PlaybackSelectionTracking from './playbackSelectionTracking';

/**
 *
 * @since 4.18.0
 *
 */
export default class PlaybackSelectionProperties {
    /**
     *
     * @param {SDK.Services.Media.PlaybackSelectionAttributes} attributes
     * @param {SDK.Services.Media.PlaybackSelectionAdTracking} [adTracking]
     * @param {SDK.Services.Media.PlaybackSelectionTracking} [tracking]
     *
     */
    constructor(attributes, adTracking, tracking) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                attributes: Types.instanceStrict(PlaybackSelectionAttributes),
                adTracking: Types.instanceStrict(PlaybackSelectionAdTracking)
                    .optional,
                tracking: Types.instanceStrict(PlaybackSelectionTracking)
                    .optional
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {SDK.Services.Media.PlaybackSelectionAttributes}
         *
         */
        this.attributes = attributes;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {SDK.Services.Media.PlaybackSelectionAdTracking|undefined}
         *
         */
        this.adTracking = adTracking;

        /**
         *
         * @access public
         * @since 4.18.0
         * @type {SDK.Services.Media.PlaybackSelectionTracking|undefined}
         *
         */
        this.tracking = tracking;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackSelectionProperties';
    }

    // #endregion
}
