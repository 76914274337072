/**
 *
 * @module glimpseEvent
 *
 */

import UserActivityEvent from '../userActivityEvent';

/**
 *
 *
 */
export default class GlimpseEvent extends UserActivityEvent {
    /**
     *
     * @param {String} eventUrn
     *
     */
    public constructor(eventUrn: string) {
        super(eventUrn);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.UserActivity.Glimpse.GlimpseEvent';
    }
}
