/**
 *
 * @module entry
 * @desc {SDK.Services.Media} entry point.
 *
 */

import AdEngine from './adEngine/entry';
import AudioRendition from './audioRendition';
import AudioType from './audioType';
import BifThumbnailSet from './bifThumbnailSet';
import CodecAttributes from './codecAttributes';
import DrmType from './drmType';
import EncryptionMode from './encryptionMode';
import HdrType from './hdrType';
import InsertionMode from './insertionMode';
import InsertionPointContentType from './insertionPointContentType';
import InsertionPointContentSubType from './insertionPointContentSubType';
import InsertionPointPlacement from './insertionPointPlacement';
import MediaClient from './mediaClient';
import MediaFetchErrorMapping from './mediaFetchErrorMapping';
import MediaPayload from './mediaPayload';
import MediaPayloadStream from './mediaPayloadStream';
import MediaPlaybackSelectionPayload from './mediaPlaybackSelectionPayload';
import MediaPlayhead from './mediaPlayhead';
import MediaPlayheadStatus from './mediaPlayheadStatus';
import MediaQuality from './mediaQuality';
import PlaybackAttributes from './playbackAttributes';
import PlaybackContext from './playbackContext';
import MediaThumbnailLink from './mediaThumbnailLink';
import MediaThumbnailLinks from './mediaThumbnailLinks';
import PlaybackRenditions from './playbackRenditions';
import PlaybackSelectionAdTracking from './playbackSelectionAdTracking';
import PlaybackSelectionAttributes from './playbackSelectionAttributes';
import PlaybackSelectionProperties from './playbackSelectionProperties';
import PlaybackSelectionTracking from './playbackSelectionTracking';
import PlaybackSecurity from './playbackSecurity';
import PlaybackVariant from './playbackVariant';
import PlaylistType from './playlistType';
import Presentation from './presentation';
import Protocol from './protocol';
import ResolutionMax from './resolutionMax';
import SpriteThumbnailSet from './spriteThumbnailSet';
import StreamingType from './streamingType';
import StreamSampleConfiguration from './streamSampleConfiguration';
import SubtitleRendition from './subtitleRendition';
import SupportedCodec from './supportedCodec';
import ThumbnailPresentationType from './thumbnailPresentationType';
import ThumbnailSet from './thumbnailSet';
import VideoResolution from './videoResolution';

import { SubscriptionType, AssetInsertionStrategyQos } from './enums';

/**
 *
 * @typedef {Object} SDK.Services.Media
 *
 * @property {SDK.Services.Media.AdEngine} AdEngine
 * @property {SDK.Services.Media.AssetInsertionStrategyQos} AssetInsertionStrategyQos
 * @property {SDK.Services.Media.AudioRendition} AudioRendition
 * @property {SDK.Services.Media.AudioType} AudioType
 * @property {SDK.Services.Media.BifThumbnailSet} BifThumbnailSet
 * @property {SDK.Services.Media.CodecAttributes} CodecAttributes
 * @property {SDK.Services.Media.DrmType} DrmType
 * @property {SDK.Services.Media.EncryptionMode} EncryptionMode
 * @property {SDK.Services.Media.HdrType} HdrType
 * @property {SDK.Services.Media.InsertionMode} InsertionMode
 * @property {SDK.Services.Media.InsertionPointContentType} InsertionPointContentType
 * @property {SDK.Services.Media.InsertionPointContentSubType} InsertionPointContentSubType
 * @property {SDK.Services.Media.InsertionPointPlacement} InsertionPointPlacement
 * @property {SDK.Services.Media.MediaClient} MediaClient
 * @property {SDK.Services.Media.MediaFetchErrorMapping} MediaFetchErrorMapping
 * @property {SDK.Services.Media.MediaPayload} MediaPayload
 * @property {SDK.Services.Media.MediaPayloadStream} MediaPayloadStream
 * @property {SDK.Services.Media.MediaPlaybackSelectionPayload} MediaPlaybackSelectionPayload
 * @property {SDK.Services.Media.MediaPlayhead} MediaPlayhead
 * @property {SDK.Services.Media.SDK.Services.Media.MediaPlayheadStatus} MediaPlayheadStatus
 * @property {SDK.Services.Media.MediaThumbnailLink} MediaThumbnailLink
 * @property {SDK.Services.Media.MediaThumbnailLinks} MediaThumbnailLinks
 * @property {SDK.Services.Media.PlaybackAttributes} PlaybackAttributes
 * @property {SDK.Services.Media.PlaybackContext} PlaybackContext
 * @property {SDK.Services.Media.PlaybackRenditions} PlaybackRenditions
 * @property {SDK.Services.Media.PlaybackSelectionAdTracking} PlaybackSelectionAdTracking
 * @property {SDK.Services.Media.PlaybackSelectionAttributes} PlaybackSelectionAttributes
 * @property {SDK.Services.Media.PlaybackSelectionProperties} PlaybackSelectionProperties
 * @property {SDK.Services.Media.PlaybackSelectionTracking} PlaybackSelectionTracking
 * @property {SDK.Services.Media.PlaybackSecurity} PlaybackSecurity
 * @property {SDK.Services.Media.PlaybackVariant} PlaybackVariant
 * @property {SDK.Services.Media.PlaylistType} PlaylistType
 * @property {SDK.Services.Media.Presentation} Presentation
 * @property {SDK.Services.Media.Protocol} Protocol
 * @property {SDK.Services.Media.ResolutionMax} ResolutionMax
 * @property {SDK.Services.Media.StreamSampleConfiguration} StreamSampleConfiguration
 * @property {SDK.Services.Media.SpriteThumbnailSet} SpriteThumbnailSet
 * @property {SDK.Services.Media.StreamingType} StreamingType
 * @property {SDK.Services.Media.SubscriptionType} SubscriptionType
 * @property {SDK.Services.Media.SubtitleRendition} SubtitleRendition
 * @property {SDK.Services.Media.SupportedCodec} SupportedCodec
 * @property {SDK.Services.Media.ThumbnailPresentationType} ThumbnailPresentationType
 * @property {SDK.Services.Media.ThumbnailSet} ThumbnailSet
 * @property {SDK.Services.Media.VideoResolution} VideoResolution
 *
 */
export default {
    AdEngine,
    AssetInsertionStrategyQos,
    AudioRendition,
    AudioType,
    BifThumbnailSet,
    CodecAttributes,
    DrmType,
    EncryptionMode,
    HdrType,
    InsertionMode,
    InsertionPointContentType,
    InsertionPointContentSubType,
    InsertionPointPlacement,
    MediaClient,
    MediaFetchErrorMapping,
    MediaPayload,
    MediaPayloadStream,
    MediaPlaybackSelectionPayload,
    MediaPlayhead,
    MediaPlayheadStatus,
    MediaQuality,
    PlaybackAttributes,
    PlaybackContext,
    MediaThumbnailLink,
    MediaThumbnailLinks,
    PlaybackRenditions,
    PlaybackSelectionAdTracking,
    PlaybackSelectionAttributes,
    PlaybackSelectionProperties,
    PlaybackSelectionTracking,
    PlaybackSecurity,
    PlaybackVariant,
    PlaylistType,
    Presentation,
    Protocol,
    ResolutionMax,
    StreamSampleConfiguration,
    SpriteThumbnailSet,
    StreamingType,
    SubscriptionType,
    SubtitleRendition,
    SupportedCodec,
    ThumbnailPresentationType,
    ThumbnailSet,
    VideoResolution
};
