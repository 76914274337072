/**
 *
 * @module browserInfoProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/commerce.md#browser-info
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/commerce/BrowserInfoProvider.md
 * @see https://github.bamtech.co/mercury/universal-payment-bridge/blob/master/docs/3ds/waiver/BR_MC_Debit_3DS_Waiver.md#browser-information
 * @see https://docs.adyen.com/checkout/3d-secure/api-reference#browserinfo
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BrowserInfo from '../services/commerce/browserInfo';
import getScope from '../services/util/getScope';
import getSafe from '../services/util/getSafe';
import Logger from '../logging/logger';

interface BrowserInfoProviderOptions {
    logger: Logger;
}

/**
 *
 * @access public
 * @since 4.12.0
 * @desc Browser information extracted from the shopper, to be
 * used in requests to the order submission v2 service.
 *
 */
export default class BrowserInfoProvider {
    /**
     *
     * @access private
     * @since 4.12.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Object}
     * @desc Stored reference to the global scope, to safely reference
     * the window `Object` in browser when extracting shopper data.
     *
     */
    private global: TodoAny;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {String}
     * @desc The accept header value of the shopper's browser.
     *
     */
    public acceptHeader: string;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {Number}
     * @desc The color depth of the shopper's browser in bits per pixel.
     *
     */
    public colorDepth: number;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {Boolean}
     * @desc Boolean value indicating if the shopper's browser is able to execute Java.
     *
     */
    public javaEnabled: boolean;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {String}
     * @desc The name of the browser language used by the shopper.
     *
     */
    public language: string;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {Number}
     * @desc The pixel height of the shopper's screen.
     *
     */
    public screenHeight: number;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {Number}
     * @desc The pixel width of the shopper's screen.
     *
     */
    public screenWidth: number;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {Number}
     * @desc Time difference between UTC time and the shopper's browser local time, in minutes.
     *
     */
    public timeZoneOffset: number;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {String}
     * @desc The user agent value of the shopper's browser.
     *
     */
    public userAgent: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} [options.logger]
     *
     */
    public constructor(options: BrowserInfoProviderOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { logger } = options;

        this.logger = logger || Logger.instance;
        this.global = getScope();
        this.acceptHeader = this.getAcceptHeader();
        this.colorDepth = this.getColorDepth();
        this.javaEnabled = this.getJavaEnabled();
        this.language = this.getLanguage();
        this.screenHeight = this.getScreenHeight();
        this.screenWidth = this.getScreenWidth();
        this.timeZoneOffset = this.getTimeZoneOffset();
        this.userAgent = this.getUserAgent();

        // @ts-ignore
        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @desc Returns a structured browser information `Object` to be serialized as JSON
     * and sent to the service via a `X-BAMTech-Browser-Info` header.
     * @returns {SDK.Services.Commerce.BrowserInfo}
     *
     */
    public getBrowserInfo() {
        const { acceptHeader, colorDepth, javaEnabled, language } = this;
        const { screenHeight, screenWidth, timeZoneOffset, userAgent } = this;

        return new BrowserInfo({
            acceptHeader,
            colorDepth,
            javaEnabled,
            language,
            screenHeight,
            screenWidth,
            timeZoneOffset,
            userAgent
        });
    }

    // #region private

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Possible Values: text/html, application/xhtml+xml, application/xml;q=0.9,
     * image/webp, image/apng/;q=0.8
     * @note Currently hardcoded as this is expected to be detected by app devs.
     * @returns {String}
     *
     */
    public getAcceptHeader() {
        return 'text/html';
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Possible Values: 1, 4, 8, 15, 16, 24, 32, 48, etc...
     * @returns {Number}
     *
     */
    public getColorDepth() {
        // @ts-ignore
        return getSafe(() => this.global.screen.colorDepth, 0);
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Possible Values: true, false.
     * @returns {Boolean}
     *
     */
    public getJavaEnabled() {
        // @ts-ignore
        return getSafe(() => this.global.navigator.javaEnabled(), false);
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Usually a two letter country code.
     * @returns {String}
     *
     */
    public getLanguage() {
        // @ts-ignore
        const language = getSafe(() => this.global.navigator.language, 'en-US');

        return language.slice(-2);
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Possible Values: 480, etc... (pixels).
     * @returns {Number}
     *
     */
    public getScreenHeight() {
        // @ts-ignore
        return getSafe(() => this.global.screen.height, 0);
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Possible Values: 640, etc... (pixels).
     * @returns {Number}
     *
     */
    public getScreenWidth() {
        // @ts-ignore
        return getSafe(() => this.global.screen.width, 0);
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @note Possible Values: -180, etc...
     * @note Difference between UTC time and the shopper's browser local time, in minutes.
     * @returns {Number}
     *
     */
    public getTimeZoneOffset() {
        return new Date().getTimezoneOffset();
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @returns {String}
     *
     */
    public getUserAgent() {
        return getSafe(
            // @ts-ignore
            () => this.global.navigator.userAgent,
            'JS-SDK-userAgent (N/A)'
        );
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Commerce.BrowserInfoProvider';
    }

    // #endregion
}
