/**
 *
 * @module contentPlugin
 * @desc {SDK.Content.ContentPlugin} entry point.
 *
 */

import Content from './entry';
import ContentService from '../services/content/entry';

import ContentApi from './contentApi';
import ContentClient from '../services/content/contentClient';
import ContentManager from './contentManager';

import getSafe from '../services/util/getSafe';

import ContentClientConfiguration from './../services/content/contentClientConfiguration';
import ContentClientExtrasMap from './../services/content/contentClientExtrasMap';
import ContentManagerConfiguration from './../services/configuration/contentManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<typeof Content, 'Content', typeof ContentService> = {
    pluginName: 'Content',
    entry: Content,
    service: ContentService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { contentManager } = sdkSession.managers;

        sdkSession.contentApi = new ContentApi({
            contentManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const { managers, logger, httpClient, services, accessTokenProvider } =
            options;

        managers.contentManager = new ContentManager(
            services.content,
            new ContentClient(services.content.client, logger, httpClient),
            logger,
            accessTokenProvider
        );
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { client: contentClient } = services.content;
        const {
            endpoints: contentClientEndpoints,
            extras: contentClientExtras
        } = contentClient;

        const clientConfiguration = new ContentClientConfiguration(
            contentClient.baseUrl,
            serviceDefinitionsConfiguration.processEndpoints(
                contentClientEndpoints
            ),
            new ContentClientExtrasMap(contentClientExtras.urlSizeLimit)
        );

        serviceDefinitionsConfiguration.content =
            new ContentManagerConfiguration(
                clientConfiguration,
                undefined,
                getSafe(() => services.content.disabled)
            );
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
