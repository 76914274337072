/**
 *
 * @module playbackRenditions
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AudioRendition from './audioRendition';
import SubtitleRendition from './subtitleRendition';

/**
 *
 * @since 4.0.0
 *
 */
export default class PlaybackRenditions {
    /**
     *
     * @param {Object} options
     * @param {Array<SDK.Services.Media.AudioRendition>} options.audio
     * @param {Array<SDK.Services.Media.SubtitleRendition>} options.subtitles
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    audio: Types.array.of.instanceStrict(AudioRendition),
                    subtitles: Types.array.of.instanceStrict(SubtitleRendition)
                })
            };

            typecheck(this, params, arguments);
        }

        const { audio, subtitles } = options;

        /**
         *
         * @access public
         * @type {Array<SDK.Services.Media.AudioRendition>}
         *
         */
        this.audio = audio;

        /**
         *
         * @access public
         * @type {Array<SDK.Services.Media.SubtitleRendition>}
         *
         */
        this.subtitles = subtitles;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.PlaybackRenditions';
    }

    // #endregion
}
