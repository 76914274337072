/**
 *
 * @module telemetryClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/telemetry.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../../../logging/logger';
import checkResponseCode from '../../util/checkResponseCode';
import replaceHeaders from '../../util/replaceHeaders';

import TelemetryClientEndpoint from './telemetryClientEndpoint';
import TelemetryClientConfiguration from './telemetryClientConfiguration';
import TelemetryPayload from './telemetryPayload';
import TelemetryResponse from './telemetryResponse';
import ValidatedTelemetryResponse from './validatedTelemetryResponse';
import HoraValidatedTelemetryResponse from './horaValidatedTelemetryResponse';
import ValidationResult from './validationResult';
import ValidationResultSuccess from './validationResultSuccess';
import ValidationResultUnknownTypeError from './validationResultUnknownTypeError';
import ValidationResultJsonDustEventDecodeError from './validationResultJsonDustEventDecodeError';
import ValidationResultType from './validationResultType';
import DustLogUtility, { ServerResponse } from './../dust/dustLogUtility';
import DustUrnReference from './../dust/dustUrnReference';
import CoreHttpClientProvider from './../../providers/shared/coreHttpClientProvider';
import LogTransaction from '../../../logging/logTransaction';
import AccessToken from '../../token/accessToken';
import HttpMethod from '../../configuration/httpMethod';

const TelemetryClientDustUrnReference =
    DustUrnReference.services.internal.telemetry.telemetryClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access telemetry services.
 *
 */
export default class TelemetryClient {
    /**
     *
     * @access private
     * @type {SDK.Services.Internal.Telemetry.TelemetryClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: TelemetryClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {CoreHttpClientProvider}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {SDK.Services.Internal.Telemetry.TelemetryClientConfiguration} telemetryClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(
        telemetryClientConfiguration: TelemetryClientConfiguration,
        logger: Logger,
        httpClient: CoreHttpClientProvider
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                telemetryClientConfiguration: Types.instanceStrict(
                    TelemetryClientConfiguration
                ),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider)
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Services.Internal.Telemetry.TelemetryClientConfiguration}
         * @desc The configuration information to use.
         *
         */
        this.config = telemetryClientConfiguration;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {HttpClient}
         * @desc The object responsible for making HTTP requests.
         *
         */
        this.httpClient = httpClient;

        /**
         *
         * @access private
         * @type {Boolean}
         * @note needs to default to true to collect dust events before the configuration is fetched and we can
         * determine if this should be enabled
         *
         */
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} options.telemetryPayloads - The collection of
     * @param {Boolean} options.useProxy - Internal proxy option for event validation.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * TelemetryPayload(s) to send.
     * @desc Posts an event collection to the telemetry service.
     * @returns {Promise<SDK.Services.Internal.Telemetry.TelemetryResponse>} Optional information and/or instructions
     * about the telemetry service. TelemetryResponse could also be HoraValidatedTelemetryResponse when useProxy is true.
     *
     */
    public postEvents(
        options: {
            accessToken: AccessToken;
            telemetryPayloads: Array<TelemetryPayload>;
            useProxy: boolean;
        },
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    telemetryPayloads:
                        Types.array.of.instanceStrict(TelemetryPayload),
                    useProxy: Types.boolean
                }),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'postEvents', params, arguments);
        }

        const { accessToken, telemetryPayloads, useProxy } = options;

        const { dustEnabled, logger, httpClient } = this;

        const endpointKey = TelemetryClientEndpoint.postEvent;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: telemetryPayloads,
            data: {
                useProxy
            }
        });

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: TelemetryClientDustUrnReference.postEvents,
            payload,
            method: HttpMethod.GET,
            endpointKey,
            logTransaction
        });

        logger.info(
            this.toString(),
            `Posting telemetry events: Count: ${telemetryPayloads.length}`
        );

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response) => {
                if (useProxy) {
                    return this.createHoraValidatedTelemetryResponse(
                        response,
                        telemetryPayloads
                    );
                }

                return this.createTelemetryResponse(response);
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} options.telemetryPayloads - The collection of
     * TelemetryPayload(s) to send.
     * @param {Boolean} options.useProxy - Internal proxy option for event validation.
     * @desc Posts a dust event collection to the telemetry service.
     * @note do NOT post dustEvents to the dustSink
     * @returns {Promise<SDK.Services.Internal.Telemetry.TelemetryResponse>} Optional information and/or instructions
     * about the telemetry service. TelemetryResponse could also be HoraValidatedTelemetryResponse when useProxy is true.
     *
     */
    public postDustEvents(options: {
        accessToken: AccessToken;
        telemetryPayloads: Array<TelemetryPayload>;
        useProxy: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    telemetryPayloads:
                        Types.array.of.instanceStrict(TelemetryPayload),
                    useProxy: Types.boolean
                })
            };

            typecheck(this, 'postDustEvents', params, arguments);
        }

        const { accessToken, telemetryPayloads, useProxy } = options;

        const { logger, httpClient } = this;

        const payload = this.getPayload({
            accessToken,
            endpointKey: TelemetryClientEndpoint.dustEvent,
            body: telemetryPayloads,
            data: {
                useProxy
            }
        });

        logger.info(
            this.toString(),
            `Posting dust events: Count: ${telemetryPayloads.length}`
        );

        return httpClient
            .post(payload)
            .then((response) => {
                return checkResponseCode(response);
            })
            .then((response) => {
                if (useProxy) {
                    return this.createHoraValidatedTelemetryResponse(
                        response,
                        telemetryPayloads
                    );
                }

                return this.createTelemetryResponse(response);
            });
    }

    /**
     *
     * @access protected
     * @since 4.11.0
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} telemetryPayloads - The collection of
     * TelemetryPayload(s) to validate.
     * @desc Validates a dust event collection to the telemetry service.
     * @returns {Promise<SDK.Services.Internal.Telemetry.ValidatedTelemetryResponse>}
     *
     */
    public validateDustEvents(
        accessToken: AccessToken,
        telemetryPayloads: Array<TelemetryPayload>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                telemetryPayloads:
                    Types.array.of.instanceStrict(TelemetryPayload)
            };

            typecheck(this, 'validateDustEvents', params, arguments);
        }

        const { logger, httpClient } = this;

        const payload = this.getPayload({
            accessToken,
            endpointKey: TelemetryClientEndpoint.validateDustEvent,
            body: telemetryPayloads
        });

        logger.info(
            this.toString(),
            `Validating dust events: Count: ${telemetryPayloads.length}`
        );

        return httpClient
            .post(payload)
            .then((response) => {
                const isAcceptedResponse =
                    response.status <= 400 &&
                    response.headers.get('content-type') === 'application/json';

                if (isAcceptedResponse) {
                    return response;
                }

                return checkResponseCode(response);
            })
            .then((response) => {
                return this.createValidatedTelemetryResponse(response);
            });
    }

    /**
     *
     * @access protected
     * @since 14.0.0
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} telemetryPayloads - The collection of
     * TelemetryPayload(s) to validate.
     * @desc Validates a QoE event collection to the telemetry service.
     * @returns {Promise<SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse>}
     *
     */
    public validateQoeEvents(
        accessToken: AccessToken,
        telemetryPayloads: Array<TelemetryPayload>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                telemetryPayloads:
                    Types.array.of.instanceStrict(TelemetryPayload)
            };

            typecheck(this, 'validateQoeEvents', params, arguments);
        }

        const { logger, httpClient } = this;

        const payload = this.getPayload({
            accessToken,
            endpointKey: TelemetryClientEndpoint.validateQoeEvent,
            body: telemetryPayloads
        });

        logger.info(
            this.toString(),
            `Validating QoE events: Count: ${telemetryPayloads.length}`
        );

        return httpClient
            .post(payload)
            .then((response) => {
                const isAcceptedResponse =
                    response.status <= 400 &&
                    response.headers.get('content-type') === 'application/json';

                if (isAcceptedResponse) {
                    return response;
                }

                return checkResponseCode(response);
            })
            .then((response) => {
                return this.createHoraValidatedTelemetryResponse(
                    response,
                    telemetryPayloads
                );
            });
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} response - Http Response
     * @desc Creates a new ValidatedTelemetryResponse object
     * @returns {SDK.Services.Internal.Telemetry.ValidatedTelemetryResponse}
     *
     */
    private createValidatedTelemetryResponse(response: ServerResponse) {
        const { headers, data } = response;

        const replyAfter = this.getReplyAfter(headers);
        const requestId = this.getRequestId(headers);

        const results = data.map((result: TodoAny) => {
            switch (result._type) {
                case ValidationResultType.success:
                    return new ValidationResultSuccess(result);

                case ValidationResultType.unknownTypeError:
                    return new ValidationResultUnknownTypeError(result);

                case ValidationResultType.jsonDustEventDecodeError:
                    return new ValidationResultJsonDustEventDecodeError(result);

                default:
                    return new ValidationResult(result);
            }
        });

        const validatedTelemetryResponse = new ValidatedTelemetryResponse({
            replyAfter,
            requestId,
            results
        });

        this.logger.log(this.toString(), validatedTelemetryResponse);

        return validatedTelemetryResponse;
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} response - Http Response
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} telemetryPayloads
     * @desc Creates a new HoraValidatedTelemetryResponse object
     * @returns {SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse}
     *
     */
    private createHoraValidatedTelemetryResponse(
        response: ServerResponse,
        telemetryPayloads: Array<TelemetryPayload>
    ) {
        const { headers, data } = response;

        const replyAfter = this.getReplyAfter(headers);
        const requestId = this.getRequestId(headers);

        const dustClientPayload = telemetryPayloads[0].client;

        const { event } = dustClientPayload;
        const { playbackActivity, startupActivity } =
            dustClientPayload.data || {};

        const horaValidatedTelemetryResponse =
            new HoraValidatedTelemetryResponse({
                replyAfter,
                requestId,
                playbackActivity,
                startupActivity,
                event,
                // turn into array to follow convention by ValidatedTelemetryResponse.results
                results: [data]
            });

        return horaValidatedTelemetryResponse;
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} response - Http Response
     * @desc Creates a new TelemetryResponse object
     * @returns {SDK.Services.Internal.Telemetry.TelemetryResponse}
     *
     */
    private createTelemetryResponse(response: ServerResponse) {
        const { headers } = response;

        const replyAfter = this.getReplyAfter(headers);
        const requestId = this.getRequestId(headers);

        return new TelemetryResponse({ replyAfter, requestId });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Internal.Telemetry.TelemetryClientEndpoint} options.endpointKey
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} options.body
     * @param {Object} [options.data={}]
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof TelemetryClientEndpoint;
        body: Array<TelemetryPayload>;
        data?: TodoAny;
    }) {
        const { accessToken, endpointKey, body, data = {} } = options;

        const { useProxy } = data;
        const { href, headers, optionalHeaders } =
            this.config.endpoints[endpointKey];

        const requestBody = JSON.stringify(body);

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                },
                'X-BAMTECH-Event-Transport-Timestamp': () => {
                    return { replacer: '{time}', value: new Date() };
                },
                'X-BAMTech-Hora-Proxy': () => {
                    return { value: useProxy };
                }
            },
            headers,
            optionalHeaders
        );

        return {
            url: href,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     * @access private
     * @since 16.0.0
     * @param {Object} headers - Http Headers
     * @desc Gets the request Id from a given response header
     * @note Indicates a unique tracking identifier
     * @returns {String}
     *
     */
    private getRequestId(headers: Headers) {
        return headers.get('x-request-id') ?? undefined;
    }

    /**
     * @access private
     * @since 16.0.0
     * @param {Object} headers - Http Headers
     * @desc Gets the reply after from a given response header
     * @note Indicates the buffer should retry some action after a defined amount of time
     * @returns {Number}
     *
     */
    private getReplyAfter(headers: Headers) {
        return Number(headers.get('x-mlbam-reply-after'));
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Telemetry.TelemetryClient';
    }
}
