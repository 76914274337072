/**
 *
 * @module deviceMetadata
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/device.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import DeviceAttributes from './deviceAttributes';

/**
 *
 * @since 9.0.0
 * @desc A semi-structured data type, containing a few mandatory fields, but
 * allowing a number of arbitrary key -> string to be added at the top level of the structure.
 *
 */
export default class DeviceMetadata {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc Gets the device family (like android, apple, browser, microsoft, roku).
     *
     */
    public deviceFamily: string;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc Gets the runtime (for example OS) for the device.
     *
     */
    public applicationRuntime: string;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc Gets the concrete hardware or browser.
     *
     */
    public deviceProfile: string;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {DeviceAttributes}
     * @desc Attributes represent characteristics of the device and software runtime
     * of the application and SDK. All attributes should be sourced from documented
     * system APIs by the SDK unless overridden.
     *
     */
    public attributes: DeviceAttributes;

    /**
     *
     * @access public
     * @since 15.2.0
     * @type {String}
     * @desc Used for device registration to populate the `device.platform` value
     * on the access token. Maps to `SdkSessionConfiguration.commonValues.platformId`.
     *
     */
    public devicePlatformId: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.deviceFamily
     * @param {String} options.applicationRuntime
     * @param {String} options.deviceProfile
     * @param {DeviceAttributes} options.attributes
     * @param {String} options.devicePlatformId
     *
     */
    public constructor(options: {
        deviceFamily: string;
        applicationRuntime: string;
        deviceProfile: string;
        attributes: DeviceAttributes;
        devicePlatformId: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    deviceFamily: Types.nonEmptyString,
                    applicationRuntime: Types.nonEmptyString,
                    deviceProfile: Types.nonEmptyString,
                    attributes: Types.instanceStrict(DeviceAttributes),
                    devicePlatformId: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            deviceFamily,
            applicationRuntime,
            deviceProfile,
            attributes,
            devicePlatformId
        } = options;

        this.deviceFamily = deviceFamily;
        this.applicationRuntime = applicationRuntime;
        this.deviceProfile = deviceProfile;
        this.attributes = attributes || {};
        this.devicePlatformId = devicePlatformId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Device.DeviceMetadata';
    }
}
