/**
 *
 * @module flexManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/flex.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';
import AccessTokenProvider from './../token/accessTokenProvider';
import FlexClient from './../services/flex/flexClient';
import FlexManagerConfiguration from './../services/configuration/flexManagerConfiguration';

import LogTransaction from './../logging/logTransaction';
import AccessToken from '../token/accessToken';

interface FlexManagerOptions {
    flexManagerConfiguration: FlexManagerConfiguration;
    flexClient: FlexClient;
    logger: Logger;
    accessTokenProvider: AccessTokenProvider;
}

/**
 *
 * @access protected
 * @since 16.0.0
 *
 */
export default class FlexManager {
    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Services.Configuration.FlexManagerConfiguration}
     *
     */
    private config: FlexManagerConfiguration;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Services.Flex.FlexClient}
     *
     */
    public client: FlexClient;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.FlexManagerConfiguration} options.flexManagerConfiguration
     * @param {SDK.Services.Flex.FlexClient} options.flexClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     *
     */
    public constructor(options: FlexManagerOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    flexManagerConfiguration: Types.instanceStrict(
                        FlexManagerConfiguration
                    ),
                    flexClient: Types.instanceStrict(FlexClient),
                    logger: Types.instanceStrict(Logger),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            flexManagerConfiguration,
            flexClient,
            logger,
            accessTokenProvider
        } = options;

        this.config = flexManagerConfiguration;
        this.client = flexClient;
        this.logger = logger;
        this.accessTokenProvider = accessTokenProvider;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {SDK.Flex.FlexOptions} flexOptions - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Flex.Screen>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getScreen(
        flexOptions: SDK.Flex.FlexOptionsOptions,
        logTransaction: LogTransaction
    ): Promise<SDK.Services.Flex.Screen> {
        return await this.client.getScreen(
            flexOptions,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @since 17.0.0
     * @param {SDK.Flex.FlexOptions} flexOptions - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Flex.ExecutionResponse>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async execute(
        flexOptions: SDK.Flex.FlexOptionsOptions,
        logTransaction: LogTransaction
    ): Promise<SDK.Services.Flex.ExecutionResponse> {
        return await this.client.execute(
            flexOptions,
            this.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Flex.FlexManager';
    }
}
