/**
 *
 * @module mediaSegmentFetchedEventData
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import ServerRequest from './serverRequest';

/**
 *
 * @since 4.0.0
 * @desc This event represents a fetch of a resource on the network.This event represents
 * a fetch of a media segment (video, audio, or subtitle).
 *
 */
export default class MediaSegmentFetchedEventData {
    /**
     *
     * @param {Object} [options={}]
     * @param {Boolean} [options.offline=false]
     * @param {SDK.Services.QualityOfService.ServerRequest} [options.serverRequest]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    offline: Types.boolean.optional,
                    serverRequest: Types.instanceStrict(ServerRequest).optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const { offline, serverRequest } = options || {};

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {Boolean}
         * @desc True if the media is being played from a local source, e.g. it has
         * been downloaded for offline playback or was bundled in the application.
         *
         */
        this.offline = Check.assigned(offline) ? offline : false;

        /**
         *
         * @access public
         * @since 4.0.0
         * @type {SDK.Services.QualityOfService.ServerRequest|undefined}
         * @desc Data about a request/response to a server.
         *
         */
        this.serverRequest = serverRequest;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.QualityOfService.MediaSegmentFetchedEventData';
    }

    // #endregion
}
