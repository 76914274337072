/**
 *
 * @module entry
 * @desc {SDK.Services.Drm} entry point.
 *
 */

import DrmClient from './drmClient';
import PlayReadyMessage from './playReadyMessage';
import DrmUtils from './drmUtils';

/**
 *
 * @typedef {Object} SDK.Services.Drm
 *
 * @property {DrmClient} DrmClient
 * @property {PlayReadyMessage} PlayReadyMessage
 * @property {DrmUtils} DrmUtils
 *
 */
export default {
    DrmClient,
    PlayReadyMessage,
    DrmUtils
};
