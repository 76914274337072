/**
 *
 * @module purchaseActivationStatus
 *
 */

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Purchase.PurchaseActivationStatus
 *
 * @property {String} ACTIVE
 * @property {String} EXPIRED
 * @property {String} FAILED
 * @property {String} IGNORED
 * @property {String} UNKNOWN
 *
 */
enum PurchaseActivationStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    FAILED = 'FAILED',
    IGNORED = 'IGNORED',
    UNKNOWN = 'UNKNOWN'
}

export default PurchaseActivationStatus;
