/**
 *
 * @module purchaseContext
 * @see https://github.com/STRML/keyMirror
 *
 */

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Paywall.PurchaseContext
 * @desc Defines the different purchase contexts to identify the page this request originated from.
 * @note Flexible enum
 *
 * @property {String} planPicker
 * @property {String} earlyAccessPlanPicker
 * @property {String} accountDetails
 * @property {String} earlyAccess
 * @property {String} planSwitch - Since `4.16.0` - Present monthly-to-annual switch options.
 *
 */
enum PurchaseContext {
    planPicker = 'planPicker',
    earlyAccessPlanPicker = 'earlyAccessPlanPicker',
    accountDetails = 'accountDetails',
    earlyAccess = 'earlyAccess',
    planSwitch = 'planSwitch'
}
export default PurchaseContext;
