/**
 *
 * @module entry
 * @desc {SDK.Services.Token} entry point.
 *
 */

import AccessContext from './accessContext';
import AccessToken from './accessToken';
import AccessTokenDelegation from './accessTokenDelegation';
import AccessTokenType from './accessTokenType';
import DeviceGrant from './deviceGrant';
import DelegationToken from './delegationToken';
import Grant from './grant';
import RefreshTokenDelegation from './refreshTokenDelegation';
import RefreshGrant from './refreshGrant';
import TemporaryGrant from './temporaryGrant';
import ExchangeGrant from './exchangeGrant';
import TokenClient from './tokenClient';
import TokenClientConfiguration from './tokenClientConfiguration';
import TokenClientExtrasMap from './tokenClientExtrasMap';
import TokenRequest from './tokenRequest';
import TokenRequestBuilder from './tokenRequestBuilder';

/**
 *
 * @typedef {Object} SDK.Services.Token
 *
 * @property {AccessContext} AccessContext
 * @property {AccessToken} AccessToken
 * @property {AccessTokenDelegation} AccessTokenDelegation
 * @property {SDK.Services.Token.AccessTokenType} AccessTokenType
 * @property {DeviceGrant} DeviceGrant
 * @property {DelegationToken} DelegationToken
 * @property {Grant} Grant
 * @property {RefreshTokenDelegation} RefreshTokenDelegation
 * @property {RefreshGrant} RefreshGrant
 * @property {TemporaryGrant} TemporaryGrant
 * @property {ExchangeGrant} ExchangeGrant
 * @property {TokenClient} TokenClient
 * @property {TokenClientConfiguration} TokenClientConfiguration
 * @property {TokenClientExtrasMap} TokenClientExtrasMap
 * @property {TokenRequest} TokenExchangeRequest
 * @property {TokenRequestBuilder} TokenExchangeRequestBuilder
 *
 */
export default {
    AccessContext,
    AccessToken,
    AccessTokenDelegation,
    AccessTokenType,
    DeviceGrant,
    DelegationToken,
    Grant,
    RefreshTokenDelegation,
    RefreshGrant,
    TemporaryGrant,
    ExchangeGrant,
    TokenClient,
    TokenClientConfiguration,
    TokenClientExtrasMap,
    TokenRequest,
    TokenRequestBuilder
};
