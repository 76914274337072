/**
 *
 * @module purchaseActivationResult
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ErrorReason from '../exception/errorReason';
import PurchaseActivation from './purchaseActivation';

/**
 *
 * @access public
 * @desc The result of the purchase activation.
 *
 */
export default class PurchaseActivationResult {
    /**
     *
     * @access public
     * @type {Boolean}
     * @desc Indicator that the access token should be refreshed before proceeding.
     *
     */
    public refreshAccessToken: boolean;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc Indicator of whether temporary access is granted.
     *
     */
    public temporaryAccessGranted: boolean;

    /**
     *
     * @access private
     * @type {Array<ErrorReason>}
     * @desc Errors responsible for the request failure.
     *
     */
    private errors?: Array<ErrorReason>;

    /**
     *
     * @access public
     * @type {Array<PurchaseActivation>}
     * @desc Purchase information for all activated SKUs.
     *
     */
    public purchases: Array<PurchaseActivation>;

    /**
     *
     * @param {Boolean} [refreshAccessToken=false]
     * @param {Boolean} [temporaryAccessGranted=false]
     * @param {Array<ErrorReason>} [errors=[]]
     * @param {Array<PurchaseActivation>} [purchases=[]]
     *
     */
    public constructor(options: {
        refreshAccessToken?: boolean;
        temporaryAccessGranted?: boolean;
        errors?: Array<ErrorReason>;
        purchases?: Array<PurchaseActivation>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    refreshAccessToken: Types.boolean.optional,
                    temporaryAccessGranted: Types.boolean.optional,
                    errors: Types.array.of.instanceStrict(ErrorReason).optional,
                    purchases:
                        Types.array.of.instanceStrict(PurchaseActivation)
                            .optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            refreshAccessToken = false,
            temporaryAccessGranted = false,
            errors = [],
            purchases = []
        } = options;

        this.refreshAccessToken = refreshAccessToken;
        this.temporaryAccessGranted = temporaryAccessGranted;
        this.errors = errors;
        this.purchases = purchases;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Purchase.PurchaseActivationResult';
    }
}
