/**
 *
 * @module paywallManagerConfiguration
 *
 */

import PaywallClientConfiguration from '../paywall/paywallClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing paywall.
 *
 */
export default class PaywallManagerConfiguration extends ServiceConfiguration<PaywallClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.PaywallManagerConfiguration';
    }

    // #endregion
}
