/**
 *
 * @module mercadoApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    MercadoPaymentRequest,
    MercadoPaymentRequestTypedef
} from './typedefs';

import { PaymentRedirectResponse } from '../../services/commerce/typedefs';

import CommerceManager from '../commerceManager';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';
import getSafe from '../../services/util/getSafe';
import Logger from '../../logging/logger';

const DustUrn = DustUrnReference.commerce.mercadoApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface MercadoApiOptions {
    commerceManager: CommerceManager;
    logger: Logger;
}

/**
 *
 * @access public
 * @since 4.19.0
 * @desc Provides ability to access mercado pago data.
 *
 */
export default class MercadoApi extends BaseApi {
    /**
     *
     * @access private
     * @since 4.19.0
     * @type {SDK.Commerce.CommerceManager}
     *
     */
    private commerceManager: CommerceManager;

    /**
     *
     * @access private
     * @since 4.19.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Commerce.CommerceManager} options.commerceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: MercadoApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceManager: Types.instanceStrict(CommerceManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceManager } = options;

        this.commerceManager = commerceManager;

        this.dustEnabled = getSafe(
            () => this.commerceManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.19.0
     * @param {Object<SDK.Commerce.Mercado.MercadoPaymentRequest>} request - request Information used to retrieve a paymentMethodId.
     * @desc Completes a Mercado Pago transaction and returns a `paymentMethodId` and `redirectUrl`.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<PaymentRedirectResponse>} paymentMethodId and redirect url.
     *
     */
    public async submitPayment(
        request: MercadoPaymentRequest
    ): Promise<PaymentRedirectResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(MercadoPaymentRequestTypedef)
        }
    })
    public async submitPayment(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.submitMercadoPayment(
            request,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.Mercado.MercadoApi';
    }

    // #endregion
}
