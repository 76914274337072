/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import {
    BillingAddress,
    BillingAddressTypedef,
    PayerName,
    PayerNameTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../typedefs';

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.PayerInfo
 * @since 9.0.0
 * @property {String} payer - Email address for PayPal user.
 * @property {String} payerId - PayPal payerId, 13 character unique ID.
 * @property {Object<SDK.Commerce.PayerName>} payerName - Name of the payer.
 * @property {String} payerCountry - PayPal country. See ISO 3166-1 alpha-2.
 *
 */
export interface PayerInfo {
    payer: string;
    payerId: string;
    payerName: PayerName;
    payerCountry: string;
}

/**
 *
 * @access private
 *
 */
export const PayerInfoTypedef = {
    payer: Types.nonEmptyString,
    payerId: Types.nonEmptyString,
    payerName: Types.object(PayerNameTypedef),
    payerCountry: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.PayPalPaymentMethod
 * @since 9.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 * @property {Boolean} [isShared] - Flag indicating if the user has agreed to allow payment method to be shared across partners.
 * @property {Object<SDK.Commerce.PayPal.PayerInfo>} [payerInfo] - User information.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users country code.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface PayPalPaymentMethod extends PaymentMethod {
    isDefault: boolean;
    usage: string;
    isShared?: boolean;
    payerInfo?: PayerInfo;
    billingAddress: BillingAddress;
}

/**
 *
 * @access private
 *
 */
export const PayPalPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString,
    isShared: Types.boolean.optional,
    payerInfo: Types.object(PayerInfoTypedef).optional,
    billingAddress: Types.object(BillingAddressTypedef)
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.GetCheckoutDetailsRequest
 * @since 9.0.0
 * @property {String} token - User's PayPal token.
 * @property {Boolean} isShared - Flag used to determine if this payment method can be used across partners.
 * @property {Boolean} isDefault - Flag used to determine if this payment method should be used for future transactions.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 *
 */
export interface GetCheckoutDetailsRequest {
    token: string;
    isShared: boolean;
    isDefault: boolean;
    usage: string;
}

/**
 *
 * @access private
 *
 */
export const GetCheckoutDetailsRequestTypedef = {
    token: Types.nonEmptyString,
    isShared: Types.boolean,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.SetCheckoutDetailsRequest
 * @since 9.0.0
 * @property {String} cancelUrl - Url used to go back to if user cancels paypal transaction.
 * @property {String} returnUrl - Url used to go back to after user confirms paypal transaction.
 * @property {String} currencyCode - Currency to be used to bill. Should originate from a PriceOrderResponse.
 * @property {String} totalAmount - Total order amount. Should originate from a PriceOrderResponse.
 * @property {String} locale - Locale of the user.
 *
 */
export interface SetCheckoutDetailsRequest {
    cancelUrl: string;
    returnUrl: string;
    currencyCode: string;
    totalAmount: string;
    locale: string;
}

/**
 *
 * @access private
 *
 */
export const SetCheckoutDetailsRequestTypedef = {
    cancelUrl: Types.nonEmptyString,
    returnUrl: Types.nonEmptyString,
    currencyCode: Types.nonEmptyString,
    totalAmount: Types.nonEmptyString,
    locale: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.PayPalUpdatePaymentMethodRequest
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {Object<SDK.Commerce.PayPal.PayerInfo>} [payerInfo]
 * @property {String} [billingAgreementId] - PayPal billingAgreementId.
 * @property {Boolean} [isShared] - Flag to mark if the user has agreed to allow payment method to be shared across TWDC partners.
 *
 */
export interface PayPalUpdatePaymentMethodRequest
    extends UpdatePaymentMethodBase {
    payerInfo?: PayerInfo;
    billingAgreementId?: string;
    isShared?: boolean;
}

/**
 *
 * @access private
 *
 */
export const PayPalUpdatePaymentMethodRequestTypedef = {
    ...UpdatePaymentMethodBaseTypedef,
    payerInfo: Types.object(PayerInfoTypedef).optional,
    billingAgreementId: Types.nonEmptyString.optional,
    isShared: Types.boolean.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.CreateBraintreePaymentMethodRequest
 * @since 21.0.0
 * @property {String} nonce - Nonce from client.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {Boolean} [isShared] - Flag to mark if the user has agreed to allow payment method to be shared across TWDC partners.
 *
 */
export interface CreateBraintreePaymentMethodRequest {
    nonce: string;
    isDefault: boolean;
    isShared?: boolean;
}

/**
 *
 * @access private
 *
 */
export const CreateBraintreePaymentMethodRequestTypedef = {
    nonce: Types.nonEmptyString,
    isDefault: Types.boolean,
    isShared: Types.boolean.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayPal.UpdateBraintreePaymentMethodRequest
 * @since 21.0.0
 * @property {String} paymentMethodId - PayPal v2 paymentMethodId created via Braintree.
 * @property {String} [nonce] - Nonce from client.
 * @property {Boolean} [isDefault] - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {Boolean} [isShared] - Flag to mark if the user has agreed to allow payment method to be shared across TWDC partners.
 *
 */
export interface UpdateBraintreePaymentMethodRequest {
    paymentMethodId: string;
    nonce?: string;
    isDefault?: boolean;
    isShared?: boolean;
}

/**
 *
 * @access private
 *
 */
export const UpdateBraintreePaymentMethodRequestTypedef = {
    paymentMethodId: Types.nonEmptyString,
    nonce: Types.nonEmptyString.optional,
    isDefault: Types.boolean.optional,
    isShared: Types.boolean.optional
};
