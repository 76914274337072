/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

/**
 *
 * @typedef {Object} SDK.Flex.FlexOptions
 * @since 16.0.0
 * @property {String<SDK.Services.Flex.FlexClientEndpoint>} key - Used to determine the endpoint to hit. EX: "billingHistory", "subscriptionSummary", "cancelFlow"
 * @property {String} version - Version provided by app dev (app dev determines which version they want/support). EX: "v1"
 * @property {Object} [flexParams] - Flex parameters to be applied as query params (GET) or (POST, PUT) data to be sent over.
 * @property {Function} [configOverride] - Optional override function. Right before the SDK makes a Flex api call we grab the config (if found, "default" for #getScreen, "execution" for #execute if not found) based on the key. If this function is defined, allows app devs to manipulate the configuration by adding to the "default", or "execution" href value, changing the HTTP method, etc...
 *
 */
export const FlexOptions = {
    key: Types.nonEmptyString,
    version: Types.nonEmptyString,
    flexParams: Types.object().optional,
    configOverride: Types.function.optional
};
