/**
 *
 * @module cafPlayerAdapter
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://developers.google.com/cast/docs/caf_receiver_overview
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.PlayerManager
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.PlaybackConfig
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.messages.LoadRequestData
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.messages.MediaInformation
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.events#.EventType
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import PlayerAdapter from './../playerAdapter';
import PlaybackMetrics from './../playbackMetrics';
import PlaybackEventListener from './../playbackEventListener';
import Playlist from './../playlist';
import DssHlsPlayerAdapter from './dssHlsPlayerAdapter';
import MelHivePlayerAdapter from './melHivePlayerAdapter';

import DrmType from '../../services/media/drmType';
import DrmProvider from '../../drm/drmProvider';

import PlaybackExitedCause from '../../services/qualityOfService/playbackExitedCause';
import PlaybackSeekCause from '../../services/qualityOfService/playbackSeekCause';
import SeekDirection from '../../services/qualityOfService/seekDirection';
import getSafe from '../../services/util/getSafe';

import adEngineRegex from '../../services/util/adEngineRegex';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

/**
 *
 * @since 3.1.0
 * @desc Chromecast Application Framework (CAF) PlayerAdapter.
 *
 */
export default class CafPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {cast.framework.PlayerManager} options.nativePlayer
     * @param {cast.framework} options.framework
     * @param {dss-hls} [options.streamer]
     * @param {Boolean} [options.disableSourceBufferTimeAdjust=false]
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     *
     */
    constructor(options) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    framework: Types.object(),
                    nativePlayer: Types.object(),
                    streamer: Types.object({
                        on: Types.function
                    }).optional,
                    disableSourceBufferTimeAdjust: Types.boolean.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            framework,
            streamer,
            disableSourceBufferTimeAdjust = false
        } = options;

        /**
         *
         * @access private
         * @type {cast.framework|null}
         * @desc Internal reference to the cast framework Object. Allows property
         * reference instead of checking against a cast global, which in turn makes this
         * PlayerAdapter easily testable.
         *
         */
        this.framework = framework;

        /**
         *
         * @access private
         * @type {Number|null}
         * @desc The current bit rate in kbit.
         * @note we must do this, make sure that the value gets updated "onEnded" "onPause" etc
         *
         */
        this.currentBitrate = null;

        /**
         *
         * @access private
         * @since 4.15.0
         * @type {Number|null}
         * @desc The current bitrate average rate in kbit.
         *
         */
        this.currentBitrateAvg = null;

        /**
         *
         * @access private
         * @since 4.15.0
         * @type {Number|null}
         * @desc The current bitrate peak rate in kbit.
         *
         */
        this.currentBitratePeak = null;

        /**
         *
         * @access private
         * @since 4.3.0
         * @type {Boolean}
         * @desc Overrides the disableSourceBufferTimeAdjust property in cast.framework.PlaybackConfig
         *
         */
        this.disableSourceBufferTimeAdjust = disableSourceBufferTimeAdjust;

        /**
         *
         * @access private
         * @type {dds-hls|mel-hive|undefined}
         * @since 4.5.0
         * @desc instance of the dss-hls player or mel hive player
         *
         */
        this.streamer = streamer;

        /**
         *
         * @access public
         * @since 3.2.0
         * @type {cast.framework.messages.HlsSegmentFormat}
         *
         */
        this.audioSegmentFormat = undefined;

        /**
         *
         * @access public
         * @since 3.2.0
         * @type {cast.framework.messages.HlsVideoSegmentFormat}
         *
         */
        this.videoSegmentFormat = null;

        /**
         *
         * @access public
         * @type {Array<SDK.Drm.DrmProvider>}
         * @since 4.5.0
         * @desc Set of DRM providers
         *
         */
        this.drmProviders = [];

        /**
         *
         * @access private
         * @type {SDK.Media.PlayerAdapter.DssHlsPlayerAdapter|null}
         * @since 4.7.0
         * @desc instance of the DssHlsPlayerAdapter or MelHivePlayerAdapter
         *
         */
        this.streamerAdapter = this.createStreamerAdapter(
            streamer,
            this.videoPlayerName,
            this.videoPlayerVersion
        );
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @throws {InvalidStateException} Unable to set playlistUri on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    async setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        try {
            if (Check.assigned(this.streamerAdapter)) {
                return await this.streamerAdapter.setSource(playlist);
            }

            this.playlistUri = playlist.streamUri;

            /**
             *
             * @type {cast.framework.PlaybackConfig}
             * @desc A copy of the current player config
             *
             */
            const playbackConfig = this.getPlaybackConfig();

            // undocumented Google MPL flag
            playbackConfig.disableSourceBufferTimeAdjust =
                this.disableSourceBufferTimeAdjust;

            // set current player config
            this.nativePlayer.setPlaybackConfig(playbackConfig);

            // override the mediaUrl / contentId in the LOAD request
            this.nativePlayer.setMediaUrlResolver(() => playlist.streamUri);
        } catch (ex) {
            const errorMsg = `${this.toString()}.setSource(playlist) unable to set playlistUri on NativePlayer`;

            const reasons = [new ErrorReason('', `${errorMsg} - ${ex}`)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        return undefined;
    }

    /**
     *
     * @access public
     * @param {Array<String>} audioSegmentTypes
     * @param {Array<String>} videoSegmentTypes
     * @since 3.2.0
     * @returns {Promise<Void>}
     *
     */
    async updateSegmentFormat(audioSegmentTypes, videoSegmentTypes) {
        this.updateAudioSegmentFormat(audioSegmentTypes);
        this.updateVideoSegmentFormat(videoSegmentTypes);
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener, nativePlayer } = this;

        // clear the override the mediaUrl / contentId in the LOAD request
        nativePlayer.setMediaUrlResolver();

        this.playlistUri = '';

        this.currentBitrate = null;
        this.currentBitrateAvg = null;
        this.currentBitratePeak = null;

        this.drmProvider = null;
        this.drmProviders = [];
        this.audioSegmentFormat = undefined;
        this.videoSegmentFormat = undefined;

        if (Check.assigned(this.streamerAdapter)) {
            this.streamerAdapter.clean();
        }

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @since 3.1.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
        this.framework = null;
        this.streamerAdapter = null;
        this.streamer = null;
    }

    /**
     *
     * @access public
     * @since 3.6.0
     * @param {Object} adEngineData
     * @desc assigns adEngine data from the playlist service, some of which should be included in segment requests
     * @note if adEngineData is unassigned do not do anything
     *
     */
    setAdEngineData(adEngineData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                adEngineData: Types.object()
            };

            typecheck(this, 'setAdEngineData', params, arguments);
        }

        this.adEngineData = adEngineData;

        if (Check.nonEmptyObject(adEngineData)) {
            const { nativePlayer } = this;
            const { ssess } = adEngineData;

            const playbackConfig = this.getPlaybackConfig();

            playbackConfig.segmentRequestHandler = (requestInfo) => {
                const isAdEngineCall = adEngineRegex(requestInfo.url);

                // need to reset these for each segment request
                requestInfo.headers = null;
                requestInfo.crossDomain = undefined;
                requestInfo.withCredentials = false;

                if (ssess && isAdEngineCall) {
                    requestInfo.headers = {};
                    requestInfo.headers.ssess = ssess;
                    requestInfo.crossDomain = true;
                    requestInfo.withCredentials = true;
                }
            };

            nativePlayer.setPlaybackConfig(playbackConfig);
        }
    }

    /**
     *
     * @access public
     * @since 15.2.0
     * @param {Object} data
     * @param {Number} data.seekSize - Indication that the user is seeking by a fixed time (size) e.g. +30, -30
     * @param {String<SDK.Services.QualityOfService.SeekDirection>} [data.seekDirection] - used for Live events
     * @param {String<SDK.Services.QualityOfService.PlaybackSeekCause>} data.seekCause
     * @desc Sets the seekData property, for the streamer adapter, provided by the application.
     *
     */
    setSeekData(data) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                data: Types.object({
                    seekSize: Types.number,
                    seekDirection: Types.in(SeekDirection).optional,
                    seekCause: Types.in(PlaybackSeekCause)
                })
            };

            typecheck.warn(this, 'setSeekData', params, arguments);
        }

        if (this.streamerAdapter) {
            this.streamerAdapter.setSeekData(data);
        } else {
            this.logger.warn(
                this.toString(),
                'Streamer is not set. Seek data will not be set.'
            );
        }
    }

    // #region protected

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        try {
            const {
                nativePlayer,
                boundHandlers,
                framework,
                streamerAdapter,
                playbackReadyEvent,
                playbackStartedEvent,
                playbackInitializedEvent,
                rebufferingEvent,
                playbackSeekedEvent,
                bitrateChangedEvent,
                playbackPausedEvent
            } = this;

            const {
                BUFFERING,
                PLAY,
                PLAYING,
                PAUSE,
                BITRATE_CHANGED,
                SEEKED,
                SEEKING,
                PLAYER_LOAD_COMPLETE
            } = framework.events.EventType;

            if (streamerAdapter) {
                streamerAdapter.addListener(listener);

                return;
            }

            this.listener = listener;

            boundHandlers.playbackReadyEvent = playbackReadyEvent.bind(this);
            boundHandlers.playbackStartedEvent =
                playbackStartedEvent.bind(this);
            boundHandlers.rebufferingEvent = rebufferingEvent.bind(this);
            boundHandlers.playbackSeekedEvent = playbackSeekedEvent.bind(this);
            boundHandlers.playbackPausedEvent = playbackPausedEvent.bind(this);
            boundHandlers.bitrateChangedEvent = bitrateChangedEvent.bind(this);
            boundHandlers.playbackInitializedEvent =
                playbackInitializedEvent.bind(this);

            nativePlayer.addEventListener(
                PLAY,
                boundHandlers.playbackReadyEvent
            );
            nativePlayer.addEventListener(
                PLAYING,
                boundHandlers.playbackStartedEvent
            );
            nativePlayer.addEventListener(
                BUFFERING,
                boundHandlers.rebufferingEvent
            );
            nativePlayer.addEventListener(
                SEEKED,
                boundHandlers.playbackSeekedEvent
            );
            nativePlayer.addEventListener(
                SEEKING,
                boundHandlers.playbackSeekedEvent
            );
            nativePlayer.addEventListener(
                BITRATE_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.addEventListener(
                PAUSE,
                boundHandlers.playbackPausedEvent
            );
            nativePlayer.addEventListener(
                PLAYER_LOAD_COMPLETE,
                boundHandlers.playbackInitializedEvent
            );
        } catch (ex) {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener
     * @throws {InvalidStateException} Unable to remove PlaybackEventListener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        try {
            const { nativePlayer, boundHandlers, framework, streamerAdapter } =
                this;

            const {
                BUFFERING,
                PLAY,
                PLAYING,
                PAUSE,
                BITRATE_CHANGED,
                SEEKED,
                SEEKING,
                PLAYER_LOAD_COMPLETE
            } = framework.events.EventType;

            if (Check.assigned(streamerAdapter)) {
                streamerAdapter.removeListener(listener);
            } else {
                if (this.listener === listener) {
                    this.listener = null;

                    nativePlayer.removeEventListener(
                        PLAY,
                        boundHandlers.playbackReadyEvent
                    );
                    nativePlayer.removeEventListener(
                        PLAYING,
                        boundHandlers.playbackStartedEvent
                    );
                    nativePlayer.removeEventListener(
                        BUFFERING,
                        boundHandlers.rebufferingEvent
                    );
                    nativePlayer.removeEventListener(
                        SEEKED,
                        boundHandlers.playbackSeekedEvent
                    );
                    nativePlayer.removeEventListener(
                        SEEKING,
                        boundHandlers.playbackSeekedEvent
                    );
                    nativePlayer.removeEventListener(
                        BITRATE_CHANGED,
                        boundHandlers.bitrateChangedEvent
                    );
                    nativePlayer.removeEventListener(
                        PAUSE,
                        boundHandlers.playbackPausedEvent
                    );
                    nativePlayer.removeEventListener(
                        PLAYER_LOAD_COMPLETE,
                        boundHandlers.playbackInitializedEvent
                    );
                }
            }
        } catch (ex) {
            const errorMsg = `${this.toString()}.removeListener(listener) unable to remove PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @since 3.2.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    setDrmProvider(drmProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProvider: Types.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProvider', params, arguments);
        }

        this.drmProvider = drmProvider;

        return this.attach();
    }

    /**
     *
     * @access protected
     * @since 4.5.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    async setDrmProviders(drmProviders) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { streamerAdapter } = this;

        this.drmProviders = drmProviders;

        if (Check.assigned(streamerAdapter)) {
            return await streamerAdapter.setDrmProviders(drmProviders);
        }

        return await this.setDrmProvider(drmProviders[0]);
    }

    /**
     *
     * @access protected
     * @since 4.15.0
     * @desc Gets a snapshot of information about media playback.
     * @returns {PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     *
     */
    getPlaybackMetrics() {
        // bypass to use DssHls getPlaybackMetrics.
        if (Check.assigned(this.streamerAdapter)) {
            return this.streamerAdapter.getPlaybackMetrics();
        }

        return new PlaybackMetrics({
            currentBitrate: this.currentBitrate,
            currentPlayhead: this.nativePlayer.getCurrentTimeSec(),
            currentBitrateAvg: this.currentBitrateAvg,
            currentBitratePeak: this.currentBitratePeak
        });
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     * @since 3.2.0
     * @returns {Promise<Void>}
     *
     */
    attach() {
        const { drmProvider, framework } = this;

        if (drmProvider.type === DrmType.WIDEVINE) {
            return this.attachDrm(
                getSafe(() => framework.ContentProtection.WIDEVINE)
            );
        }

        if (drmProvider.type === DrmType.PLAYREADY) {
            return this.attachDrm(
                getSafe(() => framework.ContentProtection.PLAYREADY)
            );
        }

        return this.attachSilkDrm();
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @param {cast.framework.ContentProtection} protectionSystem
     * @throws {InvalidStateException} Unable to set playback configuration on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    attachDrm(protectionSystem) {
        const { nativePlayer, drmProvider } = this;

        try {
            /**
             *
             * @type {cast.framework.PlaybackConfig}
             * @desc A copy of the current player config
             *
             */
            const playbackConfig = this.getPlaybackConfig();

            playbackConfig.licenseUrl = drmProvider.licenseRequestUri;
            playbackConfig.protectionSystem = protectionSystem;

            // override license request handler to set credentials
            playbackConfig.licenseRequestHandler = (requestInfo) => {
                requestInfo.crossDomain = true;
                requestInfo.withCredentials = true;
                requestInfo.headers =
                    drmProvider.processLicenseRequestHeaders() || {};
            };

            // set current player config
            nativePlayer.setPlaybackConfig(playbackConfig);

            return Promise.resolve();
        } catch (ex) {
            const errorMsg = `${this.toString()}.attachDrm(protectionSystem) unable to set playback configuration on NativePlayer`;

            const reasons = [new ErrorReason('', `${errorMsg} - ${ex}`)];
            const exceptionData = ExceptionReference.common.invalidState;

            return Promise.reject(
                new ServiceException({ reasons, exceptionData })
            );
        }
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @throws {InvalidStateException} Unable to set playback configuration on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    attachSilkDrm() {
        const { nativePlayer, accessToken } = this;

        try {
            /**
             *
             * @type {cast.framework.PlaybackConfig}
             * @desc A copy of the current player config
             *
             */
            const playbackConfig = this.getPlaybackConfig();

            // override license request handler to set credentials
            playbackConfig.licenseRequestHandler = (requestInfo) => {
                requestInfo.withCredentials = true;
                requestInfo.headers = {};
                requestInfo.headers.Authorization = accessToken;
            };

            // set current player config
            nativePlayer.setPlaybackConfig(playbackConfig);

            return Promise.resolve();
        } catch (ex) {
            const errorMsg = `${this.toString()}.attachSilkDrm() unable to set playback configuration on NativePlayer`;

            const reasons = [new ErrorReason('', `${errorMsg} - ${ex}`)];
            const exceptionData = ExceptionReference.common.invalidState;

            return Promise.reject(
                new ServiceException({ reasons, exceptionData })
            );
        }
    }

    /**
     *
     * @access private
     * @since 4.5.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    setDrmConfiguration(drmProviders) {
        const keySystems = [];

        for (const drmProvider of drmProviders) {
            const keySystem = drmProvider.type;
            const licenseRequestUri = getSafe(
                () => drmProvider.licenseRequestUri
            );

            keySystems.push({
                keySystem,
                licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                /**
                 *
                 * @note bam-hls calls `Promise.resolve(this._keySystemConfig.serverCertificate)`
                 * where serverCertificate is a reference to this function, in EME.js.
                 * We need to use an IIFE here so we setup a promise since they are not executing this function
                 * only expecting a Promise(cert), Promise(undefined), cert or undefined
                 *
                 */
                serverCertificate: ((innerKeySystem) => {
                    return () => {
                        if (innerKeySystem === DrmType.FAIRPLAY) {
                            return drmProvider.getFairPlayCertificate();
                        }

                        if (innerKeySystem === DrmType.WIDEVINE) {
                            return drmProvider.getWidevineCertificate();
                        }

                        // bam-hls expects undefined so they don't attempt to process this as a certificate
                        return Promise.resolve(undefined);
                    };
                })(keySystem)
            });
        }

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @param {Array<String>} audioSegmentTypes
     * @since 3.2.0
     * @returns {this}
     *
     */
    updateAudioSegmentFormat(audioSegmentTypes) {
        const { framework } = this;

        this.audioSegmentFormat = undefined;

        if (Check.nonEmptyArray(audioSegmentTypes)) {
            if (audioSegmentTypes.includes('FMP4')) {
                this.audioSegmentFormat = getSafe(
                    () => framework.messages.HlsSegmentFormat.FMP4
                );

                return this;
            }

            if (audioSegmentTypes.includes('PACKED_AUDIO')) {
                this.audioSegmentFormat = getSafe(
                    () => framework.messages.HlsSegmentFormat.TS_AAC
                );

                return this;
            }
        }

        return this;
    }

    /**
     *
     * @access private
     * @param {Array<String>} videoSegmentTypes
     * @since 3.2.0
     * @returns {this}
     *
     */
    updateVideoSegmentFormat(videoSegmentTypes) {
        const { framework } = this;

        this.videoSegmentFormat = undefined;

        if (Check.nonEmptyArray(videoSegmentTypes)) {
            if (videoSegmentTypes.includes('FMP4')) {
                this.videoSegmentFormat = getSafe(
                    () => framework.messages.HlsVideoSegmentFormat.FMP4
                );

                return this;
            }

            if (videoSegmentTypes.includes('TS')) {
                this.videoSegmentFormat = getSafe(
                    () => framework.messages.HlsVideoSegmentFormat.MPEG2_TS
                );

                return this;
            }
        }

        return this;
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @returns {cast.framework.PlaybackConfig}
     *
     */
    getPlaybackConfig() {
        const { nativePlayer, framework } = this;

        return Object.assign(
            Check.function(nativePlayer.getPlaybackConfig)
                ? nativePlayer.getPlaybackConfig() || {}
                : {},
            new framework.PlaybackConfig()
        );
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object<SDK.Media.AudioChangedEvent>} eventData
     * @desc Trigger when audio stream changes.
     *
     */
    audioChangedEvent(eventData) {
        this.onAudioChanged(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object<SDK.Media.SubtitleChangedEvent>} eventData
     * @desc Trigger when the subtitle changes.
     *
     */
    subtitleChangedEvent(eventData) {
        this.onSubtitleChanged(eventData);
    }

    /**
     *
     * @access private
     * @param {Object<SDK.Media.MultivariantPlaylistFetchedEvent>} eventData
     * @since 15.0.0
     *
     */
    multivariantPlaylistFetchedEvent(eventData) {
        this.onMultivariantPlaylistFetched(eventData);
    }

    /**
     *
     * @access private
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} eventData
     * @since 4.0.0
     *
     */
    variantPlaylistFetchedEvent(eventData) {
        this.onVariantPlaylistFetched(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.BufferingEvent} eventData
     * @desc Trigger when buffering starts or ends.
     *
     */
    rebufferingEvent(eventData) {
        const { isBuffering } = eventData || {};

        if (isBuffering) {
            this.onRebufferingStarted({});
        } else {
            this.onRebufferingEnded({});
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [options]
     * @param {cast.framework.events.MediaFinishedEvent} [options.eventData]
     * @param {Boolean} [options.isEnded]
     * @desc Trigger when playback has been exited.
     *
     */
    playbackEndedEvent(options) {
        if (this.streamerAdapter) {
            this.streamerAdapter.playbackEndedEvent(options);
        } else {
            const { eventData } = options || {};
            const { framework } = this;
            const { END_OF_STREAM, STOPPED, ERROR } =
                framework.events.EndedReason;
            const { endedReason, currentMediaTime = 0 } = eventData || {};

            let cause;

            switch (endedReason) {
                case END_OF_STREAM:
                    cause = PlaybackExitedCause.playedToEnd;
                    break;

                case STOPPED:
                    cause = PlaybackExitedCause.user;
                    break;

                case ERROR:
                    cause = PlaybackExitedCause.error;
                    break;
            }

            const playheadPosition = currentMediaTime * 1000;

            this.onPlaybackEnded({
                cause,
                playheadPosition
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.LoadEvent} eventData
     * @desc Trigger when playback has been initialized.
     *
     */
    playbackInitializedEvent(eventData) {
        const { playlistUri: streamUrl } = this;
        const { media } = eventData || {};
        const { startAbsoluteTime } = media;

        let playheadPosition;

        if (Check.assigned(startAbsoluteTime)) {
            playheadPosition = startAbsoluteTime * 1000;
        }

        this.onPlaybackInitialized({
            streamUrl,
            playheadPosition
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.MediaElementEvent} eventData
     * @desc Trigger when playback seeks.
     *
     */
    playbackSeekedEvent(eventData) {
        const { framework } = this;
        const { SEEKED, SEEKING } = framework.events.EventType;
        const { type, currentMediaTime = 0 } = eventData || {};

        const playheadPosition = currentMediaTime * 1000;

        if (type === SEEKED) {
            this.onPlaybackSeekEnded({
                playheadPosition
            });
        }

        if (type === SEEKING) {
            this.onPlaybackSeekStarted({
                playheadPosition
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.BitrateChangedEvent} eventData
     * @desc Trigger when the bitrate changes.
     *
     */
    bitrateChangedEvent(eventData) {
        const { bitrateAvg, bitratePeak, totalBitrate = 0 } = eventData || {};

        try {
            // convert to Kbps
            this.currentBitrate = Math.round(totalBitrate / 1000);
        } catch (ex) {
            this.currentBitrate = null;
        }

        this.onBitrateChanged({
            bitrateAvg,
            bitratePeak,
            playheadPosition: this.nativePlayer.getCurrentTimeSec()
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when playback is ready.
     *
     */
    playbackReadyEvent() {
        this.onPlaybackReady({});
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when playback starts.
     *
     */
    playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when playback gets paused.
     *
     */
    playbackPausedEvent() {
        this.onPlaybackPaused({
            playheadPosition: this.nativePlayer.getCurrentTimeSec()
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {dds-hls|mel-hive} streamer
     * @param {String} videoPlayerName
     * @param {String} videoPlayerVersion
     * @desc Creates a player adapter based on what streamer is provided if applicable.
     * @returns {PlayerAdapter|null}
     *
     */
    createStreamerAdapter(streamer, videoPlayerName, videoPlayerVersion) {
        if (streamer) {
            if (
                Check.function(streamer.getClass) &&
                streamer.getClass().playerName === 'MEL-HIVE'
            ) {
                this.logger.info(
                    this.toString(),
                    'Creating MelHivePlayerAdapter'
                );

                return new MelHivePlayerAdapter({
                    nativePlayer: streamer,
                    videoPlayerName,
                    videoPlayerVersion
                });
            }

            this.logger.info(this.toString(), 'Creating DssHlsPlayerAdapter');

            return new DssHlsPlayerAdapter({
                nativePlayer: streamer,
                videoPlayerName,
                videoPlayerVersion
            });
        }

        return null;
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.CafPlayerAdapter';
    }

    // #endregion
}
