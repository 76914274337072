/**
 *
 * @module accessToken
 *
 */

import ServicesAccessToken from '../services/token/accessToken';

/**
 *
 *
 */
export default class AccessToken extends ServicesAccessToken {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Token.AccessToken';
    }
}
