/**
 *
 * @module presentation
 *
 */

import { Types, typecheck } from '@dss/type-checking';

//import ThumbnailPresentationType from './thumbnailPresentationType';

/**
 *
 *
 */
export default class Presentation {
    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.Media.ThumbnailPresentationType>} options.presentationType
     * @param {Number} options.thumbnailCount
     * @param {Number} options.offsetMilliseconds
     * @param {Array<String>} options.paths
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    presentationType: Types.nonEmptyString, // Types.in(ThumbnailPresentationType).or.nonEmptyString,
                    thumbnailCount: Types.number,
                    offsetMilliseconds: Types.number,
                    paths: Types.array.of.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { presentationType, thumbnailCount, offsetMilliseconds, paths } =
            options;

        /**
         *
         * @access public
         * @type {String<SDK.Services.Media.ThumbnailPresentationType>}
         * @note it is entirely possible that the service returns a String that isn't in our enum
         * @desc the presentation type
         *
         */
        this.presentationType = presentationType;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The number of thumbnails available for this presentation
         *
         */
        this.thumbnailCount = thumbnailCount;

        /**
         *
         * @access public
         * @type {Number}
         * @desc The milliseconds offset in the media file for the presentation which these thumbnails represent
         *
         */
        this.offsetMilliseconds = offsetMilliseconds;

        /**
         *
         * @access public
         * @type {Array<String>}
         * @desc List of ordered urls to the spritesheets themselves
         *
         */
        this.paths = paths;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Media.Presentation';
    }

    // #endregion
}
