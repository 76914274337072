/**
 *
 * @module entry
 * @desc {SDK.Commerce.Mercado} entry point.
 *
 */

import MercadoApi from './mercadoApi';

/**
 *
 * @typedef {Object} SDK.Commerce.Mercado
 *
 * @property {MercadoApi} MercadoApi
 *
 */
export default {
    MercadoApi
};
