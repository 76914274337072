/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import { OrderStatus } from './enums';

/**
 *
 * @typedef {Object} SDK.Services.Commerce.CreatePaymentMethodResponse
 * @since 9.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 *
 */
export type CreatePaymentMethodResponse = {
    paymentMethodId: string;
};

/**
 *
 * @access private
 *
 */
export const CreatePaymentMethodResponseTypedef = {
    paymentMethodId: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.OrderResponse
 * @since 9.0.0
 * @property {String} guid - Unique identifier for the order.
 *
 */
export type OrderResponse = {
    guid: string;
};

/**
 *
 * @access private
 *
 */
export const OrderResponseTypedef = {
    guid: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.PaymentRedirectResponse
 * @since 9.0.0
 * @property {String} redirectUrl - RedirectUrl needed to complete the payment.
 * @property {String} paymentMethodId - The identifier for the payment method.
 *
 */
export type PaymentRedirectResponse = {
    redirectUrl: string;
    paymentMethodId: string;
};

/**
 *
 * @access private
 *
 */
export const PaymentRedirectResponseTypedef = {
    redirectUrl: Types.nonEmptyString,
    paymentMethodId: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.RedeemResponse
 * @since 9.0.0
 * @property {String} campaignCode - Required to calculate price of the order.
 * @property {String} voucherCode - Required to calculate price of the order.
 * @property {String} subscriptionEndDate - The date which the subscription is valid until. Should conform to ISO-8601.
 *
 */
export type RedeemResponse = {
    campaignCode: string;
    voucherCode: string;
    subscriptionEndDate: string;
};

/**
 *
 * @access private
 *
 */
export const RedeemResponseTypedef = {
    campaignCode: Types.nonEmptyString,
    voucherCode: Types.nonEmptyString,
    subscriptionEndDate: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.StrongCustomerAuthRequirements
 * @since 9.0.0
 * @property {String} sessionId
 * @property {String} challengeToken
 *
 */
export type StrongCustomerAuthRequirements = {
    sessionId: string;
    challengeToken: string;
};

/**
 *
 * @access private
 *
 */
export const StrongCustomerAuthRequirementsTypedef = {
    sessionId: Types.nonEmptyString,
    challengeToken: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.Price
 * @since 9.0.0
 * @property {Number} discountAmount
 * @property {Number} subtotalAmount
 * @property {Number} taxAmount
 * @property {Number} totalAmount
 *
 */
export type Price = {
    discountAmount: number;
    subtotalAmount: number;
    taxAmount: number;
    totalAmount: number;
};

/**
 *
 * @access private
 *
 */
export const PriceTypedef = {
    discountAmount: Types.number,
    subtotalAmount: Types.number,
    taxAmount: Types.number,
    totalAmount: Types.number
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.SetCheckoutDetailsResponse
 * @since 9.0.0
 * @property {String} token
 * @property {String} timestamp
 * @property {String} correlationId
 * @property {String} payPalAcknowledgement
 * @property {String} payPalVersion
 * @property {String} payPalBuild
 *
 */
export type SetCheckoutDetailsResponse = {
    token: string;
    timestamp: string;
    correlationId: string;
    payPalAcknowledgement: string;
    payPalVersion: string;
    payPalBuild: string;
};

/**
 *
 * @access private
 *
 */
export const SetCheckoutDetailsResponseTypedef = {
    token: Types.nonEmptyString,
    timestamp: Types.nonEmptyString,
    correlationId: Types.nonEmptyString,
    payPalAcknowledgement: Types.nonEmptyString,
    payPalVersion: Types.nonEmptyString,
    payPalBuild: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.Sku
 * @since 9.0.0
 * @property {String} name
 * @property {String} [billingType]
 * @property {String} [productDescription]
 * @property {String} currency
 * @property {String} currencyCode
 * @property {Array<String>} [categories]
 *
 */
export type Sku = {
    name: string;
    billingType?: string;
    productDescription?: string;
    currency: string;
    currencyCode: string;
    categories?: Array<string>;
};

/**
 *
 * @access private
 *
 */
export const SkuTypedef = {
    name: Types.nonEmptyString,
    billingType: Types.nonEmptyString.optional,
    productDescription: Types.nonEmptyString.optional,
    currency: Types.nonEmptyString,
    currencyCode: Types.nonEmptyString,
    categories: Types.array.of.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.OrderItem
 * @since 9.0.0
 * @property {Object<SDK.Services.Commerce.Sku>} sku
 * @property {Object<SDK.Services.Commerce.Price>} price
 * @property {Number} [subscriptionPeriod]
 * @property {String} [subscriptionTimeUnit]
 *
 */
export type OrderItem = {
    sku: Sku;
    price: Price;
    subscriptionPeriod?: number;
    subscriptionTimeUnit?: string;
};

/**
 *
 * @access private
 *
 */
export const OrderItemTypedef = {
    sku: Types.object(SkuTypedef),
    price: Types.object(PriceTypedef),
    subscriptionPeriod: Types.number.optional,
    subscriptionTimeUnit: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.PriceOrderResponse
 * @since 9.0.0
 * @property {String} [region] - Contains the region which is to be used when calling createCardPaymentMethod to lookup which createPaymentMethod endpoint to use.
 * @property {Number} orderSubTotal - Required field showing sub total amount for the whole order.
 * @property {Number} orderTaxAmount - Required field showing tax amount.
 * @property {Number} orderTotalAmount - Required field showing total amount for the whole order.
 * @property {Number} orderDiscountAmount - Required field showing discount amount for the whole order.
 * @property {Array<Object<SDK.Services.Commerce.OrderItem>>} orderItems - Info for product in the order.
 * @property {Number} [adjustmentTimeLength] - Optional field only displayed if the voucher has a time length.
 * @property {String} [adjustmentTimeUnit] - Optional field only displayed if the voucher has a time unit.
 * @property {String} campaignCode - Campaign code of the redemption code sent in the request. Otherwise campaign code sent in the request.
 * @property {String} voucherCode - Voucher code of the redemption code sent in the request. Otherwise voucher code sent in the request.
 * @property {String} [redemptionCode] - Optional field. If there is a redemptionCode in the request, the same is returned in this response.
 * @property {Boolean} [paymentRequired] - Flag for payment required info of the voucher.
 * @property {String} [offerId] - Optional field showing the offer id associated with the returning order items.
 *
 */
export type PriceOrderResponse = {
    region?: string;
    orderSubTotal: number;
    orderTaxAmount: number;
    orderTotalAmount: number;
    orderDiscountAmount: number;
    orderItems: Array<OrderItem>;
    adjustmentTimeLength?: number;
    adjustmentTimeUnit?: string;
    campaignCode: string;
    voucherCode: string;
    redemptionCode?: string;
    paymentRequired?: boolean;
    offerId?: string;
};

/**
 *
 * @access private
 *
 */
export const PriceOrderResponseTypedef = {
    region: Types.nonEmptyString.optional,
    orderSubTotal: Types.number,
    orderTaxAmount: Types.number,
    orderTotalAmount: Types.number,
    orderDiscountAmount: Types.number,
    orderItems: Types.array.of.object(OrderItemTypedef),
    adjustmentTimeLength: Types.number.optional,
    adjustmentTimeUnit: Types.nonEmptyString.optional,
    campaignCode: Types.nonEmptyString,
    voucherCode: Types.nonEmptyString,
    redemptionCode: Types.nonEmptyString.optional,
    paymentRequired: Types.boolean.optional,
    offerId: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.QueryOrderResponse
 * @since 9.0.0
 * @property {String} guid - Unique identifier for the order.
 * @property {String<SDK.Services.Commerce.OrderStatus>} orderStatus - Represents status of an order.
 * @property {Object<SDK.Services.Commerce.StrongCustomerAuthRequirements>} [strongCustomerAuth] - Represents additional information required if 3DS challenge is required.
 *
 */
export type QueryOrderResponse = {
    guid: string;
    orderStatus: keyof typeof OrderStatus;
    strongCustomerAuth?: StrongCustomerAuthRequirements;
};

/**
 *
 * @access private
 *
 */
export const QueryOrderResponseTypedef = {
    guid: Types.nonEmptyString,
    orderStatus: Types.in(OrderStatus),
    strongCustomerAuth: Types.object(StrongCustomerAuthRequirementsTypedef)
        .optional
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.ZipLocation
 * @since 9.0.0
 * @property {String} city - The city for this location.
 * @property {String} state - The state for this location.
 * @property {String} country - The country for this location.
 *
 */
export type ZipLocation = {
    city: string;
    state: string;
    country: string;
};

/**
 *
 * @access private
 *
 */
export const ZipLocationTypedef = {
    city: Types.nonEmptyString,
    state: Types.nonEmptyString,
    country: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Commerce.ClientTokenResponse
 * @since 21.0.0
 * @property {String} clientToken - Braintree client token.
 *
 */
export type ClientTokenResponse = {
    clientToken: string;
};

/**
 *
 * @access private
 *
 */
export const ClientTokenResponseTypedef = {
    clientToken: Types.nonEmptyString
};
