/**
 *
 * @module playbackTelemetryDispatcher
 * @see QoE events https://helios.eds.us-east-1.bamgrid.net/
 * @see Qoe Validation (paste a dust event into here to validate one at a time)
 *          https://hora.us-east-1.bamgrid.net/swagger/index.html#/Validation/post_validate_
 */

import { v4 as uuidv4 } from 'uuid';
import { Check, Types, typecheck } from '@dss/type-checking';

import {
    AdPodRequestedEventTypedef,
    AdPodFetchedEventTypedef,
    AdMultivariantFetchedEventTypedef,
    AdVariantFetchedEventTypedef,
    AdPlaybackStartedEventTypedef,
    AdPlaybackEndedEventTypedef,
    AudioChangedEventTypedef,
    BitrateChangedEventTypedef,
    MultivariantPlaylistFetchedEventTypedef,
    MediaSegmentFetchedEventTypedef,
    PlaybackEndedEventTypedef,
    PlaybackInitializedEventTypedef,
    PlaybackPausedEventTypedef,
    PlaybackReadyEventTypedef,
    PlaybackResumedEventTypedef,
    PlaybackSeekEndedEventTypedef,
    PlaybackSeekStartedEventTypedef,
    PlaybackStartedEventTypedef,
    PresentationTypeChangedEventTypedef,
    RebufferingEndedEventTypedef,
    RebufferingStartedEventTypedef,
    SubtitleChangedEventTypedef,
    VariantPlaylistFetchedEventTypedef,
    NonFatalPlaybackErrorEventTypedef
} from './typedefs';

import {
    AdActivity,
    AdInsertionType,
    PresentationType
} from '../services/qualityOfService/enums';

import Logger from '../logging/logger';
import MonotonicTimestampManager from '../monotonicTimestampManager';
import PlatformMetricsProvider from '../platform/platformMetricsProvider';
import MediaItem from './mediaItem';
import Playlist from './playlist';
import PlaybackEventListener from './playbackEventListener';
import PlaybackTelemetryConfiguration from './playbackTelemetryConfiguration';
import PlaybackMetricsProvider from './playbackMetricsProvider';
import PlaylistType from '../services/media/playlistType';

import ApplicationContext from '../services/qualityOfService/applicationContext';
import AudioChangedEventData from '../services/qualityOfService/audioChangedEventData';
import ErrorEventData from '../services/qualityOfService/errorEventData';
import ErrorSource from '../services/qualityOfService/errorSource';
import HeartbeatSampleType from '../services/qualityOfService/heartbeatSampleType';
import MediaSegmentFetchedEventData from '../services/qualityOfService/mediaSegmentFetchedEventData';
import PlaybackActivity from '../services/qualityOfService/playbackActivity';
import PlaybackAdEventData from '../services/qualityOfService/playbackAdEventData';
import PlaybackEventData from '../services/qualityOfService/playbackEventData';
import PlaybackHeartbeatEventData from '../services/qualityOfService/playbackHeartbeatEventData';
import PlaybackStartupEventData from '../services/qualityOfService/playbackStartupEventData';
import ProductType from '../services/qualityOfService/productType';
import StartupActivity from '../services/qualityOfService/startupActivity';
import SubtitleChangedEventData from '../services/qualityOfService/subtitleChangedEventData';

import DssWebPlayerAdapter from './playerAdapter/dssWebPlayerAdapter';

import getSafe from '../services/util/getSafe';

import TelemetryManager from '../internal/telemetry/telemetryManager';
import StreamSampleTelemetryEvent from '../internal/telemetry/streamSampleTelemetryEvent';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustCategory from '../services/internal/dust/dustCategory';
import ErrorReason from '../services/exception/errorReason';
import ExceptionReference from '../services/exception/exceptionReference';
import ServiceException from '../services/exception/serviceException';

import { MediaAnalyticsKey } from './enums';
import PlaybackContext from '../services/media/playbackContext';
//import PlayerAdapter from './playerAdapter';
import type MediaDescriptor from './mediaDescriptor';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;
const defaultZeroGuid = '00000000-0000-0000-0000-000000000000';

// https://helios.eds.us-east-1.bamgrid.net/events/playback/1.4.0
export const DataVersion = '1.4.0';

const eventDataVersionOverride = {
    // https://helios.eds.us-east-1.bamgrid.net/events/adPlayback/1.0.0
    [QualityOfServiceDustUrnReference.playbackAd]: '1.0.0'
};

export function getDataVersion(urn: string) {
    return eventDataVersionOverride[urn] || DataVersion;
}

/**
 *
 * @desc Handles stream sample and other player telemetry events
 * @since 2.0.0
 *
 */
export default class PlaybackTelemetryDispatcher extends PlaybackEventListener {
    /**
     *
     * @access private
     * @type {SDK.Media.PlaybackTelemetryConfiguration}
     *
     */
    private config: PlaybackTelemetryConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.TelemetryManager}
     *
     */
    private manager: TelemetryManager;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Platform.PlatformMetricsProvider}
     *
     */
    private platformMetricsProvider: PlatformMetricsProvider;

    /**
     *
     * @access private
     * @type {Object}
     *
     */
    private serverData: object;

    /**
     *
     * @access private
     * @type {String|null}
     *
     */
    private sessionId: Nullable<string>;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private interval: number;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private intervalId = -1;

    /**
     *
     * @access private
     * @type {Boolean}
     *
     */
    private isInitialized: boolean;

    /**
     *
     * @access private
     * @type {SDK.Media.MediaItem|null}
     *
     */
    private mediaItem: Nullable<MediaItem>;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Media.Playlist|null}
     *
     */
    private playlist: Nullable<Playlist>;

    /**
     *
     * @access private
     * @type {Boolean}
     *
     */
    private isReleased: boolean;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {Object}
     *
     */
    private extraData: TodoAny;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Number}
     * @desc Total number of video start failures (VSF).
     *
     */
    private totalVst: number;

    /**
     *
     * @access private
     * @since 15.2.1
     * @type {Number|undefined}
     * @desc The last time that a heartbeat sample was generated.
     *
     */
    private heartbeatSampleTime?: number;

    /**
     *
     * @access private
     * @since 20.0.0
     * @type {Object}
     * @desc Information on presentation.
     *
     */
    private presentationTypeCache: TodoAny;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private qoeEnabled: boolean;

    /**
     *
     * @access private
     * @since 20.1.0
     * @type {Object}
     * @desc Used to keep track of which variant URIs were last fetched
     *
     */
    private variantFetchedUris: TodoAny;

    /**
     *
     * @param {Object} options
     * @param {SDK.Media.PlaybackTelemetryConfiguration} options.playbackTelemetryConfiguration
     * @param {SDK.Internal.Telemetry.TelemetryManager} options.telemetryManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Platform.PlatformMetricsProvider} options.platformMetricsProvider
     *
     */
    public constructor(options: {
        playbackTelemetryConfiguration: PlaybackTelemetryConfiguration;
        telemetryManager: TelemetryManager;
        logger: Logger;
        platformMetricsProvider: PlatformMetricsProvider;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackTelemetryConfiguration: Types.instanceStrict(
                        PlaybackTelemetryConfiguration
                    ),
                    telemetryManager: Types.instanceStrict(TelemetryManager),
                    logger: Types.instanceStrict(Logger),
                    platformMetricsProvider: Types.instanceStrict(
                        PlatformMetricsProvider
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            playbackTelemetryConfiguration,
            telemetryManager,
            logger,
            platformMetricsProvider
        } = options;

        this.config = playbackTelemetryConfiguration;
        this.manager = telemetryManager;
        this.logger = logger;
        this.platformMetricsProvider = platformMetricsProvider;
        this.serverData = {};
        this.sessionId = null;
        this.interval = this.config.streamSampleInterval;
        this.intervalId = -1;
        this.isInitialized = false;
        this.mediaItem = null;
        this.playlist = null;
        this.isReleased = false;
        this.extraData = {};
        this.totalVst = 0;
        this.heartbeatSampleTime = undefined;
        this.presentationTypeCache = {
            presentationType: PresentationType.main
        };
        this.qoeEnabled = !getSafe(() => this.manager.qoeBuffer.disabled);
        this.variantFetchedUris = {};

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Media.PlaybackMetricsProvider} options.playbackMetricsProvider
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Media.Playlist} options.playlist
     * @param {Object} [options.extraData={}]
     * @desc Initializes the dispatcher with a provider and a mediaItem.
     *
     */
    public init(options: {
        playbackMetricsProvider: PlaybackMetricsProvider;
        mediaItem: MediaItem;
        playlist: Playlist;
        extraData?: object;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackMetricsProvider: Types.instanceStrict(
                        PlaybackMetricsProvider
                    ),
                    mediaItem: Types.instanceStrict(MediaItem),
                    playlist: Types.instanceStrict(Playlist),
                    extraData: Types.object().optional
                })
            };

            typecheck(this, 'init', params, arguments);
        }

        const {
            playbackMetricsProvider,
            mediaItem,
            playlist,
            extraData = {}
        } = options;

        const logger = this.logger;
        const isReleased = this.isReleased;

        const telemetryTrackingData = playlist.getTrackingData(
            MediaAnalyticsKey.telemetry
        );

        if (Check.emptyObject(telemetryTrackingData)) {
            return;
        }

        if (isReleased) {
            const reasons = [
                new ErrorReason(
                    '',
                    'The dispatcher has been released and cannot be reused.'
                )
            ];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }

        try {
            playbackMetricsProvider.addListener(this);

            this.serverData = telemetryTrackingData;
            this.extraData = extraData;

            this.sessionId = getSafe(
                () => mediaItem.playbackContext?.playbackSessionId,
                uuidv4()
            );

            this.isInitialized = true;
            this.playlist = playlist;
            this.mediaItem = mediaItem;

            logger.info(this.toString(), 'Initialized.');
        } catch (ex) {
            logger.error(this.toString(), ex);
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackMetricsProvider} playbackMetricsProvider
     * @desc Cleans up the dispatcher and prevents further use.
     * @returns {Promise<Void>}
     *
     */
    public async release(playbackMetricsProvider: PlaybackMetricsProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackMetricsProvider: Types.instanceStrict(
                    PlaybackMetricsProvider
                )
            };

            typecheck(this, 'release', params, arguments);
        }

        const logger = this.logger;

        if (this.isReleased) {
            logger.warn(this.toString(), 'Already released.');

            return;
        }

        try {
            playbackMetricsProvider.removeListener(this);

            await this.manager.qoeBuffer.drain();

            this.clearStreamInterval();

            this.isInitialized = false;
            this.isReleased = true;
            this.mediaItem = null;
            this.playlist = null;
            this.presentationTypeCache = null;

            logger.warn(this.toString(), 'Released.');
        } catch (ex) {
            logger.error(this.toString(), ex);

            throw ex;
        }
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackStartedEvent>} args
     *
     */
    public override onPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackStartedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.started,
                args,
                provider
            )
        );

        this.startRecordingSamples(provider);
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackPausedEvent>} args
     *
     */
    public override onPlaybackPaused(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackPausedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackPaused', params, arguments);
        }

        const sampleType = HeartbeatSampleType.state;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.paused,
                args,
                provider
            )
        );

        this.clearStreamInterval();
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackResumedEvent>} args
     *
     */
    public override onPlaybackResumed(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackResumedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackResumed', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.resumed,
                args,
                provider
            )
        );

        this.startRecordingSamples(provider);
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingStartedEvent>} args
     *
     */
    public override onRebufferingStarted(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(RebufferingStartedEventTypedef)
            };

            typecheck.warn(this, 'onRebufferingStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.rebufferingStarted,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingEndedEvent>} args
     *
     */
    public override onRebufferingEnded(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(RebufferingEndedEventTypedef)
            };

            typecheck.warn(this, 'onRebufferingEnded', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.rebufferingEnded,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackEndedEvent>} args
     *
     */
    public override onPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackEndedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackEnded', params, arguments);
        }

        const sampleType = HeartbeatSampleType.state;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(PlaybackActivity.ended, args, provider)
        );

        this.clearStreamInterval();

        this.heartbeatSampleTime = undefined;
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackInitializedEvent>} args
     *
     */
    public override onPlaybackInitialized(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackInitializedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackInitialized', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.initialized,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackReadyEvent>} args
     *
     */
    public override onPlaybackReady(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackReadyEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackReady', params, arguments);
        }

        const { playbackRequestedAtTimestamp } =
            this.mediaItem?.playbackContext || {};

        if (Check.assigned(playbackRequestedAtTimestamp)) {
            // @ts-expect-error playbackRequestedAtTimestamp cannot be undefined
            // (typescript just doesn't understand the check above)
            this.totalVst = Date.now() - playbackRequestedAtTimestamp;
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.ready,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekStartedEvent>} args
     *
     */
    public override onPlaybackSeekStarted(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackSeekStartedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackSeekStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.seekStarted,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekEndedEvent>} args
     *
     */
    public override onPlaybackSeekEnded(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackSeekEndedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackSeekEnded', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.seekEnded,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AudioChangedEvent>} args
     *
     */
    public override onAudioChanged(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AudioChangedEventTypedef)
            };

            typecheck.warn(this, 'onAudioChanged', params, arguments);
        }

        const sampleType = HeartbeatSampleType.user;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.audioChanged,
            new AudioChangedEventData(args)
        );
    }

    public override onAudioBitrateChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ) {
        // no-op
    }

    public override onSnapshotCreated(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: TodoAny
    ) {
        // no-op
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.SubtitleChangedEvent>} args
     *
     */
    public override onSubtitleChanged(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(SubtitleChangedEventTypedef)
            };

            typecheck.warn(this, 'onSubtitleChanged', params, arguments);
        }

        const sampleType = HeartbeatSampleType.user;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.subtitleChanged,
            new SubtitleChangedEventData(args)
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.BitrateChangedEvent>} args
     *
     */
    public override onBitrateChanged(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(BitrateChangedEventTypedef)
            };

            typecheck.warn(this, 'onBitrateChanged', params, arguments);
        }

        const { bitratePeak, bitrateAvg } = args;

        const options = {
            ...args,
            videoBitrate: bitratePeak,
            videoAverageBitrate: bitrateAvg
        };

        const sampleType = HeartbeatSampleType.responsive;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.bitrateChanged,
                options,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MultivariantPlaylistFetchedEvent>} args
     *
     */
    public override onMultivariantPlaylistFetched(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(MultivariantPlaylistFetchedEventTypedef)
            };

            typecheck.warn(
                this,
                'onMultivariantPlaylistFetched',
                params,
                arguments
            );
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.masterFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} args
     *
     */
    public override onVariantPlaylistFetched(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(VariantPlaylistFetchedEventTypedef)
            };

            typecheck.warn(this, 'onVariantPlaylistFetched', params, arguments);
        }

        if (this.shouldReportVariantFetched(args)) {
            this.logQoeEvent(
                QualityOfServiceDustUrnReference.playbackStartup,
                this.createPlaybackStartupEventData(
                    StartupActivity.variantFetched,
                    args,
                    provider
                )
            );
        }
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {Object<SDK.Media.MediaSegmentFetchedEvent>} args
     *
     */
    public override onMediaSegmentFetched(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(MediaSegmentFetchedEventTypedef)
            };

            typecheck.warn(this, 'onMediaSegmentFetched', params, arguments);
        }

        const { serverRequest } = args;

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.mediaSegmentFetched,
            new MediaSegmentFetchedEventData({ serverRequest })
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackHeartbeatEventData} args
     *
     */
    public onPlaybackSampled(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.instanceStrict(PlaybackHeartbeatEventData)
            };

            typecheck.warn(this, 'onPlaybackSampled', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackHeartbeat,
            args
        );
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onDrmKeyFetched(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object()
            };

            typecheck.warn(this, 'onDrmKeyFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.drmKeyFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.PlaybackStartupEventData} args
     * @note Due to how reattempts are tracked, properties that are set to
     * undefined need to be removed to prevent data being overwritten with undefined.
     *
     */
    public override onPlaybackReattempt(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.instanceStrict(PlaybackStartupEventData)
            };

            typecheck.warn(this, 'onPlaybackReattempt', params, arguments);
        }

        const reattemptData: TodoAny = {};

        Object.keys(args).forEach((key) => {
            if (args[key] !== undefined) {
                reattemptData[key] = args[key];
            }
        });

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.reattempt,
                reattemptData,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 7.0.0
     * @param {Object} args
     *
     */
    public override onSuccessfulPlaylistLoad(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object()
            };

            typecheck.warn(this, 'onSuccessfulPlaylistLoad', params, arguments);
        }

        const tracking = args.tracking;

        if (this.mediaItem) {
            this.mediaItem.priorityTracking = args.priority;
        }

        if (tracking) {
            const telemetryData = tracking.telemetry;

            if (telemetryData) {
                const trackingInfo = this.playlist?.getTrackingInfo(
                    MediaAnalyticsKey.telemetry
                );

                this.serverData = Object.assign(
                    {},
                    trackingInfo,
                    telemetryData
                );
            }
        }
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public onPlaybackError(provider: PlaybackMetricsProvider, args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object()
            };

            typecheck.warn(this, 'onPlaybackError', params, arguments);
        }

        const { analyticsProvider } = this.logger;
        const { playbackContext, payload, descriptor } = this.mediaItem || {};
        const { productType, contentKeys } = playbackContext || {};
        const {
            isFatal,
            adSlotData: adSlot,
            adPodData: adPod,
            adPodPlacement: adPlacement
        } = args;

        // HACK - Ignoring non-fatal errors to un-block a go-live of QoE due to too many events flowing through the system - We'll have to solve this differently (possibly using sampling/rate-limiting in the future) but ignore for now RE: SDKMRJS-4916
        if (!isFatal) {
            return;
        }

        const adInsertionType =
            AdInsertionType[
                (
                    descriptor as MediaDescriptor
                ).assetInsertionStrategy.toLowerCase() as keyof typeof AdInsertionType
            ];
        const presentationType = this.presentationTypeCache.presentationType;
        const applicationContext =
            presentationType === PresentationType.ad
                ? ApplicationContext.ad
                : ApplicationContext.player;
        const adsQos = payload?.stream.adsQos;

        const playbackMetrics = provider.getPlaybackMetrics();
        const { currentBitrate, currentBitrateAvg } = playbackMetrics;

        let dictionaryVersion;
        let adSessionId;
        let subscriptionType;
        let adSlotData;
        let adPodData;
        let adPodPlacement;

        if (analyticsProvider) {
            dictionaryVersion = analyticsProvider.getDictionaryVersion();
        }

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = getSafe(() => adsQos.adSession.id, defaultZeroGuid);
            subscriptionType = getSafe(() => adsQos.subscriptionType);
        }

        if (applicationContext === ApplicationContext.ad) {
            adSlotData = adSlot;
            adPodData = adPod;
            adPodPlacement = adPlacement;
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.error,
            new ErrorEventData({
                applicationContext,
                isFatal,
                source: ErrorSource.player,
                productType,
                videoBitrate: currentBitrate,
                videoAverageBitrate: currentBitrateAvg,
                audioBitrate: provider.getAudioBitrate(),
                maxAllowedVideoBitrate: provider.getMaxAllowedVideoBitrate(),
                cdnName: provider.getCdnName(),
                dictionaryVersion,
                contentKeys,
                presentationType,
                adInsertionType,
                adSessionId,
                subscriptionType,
                ...args,
                adSlotData,
                adPodPlacement,
                adPodData
            })
        );
    }

    /**
     *
     * @access public
     * @since 20.0.2
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.NonFatalPlaybackErrorEventTypedef>} args
     *
     */
    public onAdBeaconError(provider: PlaybackMetricsProvider, args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(NonFatalPlaybackErrorEventTypedef)
            };

            typecheck.warn(this, 'onAdBeaconError', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.error,
            this.createAdErrorEventData(provider, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.2
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.NonFatalPlaybackErrorEventTypedef>} args
     *
     */
    public onAdRequestedError(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(NonFatalPlaybackErrorEventTypedef)
            };

            typecheck.warn(this, 'onAdRequestedError', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.error,
            this.createAdErrorEventData(provider, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public override onAdPodRequested(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(AdPodRequestedEventTypedef)
            };

            typecheck.warn(this, 'onAdPodRequested', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(AdActivity.adPodRequested, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public override onAdPodFetched(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(AdPodFetchedEventTypedef)
            };

            typecheck.warn(this, 'onAdPodFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(AdActivity.adPodFetched, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public override onAdMultivariantFetched(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(AdMultivariantFetchedEventTypedef)
            };

            typecheck.warn(this, 'onAdMultivariantFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adMultivariantFetched,
                args
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public override onAdVariantFetched(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(AdVariantFetchedEventTypedef)
            };

            typecheck.warn(this, 'onAdVariantFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(AdActivity.adVariantFetched, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public override onAdPlaybackStarted(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(AdPlaybackStartedEventTypedef)
            };

            typecheck.warn(this, 'onAdPlaybackStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(AdActivity.adPlaybackStarted, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public override onAdPlaybackEnded(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.object(AdPlaybackEndedEventTypedef)
            };

            typecheck.warn(this, 'onAdPlaybackEnded', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(AdActivity.adPlaybackEnded, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onPresentationTypeChanged(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PresentationTypeChangedEventTypedef)
            };

            typecheck.warn(
                this,
                'onPresentationTypeChanged',
                params,
                arguments
            );
        }

        this.presentationTypeCache = args;

        const sampleType = HeartbeatSampleType.responsive;
        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);
    }

    // #region private

    /**
     *
     * @access private
     * @param {SDK.Media.PlaybackMetricsProvider} playbackMetricsProvider
     * @returns {Promise<Void>}
     *
     */
    private startRecordingSamples(
        playbackMetricsProvider: PlaybackMetricsProvider
    ) {
        return this.timedRecordStreamSample(playbackMetricsProvider);
    }

    /**
     *
     * @access private
     * @param {SDK.Media.PlaybackMetricsProvider} playbackMetricsProvider
     * @returns {Promise<Void>}
     *
     */
    private timedRecordStreamSample(
        playbackMetricsProvider: PlaybackMetricsProvider
    ) {
        const { isInitialized, interval } = this;

        if (!isInitialized) {
            return Promise.resolve();
        }

        this.clearStreamInterval();

        return this.recordStreamSample(playbackMetricsProvider).then(() => {
            this.intervalId = setTimeout(() => {
                this.timedRecordStreamSample(playbackMetricsProvider);
            }, interval * 1000) as unknown as number;
        });
    }

    /**
     *
     * @access public
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Boolean} [isManual=false]
     * @returns {Promise<Void>}
     *
     */
    public async recordStreamSample(
        provider: PlaybackMetricsProvider,
        isManual = false
    ) {
        if (!this.isInitialized) {
            return;
        }

        const { serverData, mediaItem, extraData } = this;

        const metrics = provider.getPlaybackMetrics();

        const playbackSessionId = this.sessionId;
        const { playbackContext } = mediaItem || {};
        const { currentPlayhead, currentBitrate } = metrics;
        const { interactionId } = playbackContext || {};

        const playhead = Check.number(currentPlayhead)
            ? Math.floor(currentPlayhead / 1000)
            : null;

        const streamSampleTelemetryEvent = new StreamSampleTelemetryEvent({
            playbackSessionId: playbackSessionId as string,
            playhead: playhead as number,
            bitrate: currentBitrate as number,
            serverData,
            interactionId,
            extraData
        });

        if (!isManual) {
            const sampleType = HeartbeatSampleType.periodic;

            const playbackHeartbeatEventData =
                this.createPlaybackHeartbeatEventData(provider, sampleType);

            this.onPlaybackSampled(playbackHeartbeatEventData);
        }

        this.manager.streamSampleBuffer.postEvent(streamSampleTelemetryEvent);
    }

    /**
     *
     * @access private
     * @desc Clears the stream interval for sending telemetry events
     *
     */
    private clearStreamInterval() {
        clearTimeout(this.intervalId);
        this.intervalId = -1;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Gets the qos decision object based on the current playlist type.
     *
     */
    private getQosDecision(qosDecisions: TodoAny) {
        if (this.playlist?.playlistType === PlaylistType.SLIDE) {
            return qosDecisions.slide;
        }

        return qosDecisions.complete;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {String<SDK.Services.QualityOfService.PlaybackActivity>} playbackActivity
     * @param {Object} eventData
     * @param {PlaybackMetricsProvider} provider
     * @desc returns a PlaybackEventData instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackEventData}
     *
     */
    private createPlaybackEventData(
        playbackActivity: keyof typeof PlaybackActivity,
        eventData: TodoAny,
        provider: PlaybackMetricsProvider
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const { playbackContext, descriptor, payload } = this
            .mediaItem as MediaItem;
        const { contentKeys = {}, productType } = playbackContext || {};

        const streamUrl = provider.currentStreamUrl || provider.playlistUri;
        const presentationType = this.presentationTypeCache.presentationType;
        const adInsertionType =
            AdInsertionType[
                descriptor.assetInsertionStrategy.toLowerCase() as keyof typeof AdInsertionType
            ];
        const adsQos = payload.stream.adsQos;
        const metrics = provider.getPlaybackMetrics();
        const monotonicTimestamp = MonotonicTimestampManager.getTimestamp();

        let adSessionId;
        let subscriptionType;
        let adPodPlacement;
        let adPodData;
        let adSlotData;
        let adPlayheadPosition;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = getSafe(() => adsQos.adSession.id, defaultZeroGuid);
            subscriptionType = getSafe(() => adsQos.subscriptionType);
        }

        if (presentationType === PresentationType.ad && metrics.adMetadata) {
            adPodPlacement = metrics.adMetadata.adPodPlacement;
            adPodData = metrics.adMetadata.adPodData;
            adSlotData = metrics.adMetadata.adSlotData;
            adPlayheadPosition = metrics.adMetadata.adPlayheadPosition;
        }

        return new PlaybackEventData({
            maxAllowedVideoBitrate: provider.getMaxAllowedVideoBitrate(),
            playbackActivity,
            productType,
            streamUrl,
            cdnName: Check.function(provider.getCdnName)
                ? provider.getCdnName()
                : 'null',
            contentKeys,
            presentationType,
            adInsertionType,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            adPlayheadPosition,
            monotonicTimestamp,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.1
     * @param {SDK.Services.QualityOfService.AdInsertionType} adInsertionType
     * @desc Helper to determine if adsQos exists and adInsertionType is `none`
     * @returns {Boolean}
     *
     */
    private canUseQosInfo(adInsertionType: ValueOf<typeof AdInsertionType>) {
        return adInsertionType !== AdInsertionType.none;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {String<SDK.Services.QualityOfService.StartupActivity>} startupActivity
     * @param {Object} eventData
     * @param {PlayerAdapter} provider
     * @desc Returns a `PlaybackStartupEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackStartupEventData|undefined}
     *
     */
    private createPlaybackStartupEventData(
        startupActivity: TodoAny,
        eventData: TodoAny,
        provider: PlaybackMetricsProvider
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const mediaItem = this.mediaItem as MediaItem;
        const { playbackContext, payload, descriptor } = mediaItem;
        const { videoPlayerName, videoPlayerVersion } = provider;

        const { attributes } = payload.stream;

        const {
            contentKeys = {},
            productType,
            isPreBuffering: mediaPreBuffer,
            offline: localMedia,
            startupContext
        } = playbackContext || {};

        const cpuPercentage =
            this.platformMetricsProvider.availableCpuPercentage();
        const freeMemory = this.platformMetricsProvider.availableMemoryMb();
        const playbackIntent = Check.assigned(playbackContext)
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              playbackContext!.playbackIntent
            : null;
        const streamVariants = mediaItem.getVariants();
        const adInsertionType =
            AdInsertionType[
                descriptor.assetInsertionStrategy.toLowerCase() as keyof typeof AdInsertionType
            ];
        const adsQos = payload.stream.adsQos;
        const insertion = payload.stream.insertion;
        const monotonicTimestamp = MonotonicTimestampManager.getTimestamp();

        let streamUrl = eventData.streamUrl;

        if (!streamUrl) {
            streamUrl = provider.currentStreamUrl || provider.playlistUri;
        }

        let adSessionId;
        let subscriptionType;
        let totalPodCount = 0;
        let totalSlotCount: number | undefined;
        let totalAdLength = 0;
        let createAdSessionResponseCode;
        let getPodsResponseCode;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = getSafe(() => adsQos.adSession.id, defaultZeroGuid);
            subscriptionType = getSafe(() => adsQos.subscriptionType);
            createAdSessionResponseCode = getSafe(
                () => adsQos.adSession.responseCode
            );

            if (adInsertionType === AdInsertionType.ssai) {
                getPodsResponseCode = getSafe(
                    () => adsQos.getPods.responseCode
                );
            }
        }

        if (insertion && insertion.points) {
            totalPodCount = insertion.points.length;

            if (adInsertionType === AdInsertionType.ssai) {
                insertion.points.forEach((insertionPoint: TodoAny) => {
                    const { plannedSlotCount, duration } = insertionPoint;

                    totalSlotCount = totalSlotCount
                        ? totalSlotCount + plannedSlotCount
                        : plannedSlotCount;
                    totalAdLength = totalAdLength
                        ? totalAdLength + duration
                        : duration;
                });
            }
        }

        return new PlaybackStartupEventData({
            startupActivity,
            productType,
            playbackIntent,
            mediaPreBuffer,
            streamUrl,
            videoPlayerName,
            videoPlayerVersion,
            localMedia,
            cpuPercentage,
            freeMemory,
            contentKeys,
            attributes,
            streamVariants,
            // @ts-ignore TODO fix qos not on provider (it's on sub-providers)
            qos: provider.qos || {},
            startupContext,
            adInsertionType,
            subscriptionType,
            adSessionId,
            totalPodCount,
            totalSlotCount,
            totalAdLength,
            createAdSessionResponseCode,
            getPodsResponseCode,
            monotonicTimestamp,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Returns the duration in milliseconds since the last player heartbeat
     * @returns {Number}
     *
     */
    private getPlaybackDuration() {
        const newTime = Date.now();
        const originalTime = this.heartbeatSampleTime || newTime;

        this.heartbeatSampleTime = newTime;

        return newTime - originalTime;
    }

    /**
     *
     * @access private
     * @since 15.2.0
     * @param {PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.HeartbeatSampleType} sampleType
     * @desc Returns a `PlaybackHeartbeatEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackHeartbeatEventData|undefined}
     *
     */
    private createPlaybackHeartbeatEventData(
        provider: PlaybackMetricsProvider,
        sampleType: ValueOf<typeof HeartbeatSampleType>
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const { platformMetricsProvider, totalVst } = this;
        const mediaItem = this.mediaItem as MediaItem;

        const cpuPercentage = platformMetricsProvider.availableCpuPercentage();
        const freeMemory = platformMetricsProvider.availableMemoryMb();
        const metrics = provider.getPlaybackMetrics();

        const { playbackContext } = mediaItem || {};
        const {
            currentPlayhead,
            currentBitratePeak,
            currentBitrateAvg,
            adMetadata
        } = metrics;
        const { productType, artificialDelay, contentKeys } =
            playbackContext || {};
        const presentationType = this.presentationTypeCache.presentationType;
        const adInsertionType =
            AdInsertionType[
                mediaItem.descriptor.assetInsertionStrategy.toLowerCase() as keyof typeof AdInsertionType
            ];
        const adsQos = mediaItem.payload.stream.adsQos;

        let adSessionId;
        let subscriptionType;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = getSafe(() => adsQos.adSession.id, defaultZeroGuid);
            subscriptionType = getSafe(() => adsQos.subscriptionType);
        }

        const playbackDuration = this.getPlaybackDuration();

        let playbackHeartbeatEventData;
        let adPodPlacement;
        let adSlotData;
        let adPodData;
        let adPlayheadPosition;

        if (presentationType === PresentationType.ad) {
            ({ adPodPlacement, adPodData, adSlotData, adPlayheadPosition } =
                adMetadata || {});
        }

        const heartbeatData = provider.getHeartbeatData();

        if (Check.instanceStrict(provider, DssWebPlayerAdapter)) {
            playbackHeartbeatEventData = new PlaybackHeartbeatEventData({
                ...metrics,
                ...heartbeatData,
                productType,
                playbackDuration,
                cdnName: provider.getCdnName(),
                cpuPercentage,
                freeMemory,
                artificialDelay,
                contentKeys,
                sampleType,
                presentationType,
                adInsertionType,
                adSessionId,
                subscriptionType,
                adPodPlacement,
                adPodData,
                adSlotData,
                adPlayheadPosition
            });
        } else {
            playbackHeartbeatEventData = new PlaybackHeartbeatEventData({
                ...metrics,
                ...heartbeatData,
                productType,
                playbackDuration,
                totalVst,
                playheadPosition: currentPlayhead,
                videoBitrate: currentBitratePeak,
                videoAverageBitrate: currentBitrateAvg,
                audioBitrate: provider.getAudioBitrate(),
                maxAllowedVideoBitrate: provider.getMaxAllowedVideoBitrate(),
                cdnName: provider.getCdnName(),
                cpuPercentage,
                freeMemory,
                artificialDelay,
                contentKeys,
                sampleType,
                //networkType,
                mediaDownloadTotalCount: (provider as TodoAny)
                    .mediaDownloadTotalCount,
                mediaDownloadTotalTime: (provider as TodoAny)
                    .mediaDownloadTotalTime,
                presentationType,
                adInsertionType,
                adSessionId,
                subscriptionType,
                adPodPlacement,
                adPodData,
                adSlotData,
                adPlayheadPosition
            });

            (provider as TodoAny).mediaDownloadTotalCount = 0;
            (provider as TodoAny).mediaDownloadTotalTime = 0;
        }

        return playbackHeartbeatEventData;
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {String<SDK.Services.QualityOfService.AdActivity>} adActivity
     * @param {Object} eventData
     * @desc Returns a `PlaybackAdEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackAdEventData|undefined}
     *
     */
    private createPlaybackAdEventData(
        adActivity: ValueOf<typeof AdActivity>,
        eventData: TodoAny
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const { playbackContext, payload, descriptor } = this
            .mediaItem as MediaItem;

        const monotonicTimestamp = MonotonicTimestampManager.getTimestamp();
        const adsQos = payload.stream.adsQos;
        const adInsertionType =
            AdInsertionType[
                descriptor.assetInsertionStrategy.toLowerCase() as keyof typeof AdInsertionType
            ];

        const { contentKeys = {}, productType } = playbackContext || {};

        let adStartupData;
        let networkType;

        if (eventData.serverRequest) {
            let startTimestamp = monotonicTimestamp;

            if (adActivity !== AdActivity.adPodFetched) {
                startTimestamp =
                    monotonicTimestamp - eventData.serverRequest.roundTripTime;
            }

            adStartupData = {
                startTimestamp: startTimestamp || 0,
                requestDuration: eventData.serverRequest.roundTripTime || 0
            };

            networkType = eventData.serverRequest.networkType;
        }

        let adSessionId;
        let subscriptionType;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = getSafe(() => adsQos.adSession.id, defaultZeroGuid);
            subscriptionType = getSafe(() => adsQos.subscriptionType);
        }

        return new PlaybackAdEventData({
            adActivity,
            adSessionId,
            adInsertionType,
            subscriptionType,
            productType,
            contentKeys,
            networkType,
            monotonicTimestamp,
            adStartupData,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} eventData
     * @desc Returns a `PlaybackAdEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.ErrorEventData|undefined}
     *
     */
    private createAdErrorEventData(
        provider: PlaybackMetricsProvider,
        eventData: TodoAny
    ) {
        const {
            error: errorName,
            errorLevel,
            applicationContext = ApplicationContext.ad,
            errorDetail: errorMessage
        } = eventData;

        const mediaItem = this.mediaItem as MediaItem;

        const { subscriptionType, adSession } = getSafe(
            () => mediaItem.payload.stream.adsQos,
            {}
        );

        const { assetInsertionStrategy } = getSafe(
            () => mediaItem.descriptor,
            {} as TodoAny
        );

        const { productType } = getSafe(
            () => mediaItem.playbackContext,
            {} as TodoAny
        );

        const adSessionId = getSafe(() => adSession.id, defaultZeroGuid);

        const { adMetadata } = provider.getPlaybackMetrics();

        const adInsertionType =
            assetInsertionStrategy &&
            AdInsertionType[
                assetInsertionStrategy.toLowerCase() as keyof typeof AdInsertionType
            ];

        return new ErrorEventData({
            ...adMetadata,
            adInsertionType,
            adSessionId,
            applicationContext,
            errorLevel,
            errorMessage,
            errorName,
            isFatal: false,
            presentationType: this.presentationTypeCache.presentationType,
            subscriptionType,
            productType,
            source: ErrorSource.player
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Services.Media.PlaybackContext} playbackContext
     * @returns {Object}
     *
     */
    private getCommonProperties(playbackContext?: PlaybackContext) {
        const { analyticsProvider } = this.logger;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let data = Check.assigned(playbackContext) ? playbackContext!.data : {};

        if (analyticsProvider) {
            const commonProperties = analyticsProvider.getCommonProperties();

            data = {
                ...data,
                ...commonProperties
            };
        }

        return data;
    }

    /**
     *
     * @access private
     * @param {String} urn
     * @param {Object} eventData
     *
     */
    private logQoeEvent(urn: string, eventData: TodoAny) {
        if (!this.isInitialized) {
            return;
        }

        const { logger, qoeEnabled } = this;

        const mediaItem = this.mediaItem as MediaItem;

        const commonData = this.getCommonProperties(mediaItem.playbackContext);

        const playbackSessionId = getSafe(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            () => mediaItem.playbackContext!.playbackSessionId
        );
        const { qosDecisions = {} } = getSafe(() => mediaItem.payload.stream);
        const qosDecision = this.getQosDecision(qosDecisions) || {};
        const { clientDecisions = {}, serverDecisions = {} } = qosDecision;

        if (qoeEnabled && Check.nonEmptyString(playbackSessionId)) {
            const dataVersion = getDataVersion(urn);
            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn,
                data: {
                    ...eventData,
                    ...commonData,
                    playbackSessionId,
                    clientGroupIds: clientDecisions.clientGroupIds,
                    serverGroupIds: serverDecisions.serverGroupIds
                },
                skipLogTransaction: true,
                dataVersion
            });

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     * @since 20.1.0
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} args
     * @desc Determines whether conditions allow for this variantFetched event to be reported.
     * @note In order to reduce the number of variantFetched events sent during live playback, the following filtering
     * rules are implemented:
     * 1. Errors are reported without filtering during VOD playback.
     * 2. During live playback, one error event is reported for each variant manifest that fails to load, even if the
     *    given variant fails repeatedly. This state resets when the same variant is fetched successfully at least once.
     * 3. During VOD playback, an event is generated every time a variant m3u8 is loaded successfully.
     * 4. During live playback of linear or event streams, the player reloads the active video, audio, and subtitle
     *    variants frequently. Successful loading events should only be reported during startup, and when a new variant
     *    is loaded due to ABR logic.
     * @returns {Boolean}
     *
     */
    private shouldReportVariantFetched(args: TodoAny) {
        let shouldReport = true;

        const { variantFetchedUris, mediaItem } = this;
        const { playbackContext } = mediaItem || {};
        const { productType } = playbackContext || {};

        // filtering should only apply to Live events
        if (productType === ProductType.live) {
            const { details: { type = undefined, url = undefined } = {} } =
                args || {};

            if (Check.assigned(type) && Check.assigned(url)) {
                const lowerType = type.toLowerCase();

                // report variantFetched if the variant URI has changed
                shouldReport = variantFetchedUris[lowerType] !== url;

                // keep track of the url for this variant type
                variantFetchedUris[lowerType] = url;
            }
        }

        return shouldReport;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlaybackTelemetryDispatcher';
    }

    // #endregion
}
