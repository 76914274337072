/**
 *
 * @module entry
 * @desc {SDK.Services.Subscription} entry point.
 *
 */

import SubscriptionStatus from './subscriptionStatus';
import SubscriptionClient from './subscriptionClient';
import SubscriptionOffer from './subscriptionOffer';
import SubscriptionFreeTrial from './subscriptionFreeTrial';
import SubscriptionCancellation from './subscriptionCancellation';
import ProductRule from './productRule';

import {
    SubscriptionExpiryType,
    SubscriptionSourceSubType,
    SubscriptionSourceType,
    SubscriptionStatusSubType,
    SubscriptionStatusType,
    SubscriptionType,
    SubscriptionFreeTrialStatus,
    SubscriptionCancellationReason,
    SubscriptionCancellationStatus,
    BundleType
} from './enums';

/**
 *
 * @typedef {Object} SDK.Services.Subscription
 *
 * @property {SubscriptionStatus} SubscriptionStatus
 * @property {SubscriptionClient} SubscriptionClient
 * @property {SDK.Services.Subscription.SubscriptionExpiryType} SubscriptionExpiryType
 * @property {SDK.Services.Subscription.SubscriptionSourceSubType} SubscriptionSourceSubType
 * @property {SDK.Services.Subscription.SubscriptionSourceType} SubscriptionSourceType
 * @property {SDK.Services.Subscription.SubscriptionStatusSubType} SubscriptionStatusSubType
 * @property {SDK.Services.Subscription.SubscriptionStatusType} SubscriptionStatusType
 * @property {SubscriptionOffer} SubscriptionOffer
 * @property {SubscriptionFreeTrial} SubscriptionFreeTrial
 * @property {SubscriptionFreeTrialStatus} SubscriptionFreeTrialStatus
 * @property {SubscriptionCancellation} SubscriptionCancellation
 * @property {subscriptionCancellationReason} SubscriptionCancellationReason
 * @property {SubscriptionCancellationStatus} SubscriptionCancellationStatus
 * @property {SDK.Services.Subscription.SubscriptionType} SubscriptionType
 * @property {ProductRule} ProductRule
 * @property {SDK.Services.Subscription.BundleType} BundleType
 *
 */
export default {
    SubscriptionStatus,
    SubscriptionClient,
    SubscriptionExpiryType,
    SubscriptionSourceSubType,
    SubscriptionSourceType,
    SubscriptionStatusSubType,
    SubscriptionStatusType,
    SubscriptionType,
    SubscriptionOffer,
    SubscriptionFreeTrial,
    SubscriptionFreeTrialStatus,
    SubscriptionCancellation,
    SubscriptionCancellationReason,
    SubscriptionCancellationStatus,
    ProductRule,
    BundleType
};
