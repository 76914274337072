/**
 *
 * @module getScope
 * @see https://github.com/tc39/proposal-global
 * @see https://github.com/purposeindustries/window-or-global
 *
 */

/**
 *
 * @desc Little utility that returns the global scope.
 * Works on all modern browsers, Node based environments, connected devices, etc...
 * @returns {Object}
 *
 * @example const scope = getScope();
 * @example const global = getScope();
 *
 */
export default () => {
    return (
        (typeof self === 'object' && self.self === self && self) ||
        (typeof global === 'object' && global.global === global && global) ||
        window
    );
};
