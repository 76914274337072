/**
 *
 * @module configurationManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/configuration.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import LogTransaction from '../logging/logTransaction';

import CoreHttpClientProvider from '../services/providers/shared/coreHttpClientProvider';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import PlatformProviders from '../services/providers/platformProviders';

import getSafe from '../services/util/getSafe';

import BootstrapConfiguration from '../services/configuration/bootstrapConfiguration';
import SdkSessionConfiguration from '../services/configuration/sdkSessionConfiguration';
import ConfigurationClient from '../services/configuration/configurationClient';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import PlatformProfileReference from '../services/configuration/platformProfileReference';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import ConfigurationClientConfiguration from '../services/configuration/configurationClientConfiguration';
import EnvironmentConfiguration from '../services/providers/browser/environmentConfiguration';

import EmbeddedConfiguration from './embeddedConfiguration';

const ConfigurationManagerDustUrnReference =
    DustUrnReference.configuration.configurationManager;

/**
 *
 * @access protected
 * @desc Manages configuration information required to use the SDK.
 *
 */
export default class ConfigurationManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.BootstrapConfiguration}
     *
     */
    private bootstrapConfiguration: BootstrapConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {CoreHttpClientProvider}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.EnvironmentConfiguration}
     *
     */
    public environmentConfiguration: EnvironmentConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.ConfigurationClientConfiguration}
     *
     */
    private configurationClientConfiguration: ConfigurationClientConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.ConfigurationClient}
     *
     */
    private client: ConfigurationClient;

    /**
     *
     * @access private
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     * @since 1.1.2
     *
     */
    private cacheKey: string;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.BootstrapConfiguration} options.bootstrapConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        bootstrapConfiguration: BootstrapConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        storage: CoreStorageProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bootstrapConfiguration: Types.instanceStrict(
                        BootstrapConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const { bootstrapConfiguration, logger, httpClient, storage } = options;

        this.bootstrapConfiguration = bootstrapConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.storage = storage;
        this.environmentConfiguration =
            ConfigurationManager.createEnvironmentConfiguration(
                this.bootstrapConfiguration
            );

        this.configurationClientConfiguration =
            ConfigurationManager.getClientConfiguration();

        this.client = new ConfigurationClient(
            this.configurationClientConfiguration,
            this.logger,
            this.httpClient
        );

        this.cacheKey = `
            __bam_sdk_sdkSession_configuration--
            ${this.bootstrapConfiguration.clientId}_
            ${this.bootstrapConfiguration.environment}
        `.replace(/(?:\n\s+)/g, '');

        this.dustEnabled = getSafe(() => this.client.dustEnabled);

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {LogTransaction} logTransaction
     * @desc Retrieves the remote configuration, falls back to cached version if available.
     * @note We don’t really make separate builds for different envs, a default value for
     * bootstrapConfiguration.configHostName is set in BootstrapConfiguration
     * @returns {Promise<SDK.Services.Configuration.SdkSessionConfiguration>}
     *
     */
    public async getConfiguration(logTransaction: LogTransaction) {
        const { client, cacheKey, dustEnabled, storage, logger } = this;
        const { bootstrapConfiguration, environmentConfiguration } = this;

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: ConfigurationManagerDustUrnReference.getConfiguration,
            skipLogTransaction: true
        });

        logger.info(
            this.toString(),
            `Getting configuration:
            clientId: "${bootstrapConfiguration.clientId}",
            environment: "${bootstrapConfiguration.environment}",
            deviceFamily: "${environmentConfiguration.deviceFamily}",
            sdkVersion: "${environmentConfiguration.sdkVersion}",
            configVersion: "${environmentConfiguration.configVersion}",
            configHostName: "${bootstrapConfiguration.configHostName}",
            configHostOverride: "${bootstrapConfiguration.configHostOverride}",
            applicationRuntimeOverride: "${bootstrapConfiguration.applicationRuntimeOverride}",
            deviceProfileOverride: "${bootstrapConfiguration.deviceProfileOverride}",
            configHostUrlOverride: "${bootstrapConfiguration.configHostUrlOverride}"
        `.replace(/(\s(?=\s))/g, '')
        );

        try {
            const sdkSessionConfiguration = await client.getConfiguration(
                bootstrapConfiguration,
                environmentConfiguration,
                logTransaction
            );

            logger.log(this.toString(), 'Successfully fetched configuration.');

            try {
                await this.cacheConfiguration(sdkSessionConfiguration);
            } catch (ex) {
                logger.log(
                    this.toString(),
                    'Failed to cache sdkSessionConfiguration'
                );
                logger.error(this.toString(), ex);

                dustLogUtility.captureError(ex);
            }

            return sdkSessionConfiguration;
        } catch (ex) {
            logger.error(this.toString(), 'Unable to get configuration.');
            logger.log(
                this.toString(),
                `Loading from storage, cache key: "${cacheKey}".`
            );

            dustLogUtility.captureError(ex);

            try {
                const storedSdkSessionConfiguration = await storage.get(
                    cacheKey
                );
                const sdkSessionConfiguration = new SdkSessionConfiguration(
                    storedSdkSessionConfiguration
                );

                return sdkSessionConfiguration;
            } catch (e) {
                logger.error(
                    this.toString(),
                    'Unable to fetch cached configuration.'
                );

                return await this.getFallbackConfiguration(logTransaction);
            }
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Configuration.SdkSessionConfiguration} sdkSessionConfiguration
     * @returns {Promise<Void>}
     *
     */
    private async cacheConfiguration(
        sdkSessionConfiguration: SdkSessionConfiguration
    ) {
        this.logger.log(
            this.toString(),
            `Adding configuration to Storage, cache key: "${this.cacheKey}".`
        );

        return await this.storage.set(this.cacheKey, sdkSessionConfiguration);
    }

    /**
     *
     * @access private
     * @param {LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Configuration.SdkSessionConfiguration>}
     *
     */
    public async getFallbackConfiguration(logTransaction: LogTransaction) {
        const fallbackConfiguration = new EmbeddedConfiguration({
            isFallback: true
        });
        const configurationClient = new ConfigurationClient(
            fallbackConfiguration.client,
            this.logger,
            this.httpClient
        );

        this.logger.log(this.toString(), 'Fetching fallback configuration.');

        return await configurationClient.getConfiguration(
            this.bootstrapConfiguration,
            this.environmentConfiguration,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Configuration.BootstrapConfiguration} bootstrapConfiguration
     * @returns {SDK.Services.Configuration.EnvironmentConfiguration}
     *
     */
    public static createEnvironmentConfiguration(
        bootstrapConfiguration: BootstrapConfiguration
    ) {
        const { applicationRuntimeOverride, deviceProfileOverride, platform } =
            bootstrapConfiguration;

        const platformProfile = platform
            ? PlatformProfileReference[
                  platform as keyof typeof PlatformProfileReference
              ]
            : undefined;

        const options = {
            applicationRuntimeOverride,
            deviceProfileOverride,
            applicationVersion: getSafe(
                () => bootstrapConfiguration.application.version
            ),
            ...platformProfile
        };

        return new PlatformProviders.EnvironmentConfiguration(options);
    }

    /**
     *
     * @access public
     * @returns {SDK.Services.Configuration.ConfigurationClientConfiguration}
     *
     */
    public static getClientConfiguration() {
        const embeddedConfiguration = new EmbeddedConfiguration({
            isFallback: false
        });

        return embeddedConfiguration.client;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Configuration.ConfigurationManager';
    }
}
