/**
 *
 * @module entry
 * @desc {SDK.UserProfile} entry point.
 *
 */

import UserProfileApi from './userProfileApi';

/**
 *
 * @typedef {Object} SDK.UserProfile
 *
 * @property {UserActivityApi} UserProfileApi
 *
 */
export default {
    UserProfileApi
};
