/**
 *
 * @module socketManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import RetryPolicy from './retryPolicy';

type PingPolicy = {
    disabled: boolean;
    pingInterval: number;
    pingMaxAttempts: number;
    pongTimeout: number;
};

type UnacknowledgedEventBuffer = {
    maxSize: number;
};

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class SocketManagerExtrasMap {
    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.RetryPolicy}
     * @desc retry policy for the lifecycle of a socket connection.
     *
     */
    public retryPolicy: RetryPolicy;

    /**
     *
     * @access public
     * @type {Number}
     * @desc Duplicate message store size.
     *
     */
    public messageDeduplicationStoreSize: number;

    /**
     *
     * @access public
     * @since 21.0.0
     * @type {Object}
     *
     */
    public pingPolicy: PingPolicy;

    /**
     *
     * @access public
     * @since 21.0.0
     * @type {Object}
     *
     */
    public unacknowledgedEventBuffer: UnacknowledgedEventBuffer;

    /**
     *
     * @param {SDK.Services.Configuration.RetryPolicy} retryPolicy
     * @param {Number} messageDeduplicationStoreSize
     * @param {Object} pingPolicy
     * @param {Object} options.unacknowledgedEventBuffer
     *
     */
    public constructor(options: {
        retryPolicy: RetryPolicy;
        messageDeduplicationStoreSize: number;
        pingPolicy: PingPolicy;
        unacknowledgedEventBuffer: UnacknowledgedEventBuffer;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    retryPolicy: Types.instanceStrict(RetryPolicy),
                    messageDeduplicationStoreSize: Types.number,
                    pingPolicy: Types.object({
                        disabled: Types.boolean,
                        pingInterval: Types.number,
                        pingMaxAttempts: Types.number,
                        pongTimeout: Types.number
                    }),
                    unacknowledgedEventBuffer: Types.object({
                        maxSize: Types.number
                    })
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            retryPolicy,
            messageDeduplicationStoreSize,
            pingPolicy,
            unacknowledgedEventBuffer
        } = options;

        this.retryPolicy = retryPolicy;
        this.messageDeduplicationStoreSize =
            messageDeduplicationStoreSize || 100;
        this.pingPolicy = pingPolicy;
        this.unacknowledgedEventBuffer = unacknowledgedEventBuffer;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.SocketManagerExtrasMap';
    }
}
