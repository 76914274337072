/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.AdActivity
 * @desc Used to identify what ad-related activity is occurring in playback. This is a type 1 enum.
 *
 * @property {String} adPodRequested - Since `19.0.0` - When clients make a request via getPodRequest to the Disney Ad Server, applicable to SGAI-supporting clients only.
 * @property {String} adPodFetched - Since `19.0.0` - When clients receive a response from the Disney Ad Server for getPodRequest, applicable to SGAI-supporting clients only.
 * @property {String} adMultivariantFetched - Since `19.0.0` - When a multivariant playlist has been downloaded, applicable to SGAI-supporting clients only.
 * @property {String} adVariantFetched - Since `19.0.0` - When a variant playlist has been downloaded, applicable to SGAI-supporting clients only.
 * @property {String} adPlaybackStarted - Since `19.0.0` - When the first frame has been displayed for each ad slot, applicable to all ad-supporting client.
 * @property {String} adPlaybackEnded - Since `19.0.0` - When playback completes for any reason (e.g., successful playback to end, user exit before/during playback,
 * or playback failure) for each ad slot, applicable to all ad-supporting clients.
 *
 * @example
 * AdActivity.adPodRequested // 'adPodRequested'
 *
 */
export enum AdActivity {
    adPodRequested = 'adPodRequested',
    adPodFetched = 'adPodFetched',
    adMultivariantFetched = 'adMultivariantFetched',
    adVariantFetched = 'adVariantFetched',
    adPlaybackStarted = 'adPlaybackStarted',
    adPlaybackEnded = 'adPlaybackEnded'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.AdInsertionType
 * @desc Used to identify the way ads are inserted into the stream. This is a type 1 enum.
 *
 * @property {String} ssai - Since `19.0.0` - Server-side insertion.
 * @property {String} sgai - Since `19.0.0` - Server-guided client-side insertion.
 * @property {String} none - Since `19.0.0` - No ads inserted.
 *
 * @example
 * AdInsertionType.ssai // 'ssai'
 *
 */
export enum AdInsertionType {
    ssai = 'ssai',
    sgai = 'sgai',
    none = 'none'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PodPosition
 * @desc The position of the requested or played ad pod. This is a type 1 enum.
 *
 * @property {String} preroll - Since `19.0.0` - Before content.
 * @property {String} midroll - Since `19.0.0` - During content.
 * @property {String} postroll - Since `19.0.0` - After content.
 *
 * @example
 * PodPosition.preroll // 'preroll'
 *
 */
export enum PodPosition {
    preroll = 'preroll',
    midroll = 'midroll',
    postroll = 'postroll'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PresentationType
 * @desc A playback timeline consistent of smaller units of different content types or presentations. This property indicates the type currently playing.
 * @note The type is indicated in the manifest (when ads are interleaved) or implied from the playlist (when non-interleaved).
 * @note If bumper or dub card is not available / distinguishable, you can pass `main` in its place.
 * @note Default to `unknown` if in playing state but content type is unknown, or if value is not available.
 * @note This is a type 1 enum.
 *
 * @property {String} ad - Since `19.0.0`
 * @property {String} bumper - Since `19.0.0`
 * @property {String} main - Since `19.0.0`
 * @property {String} dubcard - Since `19.0.0`
 * @property {String} promo - Since `19.0.0`
 * @property {String} slug - Since `21.0.0`
 * @property {String} unknown - Since `19.0.0`
 *
 * @example
 * SDK.Services.QualityOfService.PresentationType.ad // 'ad'
 *
 */
export enum PresentationType {
    ad = 'ad',
    bumper = 'bumper',
    main = 'main',
    dubcard = 'dubcard',
    promo = 'promo',
    slug = 'slug',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotEventType
 * @desc Used to identify what kind of event the player dispatched.
 *
 * @property {String} qosFragment - Since `19.0.0` - A video or audio media segment was downloaded.
 * @property {String} qosPlaylist - Since `19.0.0` - A multivariant or variant playlist was downloaded.
 * @property {String} seeking - Since `19.0.0` - The player started seeking.
 * @property {String} seeked - Since `19.0.0` - The player finished seeking.
 * @property {String} waiting - Since `19.0.0` - The player started rebuffering.
 * @property {String} canPlay - Since `19.0.0` - The player stopped rebuffering and has enough buffered data to start/resume playback.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotEventType.qosFragment // 'qosFragment'
 *
 */
export enum SnapshotEventType {
    qosFragment = 'qosFragment',
    qosPlaylist = 'qosPlaylist',
    seeking = 'seeking',
    seeked = 'seeked',
    waiting = 'waiting',
    canPlay = 'canPlay'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotNextAction
 * @desc Defines how player should proceed when handling upcoming downloads.
 *
 * @property {String} fail - Since `19.0.0` - Stop playback and report fatal failures.
 * @property {String} next - Since `19.0.0` - Continue to execute the next download behavior.
 * @property {String} retry - Since `19.0.0` - Retry the last download.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotNextAction.fail // 'fail'
 *
 */
export enum SnapshotNextAction {
    fail = 'fail',
    next = 'next',
    retry = 'retry'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotDownloadDataType
 * @desc Defines the various types of downloaded data resources that are tracked by a SnapshotEvent.
 *
 * @property {String} audio - Since `19.0.0` - This corresponds to either an audio variant playlist type or an audio fragment.
 * @property {String} multivariantPlaylist - Since `19.0.0` - This corresponds to the multivariant playlist being downloaded.
 * @property {String} video - Since `19.0.0` - This corresponds to either a video variant playlist type or a video fragment.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotDownloadDataType.audio // 'audio'
 *
 */
export enum SnapshotDownloadDataType {
    audio = 'audio',
    multivariantPlaylist = 'multivariantPlaylist',
    video = 'video'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotDownloadFailure
 * @desc Defines the download failure types of a SnapshotDownloadDataType that are tracked by a SnapshotEvent.
 *
 * @property {String} abort - Since `19.0.0` - The player proactively aborted the current download.
 * @property {String} error - Since `19.0.0` - non-2xx HTTP code.
 * @property {String} fragmentMissing - Since `19.0.0` - The player is trying to fetch a missing media resource (especially for Live streaming).
 * @property {String} playlistNotUpdated - Since `19.0.0` - The playlist doesn't update in a timely fashion.
 * @property {String} timeout - Since `19.0.0` - The download timed-out.
 * @property {String} unknown - Since `19.0.0` - Unknown failures that cannot be categorized.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotDownloadFailure.abort // 'abort'
 *
 */
export enum SnapshotDownloadFailure {
    abort = 'abort',
    error = 'error',
    fragmentMissing = 'fragmentMissing',
    playlistNotUpdated = 'playlistNotUpdated',
    timeout = 'timeout',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 20.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotFragmentType
 * @desc Defines the different fragment types that can be downloaded for a SnapshotDownloadDataType.
 *
 * @property {String} init - Since `20.0.0` - Downloading init sections.
 * @property {String} media - Since `20.0.0` - Downloading media segments.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotFragmentType.init // 'init'
 *
 */
export enum SnapshotFragmentType {
    init = 'init',
    media = 'media'
}
