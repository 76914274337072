/**
 *
 * @module flexClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/flex.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/growth-life-client-api.yaml
 * @see https://www.typescriptlang.org/docs/handbook/2/classes.html#implements-clauses
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import { stringify } from 'query-string';

import Logger from './../../logging/logger';
import LogTransaction from './../../logging/logTransaction';
import checkResponseCode from '../util/checkResponseCode';
import DustLogUtility from './../internal/dust/dustLogUtility';
import DustUrnReference from './../internal/dust/dustUrnReference';
import CoreHttpClientProvider from './../providers/shared/coreHttpClientProvider';
import AccessToken from './../token/accessToken';
import HttpMethod from './../configuration/httpMethod';
import appendQuerystring from './../util/appendQuerystring';

import { FlexOptions } from './../../flex/typedefs';
import FlexClientEndpoint from './flexClientEndpoint';
import FlexClientConfiguration from './flexClientConfiguration';

import { Screen, ExecutionResponse } from './typedefs';

const FlexClientDustUrnReference = DustUrnReference.services.flex.flexClient;

interface FlexClientOptions {
    flexClientConfiguration: FlexClientConfiguration;
    logger: Logger;
    httpClient: CoreHttpClientProvider;
}

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Provides a data client that can be used to access GrowthLife services.
 *
 */
export default class FlexClient {
    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Services.Flex.FlexClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    private config: FlexClientConfiguration;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {HttpClient}
     * @desc The object responsible for making HTTP requests.
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {Boolean}
     * @note needs to default to true to collect dust events before the configuration is fetched and we can
     * determine if this should be enabled
     *
     */
    public dustEnabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Flex.FlexClientConfiguration} options.flexClientConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: FlexClientOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    flexClientConfiguration: Types.instanceStrict(
                        FlexClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { flexClientConfiguration, logger, httpClient } = options;

        this.config = flexClientConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.dustEnabled = true;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {SDK.Flex.FlexOptions} flexOptions - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Flex.Screen>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getScreen(
        flexOptions: SDK.Flex.FlexOptionsOptions,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<SDK.Services.Flex.Screen> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                flexOptions: Types.object(FlexOptions),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getScreen', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;
        const { key, version, flexParams, configOverride } = flexOptions;

        let endpointKey =
            FlexClientEndpoint[key as keyof typeof FlexClientEndpoint];

        if (Check.not.assigned(endpointKey)) {
            endpointKey = FlexClientEndpoint.default;

            logger.warn(
                this.toString(),
                `FlexClientEndpoint[${key}] is not defined, using the "default" endpoint.`
            );
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                flexParams,
                version,
                configOverride
            }
        });

        logger.info(this.toString(), `Attempting to get Screen: ${key}.`);

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: FlexClientDustUrnReference.getScreen,
            payload,
            method: payload.method?.toUpperCase(),
            endpointKey,
            logTransaction
        });

        return httpClient
            .request(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const viewData = data.data;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        viewData: Types.object(Screen)
                    };

                    typecheck.warn(responseType, [viewData]);
                }

                return viewData;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {SDK.Flex.FlexOptions} flexOptions - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Flex.ExecutionResponse>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async execute(
        flexOptions: SDK.Flex.FlexOptionsOptions,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ): Promise<SDK.Services.Flex.ExecutionResponse> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                flexOptions: Types.object(FlexOptions),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'execute', params, arguments);
        }

        const { dustEnabled, logger, httpClient } = this;
        const { key, version, flexParams, configOverride } = flexOptions;

        let endpointKey =
            FlexClientEndpoint[key as keyof typeof FlexClientEndpoint];

        if (Check.not.assigned(endpointKey)) {
            endpointKey = FlexClientEndpoint.execution;

            logger.warn(
                this.toString(),
                `FlexClientEndpoint[${key}] is not defined, using the "execution" endpoint.`
            );
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                flexParams,
                version,
                configOverride
            }
        });

        logger.info(this.toString(), `Attempting to execute: ${key}.`);

        const dustLogUtility = new DustLogUtility({
            dustEnabled,
            logger,
            source: this.toString(),
            urn: FlexClientDustUrnReference.execute,
            payload,
            method: payload.method?.toUpperCase(),
            endpointKey,
            logTransaction
        });

        return httpClient
            .request(payload)
            .then((response: TodoAny) => {
                return checkResponseCode(response, dustLogUtility);
            })
            .then((response: TodoAny) => {
                const { data } = response;
                const flexData = data.data;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        flexData: Types.object(ExecutionResponse)
                    };

                    typecheck.warn(responseType, [flexData]);
                }

                return flexData;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {String<SDK.Services.Flex.FlexClientEndpoint>} options.endpointKey
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: keyof typeof FlexClientEndpoint;
        data: {
            flexParams?: object;
            version: string;
            configOverride?: (endpoint: IEndpoint) => IEndpoint;
        };
    }): GetPayloadResult {
        const { accessToken, endpointKey, data } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];
        const { headers, href, method, templated } = endpoint;
        const requestHeaders = { ...headers };
        const { flexParams, version, configOverride } = data;

        let requestMethod = method;
        let requestHref = href;
        let requestBody;
        let url;

        if (configOverride) {
            if (
                endpointKey === FlexClientEndpoint.default ||
                endpointKey === FlexClientEndpoint.execution
            ) {
                const overrideSettings = configOverride({ ...endpoint });

                requestMethod = overrideSettings.method;
                requestHref = overrideSettings.href;
            } else {
                this.logger.warn(
                    this.toString(),
                    'configOverride is only allowed for unknown key(s).'
                );
            }
        }

        if (templated && version) {
            requestHref = requestHref.replace(/\{version\}/gi, version);
        }

        url = requestHref;
        requestHeaders.Authorization = requestHeaders?.Authorization?.replace(
            /\{accessToken\}/gi,
            accessToken.token
        );

        if (Check.assigned(flexParams)) {
            if (requestMethod === HttpMethod.GET) {
                url = appendQuerystring(requestHref, stringify(flexParams));
            } else {
                requestBody = JSON.stringify(flexParams);
            }
        }

        return {
            url,
            method: requestMethod,
            body: requestBody,
            headers: requestHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Flex.FlexClient';
    }
}
