/**
 *
 * @module dssWebPlayerAdapter
 * @desc The SDK.Media.PlayerAdapter instance for the Disney Streaming web player.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/pages/btm-browser-media-platform/documentation/jsdoc/btm-browser-media-platform-common/MediaPlatform.html
 * @see https://github.bamtech.co/pages/btm-browser-media-platform/documentation/jsdoc/btm-browser-media-platform-common/MediaPlatform.Common.Constants.Events.html
 * @see https://github.bamtech.co/btm-browser-media-platform
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import PublicEvents from './../../events';

import { getResolutionString } from './playerAdapterUtils';

import PlaybackEventListener from '../playbackEventListener';
import PlaybackMetrics from '../playbackMetrics';
import PlayerAdapter from './../playerAdapter';
import Playlist from './../playlist';

import AudioRendition from './../../services/media/audioRendition';
import InsertionPointPlacement from './../../services/media/insertionPointPlacement';
import PlaybackVariant from './../../services/media/playbackVariant';
import SubtitleRendition from '../../services/media/subtitleRendition';
import DrmType from '../../services/media/drmType';
import DrmProvider from '../../drm/drmProvider';

import ErrorLevel from '../../services/qualityOfService/errorLevel';
import FetchStatus from '../../services/qualityOfService/fetchStatus';
import HttpMethod from '../../services/qualityOfService/httpMethod';
import MediaSegmentType from '../../services/qualityOfService/mediaSegmentType';
import NetworkType from '../../services/qualityOfService/networkType';
import mapHiveToQoeErrorCodes from '../../services/qualityOfService/mapHiveToQoeErrorCodes';
import PlaybackExitedCause from '../../services/qualityOfService/playbackExitedCause';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import ServerRequest from '../../services/qualityOfService/serverRequest';
import StartupActivity from '../../services/qualityOfService/startupActivity';
import QoePlaybackError from '../../services/qualityOfService/qoePlaybackError';

import getSafe from './../../services/util/getSafe';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';

import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import ExceptionReference from '../../services/exception/exceptionReference';

import {
    PodPosition,
    PresentationType
} from '../../services/qualityOfService/enums';
import ApplicationContext from '../../services/qualityOfService/applicationContext';

const defaultAdMetadata = {
    adSlotData: {
        adMediaId: '00000000-0000-0000-0000-000000000000',
        slotNumber: 0,
        plannedLength: 0
    },
    adPodPlacement: {
        podPosition: PodPosition.preroll
    },
    adPodData: {
        plannedSlotCount: 0,
        plannedLength: 0
    }
};

/**
 *
 * @since 3.9.0
 * @desc Interface used to communicate with the Disney Streaming web player.
 * @note Disney Streaming web player.
 *
 */
export default class DssWebPlayerAdapter extends PlayerAdapter {
    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer - An instance of the BAMTECH web player.
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     *
     */
    constructor(options) {
        super(options);

        /**
         *
         * @access public
         * @type {Array<SDK.Drm.DrmProvider>}
         * @desc Set of DRM providers
         *
         */
        this.drmProviders = [];

        /**
         *
         * @access private
         * @type {Object|null}
         *
         */
        this.trackingData = null;

        /**
         *
         * @access private
         * @since 8.0.0
         * @type {SDK.Services.QualityOfService.PlaybackStartupEventData}
         * @desc Keeps track of CDN fallback for QoE support.
         *
         */
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        /**
         *
         * @access private
         * @since 13.0.0
         * @type {Date}
         * @desc Time when rebufferingStartedEvent is triggered. Used to calculate duration for rebufferingEndedEvent.
         *
         */
        this.rebufferingDuration = null;

        /**
         *
         * @access private
         * @since 15.0.0
         * @type {Object}
         * @desc Keeps track of CDN fallback qos tracking information.
         *
         */
        this.qos = {};

        /**
         *
         * @access private
         * @since 20.0.2
         * @type {Object}
         * @desc Used to store various data related to ads.
         *
         */
        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        /**
         *
         * @access private
         * @since 20.0.2
         * @type {Object}
         * @desc Cached values use for the heartbeat event that are updated
         * when specific events fire.
         *
         */
        this.heartbeatData = {};

        /**
         *
         * @access private
         * @since 21.0.0
         * @type {Object|null}
         * @desc The most recent multivariant playlist request
         *
         */
        this.currentMultivariantPlaylistRequest = null;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Sets the source URI on the NativePlayer instance,
     * callback used when prepare has been called (usually via the {@link PlaybackSession}).
     * @note ensure a proper accessToken is passed on xhr requests for the NativePlayer DRM
     *
     */
    setSource(playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        // `playlistUri` can be either a string or array of objects for backwards compatibility purposes.
        this.playlistUri = playlist.streamUri;
        this.currentStreamUrl = playlist.streamUri;

        if (
            this.nativePlayer.isStartFailureSaveSupported() &&
            this.cdnFallback.isEnabled
        ) {
            this.playlistUri = playlist.mediaSources;
        }

        this.nativePlayer.drm.setXhrConfigProvider(() => ({
            headers: {
                key: {
                    Authorization: this.accessToken
                }
            }
        }));
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    clean() {
        const { listener } = this;

        this.resetSource();
        this.resetDrmProviders();

        this.drmProvider = null;
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.qos = {};
        this.mediaBytesDownloaded = 0;
        this.isBuffering = false;
        this.isReady = false;
        this.heartbeatData = {};
        this.mediaDownloadTotalCount = 0;
        this.mediaDownloadTotalTime = 0;
        this.bitrateAvg = 0;
        this.seekData = null;
        this.currentMultivariantPlaylistRequest = null;
        this.presentationType = PresentationType.main;
        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        if (Check.assigned(listener)) {
            this.removeListener(listener);
        }

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @param {Object} trackingData - The tracking data for the current `playlistUri`
     * @desc Sets tracking data
     *
     */
    setTrackingData(trackingData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                trackingData: Types.nonEmptyObject
            };

            typecheck(this, 'setTrackingData', params, arguments);
        }

        this.trackingData = trackingData;
    }

    /**
     *
     * @access public
     * @since 4.16.2
     * @param {Object} eventData
     * @desc Trigger when playback has been exited.
     * @note avoids NaN
     *
     */
    playbackEndedEvent(eventData) {
        if (Check.assigned(this.listener)) {
            const { playbackStartupEventData } = this;

            const playbackEndedEvent = eventData || {};

            playbackEndedEvent.cdnRequestedTrail =
                playbackStartupEventData.cdnRequestedTrail;
            playbackEndedEvent.cdnFailedTrail =
                playbackStartupEventData.cdnFailedTrail;
            playbackEndedEvent.cdnFallbackCount =
                playbackStartupEventData.cdnFallbackCount;
            playbackEndedEvent.isCdnFallback =
                playbackStartupEventData.isCdnFallback;

            if (
                [PresentationType.ad, PresentationType.bumper].includes(
                    this.currentMultivariantPlaylistRequest?.assetType
                )
            ) {
                const adMediaId = this.getAdMediaId();

                this.adPlaybackEnded({
                    ...playbackEndedEvent,
                    asset: {
                        mediaId: adMediaId
                    }
                });
            }

            this.onPlaybackEnded(playbackEndedEvent);
        }
    }

    /**
     *
     * @access public
     * @desc Completes the cleanup process by completely cleaning up all {@link PlayerAdapter} references.
     * This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
        this.playbackStartupEventData = null;
    }

    // #region protected

    /**
     *
     * @access protected
     * @param {PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @returns {Void}
     *
     */
    addListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, boundHandlers } = this;
        const { ErrorEvents, PlayerEvents, InterstitialEvents } =
            nativePlayer.events;

        const {
            playbackStartedEvent,
            playbackPausedEvent,
            playbackResumedEvent,
            playbackInitializedEvent,
            playbackReadyEvent,
            playbackSeekStartedEvent,
            playbackSeekEndedEvent,
            bitrateChangedEvent,
            successfulPlaylistLoad,
            rebufferingStartedEvent,
            rebufferingEndedEvent,
            subtitleChangedEvent,
            audioChangedEvent,
            multivariantPlaylistFetchedEvent,
            variantPlaylistFetchedEvent,
            mediaSegmentFetchedEvent,
            multivariantPlaylistRequest,
            multivariantPlaylistFallback,
            drmKeyFetchedEvent,
            playbackErrorEvent,
            adPodRequested,
            adPodFetched,
            adPlaybackStarted,
            adPlaybackEnded,
            adPodStarted,
            adPodEnded,
            adMultivariantFetched,
            adVariantFetched,
            adRequestedError,
            adBeaconError
        } = this;

        const { MEDIA_ERROR } = ErrorEvents;

        const {
            MEDIA_PAUSED,
            MEDIA_RESUMED,
            MEDIA_STARTED,
            INITIALIZED,
            READY,
            MEDIA_SEEK_COMPLETE,
            MEDIA_SEEKING,
            BUFFERING_STARTED,
            BUFFERING_ENDED,
            MULTIVARIANT_PLAYLIST_LOADED,
            VARIANT_LOADED,
            CHUNK_LOADED,
            DRM_LICENSE_RECEIVED,
            MULTIVARIANT_PLAYLIST_REQUEST,
            MULTIVARIANT_PLAYLIST_FALLBACK
        } = PlayerEvents.PLAYBACK;

        const {
            SESSION_REQUESTED: INTERSTITIAL_SESSION_REQUESTED,
            SESSION_FETCHED: INTERSTITIAL_SESSION_FETCHED,
            SESSION_STARTED: INTERSTITIAL_SESSION_STARTED,
            SESSION_FINISHED: INTERSTITIAL_SESSION_FINISHED,
            MULTIVARIANT_FETCHED: INTERSTITIAL_MULTIVARIANT_FETCHED,
            VARIANT_FETCHED: INTERSTITIAL_VARIANT_FETCHED,
            ASSET_STARTED: INTERSTITIAL_ASSET_STARTED,
            ASSET_FINISHED: INTERSTITIAL_ASSET_FINISHED,
            SESSION_REQUESTED_ERROR: INTERSTITIAL_SESSION_REQUESTED_ERROR,
            BEACON_ERROR
        } = InterstitialEvents;

        const { ACTIVE_PROFILE_CHANGED } = PlayerEvents.QUALITY;
        const { SELECTED_TRACK_CHANGED: SUBTITLE_SELECTED_TRACK_CHANGED } =
            PlayerEvents.CAPTIONS;
        const { SELECTED_TRACK_CHANGED: AUDIO_SELECTED_TRACK_CHANGED } =
            PlayerEvents.AUDIO;

        if (Check.function(nativePlayer.on)) {
            this.listener = listener;

            boundHandlers.playbackStartedEvent =
                playbackStartedEvent.bind(this);
            boundHandlers.playbackPausedEvent = playbackPausedEvent.bind(this);
            boundHandlers.playbackResumedEvent =
                playbackResumedEvent.bind(this);
            boundHandlers.playbackInitializedEvent =
                playbackInitializedEvent.bind(this);
            boundHandlers.playbackReadyEvent = playbackReadyEvent.bind(this);
            boundHandlers.playbackSeekStartedEvent =
                playbackSeekStartedEvent.bind(this);
            boundHandlers.playbackSeekEndedEvent =
                playbackSeekEndedEvent.bind(this);
            boundHandlers.bitrateChangedEvent = bitrateChangedEvent.bind(this);
            boundHandlers.rebufferingStartedEvent =
                rebufferingStartedEvent.bind(this);
            boundHandlers.rebufferingEndedEvent =
                rebufferingEndedEvent.bind(this);
            boundHandlers.subtitleChangedEvent =
                subtitleChangedEvent.bind(this);
            boundHandlers.audioChangedEvent = audioChangedEvent.bind(this);
            boundHandlers.multivariantPlaylistFetchedEvent =
                multivariantPlaylistFetchedEvent.bind(this);
            boundHandlers.variantPlaylistFetchedEvent =
                variantPlaylistFetchedEvent.bind(this);
            boundHandlers.mediaSegmentFetchedEvent =
                mediaSegmentFetchedEvent.bind(this);
            boundHandlers.drmKeyFetchedEvent = drmKeyFetchedEvent.bind(this);
            boundHandlers.successfulPlaylistLoad =
                successfulPlaylistLoad.bind(this);
            boundHandlers.multivariantPlaylistRequest =
                multivariantPlaylistRequest.bind(this);
            boundHandlers.multivariantPlaylistFallback =
                multivariantPlaylistFallback.bind(this);
            boundHandlers.playbackErrorEvent = playbackErrorEvent.bind(this);
            boundHandlers.adPodRequested = adPodRequested.bind(this);
            boundHandlers.adPodFetched = adPodFetched.bind(this);
            boundHandlers.adMultivariantFetched =
                adMultivariantFetched.bind(this);
            boundHandlers.adVariantFetched = adVariantFetched.bind(this);
            boundHandlers.adPlaybackStarted = adPlaybackStarted.bind(this);
            boundHandlers.adPlaybackEnded = adPlaybackEnded.bind(this);
            boundHandlers.adPodStarted = adPodStarted.bind(this);
            boundHandlers.adPodEnded = adPodEnded.bind(this);
            boundHandlers.adRequestedError = adRequestedError.bind(this);
            boundHandlers.adBeaconError = adBeaconError.bind(this);

            nativePlayer.on(MEDIA_STARTED, boundHandlers.playbackStartedEvent);
            nativePlayer.on(MEDIA_RESUMED, boundHandlers.playbackResumedEvent);
            nativePlayer.on(MEDIA_PAUSED, boundHandlers.playbackPausedEvent);
            nativePlayer.on(
                INITIALIZED,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.on(READY, boundHandlers.playbackReadyEvent);
            nativePlayer.on(
                MEDIA_SEEKING,
                boundHandlers.playbackSeekStartedEvent
            );
            nativePlayer.on(
                MEDIA_SEEK_COMPLETE,
                boundHandlers.playbackSeekEndedEvent
            );
            nativePlayer.on(
                ACTIVE_PROFILE_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.on(
                BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.on(
                BUFFERING_ENDED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.on(
                SUBTITLE_SELECTED_TRACK_CHANGED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.on(
                AUDIO_SELECTED_TRACK_CHANGED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.successfulPlaylistLoad
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_REQUEST,
                boundHandlers.multivariantPlaylistRequest
            );
            nativePlayer.on(
                MULTIVARIANT_PLAYLIST_FALLBACK,
                boundHandlers.multivariantPlaylistFallback
            );
            nativePlayer.on(
                VARIANT_LOADED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.on(
                CHUNK_LOADED,
                boundHandlers.mediaSegmentFetchedEvent
            );
            nativePlayer.on(
                DRM_LICENSE_RECEIVED,
                boundHandlers.drmKeyFetchedEvent
            );
            nativePlayer.on(MEDIA_ERROR, boundHandlers.playbackErrorEvent);
            nativePlayer.on(BEACON_ERROR, boundHandlers.adBeaconError);
            nativePlayer.on(
                INTERSTITIAL_SESSION_REQUESTED,
                boundHandlers.adPodRequested
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_FETCHED,
                boundHandlers.adPodFetched
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_REQUESTED_ERROR,
                boundHandlers.adRequestedError
            );
            nativePlayer.on(
                INTERSTITIAL_MULTIVARIANT_FETCHED,
                boundHandlers.adMultivariantFetched
            );
            nativePlayer.on(
                INTERSTITIAL_VARIANT_FETCHED,
                boundHandlers.adVariantFetched
            );
            nativePlayer.on(
                INTERSTITIAL_ASSET_STARTED,
                boundHandlers.adPlaybackStarted
            );
            nativePlayer.on(
                INTERSTITIAL_ASSET_FINISHED,
                boundHandlers.adPlaybackEnded
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_STARTED,
                boundHandlers.adPodStarted
            );
            nativePlayer.on(
                INTERSTITIAL_SESSION_FINISHED,
                boundHandlers.adPodEnded
            );
        } else {
            const errorMsg = `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`;

            const reasons = [new ErrorReason('', errorMsg)];
            const exceptionData = ExceptionReference.common.invalidState;

            throw new ServiceException({ reasons, exceptionData });
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the {@link PlaybackEventListener} to use.
     * @desc Removes listener
     * @returns {Void}
     *
     */
    removeListener(listener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const { nativePlayer } = this;
        const { boundHandlers } = this;
        const { ErrorEvents, PlayerEvents, InterstitialEvents } =
            nativePlayer.events;
        const { MEDIA_ERROR } = ErrorEvents;

        const {
            MEDIA_PAUSED,
            MEDIA_RESUMED,
            MEDIA_STARTED,
            INITIALIZED,
            READY,
            MEDIA_SEEK_COMPLETE,
            MEDIA_SEEKING,
            BUFFERING_STARTED,
            BUFFERING_ENDED,
            MULTIVARIANT_PLAYLIST_LOADED,
            VARIANT_LOADED,
            CHUNK_LOADED,
            DRM_LICENSE_RECEIVED,
            MULTIVARIANT_PLAYLIST_REQUEST,
            MULTIVARIANT_PLAYLIST_FALLBACK
        } = PlayerEvents.PLAYBACK;

        const {
            SESSION_REQUESTED: INTERSTITIAL_SESSION_REQUESTED,
            SESSION_FETCHED: INTERSTITIAL_SESSION_FETCHED,
            SESSION_STARTED: INTERSTITIAL_SESSION_STARTED,
            SESSION_FINISHED: INTERSTITIAL_SESSION_FINISHED,
            MULTIVARIANT_FETCHED: INTERSTITIAL_MULTIVARIANT_FETCHED,
            VARIANT_FETCHED: INTERSTITIAL_VARIANT_FETCHED,
            ASSET_STARTED: INTERSTITIAL_ASSET_STARTED,
            ASSET_FINISHED: INTERSTITIAL_ASSET_FINISHED,
            SESSION_REQUESTED_ERROR: INTERSTITIAL_SESSION_REQUESTED_ERROR,
            BEACON_ERROR
        } = InterstitialEvents;

        const { ACTIVE_PROFILE_CHANGED } = PlayerEvents.QUALITY;
        const { SELECTED_TRACK_CHANGED: SUBTITLE_SELECTED_TRACK_CHANGED } =
            PlayerEvents.CAPTIONS;
        const { SELECTED_TRACK_CHANGED: AUDIO_SELECTED_TRACK_CHANGED } =
            PlayerEvents.AUDIO;

        const isCorrectListener =
            Check.assigned(this.listener) && this.listener === listener;

        if (Check.function(nativePlayer.off) && isCorrectListener) {
            this.listener = null;

            nativePlayer.off(MEDIA_STARTED, boundHandlers.playbackStartedEvent);
            nativePlayer.off(MEDIA_RESUMED, boundHandlers.playbackResumedEvent);
            nativePlayer.off(MEDIA_PAUSED, boundHandlers.playbackPausedEvent);
            nativePlayer.off(
                INITIALIZED,
                boundHandlers.playbackInitializedEvent
            );
            nativePlayer.off(READY, boundHandlers.playbackReadyEvent);
            nativePlayer.off(
                MEDIA_SEEKING,
                boundHandlers.playbackSeekStartedEvent
            );
            nativePlayer.off(
                MEDIA_SEEK_COMPLETE,
                boundHandlers.playbackSeekEndedEvent
            );
            nativePlayer.off(
                ACTIVE_PROFILE_CHANGED,
                boundHandlers.bitrateChangedEvent
            );
            nativePlayer.off(
                BUFFERING_STARTED,
                boundHandlers.rebufferingStartedEvent
            );
            nativePlayer.off(
                BUFFERING_ENDED,
                boundHandlers.rebufferingEndedEvent
            );
            nativePlayer.off(
                SUBTITLE_SELECTED_TRACK_CHANGED,
                boundHandlers.subtitleChangedEvent
            );
            nativePlayer.off(
                AUDIO_SELECTED_TRACK_CHANGED,
                boundHandlers.audioChangedEvent
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.multivariantPlaylistFetchedEvent
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_LOADED,
                boundHandlers.successfulPlaylistLoad
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_REQUEST,
                boundHandlers.multivariantPlaylistRequest
            );
            nativePlayer.off(
                MULTIVARIANT_PLAYLIST_FALLBACK,
                boundHandlers.multivariantPlaylistFallback
            );
            nativePlayer.off(
                VARIANT_LOADED,
                boundHandlers.variantPlaylistFetchedEvent
            );
            nativePlayer.off(
                CHUNK_LOADED,
                boundHandlers.mediaSegmentFetchedEvent
            );
            nativePlayer.off(
                DRM_LICENSE_RECEIVED,
                boundHandlers.drmKeyFetchedEvent
            );
            nativePlayer.off(MEDIA_ERROR, boundHandlers.playbackErrorEvent);
            nativePlayer.off(
                INTERSTITIAL_SESSION_REQUESTED,
                boundHandlers.adPodRequested
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_FETCHED,
                boundHandlers.adPodFetched
            );
            nativePlayer.off(
                INTERSTITIAL_MULTIVARIANT_FETCHED,
                boundHandlers.adMultivariantFetched
            );
            nativePlayer.off(
                INTERSTITIAL_VARIANT_FETCHED,
                boundHandlers.adVariantFetched
            );
            nativePlayer.off(
                INTERSTITIAL_ASSET_STARTED,
                boundHandlers.adPlaybackStarted
            );
            nativePlayer.off(
                INTERSTITIAL_ASSET_FINISHED,
                boundHandlers.adPlaybackEnded
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_STARTED,
                boundHandlers.adPodStarted
            );
            nativePlayer.off(
                INTERSTITIAL_SESSION_FINISHED,
                boundHandlers.adPodEnded
            );
            nativePlayer.off(BEACON_ERROR, boundHandlers.adBeaconError);
            nativePlayer.off(
                INTERSTITIAL_SESSION_REQUESTED_ERROR,
                boundHandlers.adRequestedError
            );
        }
    }

    /**
     *
     * @access protected
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders - The array DRM providers from the playlist service
     * @desc Sets an array of DrmProviders
     * @returns {Promise<Void>} A promise that when resolved indicates the DRM providers have been set
     *
     */
    async setDrmProviders(drmProviders) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        this.drmProviders = drmProviders;
        this.nativePlayer.drm.configuration =
            this.getDrmConfiguration(drmProviders);
    }

    /**
     *
     * @access protected
     * @desc Gets a snapshot of information about media playback.
     * @throws {ServiceException}
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by `PlaybackTelemetryDispatcher.recordStreamSample`
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    getPlaybackMetrics() {
        const {
            currentTimeAsPdt,
            isLive,
            quality,
            currentTime,
            seekableRange = {},
            metrics = {}
        } = this.nativePlayer;

        const { playerMetrics = {} } = metrics;
        const { throughput, bufferedRange = {} } = playerMetrics.playback || {};
        const { currentKbps, averageBitrate, peakBitrate } = quality || {};

        const bufferSegmentDuration = bufferedRange.end - currentTime;
        const currentInterstitial = this.nativePlayer.currentInterstitial;

        if (currentInterstitial) {
            this.adData.adMetadata.adPlayheadPosition =
                currentInterstitial.position
                    ? Math.floor(currentInterstitial.position)
                    : 0;

            if (currentInterstitial.currentAsset) {
                const adMediaId = currentInterstitial.currentAsset.mediaId;

                if (adMediaId) {
                    this.adData.adMetadata.adSlotData = getSafe(
                        () => this.adData.data[adMediaId].adSlotData
                    );
                }
            }
        }

        let playheadProgramDateTime = null;
        let seekableRangeEndProgramDateTime = null;
        let isLiveEdge = null;

        if (isLive) {
            playheadProgramDateTime = currentTimeAsPdt;
            seekableRangeEndProgramDateTime = seekableRange.pdtEnd;
            isLiveEdge = this.nativePlayer.isLiveEdge;
        }

        return new PlaybackMetrics({
            adMetadata: this.adData ? this.adData.adMetadata : undefined,
            currentBitrate: currentKbps,
            currentPlayhead: currentTime,
            currentBitrateAvg: averageBitrate,
            currentBitratePeak: peakBitrate,
            currentThroughput: throughput,
            playheadProgramDateTime,
            seekableRangeEndProgramDateTime,
            isLiveEdge,
            bufferSegmentDuration,
            maxAllowedVideoBitrate: this.getMaxAllowedVideoBitrate()
        });
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {String}
     *
     */
    getCdnName() {
        const cdnName = getSafe(() => this.qos.cdnName);

        return Check.string(cdnName) ? cdnName : 'null';
    }

    /**
     *
     * @access protected
     * @since 15.1.0
     * @returns {Number}
     *
     */
    getMaxAllowedVideoBitrate() {
        const variants = getSafe(() => this.nativePlayer.quality.profiles, []);
        const maxVariant = variants[variants.length - 1] || {};

        return maxVariant.peakBitrate || 0;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {Object}
     *
     */
    getHeartbeatData() {
        return this.nativePlayer.heartbeat;
    }

    // #endregion

    // #region private

    /**
     *
     * @access private
     * @since 4.2.0
     * @param {Object} adEngineData
     * @desc overwrites local adEngine object in playbackSession.prepare()
     * @note used in xhrCallbacks for bam-hls and other platforms that need to reuse adEngine data
     *
     */
    setAdEngineData(adEngineData) {
        super.setAdEngineData(adEngineData);

        const { ssess } = adEngineData;

        this.nativePlayer.drm.setXhrConfigProvider(() => ({
            headers: {
                adEngine: { ssess }
            }
        }));
    }

    /**
     *
     * @access private
     * @desc Resets the `playlistUri`.
     *
     */
    resetSource() {
        this.playlistUri = '';
    }

    /**
     *
     * @access private
     * @desc Resets the DRM providers
     *
     */
    resetDrmProviders() {
        this.drmProviders = [];
        this.nativePlayer.drm.configuration = null;
    }

    /**
     *
     * @access private
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @desc Builds and returns a KeySystem object from a given drmProvider
     * @returns {Object}
     *
     */
    getKeySystem(drmProvider) {
        const keySystem = drmProvider.type;
        const licenseRequestUri = drmProvider.licenseRequestUri;
        const licenseRequestHeaders =
            drmProvider.processLicenseRequestHeaders();

        const providerImplementations = {
            [DrmType.FAIRPLAY]: {
                keySystem: DrmType.FAIRPLAY,
                urn: DustUrnReference.services.drm.drmClient
                    .getFairPlayCertificate,
                getCertificate: () => drmProvider.getFairPlayCertificate()
            },
            [DrmType.WIDEVINE]: {
                keySystem: DrmType.WIDEVINE,
                urn: DustUrnReference.services.drm.drmClient
                    .getWidevineCertificate,
                getCertificate: () => drmProvider.getWidevineCertificate()
            }
        };

        const providerImplementation = providerImplementations[keySystem];

        const keySystemObj = {
            keySystem,
            licenseRequestUri,
            licenseRequestHeaders: Object.keys(licenseRequestHeaders).map(
                (item) => ({
                    name: item,
                    value: licenseRequestHeaders[item]
                })
            ),
            /**
             *
             * @note bam-hls calls `Promise.resolve(this._keySystemConfig.serverCertificate)`
             * where serverCertificate is a reference to this function, in EME.js.
             * We need to use an IIFE here so we setup a promise since they are not executing this function
             * only expecting a Promise(cert), Promise(undefined), cert or undefined
             *
             */
            serverCertificate: Promise.resolve(undefined)
        };

        if (providerImplementation) {
            const { getCertificate } = providerImplementation;

            keySystemObj.serverCertificate = (async () => {
                return await getCertificate();
            })();
        }

        return keySystemObj;
    }

    /**
     *
     * @access private
     * @param {SDK.Drm.DrmProvider} drmProviders - The array DRM providers from the playlist service
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {{ Array }} The DRM configuration object wrapping an array of keySystems
     *
     */
    getDrmConfiguration(drmProviders) {
        const keySystems = drmProviders.map((item) => this.getKeySystem(item));

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when audio stream changes.
     *
     */
    audioChangedEvent(eventData = {}) {
        const { audioChannels, audioCodec, audioLanguage, audioName } =
            eventData || {};

        const parsedChannels = parseInt(audioChannels, 10);

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistAudioChannels = parsedChannels;
        this.heartbeatData.playlistAudioCodec = audioCodec;
        this.heartbeatData.playlistAudioLanguage = audioLanguage;
        this.heartbeatData.playlistAudioName = audioName;

        this.onAudioChanged({
            audioChannels,
            audioCodec,
            audioLanguage,
            audioName
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when buffering starts.
     *
     */
    rebufferingStartedEvent(eventData = {}) {
        // Used to calculate duration for rebufferingEndedEvent
        this.rebufferingDuration = new Date();

        this.onRebufferingStarted(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when buffering ends.
     *
     */
    rebufferingEndedEvent(eventData = {}) {
        const duration = Date.now() - this.rebufferingDuration;

        this.onRebufferingEnded({
            ...eventData,
            duration
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback has been initialized.
     * @note avoids NaN
     *
     */
    playbackInitializedEvent(eventData = {}) {
        const { audio, variant = {}, subtitle } = eventData;

        // Cache values to be used for heartbeat event.
        this.heartbeatData = {
            playlistAudioChannels: variant.audioChannels,
            playlistAudioCodec: variant.audioCodec,
            playlistAudioLanguage: audio.language,
            playlistAudioName: audio.name,
            playlistSubtitleLanguage: subtitle.language,
            playlistSubtitleName: subtitle.name,
            subtitleVisibility: subtitle.forced // why is visibility is referenced through forced property?
            // subtitles could be not forced but still visible
        };

        this.onPlaybackInitialized({
            ...eventData,
            variant: new PlaybackVariant(variant),
            audio: new AudioRendition(audio),
            subtitle: new SubtitleRendition(subtitle),
            streamUrl: this.currentStreamUrl
        });
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback seeks starts.
     * @note avoids NaN
     *
     */
    playbackSeekStartedEvent(eventData = {}) {
        this.onPlaybackSeekStarted(eventData);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback seeks ends.
     * @note avoids NaN
     *
     */
    playbackSeekEndedEvent(eventData = {}) {
        this.onPlaybackSeekEnded(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when the subtitle changes.
     *
     */
    subtitleChangedEvent(eventData = {}) {
        const { subtitleLanguage, subtitleName, subtitleVisibility } =
            eventData || {};

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistSubtitleLanguage = subtitleLanguage;
        this.heartbeatData.playlistSubtitleName = subtitleName;
        this.heartbeatData.subtitleVisibility = subtitleVisibility;

        this.onSubtitleChanged({
            subtitleLanguage,
            subtitleName,
            subtitleVisibility
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when the bitrate changes.
     *
     */
    bitrateChangedEvent(eventData = {}) {
        const isAdPlaying = getSafe(
            () => this.nativePlayer.currentInterstitial.currentAsset
        );

        const adMetadata =
            isAdPlaying && this.adData.adMetadata ? this.adData.adMetadata : {};

        this.onBitrateChanged({
            ...adMetadata,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback is ready.
     *
     */
    playbackReadyEvent(eventData = {}) {
        this.onPlaybackReady(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback starts.
     *
     */
    playbackStartedEvent(eventData = {}) {
        this.onPlaybackStarted(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback gets paused.
     *
     */
    playbackPausedEvent(eventData = {}) {
        this.onPlaybackPaused(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback gets resumed.
     *
     */
    playbackResumedEvent(eventData = {}) {
        this.onPlaybackResumed(eventData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} [eventData={}]
     *
     */
    multivariantPlaylistFetchedEvent(eventData = {}) {
        const { serverRequest: server = {} } = eventData;

        const serverRequest = new ServerRequest({
            ...server,
            serverIp: server.serverIP
        });

        this.onMultivariantPlaylistFetched({
            ...eventData,
            serverRequest
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} eventData
     *
     */
    variantPlaylistFetchedEvent(eventData) {
        const { serverRequest: server = {} } = eventData || {};

        const serverRequest = new ServerRequest({
            ...server,
            serverIp: server.serverIP
        });

        this.onVariantPlaylistFetched({
            ...eventData,
            serverRequest
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Log is made only when there is an error.
     *
     */
    mediaSegmentFetchedEvent(eventData = {}) {
        const { serverRequest: server = {} } = eventData;

        if (Check.not.assigned(server.error)) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...server,
            serverIp: server.serverIP
        });

        this.onMediaSegmentFetched({
            ...eventData,
            serverRequest
        });
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} [eventData={}]
     *
     */
    drmKeyFetchedEvent(eventData = {}) {
        const { serverRequest: server = {} } = eventData;

        const {
            status,
            serverIp: serverIP,
            cdnName,
            networkType,
            timeToFirstByte,
            error,
            method,
            host,
            path,
            statusCode,
            roundTripTime
        } = server;

        this.onDrmKeyFetched({
            ...eventData,
            serverRequest: new ServerRequest({
                method,
                host,
                path,
                statusCode,
                roundTripTime,
                status,
                serverIP,
                cdnName,
                networkType,
                timeToFirstByte,
                error
            })
        });
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {Object} eventData
     * @note Handles the MANIFEST_LOADED event to update tracking when successful playlist loads.
     *
     */
    successfulPlaylistLoad(eventData) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.currentStreamUrl = playlist.url;

            this.onSuccessfulPlaylistLoad(playlist);
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_REQUEST (to be renamed) event to update tracking when successful playlist loads.
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    multivariantPlaylistRequest(eventData) {
        const { playbackStartupEventData } = this;
        const { assetType, playlist } = eventData || {};

        // Only track certain presentation type requests
        if (
            [
                PresentationType.bumper,
                PresentationType.ad,
                PresentationType.main
            ].includes(assetType)
        ) {
            this.currentMultivariantPlaylistRequest = eventData;
        }

        // This check is necessary, due to some asset types (Bumpers) won't have tracking data.
        if (playlist && playlist.tracking) {
            const qosData = getSafe(() => playlist.tracking.qos);
            const cdnVendor = qosData ? qosData.cdnVendor : null;

            this.qos = qosData;

            playbackStartupEventData.fallbackAttempt(cdnVendor);

            if (playbackStartupEventData.isCdnFallback) {
                playbackStartupEventData.setQosData(qosData);
                this.onPlaybackReattempt(playbackStartupEventData);
            }
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} eventData
     * @note Handles the MEDIA_ERROR event
     *
     */
    playbackErrorEvent(eventData) {
        const { fatal = false, errorSource } = eventData || {};
        const { message: errorDetail } = errorSource || {};

        const { seekableRange, currentTime } = this.getPlaybackMetrics();
        const { isBehindLive, currentPDT: segmentPosition } = this.nativePlayer;
        const playheadPosition = this.normalizePlayhead(
            Math.floor(currentTime * 1000)
        );

        const { data: adDataData = {}, adMetadata } = this.adData;
        const { adPodData, adPodPlacement } = adMetadata;

        const adMediaId = this.getAdMediaId();
        const adSlotData =
            adDataData?.[adMediaId]?.adSlotData || defaultAdMetadata.adSlotData;

        let liveLatencyAmount;

        if (isBehindLive && seekableRange.pdtEnd - segmentPosition > 0) {
            liveLatencyAmount = seekableRange.pdtEnd - segmentPosition;
        }

        const errorName = mapHiveToQoeErrorCodes(errorSource);

        if (fatal) {
            // emit event to application
            this.emit(PublicEvents.MediaFailure, errorName);

            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: fatal,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.error,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adPodData,
                    adPodPlacement,
                    adSlotData
                });

                this.playbackEndedEvent({ ...eventData, errorName });

                this.removeListener(this.listener);
            }
        } else {
            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: false,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.warn,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adPodData,
                    adPodPlacement,
                    adSlotData
                });
            }
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_FALLBACK event to update tracking when successful playlist loads.
     * @note Renamed to 'Multivariant' in recent inclusive language efforts
     *
     */
    multivariantPlaylistFallback(eventData) {
        const { playlist } = eventData || {};

        if (playlist && playlist.tracking) {
            this.playbackStartupEventData.playbackError =
                QoePlaybackError.unknown;
            this.playbackStartupEventData.fallbackFailed();
        }
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @returns {Object}
     *
     */
    getCurrentVariant() {
        const { variants = [], chosenVariant } = getSafe(
            () => this.nativePlayer.metrics.playerMetrics.playback,
            {}
        );

        const currentVariant = variants[chosenVariant] || {};

        const maxVariant = variants[variants.length - 1] || {};

        const {
            encodedFrameRate: playlistFrameRate,
            peakBitrate: maxAllowedVideoBitrate
        } = maxVariant;

        const {
            peakBitrate: bitrate,
            encodedFrameRate: frameRate,
            audioCodecs,
            averageBitrate,
            videoCodec,
            videoRangeSubtype
        } = currentVariant;

        if (this.mediaStartBitrate === null) {
            this.mediaStartBitrate = averageBitrate;
        }

        return {
            resolution: getResolutionString(currentVariant),
            bitrate,
            averageBitrate,
            frameRate,
            audioCodec: Check.array(audioCodecs) ? audioCodecs[0] : undefined,
            videoCodec,
            videoRange: videoRangeSubtype,
            maxAllowedVideoBitrate,
            playlistResolution: getResolutionString(maxVariant),
            playlistFrameRate
        };
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @returns {Object}
     *
     */
    getAudioRendition() {
        const { averageBitrate, channels, codec, name, language } = getSafe(
            () => this.nativePlayer.metrics.playerMetrics.playback.audio,
            {}
        );

        return {
            audioRendition: new AudioRendition({ name, language }),
            averageBitrate,
            audioChannels: channels,
            audioCodec: codec
        };
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @returns {SDK.Services.Media.SubtitleRendition}
     *
     */
    getSubtitleRendition() {
        const { subtitles: subtitleRendition = {} } = getSafe(
            () => this.nativePlayer.metrics.playerMetrics.playback,
            {}
        );

        return new SubtitleRendition(subtitleRendition);
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED` event
     *
     */
    adPodRequested(eventData) {
        const { interstitialMetadata } = eventData;
        const { placement, midRollIndex: midrollIndex } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];

        if (!podPosition) {
            return;
        }

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.onAdPodRequested({
            adPodPlacement
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_FETCHED` event
     *
     */
    adPodFetched(eventData) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const { placement = '' } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];
        const fetchStatus = request.fetchStatus || '';

        if (!podPosition) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method.toLowerCase()],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status:
                FetchStatus[fetchStatus.toLowerCase()] || FetchStatus.completed,
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const presentation =
            placement === InsertionPointPlacement.BUMPER_PREROLL
                ? PresentationType.bumper
                : PresentationType.ad;

        if (this.presentationType !== presentation) {
            this.changePresentationType(presentation);
        }

        this.processAdPodData(eventData);

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdPodFetched({
            adPodData,
            adPodPlacement,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 21.0.0
     * @param {Object} podData
     * @returns {Object} The processed podData from the ad pod response stored in the adData private property.
     * @example
     * {
     *     data: {
     *         'someMediaId': {
     *             adSubtitleData: object;
     *             adSlotData: object;
     *             mediaUrl: string;
     *         };
     *     };
     *     adMetadata: {
     *         adPodData: object;
     *         adPodPlacement: object;
     *         adSlotData: object;
     *     }
     * }
     *
     */
    processAdPodData(podData) {
        const { interstitialMetadata = {}, response: adPodResponse = {} } =
            podData;
        const { pods = [] } = adPodResponse;
        const {
            placement = '',
            midRollIndex: midrollIndex,
            totalDuration: adPodPlannedLength,
            slotCount: plannedSlotCount
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];

        this.adData.adMetadata = defaultAdMetadata;

        this.adData.adMetadata.adPodData = {
            plannedSlotCount,
            plannedLength: Check.number(adPodPlannedLength)
                ? Math.floor(adPodPlannedLength)
                : undefined
        };

        this.adData.adMetadata.adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.adData.data = pods.reduce((data, pod) => {
            const { ads = [] } = pod;

            return ads.reduce((slotData, adSlot) => {
                const {
                    creative: { video: adCreative = {} } = {},
                    'slot-number': slotNumber
                } = adSlot;
                const {
                    'media-id': mediaId,
                    'media-url': mediaUrl,
                    'duration-ms': plannedLength
                } = adCreative;

                return {
                    ...slotData,
                    [mediaId]: {
                        adSubtitleData: {
                            subtitleVisibility: false
                        },
                        adSlotData: {
                            adMediaId: mediaId,
                            plannedLength: Check.number(plannedLength)
                                ? Math.floor(plannedLength)
                                : undefined,
                            slotNumber: slotNumber || 0
                        },
                        mediaUrl
                    }
                };
            }, data);
        }, {});
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_MULTIVARIANT_FETCHED` event
     *
     */
    adMultivariantFetched(eventData) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];
        const fetchStatus = request.fetchStatus || '';

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method.toLowerCase()],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status:
                FetchStatus[fetchStatus.toLowerCase()] || FetchStatus.completed,
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const { adSlotData } = this.getAdDataByMediaId({
            adMediaId,
            plannedLength,
            slotNumber
        });

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdMultivariantFetched({
            adPodData,
            adPodPlacement,
            adSlotData,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} options
     * @param {String} options.adMediaId
     * @param {Number} [options.plannedLength]
     * @param {Number} [options.slotNumber]
     * @returns {Object}
     *
     */
    getAdDataByMediaId(options) {
        const { adMediaId, plannedLength, slotNumber } = options;

        let adDataData = this.adData.data[adMediaId];

        if (!adDataData) {
            adDataData = {
                adSubtitleData: {
                    subtitleVisibility: false
                },
                adSlotData: {
                    adMediaId,
                    plannedLength: Check.number(plannedLength)
                        ? Math.floor(plannedLength)
                        : undefined,
                    slotNumber: slotNumber || 0
                }
            };
        }

        this.adData.data[adMediaId] = adDataData;

        return adDataData;
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_VARIANT_FETCHED` event
     *
     */
    adVariantFetched(eventData) {
        const subtitleVisibility = this.nativePlayer.areSubtitlesEnabled;

        const {
            serverRequest: request,
            startTimestamp,
            assetData,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase()];
        const fetchStatus = request.fetchStatus || '';

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method.toLowerCase()],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status:
                FetchStatus[fetchStatus.toLowerCase()] || FetchStatus.completed,
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const {
            mediaSegmentType,
            codec,
            range,
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            channels,
            language,
            name
        } = assetData;

        const adDataData = this.getAdDataByMediaId({
            adMediaId,
            plannedLength,
            slotNumber
        });

        const { adSlotData } = adDataData;

        let { adVideoData, adAudioData, adSubtitleData } = adDataData;

        let segmentType;

        if (mediaSegmentType) {
            switch (mediaSegmentType) {
                case 'video':
                    segmentType = MediaSegmentType.video;
                    adVideoData = {
                        playlistVideoCodec: codec,
                        playlistVideoRange: range,
                        videoBitrate: bitrate,
                        videoAverageBitrate: averageBitrate,
                        playlistResolution: resolution,
                        playlistFrameRate: frameRate
                    };

                    adDataData.adVideoData = adVideoData;
                    break;

                case 'audio':
                    segmentType = MediaSegmentType.audio;
                    adAudioData = {
                        playlistAudioChannels: parseInt(channels, 10),
                        playlistAudioCodec: codec,
                        playlistAudioLanguage: language,
                        playlistAudioName: name
                    };

                    adDataData.adAudioData = adAudioData;
                    break;

                case 'subtitles':
                    segmentType = MediaSegmentType.subtitle;
                    adSubtitleData = {
                        subtitleVisibility,
                        playlistSubtitleLanguage: language,
                        playlistSubtitleName: name
                    };

                    adDataData.adSubtitleData = adSubtitleData;
                    break;

                default:
                    this.logger.error(
                        `Unknown mediaSegmentType: ${mediaSegmentType}`
                    );
                    break;
            }
        }

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdVariantFetched({
            adPodData,
            adPodPlacement,
            adSlotData,
            serverRequest,
            mediaSegmentType: segmentType,
            adVideoData,
            adAudioData,
            adSubtitleData,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_ASSET_STARTED` event
     *
     */
    adPlaybackStarted(eventData = {}) {
        const {
            asset = {},
            currentInterstitial = this.nativePlayer.currentInterstitial
        } = eventData;

        const { mediaId: adMediaId, type } = asset;

        const { placement = '' } = currentInterstitial;
        const podPosition = PodPosition[placement.toLowerCase()];

        let presentation = PresentationType.ad;

        if (type === PresentationType.slug) {
            this.changePresentationType(type);
        }

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            presentation = PresentationType.bumper;
        }

        if (this.presentationType !== presentation) {
            this.changePresentationType(presentation);
        }

        if (!podPosition || !adMediaId) {
            return;
        }

        const data = this.adData.data[adMediaId];

        this.adData.adMetadata.adSlotData = data.adSlotData;

        this.onAdPlaybackStarted({
            adPodPlacement: this.adData.adMetadata.adPodPlacement,
            adPodData: this.adData.adMetadata.adPodData,
            adSlotData: this.adData.adMetadata.adSlotData,
            adVideoData: data.adVideoData,
            adAudioData: data.adAudioData,
            adSubtitleData: data.adSubtitleData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_ASSET_FINISHED` event
     *
     */
    adPlaybackEnded(options) {
        const {
            playbackError: errorName,
            playbackErrorDetail: errorMessage,
            cause: endCause,
            asset
        } = options || {};

        const { placement = '' } = this.nativePlayer.currentInterstitial;
        const { mediaId: adMediaId } = asset || {};

        let cause = endCause;
        let adErrorData;
        let adVideoData;
        let adAudioData;
        let adSubtitleData;

        const podPosition = PodPosition[placement.toLowerCase()];

        if (!podPosition || !adMediaId) {
            return;
        }

        if (!cause) {
            cause = PlaybackExitedCause.playedToEnd;
        }

        if (errorName || errorMessage) {
            adErrorData = {
                errorName,
                errorMessage
            };
        }

        if (this.adData.data[adMediaId]) {
            const data = Object.assign({}, this.adData.data[adMediaId]);

            adVideoData = data.adVideoData;
            adAudioData = data.adAudioData;
            adSubtitleData = data.adSubtitleData;
        }

        const { adPodData, adPodPlacement, adSlotData } =
            this.adData.adMetadata;

        this.onAdPlaybackEnded({
            adPodPlacement,
            adPodData,
            adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData,
            cause,
            adErrorData
        });

        delete this.adData.data[adMediaId];
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_STARTED` event
     *
     */
    adPodStarted(eventData) {
        const { placement = '' } = eventData;

        let presentation = PresentationType.ad;

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            presentation = PresentationType.bumper;
        }

        this.changePresentationType(presentation);
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_ASSET_FINISHED` event
     *
     */
    adPodEnded() {
        this.changePresentationType();
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED_ERROR` event
     *
     */
    adRequestedError(eventData) {
        const { serverRequest } = eventData || {};
        const { host, path } = serverRequest || {};

        this.changePresentationType(PresentationType.unknown);

        this.onAdRequestedError({
            error: QoePlaybackError.adServerError,
            errorLevel: ErrorLevel.warn,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `BEACON_ERROR` event
     *
     */
    adBeaconError(eventData = {}) {
        const { serverRequest } = eventData;
        const { host, path } = serverRequest || {};

        this.onAdBeaconError({
            error: QoePlaybackError.adBeaconError,
            errorLevel: ErrorLevel.info,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {String} [presentation]
     * @desc Updates PresentationType
     *
     */
    changePresentationType(presentation) {
        const areSubtitlesEnabled = this.nativePlayer.areSubtitlesEnabled;

        const {
            audioRendition: audio,
            averageBitrate: audioBitrate,
            audioChannels
        } = this.getAudioRendition();

        const { bitrate: videoBitrate, averageBitrate: videoAverageBitrate } =
            this.getCurrentVariant();

        const subtitle = this.getSubtitleRendition();

        this.presentationType = presentation || PresentationType.main;

        this.onPresentationTypeChanged({
            presentationType: this.presentationType,
            areSubtitlesEnabled,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            audioChannels: parseInt(audioChannels, 10),
            audio,
            subtitle
        });
    }

    /**
     *
     * @access private
     * @since 21.0.0
     * @desc Get the ad mediaId from the ad data based on the currentMultivariantPlaylistRequest value
     * @returns {String|undefined}
     *
     */
    getAdMediaId() {
        const { data: adDataData = {} } = this.adData;

        const adMediaId = Object.keys(adDataData).find((key) =>
            this.currentMultivariantPlaylistRequest?.url?.includes(
                adDataData?.[key]?.mediaUrl
            )
        );

        return adMediaId;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} exception - the exception object that dss web throws
     * @desc Converts a dss web error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given dss web error
     *
     */
    constructErrorCaseFromDssWebError(exception) {
        const reasons = [
            new ErrorReason(exception.error, exception.errorDetail)
        ];

        return new ServiceException({ reasons, exceptionData: exception });
    }

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Media.PlayerAdapter.DssWebPlayerAdapter';
    }

    // #endregion
}
