/**
 *
 * @module receipt
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ReceiptCredentials, {
    ReceiptData
} from '../services/purchase/receiptCredentials';

/**
 *
 *
 */
export default class Receipt {
    /**
     *
     * @access private
     * @type {SDK.Services.Purchase.ReceiptCredentials}
     * @desc contains the receipt information necessary to identify this purchase
     *
     */
    public receiptCredentials: ReceiptCredentials;

    /**
     *
     * @param {String} store
     * @param {ReceiptData} receipt
     *
     */
    public constructor(store: string, receipt: ReceiptData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                store: Types.nonEmptyString,
                receipt: Types.object()
            };

            typecheck(this, params, arguments);
        }

        this.receiptCredentials = new ReceiptCredentials(store, receipt);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Purchase.Receipt';
    }
}
