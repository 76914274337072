/**
 *
 * @module device
 * @desc Detect and classify some device specific information via userAgent.
 * Detects browser, browser version, os, form-factor.
 *
 */

/* eslint-disable guard-for-in */

import RegExes from './device.regexs';

const CACHE = {};
const REGEXES_OTHER = RegExes.constants.OTHER;
const REGEXES_VERSION = RegExes.constants.VERSION;
const REGEXES_FORMFACTORS = RegExes.constants.FORMFACTORS;

/**
 *
 * @access private
 * @function safeArray
 * @param {Array} ar
 * @param {Number} index
 * @returns {*}
 *
 */
function safeArray(ar, index) {
    return ar && index ? ar[index] : ar;
}

/**
 *
 * @access private
 * @function version
 * @param {String} vstr - The version string to parse.
 * @returns {string}
 *
 */
function version(vstr) {
    const varr = vstr.split(/[._]/).slice(0, 3);

    if (vstr && varr.length < 2) {
        varr.push(0);
    }

    return varr.join('.');
}

/**
 *
 * @access private
 * @function windowsVersion
 * @param {String} vstr - The version string to parse.
 * @returns {string}
 *
 */
function windowsVersion(vstr) {
    const versionMap = {
        'NT 6.0': 'vista',
        'NT 6.1': '7.0',
        'NT 6.2': '8.0',
        'NT 6.3': '8.1',
        'NT 6.4': '10.0',
        'NT 10.0': '10.0'
    };

    return versionMap[vstr] || vstr;
}

/**
 *
 * @access public
 * @constructor
 * @param {String} [userAgent]
 *
 */
function Device(userAgent) {
    userAgent = userAgent || window.navigator.userAgent;

    if (CACHE[userAgent]) {
        return CACHE[userAgent];
    }

    if (!(this instanceof Device)) {
        return new Device(userAgent);
    }

    CACHE[userAgent] = this;

    this.userAgent = userAgent;
    this.is = {};
    this.details = this.getDeviceDetails();
    this.platform = this.getPlatform();
    this.platformVersion = this.getPlatformVersion();
    this.browser = this.getBrowser();
    this.browserVersion = this.getBrowserVersion();
    this.formFactor = this.getFormFactor();
    this.browserLanguage = this.getBrowserLanguage();
}

Device.prototype = {
    /**
     *
     * @since 10.0.0
     * @returns {String} The browser language.
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#specifying-languages
     *
     */
    getBrowserLanguage() {
        let lang;

        const langPropKeys = [
            'language',
            'browserLanguage',
            'systemLanguage',
            'userLanguage'
        ];

        for (let i = 0; i < langPropKeys.length; i++) {
            lang = window.navigator[langPropKeys[i]];

            if (lang) {
                return lang;
            }
        }

        return null;
    },

    /**
     *
     * @returns {Object} The device details.
     *
     */
    getDeviceDetails() {
        let details = null;

        for (let i = 0; i < RegExes.devices.length; i++) {
            const device = RegExes.devices[i];

            for (let j = 0; j < device.regExes.length; j++) {
                const re = device.regExes[j];
                let match = this.match(re, device.groupIndex);

                if (match) {
                    if (device.done) {
                        match = device.done(match);
                    }

                    details = {
                        family: device.family,
                        name: match.toLowerCase().replace(/(\s|-|_)/gi, '')
                    };

                    break;
                }
            }

            if (details) {
                break;
            }
        }

        return (
            details || {
                family: 'default',
                name: 'default'
            }
        );
    },
    /**
     *
     * @returns {String} The platform value.
     *
     */
    getPlatform() {
        return this.test(RegExes.platforms) || REGEXES_OTHER;
    },
    /**
     *
     * @returns {String} The platform version
     *
     */
    getPlatformVersion() {
        let platformVersion = '';

        if (this.platform && this.platform !== REGEXES_OTHER) {
            platformVersion =
                this.match(
                    RegExes.platformVersion[this.platform.toUpperCase()],
                    2
                ) || REGEXES_VERSION;
        }

        switch (true) {
            case this.is.android:
            case this.is.ios:
            case this.is.macintosh:
                platformVersion = version(platformVersion);
                break;
            case this.is.windows:
            case this.is.windowsphone:
                platformVersion = windowsVersion(platformVersion);
                break;
        }

        return platformVersion;
    },
    /**
     *
     * @returns {String} The browser.
     *
     */
    getBrowser() {
        this.test(RegExes.browserCore);

        return this.test(RegExes.browser) || REGEXES_OTHER;
    },
    /**
     *
     * @returns {String} The browser version
     *
     */
    getBrowserVersion() {
        let browserVersion = '';

        if (this.browser) {
            const re = RegExes.browser[this.browser.toUpperCase()];

            if (re) {
                if (Array.isArray(re)) {
                    for (let i = 0; i < re.length; i++) {
                        browserVersion = this.match(re[i], 1);

                        if (browserVersion !== '') {
                            break;
                        }
                    }
                } else {
                    browserVersion = this.match(re, 1);
                }
            }
        }

        return version(browserVersion);
    },
    /**
     *
     * @returns {String} The form factor
     * @todo different tests per platform?
     *
     */
    getFormFactor() {
        let formFactorTest = '';
        const formFactors = REGEXES_FORMFACTORS;

        if (this.is.msie) {
            formFactorTest = RegExes.formFactor.ms;
        } else {
            formFactorTest = RegExes.formFactor.default;
        }

        return formFactors[this.test(formFactorTest)] || formFactors.other;
    },
    /**
     *
     * @desc Helper method for safely running regex matches against the instances userAgent.
     * @param {RegExp} regex -Regex to match.
     * @param {Number} [index] - Optional captured match to return.
     * If no index is supplied, the entire match set is return as an array.
     * @returns {*} String when used with index, or array of matches.
     *
     */
    match(regex, index) {
        const results = safeArray(regex.exec(this.userAgent), index) || '';

        return results;
    },
    /**
     *
     * @param {Object} reMap
     * @returns {*}
     *
     */
    test(reMap) {
        const ua = this.userAgent;

        let result = '';
        let key;
        let check;

        for (key in reMap) {
            const re = reMap[key];

            if (Array.isArray(re)) {
                for (let i = 0; i < re.length; i++) {
                    check = re[i].test(ua);

                    if (check) {
                        break;
                    }
                }
            } else {
                check = re.test(ua);
            }

            key = key.toLowerCase();

            this.is[key] = this.is[key] || (check && !result);

            if (check && !result) {
                result = key;
            }
        }

        return result;
    },
    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Providers.Device';
    }
};

/**
 *
 * @access protected
 *
 */
export default Device;
