/**
 *
 * @module drmManagerConfiguration
 *
 */

import DrmClientConfiguration from '../drm/drmClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for DRM.
 *
 */
export default class DrmManagerConfiguration extends ServiceConfiguration<DrmClientConfiguration> {
    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.DrmManagerConfiguration';
    }

    // #endregion
}
