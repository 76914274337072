/**
 *
 * @module entry
 * @desc {SDK.Ripcut} entry point.
 *
 */

import ImageFormat from './imageFormat';
import PNGCompression from './pngCompression';
import PVRTexture from './pvrTexture';
import PVRTextureQuality from './pvrTextureQuality';
import RipcutApi from './ripcutApi';
import RipcutManager from './ripcutManager';
import RipcutImageRequestMode from './ripcutImageRequestMode';
import RipcutLabelConfigurationRequestMode from './ripcutLabelConfigurationRequestMode';
import RipcutLabelPosition from './ripcutLabelPosition';
import ScalingAlgorithm from './scalingAlgorithm';

/**
 *
 * @typedef {Object} SDK.Ripcut
 *
 * @property {ImageFormat} ImageFormat
 * @property {PNGCompression} PNGCompression
 * @property {PVRTexture} PVRTexture
 * @property {PVRTextureQuality} PVRTextureQuality
 * @property {RipcutApi} RipcutApi
 * @property {RipcutManager} RipcutManager
 * @property {RipcutImageRequestMode} RipcutImageRequestMode
 * @property {RipcutLabelConfigurationRequestMode} RipcutLabelConfigurationRequestMode
 * @property {RipcutLabelPosition} RipcutLabelPosition
 * @property {ScalingAlgorithm} ScalingAlgorithm
 *
 */
export default {
    ImageFormat,
    PNGCompression,
    PVRTexture,
    PVRTextureQuality,
    RipcutApi,
    RipcutManager,
    RipcutImageRequestMode,
    RipcutLabelConfigurationRequestMode,
    RipcutLabelPosition,
    ScalingAlgorithm
};
