/**
 *
 * @module configurationClientHostParams
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc A model containing the ConfigurationClient's host params.
 *
 */
export default class ConfigurationClientHostParams {
    /**
     *
     * @param {String} baseUrl
     *
     */
    constructor(baseUrl) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                baseUrl: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access public
         * @type {Object}
         *
         */
        this.baseUrl = baseUrl;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.ConfigurationClientHostParams';
    }

    // #endregion
}
