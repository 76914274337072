/**
 *
 * @module nagraDrmProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md#nagra
 *
 */

import { Check } from '@dss/type-checking';

import Events from './../events';
import DrmProvider from './drmProvider';
import NagraCertificateStorage from './nagraCertificateStorage';
import DrmType from './../services/media/drmType';
import DrmClientEndpoint from './../services/drm/drmClientEndpoint';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import getSafe from '../services/util/getSafe';
import type DrmManager from './drmManager';
import type TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';
import type MediaItem from '../media/mediaItem';

const DustUrn = DustUrnReference.drm.nagraDrmProvider;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @since 4.4.0
 * @desc Nagra is Canal+ encryption
 *
 */
export default class NagraDrmProvider extends DrmProvider {
    /**
     *
     * @access private
     * @type {SDK.Drm.FairPlayCertificateStorage}
     * @desc references the Storage API to get/set a certificate
     *
     */
    private certificateStorage: NagraCertificateStorage;

    /**
     *
     * @param {Object} options
     * @param {SDK.Drm.DrmManager} options.drmManager
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {String} [options.endpointKey]
     *
     */
    public constructor(options: {
        drmManager: DrmManager;
        tokenManager: TokenManager;
        logger: Logger;
        mediaItem: MediaItem;
        endpointKey: keyof typeof DrmClientEndpoint;
    }) {
        super({
            ...options,
            type: DrmType.PRMNAGRA
        });

        this.certificateStorage = new NagraCertificateStorage({
            clientId: getSafe(() => this.drmManager.clientId),
            environment: getSafe(() => this.drmManager.environment),
            storage: getSafe(() => this.drmManager.storage),
            logger: this.logger
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private get individualizationUri() {
        return getSafe(
            () =>
                this.drmManager.config.client.endpoints[
                    DrmClientEndpoint.individualization
                ].href,
            ''
        );
    }

    /**
     *
     * @access private
     * @param {IGNORE-PARAMS}
     * @since 4.4.0
     * @desc Gets a certificate required for decrypting Nagra protected content. First we attempt to retrieve the
     * certificate from storage, if that does not exist we make a new request for a new certificate, store that and
     * then return the certificate.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<Object>}
     *
     */
    public async getNagraCertificate(): Promise<Uint8Array>;

    @apiMethodDecorator()
    public async getNagraCertificate(
        apiOptions?: unknown
    ): Promise<Uint8Array> {
        const { logTransaction } = apiOptions as ApiOptions;

        const { mediaItem } = this;

        try {
            const storedCertificate =
                await this.certificateStorage.getStoredCertificate();

            if (Check.assigned(storedCertificate)) {
                return storedCertificate;
            }

            const certificate = await this.drmManager.getNagraCertificate(
                mediaItem,
                logTransaction
            );

            await this.certificateStorage.storeCertificate(certificate);

            return await this.certificateStorage.getStoredCertificate();
        } catch (exception) {
            this.logger.error(
                this.toString(),
                'Nagra certificate request failure.'
            );
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.NagraDrmProvider';
    }

    // #endregion
}
