/**
 *
 * @module klarnaApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { KlarnaPaymentRequest, KlarnaPaymentRequestTypedef } from './typedefs';

import { PaymentRedirectResponse } from '../../services/commerce/typedefs';

import CommerceManager from '../commerceManager';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';
import getSafe from '../../services/util/getSafe';
import Logger from '../../logging/logger';

const DustUrn = DustUrnReference.commerce.klarnaApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

interface KlarnaApiOptions {
    commerceManager: CommerceManager;
    logger: Logger;
}

/**
 *
 * @access public
 * @since 8.0.0
 * @desc Provides ability to access Klarna data.
 *
 */
export default class KlarnaApi extends BaseApi {
    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Commerce.CommerceManager}
     *
     */
    private commerceManager: CommerceManager;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Commerce.CommerceManager} options.commerceManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: KlarnaApiOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceManager: Types.instanceStrict(CommerceManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceManager } = options;

        this.commerceManager = commerceManager;

        this.dustEnabled = getSafe(
            () => this.commerceManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.Klarna.KlarnaPaymentRequest>} request - Information used to submit a Klarna payment request.
     * @desc Submit a payment request through the Klarna payment service. Returns a paymentMethodId and redirectUrl used to complete the payment process.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.PaymentRedirectResponse>}
     *
     */
    public async submitPayment(
        request: KlarnaPaymentRequest
    ): Promise<PaymentRedirectResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(KlarnaPaymentRequestTypedef)
        }
    })
    public async submitPayment(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions;

        return await this.commerceManager.submitKlarnaPayment(
            request,
            logTransaction
        );
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.Klarna.KlarnaApi';
    }

    // #endregion
}
