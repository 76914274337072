/**
 *
 * @module audioType
 *
 */

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.AudioType
 * @desc An enumeration of possible user-preferred audio types. Used to
 * differentiate atmos in playback scenario name.
 *
 * @property {String} atmos - Since `4.18.0` - Playlist responses contain atmos audio variants.
 *
 * @example
 * AudioType.atmos // 'ATMOS'
 *
 */
export default {
    atmos: 'ATMOS'
};
