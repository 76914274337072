/**
 *
 * @module telemetryBufferConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

interface TelemetryBufferConfigurationOptions {
    batchLimit: number;
    disabled: boolean;
    maxBackOffRetrySeconds: number;
    minimumBatchSize: number;
    replyAfterFallback: number;
    queueLimit: number;
}

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Provides additional configuration information used by EventBuffer.
 *
 */
export default class TelemetryBufferConfiguration {
    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {Number}
     * @desc Max amount of events to post.
     *
     */
    private batchLimit: number;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {Boolean}
     * @desc If this is true then telemetry events will not be published.
     *
     */
    private disabled: boolean;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {Number}
     * @desc Max amount of seconds before allowing telemetry to be retried after a failure.
     *
     */
    private maxBackOffRetrySeconds: number;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {Number}
     * @dec Used by EventBuffer to only send events from the queue when it reached a minimum batch size.
     *
     */
    private minimumBatchSize: number;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {Number}
     * @desc Seconds to wait before scheduling next batch.
     *
     */
    private replyAfterFallback: number;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {Number}
     * @desc Max amount of items in the buffer queue.
     *
     */
    private queueLimit: number;

    /**
     *
     * @access protected
     * @param {Object} [options={}]
     * @param {Number} [options.batchLimit=20]
     * @param {Boolean} [options.disabled=false]
     * @param {Number} [options.maxBackOffRetrySeconds=120]
     * @param {Number} [options.minimumBatchSize=10]
     * @param {Number} [options.replyAfterFallback=30]
     * @param {Number} [options.queueLimit=200]
     *
     */
    public constructor(options?: TelemetryBufferConfigurationOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    batchLimit: Types.number,
                    disabled: Types.boolean,
                    maxBackOffRetrySeconds: Types.number,
                    minimumBatchSize: Types.number,
                    replyAfterFallback: Types.number,
                    queueLimit: Types.number.optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            batchLimit,
            disabled,
            maxBackOffRetrySeconds,
            minimumBatchSize,
            replyAfterFallback,
            queueLimit
        } = options || {};

        this.batchLimit = batchLimit || 20;
        this.disabled = disabled || false;
        this.maxBackOffRetrySeconds = maxBackOffRetrySeconds || 120;
        this.minimumBatchSize = minimumBatchSize || 10;
        this.replyAfterFallback = replyAfterFallback || 30;
        this.queueLimit = queueLimit || 200;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.TelemetryBufferConfiguration';
    }

    // #endregion
}
