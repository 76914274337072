/**
 *
 * @module playbackError
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 16.0.0
 * @typedef {Object} SDK.Services.QualityOfService.QoePlaybackError
 * @desc Defines the different types of playback startup, playback, and ad errors that can be reported for QoE.
 * @note This enum is currently the superset of values from a PlaybackError and MediaFetchError.
 * @note Fallback enum, defines a set of known values, as well as a `catch all` value. SDK Type 2 enum.
 *
 * @property {String} drmHdcp - Since `16.0.0` - All HDCP related errors.
 * @property {String} drmDecryption - Since `16.0.0` - DRM decryption error.
 * @property {String} drmExpiredLicense - Since `16.0.0` - DRM license expired.
 * @property {String} drmSecurityLevel - Since `16.0.0` - DRM security level error.
 * @property {String} drmOther - Since `16.0.0` - All other DRM errors.
 * @property {String} networkFailure - Since `16.0.0` - Any failure due to a network related problem.
 * @property {String} serviceError - Since `16.0.0` - Failure due to HTTP errors when requesting media.
 * @property {String} authorizationExpired - Since `16.0.0` - DSS user token expired.
 * @property {String} concurrency - Since `16.0.0` - Concurrency violation.
 * @property {String} pcon - Since `16.0.0` - Failure due to user being locked out of content due to parental controls.
 * @property {String} blackout - Since `16.0.0` - Failure due to user being blacked out from watching content.
 * @property {String} notEntitled - Since `16.0.0` - Failure due to user not being entitled to watch content.
 * @property {String} contentNotAvailable - Since `16.0.0` - Failure due to content no longer being available.
 * @property {String} ageNotVerified - Since `16.0.0` - The age for the users profile has not been verified and needs to be before proceeding.
 * @property {String} authenticationExpired - Since `16.0.0` - Authentication has expired and needs to be updated before proceeding.
 * @property {String} locationAcquisitionError - Since `16.0.0` - Unable to get user location due to location service being disabled. NOTE Only applicable on certain platforms.
 * @property {String} locationNotAllowed - Since `16.0.0` - User is not allowed to use the app in their current location. Some products prohibit the use of the app in certain locations. NOTE. Only applicable for certain products.
 * @property {String} loginRequired - Since `16.0.0` - The requested media can only be viewed after authentication.
 * @property {String} mediaNotAllowed - Since `16.0.0` - The current user is not allowed to access the media for the requested license.
 * @property {String} parentalControlsRestricted - Since `16.0.0` - The current user is not allowed to access the media due to parental controls restrictions on the profile.
 * @property {String} pinExpired - Since `16.0.0` - The profile pin has expired and needs to be updated before proceeding.
 * @property {String} profileMissing - Since `16.0.0` - There was no active profile in the token or the service was unable to use it.
 * @property {String} profilePinMissing - Since `16.0.0` - The request requires a pin to be set on the profile but no pin has been set.
 * @property {String} requestBlocked - Since `16.0.0` - Device IP address is blacklisted.
 * @property {String} requestTemporarilyBlocked - Since `16.0.0` - Device IP address is temporarily blocked.
 * @property {String} temporarilyThrottled - Since `16.0.0` - Device IP address is temporarily throttled.
 * @property {String} temporarilyUnavailable - Since `16.0.0` - Server is too busy and rejected request.
 * @property {String} unexpectedError - Since `16.0.0` - An unexpected error has occurred.
 * @property {String} upgradeRequired - Since `16.0.0` - The service received a `X-BAMTech-R21-Unsupported` header and determined this profile did not meet the requirements to play the requested media.
 * @property {String} unknown - Since `16.0.0` - All other errors.
 * @property {String} adBeaconError - Since `19.0.0` - Client failed to send ad beacon to ad server.
 * @property {String} adServerError - Since `19.0.0` - Client failed to request from ad server or ad server response can't be parsed.
 *
 * @example
 * SDK.Services.QualityOfService.QoePlaybackError.drmHdcp // 'drmHdcp'
 *
 */
export default keyMirror({
    drmHdcp: null,
    drmDecryption: null,
    drmExpiredLicense: null,
    drmSecurityLevel: null,
    drmOther: null,
    networkFailure: null,
    serviceError: null,
    authorizationExpired: null,
    concurrency: null,
    pcon: null,
    blackout: null,
    notEntitled: null,
    contentNotAvailable: null,
    ageNotVerified: null,
    authenticationExpired: null,
    locationAcquisitionError: null,
    locationNotAllowed: null,
    loginRequired: null,
    mediaNotAllowed: null,
    parentalControlsRestricted: null,
    pinExpired: null,
    profileMissing: null,
    profilePinMissing: null,
    requestBlocked: null,
    requestTemporarilyBlocked: null,
    temporarilyThrottled: null,
    temporarilyUnavailable: null,
    unexpectedError: null,
    upgradeRequired: null,
    unknown: null,
    adBeaconError: null,
    adServerError: null
});
