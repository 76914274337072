/**
 *
 * @module eligibilityManager
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';

import EligibilityClient from '../services/eligibility/eligibilityClient';

import EligibilityManagerConfiguration from '../services/configuration/eligibilityManagerConfiguration';

import AccessTokenProvider from '../token/accessTokenProvider';
import type LogTransaction from '../logging/logTransaction';
import type EligibilityStatusResponse from '../services/eligibility/eligibilityStatusResponse';
import type AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @since 4.2.0
 *
 */
export default class EligibilityManager {
    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Services.Configuration.EligibilityManagerConfiguration}
     *
     */
    private config: EligibilityManagerConfiguration;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Services.Eligibility.EligibilityClient}
     *
     */
    private client: EligibilityClient;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.EligibilityManagerConfiguration} options.config
     * @param {SDK.Services.Eligibility.EligibilityClient} options.client
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     *
     */
    public constructor(options: {
        config: EligibilityManagerConfiguration;
        client: EligibilityClient;
        logger: Logger;
        accessTokenProvider: AccessTokenProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        EligibilityManagerConfiguration
                    ),
                    client: Types.instanceStrict(EligibilityClient),
                    logger: Types.instanceStrict(Logger),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, accessTokenProvider, logger } = options;

        this.config = config;
        this.client = client;
        this.logger = logger;
        this.accessTokenProvider = accessTokenProvider;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} [sku]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Eligibility.EligibilityStatusResponse>}
     *
     */
    public async getEligibilityStatus(
        sku: string | undefined,
        logTransaction: LogTransaction
    ): Promise<EligibilityStatusResponse> {
        const { accessToken } = this;

        return await this.client.getEligibilityStatus({
            sku,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Eligibility.EligibilityManager';
    }
}
