/**
 *
 * @module dustDecorators
 *
 */
import { Check, Types, typecheck } from '@dss/type-checking';

import DustLogUtility from './dustLogUtility';

import LogTransaction from '../../../logging/logTransaction';

import InvalidArgumentException from './../../exception/invalidArgumentException';
import type Logger from '../../../logging/logger';

// Due to the inability to override constructor functions
// we have to provide a way for our test infrastructure
// to override this constructor for testability
const internalTestabilityOverride = {
    MockDustLogUtility: undefined as TodoAny
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// @see: https://luckylibora.medium.com/typescript-method-decorators-in-depth-problems-and-solutions-74387d51e6a
type TMethodDecorator<Base = any> = <T extends Base>(
    target: T,
    key: string,
    descriptor: TypedPropertyDescriptor<any>
) => TypedPropertyDescriptor<any> | void;
/* eslint-enable @typescript-eslint/no-explicit-any */

type ApiMethodDecoratorableClass = {
    logger: Logger;
    //dustEnabled: boolean; leaving this commented out to avoid changing all api's to have dustEnabled public...
};

/**
 *
 * @access protected
 *
 */
export default {
    /**
     *
     * For internal use only - allows us to mock out and test internal constructors within the SDK
     *
     */
    internalTestabilityOverride,

    /**
     *
     * @access public
     * @param {Object} urnBaseObj
     * @param {Object|false} [apiMethodOptions] - false in the case of no parameters (ex see usages of __SDK_TYPECHECK__)
     * @param {Boolean} [apiMethodOptions.skipDustLogUtility=false]
     * @param {String} [apiMethodOptions.method] - Default is the function name
     * @param {Object} [apiMethodOptions.paramTypes] - The typedefs of any parameters for the api method being decorated.
     * @desc Provides a way of wrapping a method level a decorator that wraps the method execution with standard dust logging
     * @returns {any} A new function that wraps the original function with the both DustLogUtility and LogTransaction.
     *
     */
    apiMethodDecorator(
        urnBaseObj: Record<string, unknown>,
        apiMethodOptions?: {
            skipDustLogUtility?: boolean;
            method?: string;
            paramTypes?: object | boolean;
        }
    ): TMethodDecorator<ApiMethodDecoratorableClass> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                urnBaseObj: Types.nonEmptyObject,
                apiMethodOptions: Types.object({
                    skipDustLogUtility: Types.boolean.optional,
                    method: Types.nonEmptyString.optional,
                    paramTypes: Types.object().optional
                }).optional
            };

            typecheck(
                'DustDecorators',
                'apiMethodDecorator',
                params,
                arguments
            );
        }

        /**
         *
         * @param {Object} target - The prototype of the class for an instance member.
         * @param {string} key - The name of the member.
         * @param {Object} descriptor - The Property Descriptor for the member.
         *
         */
        return <T extends ApiMethodDecoratorableClass>(
            target: T,
            key: string,
            descriptor: TypedPropertyDescriptor<unknown>
        ) => {
            const urn = urnBaseObj[key] as string;

            if (!urn) {
                throw new InvalidArgumentException(
                    `DustDecorator - Could not find urn name based on key ${key}`
                );
            }

            // Original function implementation the decorator is wrapping
            const original = descriptor.value;

            if (Check.function(original)) {
                // Re-write the original function with a version that wraps
                // it's execution with the DustLogUtility wrap pattern
                //
                // Would have preferred to use async/await here but
                // some strange error was thrown by a linting tool
                // so re-factored to use a promise chain for now.
                //
                // NOTE: don’t use arrow function for descriptor.value,
                // otherwise method context will be lost.
                descriptor.value = function (...args: Array<unknown>) {
                    // @ts-expect-error dustEnabled is not on BaseApi - TODO make it?
                    // currently we just assume it exists.
                    const { logger, dustEnabled } = this as BaseApi;

                    if (!logger) {
                        // eslint-disable-next-line no-console
                        console.warn('no "logger" instance found on', target);
                    }

                    const {
                        method = key,
                        paramTypes,
                        skipDustLogUtility
                    } = apiMethodOptions || {};

                    /* istanbul ignore else */
                    if (__SDK_TYPECHECK__) {
                        if (paramTypes) {
                            // validate the method's param/args
                            typecheck(
                                this,
                                method,
                                paramTypes as object,
                                arguments
                            );
                        }
                    }

                    // grab the namespace as placeholder for file name
                    const file = this.toString();

                    return LogTransaction.wrapLogTransaction<T>({
                        urn,
                        file,
                        logger,
                        /**
                         *
                         * @param {SDK.Logging.LogTransaction} logTransaction
                         *
                         */
                        action: (logTransaction) => {
                            if (skipDustLogUtility) {
                                return original.call(this, {
                                    method,
                                    logTransaction,
                                    args
                                });
                            }

                            return DustLogUtility.wrap({
                                dustEnabled,
                                dustUtilityCtorOptions: {
                                    logger,
                                    source: target.constructor.name,
                                    urn,
                                    skipLogTransaction: true
                                },
                                action: (dustLogUtility) =>
                                    original.call(this, {
                                        method,
                                        logTransaction,
                                        dustLogUtility,
                                        args
                                    }),
                                DustLogUtilityCtorOverride:
                                    internalTestabilityOverride.MockDustLogUtility
                            });
                        }
                    });
                };
            }

            return descriptor;
        };
    }
};
