/**
 *
 * @module checkResponseCode
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/sdkErrorCases.json
 * @see https://github.bamtech.co/userservices/orchestration-api/blob/master/OrchestrationApi.yaml#L99
 * @see https://docs.google.com/spreadsheets/d/1kHtlh7D08dArPp97iYTMHA2aIoBtK2bqBIbIUK1PJiM
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import ErrorCode from '../exception/errorCode';
import ErrorReason from '../exception/errorReason';
import { Exception } from '../exception/exceptionDefinitions';
import ExceptionReference from '../exception/exceptionReference';
import ServiceException from '../exception/serviceException';
import DustLogUtility, {
    ServerResponse
} from '../internal/dust/dustLogUtility';

/**
 *
 * @access private
 * @param {Number} status
 * @param {Array} errors
 * @param {String} [methodName]
 * @note If the error case you are expecting is based on a method and status code but not error code(s) from the
 * service then you have to invoke `Util.checkResponseCode` as so `Util.checkResponseCode(status, errors, methodName)`.
 * This requires additional information to be added to the error case in `ExceptionReference` for it to be handled properly.
 * @returns {Object|undefined}
 *
 */
export function handleErrorCase(
    status?: number,
    errors?: Array<ErrorReason>,
    methodName?: string
) {
    const error = errors?.length ? errors[0] : ({} as ErrorReason);
    const { description, code } = error;

    let exceptionData;

    if (Check.nonEmptyString(methodName as string)) {
        /**
         *
         * @note Filters through the ExceptionReference object to determine the error case based on
         * the http status code and method name
         *
         */
        for (const key of Object.keys(ExceptionReference)) {
            const namespace = (
                ExceptionReference as Indexable<typeof ExceptionReference>
            )[key];

            let isErrorCase;

            for (const subKey of Object.keys(namespace)) {
                const exceptionReference = (
                    namespace as Indexable<typeof namespace>
                )[subKey];

                /**
                 *
                 * @note Possible error cases for a given exception. These error cases are for exceptions
                 * that are based on http status code and method name.
                 *
                 */
                const errorCases = exceptionReference.errorCases;

                if (Check.array(errorCases)) {
                    isErrorCase = errorCases.some((errorCase) => {
                        if (
                            !errorCase.status.includes(status as number) ||
                            !errorCase.method.includes(methodName as string)
                        ) {
                            return false;
                        }

                        /**
                         *
                         * @note Returns false if the error code returned from the service does not match
                         * the error code associated with the exception, even though the http status and
                         * method name requirements were met. This occurs because some error codes are used
                         * for multiple error cases. Example: `idp.error.password.invalid-value`.
                         *
                         *
                         */
                        if (
                            Check.assigned(errorCase.code) &&
                            errorCase.code !== code
                        ) {
                            return false;
                        }

                        return true;
                    });
                }

                if (isErrorCase) {
                    exceptionData = exceptionReference;
                    break;
                }
            }

            if (isErrorCase) {
                break;
            }
        }
    } else {
        /**
         *
         * @note Filters through the ErrorCode reference object to determine the error case based on
         * the supplied error code from the failed service request.
         *
         */
        for (const key of Object.keys(ErrorCode)) {
            const errorCode = (ErrorCode as Indexable<typeof ErrorCode>)[key];

            /**
             *
             * @note Some error cases require us to check against the returned error description.
             * Currently this is only used for LocationNotAllowedException and InvalidGrantException
             *
             */
            if (Check.array(errorCode.description)) {
                if (
                    code === errorCode.code &&
                    errorCode.description.includes(description)
                ) {
                    exceptionData = errorCode.exceptionData;
                    break;
                }

                /**
                 *
                 * @note Some error cases require us to check against the http status code and the returned error code.
                 *
                 */
            } else if (Check.array(errorCode.status)) {
                if (
                    code === errorCode.code &&
                    errorCode.status.includes(status as number)
                ) {
                    exceptionData = errorCode.exceptionData;
                    break;
                }

                /**
                 *
                 * @note Some specific error cases only require a unique error code.
                 *
                 */
            } else if (code === errorCode.code) {
                exceptionData = errorCode.exceptionData;
                break;
            }
        }
    }

    return exceptionData;
}

/**
 *
 * @param {Object} [options={}]
 * @param {Number} options.status
 * @param {Array} options.errors
 * @returns {Array}
 *
 */
export function processReasons(
    options = {} as {
        status: number;
        errors: Array<ErrorReason>;
    }
) {
    const { status, errors } = options;
    const reasons: Array<ErrorReason> = [];
    const errorCodes = [200];

    if ((status >= 400 || errorCodes.includes(status)) && Check.array(errors)) {
        errors.forEach((error = {} as ErrorReason) => {
            const { code, description } = error;

            reasons.push(new ErrorReason(code, description));
        });
    }

    return reasons;
}

/**
 *
 * @param {Object} options
 * @param {Number} [options.status] - The HTTP status code
 * @param {String} [options.transactionId] - The identifier allows BAM to lookup the entire operation in DUST
 * @param {Array} [options.errors] - Group of errors
 * @param {String} [options.methodName] - name of the failed method
 * @param {String} [options.contentType] - content-type response header value
 * @desc Returns an exception based on the supplied parameters
 * @note Defaults exceptionData to an empty object if it is null or undefined. This will ensure a generic
 * ServiceException is created as a fallback.
 * @returns {ServiceException}
 *
 */
export function createException(
    options = {} as {
        status: number;
        transactionId?: Nullable<string>;
        errors?: Array<ErrorReason>;
        methodName?: string;
        contentType?: Nullable<string>;
    }
) {
    const {
        status,
        transactionId,
        errors = [],
        methodName,
        contentType
    } = options;

    const reasons = processReasons({ status, errors });

    let exceptionData = handleErrorCase(status, errors, methodName);

    if (!exceptionData) {
        const isTransactionIdMissing = Check.not.assigned(transactionId);
        const isHtmlContentType =
            Check.string(contentType) &&
            contentType.toLowerCase() === 'text/html';

        if (status >= 500) {
            exceptionData = ExceptionReference.common.temporarilyUnavailable;
        } else if (
            status === 403 &&
            isTransactionIdMissing &&
            isHtmlContentType
        ) {
            exceptionData = ExceptionReference.common.requestBlocked;
        } else if (status === 200 && errors && errors.length) {
            exceptionData = ExceptionReference.common.unexpectedError;

            const error = errors[0];

            if (Check.string(error.description)) {
                exceptionData.message = error.description;
            }
        } else {
            exceptionData = {} as Exception;
        }
    }

    return new ServiceException({
        transactionId: transactionId ?? undefined,
        reasons,
        exceptionData,
        status
    });
}

export const graphqlErrorCode = {
    200: [
        ErrorCode.clientIdNotFound,
        ErrorCode.redirectUriInvalid,
        ErrorCode.redirectUriNotAllowed,
        ErrorCode.accountArchived,
        ErrorCode.accountBlocked,
        ErrorCode.accountNotEligibleAdTier,
        ErrorCode.accountProfilePinDeleteFailed,
        ErrorCode.accountProfilePinGetFailed,
        ErrorCode.accountSecurityFlagged,
        ErrorCode.accountVerificationRequired,
        ErrorCode.accountIdentityVerificationRequired,
        ErrorCode.actionGrantRejected,
        ErrorCode.deviceIdInvalid,
        ErrorCode.offDeviceGrantInvalid,
        ErrorCode.offDeviceActionGrantInvalid,
        ErrorCode.offDeviceMissingActionGrant,
        ErrorCode.offDeviceMissingRedemptionFlow,
        ErrorCode.invalidCampaign,
        ErrorCode.invalidCardSecurityCode,
        ErrorCode.invalidEmail,
        ErrorCode.invalidEmailUpdate,
        ErrorCode.invalidLineItemsCount,
        ErrorCode.invalidOrderCampaignsCount,
        ErrorCode.invalidPasscode,
        ErrorCode.invalidPaymentMethodId,
        ErrorCode.invalidVoucher,
        ErrorCode.licensePlateNotFound,
        ErrorCode.unexpectedError,
        ErrorCode.unpriceableOrderError101,
        ErrorCode.unpriceableOrderError102,
        ErrorCode.unpriceableOrderError103,
        ErrorCode.unpriceableOrderError104,
        ErrorCode.unpriceableOrderError110,
        ErrorCode.unpriceableOrderError111,
        ErrorCode.unpriceableOrderError112,
        ErrorCode.unpriceableOrderError113,
        ErrorCode.unpriceableOrderError116,
        ErrorCode.unpriceableOrderError117,
        ErrorCode.unpriceableOrderError118,
        ErrorCode.accountGetFailed,
        ErrorCode.accountLoginFailed,
        ErrorCode.accountNotFound,
        ErrorCode.clientIdNotFound,
        ErrorCode.maximumProfilesReached,
        ErrorCode.partnerNotSupported,
        ErrorCode.profileCreationProtected,
        ErrorCode.profilePinInvalid,
        ErrorCode.profileRetrieval,
        ErrorCode.userProfileDeleteFailed,
        ErrorCode.profileLookupIdFailed,
        ErrorCode.userProfileNotFound,
        ErrorCode.userProfileUpdateFailed,
        ErrorCode.identityAlreadyExists,
        ErrorCode.identityAlreadyUsed,
        ErrorCode.idpErrorIdentityBadCredentials,
        ErrorCode.idpErrorAuthenticationBlocked,
        ErrorCode.idpErrorIdentityNotFound,
        ErrorCode.idpErrorPasswordInvalidValue,
        ErrorCode.idpFailureDataStoreThrottling,
        ErrorCode.marketingPreferenceUpdateFailed,
        ErrorCode.payloadFieldsIncorrect,
        ErrorCode.idpPayloadFieldsIncorrectGraphQl,
        ErrorCode.documentsRetrievalFailed,
        ErrorCode.invalidPreAuthToken,
        ErrorCode.temporarilyUnavailable,
        ErrorCode.preAuthThrottled,
        ErrorCode.profilePinNotEligible,
        ErrorCode.profilePinUpdateFailed,
        ErrorCode.rateLimited,
        ErrorCode.resourceTimedOut,
        ErrorCode.tokenServiceInvalidGrant,
        ErrorCode.tokenServiceUnauthorizedClient,
        ErrorCode.identityPasswordResetRequired,
        ErrorCode.orderCountryInvalid,
        ErrorCode.orderStandaloneExceedsBundle,
        ErrorCode.redirectUriInvalid,
        ErrorCode.redirectUriNotAllowed,
        ErrorCode.selfAccountHasActiveD2cSubscription,
        ErrorCode.selfAccountNotActive,
        ErrorCode.selfAccountNotFound,
        ErrorCode.selfRequestDuplicate
    ],
    202: [ErrorCode.copyFailure, ErrorCode.copyLimitReached]
};

const flattenedGraphQlCodes = [
    ...graphqlErrorCode[200].map((item) => item.code),
    ...graphqlErrorCode[202].map((item) => item.code)
];

/**
 *
 * @param {Array} errors
 * @desc Checks for error cases thrown on success (status code 200 & 202)
 * @returns {Boolean}
 *
 */
export function checkAcceptedCase(errors?: Array<ErrorReason>) {
    if (Check.array(errors)) {
        return errors.some((error = {} as ErrorReason) => {
            const code = error.code;

            return flattenedGraphQlCodes.includes(code);
        });
    }

    return false;
}

/**
 *
 * @access private
 * @param {Object} response
 * @param {DustLogUtility} [dustLogUtility]
 * @param {String} [methodName] - name of the failed method
 * @returns {Promise<Object>} resolves the response or rejects with an exception
 * @note methodName is used for error cases that rely on the http status and not a service error code.
 * it is currently only used for error cases relating to the Commerce feature.
 * @see https://tools.ietf.org/html/rfc6749#section-5.1 (success)
 * @see https://tools.ietf.org/html/rfc6749#section-5.2 (error)
 *
 */
export default function checkResponseCode(
    response: ServerResponse,
    dustLogUtility?: DustLogUtility,
    methodName?: string
) {
    /* istanbul ignore else */
    if (__SDK_TYPECHECK__) {
        const params = {
            response: Types.object(),
            dustLogUtility: Types.skip, // DustLogUtility - can't add ref to item due to circular reference.
            methodName: Types.nonEmptyString.optional
        };

        typecheck('checkResponseCode', params, arguments);
    }

    const { status, headers, data } = response;
    const {
        errors = [],
        // Flex error details
        error: { details = [] } = {}
    } = data || {};
    const {
        error,
        error_description: errorDescription,
        data: orchestrationData
    } = data;

    /**
     *
     * @note this structure is related to the Flex service
     *
     */
    details.forEach((detail: { code: string; issue: string }) => {
        const { code, issue: description } = detail;

        errors.push({ code, description });
    });

    /**
     *
     * @note this structure is related to the graph service
     *
     */
    const { message, extensions = {} } = errors[0] || {};
    const isSuccessStatus = [200, 202].includes(status);
    const transactionId =
        headers && headers.get ? headers.get('x-request-id') : null;
    const contentType =
        headers && headers.get ? headers.get('content-type') : null;

    let isError = status >= 400;
    let isAcceptedErrorCase = false;

    /**
     *
     * @note create consistent error object structure
     * service only returns error string
     *
     */
    if (isError && Check.string(error)) {
        errors.push({ code: error, description: errorDescription });
    }

    /**
     *
     * @note create consistent error object structure
     * from a slightly different structure, returned by the graph service
     * assign the first item and create an exception based on that data
     *
     */
    if (
        isSuccessStatus &&
        Check.string(extensions.code) &&
        Check.not.nonEmptyObject(orchestrationData)
    ) {
        errors[0] = { code: extensions.code, description: message };
        isError = true;
    }

    if (dustLogUtility) {
        dustLogUtility.parseResponse({
            response,
            transactionId: transactionId as string
        });
    }

    if (isSuccessStatus) {
        isAcceptedErrorCase = checkAcceptedCase(errors);
    }

    let exception;

    if (isError || isAcceptedErrorCase) {
        exception = createException({
            status,
            transactionId,
            errors,
            methodName,
            contentType
        });

        if (dustLogUtility) {
            if (dustLogUtility.logTransaction) {
                dustLogUtility.setServiceInteraction({ response, exception });
            }

            dustLogUtility.captureError(exception);
        }

        if (process.env.NODE_ENV === 'test') {
            const eConsole = console as ExtendedConsole;

            eConsole.yellow('\n**********************************************');
            eConsole.yellow('DEBUG: response.url:', response.url);
            eConsole.yellow('DEBUG: response.status:', status);
            eConsole.yellow('DEBUG: response.headers:', headers);
            eConsole.yellow('DEBUG: response.body:', response.body);
            eConsole.yellow('DEBUG: exception.reasons:', exception?.reasons);

            if (response?.data?.errors) {
                eConsole.yellow(
                    'DEBUG: Graph Response Errors',
                    response.data.errors
                );
            }
            eConsole.yellow('**********************************************\n');
        }

        return Promise.reject(exception);
    }

    if (dustLogUtility?.logTransaction) {
        dustLogUtility.setServiceInteraction({ response, exception });
    }

    return Promise.resolve(response);
}
