/**
 *
 * @module telemetryManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TelemetryBufferConfiguration from './telemetryBufferConfiguration';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class TelemetryManagerExtrasMap {
    /**
     *
     * @access protected
     * @param {Object} options
     * @param {Number} options.batchLimit (deprecated)
     * @param {Number} options.replyAfterFallback (deprecated)
     * @param {Number} options.minimumBatchSize (deprecated)
     * @param {Object} options.bufferConfigurationDefault
     * @param {Object} options.prohibited
     * @param {Boolean} options.permitAppDustEvents
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} [options.glimpseBufferConfiguration=options.bufferConfigurationDefault]
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} [options.streamSampleBufferConfiguration=options.bufferConfigurationDefault]
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} [options.qoeBufferConfiguration=options.bufferConfigurationDefault]
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} [options.eventBufferConfiguration=options.eventBufferConfiguration]
     * @param {Object} [options.fastTrack]
     *
     */
    constructor(options) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bufferConfigurationDefault: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    prohibited: Types.object(),
                    permitAppDustEvents: Types.boolean,
                    streamSampleBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    glimpseBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    qoeBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    eventBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    fastTrack: Types.object()
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            bufferConfigurationDefault,
            streamSampleBufferConfiguration,
            glimpseBufferConfiguration,
            qoeBufferConfiguration,
            eventBufferConfiguration,
            fastTrack,
            prohibited,
            permitAppDustEvents
        } = options;

        /**
         *
         * @access protected
         * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
         * @desc default event buffer configuration
         *
         */
        this.bufferConfigurationDefault =
            bufferConfigurationDefault || new TelemetryBufferConfiguration();

        /**
         *
         * @access protected
         * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
         * @desc optional stream buffer configuration
         *
         */
        this.streamSampleBufferConfiguration =
            streamSampleBufferConfiguration || this.bufferConfigurationDefault;

        /**
         *
         * @access protected
         * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
         * @desc optional Glimpse buffer configuration
         *
         */
        this.glimpseBufferConfiguration =
            glimpseBufferConfiguration || this.bufferConfigurationDefault;

        /**
         *
         * @access protected
         * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
         * @desc optional QoE buffer configuration
         *
         */
        this.qoeBufferConfiguration =
            qoeBufferConfiguration || this.bufferConfigurationDefault;

        /**
         *
         * @access protected
         * @since 4.3.0
         * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
         * @desc optional eventBufferConfiguration used for DUST
         *
         */
        this.eventBufferConfiguration =
            eventBufferConfiguration || this.bufferConfigurationDefault;

        /**
         *
         * @access protected
         * @since 3.10.0
         * @type {Object|undefined}
         * @desc contains fast track information for events - it is expected to contain a property `urns` which
         * is an `Array` that will contain a list of urn's used to fast track events
         *
         */
        this.fastTrack = fastTrack;

        /**
         *
         * @access protected
         * @since 4.8.0
         * @type {Object}
         * @desc A collection of URNs to be prohibited.
         *
         */
        this.prohibited = prohibited;

        /**
         *
         * @access protected
         * @since 4.8.0
         * @type {Boolean}
         * @desc Disables all custom DUST events when set to false.
         *
         */
        this.permitAppDustEvents = permitAppDustEvents;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Configuration.TelemetryManagerExtrasMap';
    }

    // #endregion
}
