/**
 *
 * @module ripcutClientEndpoint
 *
 */

import keymirror from 'keymirror';

/**
 *
 * @access protected
 * @since 17.0.0
 * @typedef {Object} SDK.Services.Ripcut.RipcutClientEndpoint
 *
 * @property {String} main - Since `17.0.0`
 * @property {String} mainScale - Since `17.0.0`
 * @property {String} mainCompose - Since `17.0.0`
 * @property {String} mainBadging - Since `17.0.0`
 *
 */
export default keymirror({
    main: null,
    mainScale: null,
    mainCompose: null,
    mainBadging: null
});
