/**
 *
 * @module serviceClientConfiguration
 *
 */

/**
 *
 * @access protected
 * @desc A model containing service client configuration settings.
 *
 */
export default class ServiceClientConfiguration<
    TEndpoints = Record<string, never>,
    TExtras = Record<string, never>
> {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public baseUrl: string;
    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public endpoints: IEndpoints<TEndpoints>;
    /**
     *
     * @access public
     * @type {TExtras}
     *
     */
    public extras: TExtras;

    /**
     *
     * @param {String} baseUrl
     * @param {Object} [endpoints={}]
     * @param {Object} [extras={}]
     *
     */
    public constructor(
        baseUrl: string,
        endpoints: IEndpoints<TEndpoints> = {} as IEndpoints<TEndpoints>,
        extras: TExtras = {} as TExtras
    ) {
        this.baseUrl = baseUrl;
        this.endpoints = endpoints;
        this.extras = extras;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ServiceClientConfiguration';
    }
}
