/**
 *
 * @module entry
 * @desc {SDK.Invoice} entry point.
 *
 */

import InvoiceApi from './invoiceApi';
import InvoiceManager from './invoiceManager';

/**
 *
 * @typedef {Object} SDK.Invoice
 *
 * @property {InvoiceApi} InvoiceApi
 * @property {InvoiceManager} InvoiceManager
 *
 */
export default {
    InvoiceApi,
    InvoiceManager
};
