/**
 *
 * @module mediaCapabilitiesProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';

import HdrType from '../services/media/hdrType';
import SupportedCodec from '../services/media/supportedCodec';

import {
    HdcpSecurityLevel,
    SlugDuration,
    WidevineSecurityLevel
} from './enums';

/**
 *
 * @since 3.8.0
 * @desc Defines the contract for a class that can provide the current media capabilities of the device.
 * The SDK ships with a default implementation of the MediaCapabilitiesProvider that returns
 * falsy values. This guarantees that a valid Playback Scenario (PBS) will always be used, but the
 * default implementation will return a lower quality stream. For UHD support, app developers must
 * implement their own MediaCapabilitiesProvider including all methods in their implementation.
 *
 */
export default class MediaCapabilitiesProvider {
    /**
     *
     * @access private
     * @since 20.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Logging.Logger} [logger=Logging]
     *
     */
    public constructor(logger?: Logger) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger).optional
            };

            typecheck(this, params, arguments);
        }

        this.logger = logger || Logger.instance;

        this.logger.log(this.toString(), 'Created provider');
    }

    /**
     *
     * @access public
     * @desc An interface method. Retrieves the supported HDR types of the device. An
     * empty array signifies HDR is not compatible.
     * @returns {Array<SDK.Services.Media.HdrType>}
     * @note The SDK ships with a default implementation that returns a falsy value.
     *
     */
    public getSupportedHdrTypes(): Array<ValueOf<typeof HdrType>> {
        return [];
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @returns {String<SDK.Media.WidevineSecurityLevel>|null}
     * @note The SDK ships with a default implementation that returns a falsy value.
     *
     */
    public getWidevineSecurityLevel(): Nullable<
        ValueOf<typeof WidevineSecurityLevel>
    > {
        return null;
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @returns {Array<SDK.Services.Media.SupportedCodec>}
     * @note The SDK ships with a default implementation that returns a falsy value.
     *
     */
    public getSupportedCodecs(): Array<ValueOf<typeof SupportedCodec>> {
        return [];
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @returns {SDK.Media.HdcpSecurityLevel}
     * @note The SDK ships with a default implementation that returns a `HdcpSecurityLevel.unknown` value.
     *
     */
    public getHdcpSecurityLevel(): ValueOf<typeof HdcpSecurityLevel> {
        return HdcpSecurityLevel.unknown;
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @returns {Boolean}
     * @note The SDK ships with a default implementation that returns a falsy value.
     *
     */
    public supportsAtmos(): boolean {
        return false;
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @returns {String<SDK.Media.SlugDuration>|undefined}
     * @note The SDK ships with a default implementation that returns undefined.
     *
     */
    public getSlugDuration(): ValueOf<typeof SlugDuration> | undefined {
        return undefined;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaCapabilitiesProvider';
    }
}
