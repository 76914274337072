/**
 *
 * @module orchestrationManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/orchestration.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './../logging/logger';

import getSafe from '../services/util/getSafe';

import AccessToken from '../token/accessToken';
import AccessTokenProvider from '../token/accessTokenProvider';
import DeviceMetadata from '../device/deviceMetadata';
import LogTransaction from '../logging/logTransaction';
import OsDeviceId from '../device/osDeviceId';

import DeviceGrant from '../services/token/deviceGrant';
import ExchangeDeviceGrantForAccessTokenRequest from '../services/orchestration/internal/exchangeDeviceGrantForAccessTokenRequest';
import OrchestrationRequest from '../services/orchestration/internal/orchestrationRequest';
import RefreshTokenRequest from '../services/orchestration/internal/refreshTokenRequest';
import RegisterDeviceRequest, {
    RegisterDeviceInput
} from '../services/orchestration/internal/registerDeviceRequest';
import UpdateDeviceOperatingSystemRequest from '../services/orchestration/internal/updateDeviceOperatingSystemRequest';

import OrchestrationManagerConfiguration from '../services/configuration/orchestrationManagerConfiguration';
import OrchestrationClient from '../services/orchestration/orchestrationClient';

export interface QueryOptions {
    query: string;
    operationName: string;
    variables: Record<string, unknown>;
}

/**
 *
 * @access protected
 * @since 4.17.0
 * @desc Serves as a go between between the public APIs and the `OrchestrationClient`.
 *
 */
export default class OrchestrationManager {
    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Services.Configuration.OrchestrationManagerConfiguration}
     *
     */
    private config: OrchestrationManagerConfiguration;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Services.Orchestration.OrchestrationClient}
     *
     */
    public client: OrchestrationClient;

    /**
     *
     * @access private
     * @since 9.0.0
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    private accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.OrchestrationManagerConfiguration} options.config
     * @param {SDK.Services.Orchestration.OrchestrationClient} options.client
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        config: OrchestrationManagerConfiguration;
        client: OrchestrationClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        OrchestrationManagerConfiguration
                    ),
                    client: Types.instanceStrict(OrchestrationClient),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { config, client, accessTokenProvider, logger } = options;

        this.config = config;
        this.client = client;
        this.accessTokenProvider = accessTokenProvider;
        this.logger = logger;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @since 4.17.0
     * @param {Object} options
     * @param {String} options.query - A custom query that returns only the specified set of data.
     * @param {String} options.operationName
     * @param {Object} [options.variables]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns the results of a custom query.
     * @returns {Promise<Object>}
     *
     */
    public async query<T>(
        options: QueryOptions,
        logTransaction: LogTransaction
    ): Promise<T> {
        const request = new OrchestrationRequest(options);

        return await this.client.query<T>({
            request,
            accessToken: this.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @param {Object} options
     * @param {DeviceMetadata} options.deviceMetadata
     * @param {String} [options.userToken]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns the results of a register device query.
     * @note apiKey is not being passed here because it's getting pulled by the constructor
     * @returns {Promise<Object>}
     *
     */
    public async registerDevice(
        options: {
            deviceMetadata: DeviceMetadata;
            userToken?: string;
        },
        logTransaction: LogTransaction
    ): Promise<Record<string, unknown>> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    deviceMetadata: Types.instanceStrict(DeviceMetadata),
                    userToken: Types.nonEmptyString.optional
                }),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'registerDevice', params, arguments);
        }

        const { deviceMetadata, userToken } = options;

        const {
            deviceFamily,
            applicationRuntime,
            deviceProfile,
            attributes,
            devicePlatformId
        } = deviceMetadata;

        const {
            osDeviceIds,
            manufacturer,
            model,
            operatingSystem,
            operatingSystemVersion,
            browserName,
            browserVersion,
            deviceLanguage,
            brand
        } = attributes;

        const input: RegisterDeviceInput = {
            deviceFamily,
            applicationRuntime,
            deviceProfile,
            deviceLanguage,
            attributes: {
                osDeviceIds,
                manufacturer,
                model,
                operatingSystem,
                operatingSystemVersion,
                browserName,
                browserVersion,
                brand
            },
            devicePlatformId
        };

        // @note renaming to `huluUserToken` to match the service naming.
        input.huluUserToken = userToken;

        const request = new RegisterDeviceRequest(input);

        return await this.client.registerDevice(request, logTransaction);
    }

    /**
     *
     * @access protected
     * @since 9.0.0
     * @param {Object|undefined} options
     * @param {String} options.operatingSystem
     * @param {String} options.operatingSystemVersion
     * @param {Array<OsDeviceId>} options.osDeviceIds
     * @param {String} [options.brand]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc If the config has `enableUpdateDeviceOS`, this will make the `UpdateDeviceOperatingSystemRequest` orchestration mutation call, otherwise it is ignored.
     * @note Only JS SDK uses this method, that is why it's not in the Spec.
     * @returns {Promise<Object|Void>}
     *
     */
    public async updateDeviceOperatingSystem(
        options:
            | {
                  operatingSystem: string;
                  operatingSystemVersion: string;
                  osDeviceIds: Array<OsDeviceId>;
                  brand?: string;
              }
            | undefined,
        logTransaction: LogTransaction
    ): Promise<Record<string, unknown> | void> {
        const enableUpdateDeviceOS = getSafe(
            () => this.config.extras.enableUpdateDeviceOS,
            false
        );

        if (enableUpdateDeviceOS === false) {
            this.logger.info(
                this.toString(),
                'Skipped updateDeviceOperatingSystem per config setting.'
            );

            return undefined;
        }

        const { operatingSystem, operatingSystemVersion, osDeviceIds, brand } =
            options || {};

        const input = {
            operatingSystem,
            operatingSystemVersion,
            osDeviceIds,
            brand
        };

        const request = new UpdateDeviceOperatingSystemRequest(input);

        return await this.client.query({
            request,
            accessToken: this.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access protected
     * @since 10.0.0
     * @param {Object} options
     * @param {SDK.Services.Token.DeviceGrant} options.deviceGrant
     * @param {String} [options.userToken]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Exchanges a device grant string for an access token.
     * @returns {Promise<Object>}
     *
     */
    public async exchangeDeviceGrantForAccessToken(
        options: {
            deviceGrant: DeviceGrant;
            userToken?: string;
        },
        logTransaction: LogTransaction
    ): Promise<Record<string, unknown>> {
        const input = {
            deviceGrant: options.deviceGrant.assertion,
            huluUserToken: options.userToken
        };

        const request = new ExchangeDeviceGrantForAccessTokenRequest(input);

        return await this.client.exchangeDeviceGrantForAccessToken(
            request,
            logTransaction
        );
    }

    /**
     *
     * @access protected
     * @since 10.0.0
     * @param {Object} options
     * @param {String} options.refreshToken
     * @param {String} [options.reason]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Refreshes access token using orchestration.
     * @returns {Promise<Object>}
     *
     */
    public async refreshToken(
        options: {
            refreshToken: string;
            reason?: string;
        },
        logTransaction: LogTransaction
    ): Promise<Record<string, unknown>> {
        const { refreshToken, reason } = options;

        const input = {
            refreshToken
        };

        const request = new RefreshTokenRequest(input);

        return await this.client.refreshToken({
            request,
            reason,
            logTransaction
        });
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh `AccessToken` from the `AccessTokenProvider` instance.
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken(): AccessToken {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Orchestration.OrchestrationManager';
    }
}
