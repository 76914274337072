/**
 *
 * @module sessionInfoStorage
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from './../logging/logger';
import PlatformProviders from './../services/providers/platformProviders';
import SessionInfo from './../services/session/sessionInfo';
import SessionInfoChangedEvent from './../sessionInfoChangedEvent';
import InternalEvents from './../internalEvents';
import type CoreStorageProvider from '../services/providers/shared/coreStorageProvider';

/**
 *
 * @access protected
 * @since 4.3.0
 * @desc Provides a storage mechanism for storing SessionInfo.
 *
 */
export default class SessionInfoStorage extends EventEmitter {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    private clientId: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private environment: string;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     *
     */
    private cacheKey: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        logger: Logger;
        storage: CoreStorageProvider;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientId, environment, logger, storage } = options;

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.clientId = clientId;

        /**
         *
         * @access private
         * @type {String}
         *
         */
        this.environment = environment;

        /**
         *
         * @access private
         * @type {SDK.Logging.Logger}
         *
         */
        this.logger = logger;

        /**
         *
         * @access private
         * @type {SDK.Services.PlatformProviders.Storage}
         *
         */
        this.storage = storage;

        /**
         *
         * @access private
         * @type {String}
         * @desc cache key scoped under client ID and environment to prevent clashes,
         * maintains the same structure as all other cacheKey(s) in the SDK
         *
         */
        this.cacheKey = `
            __bam_sdk_session_info--${this.clientId}_${this.environment}
        `.replace(/(?:\n\s+)/g, '');

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Session.SessionInfo} newSessionInfo
     * @desc stores SessionInfo in Storage
     * @returns {Promise<Void>}
     *
     */
    public async saveSessionInfo(newSessionInfo: SessionInfo) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                sessionInfo: Types.instanceStrict(SessionInfo)
            };

            typecheck(this, 'saveSessionInfo', params, arguments);
        }

        const { cacheKey, logger, storage } = this;

        logger.info(
            this.toString(),
            `Save SessionInfo to Storage using key: "${cacheKey}".`
        );

        const oldSessionInfo = await this.getSessionInfo();

        await storage.set(cacheKey, newSessionInfo);

        if (this.hasSessionInfoChanged(oldSessionInfo, newSessionInfo)) {
            const sessionInfoChangedEvent = new SessionInfoChangedEvent(
                oldSessionInfo as SessionInfo,
                newSessionInfo
            );

            this.emit(
                InternalEvents.SessionInfoChanged,
                sessionInfoChangedEvent
            );
        }
    }

    /**
     *
     * @access public
     * @desc returns SessionInfo from Storage
     * @returns {SDK.Services.Session.SessionInfo|null}
     *
     */
    public async getSessionInfo() {
        const { logger, storage, cacheKey } = this;

        logger.log(this.toString(), 'Get cached SessionInfo.');

        try {
            const cachedInfo = await storage.get(cacheKey);

            if (cachedInfo) {
                return SessionInfoStorage.normalizeSessionInfoFromCache(
                    cachedInfo
                );
            }
        } catch (ex) {
            logger.error(this.toString(), ex);
        }

        return null;
    }

    /**
     *
     * @access public
     * @desc deletes the current SessionInfo from Storage
     * @returns {Promise<Void>}
     *
     */
    public async clear() {
        const { cacheKey, storage } = this;

        this.logger.warn(
            this.toString(),
            `Clearing cached SDK.Services.Session.SessionInfo for key: "${cacheKey}".`
        );

        await storage.remove(cacheKey);
    }

    /**
     *
     * @access public
     * @param {Object} cachedInfo
     * @desc Helper function to construct a `SessionInfo` instance from the cached data
     * @returns {SDK.Services.Session.SessionInfo}
     *
     */
    public static normalizeSessionInfoFromCache(cachedInfo: TodoAny) {
        return SessionInfo.create(cachedInfo);
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {SDK.Services.Session.SessionInfo} oldSessionInfo
     * @param {SDK.Services.Session.SessionInfo} newSessionInfo
     * @desc Determines whether the the new SessionInfo object is different from the old one
     * @returns {Boolean}
     *
     */
    private hasSessionInfoChanged(
        oldSessionInfo: SessionInfo | undefined | null,
        newSessionInfo: SessionInfo
    ) {
        if (Check.not.assigned(oldSessionInfo)) {
            return true;
        }

        try {
            // @ts-expect-error (says oldSession could be undefined but no because of the Check.not.assigned above)
            return oldSessionInfo.id !== newSessionInfo.id;
        } catch (ex) {
            this.logger.warn(this.toString(), ex);

            return true;
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Session.SessionInfoStorage';
    }
}
