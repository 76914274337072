/**
 *
 * @module httpMethod
 * @see https://github.com/STRML/keyMirror
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Configuration.HttpMethod
 *
 * @property {String} GET
 * @property {String} POST
 * @property {String} DELETE
 * @property {String} PATCH
 * @property {String} PUT
 *
 */
export default keyMirror({
    GET: null,
    POST: null,
    DELETE: null,
    PATCH: null,
    PUT: null
});
