/**
 *
 * @module mediaItem
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import MediaPayload from '../services/media/mediaPayload';
import AudioRendition from '../services/media/audioRendition';
import SubtitleRendition from '../services/media/subtitleRendition';
import PlaybackVariant from '../services/media/playbackVariant';
import PlaybackContext from '../services/media/playbackContext';
import PlaylistType from '../services/media/playlistType';

import MediaDescriptor from './mediaDescriptor';
import MediaSource from './mediaSource';
import Playlist from './playlist';

import { MediaAnalyticsKey } from './enums';

import {
    Insertion,
    InsertionTypedef,
    SourceInfo,
    UrlInfo
} from './../services/media/typedefs';
import type MediaPayloadStream from '../services/media/mediaPayloadStream';

/**
 *
 * @since 2.0.0
 * @desc Encapsulated payload and media description with convenience methods
 * for retrieving streams and telemetry data.
 *
 */
export default class MediaItem {
    /**
     *
     * @access private
     * @type {SDK.Services.Media.MediaPayload}
     *
     */
    public payload: MediaPayload;

    /**
     *
     * @access private
     * @type {SDK.Media.MediaDescriptor}
     *
     */
    public descriptor: MediaDescriptor;

    /**
     *
     * @access private
     * @since 4.0.0
     * @type {SDK.Services.Media.PlaybackContext|undefined}
     *
     */
    public playbackContext?: PlaybackContext;

    /**
     *
     * @access public
     * @type {SDK.Services.Media.MediaPlayhead}
     *
     */
    public playhead: MediaPayload;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.Media.PlaylistType}
     *
     */
    public preferredPlaylistType: TodoAny;

    /**
     *
     * @access public
     * @since 15.2.1
     * @type {Number|undefined}
     *
     */
    public priorityTracking?: number;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Services.Media.Insertion|undefined}
     * @desc Playlist service response SGAI/SSAI insertion data.
     *
     */
    public insertion?: Insertion;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Media.MediaPayload} options.mediaPayload
     * @param {SDK.Media.MediaDescriptor} options.mediaDescriptor
     * @param {SDK.Services.Media.PlaybackContext} [options.playbackContext]
     * @param {SDK.Services.Media.PlaylistType} options.preferredPlaylistType
     * @param {SDK.Services.Media.Insertion} [options.insertion]
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    public constructor(options: {
        mediaPayload: MediaPayload;
        mediaDescriptor: MediaDescriptor;
        playbackContext?: PlaybackContext;
        preferredPlaylistType: PlaylistType;
        insertion?: Insertion;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaPayload: Types.instanceStrict(MediaPayload),
                    mediaDescriptor: Types.instanceStrict(MediaDescriptor),
                    playbackContext:
                        Types.instanceStrict(PlaybackContext).optional,
                    preferredPlaylistType: Types.in(PlaylistType),
                    insertion: Types.object(InsertionTypedef).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            mediaPayload,
            mediaDescriptor,
            playbackContext,
            preferredPlaylistType,
            insertion
        } = options;

        /**
         *
         * @access private
         * @type {SDK.Services.Media.MediaPayload}
         *
         */
        this.payload = mediaPayload;

        /**
         *
         * @access private
         * @type {SDK.Media.MediaDescriptor}
         *
         */
        this.descriptor = mediaDescriptor;

        /**
         *
         * @access private
         * @since 4.0.0
         * @type {SDK.Services.Media.PlaybackContext|undefined}
         *
         */
        this.playbackContext = playbackContext;

        /**
         *
         * @access public
         * @type {SDK.Services.Media.MediaPlayhead}
         *
         */
        this.playhead = this.payload.playhead;

        /**
         *
         * @access public
         * @since 13.0.0
         * @type {SDK.Services.Media.PlaylistType}
         *
         */
        this.preferredPlaylistType = preferredPlaylistType;

        /**
         *
         * @access public
         * @since 15.2.1
         * @type {Number|undefined}
         *
         */
        this.priorityTracking = undefined;

        /**
         *
         * @access public
         * @since 18.0.0
         * @type {SDK.Services.Media.Insertion|undefined}
         * @desc Playlist service response SGAI/SSAI insertion data.
         *
         */
        this.insertion = insertion;
    }

    /**
     *
     * @access public
     * @returns {Promise<SDK.Media.Playlist>}
     *
     */
    public getDefaultPlaylist() {
        return this.getPlaylist();
    }

    /**
     *
     * @access public
     * @returns {Promise<SDK.Media.Playlist>}
     *
     */
    public getPreferredPlaylist() {
        return this.getPlaylist(this.preferredPlaylistType);
    }

    /**
     *
     * @access public
     * @param {String<SDK.Media.MediaAnalyticsKey>} [key=MediaAnalyticsKey.conviva]
     * @returns {Object}
     *
     */
    public getTrackingData(key: MediaAnalyticsKey) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                key: Types.in(MediaAnalyticsKey).optional
            };

            typecheck(this, 'getTrackingData', params, arguments);
        }

        const { tracking } = this.payload;

        key = key || MediaAnalyticsKey.conviva;

        const trackingData =
            (tracking as Indexable<typeof tracking>)[key] || {};

        return trackingData;
    }

    /**
     *
     * @access public
     * @desc Gets video and audio segment types from the preferred Playlist.
     * @returns {Promise<{videoSegmentTypes: Array<String>|undefined, audioSegmentTypes: Array<String>|undefined}>}
     * @note Chromecast calls this
     *
     */
    public async getSegmentTypes() {
        const playlist = await this.getPreferredPlaylist();

        return {
            videoSegmentTypes: playlist.attributes?.videoSegmentTypes,
            audioSegmentTypes: playlist.attributes?.audioSegmentTypes
        };
    }

    /**
     *
     * @access public
     * @since 4.0.0
     * @returns {Promise<Array<SDK.Services.Media.AudioRendition>>}
     *
     */
    public getAudioRenditions() {
        const { renditions = {} } = this.payload.stream;
        const { audio = [] } = renditions;

        const audioRenditions = audio.map(
            (item: AudioRendition) => new AudioRendition(item)
        );

        return Promise.resolve(audioRenditions);
    }

    /**
     *
     * @access public
     * @since 4.0.0
     * @returns {Promise<Array<SDK.Services.Media.SubtitleRendition>>}
     *
     */
    public getSubtitleRenditions() {
        const { renditions = {} } = this.payload.stream;
        const { subtitles = [] } = renditions;

        const subtitleRenditions = subtitles.map(
            (item: SubtitleRendition) => new SubtitleRendition(item)
        );

        return Promise.resolve(subtitleRenditions);
    }

    /**
     *
     * @access public
     * @since 4.0.0
     * @returns {Array<SDK.Services.Media.PlaybackVariant>}
     *
     */
    public getVariants() {
        const { variants = [] } = this.payload.stream;

        const playbackVariants = variants.map(
            (item: PlaybackVariant) => new PlaybackVariant(item)
        );

        return playbackVariants;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @returns {Array<SDK.Services.Media.SourceInfo>}
     *
     */
    public getSourceInfos(): Array<SourceInfo> {
        return this.payload.stream.sources;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {String<SDK.Services.Media.PlaylistType>} playlistType
     * @desc Gets a list of `MediaSource`
     * @returns {Array<SDK.Media.MediaSource>}
     *
     */
    private getMediaSources(playlistType: PlaylistType) {
        const { COMPLETE, SLIDE } = PlaylistType;

        const sourceInfos = this.getSourceInfos();

        return sourceInfos.map((sourceInfo) => {
            let urlInfo;

            switch (playlistType) {
                case COMPLETE:
                    urlInfo = sourceInfo.complete;
                    break;
                case SLIDE:
                    urlInfo = sourceInfo.slide;
                    break;
            }
            // there will always be either a complete or a slide url
            // so urlInfo will not be undefined for primaryContent

            return new MediaSource({
                priority: sourceInfo.priority,
                primaryContent: urlInfo as UrlInfo,
                insertion: sourceInfo.insertion
            });
        });
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Media.MediaPayloadStream} stream
     * @returns {Array<String<SDK.Services.Media.PlaylistType>>}
     *
     */
    private getAvailablePlaylistTypes(stream: MediaPayloadStream) {
        const { complete, slide } = stream.sources[0];

        const availablePlaylistTypes: Array<PlaylistType> = [];

        if (Check.assigned(complete)) {
            availablePlaylistTypes.push(PlaylistType.COMPLETE);
        }

        if (Check.assigned(slide)) {
            availablePlaylistTypes.push(PlaylistType.SLIDE);
        }

        return availablePlaylistTypes;
    }

    /**
     *
     * @access private
     * @param {Array<String<SDK.Services.Media.PlaylistType>>} availablePlaylistTypes
     * @param {String<SDK.Services.Media.PlaylistType>} [playlistType]
     * @desc Gets preferred PlaylistType based on given `playlistType` from a given list of `availablePlaylistTypes`
     * @returns {String<SDK.Services.Media.PlaylistType>}
     *
     */
    private getPreferredPlaylistType(
        availablePlaylistTypes: Array<PlaylistType>,
        playlistType?: PlaylistType
    ) {
        const { COMPLETE, SLIDE } = PlaylistType;

        switch (playlistType) {
            case COMPLETE:
                return availablePlaylistTypes.includes(COMPLETE)
                    ? COMPLETE
                    : SLIDE;
            case SLIDE:
                return availablePlaylistTypes.includes(SLIDE)
                    ? SLIDE
                    : COMPLETE;
            default:
                return availablePlaylistTypes[0];
        }
    }

    /**
     *
     * @access private
     * @param {String<SDK.Services.Media.PlaylistType>} [playlistType]
     * @returns {Promise<SDK.Media.Playlist>}
     *
     */
    private async getPlaylist(playlistType?: PlaylistType) {
        const { stream, tracking: trackingInfo } = this.payload;
        const { variants = [], renditions = {}, attributes } = stream;
        const { audio = [], subtitles = [] } = renditions;

        const availablePlaylistTypes = this.getAvailablePlaylistTypes(stream);
        const preferredPlaylistType = this.getPreferredPlaylistType(
            availablePlaylistTypes,
            playlistType
        );
        const mediaSources = this.getMediaSources(preferredPlaylistType);

        const playbackVariants = variants.map(
            (item: PlaybackVariant) => new PlaybackVariant(item)
        );
        const audioRenditions = audio.map(
            (item: AudioRendition) => new AudioRendition(item)
        );
        const subtitleRenditions = subtitles.map(
            (item: SubtitleRendition) => new SubtitleRendition(item)
        );

        const playlist = new Playlist({
            mediaSources,
            trackingInfo,
            playlistType: preferredPlaylistType,
            availablePlaylistTypes,
            attributes,
            variants: playbackVariants,
            audioRenditions,
            subtitleRenditions
        });

        return playlist;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaItem';
    }
}
