/**
 *
 * @module ripcutLabelPosition
 *
 */

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.RipcutLabelPosition
 * @desc Indicates position of the label's image on the scaled master image. TL - Top Left, TC - Top Center, TR - Top Right, L - Left, C - Center, R - Right, LL - Low Left, LC - Low Center, LR - Low Right.
 *
 * @property {String} topLeft - Since `17.0.0`
 * @property {String} topCenter - Since `17.0.0`
 * @property {String} topRight - Since `17.0.0`
 * @property {String} left - Since `17.0.0`
 * @property {String} center - Since `17.0.0`
 * @property {String} right - Since `17.0.0`
 * @property {String} lowLeft - Since `17.0.0`
 * @property {String} lowCenter - Since `17.0.0`
 * @property {String} lowRight - Since `17.0.0`
 *
 * @example
 * SDK.Ripcut.RipcutLabelPosition.topLeft // 'TL'
 *
 */
export default {
    topLeft: 'TL',
    topCenter: 'TC',
    topRight: 'TR',
    left: 'L',
    center: 'C',
    right: 'R',
    lowLeft: 'LL',
    lowCenter: 'LC',
    lowRight: 'LR'
};
