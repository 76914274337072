/**
 *
 * @module validatedTelemetryResponse
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TelemetryResponse from './telemetryResponse';
import ValidationResult from './validationResult';

/**
 *
 * @access protected
 * @since 4.11.0
 * @desc Model describing the response from the telemetry validation service
 * optionally containing information, instructions and validation results.
 *
 */
export default class ValidatedTelemetryResponse extends TelemetryResponse {
    /**
     *
     * @access public
     * @since 4.11.0
     * @type {Array<SDK.Services.Internal.Telemetry.ValidationResult>}
     *
     */
    public results: Array<ValidationResult>;

    /**
     *
     * @param {Object} options
     * @param {Array<SDK.Services.Internal.Telemetry.ValidationResult>} options.results
     * @param {Number} [options.replyAfter]
     * @param {String} [options.requestId]
     *
     */
    public constructor(options: {
        results: Array<ValidationResult>;
        replyAfter?: number;
        requestId?: string;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    results: Types.array.of.instanceStrict(ValidationResult),
                    replyAfter: Types.skip,
                    requestId: Types.skip
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { results } = options;

        this.results = results;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.ValidatedTelemetryResponse';
    }
}
