/**
 *
 * @module platformProviders
 *
 */

import DefaultGeoProvider from './defaultGeoProvider';

let geoProvider = DefaultGeoProvider;

/**
 *
 * @desc Provides a way to inject and pull core platform provider implementations.
 *
 */
export default {
    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object} options
     * @param {GeoProvider} options.GeoProvider
     * @desc Allows configuration of core infrastructure providers
     *
     */
    setProviders(options) {
        geoProvider = options.GeoProvider;
    },

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {GeoProvider}
     *
     */
    get GeoProvider() {
        if (!geoProvider) {
            throw new Error('GeoProvider provider not specified');
        }

        return geoProvider;
    }
};
