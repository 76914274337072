/**
 *
 * @module playlistBitrateType
 * @see https://github.com/STRML/keyMirror
 *
 */

import keyMirror from 'keymirror';

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaylistBitrateType
 * @desc Used to distinguish what the playlist bitrate type is (average or peak).
 *
 * @property {String} average - `Since 13.0.0`
 * @property {String} peak - `Since 13.0.0`
 *
 * @example
 * SDK.Services.QualityOfService.PlaylistBitrateType.average // 'average'
 *
 */
export default keyMirror({
    average: null,
    peak: null
});
