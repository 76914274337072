/* eslint-disable @typescript-eslint/no-non-null-assertion */

/**
 *
 * @module subscriptionApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/subscription.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/SubscriptionApi.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/subscription.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import BaseApi from '../baseApi';

import SubscriptionManager from './subscriptionManager';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import Logger from '../logging/logger';
import getSafe from '../services/util/getSafe';
import { SubscriptionState } from '../services/subscriber/enums';

import type Subscription from './subscription';

import {
    Subscription as SubscriptionV2,
    SubscriberInfo
} from './../services/subscriber/typedefs';

const DustUrn = DustUrnReference.subscription.subscriptionApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides ability to access the subscriptions that are available and associated with an account.
 *
 */
export default class SubscriptionApi extends BaseApi {
    /**
     *
     * @access private
     * @type {SDK.Subscription.SubscriptionManager}
     *
     */
    private subscriptionManager: SubscriptionManager;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private dustEnabled: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Subscription.SubscriptionManager} options.subscriptionManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        logger: Logger;
        subscriptionManager: SubscriptionManager;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    subscriptionManager:
                        Types.instanceStrict(SubscriptionManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { subscriptionManager } = options;

        this.subscriptionManager = subscriptionManager;
        this.dustEnabled = getSafe(
            () => this.subscriptionManager.client.dustEnabled
        );

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @desc Gets the list of Subscriptions associated with the current account.
     * @param {IGNORE-PARAMS}
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Array<Subscription>>} A promise that completes when the
     * operation has succeeded and returns the requested subscriptions.
     *
     */
    public async getSubscriptions(): Promise<Array<Subscription>>;

    @apiMethodDecorator()
    public async getSubscriptions(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return await this.subscriptionManager.getSubscriptions(logTransaction);
    }

    /**
     *
     * @access public
     * @since 4.13.0
     * @param {SDK.Services.Subscriber.SubscriptionState} [state] - When supplied, only returns subscriptions
     * with this status. If not supplied, returns all subscriptions except churned.
     * @param {Boolean} [includeChurned] - When supplied, indicates whether churned subscriptions should be returned. Default is false.
     * @desc Returns subscriber's subscription information.
     * @throws {SubscriptionNotFoundException} No subscription with that id has been found for the user.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Subscriber.SubscriberInfo>>} A promise that completes when the operation has
     * succeeded and returns the requested subscriber info.
     *
     */
    public async getSubscriberInfo(
        state?: ValueOf<typeof SubscriptionState>,
        includeChurned?: boolean
    ): Promise<SubscriberInfo>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            state: Types.in(SubscriptionState).optional,
            includeChurned: Types.boolean.optional
        }
    })
    public async getSubscriberInfo(apiOptions?: unknown) {
        const {
            logTransaction,
            args: [state, includeChurned]
        } = apiOptions as ApiOptions;

        return await this.subscriptionManager.getSubscriberInfo({
            state,
            includeChurned,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.13.0
     * @param {String} subscriptionId - The subscription identifier.
     * @desc Returns a subscription by id.
     * @throws {SubscriptionNotFoundException} No subscription with that id has been found for the user.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Subscriber.Subscription>>} A promise that completes when the operation has
     * succeeded and returns the requested subscription.
     *
     */
    public async getAccountSubscription(
        subscriptionId: string
    ): Promise<SubscriptionV2>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            subscriptionId: Types.nonEmptyString
        }
    })
    public async getAccountSubscription(apiOptions: unknown) {
        const {
            logTransaction,
            args: [subscriptionId]
        } = apiOptions as ApiOptions;

        return await this.subscriptionManager.getAccountSubscription(
            subscriptionId,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Subscription.SubscriptionApi';
    }
}
