/**
 *
 * @module queryBuilder
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/content.md
 *
 */

import HttpMethod from '../configuration/httpMethod';

/**
 *
 * @access protected
 *
 */
export default class QueryBuilder {
    /**
     *
     * @param {String} url - The URL for which to build the query.
     * @param {HttpMethod} method - The method for which to build the query.
     * @desc Returns the URL for the query.
     * @returns {String} The URL for the query.
     *
     */
    public createQueryUrl(
        url: string, // eslint-disable-line @typescript-eslint/no-unused-vars
        method: keyof typeof HttpMethod // eslint-disable-line @typescript-eslint/no-unused-vars
    ): string {
        const methodSignature = 'createQueryUrl(url, method)';

        throw new Error(`${methodSignature} - not-implemented`);
    }

    /**
     *
     * @desc Returns the post content for the query.
     * @returns {String} The post content for the query.
     *
     */
    public createPostContent(): string {
        const methodSignature = 'createPostContent()';

        throw new Error(`${methodSignature} - not-implemented`);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Content.QueryBuilder';
    }
}
