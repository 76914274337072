/**
 *
 * @module flexPlugin
 * @desc {SDK.Flex.FlexPlugin} entry point.
 *
 */

import Flex from './entry';
import FlexApi from './flexApi';
import FlexManager from './flexManager';
import FlexService from './../services/flex/entry';
import FlexClient from './../services/flex/flexClient';
import type { IPlugin } from '../IPlugin';

import getSafe from './../services/util/getSafe';

import FlexClientConfiguration from './../services/flex/flexClientConfiguration';
import FlexManagerConfiguration from './../services/configuration/flexManagerConfiguration';

const plugin: IPlugin<typeof Flex, 'Flex', typeof FlexService> = {
    pluginName: 'Flex',
    entry: Flex,
    service: FlexService,

    /**
     *
     * @access protected
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options: TodoAny) {
        const { sdkSession, logger } = options;
        const { flexManager } = sdkSession.managers;

        sdkSession.flexApi = new FlexApi({
            flexManager,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options: TodoAny) {
        const { managers, logger, httpClient, services, accessTokenProvider } =
            options;

        const flexManager = new FlexManager({
            flexManagerConfiguration: services.flex,
            flexClient: new FlexClient({
                flexClientConfiguration: services.flex.client,
                logger,
                httpClient
            }),
            logger,
            accessTokenProvider
        });

        managers.flexManager = flexManager;
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration: TodoAny, services: TodoAny) {
        const clientConfiguration = new FlexClientConfiguration(
            getSafe(() => services.flex.client.baseUrl),
            serviceDefinitionsConfiguration.processEndpoints(
                getSafe(() => services.flex.client.endpoints)
            )
        );

        serviceDefinitionsConfiguration.flex = new FlexManagerConfiguration(
            clientConfiguration,
            undefined,
            getSafe(() => services.flex.disabled)
        );
    }
};

/**
 *
 * @access public
 * @since 16.0.0
 *
 */
export default plugin;
