/**
 *
 * @module entry
 * @desc {SDK.Logging} entry point.
 *
 */

import LoggingApi from './loggingApi';
import Logger from './logger';
import LogEvent from './logEvent';
import LogSink from './logSink';
import LogLevel from './logLevel';
import LogTransaction from './logTransaction';
import Metric from './metric';
import ConsoleSink from './sinks/consoleSink';
import AnalyticsProvider from './analyticsProvider';

/**
 *
 * @typedef {Object} SDK.Logging
 *
 * @property {LoggingApi} LoggingApi
 * @property {Logger} Logger
 * @property {LogEvent} LogEvent
 * @property {LogSink} LogSink
 * @property {LogLevel} LogLevel
 * @property {LogTransaction} LogTransaction
 * @property {Metric} Metric
 * @property {AnalyticsProvider} AnalyticsProvider
 *
 */
export default Object.assign(Logger.instance, {
    LoggingApi,
    Logger,
    LogEvent,
    LogSink: Object.assign(LogSink, {
        ConsoleSink
    }),
    LogLevel,
    LogTransaction,
    Metric,
    AnalyticsProvider
});
