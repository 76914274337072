/**
 *
 * @module hdrType
 *
 */

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.HdrType
 * @desc Defines the different types of HDR available.
 *
 * @property {String} dolbyVision - Since `4.18.0` - The dolby vision type.
 * @property {String} hdr10 - Since `4.18.0` - The Hdr10 type.
 *
 * @example
 * HdrType.hdr10 // 'HDR10'
 *
 */
export default {
    dolbyVision: 'DOLBY_VISION',
    hdr10: 'HDR10'
};
