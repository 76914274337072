/**
 *
 * @module networkType
 *
 */

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.NetworkType
 * @desc Defines the different types of networks.
 *
 * @property {String} cellular3g - Since `4.0.0`.
 * @property {String} cellular4g - Since `4.0.0`.
 * @property {String} cellular5g - Since `4.0.0`.
 * @property {String} wifi - Since `4.0.0`.
 * @property {String} ethernet - Since `4.0.0`.
 * @property {String} unknown - Since `4.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.NetworkType.wifi // 'wifi'
 *
 */
export default {
    cellular3g: '3g',
    cellular4g: '4g',
    cellular5g: '5g',
    wifi: 'wifi',
    ethernet: 'ethernet',
    unknown: 'unknown'
};
