/**
 *
 * @module baseEnvironmentConfiguration
 *
 */

import VersionInfo from './../../versionInfo';

/**
 *
 * @access protected
 * @abstract
 * @since 4.8.0
 * @desc Provides configuration information that describes the current environment
 * and is used by the ConfigurationClient to retrieve the SdkSessionConfiguration
 * necessary to create an SdkSession instance.
 *
 */
export default class BaseEnvironmentConfiguration {
    /**
     *
     * @param {Object} options
     * @param {String} options.applicationRuntime
     * @param {String} options.applicationVersion
     * @param {String} options.platform
     * @param {String} options.deviceFamily
     * @param {String} options.deviceProfile
     * @param {String} options.deviceRuntimeProfile
     * @param {String} options.configVersion
     * @param {Object} options.device
     * @param {String} [options.applicationRuntimeOverride]
     * @param {String} [options.deviceProfileOverride]
     *
     */
    constructor(options = {}) {
        const {
            applicationRuntime,
            applicationVersion,
            platform,
            deviceFamily,
            deviceProfile,
            deviceRuntimeProfile,
            configVersion,
            device,
            applicationRuntimeOverride,
            deviceProfileOverride
        } = options;

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the application runtime.
         * @note applicationRuntimeOverride argument when not null should be used instead of applicationRuntime.
         *
         */
        this.applicationRuntime =
            applicationRuntimeOverride || applicationRuntime || '';

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the application version.
         * @note Used to populate the `DustApplicationPayload.version` field and application headers.
         *
         */
        this.applicationVersion = applicationVersion || '';

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the device family (e.g. "browser").
         * @note Used in constructing the configuration url. This is the main SDK platform identifier.
         *
         */
        this.deviceFamily = deviceFamily || 'browser';

        /**
         *
         * @access private
         * @since 13.0.0
         * @type {String}
         * @desc Override identifier for device family.
         * @note Used in cases where a different deviceFamily override is wanted,
         * for example, when constructing the SDK platform common header.
         *
         */
        this.deviceFamilyOverride = 'javascript';

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the device profile (e.g. "desktop", "sony").
         * @note deviceProfileOverride argument when not null should be used instead of deviceProfile.
         *
         */
        this.deviceProfile = deviceProfileOverride || deviceProfile || '';

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the device runtime profile.
         * @note Optional internal use by some platforms
         *
         */
        this.deviceRuntimeProfile = deviceRuntimeProfile || '';

        /**
         *
         * @access public
         * @type {String}
         * @desc Gets or sets the configuration version (e.g. "4.0").
         *
         */
        this.configVersion = configVersion || '4.0';

        /**
         *
         * @access public
         * @type {String}
         *
         */
        this.platform = platform || '';

        /**
         *
         * @access private
         * @type {Object}
         *
         */
        this.device = device;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     * @desc Constructed platform identifier `String`.
     * @note For example: `javascript/macosx/chrome`, `javascript/samsung/tizen`, etc...
     *
     */
    get constructedPlatformIdentifier() {
        return `${this.deviceFamilyOverride}/${
            this.deviceProfile || 'fallbackDeviceProfile'
        }/${this.applicationRuntime || 'fallbackApplicationRuntime'}`;
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @type {String}
     * @desc Gets the shortened SDK version (e.g. "4.8").
     *
     */
    get sdkVersion() {
        return VersionInfo.versionShort;
    }

    // #region private

    /**
     *
     * @access private
     *
     */
    toString() {
        return 'SDK.Services.Providers.BaseEnvironmentConfiguration';
    }

    // #endregion
}
