/**
 *
 * @module redeemRetryHandler
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import LogTransaction from '../logging/logTransaction';
import AccessStatus from './accessStatus';
import PurchaseManager from './purchaseManager';
import Receipt from './receipt';

/**
 *
 *
 */
export default class RedeemRetryHandler {
    /**
     *
     * @access public
     * @type {SDK.Purchase.PurchaseManager}
     *
     */
    public provider: PurchaseManager;

    /**
     *
     * @access public
     * @type {SDK.Purchase.Receipt}
     *
     */
    public receipt: Receipt;

    /**
     *
     * @param {SDK.Purchase.PurchaseManager} provider
     * @param {SDK.Purchase.Receipt} receipt
     *
     */
    public constructor(provider: PurchaseManager, receipt: Receipt) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.object({
                    redeemPurchases: Types.function
                }),
                receipt: Types.instanceStrict(Receipt)
            };

            typecheck(this, params, arguments);
        }

        this.provider = provider;
        this.receipt = receipt;
    }

    /**
     *
     * @access public
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc handles the running of SDK.Purchase.PurchaseManager.redeemPurchases
     * @returns {Promise<AccessStatus>}
     *
     */
    public run(logTransaction: LogTransaction): Promise<AccessStatus> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'run', params, arguments);
        }

        return this.provider.redeemPurchases(
            this.receipt.receiptCredentials,
            this,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Purchase.RedeemRetryHandler';
    }
}
