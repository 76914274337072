/**
 *
 * @module resolutionMax
 *
 */

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.ResolutionMax
 * @desc Resolution strings used for filtering during playback selection. eg `["1920x180","1280x720"]`
 *
 * @property {String} res1920x1080 - Since `4.18.0` - Primarily used for the HD multi master use case.
 * @property {String} res1280x720 - Since `4.18.0` - Primarily used for the restricted sw drm use case.
 *
 * @example
 * ResolutionMax.res1280x720 // '1280x720'
 *
 */
export default {
    res1920x1080: '1920x1080',
    res1280x720: '1280x720'
};
